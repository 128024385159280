import React, { useEffect,useState } from 'react';
import TextField  from '@mui/material/TextField' 
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import ProjecttypeallAPI from "apis/panel/project/project";
import TaskcreateAPI from "apis/panel/task/task";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Loader from "components/Loader";


const AddNewTask = () => {

  const [errorFields, setErrorFields] = useState({
    taskid: false,
    projecttype: false,
    taskname: false,
    status: false,
    costcenter:false
   
    
  });

 




  const { getAllProjectstypeAPI } = ProjecttypeallAPI();
  const { getTaskCreateAPI } = TaskcreateAPI();

  const[projecttypeid, setprojecttypeid]= useState('');
  const[projecttypename, setprojecttypename]= useState('');
  const[taskname, settaskename]= useState('');
  const[costcenter, setcostcenter]= useState('');
  const[startdate, setstartend]= useState('');
  const[enddate, setendend]= useState('');
  const[status, setstatus]= useState('');
  const[taskcode, settaskcode]= useState('');
  const[description, setdescription]= useState('');
  const[projecttypeoption, setprojecttypeoption]= useState([]);
  const navigate = useNavigate();
  const [loader2, setLoader2] = useState(false)



  const handleGetAllprojectype = async () => {
    try {
        let res = await getAllProjectstypeAPI();
        // console.log(titleOptions, "res")
        setprojecttypeoption(res?.data || []);
    }
    catch (err) {}


  };

  const handleNavigate = (e) => {
    navigate("/panel/projecttask");
  };


  useEffect(()=>{
    handleGetAllprojectype()

  },[])

  const handleprojecttype  = (e) => {
    const selectedprojecttype = projecttypeoption.find(option => option.projectTypeName === e.target.value);
    setprojecttypename(selectedprojecttype.projectTypeName); // Set the titleName
    setprojecttypeid(selectedprojecttype.projectTypeId); // Set the titleId
  };

  const handlestartAccept = (date) => {
    setstartend(date);
  }

  const handleendAccept = (date) => {
    setendend(date);
  }

  const handleprojectnameChange = (e) => {
    settaskename(e.target.value);
  };

  const handleaccountChange = (e) => {
    setcostcenter(e.target.value);
  };
  const handlestatusChange = (e) => {
    setstatus(e.target.value);
  };

  const handledescriptionChange = (e) => {
    setdescription(e.target.value);
  };
  const handleprojectcodeChange = (e) => {
    settaskcode(e.target.value);
  };



  const handletaskcreate = async (e) => {
    setLoader2(true)
    e.preventDefault();
    
    const errors = {};
    if (!taskcode) errors.taskid = true;
    if (!projecttypeid) errors.projecttype = true;
    if (!taskname) errors.taskname = true;
   
    if (!status) errors.status = true;
    if (!costcenter) errors.costcenter = true;
  
   
  

    setErrorFields(errors);

    if (Object.keys(errors).length === 0) {
    
 
  const values = {
    projectTypeId: projecttypeid,
    taskName: taskname,
    taskCode: taskcode,
    costCenter:costcenter,
    status: status,
    "isActive":true
   
   
  };
  
  const data = await getTaskCreateAPI(values);


  if(data.status === 'Ok'){
    navigate("/panel/projecttask");
    setLoader2(false)
    // sessionStorage.setItem('EmployeeID',data.data.employeeId)
    // sessionStorage.setItem('EmployeeCode',data.data.employeeCode)
    return toast.success("Record created successfully.", { toast: "Record created successfully." });
  }else{

    setLoader2(false)
    return toast.error("Tasks ID already exist!", { toast: "Tasks ID already exist!" });

  }
}
else{
  setLoader2(false)
  return toast.error("Error saving Data", { toast: "Error saving Data" });
}




};
  return (
    <div className='ProjectCont'>
       <Loader loading={loader2} />

<Box  sx={{pl:3,pt:3}} className="addnewprojecthead">Add New Task</Box>
           <Grid container spacing={2} sx={{ pl: 7, pr: 7, mt: 2 }} >
        
         
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <Box className="label">Task ID <span style={{ color: "red" }}>*</span></Box>
                <TextField
                  id="permanentAddress"
                  placeholder="Enter the Task Id"
                  variant="outlined"
                  fullWidth
                  style={{border:"1.5px solid #B2D4F8"}}
                //   value={houseNo}
                  onChange={handleprojectcodeChange}
                />
                 {errorFields.taskid && <span className="error-msg">This is required Field</span>}
              </Grid>
   
     
              <Grid item xs={12} sm={6} md={6} lg={6}>
              <Box className="label">Project type <span style={{ color: "red" }}>*</span></Box>
                <FormControl fullWidth>
                  <Select
                    id="permanentState"
                    defaultValue=""
                    placeholder="Select type"
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                    style={{ border: "1.5px solid #B2D4F8", color: "grey" }}
                    // value={selectedState}
                    onChange={handleprojecttype}
                  >
                    <MenuItem value="">
                      Select Project type
                    </MenuItem>
                    
          {projecttypeoption.map((option) => (
          <MenuItem key={option.id} value={option.projectTypeName}>{option.projectTypeName}</MenuItem>
        ))}
                  </Select>
                </FormControl>
                {errorFields.projecttype && <span className="error-msg">This is required Field</span>}
              </Grid>
       


        
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <Box className="label">Task Name <span style={{ color: "red" }}>*</span></Box>
                <TextField
                  id="permanentAddress"
                  placeholder="Enter the Task Name"
style={{border:"1px solid #B2D4F8"}}
                  // Add onChange handler
                  variant="outlined"
                  fullWidth
                //   value={streetName}
                    onChange={handleprojectnameChange}
                />
                 {errorFields.taskname && <span className="error-msg">This is required Field</span>}
              </Grid>
       
   
              <Grid item xs={12} sm={6} md={6} lg={6}>
              <Box className="label">Status <span style={{ color: "red" }}>*</span></Box>
                <FormControl fullWidth>
                  <Select
                    id="permanentState"
                    defaultValue=""
                    placeholder="Select status"
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                    style={{ border: "1.5px solid #B2D4F8", color: "grey" }}
                    // value={selectedState}
                    onChange={handlestatusChange}
                  >
                    <MenuItem value="">
                      Select Status
                    </MenuItem>
                    <MenuItem value="Active">Active</MenuItem>
                    <MenuItem value="Inactive">InActive</MenuItem>
                    {/* <MenuItem value="Onhold">OnHold</MenuItem> */}
                  </Select>
                </FormControl>
                {errorFields.status && <span className="error-msg">This is required Field</span>}
              </Grid>
      

        
       
             
       

      
  
        <Grid item xs={12} sm={6} md={6} lg={6}>
        <Box className="label">Cost Center <span style={{ color: "red" }}>*</span></Box>
                <FormControl fullWidth>
                  <Select
                    id="permanentState"
                    defaultValue=""
                    placeholder="Select Cost Center"
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                    style={{ border: "1.5px solid #B2D4F8", color: "grey" }}
                    // value={selectedState}
                    onChange={handleaccountChange}
                  >
                    <MenuItem value="">
                      Select Cost Center
                    </MenuItem>
                    <MenuItem value="Billable">BILLABLE</MenuItem>
              <MenuItem value="Non-Billable">NONBILLABLE</MenuItem>
                  </Select>
                </FormControl>
                {errorFields.costcenter && <span className="error-msg">This is required Field</span>}
                </Grid>
              
</Grid>

              
<Grid container  sx={{ pl: 4, pr:5, mt:5 , justifyContent:"center"}} >
 

        <Grid item xs={12} sm={2} md={2} lg={2}>
        <Button className="cancelButton"onClick={handleNavigate} >Cancel</Button>
        </Grid>

        <Grid item  sx={{mr:1}} xs={12} sm={2} md={2} lg={2}>
        <Button className="saveButton" onClick={handletaskcreate}>Save</Button>
        </Grid>

        </Grid>
        
    </div>
  )
}

export default AddNewTask