// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cardsinrpHome :hover{
    transition: transform 0.3s ease;
    transform: scale(1.1);
}

.CardContentTitle{
    font-family: "Inter";
    font-size: 18px;
    font-weight: 500;
    color: black;
    justify-content: center;
    text-align: center;

}`, "",{"version":3,"sources":["webpack://./src/views/panel/vraShifts/homePage/RpHome.css"],"names":[],"mappings":"AAAA;IACI,+BAA+B;IAC/B,qBAAqB;AACzB;;AAEA;IACI,oBAAoB;IACpB,eAAe;IACf,gBAAgB;IAChB,YAAY;IACZ,uBAAuB;IACvB,kBAAkB;;AAEtB","sourcesContent":[".cardsinrpHome :hover{\r\n    transition: transform 0.3s ease;\r\n    transform: scale(1.1);\r\n}\r\n\r\n.CardContentTitle{\r\n    font-family: \"Inter\";\r\n    font-size: 18px;\r\n    font-weight: 500;\r\n    color: black;\r\n    justify-content: center;\r\n    text-align: center;\r\n\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
