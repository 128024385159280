import config from "config/config";
import HandleApiResponse from "utils/handleApiResponse";
import { toast } from "react-toastify";
import * as moment from "moment";

const rpRosterAPI = () => {
    const { handleResponse, handleError } = HandleApiResponse();

    async function getAllRadRosterDetailsAPI(values) {
        try {
          const obj = {
            method: "GET",
            credentials: "include",
            headers: {
              "Content-Type": "application/json",
            },
          };
          const query = JSON.stringify(values);
          const res = await fetch(
            `${config.host}/panel/rp/radRoster/radRosters?query=${query}`,
            obj
          );
          return handleResponse(res);
        } catch (err) {
          handleError(err);
        }
      }

      async function getRadRosterFindOne(values) {
        try {
          const obj = {
            method: "GET",
            credentials: "include",
            headers: {
              "Content-Type": "application/json",
            },
          };
          const query = JSON.stringify(values);
          let res = await fetch(`${config.host}/panel/rp/radRoster/findOne?query=${query}`, obj);
          return handleResponse(res);
        } catch (err) {
          handleError(err);
        }
      } 

      async function updateRadRosterAPI(value) {
        try {
          const obj = {
            method: "PUT",
            credentials: "include",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(value),
          };
          let res = await fetch(`${config.host}/panel/rp/radRoster/update`, obj);
          return handleResponse(res);
        } catch (err) {
          handleError(err);
        }
      }

      async function createRadRosterAPI(value) {
        try {
          const obj = {
            method: "Post",
            credentials: "include",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(value),
          };
          let res = await fetch(`${config.host}/panel/rp/radRoster/create`, obj);
          return handleResponse(res);
        } catch (err) {
          handleError(err);
        }
      }

    
    return{
        getAllRadRosterDetailsAPI,
        getRadRosterFindOne,
        updateRadRosterAPI,
        createRadRosterAPI
    }
}
export default rpRosterAPI;
