// npm packages
import React, { useEffect, useState, useContext, Fragment } from "react";
import { Routes, Route } from "react-router-dom";

// custom pages
import childRoutes from "routes/childRoutes/childRoutes";
import Context from "context/context";
import Sidebar from "components/sidebar/Sidebar";
import SideBarRoutes from "routes/sidebarRoutes/sideBarRoutes";

function ChildLayout() {
  const { state } = useContext(Context);

  const [selectedRoutes, setSelectedRoutes] = useState([]);
  const [sidebarRoutes, setSidebarRoutes] = useState([]);

  useEffect(() => {
    if (
      state?.isLoggedIn &&
      state?.permission &&
      Object.keys(state?.permission).length > 0
    ) {
      handleMountChildRoutes(state.permission);
    }
  }, [state?.isLoggedIn, state.permission]);

  async function handleMountChildRoutes(permission) {
    try {
      let authorizedRoutes = [];
      let sidebarRoutes = [];

      for (let i = 0; i < childRoutes.length; i++) {
        for (const item of permission) {
          if (childRoutes[i].permissionName === item?.name) {
            authorizedRoutes.push(childRoutes[i]);
          }
        }
      }

      for (let i = 0; i < SideBarRoutes.length; i++) {
        for (const item of permission) {
          if (SideBarRoutes[i].permissionName === item?.name) {
            sidebarRoutes.push(SideBarRoutes[i]);
          }
        }
      }

      if (authorizedRoutes && authorizedRoutes?.length > 0) {
        const routes = authorizedRoutes.map((route, i) => {
          return (
            <Route
              key={i}
              path={route.path}
              element={<route.component key={i} />}
            />
          );
        });
        setSelectedRoutes(routes);
        setSidebarRoutes(sidebarRoutes);
      }
    } catch (e) {
      console.log({ e });
    }
  }

  return (
    <Fragment>
      <Sidebar style={{background: '#000'}}
        bodyLayout={<Routes>{selectedRoutes}</Routes>}
        sidebarRoutes={sidebarRoutes}
      />
    </Fragment>
  );
}

export default ChildLayout;
