import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { Grid, Box, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Checkbox } from '@mui/material';
import { FormControl, IconButton, MenuItem, Modal, TextField, Typography, Select } from '../../../../node_modules/@mui/material/index';
import Loader from "components/Loader";
import "./Project.css";
import { toastCss, theme, loginRequest } from "config/config";
import { toast } from "react-toastify";
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import Pagination from '@mui/material/Pagination';
import ProjectAPI from 'apis/panel/project/project';

const TasksTable = ({ projectId }) => {
    const navigate = useNavigate();
    const [loader2, setLoader2] = useState(false)
    const [assignTaskPopup, setAssignTaskPopup] = useState(false)
    const [selectedTasks, setSelectedTasks] = useState(new Set());
    const [taskList, setTaskList] = useState([])
    const [taskTypeList, setTaskTypeList] = useState([])
    const [count, setCount] = useState(0)
    const [offset, setOffset] = useState(1);
    // const [isActive, setIsActive] = useState('');
    const [searchValue, setSearchValue] = useState('')
    const [load, setLoad] = useState(false)
    const [filterValue, setFilterValue] = useState('')
    const [updateButton, setUpdateButton] = useState(false)
    const [selectAll, setSelectAll] = useState(false)

    const { getAllProjectTasksAPI, getAllProjectTypeTasksAPI, updateProjectTasksAPI } = ProjectAPI()

    const handleOpenAssignTaskPopup = () => {

        setAssignTaskPopup(true)
    }

    const handleCloseAssignTaskPopUp = () => {
        // setIsActive("")
        setOffset(1)
        setAssignTaskPopup(false)
        setSelectedTasks(new Set())
        setUpdateButton(false)
        setSelectAll(false)
    }

    const handleSelectAll = (task) => {

        if (task != 'Create') {
            setUpdateButton(true)
            if (selectedTasks.size === taskList.length) {
                setSelectedTasks(new Set());
                setSelectAll(false);
                setUpdateButton(false)

            } else {
                const newSelectedTasks = new Set(taskList.map((task) => task?.projectTaskId));
                setSelectedTasks(newSelectedTasks);
                setSelectAll(true);
            }
        } else if (task == 'Create') {
           
            if (selectedTasks.size === taskTypeList.length) {
                setSelectedTasks(new Set());
                setSelectAll(false);


            } else {
                const newSelectedTasks = new Set(taskTypeList.map((task) => task?.taskId));
                setSelectedTasks(newSelectedTasks);
                setSelectAll(true);
            }
        }


    }

    const handleSelection = (taskId, task) => {

        if (task != 'Create') {
            setUpdateButton(true)
        }
        setSelectedTasks((prevSelectedTasks) => {
            const newSelectedTasks = new Set(prevSelectedTasks);
            if (newSelectedTasks.has(taskId)) {
                newSelectedTasks.delete(taskId);
            } else {
                newSelectedTasks.add(taskId);
            }
            if (newSelectedTasks.size === 0) {
                setUpdateButton(false);
            }
            if (newSelectedTasks.size === taskList.length) {
                setSelectAll(true);
            } else {
                setSelectAll(false);
            }
            return newSelectedTasks;

        });


    };

    const handleAssignNewTask = async () => {
        const selectedTaskArray = Array.from(selectedTasks);

        if (selectedTaskArray.length > 0) {
            try {
                let values = {
                    projectId,
                    'type': 'CREATE',
                    'tasks': selectedTaskArray
                }

                if (updateButton) {
                    values = {
                        projectId,
                        'type': 'UPDATE',
                        'tasks': selectedTaskArray
                    }

                }
                setLoader2(true)
                const res = await updateProjectTasksAPI(values);
                setLoader2(false)
                if (res.status === "Ok") {
                    setLoad(prev => !prev);
                    toast.success("Record updated successfully.", toastCss);
                } else {
                    toast.error(res.error.message, toastCss);
                }

                handleCloseAssignTaskPopUp()


            } catch (error) {
                toast.error("An error occurred while assigning tasks.", toastCss);
            }
        } else {
            return toast.error('Select atleast one task')
        }


    };

    const getAllProjectTaskDetails = async () => {
        try {

            let values = {
                offset: (offset - 1) * 10,
                limit: 10,
                projectId
            };
            setLoader2(true)
            let res = await getAllProjectTasksAPI(values);
            setTaskList(res?.data?.data || []);
            setCount(res?.data?.count);
            setLoader2(false)
        }
        catch (err) {
            setLoader2(false)
        }
    }

    const getAllProjectTaskTypeDetails = async () => {

        try {

            let values = {
                offset: (offset - 1) * 10,
                limit: 10,
                projectId
            };
            if (searchValue) {
                values = {
                    offset: (offset - 1) * 10,
                    limit: 10,
                    projectId,
                    searchValue
                };
            }
            if (filterValue) {
                values = {
                    ...values,
                    filterValue,
                };
            }

            setLoader2(true)
            let res = await getAllProjectTypeTasksAPI(values);

            setTaskTypeList(res?.data?.data || []);
            setCount(res?.data?.count);

            setLoader2(false)
        }
        catch (err) {
            setLoader2(false)
        }
    }


    const handlePagination = (value) => {
        setOffset(value);
    };

    const BlueCheckbox = styled(Checkbox)(({ theme }) => ({
        color: '#0070E9',
        '&.Mui-checked': {
            color: '#0070E9',
        },
        '& .MuiSvgIcon-root': {
            fontSize: '20px', // Adjust the size of the checkbox icon
        }
    }));

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        padding: '8px', // Adjust the padding as needed
        // height: '40px', // Set a fixed height for the cell
    }));

    const StyledTableCellBody = styled(TableCell)(({ theme }) => ({
        // Adjust the padding as needed
        padding: '0px', // Set a fixed height for the cell
        paddingLeft: '8px',
    }));


    useEffect(() => {
        getAllProjectTaskDetails()
    }, [projectId,offset, load])

    useEffect(() => {
        if (assignTaskPopup) {
            getAllProjectTaskTypeDetails();
        }
    }, [assignTaskPopup, searchValue, filterValue, offset])


    return (
        <div className='ProjectCont'>
            <Loader loading={loader2} />
            <Grid container className="editsetupmaincont" sx={{ pl: 3, pr: 3, mt: 2, mb: 1 }}>
                <Box className="addnewprojecthead">Tasks</Box>
                <Grid md={6} lg={6} sm={12} className='taskAssignUpdateButtons'>
                    <Box >
                        {updateButton ? (
                            <Button className="projsetupedit" onClick={handleAssignNewTask}>
                                Remove Task

                            </Button>
                        ) : (
                            <Button className="removeTaskDisable" disabled>
                                Remove Task

                            </Button>
                        )}
                    </Box>
                    <Box>
                        <Button className="projsetupedit" onClick={handleOpenAssignTaskPopup}>
                            Assign Task
                        </Button>
                    </Box>
                </Grid>
            </Grid>
            <Grid container spacing={2} sx={{ pt: 1, pl: 4, pr: 3, mt: 2 }}>
                <TableContainer component={Paper} sx={{ boxShadow: 'none', border: 1, borderColor: 'grey.300' }}>
                    <Table aria-label="collapsible table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell padding="checkbox">
                                    <BlueCheckbox
                                        checked={selectAll}
                                        onChange={() => handleSelectAll('Update')} />
                                </StyledTableCell>
                                <StyledTableCell><b className="projectOwnertable-header">S no</b></StyledTableCell>
                                <StyledTableCell><b className="projectOwnertable-header">Task Id</b></StyledTableCell>
                                <StyledTableCell><b className="projectOwnertable-header">Task Name</b></StyledTableCell>
                                <StyledTableCell><b className="projectOwnertable-header">Cost Center</b></StyledTableCell>
                                <StyledTableCell><b className="projectOwnertable-header">Status</b></StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {taskList && taskList.length > 0 ? (
                                taskList.map((task, index) => (
                                    <TableRow key={task?.projectTaskId} className="tablebody-tablerowmain">
                                        <StyledTableCellBody padding="checkbox">
                                            <BlueCheckbox
                                                checked={selectedTasks.has(task?.projectTaskId)}
                                                onChange={() => handleSelection(task?.projectTaskId, 'Update')}
                                                inputProps={{ 'aria-label': 'controlled' }}
                                            />
                                        </StyledTableCellBody>
                                        <StyledTableCellBody><b className="projectOwnerTable-body">{index + 1}</b></StyledTableCellBody>
                                        <StyledTableCellBody><b className="projectOwnerTable-body">{task?.taskCode}</b></StyledTableCellBody>
                                        <StyledTableCellBody><b className="projectOwnerTable-body">{task?.taskName}</b></StyledTableCellBody>
                                        <StyledTableCellBody><b className="projectOwnerTable-body">{task?.costCenter}</b></StyledTableCellBody>
                                        <StyledTableCellBody><b className="projectOwnerTable-body">
                                            {task?.status === 'Active' ? (
                                                <span style={{ color: 'green', fontWeight: 'bold' }}>{task?.status}</span>
                                            ) : (
                                                <span style={{ color: 'red', fontWeight: 'bold' }}>{task?.status}</span>
                                            )}
                                        </b></StyledTableCellBody>
                                    </TableRow>
                                ))
                            ) : (
                                <TableRow style={{ border: "none" }}>
                                    <StyledTableCellBody colSpan={6} style={{ textAlign: 'center', fontSize: '16px', color: "grey", height: "45px", border: "none" }}>
                                        No tasks available
                                    </StyledTableCellBody>
                                </TableRow>
                            )}
                        </TableBody>

                    </Table>
                </TableContainer>
                 <Box
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                margin: "auto",
                                pt: 2,
                            }}
                        >
                            <Pagination
                                style={{
                                    textAlign: "center",
                                    justifyContent: "center",
                                    margin: "auto",
                                    pt: 2,
                                }}
                                count={
                                    (count / 10) % 1 == 0
                                        ? Math.floor(count / 10)
                                        : Math.floor(count / 10 + 1)
                                }
                                page={offset}
                                siblingCount={1}
                                onChange={(event, value) => {
                                    handlePagination(value);
                                }}
                                color="primary"
                            />
                        </Box>
            </Grid>

            <Modal
                open={assignTaskPopup}
                onClose={handleCloseAssignTaskPopUp}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <Box sx={{

                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: "60%",
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    borderRadius: "10px",
                    p: 4,


                }}>
                    <IconButton
                        aria-label="close"
                        onClick={handleCloseAssignTaskPopUp}
                        sx={{
                            position: "absolute",
                            top: 0,
                            right: 0,
                            color: "inherit",
                            zIndex: 1,
                        }}
                    >
                        <CloseIcon />
                    </IconButton>

                    <Grid container alignItems="center" >
                        <Grid item xs>
                            <Typography variant="h6" style={{ color: "#0070E9" }}>
                                Assign Task
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            md={6}
                            sm={6}
                            lg={4}
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'flex-end'
                            }}
                        >
                            <TextField
                                style={{ borderRadius: '5px' }}
                                variant="outlined"
                                placeholder="Search by Task ID, Task Name"
                                fullWidth
                                value={searchValue}
                                onChange={(e) => setSearchValue(e.target.value)}
                                sx={{
                                    '& .MuiInputBase-input': {
                                        alignItems: 'center',
                                        height: '6px',
                                        fontSize: '0.8rem'
                                    },
                                }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <SearchIcon />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                        <Grid
                            style={{ alignSelf: "center", justifyContent: "center" }}
                            item
                            xl={2}
                            lg={2}
                            md={2}
                            sm={12}
                            xs={12}
                        >

                            <FormControl sx={{ m: 1, minWidth: 120 }}>
                                <Select
                                    displayEmpty
                                    style={{
                                        alignItems: "center",
                                        height: "38px",
                                        //   border: "1px solid #8798A9",
                                        color: "grey"
                                    }}
                                    inputProps={{ "aria-label": "Without label" }}
                                    value={filterValue}
                                    onChange={(e) => {
                                        setFilterValue(e.target.value);
                                        setOffset(1);
                                    }}
                                >
                                    <MenuItem value="">TaskId</MenuItem>
                                    <MenuItem value='taskName'>TaskName</MenuItem>
                                </Select>
                            </FormControl>

                        </Grid>
                        {/* <Grid item>
                            <IconButton onClick={handleCloseAssignTaskPopUp}>
                                <CloseIcon />
                            </IconButton>
                        </Grid> */}
                    </Grid>
                    <Grid container spacing={2} sx={{ pt: 0, pl: 4, pr: 3, mt: 2 }}>

                        <TableContainer component={Paper} sx={{
                            boxShadow: 'none',
                            border: 1,
                            borderColor: 'grey.300',
                            maxHeight: '50vh',  // Set maximum height for the table container
                            overflow: 'auto'
                        }}>
                            <Table aria-label="collapsible table">
                                <TableHead>
                                    <TableRow>
                                        
                                        <StyledTableCell padding="checkbox">
                                            <BlueCheckbox
                                                checked={selectAll}
                                                onChange={() => handleSelectAll('Create')} />

                                        </StyledTableCell >
                                        <StyledTableCell ><b className='projectOwnertable-header' >S no</b></StyledTableCell >
                                        <StyledTableCell ><b className='projectOwnertable-header'>Task Id</b></StyledTableCell >
                                        <StyledTableCell ><b className='projectOwnertable-header'>Task Name</b></StyledTableCell >
                                        <StyledTableCell ><b className='projectOwnertable-header'>Cost Center</b></StyledTableCell >
                                        <StyledTableCell ><b className='projectOwnertable-header'>Status</b></StyledTableCell >
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {taskTypeList && taskTypeList.length > 0 ? (
                                        taskTypeList.map((taskType, index) => (
                                            <TableRow key={taskType?.taskId} className="tablebody-tablerowmain">
                                                <StyledTableCellBody padding="checkbox" >
                                                    <BlueCheckbox
                                                        checked={selectedTasks.has(taskType?.taskId)}
                                                        onChange={() => handleSelection(taskType?.taskId, 'Create')}
                                                        inputProps={{ 'aria-label': 'controlled' }}
                                                    />
                                                </StyledTableCellBody >
                                                <StyledTableCellBody ><b className='projectTaskTable-body'>{((offset - 1) * 10) + index + 1}</b></StyledTableCellBody >
                                                <StyledTableCellBody ><b className='projectTaskTable-body'>{taskType?.taskCode}</b></StyledTableCellBody >
                                                <StyledTableCellBody ><b className='projectTaskTable-body' >{taskType?.taskName}</b></StyledTableCellBody >
                                                <StyledTableCellBody ><b className='projectTaskTable-body'>{taskType?.costCenter}</b></StyledTableCellBody >
                                                <StyledTableCellBody ><b className='projectTaskTable-body'>
                                                    {taskType?.status === 'Active' ?
                                                        (<span style={{ color: 'green', fontWeight: 'bold' }}>{taskType?.status}</span>) :
                                                        (<span style={{ color: 'red', fontWeight: 'bold' }}>{taskType?.status}</span>)}
                                                </b></StyledTableCellBody >
                                            </TableRow>
                                        ))
                                    ) : (
                                        <TableRow>
                                            <StyledTableCellBody colSpan={6} style={{ textAlign: 'center', fontSize: '16px', color: "grey", height: "45px" }}>
                                                No tasks type available
                                            </StyledTableCellBody>
                                        </TableRow>
                                    )}
                                    { }
                                </TableBody>
                            </Table>

                        </TableContainer>
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                margin: "auto",
                                pt: 2,
                            }}
                        >
                            <Pagination
                                style={{
                                    textAlign: "center",
                                    justifyContent: "center",
                                    margin: "auto",
                                    pt: 2,
                                }}
                                count={
                                    (count / 10) % 1 == 0
                                        ? Math.floor(count / 10)
                                        : Math.floor(count / 10 + 1)
                                }
                                page={offset}
                                siblingCount={1}
                                onChange={(event, value) => {
                                    handlePagination(value);
                                }}
                                color="primary"
                            />
                        </Box>
                    </Grid>
                    {taskTypeList && taskTypeList.length > 0 && (
                        <Grid container spacing={1} sx={{ pl: 2, pr: 5, mt: 2 }}>
                            <Grid item xs={12} sm={2}>
                                <Button className="cancelButton" onClick={handleCloseAssignTaskPopUp}>Cancel</Button>
                            </Grid>
                            <Grid item xs={12} sm={2}>
                                <Button
                                    className="saveButton"
                                    onClick={handleAssignNewTask}>
                                    Save
                                </Button>
                            </Grid>
                        </Grid>
                    )}

                </Box>
            </Modal>

            {/* {taskList && taskList.length > 0 && (
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                    <Pagination
                        count={(count / 10) % 1 === 0 ? Math.floor(count / 10) : Math.floor(count / 10 + 1)}
                        page={offset}
                        siblingCount={1}
                        onChange={(event, value) => {
                            handlePagination(value);
                        }}
                        color="primary"
                    />
                </Box>
            )} */}
        </div>
    );
};

export default TasksTable;
