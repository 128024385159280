import React, { useEffect,useState } from 'react';
import { useNavigate } from "react-router-dom";

import TextField  from '@mui/material/TextField' 
import Grid from '@mui/material/Grid';

import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

import projectRoleAPI from "apis/panel/projectRole/projectRole";
import { toast } from "react-toastify";
import Loader from "components/Loader";
import { Troubleshoot } from '../../../../node_modules/@mui/icons-material/index';
// import { Navigate } from 'react-big-calendar';


const AddNewRole = () => {

 




  
  const { getRoleCreateAPI } = projectRoleAPI();

  const[roleid, setroleid]= useState('');
  const[rolename, setrolename]= useState('');
 
  const[status, setstatus]= useState('');
 
  const[description, setdescription]= useState('');
  const[active, setactive]= useState('');
  const[inactive, setinactive]= useState('');
  const navigate = useNavigate();
  const [loader2, setLoader2] = useState(false)
  const [errorFields, setErrorFields] = useState({
    rolename: false,
    roleid: false,
    rolestatus: false,
   
   
    
  });
  



  


  

  

  const handleprojectnameChange = (e) => {
    setrolename(e.target.value);
  };

 
  const handlestatusChange = (e) => {
    setstatus(e.target.value);
  };

  const handledescriptionChange = (e) => {
    setdescription(e.target.value);
  };
  const handleprojectcodeChange = (e) => {
    setroleid(e.target.value);
  };

  const handleNavigate = (e) => {
    navigate("/panel/projectrole");
  };



  const handlerolecreate = async (e) => {
    setLoader2(true)
   
    e.preventDefault();
    // Validate form fields
    const errors = {};
    if (!rolename) errors.rolename = true;
    if (!roleid) errors.roleid = true;
    if (!status) errors.rolestatus = true;
    // if (!lastName) errors.middleName = true;
   
  //   if (!mothersName) errors.mothersName = true;
  //   // if (!spouseName) errors.spouseName = true;
  //   if (!bloodgroup) errors.bloodgroup = true;
  //   if (!maritalStatus) errors.maritalStatus = true;
  //   if (!businessMail) errors.businessMail = true;
  //   if (!personalMail) errors.personalMail = true;
  //   if (!personalPhone) errors.personalPhone = true;
  //   if (!dateOfBirth) errors.dateOfBirth = true;
  //   if (!gender) errors.gender = true;
  //   if (maritalStatus === 'Married' && !spouseName) {
  //     errors.spouseName = true;
  // }

  

    setErrorFields(errors);

    if (Object.keys(errors).length === 0) {
    
 
  const values = {
    projectRoleName: rolename,
    projectRoleCode: roleid,
    isActive: true,
    projectRoleDescription:description,
    status:status
   
   
   
  };
  
  const data = await getRoleCreateAPI(values);


  if(data.status === 'Ok'){
    navigate("/panel/projectrole");
    setLoader2(false)
    // sessionStorage.setItem('EmployeeID',data.data.employeeId)
    // sessionStorage.setItem('EmployeeCode',data.data.employeeCode)
    return toast.success("Data Saved Successfully", { toast: "Data Saved Successfully" });

  }else{
    setLoader2(false)
    return toast.error("Role ID already exist!", { toast: "Role ID already exist!" });

  }

}
else{
  setLoader2(false)
  return toast.error("Error saving Data", { toast: "Error saving Data" });
}




};


  return (
    <div className="ProjectCont">
       <Loader loading={loader2} />


<Box  sx={{pl:3,pt:3}} className="addnewprojecthead">Add New Role</Box>
           <Grid container spacing={2} sx={{ pl: 7, pr: 7, pb: 3, mt: 2 }} >
        
         
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <Box className="label">Role ID <span style={{ color: "red" }}>*</span></Box>
                <TextField
                  id="permanentAddress"
                  placeholder="Enter Role Id"
                  variant="outlined"
                  fullWidth
                  style={{border:"1.5px solid #B2D4F8"}}
                //   value={houseNo}
                  onChange={handleprojectcodeChange}
                />
                 {errorFields.roleid && <span className="error-msg">This is required Field</span>}
              </Grid>
   
     
              <Grid item xs={12} sm={6} md={6} lg={6} >
                <Box className="label">Role Name<span style={{ color: "red" }}>*</span></Box>
                <TextField
                  id="permanentAddress"
                  placeholder="Enter Role Name"
                  style={{border:"1.5px solid #B2D4F8"}}
                  // Add onChange handler
                  variant="outlined"
                  fullWidth
                //   value={streetName}
                  onChange={handleprojectnameChange}
                  
                />
                 {errorFields.rolename && <span className="error-msg">This is required Field</span>}
              </Grid>
       
                <Grid item xs={12} sm={6} md={6} lg={6} sx={{mt:"20px"}}>
                <Box className="label">Role Status <span style={{ color: "red" }}>*</span></Box>
                <FormControl fullWidth>
                  <Select
                    id="permanentState"
                    defaultValue=""
                    placeholder="Select status"
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                    style={{ border: "1.5px solid #B2D4F8", color: "grey" }}
                    // value={selectedState}
                    onChange={handlestatusChange}
                  >
                    

                    <MenuItem value="">
                      Select Status
                    </MenuItem>
                    <MenuItem value="Active">Active</MenuItem>
                    <MenuItem value="Inactive">InActive</MenuItem>
                  </Select>
                </FormControl>
                {errorFields.rolestatus && <span className="error-msg">This is required Field</span>}
              </Grid>

       

        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12}  sx={{pl:7, pr:7,}}>
                <Box className="label">Description </Box>
                <TextField
                  id="permanentAddress"
                  placeholder="Enter Description"
                  variant="outlined"
                  sx={{
                    "& .MuiInputBase-input": {
                      height: "90px",
                      border: "1.5px solid #B2D4F8"
                    },
                  }}
                  fullWidth
                //   value={streetName}
                      onChange={handledescriptionChange}
                />
                
              </Grid>


              
<Grid container  sx={{ pl: 4, pr:5, mt:5 , justifyContent:"center"}} >

        <Grid item xs={12} sm={2} md={2} lg={2}>
        <Button className="cancelButton" onClick={handleNavigate} >Cancel</Button>
        </Grid>

        <Grid item  sx={{mr:1}} xs={12} sm={2} md={2} lg={2}>
        <Button className="saveButton" onClick={handlerolecreate}>Save</Button>
        </Grid>

        </Grid>
        
    </div>
  )
}

export default AddNewRole;