// custom pages
import config from "config/config";
import HandleApiResponse from "utils/handleApiResponse";

const leaveManagementAPI = () => {
  const { handleResponse, handleError } = HandleApiResponse();


  async function getAllPublicHolidaysAPI(values) {
    try {
      const obj = {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      };
      const query = JSON.stringify(values);
      const res = await fetch(
        `${config.host}/panel/leave-management/holidays?query=${query}`,
        obj
      );
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }



  async function getAllLeaveTypesAPI() {
    try {
      const obj = {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      };
    
      const res = await fetch(
        `${config.host}/panel/leave-management/leave-types`,
        obj
      );
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }

  async function createLeaveRequestAPI(value) {
    try {
      const obj = {
        method: "Post",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(value),
      };
      let res = await fetch(`${config.host}/panel/leave-management/leave-request`, obj);
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }

  async function ManagerGetAllLeaveRequestsAPI(value) {
    try {
      const obj = {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      
      };
      const query= JSON.stringify(value)
      let res = await fetch(`${config.host}/panel/leave-management/manager/leave-requests?query=${query}`, obj);
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }

  async function ManagerUpdateLeaveRequestAPI(value) {
    try {
      const obj = {
        method: "PUT",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(value),
      };
      let res = await fetch(`${config.host}/panel/leave-management/manager/leave-requests`, obj);
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }


  async function GetAllLeavesStatusAPI(value) {
    try {
      const obj = {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      
      };
      const query= JSON.stringify(value)
      let res = await fetch(`${config.host}/panel/leave-management/leave-requests?query=${query}`, obj);
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }


  async function withdrawLeaveRequestAPI(value) {
    try {
      const obj = {
        method: "Post",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(value),
      };
      let res = await fetch(`${config.host}/panel/leave-management/leave-request/withdraw`, obj);
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }


  async function getAllPublicHolidaysYearAPI(values) {
    try {
      const obj = {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      };
      const query = JSON.stringify(values);
      const res = await fetch(
        `${config.host}/panel/leave-management/holidays/year?query=${query}`,
        obj
      );
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }

  async function getTotalLeavesTakenYearlyAPI(values) {
    try {
      const obj = {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      };
      const query = JSON.stringify(values);
      let res = await fetch(`${config.host}/panel/leave-management/leaves-summary?query=${query}`, obj);
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }


  async function getLeavesNumberWithType(values) {
    try {
      const obj = {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      };
      const query = JSON.stringify(values);
      let res = await fetch(`${config.host}/panel/leave-management/leaves?query=${query}`, obj);
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }


  

  

  return {
    getAllPublicHolidaysAPI,
    getAllLeaveTypesAPI,
    createLeaveRequestAPI,
    ManagerGetAllLeaveRequestsAPI,
    ManagerUpdateLeaveRequestAPI,
    GetAllLeavesStatusAPI,
    withdrawLeaveRequestAPI,
    getAllPublicHolidaysYearAPI,
    getTotalLeavesTakenYearlyAPI,
    getLeavesNumberWithType

  };
};

export default leaveManagementAPI;
