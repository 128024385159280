import React from 'react';

import "./AddEmployee.css"
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import contactInfo from './ContactInfo';
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import personalInfo from "assets/images/Group (1).png";
import ContactInfo from './ContactInfo';
import EducationInfo from './EducationInfo';
import DocumentInfo from './Documentinfo';
import NumberInfo from './NumberInfo';
import InsuranceInfo from './InsuranceInfo';
import BankInfo from './BankInfo';
import EmployementInfo from './EmployementInfo';
import ManagerInfo from './ManagerInfo';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useState, useEffect } from "react";
import getAllGenderdrop from"apis/panel/AddEmployee/addEmployee";
import getAllTitlesdrop from "apis/panel/AddEmployee/addEmployee";
import getMaritalstatusAPIdrop from "apis/panel/AddEmployee/addEmployee"
import AddEmployee from './AddEmployee';
import ProjectInfo from './ProjectInfo';

const MainInfo = () => {
  return (
    <div className='mainInfoCont'>
      <AddEmployee/>
      <ContactInfo/>
      <EducationInfo/>
      <DocumentInfo/>
      <NumberInfo/>
      <BankInfo/>
      <InsuranceInfo/>
      <EmployementInfo/>
      <ManagerInfo/>
      <ProjectInfo/>        
    </div>
  )
}

export default MainInfo;