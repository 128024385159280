// custom pages
import config from "config/config";
import HandleApiResponse from "utils/handleApiResponse";

const ConfigurationAPI = () => {
  const { handleResponse, handleError } = HandleApiResponse();

  async function getAllTitleAPI(values) {
    try {
      const obj = {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      };
      const query = JSON.stringify(values);
      let res = await fetch(`${config.host}/panel/title/findAllMaster?query=${query}`, obj);
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }

  async function getCreateTitleAPI(data) {
    try {
      const obj = {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      };
      let res = await fetch(`${config.host}/panel/title/create`, obj);
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }

  async function getUpdateTitleAPI(data) {
   
    try {
      const obj = {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      };
     
      let res = await fetch(`${config.host}/panel/title/update`, obj);
      return handleResponse(res);
    } catch (err) {
      
      handleError(err);
    }
  }

  async function getDeleteTitleAPI(data) {
    try {
      const obj = {
        method: "DELETE",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      };
      let res = await fetch(`${config.host}/panel/title/delete/${data}`, obj);
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }

  async function getAllCountryAPI() {
    try {
      const obj = {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      };
      let res = await fetch(`${config.host}/panel/countries/findAll`, obj);
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }

  async function getCreateCountriesAPI(data) {
    try {
      const obj = {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      };
      let res = await fetch(`${config.host}/panel/countries/create`, obj);
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }

  async function getUpdateCountriesAPI(data) {
    try {
      const obj = {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      };
      let res = await fetch(`${config.host}/panel/countries/update`, obj);
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }

  async function getDeleteCountriesAPI(data) {
    try {
      const obj = {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      };
      let res = await fetch(
        `${config.host}/panel/countries/delete/${data}`,
        obj
      );
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }

  async function getAllGenderAPI(values) {
    try {
      const obj = {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      };
      const query = JSON.stringify(values);
      let res = await fetch(`${config.host}/panel/gender/findAllMaster?query=${query}`, obj);
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }

  async function getCreateGenderAPI(data) {
    try {
      const obj = {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      };
      let res = await fetch(`${config.host}/panel/gender/create`, obj);
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }

  async function getUpdateGenderAPI(data) {
    try {
      const obj = {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      };
      let res = await fetch(`${config.host}/panel/gender/update`, obj);
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }

  async function getDeleteGenderAPI(data) {
    try {
      const obj = {
        method: "DELETE",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      };

      let res = await fetch(`${config.host}/panel/gender/delete/${data}`, obj);
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }

  async function getAllCertificatesAPI(values) {
    try {
      const obj = {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      };
      const query = JSON.stringify(values);
      let res = await fetch(`${config.host}/panel/certificates/findAllMaster?query=${query}`, obj);
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }

  async function getCreateCertificatesAPI(data) {
    try {
      const obj = {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      };
      let res = await fetch(`${config.host}/panel/certificates/create`, obj);
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }

  async function getUpdateCertificatesAPI(data) {
    try {
      const obj = {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      };
      let res = await fetch(`${config.host}/panel/certificates/update`, obj);
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }

  async function getDeleteCertificatesAPI(data) {
    try {
      const obj = {
        method: "DELETE",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      };
      let res = await fetch(
        `${config.host}/panel/certificates/delete/${data}`,
        obj
      );
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }

  async function getAllDocumentsAPI(values) {
    try {
      const obj = {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      };
      const query = JSON.stringify(values);
      let res = await fetch(`${config.host}/panel/documents/findAllMaster?query=${query}`, obj);
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }

  async function getCreateDocumentsAPI(data) {
    try {
      const obj = {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      };
      let res = await fetch(`${config.host}/panel/documents/create`, obj);
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }

  async function getUpdateDocumentsAPI(data) {
    try {
      const obj = {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      };
      let res = await fetch(`${config.host}/panel/documents/update`, obj);
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }

  async function getDeleteDocumentsAPI(data) {
    try {
      const obj = {
        method: "DELETE",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      };
      let res = await fetch(
        `${config.host}/panel/documents/DELETE/${data}`,
        obj
      );
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }

  async function getAllEmploymentTypesAPI(values) {
    try {
      const obj = {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      };
      const query = JSON.stringify(values);
      let res = await fetch(
        `${config.host}/panel/employmentTypes/findAllMaster?query=${query}`,
        obj
      );
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }

  async function getCreateemploymentTypesAPI(data) {
    try {
      const obj = {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      };
      let res = await fetch(`${config.host}/panel/employmentTypes/create`, obj);
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }

  async function getUpdateemploymentTypesAPI(data) {
    try {
      const obj = {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      };
      let res = await fetch(`${config.host}/panel/employmentTypes/update`, obj);
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }

  async function getDeleteemploymentTypesAPI(data) {
    try {
      const obj = {
        method: "DELETE",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      };
      let res = await fetch(
        `${config.host}/panel/employmentTypes/delete/${data}`,
        obj
      );
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }

  async function getAllWorkLocationsAPI(values) {
    try {
      const obj = {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      };
      const query = JSON.stringify(values);
      let res = await fetch(`${config.host}/panel/workLocations/findAllMaster?query=${query}`, obj);
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }

  async function getCreateworkLocationsAPI(data) {
    try {
      const obj = {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      };
      let res = await fetch(`${config.host}/panel/workLocations/create`, obj);
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }

  async function getUpdateworkLocationsAPI(data) {
    try {
      const obj = {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      };
      let res = await fetch(`${config.host}/panel/workLocations/update`, obj);
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }

  async function getDeleteworkLocationsAPI(data) {
    try {
      const obj = {
        method: "DELETE",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      };
      let res = await fetch(
        `${config.host}/panel/workLocations/delete/${data}`,
        obj
      );
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }

  async function getAllExperiencesAPI(values) {
    try {
      const obj = {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      };
      const query = JSON.stringify(values);
      let res = await fetch(`${config.host}/panel/experiences/findAllMaster?query=${query}`, obj);
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }

  async function getCreateexperiencesAPI(data) {
    try {
      const obj = {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      };
      let res = await fetch(`${config.host}/panel/experiences/create`, obj);
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }

  async function getUpdateexperiencesAPI(data) {
    try {
      const obj = {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      };
      let res = await fetch(`${config.host}/panel/experiences/update`, obj);
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }

  async function getDeleteexperiencesAPI(data) {
    try {
      const obj = {
        method: "DELETE",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      };
      let res = await fetch(
        `${config.host}/panel/experiences/delete/${data}`,
        obj
      );
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }

  async function getAllStatesAPI() {
    try {
      const obj = {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      };
      let res = await fetch(`${config.host}/panel/states/findAll`, obj);
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }

  async function getCreatestatesAPI(data) {
    try {
      const obj = {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      };
      let res = await fetch(`${config.host}/panel/states/create`, obj);
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }

  async function getUpdatestatesAPI(data) {
    try {
      const obj = {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      };
      let res = await fetch(`${config.host}/panel/states/update`, obj);
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }

  async function getDeletestatesAPI(data) {
    try {
      const obj = {
        method: "DELETE",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      };
      let res = await fetch(`${config.host}/panel/states/delete/${data}`, obj);
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }

  async function getAllCitiesAPI(limits) {
    try {
      const obj = {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      };
      let limit = JSON.stringify(limits);
      let res = await fetch(
        `${config.host}/panel/cities/findAll?limits=${limit}`,
        obj
      );
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }

  async function getCreatecitiesAPI(data) {
    try {
      const obj = {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      };
      let res = await fetch(`${config.host}/panel/cities/create`, obj);
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }

  async function getUpdatecitiesAPI(data) {
    try {
      const obj = {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      };
      let res = await fetch(`${config.host}/panel/cities/update`, obj);
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }

  async function getDeletecitiesAPI(data) {
    try {
      const obj = {
        method: "DELETE",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      };
      let res = await fetch(`${config.host}/panel/cities/delete/${data}`, obj);
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }

  async function getAllDegreesAPI(values) {
    try {
      const obj = {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      };
      const query = JSON.stringify(values);
      let res = await fetch(`${config.host}/panel/degrees/findAllMaster?query=${query}`, obj);
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }

  async function getCreateDegreesAPI(data) {
    try {
      const obj = {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      };
      let res = await fetch(`${config.host}/panel/degrees/create`, obj);
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }

  async function getUpdatedegreesAPI(data) {
    try {
      const obj = {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      };
      let res = await fetch(`${config.host}/panel/degrees/update`, obj);
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }

  async function getDeletedegreesAPI(data) {
    try {
      const obj = {
        method: "DELETE",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      };
      let res = await fetch(`${config.host}/panel/degrees/delete/${data}`, obj);
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }

  async function getAllBloodGroupsAPI(values) {
    try {
      const obj = {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      };
      const query = JSON.stringify(values);
      let res = await fetch(`${config.host}/panel/bloodgroups/findAllMaster?query=${query}`, obj);
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }

  async function getCreatebloodgroupsAPI(data) {
    try {
      const obj = {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      };
      let res = await fetch(`${config.host}/panel/bloodgroups/create`, obj);
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }

  async function getUpdatebloodgroupsAPI(data) {
    try {
      const obj = {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      };
      let res = await fetch(`${config.host}/panel/bloodgroups/update`, obj);
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }

  async function getDeletebloodgroupsAPI(data) {
    try {
      const obj = {
        method: "DELETE",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      };
      let res = await fetch(
        `${config.host}/panel/bloodgroups/delete/${data}`,
        obj
      );
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }

  async function getAllMaritalStatusAPI(values) {
    try {
      const obj = {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      };
      let value= {
        offset: 0,
        limit: 10,
    };
      values?value=values:value= {
          offset: 0,
          limit: 10,
      };
      const query = JSON.stringify(value);
      let res = await fetch(`${config.host}/panel/marital_status/findAllMaster?query=${query}`, obj);
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }

  async function getCreatemaritalstatusAPI(data) {
    try {
      const obj = {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      };
      let res = await fetch(`${config.host}/panel/marital_status/create`, obj);
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }

  async function getUpdatemaritalstatusAPI(data) {
    try {
      const obj = {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      };
      let res = await fetch(`${config.host}/panel/marital_status/update`, obj);
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }

  async function getDeletemaritalstatusAPI(data) {
    try {
      const obj = {
        method: "DELETE",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      };
      let res = await fetch(
        `${config.host}/panel/marital_status/delete/${data}`,
        obj
      );
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }

  async function getAllDesignationsAPI(values) {
    try {
      const obj = {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      };
      const query = JSON.stringify(values);
      let res = await fetch(`${config.host}/panel/designations/findAllMaster?query=${query}`, obj);
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }

  async function getCreatedesignationsAPI(data) {
    try {
      const obj = {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      };
      let res = await fetch(`${config.host}/panel/designations/create`, obj);
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }

  async function getUpdatedesignationsAPI(data) {
    try {
      const obj = {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      };
      let res = await fetch(`${config.host}/panel/designations/update`, obj);
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }

  async function getDeletedesignationsAPI(data) {
    try {
      const obj = {
        method: "DELETE",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      };
      let res = await fetch(
        `${config.host}/panel/designations/delete/${data}`,
        obj
      );
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }

  async function getAllRelationshipAPI(values) {
    try {
      const obj = {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      };
      const query = JSON.stringify(values);
      let res = await fetch(`${config.host}/panel/relationship/findAllMaster?query=${query}`, obj);
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }

  async function getCreaterelationshipAPI(data) {
    try {
      const obj = {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      };
      let res = await fetch(`${config.host}/panel/relationship/create`, obj);
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }

  async function getUpdaterelationshipAPI(data) {
    try {
      const obj = {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      };
      let res = await fetch(`${config.host}/panel/relationship/update`, obj);
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }

  async function getDeleterelationshipAPI(data) {
    try {
      const obj = {
        method: "DELETE",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      };
      let res = await fetch(
        `${config.host}/panel/relationship/delete/${data}`,
        obj
      );
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }

  async function getAllDepartmentsAPI(values) {
    try {
      const obj = {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      };
      const query = JSON.stringify(values);
      let res = await fetch(`${config.host}/panel/departments/findAllMaster?query=${query}`, obj);
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }

  async function getCreatedepartmentsAPI(data) {
    try {
      const obj = {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      };
      let res = await fetch(`${config.host}/panel/departments/create`, obj);
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }

  async function getUpdatedepartmentsAPI(data) {
    try {
      const obj = {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      };
      let res = await fetch(`${config.host}/panel/departments/update`, obj);
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }

  async function getDeletedepartmentsAPI(data) {
    try {
      const obj = {
        method: "DELETE",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      };
      let res = await fetch(
        `${config.host}/panel/departments/delete/${data}`,
        obj
      );
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }

  async function getAllBanksAPI(values) {
    try {
      const obj = {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      };
      const query = JSON.stringify(values);
      let res = await fetch(`${config.host}/panel/banks/findAllMaster?query=${query}`, obj);
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }

  async function getCreatebanksAPI(data) {
    try {
      const obj = {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      };
      let res = await fetch(`${config.host}/panel/banks/create`, obj);
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }

  async function getUpdatebanksAPI(data) {
    try {
      const obj = {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      };
      let res = await fetch(`${config.host}/panel/banks/update`, obj);
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }

  async function getDeletebanksAPI(data) {
    try {
      const obj = {
        method: "DELETE",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      };
      let res = await fetch(`${config.host}/panel/banks/delete/${data}`, obj);
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }

  async function getAllBanksBranchesAPI(values) {
    try {
      const obj = {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      };
      const query = JSON.stringify(values);
      let res = await fetch(`${config.host}/panel/bankbranches/findAllMaster?query=${query}`, obj);
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }

  async function getCreatebankBranchesAPI(data) {
    try {
      const obj = {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      };
      let res = await fetch(`${config.host}/panel/bankBranches/create`, obj);
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }

  async function getUpdatebankBranchesAPI(data) {
    try {
      const obj = {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      };
      let res = await fetch(`${config.host}/panel/bankBranches/update`, obj);
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }

  async function getDeletebankBranchesAPI(data) {
    try {
      const obj = {
        method: "DELETE",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      };
      let res = await fetch(
        `${config.host}/panel/bankBranches/delete/${data}`,
        obj
      );
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }

  async function getAllrolesAPI(values) {
    try {
      const obj = {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      };
      const query = JSON.stringify(values);
      let res = await fetch(`${config.host}/panel/roles/findAllMaster?query=${query}`, obj);
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }

  async function getCreaterolesAPI(data) {
    try {
      const obj = {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      };
      let res = await fetch(`${config.host}/panel/roles/create`, obj);
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }

  async function getUpdaterolesAPI(data) {
    try {
      const obj = {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      };
      let res = await fetch(`${config.host}/panel/roles/update`, obj);
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }

  async function getDeleteRolesrAPI(data) {
    try {
      const obj = {
        method: "DELETE",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      };
      let res = await fetch(`${config.host}/panel/roles/delete/${data}`, obj);
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }

  async function getAllProjectAPI(values) {
    try {
      const obj = {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      };
      const query = JSON.stringify(values);
      let res = await fetch(`${config.host}/panel/projectTypes/findAllMaster?query=${query}`, obj);
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }

  async function getUpdateProjectAPI(data) {
    try {
      const obj = {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      };
      let res = await fetch(`${config.host}/panel/projectTypes/update`, obj);
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }

  async function getCreateprojectAPI(data) {
    try {
      const obj = {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      };
      let res = await fetch(`${config.host}/panel/projectTypes/create`, obj);
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }

  async function getDeleteProjectAPI(data) {
    try {
      const obj = {
        method: "DELETE",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      };
      let res = await fetch(
        `${config.host}/panel/projectTypes/delete/${data}`,
        obj
      );
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }

  async function getAllMasterCitiesAPI(values) {
    try {
      const obj = {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      };
      const query = JSON.stringify(values);
      let res = await fetch(`${config.host}/panel/cities/findAllMaster?query=${query}`, obj);
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }

  async function getAllMasterStatesAPI(values) {
    try {
      const obj = {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      };
      const query = JSON.stringify(values);
      let res = await fetch(`${config.host}/panel/states/findAllMaster?query=${query}`, obj);
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }

  async function getAllMasterCountriesAPI(values) {
    try {
      const obj = {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      };
      const query = JSON.stringify(values);
      let res = await fetch(`${config.host}/panel/countries/findAllMaster?query=${query}`, obj);
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }

/////////////////////////////////// Settings APIs ////////////////////////

  async function getAllSettingsAPI(values) {
    try {
      const obj = {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      };
      const query = JSON.stringify(values);
      let res = await fetch(`${config.host}/panel/settings/findAllMaster?query=${query}`, obj);
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }

  async function getCreateSettingsAPI(data) {
    try {
      const obj = {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      };
      let res = await fetch(`${config.host}/panel/settings/create`, obj);
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }

  async function getUpdateSettingsAPI(data) {
   
    try {
      const obj = {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      };
     
      let res = await fetch(`${config.host}/panel/settings/update`, obj);
      return handleResponse(res);
    } catch (err) {
      
      handleError(err);
    }
  }

  async function getDeleteSettingsAPI(data) {
    try {
      const obj = {
        method: "DELETE",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      };
      let res = await fetch(`${config.host}/panel/settings/delete/${data}`, obj);
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }



  //////////////////////////////// Permissions APIs ///////////////////////////////


  async function getAllPermissionsAPI(values) {
    try {
      const obj = {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      };
      const query = JSON.stringify(values);
      let res = await fetch(`${config.host}/panel/permissions/findAllMaster?query=${query}`, obj);
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }

  async function getCreatePermissionsAPI(data) {
    try {
      const obj = {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      };
      let res = await fetch(`${config.host}/panel/permissions/create`, obj);
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }

  async function getUpdatePermissionsAPI(data) {
   
    try {
      const obj = {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      };
     
      let res = await fetch(`${config.host}/panel/permissions/update`, obj);
      return handleResponse(res);
    } catch (err) {
      
      handleError(err);
    }
  }

  async function getDeletePermissionsAPI(data) {
    try {
      const obj = {
        method: "DELETE",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      };
      let res = await fetch(`${config.host}/panel/permissions/delete/${data}`, obj);
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }


////////////////////////////////// Holidays APIs ///////////////////////////////////

async function getAllHolidaysAPI(values) {
  try {
    const obj = {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    };
    const query = JSON.stringify(values);
    let res = await fetch(`${config.host}/panel/holidays/findAll?query=${query}`, obj);
    return handleResponse(res);
  } catch (err) {
    handleError(err);
  }
}

async function getCreateHolidaysAPI(data) {
  try {
    const obj = {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    };
    let res = await fetch(`${config.host}/panel/holidays/create`, obj);
    return handleResponse(res);
  } catch (err) {
    handleError(err);
  }
}

async function getUpdateHolidaysAPI(data) {
   
  try {
    const obj = {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    };
   
    let res = await fetch(`${config.host}/panel/holidays/update`, obj);
    return handleResponse(res);
  } catch (err) {
    
    handleError(err);
  }
}





  return {
    getAllMasterCountriesAPI,
    getAllMasterCitiesAPI,
    getAllMasterStatesAPI,
    getAllTitleAPI,
    getCreateTitleAPI,
    getUpdateTitleAPI,
    getDeleteTitleAPI,
    getAllCountryAPI,
    getAllGenderAPI,
    getUpdateGenderAPI,
    getDeleteGenderAPI,
    getAllCertificatesAPI,
    getCreateCertificatesAPI,
    getDeleteCertificatesAPI,
    getAllDocumentsAPI,
    getUpdateDocumentsAPI,
    getDeleteDocumentsAPI,
    getAllEmploymentTypesAPI,
    getUpdateemploymentTypesAPI,
    getDeleteemploymentTypesAPI,
    getAllWorkLocationsAPI,
    getUpdateworkLocationsAPI,
    getDeleteworkLocationsAPI,
    getAllStatesAPI,
    getAllDegreesAPI,
    getAllBloodGroupsAPI,
    getAllMaritalStatusAPI,
    getAllDesignationsAPI,
    getAllRelationshipAPI,
    getAllDepartmentsAPI,
    getAllCitiesAPI,
    getAllExperiencesAPI,
    getAllBanksAPI,
    getAllBanksBranchesAPI,
    getCreateCountriesAPI,
    getCreateGenderAPI,
    getCreateDocumentsAPI,
    getCreateemploymentTypesAPI,
    getCreateworkLocationsAPI,
    getCreateexperiencesAPI,
    getCreatestatesAPI,
    getCreatecitiesAPI,
    getCreateDegreesAPI,
    getCreatebloodgroupsAPI,
    getCreatemaritalstatusAPI,
    getCreatedesignationsAPI,
    getCreaterelationshipAPI,
    getCreatedepartmentsAPI,
    getCreatebanksAPI,
    getCreatebankBranchesAPI,
    getUpdateCountriesAPI,
    getDeleteCountriesAPI,
    getUpdateCertificatesAPI,
    getUpdateexperiencesAPI,
    getDeleteexperiencesAPI,
    getUpdatedegreesAPI,
    getDeletedegreesAPI,
    getDeletebloodgroupsAPI,
    getUpdatebloodgroupsAPI,
    getUpdatemaritalstatusAPI,
    getDeletemaritalstatusAPI,
    getDeletedesignationsAPI,
    getUpdatedesignationsAPI,
    getDeleterelationshipAPI,
    getUpdaterelationshipAPI,
    getUpdatedepartmentsAPI,
    getDeletedepartmentsAPI,
    getDeletebanksAPI,
    getUpdatebanksAPI,
    getUpdatebankBranchesAPI,
    getDeletebankBranchesAPI,
    getUpdatestatesAPI,
    getAllrolesAPI,
    getCreaterolesAPI,
    getUpdaterolesAPI,
    getDeleteRolesrAPI,
    getDeletestatesAPI,
    getDeletecitiesAPI,
    getUpdatecitiesAPI,
    getAllProjectAPI,
    getUpdateProjectAPI,
    getCreateprojectAPI,
    getDeleteProjectAPI,
    getAllSettingsAPI,
    getCreateSettingsAPI,
    getUpdateSettingsAPI,
    getDeleteSettingsAPI,
    getAllPermissionsAPI,
    getCreatePermissionsAPI,
    getUpdatePermissionsAPI,
    getDeletePermissionsAPI,
  
    getAllHolidaysAPI,
    getCreateHolidaysAPI,
    getUpdateHolidaysAPI

  };
};
export default ConfigurationAPI;
