import React, { useState, useEffect, useContext } from 'react';
import { useParams } from "react-router-dom";
import "./EditAddEmployee.css"
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import personalInfo from "assets/images/Layer_1 (3).png";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import * as moment from "moment";
import { toast } from "react-toastify";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import projectAPI from 'apis/panel/project/project';
import projectRoleAPI from 'apis/panel/projectRole/projectRole';
import Loader from "components/Loader";
import Context from "context/context";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const EditProjectInfo = (props) => {
    const { state } = useContext(Context);
    const [roleCode] = useState(state.role.roleCode);
    
    let params = useParams();
    const employeeId = params.id;
    
    const { getAllProjects, getprojectfindone, createEmployeeProject } = projectAPI();
    const { getAllProjectRoles } = projectRoleAPI();

    const [loader2, setLoader2]= useState(false);
    const [expanded, setExpanded] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false);

    const [projectInfo, setProjectInfo] = useState();
    // const [projectArray, setProjectArray] = useState();

    const [allProjects, setAllProjects] = useState([]);
    const [allProjectRoles, setAllProjectRoles] = useState([]); 
    const [removedProjects, setRemovedProjects] = useState([]); 

    const [selectedProjects, setSelectedProjects] = useState([]);
    const [projectArray, setProjectArray] = useState([]);


    const [handleDialog, setHandleDialog] = useState({
        dialog:false,
        index:null
    }); 

    useEffect(() => {
        handleGetAllProjectApi();
        handleGetAllProjectRoleApi();      
    }, [])

    useEffect(() => {
        // Populate selectedProjects with initial project IDs if editing existing data
        setSelectedProjects(projectArray.filter(p => p.projectId).map(p => p.projectId));
    }, [projectArray]);

    useEffect(() => {
        let employeeDatas = state.employeeData;
        if(employeeDatas){
            let employeeProjects = employeeDatas?.projects;
            if(employeeProjects?.length>0){
                let projectArray = [];
                let projectInfo = [];
                for (let index = 0; index < employeeProjects.length; index++) {
                    const element = employeeProjects[index];
                    projectArray.push({
                        id: index+1,
                        projectId: element.projectId,
                        projectName: element.projects.projectName,
                        projectCode: element.projects.projectCode,
                        projectRoleId: element.projectRoleId,
                        projectRoleName: element.projectRoles.projectRoleName,
                        percentAllocation: element.percentAllocation,
                        startDate: element.startDate,
                        endDate: element.endDate,
                        status: element.status,
                        employeeProjectId:element.employeeProjectId
                    })
                    projectInfo.push({
                        id: index+1
                    })
                }
                setProjectArray(projectArray);
                setProjectInfo(projectInfo);                
            }
            else{
                setProjectInfo([{id: 1}]);
                setProjectArray([{id: 1}]);
            }
        }
    }, [state?.employeeData])

    const handleAddSection = () => {
        const newId = projectArray.length > 0 ? projectArray[projectArray.length - 1].id + 1 : 1;
        setProjectInfo(prevState => [...prevState, { id: newId }]);
        setProjectArray(prev => [...prev, { id: newId }]);
    };
    
    const handleRemoveSection = (indexToRemove) => {
        const updatedProjectArray = projectArray.filter((_, index) => index !== indexToRemove);
        const removedProjectId = projectArray[indexToRemove]?.projectId;
    
        if (projectArray[indexToRemove]?.employeeProjectId) {
            setRemovedProjects([...removedProjects, { ...projectArray[indexToRemove], status: "Inactive" }]);
        }
    
        setProjectInfo(updatedProjectArray);
        setProjectArray(updatedProjectArray);
    
        if (removedProjectId) {
            const newSelectedProjects = updatedProjectArray.filter(p => p.projectId).map(p => p.projectId);
            setSelectedProjects(newSelectedProjects);
        }
    
        setHandleDialog({
            dialog: false,
            index: null
        });
    };
    

    const handleGetAllProjectApi = async () => {
        const data = await getAllProjects();
        setAllProjects(data.data);
    }

    const handleGetAllProjectRoleApi = async () => {
        const data = await getAllProjectRoles();
        setAllProjectRoles(data.data)
    }
  
    const toggleEditMode = (event) => {
        event.stopPropagation();
        setIsEditMode(!isEditMode);
    };

    const handleAccordionChange = (event, isExpanded) => {
        setExpanded(isExpanded);
        if (!isExpanded) {
            setIsEditMode(false);
        }
    };

    const handleProjects = async (projectId, sectionId) => {
        // Fetch additional project details if needed
        const projects = await getprojectfindone(projectId);
        const updatedArray = projectArray.map((p) =>
            p.id === sectionId ? {
                ...p,
                projectId: projectId,
                projectName: allProjects.find(option => option.projectId === projectId).projectName,
                projectCode: projects.data.projectCode
            } : p
        );
        const newSelectedProjects = updatedArray.filter(p => p.projectId).map(p => p.projectId);
        setProjectArray(updatedArray);
        setSelectedProjects(newSelectedProjects);
    }
    
    
    const handlePercentAllocation = (percentAllocation, sectionId) => {
        const updatedarr = projectArray.map((p) =>
            p.id == sectionId ? {...p,
                percentAllocation: percentAllocation
            } : p
        )
        setProjectArray(updatedarr);
    };

    const handleProjectRoles = async(projectRoleId, sectionId) => {
        const updatedarr = projectArray.map((p) =>
            p.id == sectionId ? {...p,
                projectRoleId: projectRoleId,
                projectRoleName: allProjectRoles.find(option => option.projectRoleId === projectRoleId).projectRoleName,                
            } : p
        )
        setProjectArray(updatedarr);
    }

    const handleStartDate = async(startDate, sectionId) => {
        const updatedarr = projectArray.map((p) =>
            p.id == sectionId ? {...p,
                startDate: startDate
            } : p
        )
        setProjectArray(updatedarr);
    }

    const handleEndDate = async(endDate, sectionId) => {
        const updatedarr = projectArray.map((p) =>
            p.id == sectionId ? {...p,
                endDate: endDate
            } : p
        )
        setProjectArray(updatedarr);
    }

    const handleProjectStatus = (status, sectionId) => {
        const updatedarr = projectArray.map((p) =>
            p.id == sectionId ? {...p,
                status: status
            } : p
        )
        setProjectArray(updatedarr);
    };
    
    const [errors, setErrors] = useState({
        projectId: false,
        projectRoleId: false,
        percentAllocation: false,
        startDate: false,
        endDate: false,
        status: false
    });

    const handleUpdateProject = async () => {
        setLoader2(true);
        const validationErrors = {};
        if (!projectArray.every(item => item.projectId)) {
        validationErrors.projectId = true;
        }
        if (!projectArray.every(item => item.projectRoleId)) {
        validationErrors.projectRoleId = true;
        }
        if (!projectArray.every(item => item.percentAllocation)) {
        validationErrors.percentAllocation = true;
        }
        if (!projectArray.every(item => item.startDate)) {
        validationErrors.startDate = true;
        }
        if (!projectArray.every(item => item.endDate)) {
        validationErrors.endDate = true;
        }
        if (!projectArray.every(item => item.status)) {
        validationErrors.status = true;
        }
        setErrors(validationErrors);
        
        if (Object.keys(validationErrors).length === 0) {
            let body = {
                "employeeId": employeeId,
                "projectData": [...projectArray,...removedProjects],
            }
            if(body?.projectData && body?.projectData?.length ===0 ){
                setLoader2(false);
                return toast.error("Please add atleast one project.", { toast: "Error saving Data" });
            }
            const data = await createEmployeeProject(body)
            if (data.status === 'Ok') {
                setLoader2(false);
                return toast.success("Data Saved Successfully", { toast: "Data Saved Successfully" });
            } 
            else {
                setLoader2(false);
                return toast.error("Error saving Data", { toast: "Error saving Data" });
            }
        }
        else{
            setLoader2(false)
            return toast.error("Error in validation");
        }
    }

      
    return (
        <div>
            <Loader loading={loader2}/>
            <Accordion sx={{ mt: 2, boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }} onChange={handleAccordionChange}>
                <AccordionSummary
                    expandIcon={<ArrowDropDownIcon className="Accordianicon" />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                    style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px", height: "70px" }}
                >
                    <Box sx={{ pl: 3 }}> <img sx={{ pl: 3 }} className='personalInfo-logo' src={personalInfo} alt="info" /></Box>
                    <Box className="acc-title" sx={{ pl: 3 }}>Project Information</Box>
                    <Box sx={{ ml: 45 }}>
                        {expanded && (roleCode == "R001") && (
                            <button className="editButton" onClick={toggleEditMode}>
                            Edit
                            <Box sx={{ flexGrow: 1 }} />
                            {isEditMode}
                            </button>
                        )}
                    </Box>
                </AccordionSummary>
                <AccordionDetails>
                {isEditMode && (
                            <div>
                            {
                                <Button style={{backgroundColor:"green",margin:"10px 40px", float:"right", color:"#fff", textTransform:"capitalize",fontWeight:"bold"}} onClick={handleAddSection}>Add Project</Button>
                            }
                                
                            </div>
                        )}
                    {projectInfo?.map((section, index) => (
                     <>
                    <Grid container spacing={2} sx={{ pl: 3, pr: 5, mt: 2 }} style={{ display: "flex", justifyContent: "space-between" }} >
                        <Box className="subHeadingforinfos" sx={{ pl: 3 }}>Project Info {index + 1}</Box>
                    {isEditMode&& <Button className="removeSectionButton" style={{ float:"right",textTransform:"capitalize",fontWeight:"bold"}} onClick={() => {
                        if(projectArray[index]?.employeeProjectId){
                            setHandleDialog({dialog:true, index:index})
                        }else{
                            handleRemoveSection(index)
                        }
                    }}>Remove Project</Button>}
                       
                    </Grid>
                    <Grid container spacing={2} sx={{ pl: 4, pr: 5, mt: 2 }} >
                        {isEditMode ? (
                       <Grid item xs={12} sm={6} md={6} lg={6}>
                       <Box className="label">Project Name <span style={{ color: "red" }}>*</span></Box>
                       <FormControl fullWidth>
    <Select
        id="projectId"
        placeholder="Select Project"
        defaultValue=""
        value={projectArray[index].projectId}
        displayEmpty
        inputProps={{ 'aria-label': 'Without label' }}
        style={{ border: "1.5px solid #B2D4F8", color: "grey" }}
        onChange={(e) => {
            handleProjects(e.target.value, section.id);
        }}
    >
        <MenuItem value=""> Select Project </MenuItem>
        {allProjects
        .filter(project => project.status === "Active" || project.projectId === projectArray[index].projectId)
        .filter(project => !selectedProjects.includes(project.projectId) || project.projectId === projectArray[index].projectId)
        .map(project => (
          <MenuItem key={project.projectId} value={project.projectId}>{project.projectName}</MenuItem>
        ))}
    </Select>
</FormControl>

                       {errors.projectId && <div className='error-message'>This field is mandatory.</div>}
                     </Grid>
                        ) : (
                        <Grid item xs={12} sm={4} md={4} lg={4} sx={{ mt: 2 }}>
                            <Box className="editlabel">Project Name</Box>
                            <Box className="editvalue">{projectArray[index].projectName}</Box>
                        </Grid>
                        )}
                        {isEditMode ? (
                        <Grid item xs={12} sm={6} md={6} lg={6}>
                            <Box className="label">Project Code</Box>
                            <TextField 
                                id="projectCode"
                                placeholder="Project Code"
                                value={projectArray[index].projectCode}
                                disabled={true}
                                variant="outlined"
                                fullWidth 
                            />
                        </Grid>
                        ) : (
                        <Grid item xs={12} sm={4} md={4} lg={4} sx={{ mt: 2 }}>
                            <Box className="editlabel">Project Code</Box>
                            <Box className="editvalue">{projectArray[index].projectCode}</Box>
                        </Grid>
                        )}
                        {isEditMode ? (
                        <Grid item xs={12} sm={6} md={6} lg={6}>
                            <Box className="label">Resource Allocation Percentage <span style={{ color: "red" }}>*</span></Box>
                            <TextField 
                                id="percentAllocation"
                                value={projectArray[index].percentAllocation}
                                placeholder="Enter Percent Allocation"
                                variant="outlined" 
                                fullWidth 
                                type="number"
                                InputProps={{ inputProps: { min: 0, max: 100 } }}
                                onChange={(e) => {
                                    handlePercentAllocation(e.target.value, section.id);
                                }}
                            />
                            {errors.percentAllocation && <div className='error-message'>This field is mandatory.</div>}
                        </Grid>
                        ) : (
                        <Grid item xs={12} sm={4} md={4} lg={4} sx={{ mt: 2 }}>
                            <Box className="editlabel">Resource Allocation Percentage</Box>
                            <Box className="editvalue">{projectArray[index].percentAllocation}</Box>
                        </Grid>
                        )}
                        {isEditMode ? (
                        <Grid item xs={12} sm={6} md={6} lg={6}>
                        <Box className="label"> Role Assignment in Project <span style={{ color: "red" }}>*</span></Box>
                        <FormControl fullWidth>
                          <Select
                            id="projectRoleId"
                            placeholder="Select Project Role"
                            defaultValue=""
                            value={projectArray[index].projectRoleId}
                            displayEmpty
                            inputProps={{ 'aria-label': 'Without label' }}
                            style={{ border: "1.5px solid #B2D4F8", color: "grey" }}
                            onChange={(e) => {
                              handleProjectRoles(e.target.value, section.id);
                            }}
                          >
                            <MenuItem value=""> Select Project Role </MenuItem>
                            {allProjectRoles
                              .filter((role) => role.status === "Active")
                              .map((role) => (
                                <MenuItem key={role.projectRoleId} value={role.projectRoleId}>
                                  {role.projectRoleName}
                                </MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                        {errors.projectRoleId && <div className='error-message'>This field is mandatory.</div>}
                      </Grid>
                        ) : (
                        <Grid item xs={12} sm={4} md={4} lg={4} sx={{ mt: 2 }}>
                            <Box className="editlabel">Role Assignment in Project</Box>
                            <Box className="editvalue">{projectArray[index].projectRoleName}</Box>
                        </Grid>
                        )}
                        {isEditMode ? (
                        <Grid item xs={12} sm={6} md={6} lg={6}>
                            <Box className="label"> Employee Project Start Date <span style={{ color: "red" }}>*</span></Box>
                            <LocalizationProvider fullWidth dateAdapter={AdapterDateFns}>
                                <DatePicker
                                  sx={{width:"100%"}}
                                    id="startDate"
                                    name="startDate"
                                    value={new Date(projectArray[index].startDate)}
                                    onChange={(date) => {
                                        handleStartDate(date, section.id);
                                    }}
                                    format="dd/MM/yyyy"
                                    renderInput={(params) => <TextField {...params} helperText={null} />}
                                />
                            </LocalizationProvider>
                            {errors.startDate && <div className='error-message'>This field is mandatory.</div>}
                        </Grid>
                        ) : (
                        <Grid item xs={12} sm={4} md={4} lg={4} sx={{ mt: "30px" }}>
                            <Box className="editlabel">Employee Project Start Date</Box>
                            <Box className="editvalue">{projectArray[index].startDate?moment(projectArray[index].startDate).format('DD/MM/YYYY'):""}</Box>
                        </Grid>
                        )}
                        {isEditMode ? (
                        <Grid item xs={12} sm={6} md={6} lg={6}>
                            <Box className="label">Employee Project End Date<span style={{ color: "red" }}>*</span></Box>
                            <LocalizationProvider fullWidth dateAdapter={AdapterDateFns}>
                                <DatePicker
                                  sx={{width:"100%"}}
                                    id="endDate"
                                    name="endDate"
                                    value={new Date(projectArray[index].endDate)}
                                    onChange={(date) => {
                                        handleEndDate(date, section.id);
                                    }}
                                    format="dd/MM/yyyy"
                                    renderInput={(params) => <TextField {...params} helperText={null} />}
                                />
                            </LocalizationProvider>
                            {errors.endDate && <div className='error-message'>This field is mandatory.</div>}
                        </Grid>
                        ) : (
                        <Grid item xs={12} sm={4} md={4} lg={4} sx={{ mt: "30px" }}>
                            <Box className="editlabel">Employee Project End Date</Box>
                            <Box className="editvalue">{projectArray[index].endDate?moment(projectArray[index].endDate).format('DD/MM/YYYY'):""}</Box>
                        </Grid>
                        )}
                        {isEditMode ? (
                        <Grid item xs={12} sm={6} md={6} lg={6}>
                            <Box className="label">Employee Project Status <span style={{ color: "red" }}>*</span></Box>
                            <FormControl fullWidth>
                                <Select
                                    id="projectStatus"
                                    placeholder="Select Status"
                                    defaultValue=""
                                    value={projectArray[index].status}
                                    displayEmpty
                                    inputProps={{ 'aria-label': 'Without label' }}
                                    style={{ border: "1.5px solid #B2D4F8", color: "grey" }}
                                    onChange={(e) => {
                                        handleProjectStatus(e.target.value, section.id);
                                    }}
                                >
                                    <MenuItem value="">Select Status </MenuItem>                                    
                                    <MenuItem value="Active">Active</MenuItem>
                                    <MenuItem value="Inactive">Inactive</MenuItem>
                                </Select>
                            </FormControl>
                            {errors.status && <div className='error-message'>This field is mandatory.</div>}
                        </Grid>
                        ) : (
                        <Grid item xs={12} sm={4} md={4} lg={4} sx={{ mt: 2 }}>
                            <Box className="editlabel">Employee Project Status</Box>
                            <Box className="editvalue">{projectArray[index].status}</Box>
                        </Grid>
                        )}
                    </Grid>
                    </>
                    ))}
                    
                    {isEditMode && (
                    <Grid container spacing={1} sx={{ pl: 4, pr: 5, mt: 5, justifyContent: "center" }} >
                        <Grid item xs={12} sm={2} md={2} lg={2}>
                            <Button className="cancelButton" onClick={toggleEditMode}>Cancel</Button>
                        </Grid>
                        <Grid item xs={12} sm={2} md={2} lg={2}>
                            <Button className="saveButton" onClick={handleUpdateProject}>
                            Save
                            </Button>
                        </Grid>
                    </Grid>
                    )}

                </AccordionDetails>
            </Accordion>
            <Dialog
        open={handleDialog.dialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" style={{fontWeight:"bold"}}>
          {"Remove Project Confirmation"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          <span style={{color:"orange", fontWeight:"bold"}}>Warning:</span> Please set the
                    status to Inactive as the employee has logged timesheets for this
                    project."
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button 
          style={{backgroundColor:"green", color:"#fff",marginRight:"20px",fontWeight:"bold"}}
          onClick={()=>{
            setHandleDialog({
                dialog:false,
                index:null
            })
            }}
            autoFocus
            >Ok</Button>
          {/* <Button 
          style={{backgroundColor:"red", color:"#fff",marginRight:"20px",fontWeight:"bold"}}
          onClick={()=>{
            // handleRemoveSection(handleDialog.index)
          }} 
          >
            Confirm
          </Button> */}
        </DialogActions>
      </Dialog>
        </div>
    )
}

export default EditProjectInfo