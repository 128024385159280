import { useContext } from "react";
import { useNavigate } from "react-router-dom";

import Context from "context/context";

const HandleApiResponse = () => {
  const navigate = useNavigate();
  const { dispatch } = useContext(Context);

  async function handleResponse(res) {
    if (res && res.status === 401) {
      dispatch({ type: "SET_LOADING", payload: false });
      navigate("/session-expired");
      return;
    }
    if (res && (res.status === 200 || res.status === 201)) {
      let body = await res.json();
      return body;
    }
  }

  async function handleError(err) {
    console.log({ err });
  }

  return {
    handleResponse,
    handleError,
  };
};

export default HandleApiResponse;
