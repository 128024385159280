import { toast } from "react-toastify";

// custom pages
import config from "config/config";
import HandleApiResponse from "utils/handleApiResponse";

const fileHandler = () => {
  const { handleResponse, handleError } = HandleApiResponse();

  async function fileUploadAPI(data) {
    try {
      const obj = {
        method: "Post",
        credentials: "include",
        body: data,
      };
      let res = await fetch(`${config.host}/panel/s3/upload`, obj);
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }

  async function fileDownloadAPI(id) {
    try {
      const obj = {
        method: "GET",
        credentials: "include",
      };
      const res = await fetch(`${config.host}/panel/s3/download?name=${id}`, obj);

      if(res.ok){
      const blob = await res.blob();

      const contentDisposition = res.headers.get("Content-Disposition");
      const filename = contentDisposition ? contentDisposition.split('filename=')[1].replace(/"/g, ''): '';

      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename); 
      document.body.appendChild(link);
      link.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(link);
      toast.success("File Downloaded Successfully", { toastId: "file-success" });
      return;
      }
      toast.error("File Not Found", { toastId: "file-error" });
      return
    } catch (err) {
      handleError(err);
    }
  }

  async function fileViewAPI(id) {
    try {
      const obj = {
        method: "GET",
        credentials: "include",
      };
      const res = await fetch(`${config.host}/panel/s3/download?name=${id}`, obj);

      if(res.ok){
      const blob = await res.blob();
      return blob;
      }
      toast.error("Document is not available for now, you can try again some time.", { toastId: "file-view-error" });
      return
    } catch (err) {
      toast.error("Document is not available for now, you can try again some time.", { toastId: "file-view-error" });
      handleError(err);
    }
  }

  return {
    fileUploadAPI,
    fileDownloadAPI,
    fileViewAPI
  };
};

export default fileHandler;
