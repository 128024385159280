import React from "react";
import { ReactComponent as NotFoundSVG } from "assets/svg/not-found.svg";

export default function NotFoundPage() {
  return (
    <div style={{ textAlign: "center",padding:"50px" }}>
      <NotFoundSVG style={{ width: "200px",height: "200px"  }} />
     <div>Oops! No Records Found</div>
    </div>
  );
}
