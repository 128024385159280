import React, { useRef, useState ,useEffect} from 'react';

import "./AddEmployee.css"
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import personalInfo from "assets/images/Layer_1 (1).png";
import AttachFileIcon from '@mui/icons-material/AttachFile';

import CloudUploadIcon from '@mui/icons-material/CloudUpload';

import {  InputAdornment, IconButton } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import getemployee from '../../../apis/panel/managerinfo/managerinfo'
import getEmpall from '../../../apis/panel/managerinfo/managerinfo'
import getRel from '../../../apis/panel/managerinfo/managerinfo'
import { toast } from "react-toastify";



// import { makeStyles } from '@mui/styles';

// const useStyles = makeStyles((theme) => ({
//   fileInput: {
//     '& input[type="file"]': {
//       display: 'none',
//     },
//   },
//   textField: {
//     '& .MuiOutlinedInput-root': {
//       '&.Mui-focused fieldset': {
//         borderColor: theme.palette.primary.main,
//       },
//       '&:hover fieldset': {
//         borderColor: theme.palette.secondary.main,
//       },
//     },
//     '& .MuiFormLabel-root.Mui-focused': {
//       color: theme.palette.primary.main,
//     },
//   },
// }));



const label = { inputProps: { 'aria-label': 'Checkbox demo' } };


const ManagerInfo = () => {
    const [file, setFile] = useState(null);

    const { getEmployeeinfo } = getemployee();
    const { getemployeeall } = getEmpall();
    const { getEmployementupdate } = getRel();
    const [RelationsData, setRelationsData] = useState([])
    const [EmployeeId] = useState(sessionStorage.getItem('EmployeeID'))
    const [relName, setrelName] = useState()
    const [employeeid, setEmployeeId] = useState('');
    const[mail,setmail]= useState('')
    const[managerid,setmanagerid]=useState('');
    const [accordionOpen, setAccordionOpen] = useState(false);

    const handleCancel = () => {
      setAccordionOpen(false); 
    };
  



    useEffect(() => {
      findrelations()
    }, [])


    const selectedData= (e) => {
      
      let body ={
        relID:e,
        
      }
      findEmpRelations(body)
        }
        const findEmpRelations = async (e) => {
          const data = await getEmployeeinfo(e)
          setrelName(data?.data?.designationResult?.designationName)
          setEmployeeId(data?.data?.designationResult?.designationId)
          setmail(data?.data?.businessEmail)
      
        }
        const findrelations = async () => {
          const data = await getemployeeall()
          setRelationsData(data.data.employee)
        }

        const [errorFields, setErrorFields] = useState({
          mangername: false,
          designation: false,
          email: false,
          
          
        
         
          
        });

    const handleFileChange = (event) => {
      const selectedFile = event.target.files[0];
      setFile(selectedFile);
    };

    const handleReset = () => {
      setrelName('')
      setmail('')
      

      

    }

    



    const handlerelationship = (e) => {
      const selectedemployee = RelationsData.find(option => option.firstName === e.target.value);
      
      // selectedData(e.target.value)
      if (selectedemployee) {
        // setEmployeeId(selectedemployee.employeeId);
      } else {
        // setEmployeeId(null); // Clear the relationship ID if the selected relationship is not found
      }

      selectedData(e.target.value)
      let manager = e.target.value
      setmanagerid(manager)
      

    }

    const handleCreatecontact = async (e) => {
      e.preventDefault();
      // Validate form fields
      const errors = {};
      if (!managerid) errors.mangername = true;
      if (!relName) errors.designation = true;
      if (!mail) errors.email = true;
      
      
      
    
  
      setErrorFields(errors);
  
      // If no errors, proceed with form submission
      if (Object.keys(errors).length === 0) {
      // const selectedemployee = RelationsData.find(option => option.firstName === e.target.value);
      // setEmployeeId(selectedemployee.employeeId);
      const values = {
        employeeId: EmployeeId, // Use employeeId state instead of EmployeeId constant
        managerId: managerid
      };
      
      const data = await getEmployementupdate(values);
      if(data.status === 'Ok'){
        return toast.success("Data Saved Successfully", { toast: "Data Saved Successfully" });

      }else{
        return toast.error("Error saving Data", { toast: "Error saving Data" });

      }
    };
  }

 
  return (
    <Accordion expanded={accordionOpen} onChange={() => setAccordionOpen(!accordionOpen)} sx={{ mt: 2, boxShadow:"rgba(0, 0, 0, 0.24) 0px 3px 8px" }}>
    <AccordionSummary
        expandIcon={<ArrowDropDownIcon className="Accordianicon"/>}
      aria-controls="panel1-content"
      id="panel1-header"
      style={{boxShadow:"rgba(0, 0, 0, 0.24) 0px 3px 8px", height:"70px"}}
    >
      <Box sx={{ pl: 3 }}> <img sx={{ pl: 3 }} className='personalInfo-logo' src={personalInfo} alt="info" /></Box>
      <Box className="acc-title" sx={{ pl: 3 }}>Manager Information</Box>
    </AccordionSummary>
    <AccordionDetails>



      <Grid container spacing={2}  sx={{ pl: 4, pr:5, mt:1 }} >
        {/* <Box>Current Addres</Box> */}

        <Grid item xs={12} sm={6} md={6} lg={6}>
        <Box className="label" >Manager Name <span style={{color:"red"}}>*</span></Box>
        <FormControl fullWidth>
              {/* <InputLabel id="marital-status-label">Country</InputLabel> */}
              <Select
                labelId="marital-status-label"
                id="maritalStatus"
                displayEmpty
                inputProps={{ 'aria-label': 'Without label' }}
                style={{ border: "1.5px solid #B2D4F8", color: "grey" }}
                // value={Selectedrelation.relationshipName}
                value={relName}
                onChange={
                  handlerelationship
                }
              
              >
                <MenuItem>
                  Select Manager
                </MenuItem>
                {console.log(RelationsData,'hhh')
                }
                {
                  RelationsData?.map((i) => (
                    <MenuItem value={i.employeeId}>{i.firstName}</MenuItem>
                  ))
                }
              </Select>
            </FormControl>
            {errorFields. mangername&& <span className="error-msg">Manager Name is required</span>}
          </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6}>
        <Box className="label" > Designation <span style={{color:"red"}}>*</span></Box>
          <TextField id="firstName"  variant="outlined" fullWidth value={relName} />
          {errorFields. designation&& <span className="error-msg">Designation is required</span>}
        </Grid>
       </Grid>
       <Grid container spacing={2}  sx={{ pl: 4, pr:5, mt:2 }} >
        <Grid item xs={12} sm={6} md={6} lg={6}>
       
        <Box className="label" > Email <span style={{color:"red"}}>*</span></Box>
          <TextField id="firstName"  placeholder="Email" variant="outlined" fullWidth value={mail} />
          {errorFields. email&& <span className="error-msg">Email is required</span>}
        </Grid>
        </Grid>



    

   
<Grid container spacing={1}  sx={{ pl: 4, pr:5, mt:5 , justifyContent:"center"}} >
        <Grid item xs={12} sm={3} md={3} lg={3}>
        <Button className="resetChanges" onClick={handleReset}>Reset Changes</Button>
        </Grid>

        <Grid item xs={12} sm={2} md={2} lg={2}>
        <Button className="cancelButton" onClick={handleCancel}>Cancel</Button>
        </Grid>

        <Grid item xs={12} sm={2} md={2} lg={2}>
        <Button className="saveButton" onClick={handleCreatecontact}>Save</Button>
        </Grid>

        </Grid>
    </AccordionDetails>
  </Accordion>
   
  )
}

export default ManagerInfo

