import React, { useState, useContext } from 'react';
import { useDropzone } from 'react-dropzone';
import { motion } from 'framer-motion';
import { toast, ToastContainer } from 'react-toastify'; // Import toast and ToastContainer
import 'react-toastify/dist/ReactToastify.css'; // Import the CSS for styling
// import "./ImportSrf.css";
import getAllVraShiftAPIS from "apis/panel/vraShifts/vraShifts.js";
import upload from "../../../../assets/images/rpHomePage/upload.gif";
import Context from "context/context";
import { Box } from '@mui/material';

const ImportQagenda = () => {
  const { fileUploadQgendaAPI } = getAllVraShiftAPIS();
  const [uploadProgress, setUploadProgress] = useState(0); // State to track upload progress
  const { state, dispatch } = useContext(Context);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: {
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
      'application/vnd.ms-excel': ['.xls']
    },
    onDrop: (acceptedFiles) => {
      if (acceptedFiles.length > 0) {
        handleFileUpload(acceptedFiles[0]);
      } else {
        toast.error('Only Excel files are allowed.');
      }
    },
  });

  const handleFileUpload = async (file) => {
    const maxSize = 1024 * 1024 * 5; // 5MB
    if (file.size > maxSize) {
      // Show error message if file size exceeds the limit
      toast.error("File size exceeds the limit (5MB)", {
        toastId: "file-upload-maximum",
      });
      return;
    }

    
    try {
      dispatch({ type: "SET_LOADING", payload: true });

      const response= await fileUploadQgendaAPI(file)

      dispatch({ type: "SET_LOADING", payload: false });

      if (response?.status==200) {
        toast.success(response?.detail);
        
        setUploadProgress(0); 
        return response.detail;
      }else{
        toast.error(response?.detail);
        setUploadProgress(0); 
        return;
      }

      
      
    } catch (err) {
      dispatch({ type: "SET_LOADING", payload: false });
      
      console.error('Error uploading file:', err);
      toast.error('Error uploading file.');
    }
  };



  return (
    <div style={{ backgroundColor: "white", borderRadius: "10px" }}>
      <Box sx={{ pt: 2, pb: 2, pl: 2 }} className="VraShiftsTitle">Import Qgenda Report</Box>
      <div className="containerforupload">
        <motion.div
          className="upload-area"
          {...getRootProps()}
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          <input {...getInputProps()} />
          <div className="instructions">
            {isDragActive ? (
              <img style={{ width: "40%" }} src={upload} alt="Upload indicator" />
            ) : (
              <>
                <img style={{ width: "40%" }} src={upload} alt="Upload indicator" />
                <p style={{ color: "lightgrey" }}>Drag and drop your Excel files here</p>
              </>
            )}
          </div>
        </motion.div>
        
        {/* Display progress bar */}
        {uploadProgress > 0 && (
          <div className="progress-container">
            <div className="progress-bar" style={{ width: `${uploadProgress}%` }}>
              {uploadProgress}%
            </div>
          </div>
        )}
        <ToastContainer /> {/* Render ToastContainer to display notifications */}
      </div>
    </div>
  );
};

export default ImportQagenda;
