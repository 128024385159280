import React, { useEffect, useState,useContext } from 'react';
import Context from "context/context";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Grid from "@mui/material/Grid";
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Button from "@mui/material/Button";
import TimesheetApis from "apis/panel/timesheet/TimeSheet";
import { toast } from "react-toastify";
import * as moment from "moment";

const CreateEditedTask = ({ editModalContent, closeModal }) => {
  const { dispatch } = useContext(Context);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedTask, setSelectedTask] = useState('');
  const [selectedShift, setSelectedShift] = useState('');
  const [selectedOwner, setSelectedOwner] = useState('');
  const [selectedHours, setSelectedHours] = useState(0);
  const [selectedWorkLocation, setSelectedWorkLocation] = useState('');
  const [description, setDescription] = useState('');
  const [projectTaskList, setProjectTaskList] = useState([]);
  const [projectShiftsList, setProjectShiftsList] = useState([]);
  const [projectOwnerList, setProjectOwnerList] = useState([]);
  const [allSelectedTasks,setAllSelectedTasks]= useState([])
  const [validationErrors, setValidationErrors] = useState({});

  const { updateTaskAPI } = TimesheetApis();

  useEffect(() => {
    if (editModalContent) {
      setIsEditModalOpen(true);

      // Set project task list
      setProjectTaskList(editModalContent?.values[0]);

      // Set project owner list
      setProjectOwnerList(editModalContent?.values[1]);

      // Set project shifts list
      setProjectShiftsList(editModalContent?.values[2]);

      // Find taskId based on taskName
      const selectedTaskObj = projectTaskList.find(task => task.taskName === editModalContent.taskName);
      const selectedTaskId = selectedTaskObj ? selectedTaskObj.taskId : '';
      setSelectedTask(selectedTaskId);

      // Find projectOwnerId based on ownerName
      const selectedOwnerObj = projectOwnerList.find(owner => owner.ownerName === editModalContent.ownerName);
      const selectedOwnerId = selectedOwnerObj ? selectedOwnerObj.projectOwnerId : '';
      setSelectedOwner(selectedOwnerId);

      // Find projectShiftId based on projectShiftName
      const selectedShiftObj = projectShiftsList.find(shift => shift.projectShiftName === editModalContent.projectShiftName);
      const selectedShiftId = selectedShiftObj ? selectedShiftObj.projectShiftId : '';
      setSelectedShift(selectedShiftId);

      // Set selected hours based on editModalContent.hours
      setSelectedHours(editModalContent.hours);

      // Set selected workLocation based on editModalContent.workLocationType
      setSelectedWorkLocation(editModalContent?.workLocationType);

      // Set description based on editModalContent.description
      setDescription(editModalContent?.description);

      // set allselectedtasks based on editM(odalContent.allSelectedTasks
      const matchedTaskIds=[]
      const selectedTaskObj2 = projectTaskList.find(task => task.taskName === editModalContent.taskName);
      const selectedTaskId2 = selectedTaskObj2 ? selectedTaskObj.taskId : '';
      editModalContent?.allSelectedTasks.map((task)=>{
        if(task!=selectedTaskId2){
          matchedTaskIds.push(task)
        }
      })
      
      
      setAllSelectedTasks(matchedTaskIds)
      console.log(matchedTaskIds,'allSelected');
  
    }
  }, [editModalContent, projectTaskList, projectOwnerList, projectShiftsList]);

  const handleProjectTaskChange = (event) => {
    setSelectedTask(event.target.value);
    setValidationErrors({ ...validationErrors, selectedTask: '' });
  };

  const handleProjectShiftChange = (event) => {
    setSelectedShift(event.target.value);
    setValidationErrors({ ...validationErrors, selectedShift: '' });
  };

  const handleProjectOwnerChange = (event) => {
   
    setSelectedOwner(event.target.value);
    setValidationErrors({ ...validationErrors, selectedOwner: '' });
  };

  const handleCloseModal = () => {
    setIsEditModalOpen(false);
    closeModal();
  };

  const handleSaveChanges = async () => {
    dispatch({ type: "SET_LOADING", payload: true });
    const errors = {};
    if (!selectedTask) errors.selectedTask = 'This field is mandatory';
    if (!selectedShift) errors.selectedShift = 'This field is mandatory';
    if (!selectedOwner) errors.selectedOwner = 'This field is mandatory';
    if (!selectedHours) errors.selectedHours = 'This field is mandatory';
    if (!selectedWorkLocation) errors.selectedWorkLocation = 'This field is mandatory';
    if (!description) errors.description = 'This field is mandatory';

    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
      return;
    }

    if(!allSelectedTasks.includes(selectedTask)){

      setAllSelectedTasks(prevTasks => [...prevTasks, selectedTask]);
     
    }else{
      return toast.error(`This task has already been created for the day ${moment(editModalContent?.date).format("DD-MMM-YYYY")}. Please add the details to the existing task record. Duplicate task records for a single day are not allowed.`);
    }
    

    const value = {
      projectId: editModalContent?.projectId,
      date: editModalContent?.date,
      taskId: selectedTask,
      hoursLogged: Number(selectedHours),
      workArrangement: selectedWorkLocation,
      projectOwnerId: selectedOwner,
      projectShiftId: selectedShift,
      description: description,
      employeeWorkHoursId: editModalContent?.employeeWorkHoursId,
    };

    let res = await updateTaskAPI(value);
    dispatch({ type: "SET_LOADING", payload: false });
    if (res?.status == "Ok"){
      toast.success("Record updated successfully.");
    }
    
    handleCloseModal(); // Close modal after saving
  };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "60%",
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
  };

  return (
    <div>
      <Modal
        open={isEditModalOpen}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ border: "none" }}
      >
        <Box sx={style} style={{ minHeight: '300px', maxHeight: "450px", overflowY: 'auto', borderRadius: "10px" }}>
          <IconButton
            aria-label="close"
            onClick={handleCloseModal}
            sx={{
              position: 'absolute',
              right: 0,
              top: 0,
              color: 'inherit',
              zIndex: 1
            }}
          >
            <CloseIcon />
          </IconButton>

          <Grid container xs={12} sm={12} md={12} lg={12} style={{ alignItems: "center" }}>
            <Grid item xs={12} sm={5} md={5} lg={5} style={{ display: "flex", gap: "1%" }}>
              <div className="tsheads">Project Name : </div>
              <div className="tsbods">{editModalContent?.projectName}</div>
            </Grid>
          
          <Grid
                  item
                  xs={12}
                  sm={4}
                  md={4}
                  lg={4}
                  style={{ display: "flex", gap: "1%", pl: 2 }}
                >
                  <div className="tsheads">Task Date :</div>
                  <div className="tsbods">
                    {" "}
                    {moment(editModalContent?.date).format("DD-MMM-YYYY")}
                  </div>
                </Grid>
                </Grid>
          <Box>
            <Grid container sx={{ mt: 2, display: "flex", gap: "4%" }}>
              <Grid item xs={12} sm={5.5} md={5.5} lg={5.5} sx={{ mt: 2 }}>
                <Box className="label" style={{ color: "#002C5D" }}> Task Name <span style={{ color: "red" }}>*</span></Box>
                <FormControl sx={{ width: '100%' }}>
                  <Select
                    value={selectedTask}
                    onChange={handleProjectTaskChange}
                    sx={{ padding: "0px 0px", border: '1px solid #B2D4F8', height: "48px" }}
                  >
                    <MenuItem value="">Select Task</MenuItem>
                    {projectTaskList && projectTaskList.length > 0 && (
                      projectTaskList.map((task, index) => (
                        <MenuItem key={index} value={task.taskId}>{task.taskName}</MenuItem>
                      ))
                    )}
                  </Select>
                </FormControl>
                {validationErrors.selectedTask && <div style={{ color: 'red' }}>{validationErrors.selectedTask}</div>}
              </Grid>

              <Grid item xs={12} sm={5.5} md={5.5} lg={5.5} sx={{ mt: 2 }}>
                <Box className="label" style={{ color: "#002C5D" }}>Project Owner <span style={{ color: "red" }}>*</span></Box>
                <FormControl sx={{ width: '100%' }}>
                  <Select
                    value={selectedOwner}
                    onChange={handleProjectOwnerChange}
                    sx={{ padding: "0px 0px", border: '1px solid #B2D4F8', height: "48px" }}
                  >
                    <MenuItem value="">Select Owner</MenuItem>
                    {projectOwnerList && projectOwnerList.length > 0 && (
                      projectOwnerList.map((owner, index) => (
                        <MenuItem key={index} value={owner.projectOwnerId}>{owner.ownerName}</MenuItem>
                      ))
                    )}
                  </Select>
                </FormControl>
                {validationErrors.selectedOwner && <div style={{ color: 'red' }}>{validationErrors.selectedOwner}</div>}
              </Grid>

              <Grid item xs={12} sm={5.5} md={5.5} lg={5.5} sx={{ mt: 2 }}>
                <Box className="label" style={{ color: "#002C5D" }}>Number of Hours <span style={{ color: "red" }}>*</span></Box>
                <input
                  value={selectedHours}
                  onChange={(e) => {
                    setSelectedHours(e.target.value);
                    setValidationErrors({ ...validationErrors, selectedHours: '' });
                  }}
                  variant="outlined"
                  fullWidth
                  style={{ border: '1.5px solid #B2D4F8', width: "97%", height: "45px", borderRadius: "5px",paddingLeft:"10px" }}
                  type="number"
                  min={0}
                  max={24}
                  step={0.5}
                />
                {validationErrors.selectedHours && <div style={{ color: 'red' }}>{validationErrors.selectedHours}</div>}
              </Grid>

              <Grid item xs={12} sm={5.5} md={5.5} lg={5.5} sx={{ mt: 2 }}>
                <Box className="label" style={{ color: "#002C5D" }}> Work Arrangement <span style={{ color: "red" }}>*</span></Box>
                <FormControl sx={{ width: '100%' }}>
                  <Select
                    id="workLocationType"
                    name="workLocationType"
                    sx={{ padding: "0px 0px", border: '1px solid #B2D4F8', height: "48px" }}
                    value={selectedWorkLocation}
                    onChange={(e) => {
                      setSelectedWorkLocation(e.target.value);
                      setValidationErrors({ ...validationErrors, selectedWorkLocation: '' });
                    }}
                  >
                    <MenuItem value={'Office'}>Office</MenuItem>
                    <MenuItem value={'Home'}>Home</MenuItem>
                  </Select>
                </FormControl>
                {validationErrors.selectedWorkLocation && <div style={{ color: 'red' }}>{validationErrors.selectedWorkLocation}</div>}
              </Grid>

              <Grid item xs={12} sm={5.5} md={5.5} lg={5.5} sx={{ mt: 2 }}>
                <Box className="label" style={{ color: "#002C5D" }}> Shift <span style={{ color: "red" }}>*</span></Box>
                <FormControl sx={{ width: '100%' }}>
                  <Select
                    value={selectedShift}
                    onChange={handleProjectShiftChange}
                    sx={{ padding: "0px 0px", border: '1px solid #B2D4F8', height: "48px" }}
                  >
                    <MenuItem value="">Select Shift</MenuItem>
                    {projectShiftsList && projectShiftsList.length > 0 && (
                      projectShiftsList.map((shift, index) => (
                        <MenuItem key={index} value={shift.projectShiftId}>{shift.shiftName}</MenuItem>
                      ))
                    )}
                  </Select>
                </FormControl>
                {validationErrors.selectedShift && <div style={{ color: 'red' }}>{validationErrors.selectedShift}</div>}
              </Grid>

              <Grid item xs={12} sm={5.5} md={5.5} lg={5.5} sx={{ mt: 2 }}>
                <Box className="label" style={{ color: "#002C5D" }}>Description<span style={{ color: "red" }}>*</span></Box>
                <textarea
                  id="description"
                  name="description"
                  placeholder="Type the task description"
                  variant="outlined"
                  fullWidth
                  style={{ width: "98%", border: "1.5px solid #B2D4F8", borderRadius: "5px" ,paddingLeft:"10px" }}
                  value={description}
                  onChange={(event) => {
                    setDescription(event.target.value);
                    setValidationErrors({ ...validationErrors, description: '' });
                  }}
                  multiline
                  rows={4}
                  maxRows={10}
                />
                {validationErrors.description && <div style={{ color: 'red' }}>{validationErrors.description}</div>}
              </Grid>

              <Grid container spacing={1} sx={{ pl: 6, pr: 5, mt: 5, justifyContent: "center" }}>
                <Grid item xs={12} sm={2} md={2} lg={2}>
                  <Button className="cancelButton" onClick={handleCloseModal}>Cancel</Button>
                </Grid>
                <Grid item xs={12} sm={2} md={2} lg={2}>
                  <Button className="saveButton" onClick={handleSaveChanges}>Save</Button>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default CreateEditedTask;
