// npm packages
import React, { useEffect, useState, useContext } from "react";
import { toast } from "react-toastify";

// custom pages
import "./AddEmployee.css";
import Context from "context/context";
import getAllbankdrop from "apis/panel/bank/bank";
import getAllbranchesdrop from "apis/panel/bank/bank";
import getAllbankcreate from "apis/panel/bank/bank";
import FileHandler from "apis/panel/fileHandler/fileHandler";
import ReusableFunction from "utils/reusablefunction";

// custom images
import personalInfo from "assets/images/Capa_1 (3).png";

// material ui
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { InputAdornment, IconButton } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ClearIcon from "@mui/icons-material/Clear";

const BankInfo = () => {
  const { dispatch } = useContext(Context);
  const { getAllbankAPI } = getAllbankdrop();
  const { getAllbankbranchesAPI } = getAllbranchesdrop();
  const { getbankCreateAPI } = getAllbankcreate();
  const { fileUploadAPI } = FileHandler();
  const { handleFileInputReselect } = ReusableFunction();

  const [bankid, setbankid] = useState("");
  const [passbook, setpassbook] = useState(null);
  const [bankname, setbankname] = useState("");
  const [bankbranchesid, setbankbranchesid] = useState("");
  const [bankbranchesname, setbankbranchesname] = useState("");
  const [bankoption, setbankoption] = useState([]);
  const [bankbranchesoption, setbankbranchesoption] = useState([]);
  const [account, setaccount] = useState("");
  const [idfc, setidfc] = useState("");
  const [accordionOpen, setAccordionOpen] = useState(false);
  const [passbookFilePath, setPassbookFilePath] = useState("");

  const EmployeeId = sessionStorage.getItem("EmployeeID");

  const handleCancel = () => {
    setAccordionOpen(false);
  };

  const handleClearpassbookFile = () => {
    setpassbook("");
  };

  const handleGetAllbank = async () => {
    try {
      let res = await getAllbankAPI();
      setbankoption(res?.data || []);
    } catch (err) {}
  };

  const handleGetAllbankbranches = async () => {
    try {
      let res = await getAllbankbranchesAPI();
      setbankbranchesoption(res?.data || []);
    } catch (err) {}
  };

  useEffect(() => {
    handleGetAllbank();
    handleGetAllbankbranches();
  }, []);

  const handlebankChange = (e) => {
    const selectedbank = bankoption.find(
      (option) => option.bankName === e.target.value
    );
    setbankname(selectedbank.bankName); // Set the titleName
    setbankid(selectedbank.bankId); // Set the titleId
  };
  const handlebankbranchesChange = (e) => {
    const selectedbankbranches = bankbranchesoption.find(
      (option) => option.bankBranchName === e.target.value
    );
    setbankbranchesname(selectedbankbranches.bankBranchName); // Set the titleName
    setbankbranchesid(selectedbankbranches.bankBranchId); // Set the titleId
  };
  const handleaccountChange = (e) => {
    setaccount(e.target.value);
  };
  const handleidfcChange = (e) => {
    setidfc(e.target.value);
  };

  const [errorFields, setErrorFields] = useState({
    Bankname: false,
    Branchname: false,
    accountname: false,
    idfcnumber: false,
    bankfile: false,
  });

  const handleReset = () => {
    setbankname("");
    setbankbranchesname("");
    setaccount("");
    setidfc("");
    setpassbook("");
  };

  const handleFileUpload = async (file, type) => {
    const maxSize = 1024 * 1024 * 5;
    if (file.size > maxSize) {
      toast.error("File size exceeds the limit (5MB)", {
        toastId: "file-upload-maximum",
      });
      return false;
    }
    try {
      dispatch({ type: "SET_LOADING", payload: true });
      let input = new FormData();
      input.append("type", "employee_bank_passbooks");
      input.append("employeeId", EmployeeId);
      input.append("file", file);
      const res = await fileUploadAPI(input);
      dispatch({ type: "SET_LOADING", payload: false });
      if (res?.data?.location) {
        return res?.data?.location;
      }
      return false;
    } catch (err) {
      dispatch({ type: "SET_LOADING", payload: false });
      console.log({ err });
    }
  };

  const handleFileChangeSSC = async (event) => {
    const file = event.target.files[0];
    const location = await handleFileUpload(file);
    if (location) {
      setPassbookFilePath(location);
      setpassbook(file);
    }
  };

  const handlebankcreate = async (e) => {
    e.preventDefault();
    // Validate form fields
    const errors = {};
    if (!bankid) errors.Bankname = true;
    if (!bankbranchesid) errors.Branchname = true;
    if (!account) errors.accountname = true;
    if (!idfc) errors.idfcnumber = true;
    if (!passbook) errors.bankfile = true;

    setErrorFields(errors);

    // If no errors, proceed with form submission
    if (Object.keys(errors).length === 0) {
      if (passbook) {
        const values = {
          employeeId: EmployeeId,
          bankId: bankid,
          bankBranchId: bankbranchesid,
          accountNumber: account,
          ifscCode: idfc,
          passbookFilePath: passbookFilePath,
        };

        const data = await getbankCreateAPI(values);
        if (data.status === "Ok") {
          return toast.success("Data Saved Successfully", {
            toast: "Data Saved Successfully",
          });
        } else {
          return toast.error("Error saving Data", {
            toast: "Error saving Data",
          });
        }
      }
    }
  };

  return (
    <Accordion
      expanded={accordionOpen}
      onChange={() => setAccordionOpen(!accordionOpen)}
      sx={{ mt: 2, boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}
    >
      <AccordionSummary
        expandIcon={<ArrowDropDownIcon className="Accordianicon" />}
        aria-controls="panel1-content"
        id="panel1-header"
        style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px", height: "70px" }}
      >
        <Box sx={{ pl: 3 }}>
          {" "}
          <img
            sx={{ pl: 3 }}
            className="personalInfo-logo"
            src={personalInfo}
            alt="info"
          />
        </Box>
        <Box className="acc-title" sx={{ pl: 3 }}>
          Bank Information
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={2} sx={{ pl: 4, pr: 5, mt: 1 }}>
          {/* <Box>Current Addres</Box> */}

          <Grid item xs={12} sm={6} md={6} lg={6}>
            <Box className="label">
              Bank Name <span style={{ color: "red" }}>*</span>
            </Box>
            <FormControl fullWidth>
              <Select
                labelId="title-label"
                id="title"
                style={{ border: "1px solid #B2D4F8", color: "grey" }}
                defaultValue=""
                name="title"
                value={bankname}
                onChange={handlebankChange}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
              >
                <MenuItem value="">Select Bank Name</MenuItem>

                {bankoption.map((option) => (
                  <MenuItem key={option.id} value={option.bankName}>
                    {option.bankName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {errorFields.Bankname && (
              <span className="error-msg">Bankname is required</span>
            )}
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <Box className="label">
              {" "}
              Branch <span style={{ color: "red" }}>*</span>
            </Box>
            <FormControl fullWidth>
              <Select
                labelId="title-label"
                id="title"
                style={{ border: "1px solid #B2D4F8", color: "grey" }}
                defaultValue=""
                name="title"
                value={bankbranchesname}
                onChange={handlebankbranchesChange}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
              >
                <MenuItem value="">Select Branch Name</MenuItem>

                {bankbranchesoption.map((option) => (
                  <MenuItem key={option.id} value={option.bankBranchName}>
                    {option.bankBranchName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {errorFields.Branchname && (
              <span className="error-msg">Branch is required</span>
            )}
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ pl: 4, pr: 5, mt: 2 }}>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <Box className="label">
              {" "}
              Account Number <span style={{ color: "red" }}>*</span>
            </Box>
            <TextField
              id="firstName"
              placeholder="Enter Account Number"
              variant="outlined"
              fullWidth
              onChange={handleaccountChange}
            />
            {errorFields.accountname && (
              <span className="error-msg">Account Number is required</span>
            )}
          </Grid>

          <Grid item xs={12} sm={6} md={6} lg={6}>
            <Box className="label">
              {" "}
              IFSC Code <span style={{ color: "red" }}>*</span>
            </Box>
            <TextField
              placeholder="Enter IFSC Code"
              onChange={handleidfcChange}
              id="firstName"
              variant="outlined"
              fullWidth
            />
            {errorFields.idfcnumber && (
              <span className="error-msg">IFSC number is required</span>
            )}
          </Grid>
        </Grid>

        <Grid container spacing={2} sx={{ pl: 4, pr: 5, mt: 2 }}>
          {/* <Box>Current Addres</Box> */}

          <Grid item xs={12} sm={6} md={6} lg={6}>
            <Box className="label">
              Passbook (JPEG, PNG, PDF)<span style={{ color: "red" }}>*</span>
            </Box>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Browse Files (max of 5mb)"
              //   label="Brow"
              type="text"
              value={passbook ? passbook.name : ""}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {passbook && (
                      <IconButton
                        onClick={handleClearpassbookFile}
                        edge="end"
                        aria-label="clear file"
                      >
                        <ClearIcon />
                      </IconButton>
                    )}
                    <input
                      type="file"
                      accept=".pdf,.doc,.docx"
                      id="file-upload0"
                      style={{ display: "none" }}
                      onClick={handleFileInputReselect}
                      onChange={handleFileChangeSSC}
                    />
                    <label htmlFor="file-upload0">
                      <IconButton
                        component="span"
                        color="primary"
                        aria-label="attach file"
                      >
                        <AttachFileIcon style={{ color: "#0056B2" }} />
                      </IconButton>
                    </label>
                  </InputAdornment>
                ),
              }}
            />
            {errorFields.bankfile && (
              <span className="error-msg">Passbook file is required</span>
            )}
          </Grid>
        </Grid>

        <Grid
          container
          spacing={1}
          sx={{ pl: 4, pr: 5, mt: 5, justifyContent: "center" }}
        >
          <Grid item xs={12} sm={3} md={3} lg={3}>
            <Button className="resetChanges" onClick={handleReset}>
              Reset Changes
            </Button>
          </Grid>

          <Grid item xs={12} sm={2} md={2} lg={2}>
            <Button className="cancelButton" onClick={handleCancel}>
              Cancel
            </Button>
          </Grid>

          <Grid item xs={12} sm={2} md={2} lg={2}>
            <Button className="saveButton" onClick={handlebankcreate}>
              Save
            </Button>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default BankInfo;
