import React, { useEffect, useState } from 'react';
import "./AddEmployee.css"
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TextField from '@mui/material/TextField'
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import personalInfo from "assets/images/Capa_1.png";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import getAllCountriesdrop from "apis/panel/Contact/contact";
import getStateCountrydrop from "apis/panel/Contact/contact";
import getAllStatesdrop from "apis/panel/Contact/contact";
import getStatecityDrop from "apis/panel/Contact/contact";
import getAllCitiesDrop from "apis/panel/Contact/contact";
import PostApi from "apis/panel/Contact/contact";
import { toast } from "react-toastify";

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

const ContactInfo = () => {
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState({ id: "", name: "" });
  const [selectedState, setSelectedState] = useState({ id: "", name: "" });
  const [selectedCity, setSelectedCity] = useState({ id: "", name: "" });
  const [cities, setCities] = useState([]);
  const [postalCode, setPostalCode] = useState("");
  const [address, setAddress] = useState("");
  const [streetName, setStreetName] = useState("");
  const [isPermanentSameAsCurrent, setIsPermanentSameAsCurrent] = useState(false);
  const EmployeeId = sessionStorage.getItem('EmployeeID')

  const [accordionOpen, setAccordionOpen] = useState(false);

  const handleCancel = () => {
    setAccordionOpen(false);
  };



  const [permanentAddress, setPermanentAddress] = useState({
    address: "",
    streetName: "",
    selectedCountry: { id: "", name: "" },
    selectedState: { id: "", name: "" },
    selectedCity: { id: "", name: "" },
    postalCode: ""
  });




  const { getAllCountriesAPI } = getAllCountriesdrop();
  const { getStateCountryApi } = getStateCountrydrop();
  const { getAllStatesApi } = getAllStatesdrop();
  const { getStatecityApi } = getStatecityDrop();
  const { getAllCitiesApi } = getAllCitiesDrop();
  const { getContactCreateAPI } = PostApi();


  const [errorFields, setErrorFields] = useState({
    address: false,
    streetName: false,
    selectedCountry: false,
    selectedState: false,
    selectedCity: false,
    postalCode: false,
    permanentAddress: false, // New state for permanent address fields
    permanentStreetName: false,
    permanentCountry: false,
    permanentState: false,
    permanentCity: false,
    permanentPostalCode: false
  });

  const handleGetAllCountries = async () => {
    try {
      let res = await getAllCountriesAPI();
      setCountries(res?.data || []);
    } catch (err) {
      console.error("Error fetching countries:", err);
    }
  };

  useEffect(() => {
    handleGetAllCountries();
  }, []);

  const handleCountryChange = async (event) => {
    const countryName = event.target.value;
    const selectedCountry = countries.find(country => country.countryName === countryName);
    setSelectedCountry(selectedCountry);
    try {
      let res = await getStateCountryApi({ countryId: selectedCountry.countryId });
      setStates(res.data);
    }
    catch (err) {
      console.error("Error fetching states:", err);
    }
  };

  const handleStateChange = async (event) => {
    const stateName = event.target.value;
    const selectedState = states.find(state => state.stateName === stateName);
    setSelectedState(selectedState);
    try {
      let res = await getStatecityApi({ StateId: selectedState.stateId });
      setCities(res.data);
    }
    catch (err) {
      console.error("Error fetching cities:", err);
    }
  };

  const handleCityChange = (event) => {
    const cityName = event.target.value;
    const selectedCity = cities.find(city => city.cityName === cityName);
    setSelectedCity(selectedCity);
  };

  const handlePermanentCountryChange = (event) => {
    const countryName = event.target.value;
    const selectedCountry = countries.find(country => country.countryName === countryName);
    setPermanentAddress(prevPermanentAddress => ({
      ...prevPermanentAddress,
      selectedCountry: selectedCountry
    }));
  };

  const handlePermanentStateChange = (event) => {
    const stateName = event.target.value;
    const selectedState = states.find(state => state.stateName === stateName);
    setPermanentAddress(prevPermanentAddress => ({
      ...prevPermanentAddress,
      selectedState: selectedState
    }));
  };

  const handlePermanentCityChange = (event) => {
    const cityName = event.target.value;
    const selectedCity = cities.find(city => city.cityName === cityName);
    setPermanentAddress(prevPermanentAddress => ({
      ...prevPermanentAddress,
      selectedCity: selectedCity
    }));
  };




  const handlePostalCodeChange = (event) => {
    const code = event.target.value;
    setPostalCode(code);
  };

  const handleAddressChange = (event) => {
    const address = event.target.value;
    setAddress(address);
  };

  const handleStreetNameChange = (event) => {
    const street = event.target.value;
    setStreetName(street);
  };

  const handleCheckboxChange = (event) => {
    const isChecked = event.target.checked;
    setIsPermanentSameAsCurrent(isChecked);

    if (isChecked) {
      setPermanentAddress({
        address: address,
        streetName: streetName,
        selectedCountry: selectedCountry,
        selectedState: selectedState,
        selectedCity: selectedCity,
        postalCode: postalCode
      });
    } else {
      setPermanentAddress({
        address: "",
        streetName: "",
        selectedCountry: { id: "", name: "" },
        selectedState: { id: "", name: "" },
        selectedCity: { id: "", name: "" },
        postalCode: ""
      });
    }
  };



  const validateForm = () => {
    const errors = {
      address: address.trim() === "",
      streetName: streetName.trim() === "",
      selectedCountry: selectedCountry.id === "",
      selectedState: selectedState.id === "",
      selectedCity: selectedCity.id === "",
      postalCode: postalCode.trim() === ""
    };

    // Validate permanent address fields only if the checkbox is unchecked
    if (!isPermanentSameAsCurrent) {
      errors.permanentAddress = permanentAddress.address.trim() === "";
      errors.permanentStreetName = permanentAddress.streetName.trim() === "";
      errors.permanentCountry = permanentAddress.selectedCountry.id === "";
      errors.permanentState = permanentAddress.selectedState.id === "";
      errors.permanentCity = permanentAddress.selectedCity.id === "";
      errors.permanentPostalCode = permanentAddress.postalCode.trim() === "";
    }

    setErrorFields(errors);

    // Check if any field has errors
    return !Object.values(errors).some(error => error);
  };
  const handlePermanentAddressChange = (event) => {
    const { id, value } = event.target;
    setPermanentAddress(prevPermanentAddress => ({
      ...prevPermanentAddress,
      [id]: value
    }));
  };

  const handlePermanentStreetChange = (event) => {
    const { id, value } = event.target;
    setPermanentAddress(prevPermanentAddress => ({
      ...prevPermanentAddress,
      [id]: value
    }));
  };


  const handlePostAddress = async (e) => {
    const isValid = validateForm(); // Call validateForm to check for errors
    if (isValid) {
      const requestData = {
        employeeId: EmployeeId,
        addressType: "current",
        doorNo: address,
        street: streetName,
        postalCode: postalCode,
        countryId: selectedCountry.countryId,
        stateId: selectedState.stateId,
        cityId: selectedCity.cityId,
        isPermanent: isPermanentSameAsCurrent,
        permanentAddress: isPermanentSameAsCurrent ? null : {
          addressType: "permanent",
          doorNo: permanentAddress.address,
          street: permanentAddress.streetName,
          postalCode: permanentAddress.postalCode,
          countryId: permanentAddress.selectedCountry.countryId,
          stateId: permanentAddress.selectedState.stateId,
          cityId: permanentAddress.selectedCity.cityId
        }
      };

      const data = await getContactCreateAPI(requestData);
      if (data.status === 'Ok') {
        return toast.success("Data Saved Successfully", { toast: "Data Saved Successfully" });

      } else {
        return toast.error("Error saving Data", { toast: "Error saving Data" });

      }

    }
  };

  return (
    <Accordion expanded={accordionOpen} onChange={() => setAccordionOpen(!accordionOpen)} sx={{ mt: 2, boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}>
      <AccordionSummary
        expandIcon={<ArrowDropDownIcon className="Accordianicon" />}
        aria-controls="panel1-content"
        id="panel1-header"
        style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px", height: "70px" }}
      >
        <Box sx={{ pl: 3 }}>
          <img sx={{ pl: 3 }} className='personalInfo-logo' src={personalInfo} alt="info" />
        </Box>
        <Box className="acc-title" sx={{ pl: 3 }}>Address Information</Box>
      </AccordionSummary>
      <AccordionDetails>

        {/* Current Address */}
        <Grid container spacing={2} sx={{ pl: 7, pr: 5, mt: 2 }}>
          <Box className="subHeadingforinfos"> Current Address </Box>
        </Grid>
        {/* Current Address Fields */}
        <Grid container spacing={2} sx={{ pl: 4, pr: 5, mt: 1 }}>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <Box className="label">Flat, House No <span style={{ color: "red" }}>*</span></Box>
            <TextField style={{ border: "1.5px solid #B2D4F8" }} id="firstName" placeholder="Enter Address" value={address} onChange={handleAddressChange} variant="outlined" fullWidth />
            {errorFields.address && <p className="error-message">Address is required</p>}
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <Box className="label">Street Name<span style={{ color: "red" }}>*</span></Box>
            <TextField style={{ border: "1.5px solid #B2D4F8" }} id="firstName" value={streetName} onChange={handleStreetNameChange} placeholder="Street Name" variant="outlined" fullWidth />
            {errorFields.streetName && <p className="error-message">Street Name is required</p>}
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ pl: 4, pr: 5, mt: 1 }}>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <Box className="label">Country <span style={{ color: "red" }}>*</span></Box>
            <FormControl fullWidth>
              <Select
                labelId="marital-status-label"
                id="maritalStatus"
                defaultValue=""
                displayEmpty
                inputProps={{ 'aria-label': 'Without label' }}
                style={{ border: "1.5px solid #B2D4F8", color: "grey" }}
                value={selectedCountry.countryName}
                onChange={handleCountryChange}
              >
                <MenuItem value="">
                  Select Country
                </MenuItem>
                {countries.map((country, index) => (
                  <MenuItem key={index} value={country.countryName}>
                    {country.countryName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {errorFields.selectedCountry && <p className="error-message">Country is required</p>}
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <Box className="label">State <span style={{ color: "red" }}>*</span></Box>
            <FormControl fullWidth>
              <Select
                id="country"
                defaultValue=""
                placeholder="Select Country"
              
                displayEmpty
                value={selectedState.stateName}
                inputProps={{ 'aria-label': 'Without label' }}
                style={{ border: "1.5px solid #B2D4F8", color: "grey" }}
                onChange={handleStateChange}
              >
                <MenuItem value="">
                  Select State
                </MenuItem>
                {states.map((state, index) => (
                  <MenuItem key={index} value={state.stateName}>
                    {state.stateName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {errorFields.selectedState && <p className="error-message">State is required</p>}
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ pl: 4, pr: 5, mt: 1 }}>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <Box className="label">City <span style={{ color: "red" }}>*</span></Box>
            <FormControl fullWidth>
              <Select
                labelId="marital-status-label"
                id="maritalStatus"
                placeholder="Marital Status"
                style={{ border: "1.5px solid #B2D4F8", color: "grey" }}
                defaultValue=""
                displayEmpty
                onChange={handleCityChange}
                value={selectedState.cityName}
                inputProps={{ 'aria-label': 'Without label' }}
              >

<MenuItem value="">
                  Select City
                </MenuItem>
                {cities.map((city, index) => (
                  <MenuItem key={index} value={city.cityName}>
                    {city.cityName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {errorFields.selectedCity && <p className="error-message">City is required</p>}
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <Box className="label">Postal Code <span style={{ color: "red" }}>*</span></Box>
            <TextField style={{ border: "1.5px solid #B2D4F8" }} id="fathersName" placeholder="Postal Code" value={postalCode} onChange={handlePostalCodeChange} variant="outlined" fullWidth />
            {errorFields.postalCode && <p className="error-message">Postal Code is required</p>}
          </Grid>
        </Grid>

        {/* Permanent Address Section */}

        <Grid container spacing={2} sx={{ pl: 7, pr: 5, mt: 5 }}>
          <Box className="subHeadingforinfos"> Permanent Address <span style={{ color: "#FE602F", fontWeight: "400", alignSelf: "center" }}>
            <Checkbox style={{ color: "#FE602F" }} checked={isPermanentSameAsCurrent} onChange={handleCheckboxChange} />Same as Current Address
          </span>
          </Box>
        </Grid>
        {/* Permanent Address Fields */}
        {!isPermanentSameAsCurrent && (
          <Grid container spacing={2} sx={{ pl: 4, pr: 5, mt: 1.5 }}>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <Box className="label">Flat, House No <span style={{ color: "red" }}>*</span></Box>
              <TextField style={{ border: "1.5px solid #B2D4F8" }}
                id="permanentAddress"
                placeholder="Enter Address"
                value={permanentAddress.address}
                onChange={(e) => setPermanentAddress({ ...permanentAddress, address: e.target.value })}
                variant="outlined"
                fullWidth
              />
              {errorFields.permanentAddress && <p className="error-message">Permanent Address is required</p>}
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <Box className="label">Street Name<span style={{ color: "red" }}>*</span></Box>
              <TextField style={{ border: "1.5px solid #B2D4F8" }}
                id="permanentStreetName"
                placeholder="Street Address"
                value={permanentAddress.streetName}
                onChange={(e) => setPermanentAddress({ ...permanentAddress, streetName: e.target.value })}
                variant="outlined"
                fullWidth
              />
              {errorFields.permanentStreetName && <p className="error-message">Permanent Street Name is required</p>}
            </Grid>
          </Grid>
        )}
        {!isPermanentSameAsCurrent && (
          <Grid container spacing={2} sx={{ pl: 4, pr: 5, mt: 1 }}>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <Box className="label">Country <span style={{ color: "red" }}>*</span></Box>
              <FormControl fullWidth>
                <Select
                  labelId="permanent-country-label"
                  id="permanentCountry"
                  defaultValue=""
                  displayEmpty
                  inputProps={{ 'aria-label': 'Without label' }}
                  style={{ border: "1.5px solid #B2D4F8", color: "grey" }}
                  value={permanentAddress.selectedCountry.countryName}
                  onChange={handlePermanentCountryChange}
                >
                  <MenuItem value="">
                    Select Country
                  </MenuItem>
                  {countries.map((country, index) => (
                    <MenuItem key={index} value={country.countryName}>
                      {country.countryName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {errorFields.permanentCountry && <p className="error-message">Permanent Country is required</p>}
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <Box className="label">State <span style={{ color: "red" }}>*</span></Box>
              <FormControl fullWidth>
                <Select
                  id="permanentState"
                  defaultValue=""
                  placeholder="Select Country"
                  displayEmpty
                  value={permanentAddress.selectedState.stateName}
                  inputProps={{ 'aria-label': 'Without label' }}
                  style={{ border: "1.5px solid #B2D4F8", color: "grey" }}
                  onChange={handlePermanentStateChange}
                >
                  <MenuItem value="">
                    Select State
                  </MenuItem>
                  {states.map((state, index) => (
                    <MenuItem key={index} value={state.stateName}>
                      {state.stateName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {errorFields.permanentState && <p className="error-message">Permanent State is required</p>}
            </Grid>
          </Grid>
        )}
        {!isPermanentSameAsCurrent && (
          <Grid container spacing={2} sx={{ pl: 4, pr: 5, mt: 1 }}>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <Box className="label">City <span style={{ color: "red" }}>*</span></Box>
              <FormControl fullWidth>
                <Select
                  labelId="permanent-city-label"
                  id="permanentCity"
                  placeholder="City"
                  style={{ border: "1.5px solid #B2D4F8", color: "grey" }}
                  defaultValue=""
                  displayEmpty
                  value={permanentAddress.selectedCity.cityName}
                  onChange={handlePermanentCityChange}
                  inputProps={{ 'aria-label': 'Without label' }}
                >
                    <MenuItem value="">
                  Select City
                </MenuItem>
                  {cities.map((city, index) => (
                    <MenuItem key={index} value={city.cityName}>
                      {city.cityName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {errorFields.permanentCity && <p className="error-message">Permanent City is required</p>}
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <Box className="label">Postal Code <span style={{ color: "red" }}>*</span></Box>
              <TextField style={{ border: "1.5px solid #B2D4F8" }}
                id="permanentPostalCode"
                placeholder="Postal Code"
                value={permanentAddress.postalCode}
                onChange={(e) => setPermanentAddress({ ...permanentAddress, postalCode: e.target.value })}
                variant="outlined"
                fullWidth
              />
              {errorFields.permanentPostalCode && <p className="error-message">Permanent Postal Code is required</p>}
            </Grid>
          </Grid>
        )}


        {/* Buttons Section */}
        <Grid container spacing={1} sx={{ pl: 4, pr: 5, mt: 5, justifyContent: "center" }}>
          <Grid item xs={12} sm={3} md={3} lg={3}>
            <Button className="resetChanges">Reset Changes</Button>
          </Grid>
          <Grid item xs={12} sm={2} md={2} lg={2}>
            <Button className="cancelButton" onClick={handleCancel}>Cancel</Button>
          </Grid>
          <Grid item xs={12} sm={3} md={3} lg={3}>
            <Button className="saveButton" onClick={handlePostAddress}>Save</Button>
          </Grid>
        </Grid>

      </AccordionDetails>
    </Accordion>
  );
};

export default ContactInfo;
