// npm packages
import * as React from "react";
import { useState, useEffect, useContext } from "react";
import Context from "context/context";
import * as moment from "moment";
import { toast } from "react-toastify";

// custom pages
import tick from "assets/images/Frame 41.png";
import cross from "assets/images/Frame 80.png";
import Timesheet from "./Timesheet";
import getAllProject from "apis/panel/timesheet/TimeSheet";
import ApprovalApi from "apis/panel/timesheet/TimeSheet";
import Tables from "components/table/table";

// material-ui
import Grid from "@mui/material/Grid";
import Modal from "@mui/material/Modal";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import Switch from "@mui/material/Switch";
import CloseIcon from "@mui/icons-material/Close";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import Pagination from '@mui/material/Pagination';
import { Padding } from "../../../../node_modules/@mui/icons-material/index";

const CustomWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 500,
    cursor: "pointer",
    backgroundColor: "#002c5d",
    color: "#ffffff",
  },
});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "70%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const TimeSheetTable = () => {
  const moment = require('moment-timezone');
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const { state, dispatch } = useContext(Context);
  const [showTimeSheetTable, setShowTimeSheetTable] = useState(true);
  const [roleCode] = useState(state.role.roleCode);
  const [projDetails, setProjDetails] = useState([]);
  const [offset, setOffset] = useState(1);
  const [count, setCount] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const [filterValue, setFilterValue] = useState("");
  const [reject, setReject] = useState(false);
  const [view, setView] = useState(true);
  const [SwitchVal, setSwitchVal] = useState("");
  const [reason, setReason] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [dataMessage, setDataMessage] = useState("");
  const [selectedEmployeeId, setSelectedEmployeeId] = useState("");
  const [selectedEmployeeCode, setSelectedEmployeeCode] = useState("");
  const [selectedEmployeeName, setSelectedEmployeeName] = useState("");
  const [selectedDates, setSelectedDates] = useState([]);
  const [selectedWork, setSelectedWork] = useState({});
  const [sickLeaveData, setSickLeaveData] = useState([]);
  const [ptoLeaveData, setPtoLeaveData] = useState([]);
  const [totalData, setTotalData] = useState({});
  const [projects, setProjects] = useState([]);
  const [approvestatus, setapprovestatus] = useState("");
  const [selectedEmployeeWorkWeekId, setSelectedEmployeeWorkWeekId] =
    useState("");
  const [selectedDate, setSelectedDate] = useState(new Date());
  const { getAllProjectAPI } = getAllProject();
  const { putApprovalAPI, updateApprovalAPI } = ApprovalApi();
  const toggleComponent = () => {
    setShowTimeSheetTable((prevState) => !prevState);
  };
  const handleView = (isChecked) => {
    setView(isChecked);
    setSwitchVal(isChecked ? "My View" : "Manager View");
    toggleComponent();
  };
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    getWeekDates(moment().format("YYYY-MM-DD"));
    setSelectedDate(new Date());
    setReject(false);
    setReason("");
  };
  useEffect(() => {
    setDisabled(reject);
  }, [reject]);

  const handleReject = () => {
    setReject(true);
  };

  const handleGetAllProjectsApi = async () => {
    try {
      dispatch({ type: "SET_LOADING", payload: true });
      let values;
      values = {
        offset: (offset - 1) * 10,
        limit: 10,
      };
      if (searchValue) {
        values = {
          ...values,
          searchValue,
        };
      }
      if (filterValue) {
        values = {
          ...values,
          filterValue,
        };
      }
      let res = await getAllProjectAPI(values);
      setProjDetails(res?.data?.employees || []);
      setCount(res?.data?.count);
      dispatch({ type: "SET_LOADING", payload: false });
    } catch (err) {
      dispatch({ type: "SET_LOADING", payload: false });
    }
  };

  useEffect(() => {
    handleGetAllProjectsApi();
  }, [offset, searchValue, filterValue]);

  // const getWeekDates = async (selectedDate) => {
  //   let d1 = moment(selectedDate).startOf("isoWeek").format("YYYY-MM-DD");
  //   let d2 = moment(d1).add(1, "days").format("YYYY-MM-DD");
  //   let d3 = moment(d2).add(1, "days").format("YYYY-MM-DD");
  //   let d4 = moment(d3).add(1, "days").format("YYYY-MM-DD");
  //   let d5 = moment(d4).add(1, "days").format("YYYY-MM-DD");
  //   let d6 = moment(d5).add(1, "days").format("YYYY-MM-DD");
  //   let d7 = moment(d6).add(1, "days").format("YYYY-MM-DD");
  //   setSelectedDates([d1, d2, d3, d4, d5, d6, d7]);
  // };

  const getWeekDates = async (selectedDate) => {
    // Detect the user's time zone
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    // Calculate week dates based on the user's time zone
    let startOfWeek = moment.tz(selectedDate, userTimeZone).startOf("isoWeek");

    // Generate the week dates, ensuring each is in the user's time zone
    let d1 = startOfWeek.format("YYYY-MM-DD");
    let d2 = startOfWeek.clone().add(1, "days").format("YYYY-MM-DD");
    let d3 = startOfWeek.clone().add(2, "days").format("YYYY-MM-DD");
    let d4 = startOfWeek.clone().add(3, "days").format("YYYY-MM-DD");
    let d5 = startOfWeek.clone().add(4, "days").format("YYYY-MM-DD");
    let d6 = startOfWeek.clone().add(5, "days").format("YYYY-MM-DD");
    let d7 = startOfWeek.clone().add(6, "days").format("YYYY-MM-DD");
    setSelectedDates([d1, d2, d3, d4, d5, d6, d7]);
};


  const handleEmployeeView = async (
    employeeId,
    employeeCode,
    employeeName,
    startDate,
    employeeWorkWeekId
  ) => {
    try {
      dispatch({ type: "SET_LOADING", payload: true });
      setSelectedEmployeeId(employeeId);
      setSelectedEmployeeCode(employeeCode);
      setSelectedEmployeeName(employeeName);
      handleFindone(
        moment(startDate).format("YYYY-MM-DD"),
        employeeId,
        employeeWorkWeekId
      );
    } catch (err) {
      dispatch({ type: "SET_LOADING", payload: false });
    }
  };

  const handleFindone = async (startDate, employeeId, employeeWorkWeekId) => {
    let values;
    console.log(startDate,"start")
    values = {
      week: startDate,
      employeeId: employeeId,
      employeeWorkWeekId: employeeWorkWeekId,
    };
    try {
      dispatch({ type: "SET_LOADING", payload: true });
      let res = await putApprovalAPI(values);
      if (res?.data?.message) {
        setDataMessage(res?.data?.message);
      } else {
        setDataMessage("");
      }
      if (res.status === "Ok" && res?.data) {
        if (res?.data?.employeeWorkWeeks) {
          setSelectedWork(res?.data?.employeeWorkWeeks);
        }
        if (res?.data?.employeeProject) {
          setapprovestatus(res?.data?.access?.status);
          let employeeProject = res?.data?.employeeProject;
          setProjects(employeeProject);
          const dateSums = {};
          let allTotal = 0;
          employeeProject?.forEach((project) => {
            project?.tasks?.forEach((task) => {
              Object.entries(task).forEach(([key, value]) => {
                if (/^\d{4}-\d{2}-\d{2}$/.test(key)) {
                  dateSums[key] =
                    (dateSums[key] || 0) + parseFloat(value.hoursLogged);
                  allTotal = allTotal + parseFloat(value.hoursLogged);
                }
              });
            });
          });
          setTotalData(dateSums);
          if (res?.data?.timeoff?.length > 0 && res?.data?.timeoff[0]) {
            let employeeSick = res?.data?.timeoff[0];
            setSickLeaveData(employeeSick);
          }
          if (res?.data?.timeoff?.length > 0 && res?.data?.timeoff[1]) {
            let employeePto = res?.data?.timeoff[1];
            setPtoLeaveData(employeePto);
          }
        }
      }
      const access = res?.data?.access;
      console.log(access, "access")
      if (access && !access?.enableApproveBtn && !access?.enableRejectBtn) {
        setDisabled(true);
      } else if (
        access &&
        access?.enableApproveBtn &&
        access?.enableRejectBtn
      ) {
        setDisabled(false);
      }
      getWeekDates(moment(startDate).format("YYYY-MM-DD"));
      setSelectedDate(new Date(startDate));
      handleOpen();
      dispatch({ type: "SET_LOADING", payload: false });
    } catch (err) {
      console.log({ err });
    }
  };

  const handleRejections = async (e) => {
    if (!reason.trim()) {
      toast.error("Reason cannot be empty");
      return;
    }
    dispatch({ type: "SET_LOADING", payload: true });
    const values = {
      employeeWorkWeekId: selectedWork?.employeeWorkWeekId,
      status: "Rejected",
      reason: reason,
    };
    const data = await updateApprovalAPI(values);
    if (data.status === "Ok") {
      handleClose();
      dispatch({ type: "SET_LOADING", payload: false });
      if (offset > 1) {
        setOffset(1);
      } else {
        handleGetAllProjectsApi();
      }
      return toast.success(
        "The timesheet has been rejected with a reason provided",
        { toast: "The timesheet has been rejected with a reason provided" }
      );
    } else {
      dispatch({ type: "SET_LOADING", payload: false });
      return toast.error(data.error.message);
    }
  };

  const handleApprove = async (e) => {
    dispatch({ type: "SET_LOADING", payload: true });
    const values = {
      employeeWorkWeekId: selectedWork?.employeeWorkWeekId,
      status: "Approved",
    };
    const data = await updateApprovalAPI(values);
    if (data.status === "Ok") {
      handleClose();
      dispatch({ type: "SET_LOADING", payload: false });
      if (offset > 1) {
        setOffset(1);
      } else {
        handleGetAllProjectsApi();
      }
      return toast.success("The timesheet has been successfully approved!", {
        toast: "The timesheet has been successfully approved!",
      });
    } else {
      dispatch({ type: "SET_LOADING", payload: false });
      return toast.error(data.error.message);
    }
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
    setReject(false);
    getWeekDates(date);
    handleFindone(
      moment(date).format("YYYY-MM-DD"),
      selectedEmployeeId,
      selectedEmployeeWorkWeekId
    );
  };

  const handlePagination = (value) => {
    setOffset(value);
  };

  return (
    <div>
      {showTimeSheetTable && projDetails.length > 0 && (
        <>
          <div
            style={{
              backgroundColor: "white",
              borderRadius: "10px",
            }}
          >
            <Tables
              title={
                <Box className="EmployeeRoster" sx={{ pt: 2, pb: 2, pl: 2 }}>
                  Manager View
                </Box>
              }
              searchBar={true}
              filter={true}
              role={roleCode}

              switchView={
                <Grid
                  item
                  xs={12}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <CustomWidthTooltip
                    title={
                      <span>
                        Toggle to switch back to Timesheet View <br />
                        to fill and submit your timesheet.
                      </span>
                    }
                  >
                    <div className="Switch-div">
                      <Switch
                        style={{ color: "#0070E9" }}
                        checked={view}
                        onChange={(e) => handleView(e.target.checked)}
                      />
                      <h3 style={{ margin: "7px 0px 0px 0px", color: "#0070E9" }}>
                        {SwitchVal}
                      </h3>
                    </div>
                  </CustomWidthTooltip>
                  <div className="tsbods" style={{ color: "#0070E9" }}>
                    Back to My Timesheet
                  </div>
                </Grid>
              }
              setSearchValue={setSearchValue}
              filterValue={filterValue}
              setFilterValue={setFilterValue}
              filterValues={[
                { label: "Start Date", value: "" },
                { label: "Employee Id", value: "employeeCode" },
                { label: "Employee Name", value: "employeeName" },
              ]}

              heading={[
                "S.No",
                "Employee Id",
                "Employee Name",
                "Role",
                "Project Name",
                "Start Date",
                "End Date",
                "Action",
              ]}
              data={projDetails?.map((project, index) => [
                index + 1 + (offset - 1) * 10,
                project.employeeCode,
                project.firstName,
                project?.projectRoleName,
                project.projectName,
                <span
                  style={{
                    fontWeight: "700",
                    color: "#008A27",
                  }}
                >
                  {project.startDate
                    ? moment(project.startDate).format("DD-MMM-YYYY")
                    : ""}
                </span>,
                <span
                  style={{
                    fontWeight: "700",
                    color: "#E90000",
                  }}
                >
                  {project.endDate
                    ? moment(project.endDate).format("DD-MMM-YYYY")
                    : ""}
                </span>,
                <CustomWidthTooltip
                  title='Click to view details'
                >
                  <span className="jobType">

                    <RemoveRedEyeIcon
                      style={{
                        color: "#FE602F",
                        cursor: "pointer",
                        textAlign: "center",
                      }}
                      onClick={(e) => {
                        setSelectedEmployeeWorkWeekId(
                          project?.employeeWorkWeekId
                        );
                        handleEmployeeView(
                          project.employeeId,
                          project.employeeCode,
                          project.firstName,
                          project.startDate,
                          project?.employeeWorkWeekId
                        );
                      }}
                    />
                  </span>
                </CustomWidthTooltip>
              ])}
              offset={offset}
              setOffset={setOffset}
              count={count}
              placeholder="Search by Employee ID & Employee Name"
            />
          </div>

          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style} style={{ height: "450px", overflowY: "auto" }}>
              <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                  position: "absolute",
                  right: 0,
                  top: 0,
                  color: "inherit",
                  zIndex: 1,
                }}
              >
                <CloseIcon />
              </IconButton>
              <Grid container sx={{ mt: 2, mb: 2, gap: "20px" }}>
                <Grid
                  item
                  xs={12}
                  sm={4}
                  md={4}
                  lg={4}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <Box className="modalname">
                    {selectedEmployeeCode} - {selectedEmployeeName}
                  </Box>
                </Grid>
                <Grid item xs={12} sm={3} md={3} lg={3}>
                  <LocalizationProvider fullWidth dateAdapter={AdapterDateFns}>
                    <DatePicker
                      id="selectedDate"
                      name="selectedDate"
                      value={selectedDate}
                      className="dateInput"
                      onAccept={handleDateChange}
                      format="dd-MMM-yyyy"
                      maxDate={new Date()}
                      renderInput={(params) => (
                        <TextField {...params} fullWidth />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
                {console.log(disabled, 'aaaaaaaaaaaaaaaaa')}
                {dataMessage == "" && !reject && (
                  <Grid
                    item
                    xs={12}
                    sm={4}
                    md={4}
                    lg={4}
                    style={{ justifyContent: "center", textAlign: "center" }}
                  >
                    {approvestatus === "Pending" && !disabled ? (
                      <>
                        <Button
                          className="copybutton"
                          sx={{ mr: "10px", ml: "10px" }}
                          onClick={handleReject}
                          disabled={disabled}
                        >
                          Reject
                        </Button>
                        <Button
                          className="approvalbutton"
                          onClick={handleApprove}
                          disabled={disabled}
                        >
                          Approve
                        </Button>
                      </>
                    ) : (
                      approvestatus === "Pending" && (
                       <Grid className="pendingReqMessage">
                        Timesheet Status: Pending Manager Approval
                       </Grid>
                      ))}


                    {approvestatus === "Approved" && (
                        <Grid className="pendingReqMessageApprove">
                       Timesheet Status: Approved
                       </Grid>
                    )}
                    {approvestatus === "Rejected" && (
                       <Grid className="pendingReqMessage">
                      Timesheet Status: Rejected by Manager
                      </Grid>
                    )}
                  </Grid>
                )}
              </Grid>
              {reject && (
                <Grid container sx={{ mt: 2, mb: 2 }}>
                  <Grid item xs={12} sm={9} md={9} lg={9} sx={{ mt: 2 }}>
                    <Box className="label">
                      Reason <span style={{ color: "red" }}>*</span>
                    </Box>
                    <TextField
                      sx={{ height: "59px" }}
                      id="LastName"
                      name="LastName"
                      placeholder="Reason"
                      value={reason}
                      onChange={(e) => {
                        setReason(e.target.value);
                      }}
                      variant="outlined"
                      fullWidth
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={2}
                    md={2}
                    lg={2}
                    className="tickcrossgrid"
                    sx={{ pl: 2, pt: 6 }}
                  >
                    <Box>
                      {" "}
                      <img
                        className="tick"
                        onClick={(e) => {
                          {
                            setReject(!reject);
                            setReason("");
                          }
                        }}
                        src={tick}
                        alt="Ok"
                        style={{ cursor: "pointer" }}
                      />
                    </Box>
                    <Box>
                      {" "}
                      <img
                        className="tick"
                        onClick={handleRejections}
                        src={cross}
                        alt="Cancel"
                        style={{ cursor: "pointer" }}
                      />
                    </Box>
                  </Grid>
                </Grid>
              )}
              {dataMessage == "" ? (
                <table className="timesheet-table">
                  <thead>
                    <tr>
                      <th style={{ width: "15%", height: "60px" }}>Projects</th>
                      <th style={{ width: "15%", height: "60px" }}>Tasks</th>
                      {selectedDates?.map((day, index) => (
                        <th
                          key={index}
                          className="daystyle"
                          style={{
                            width: "10%",
                            height: "60px",
                          }}
                        >
                          {/* {new Date(day).toLocaleDateString("en-US", {
                            weekday: "short",
                          })} */}
                           {moment.tz(day, userTimeZone).format("ddd")}
    {console.log(day, "tesdfg")}
                          <br />
                          <span
                            style={{
                              color: "#0070E9",
                              fontWeight: "700",
                              fontSize: "14px",
                              paddingTop: "6px",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {/* {moment(day).format("DD-MMM-YYYY")} */}
                            {moment.tz(day, userTimeZone).format("DD-MM-YYYY")}
                          </span>
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {projects?.map((project) => (
                      <React.Fragment key={project.name}>
                        {project?.tasks?.map((task, index) => (
                          <tr key={`${project.name}_${task}`}>
                            {index === 0 && (
                              <td
                                rowSpan={project?.tasks?.length}
                                className="projectntaskname"
                              >
                                {project.projectName}
                              </td>
                            )}
                            <td className="projectntaskname">
                              {task.taskName}
                            </td>
                            {selectedDates?.map((day, index) => (
  <>
    {task[day]?.hoursLogged > 0 ? (
      <CustomWidthTooltip
        title={
          <>
            <span
              style={{
                fontFamily: "Inter",
                fontWeight: "600",
              }}
            >
              Work Arrangement:
            </span>{" "}
            {task[day]?.workLocationType || ""} <br />{" "}
            <div
              style={{
                borderBottom: "1px solid grey",
                marginTop: "2%",
                marginBottom: "2%",
              }}
            ></div>
            <span
              style={{
                fontFamily: "Inter",
                fontWeight: "600",
              }}
            >
              Description:
            </span>{" "}
            {task[day]?.description || ""}
            <div
              style={{
                borderBottom: "1px solid grey",
                marginTop: "2%",
                marginBottom: "2%",
              }}
            ></div>
            <span
              style={{
                fontFamily: "Inter",
                fontWeight: "600",
              }}
            >
              Project Owner:
            </span>{" "}
            {task[day]?.projectOwnerName || ""}
            <div
              style={{
                borderBottom: "1px solid grey",
                marginTop: "2%",
                marginBottom: "2%",
              }}
            ></div>
            <span
              style={{
                fontFamily: "Inter",
                fontWeight: "600",
              }}
            >
              Project Shift:
            </span>{" "}
            {task[day]?.projectShiftName || ""}
          </>
        }
      >
        <td
          key={index}
          style={{
            textAlign: "center",
            color: task[day]?.hoursLogged > 0 ? "#00B562" : "inherit",
            fontSize: "14px",
            fontWeight: "700",
            cursor: "pointer",
          }}
        >
          {task[day]?.hoursLogged || 0}
        </td>
      </CustomWidthTooltip>
    ) : (
      <td
        key={index}
        style={{
          textAlign: "center",
          color: "inherit",
          fontSize: "14px",
          fontWeight: "700",
        }}
      >
        {task[day]?.hoursLogged || 0}
      </td>
    )}
  </>
))}

                          </tr>
                        ))}
                      </React.Fragment>
                    ))}
                    <tr>
                      <td
                        colSpan={2}
                        style={{
                          color: "#E97000",
                          fontSize: "14px",
                          height: "40px",
                          fontWeight: "700",
                          textAlign: "center",
                          height: "40px",
                        }}
                      >
                        Sick Leave
                      </td>
                      {selectedDates?.map((day, index) => (
                        <CustomWidthTooltip
                          title={sickLeaveData[day]?.description || ""}
                        >
                          <td
                            key={index}
                            style={{
                              color: "#E97000",
                              height: "40px",
                              textAlign: "center",
                              fontWeight: 700,
                              cursor: "pointer",
                            }}
                          >
                            {sickLeaveData[day]?.hoursLogged || 0}
                          </td>
                        </CustomWidthTooltip>
                      ))}
                    </tr>
                    <tr>
                      <td
                        colSpan={2}
                        style={{
                          color: "#0070E9",
                          fontSize: "14px",
                          height: "40px",
                          fontWeight: "700",
                          textAlign: "center",
                        }}
                      >
                        Privilege Leave
                      </td>
                      {selectedDates?.map((day, index) => (
                        <CustomWidthTooltip
                          title={ptoLeaveData[day]?.description || ""}
                        >
                          <td
                            key={index}
                            style={{
                              color: "#0070E9",
                              height: "40px",
                              textAlign: "center",
                              fontWeight: 600,
                            }}
                          >
                            {ptoLeaveData[day]?.hoursLogged || 0}
                          </td>
                        </CustomWidthTooltip>
                      ))}
                    </tr>
                    <tr>
                      <td
                        colSpan={2}
                        style={{
                          color: "#00B562",
                          fontSize: "14px",
                          height: "40px",
                          fontWeight: "700",
                          textAlign: "center",
                        }}
                      >
                        Total Working Hours
                      </td>
                      {selectedDates.map((day, index) => (
                        <td
                          style={{
                            color: "#00B562",
                            height: "40px",
                            textAlign: "center",
                            fontWeight: 600,
                          }}
                          key={index}
                        >
                          {totalData?.[day] || 0}
                        </td>
                      ))}
                    </tr>
                  </tbody>
                </table>
              ) : (
                <Box sx={{ mt: 10 }}>
                  <Grid
                    style={{
                      color: "red",
                      fontWeight: "700",
                      fontSize: "16px",
                      textAlign: "center",
                    }}
                  >
                    {dataMessage}
                  </Grid>
                </Box>
              )}
            </Box>
          </Modal>
        </>
      )}
      {showTimeSheetTable ? projDetails.length == 0 && (
        <div
          style={{
            backgroundColor: "white",
            borderRadius: "10px",
          }}
        >

          <Tables
            title={
              <Box className="EmployeeRoster" sx={{ pt: 2, pb: 2, pl: 2 }}>
                Manager View
              </Box>
            }
            searchBar={true}
            filter={true}
            role={roleCode}

            switchView={
              <Grid
                item
                xs={12}
                style={{ display: "flex", alignItems: "center" }}
              >
                <CustomWidthTooltip
                  title={
                    <span>
                      Toggle to switch back to Timesheet View <br />
                      to fill and submit your timesheet.
                    </span>
                  }
                >
                  <div className="Switch-div">
                    <Switch
                      style={{ color: "#0070E9" }}
                      checked={view}
                      onChange={(e) => handleView(e.target.checked)}
                    />
                    <h3 style={{ margin: "7px 0px 0px 0px", color: "#0070E9" }}>
                      {SwitchVal}
                    </h3>
                  </div>
                </CustomWidthTooltip>
                <div className="tsbods" style={{ color: "#0070E9" }}>
                  Back to My Timesheet
                </div>
              </Grid>
            }


            setSearchValue={setSearchValue}
            filterValue={filterValue}
            setFilterValue={setFilterValue}
            filterValues={[
              { label: "Start Date", value: "" },
              { label: "Employee Id", value: "employeeCode" },
              { label: "Employee Name", value: "employeeName" },
            ]}
            offset={offset}
            setOffset={setOffset}
            count={0}
            placeholder="Search by Employee ID & Employee Name"



          />



        </div>
      ) : (
        <Timesheet />
      )}



    </div>
  );
};

export default TimeSheetTable;