import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import dayjs from "dayjs";

import "./Project.css";
import ProjectAPI from "apis/panel/project/project";
import { toastCss } from "config/config";

import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";
import Loader from "components/Loader";
import Pagination from "@mui/material/Pagination";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import {
  FormControlLabel,
  IconButton,
  Modal,
  Typography,
} from "../../../../node_modules/@mui/material/index";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { renderTimeViewClock } from "@mui/x-date-pickers/timeViewRenderers";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import Checkbox from "@mui/material/Checkbox";
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    background: {
      paper: '#fff',
    },
    text: {
      primary: '#173A5E',
      secondary: '#46505A',
    },
    action: {
      active: '#001E3C',
    },
    success: {
      main: '#009688', 
      dark: '#00675b', 
    },
    primary: {
      main: '#FE602F', 
    },
  },
});



const CustomWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 500,
    cursor: "pointer",
    backgroundColor: "#002c5d",
    color: "#ffffff",
  },
});

const ShiftsTable = ({ projectId }) => {
  const [loader2, setLoader2] = useState(false);

  const [handlePopup, setHandlePopup] = useState({
    type: "",
    open: false,
  });

  const [shiftList, setShiftList] = useState([]);

  const [count, setCount] = useState(0);
  const [offset, setOffset] = useState(1);

  const [load, setLoad] = useState(false);

  const [newShiftObj, setNewShiftObj] = useState({
    projectShiftId: "",
    shiftName: "",
    shiftAllowance:"",
    foodAllowance:"",
    shiftStartTime: null,
    shiftEndTime: null,
    status: "Active",
    isDefault: false,
  });

  const [editShiftObj, setEditShiftObj] = useState({});
  const [defaultShift, setDefaultShift] = useState({});

  const { getAllShiftDetailsAPI, createShiftAPI, updateShiftAPI } =
    ProjectAPI();

  const handleOpenEditShiftPopup = (shift) => {
    setNewShiftObj({
      projectShiftId: shift.projectShiftId,
      shiftName: shift.shiftName,
      shiftAllowance: shift.shiftAllowance,
      foodAllowance: shift.foodAllowance,
      shiftStartTime: dayjs(shift.shiftStartTime, "hh:mm A"),
      shiftEndTime: dayjs(shift.shiftEndTime, "hh:mm A"),
      status: shift.status,
      isDefault: shift.isDefault,
    });
    setEditShiftObj({ projectShiftId: shift.projectShiftId });
    setHandlePopup({ type: "EDIT", open: true });
  };

  const handleClosePopup = () => {
    setNewShiftObj({
      projectShiftId: "",
      shiftName: "",
      shiftAllowance:"",
      foodAllowance:"",
      shiftStartTime: null,
      shiftEndTime: null,
      status: "Active",
      isDefault: false,
    });
    setHandlePopup({
      type: "",
      open: false,
    });
    setEditShiftObj({});
  };

  const handleAddNewShift = async () => {
    try {
      const { shiftName, status, shiftAllowance, foodAllowance,shiftStartTime, shiftEndTime, isDefault } =
        newShiftObj;
      if (!shiftName || !status || !shiftStartTime || !shiftEndTime || !shiftAllowance || !foodAllowance) {
        return toast.error("Please fill in all required fields.");
      }


      if (isDefault && status === "Inactive") {
        return toast.error("The default project shift record cannot be deactivated.", {
          toastId: "default-inactive-error",
        });
      }

      if (handlePopup?.type === "CREATE") {
        const values = {
          projectId,
          shiftName,
          shiftAllowance,
          foodAllowance,
          status,
          shiftStartTime,
          shiftEndTime,
          isDefault,
        };
        if (values?.isDefault && Object.keys(defaultShift)?.length > 0) {
          return toast.error(
            `Already default Shift is assigned for the Project Shift - "${defaultShift?.shiftName}"`,
            { toastId: "default-shift-error" }
          );
        }
        setLoader2(true);
        const res = await createShiftAPI(values);
        setLoader2(false);
        if (res.status === "Ok") {
          toast.success("Record created successfully.", toastCss);
          setLoad((prev) => !prev);
          handleClosePopup();
        } else {
          toast.error(res.error.message, toastCss);
        }
      } else if (handlePopup?.type === "EDIT") {
        if (editShiftObj && Object.keys(editShiftObj)?.length === 0) {
          return toast.error("Please change at least one field.");
        }

        const {
          shiftName,
          status,
          shiftAllowance,
          foodAllowance,
          shiftStartTime,
          shiftEndTime,
          isDefault,
          projectShiftId,
        } = editShiftObj;

        let values = { projectShiftId };

        if (shiftName)
          values = {
            ...values,
            shiftName,
          };
          if (shiftAllowance)
            values = {
              ...values,
              shiftAllowance,
            };
            if (foodAllowance)
              values = {
                ...values,
                foodAllowance,
              };
  

        if (status)
          values = {
            ...values,
            status,
          };

        if (shiftStartTime)
          values = {
            ...values,
            shiftStartTime,
          };

        if (shiftEndTime)
          values = {
            ...values,
            shiftEndTime,
          };

        if (typeof isDefault === "boolean")
          values = {
            ...values,
            isDefault,
          };

        if (values?.isDefault && Object.keys(defaultShift)?.length > 0) {
          return toast.error(
            `Already default Shift is assigned for the Project Shift - "${defaultShift?.shiftName}"`,
            { toastId: "default-shift-error" }
          );
        }

        setLoader2(true);
        const res = await updateShiftAPI(values);
        setLoader2(false);
        if (res.status === "Ok") {
          toast.success("Record updated successfully.", toastCss);
          setLoad((prev) => !prev);
          handleClosePopup();
        } else {
          toast.error(res.error.message, toastCss);
        }
      }
    } catch (error) {
      setLoader2(false);
    }
  };

  const getAllShiftDetails = async () => {
    try {
      let values = {
        offset: (offset - 1) * 10,
        limit: 10,
        projectId,
      };
      setLoader2(true);
      let res = await getAllShiftDetailsAPI(values);
      setShiftList(res?.data?.data || []);
      setCount(res?.data?.count);
      setDefaultShift(res?.data?.defaultShift);
      setLoader2(false);
    } catch (err) {
      setLoader2(false);
    }
  };

  const handlePagination = (value) => {
    setOffset(value);
  };

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    padding: "15px",
  }));

  const StyledTableCellBody = styled(TableCell)(({ theme }) => ({
    padding: "10px",
    paddingLeft: "15px",
  }));

  useEffect(() => {
    getAllShiftDetails();
  }, [projectId, offset, load]);

  return (
    <div className="ProjectCont">
      <Loader loading={loader2} />
      <Grid
        container
        className="editsetupmaincont"
        sx={{ pl: 3, pr: 3, mt: 2, mb: 1 }}
      >
        <Box className="addnewprojecthead">Shift</Box>
        <Box>
          <Button
            className="projsetupedit"
            onClick={() => {
              setHandlePopup({ type: "CREATE", open: true });
            }}
          >
            Add New Shift
          </Button>
        </Box>
      </Grid>
      <Grid container spacing={2} sx={{ pt: 1, pl: 4, pr: 3, mt: 2 }}>
        <TableContainer
          component={Paper}
          sx={{ boxShadow: "none", border: 1, borderColor: "grey.300" }}
        >
          <Table aria-label="collapsible table" className="employeeRosterMediaQuery">
            <TableHead>
              <TableRow>
                <StyledTableCell style={{whiteSpace:"nowrap"}}>
                  <b className="projectOwnertable-header">Shift Name</b>
                </StyledTableCell>
                <StyledTableCell >
                  <b className="projectOwnertable-header">Shift Timing</b>
                </StyledTableCell>
                <StyledTableCell style={{whiteSpace:"nowrap"}}>
                  <b className="projectOwnertable-header">Food<br/> Allowance</b>
                </StyledTableCell>
                <StyledTableCell style={{whiteSpace:"nowrap"}}>
                  <b className="projectOwnertable-header">Shift <br/> Allowance</b>
                </StyledTableCell>
                <StyledTableCell style={{whiteSpace:"nowrap"}}>
                  <b className="projectOwnertable-header">Status</b>
                </StyledTableCell>
                <StyledTableCell style={{whiteSpace:"nowrap"}}>
                  <b className="projectOwnertable-header">Default</b>
                </StyledTableCell>
                <StyledTableCell style={{whiteSpace:"nowrap"}}>
                  <b className="projectOwnertable-header">Action</b>
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {shiftList && shiftList.length > 0 ? (
                shiftList.map((shift, index) => (
                  <TableRow
                    key={shift?.projectShiftId}
                    className="tablebody-tablerowmain"
                  >
                    <StyledTableCellBody>
                      <b className="projectOwnerTable-body">
                        {shift?.shiftName}
                      </b>
                    </StyledTableCellBody>
                    <StyledTableCellBody style={{whiteSpace:"nowrap"}}>
                      <b className="projectOwnerTable-body">
                        {shift?.shiftStartTime} to {shift?.shiftEndTime}
                      </b>
                    </StyledTableCellBody>

                    <StyledTableCellBody>
                      <b className="projectOwnerTable-body">
                        {shift?.foodAllowance}
                      </b>
                    </StyledTableCellBody>
                    <StyledTableCellBody>
                      <b className="projectOwnerTable-body">
                        {shift?.shiftAllowance}
                      </b>
                    </StyledTableCellBody>
                    <StyledTableCellBody>
                      <b className="projectOwnerTable-body">
                        {shift?.status === "Active" ? (
                          <span style={{ color: "green", fontWeight: "bold" }}>
                            {shift?.status}
                          </span>
                        ) : (
                          <span style={{ color: "red", fontWeight: "bold" }}>
                            {shift?.status}
                          </span>
                        )}
                      </b>
                    </StyledTableCellBody>
                    <StyledTableCellBody>
                      <b className="projectOwnerTable-body">
                        <Checkbox
                          checked={shift?.isDefault}
                          sx={{
                            color: "rgb(0, 44, 93)",
                            "&.Mui-checked": {
                              color: "rgb(0, 44, 93)",
                            },
                            cursor: "inherit",
                          }}
                        />
                      </b>
                    </StyledTableCellBody>
                    <StyledTableCellBody>
                      <b className="projectOwnerTable-body">
                        <CustomWidthTooltip title="Click to edit details">
                          <IconButton
                            style={{ color: "rgb(0, 121, 233)", height: "2px" }}
                          >
                            <EditOutlinedIcon
                              onClick={() => {
                                handleOpenEditShiftPopup(shift);
                              }}
                            />
                          </IconButton>
                        </CustomWidthTooltip>
                      </b>
                    </StyledTableCellBody>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <StyledTableCellBody
                    colSpan={6}
                    style={{
                      textAlign: "center",
                      fontSize: "16px",
                      color: "grey",
                    }}
                  >
                    No Shifts available
                  </StyledTableCellBody>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>

      <Modal
        open={handlePopup?.open}
        onClose={handleClosePopup}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >

        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            // width: "38%",
            bgcolor: "background.paper",
            boxShadow: 24,
            height: "auto",
            borderRadius: 3,
            p: 3,
            overflowY: "scroll",
          }}
        >
          <IconButton
            aria-label="close"
            onClick={handleClosePopup}
            sx={{
              position: "absolute",
              top: 0,
              right: 0,
              color: "inherit",
              zIndex: 1,
            }}
          >
            <CloseIcon />
          </IconButton>

          <Grid container alignItems="center">
            {handlePopup?.type === "EDIT" ? (
              <Grid item xs>
                <Typography
                  variant="h6"
                  style={{ color: "#0070E9", fontWeight: "Bold" }}
                >
                  Edit Shift
                </Typography>
              </Grid>
            ) : (
              <Grid item xs>
                <Typography
                  variant="h6"
                  style={{ color: "#0070E9", fontWeight: "bold" }}
                >
                  Add New Shift
                </Typography>
              </Grid>
            )}

            
          </Grid>
          <Grid container spacing={2}>
            <Grid container spacing={2} sx={{ p: "10px" }}>
              <Grid item xs={6} lg={6} sx={{ mt: 1 }}>
                <Box className="addProjectOwnerPopup">
                  Shift Name <span style={{ color: "red" }}>*</span>
                </Box>
                <TextField
                  className="editvalue"
                  placeholder="Enter Shift Name"
                  value={newShiftObj?.shiftName}
                  onChange={(e) => {
                    setNewShiftObj({
                      ...newShiftObj,
                      shiftName: e.target.value,
                    });
                    if (handlePopup?.type === "EDIT")
                      setEditShiftObj({
                        ...editShiftObj,
                        shiftName: e.target.value,
                      });
                  }}
                  sx={{ width: "100%" }}
                />
              </Grid>

              <Grid item xs={6} lg={6} sx={{ mt: 1 }}>
                <Box className="addProjectOwnerPopup">
                  Food Allowance <span style={{ color: "red" }}>*</span>
                </Box>
                <TextField
                  className="editvalue"
                  placeholder="Enter Food Allowance"
                  value={newShiftObj?.foodAllowance}
                  onChange={(e) => {
                    setNewShiftObj({
                      ...newShiftObj,
                      foodAllowance: e.target.value,
                    });
                    if (handlePopup?.type === "EDIT")
                      setEditShiftObj({
                        ...editShiftObj,
                        foodAllowance: e.target.value,
                      });
                  }}
                  sx={{ width: "100%" }}
                />
              </Grid>

              <Grid item xs={6} lg={6} sx={{ mt: 1 }}>
                <Box className="addProjectOwnerPopup">
                  Shift Allowance <span style={{ color: "red" }}>*</span>
                </Box>
                <TextField
                  className="editvalue"
                  placeholder="Enter Shift Allownace"
                  value={newShiftObj?.shiftAllowance}
                  onChange={(e) => {
                    setNewShiftObj({
                      ...newShiftObj,
                      shiftAllowance: e.target.value,
                    });
                    if (handlePopup?.type === "EDIT")
                      setEditShiftObj({
                        ...editShiftObj,
                        shiftAllowance: e.target.value,
                      });
                  }}
                  sx={{ width: "100%" }}
                />
              </Grid>
              <Grid item xs={6} lg={6} sx={{ mt: 1 }}>
                <Box className="addProjectOwnerPopup">
                  Status <span style={{ color: "red" }}>*</span>
                </Box>
                <FormControl sx={{ width: "100%" }}>
                  <Select
                    value={newShiftObj?.status}
                    defaultValue
                    displayEmpty
                    onChange={(e) => {
                      setNewShiftObj({
                        ...newShiftObj,
                        status: e.target.value,
                      });
                      if (handlePopup?.type === "EDIT")
                        setEditShiftObj({
                          ...editShiftObj,
                          status: e.target.value,
                        });
                    }}
                    style={{ color: "grey" }}
                  >
                    <MenuItem value="Active">Active</MenuItem>
                    <MenuItem value="Inactive">Inactive</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>

            <ThemeProvider theme={theme}>
      <Grid container spacing={2} sx={{ p: '10px' }}>
        <Grid item xs={6} lg={6} sx={{ mt: 1 }}>
          <Box className="addProjectOwnerPopup">
            Shift Start Time <span style={{ color: 'red' }}>*</span>
          </Box>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={['TimePicker']} style={{ overflowY: 'scroll' }}>
              <TimePicker
                label="Start Time"
                value={newShiftObj?.shiftStartTime}
                sx={{ width: '100%' }}
                onChange={(newValue) => {
                  setNewShiftObj({
                    ...newShiftObj,
                    shiftStartTime: newValue.format('hh:mm A'),
                  });
                  if (handlePopup?.type === 'EDIT')
                    setEditShiftObj({
                      ...editShiftObj,
                      shiftStartTime: newValue.format('hh:mm A'),
                    });
                }}
                viewRenderers={{
                  hours: renderTimeViewClock,
                  minutes: renderTimeViewClock,
                  seconds: renderTimeViewClock,
                }}
              />
            </DemoContainer>
          </LocalizationProvider>
        </Grid>

        <Grid item xs={6} lg={6} sx={{ mt: 1 }}>
          <Box className="addProjectOwnerPopup">
            Shift End Time <span style={{ color: '' }}>*</span>
          </Box>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={['TimePicker']}>
              <TimePicker
                label="End Time"
                value={newShiftObj?.shiftEndTime}
                sx={{ width: '100%' }}
                onChange={(newValue) => {
                  setNewShiftObj({
                    ...newShiftObj,
                    shiftEndTime: newValue.format('hh:mm A'),
                  });
                  if (handlePopup?.type === 'EDIT')
                    setEditShiftObj({
                      ...editShiftObj,
                      shiftEndTime: newValue.format('hh:mm A'),
                    });
                }}
                viewRenderers={{
                  hours: renderTimeViewClock,
                  minutes: renderTimeViewClock,
                  seconds: renderTimeViewClock,
                }}
              />
            </DemoContainer>
          </LocalizationProvider>
        </Grid>


        
      </Grid>
    </ThemeProvider>

    <Grid container spacing={2} sx={{ p: "10px" }}>
              <Grid item xs={6} lg={6}>
                <Box className="addProjectOwnerPopup">
                  Default
                  {/* <Checkbox
                    inputProps={{ "aria-label": "controlled" }}
                    checked={newShiftObj?.isDefault}
                    sx={{
                      color: "rgb(0, 44, 93)",
                      "&.Mui-checked": {
                        color: "rgb(0, 44, 93)",
                      },
                      "& .MuiSvgIcon-root": { fontSize: 28 },
                    }}
                    onChange={(e) => {
                      setNewShiftObj({
                        ...newShiftObj,
                        isDefault: !newShiftObj?.isDefault,
                      });
                      if (handlePopup?.type === "EDIT")
                        setEditShiftObj({
                          ...editShiftObj,
                          isDefault: !newShiftObj?.isDefault,
                        });
                    }}
                  /> */}


<Checkbox
    sx={{
      color: "rgb(0, 44, 93)",
      "&.Mui-checked": {
        color: "rgb(0, 44, 93)",
      },
      "& .MuiSvgIcon-root": { fontSize: 28 },
    }}
  checked={newShiftObj.isDefault}
  onChange={(event) => {
    if (newShiftObj.status === "Inactive" && event.target.checked) {
      toast.error("An inactive record cannot be set as default", {
        toastId: "default-inactive-error",
      });
      return;
    }
    setNewShiftObj({
      ...newShiftObj,
      isDefault: event.target.checked,
    });

    if (handlePopup?.type === "EDIT")
      setEditShiftObj({
        ...editShiftObj,
        isDefault: !newShiftObj?.isDefault,
      });
  }}
/>
                </Box>
              </Grid>
            </Grid>
          
          
</Grid>


      
          <Grid
            container
            sx={{ display: "flex", justifyContent: "center", margin: "auto" }}
          >
            <Grid item xs={12} sm={3}>
              <Button className="cancelButton" onClick={handleClosePopup}>
                Cancel
              </Button>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Button className="saveButton" onClick={handleAddNewShift}>
                Save
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>

      {shiftList && shiftList.length > 0 && (
        <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
          <Pagination
            count={
              (count / 10) % 1 == 0
                ? Math.floor(count / 10)
                : Math.floor(count / 10 + 1)
            }
            page={offset}
            siblingCount={1}
            onChange={(event, value) => {
              handlePagination(value);
            }}
            color="primary"
          />
        </Box>
      )}
    </div>
  );
};

export default ShiftsTable;
