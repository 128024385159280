// custom pages
import config from "config/config";
import HandleApiResponse from "utils/handleApiResponse";

const employeeAPI = () => {
  const { handleResponse, handleError } = HandleApiResponse();

  async function getOneEmployeeAPI() {
    try {
      const obj = {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      };
      let res = await fetch(`${config.host}/panel/employees/profile`, obj);
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }

  async function getAllEmployeesAPI() {
    try {
      const obj = {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      };
      let res = await fetch(`${config.host}/panel/employees`, obj);
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }

  async function getAllEmployeesMasterAPI() {
    try {
      const obj = {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      };
      let res = await fetch(`${config.host}/panel/employees/findall/master`, obj);
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }

  async function getTimeoffAPI(values) {
    try {
      const obj = {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      };
      let res = await fetch(
        `${config.host}/panel/employee/timeoff?year=${values?.year}`,
        obj
      );
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }

  async function getTrainingStatusAPI() {
    try {
      const obj = {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      };
      let res = await fetch(
        `${config.host}/panel/employee/training-status`,
        obj
      );
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }

  async function getEmployeeCreateAPI(data) {
    try {
      const obj = {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      };
      let res = await fetch(`${config.host}/panel/employee/create`, obj);
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }

  return {
    getOneEmployeeAPI,
    getAllEmployeesAPI,
    getAllEmployeesMasterAPI,
    getTimeoffAPI,
    getTrainingStatusAPI,
    getEmployeeCreateAPI,
  };
};

export default employeeAPI;
