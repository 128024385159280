import React, { useState, useEffect, useContext, Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/TextLayer.css";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";

import "./sidebar.css";
import FileHandlerAPI from "apis/panel/fileHandler/fileHandler";

import Context from "context/context";
import config from "config/config";
import LogoIcon from "assets/images/Logo1.png";
import AuthAPI from "apis/auth/auth";
import EmployeeAPI from "apis/panel/employee/employee";
import Grid from "@mui/material/Grid";
import FileHandler from "apis/panel/fileHandler/fileHandler";
import Pagination from "@mui/material/Pagination";

import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Task } from "../../../node_modules/@mui/icons-material/index";

const settings = ["Change Password", "Logout"];

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

export default function MiniDrawer({ bodyLayout, sidebarRoutes }) {
  const { state, dispatch } = useContext(Context);
  const { logoutAPI } = AuthAPI();
  const { fileViewAPI } = FileHandlerAPI();
  const navigate = useNavigate();


  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [expandedRoute, setExpandedRoute] = useState(null);

  const { fileDownloadAPI } = FileHandler();

  // const locate= window.location.href;
  // const path=locate.split("/n")
  // const info= path[4]
  // console.log(path[4], "path")

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  // check current route
  function isThisRoute(path) {
    if (path) {
      if (path.toLowerCase() === window.location.pathname.toLowerCase()) {
        return true;
      } else {
        return false;
      }
    }
  }

  const handleLogout = async () => {
    try {
      const res = await logoutAPI();
      toast.success(res?.message, config?.toastCss);
    } catch (err) {
      console.log({ err });
    }
  };

  const handleClickUserMenu = async (key) => {
    try {
      handleCloseUserMenu();
      if (key == "Profile") {
        navigate("/panel/profile");
      } else if (key == "Change Password") {
        navigate("/panel/change_password");
      } else {
        dispatch({ type: "SET_LOGOUT" });
        handleLogout();
        navigate("/");
      }
    } catch (err) {
      console.log({ err });
    }
  };

  const { getOneEmployeeAPI } = EmployeeAPI();
  const [employee, setEmployee] = useState({});

  useEffect(() => {
    handleGetEmployee();
  }, []);

  const handleGetEmployee = async (id) => {
    try {
      let res = await getOneEmployeeAPI();
      if (res.type == "SUCCESS") {
        let employee = res.data.employee;
        setEmployee(employee);
      }
    } catch (err) {
      console.log({ err });
    }
  };

  useEffect(() => {
    const letters = document.querySelectorAll(".text-animation .letter");
    letters.forEach((letter, index) => {
      setTimeout(() => {
        letter.style.opacity = 1;
      }, index * 100);
    });
  }, []);

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleFileView = async (id) => {
    dispatch({ type: "SET_LOADING", payload: true });
    try {
      const res = await fileViewAPI("static/EmployeeHandbook.pdf");
      if (res) {
        setPdfBlob(res);
        handleClickOpen();
      }
      // fetch(
      //   "http://localhost:8080/api/v1/panel/s3/download?name=static/EmployeeHandbook.pdf",
      //   {
      //     method: "GET",
      //     credentials: "include",
      //   }
      // )
      //   .then((response) => response.blob())
      //   .then((blob) => {
      //     if(blob){
      //     setPdfBlob(blob);
      //     handleClickOpen();
      //   } else{
      //     toast.error(
      //       "Document is not available for now, you can try again some time.",
      //       { toastId: "employee-handbook-error" }
      //     );
      //   }
      //   }).catch((error) => {
      //     console.log(error)
      //   });;
      dispatch({ type: "SET_LOADING", payload: false });
    } catch (error) {
      dispatch({ type: "SET_LOADING", payload: false });
      console.error("Error downloading file:", error);
    }
  };

  const [pdfBlob, setPdfBlob] = useState(null);
  const [numPages, setNumPages] = useState();
  const [pageNumber, setPageNumber] = useState(1);
  const [roleCode, setRoleCode] = useState(state.role.roleCode);

  function onDocumentLoadSuccess({ numPages }) { 
    setNumPages(numPages);
  }
const permissions= state.permission.map((p)=>{
  return p.name
})


  const handleMainRouteClick = (obj) => {
   


    if (!permissions.includes('VIEW_LEAVE_MANAGEMENT_MANAGER_VIEW') && obj?.name === "Leave Management") {
      const myViewRoute = obj.subRoutes?.find(subRoute => subRoute.name === "My View");
      if (myViewRoute) {
        navigate(myViewRoute.to);
      }
    
    } else {
      if (obj?.name === expandedRoute) { 
        setExpandedRoute(null); // Collapse if already expanded
      } else {
        setExpandedRoute(obj?.name); // Expand the clicked route
      }
    }
   
  };


 const handleSubRouteClick = (subRoute, mainRoute) => {
    navigate(subRoute?.to);
  };


  return (
    <Fragment>
      <Box sx={{ display: "flex", backgroundColor: "rgba(229, 240, 252, 1)" }}>
        <Box
        className="BackgroundMediaquery"
          style={{
            width: "100%",
            maxWidth: "300px",
            display: "flex",
            flexDirection: "column",
            // height: "auto",
          }}
         >
          <div
            style={{
              margin: "20px",
              flex: 1,
              backgroundColor: "rgba(0, 44, 93, 1)",
              borderRadius: "11px",
            }}
          >
            <div style={{ padding: "20px" }}>
              <div style={{ margin: "10px 0px 20px 0px" }}>
                <img className="logo-icon" src={LogoIcon} alt="logo" />
              </div>
              <List>
                {sidebarRoutes?.map((obj, index) => (
                  <React.Fragment key={obj?.name}>
                    <ListItem
                      onClick={() => {
                        if (obj?.subRoutes) {
                          handleMainRouteClick(obj);
                        } else {
                          var encodedStringBtoA = btoa(employee.businessEmail);
                          if (obj?.type == "TP") {
                            window.open(obj?.to + `/${encodedStringBtoA}`, "_blank");
                          } else if (obj?.type === "employee-handbook-document") {
                            if (pageNumber > 1) {
                              setPageNumber(1);
                            }
                            handleFileView();
                          } else {
                            navigate(obj?.to);
                          }
                        }
                      }}
                      disablePadding
                      sx={{
                        display: "block",
                        backgroundColor: isThisRoute(obj?.to)
                          ? "rgba(229, 240, 252, 1)"
                          : "none",
                        borderRadius: isThisRoute(obj?.to) ? "25px" : 0,
                        margin: "12px 0px 0px 0px",
                        fontSize: "14px",
                        // whiteSpace:'nowrap',
                        
                      }}
                    >
                      <ListItemButton>
                        <ListItemIcon
                          sx={{
                            width: "20px",
                            minWidth: 0,
                            mr: 1,
                            justifyContent: "center",
                            color: isThisRoute(obj?.to)
                              ? config?.theme?.primary?.main
                              : "rgba(178, 212, 248, 1)",
                          }}
                        >
                          {isThisRoute(obj?.to) ? (
                            <img src={obj?.darkIcon} alt="" className="darkIcon" />
                          ) : (
                            <img src={obj?.lightIcon} alt="" className="lightIcon" />
                          )}
                        </ListItemIcon>
                        <ListItemText
                          primary={obj?.name}
                          sx={{
                            opacity: 1,
                            color: isThisRoute(obj?.to)
                              ? config?.theme?.primary?.main
                              : "rgba(178, 212, 248, 1)",
                            ".MuiListItemText-primary": {
                              fontWeight: "bold",
                              fontSize: "14px",
                              fontFamily: "Inter",
                              whiteSpace: "no-wrap",
                            },
                          }}
                        />
                      </ListItemButton>
                    </ListItem>

                    {/* Render sub-routes if they exist and if the route is expanded */}
                    {/* {console.log(obj,'subroute')} */}
                    {obj?.subRoutes && expandedRoute === obj?.name &&
                      obj.subRoutes?.map((subRoute, subIndex) => (
                        <ListItem
                          key={subRoute?.name}
                          onClick={() => handleSubRouteClick(subRoute, obj)}
                          disablePadding
                          sx={{
                            display: "block",
                            paddingLeft: "30px",
                            backgroundColor: isThisRoute(subRoute?.to)
                              ? "rgba(229, 240, 252, 1)"
                              : "none",
                            borderRadius: isThisRoute(subRoute?.to) ? "25px" : 0,
                            margin: "6px 0px 0px 0px",
                            fontSize: "14px",
                          }}
                        >
                          <ListItemButton>
                            {/* <ListItemIcon
                              sx={{
                                width: "20px",
                                minWidth: 0,
                                mr: 1,
                                justifyContent: "center",
                                color: isThisRoute(subRoute?.to)
                                  ? config?.theme?.primary?.main
                                  : "rgba(178, 212, 248, 1)", 
                              }}
                            >
                              <img src={obj?.lightIcon} alt="" className="lightIcon" />
                            </ListItemIcon> */}
                            <ListItemText
                              primary={subRoute?.name}
                              sx={{
                                opacity: 1,
                                color: isThisRoute(subRoute?.to)
                                  ? config?.theme?.primary?.main
                                  : "rgba(178, 212, 248, 1)",
                                ".MuiListItemText-primary": {
                                  fontWeight: "bold",
                                  fontSize: "14px",
                                  fontFamily: "Inter",
                                  whiteSpace: "no-wrap",
                                },
                              }}
                            />
                          </ListItemButton>
                        </ListItem>
                      ))}
                  </React.Fragment>
                ))}
              </List>
            </div>
          </div>
        </Box>

        <Box component="main" sx={{ flexGrow: 1 }}>
          <Box
            style={{
              background: "linear-gradient(to right, #002C5D, #D6EAFF)",
              margin: "20px 20px 20px 0px",
              boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.4)",
              borderRadius: "11px",
            }}
          >
            <Toolbar>
              <Box>
                {/* <Grid className="pathName">"The Future Starts <span className="todaypath"> Today</span> Not Tomorrow"</Grid> */}
                <Grid className="pathName">
                  {" "}
                  Employee Nexus{" "}
                  <span className="pathName1">
                    "Redefining Employee Engagement"
                  </span>
                </Grid>
              </Box>
              <Box sx={{ marginLeft: "auto" }}>
                <IconButton sx={{ p: 0 }}>
                  <Avatar
                    src={employee?.profileImageFilePath}
                    alt={employee?.firstName}
                  />
                </IconButton>
                <span
                  style={{
                    fontSize: "14px",
                    color: "rgba(0, 44, 93, 1)",
                    fontWeight: "700",
                    marginLeft: "16px",
                    cursor: "pointer",
                  }}
                  onClick={handleOpenUserMenu}
                >
                  {state?.employee?.firstName}
                  {anchorElUser ? (
                    <ArrowDropUpIcon style={{ verticalAlign: "middle" }}/>
                  ) : (
                    <ArrowDropDownIcon style={{ verticalAlign: "middle" }}/>
                  )}
                </span>
                <Menu
                  sx={{ mt: "45px" }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  {settings.map((setting) => (
                    <MenuItem
                      key={setting}
                      onClick={() => {
                        handleClickUserMenu(setting);
                      }}
                    >
                      <Typography textAlign="center">{setting}</Typography>
                    </MenuItem>
                  ))}
                </Menu>
              </Box>
            </Toolbar>
          </Box>
          <Box style={{ marginRight: "20px" }}>{bodyLayout}</Box>
        </Box>
      </Box>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="false"
      >
        <div
          style={{
            padding: "20px",
          }}
        >
          <Document
            file={pdfBlob}
            onLoadSuccess={onDocumentLoadSuccess}
            renderTextLayer={false}
          >
            <Page pageNumber={pageNumber} />
          </Document>
          <div className="document-view-pagination">
            <p>
              <Pagination
                page={pageNumber}
                count={numPages}
                onChange={(event, value) => {
                  setPageNumber(value);
                }}
                sx={{
                  "& .MuiPaginationItem-root": {
                    "&.Mui-selected": {
                      backgroundColor: "#FE602F",
                      color: "white",
                    },
                  },
                }}
              />
            </p>
          </div>
        </div>
      </Dialog>
    </Fragment>
  );
}
