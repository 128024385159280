import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import Context from "context/context";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import Tables from "components/table/table";
import projectRoleAPI from "apis/panel/projectRole/projectRole";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

const CustomWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 500,
    cursor: "pointer",
    backgroundColor: "#002c5d",
    color: "#ffffff",
  },
});

const RolesSetup = () => {
  const { state, dispatch } = useContext(Context);
  const [roleCode] = useState(state.role.roleCode);
  const navigate = useNavigate();  
  const { getAllMasterProjectRoles } = projectRoleAPI();
  const [roleDetails, setroleDetails] = useState([]);
  const [offset, setOffset] = useState(1);
  const [count, setCount] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const [filterValue, setFilterValue] = useState("");
  const [serialNumber, setSerialNumber] = useState("");
  
  useEffect(() => {
    handleGetAllrole();    
  }, [offset, searchValue, filterValue]);

  const handleGetAllrole = async () => {
    try {
      dispatch({ type: "SET_LOADING", payload: true });
      let values;
      values = {
        offset: (offset - 1) * 10,
        limit: 10,
      };
      if (searchValue) {
        values = {
          ...values,
          searchValue,
        };
      }
      if (filterValue) {
        values = {
          ...values,
          filterValue,
        };
      }
      let res = await getAllMasterProjectRoles(values);
      setroleDetails(res?.data?.roles || []);
      setCount(res?.data?.count);
      setSerialNumber(offset)
      dispatch({ type: "SET_LOADING", payload: false });
    } 
    catch (err) {
      dispatch({ type: "SET_LOADING", payload: false });
    }
  };

  return (
    <div style={{ backgroundColor: "white", borderRadius: "10px" }}>
      
      <Tables
        title={
          <Box className="EmployeeRoster" sx={{ pt:2, pb:2, pl:2}}>Role Information</Box>
        }
        searchBar={true}
        filter={true}
        role={roleCode}
        create={
          <Button 
            className="AddEmployerButton" 
            onClick={(e) =>
              navigate("../AddRole")
            }
          >
            New Role
          </Button>
        }
        setSearchValue={setSearchValue}
        filterValue={filterValue}
        setFilterValue={setFilterValue}
        filterValues={[
          { label: "Role Id", value: "" },
          { label: "Role Name", value: "roleName" }
        ]}
        heading={[
          "S.No",
          "Role Id",
          "Role Name",
          "Status",
          "Action"
        ]}
        data={roleDetails?.map((role, index) => [
          index + 1 + (serialNumber - 1) * 10,
          role.projectRoleCode,
          role.projectRoleName,
          <span style={{ color: role.status === 'Active' ? 'green' : 'red', fontWeight: 700, fontSize: '14px' }}>
            {role.status === 'Active' ? ' Active' : ' InActive'}
          </span>,       
            <CustomWidthTooltip
            title='Click to view details'
          >     
          <span className="jobType">            
            <RemoveRedEyeIcon 
              style={{ color: "#FE602F", cursor: "pointer" }} 
              onClick={(e) =>
                navigate("../EditProjectRole/"+role.projectRoleId)
              } 
            />
          </span>
          </CustomWidthTooltip>
        ])}
        offset={offset}
        setOffset={setOffset}
        count={count}
        placeholder="Search by Role ID & Role Name"
      />
    </div>
  )
}

export default RolesSetup;