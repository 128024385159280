// custom pages
import config from "config/config";
import HandleApiResponse from "utils/handleApiResponse";

const education = () => {
  const { handleResponse, handleError } = HandleApiResponse();

  async function getAllDegreesAPI() {
    try {
      const obj = {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      };
      let res = await fetch(`${config.host}/panel/degrees/findAll`, obj);
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }

  async function PostEducationApi(data) {
    try {
      const obj = {
        method: "Post",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      };
      let res = await fetch(
        `${config.host}/panel/employees/createEducation`,
        obj
      );
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }

  return {
    getAllDegreesAPI,
    PostEducationApi,
  };
};

export default education;
