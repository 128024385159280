module.exports = {
  host: process.env.REACT_APP_API_URL,
  S3SecretKey: process.env.REACT_APP_S3_SECRET_ACCESS_KEY,
  ACESSKEY:process.env.REACT_APP_S3_ACCESS_KEY_ID,
  REGION:process.env.REACT_APP_REGION,
  toastCss: {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: false,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  },
  theme: {
    primary: {
      light: '#B2D4F8',
      main: '#00225d',
      dark: '#002C5D',
      contrastText: '#fff',
    },
    secondary: {
      light: '#E5F0FC',
      main: '#f44336',
      dark: '#002C5D',
      contrastText: '#000',
    },
    primaryText:"#B2D4F8",
    primaryBg: "blue",
    secondaryBg: "green",
    contrastText: '#000'   
  },
  google: {
    clientId: process.env.REACT_APP_GOOGLE_API_CLIENT_ID,
    clientSecret: process.env.REACT_APP_GOOGLE_API_CLIENT_SECRET
  },
  s3: {
    bucket: process.env.REACT_APP_S3_BUCKET,    
    region: process.env.REACT_APP_S3_REGION,   
    accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_S3_SECRET_ACCESS_KEY
  },

  msalConfig : {
    auth: {
        clientId: process.env.REACT_APP_MICROSOFT_API_CLIENT_ID,
        authority: `https://login.microsoftonline.com/${process.env.REACT_APP_MICROSOFT_API_TENANT_ID}`,
        redirectUri: process.env.REACT_APP_MICROSOFT_REDIRECTURI,
        // postLogoutRedirectUri:"http://localhost:3000",
        // navigateToLoginRequestUrl:false,
    },
    cache: {
        cacheLocation: "sessionStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
  
},

   loginRequest : {
    scopes: ["User.Read"] 
}
};
