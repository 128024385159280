// npm packages
import React, { useState, useEffect, useContext } from "react";
import { toast } from "react-toastify";

// custom pages
import "./AddEmployee.css";
import DocumentsAPI from "apis/panel/Document/document";
import FileHandler from "apis/panel/fileHandler/fileHandler";
import Context from "context/context";
import ReusableFunction from "utils/reusablefunction";

// custom images
import personalInfo from "assets/images/Capa_1 (2).png";

// material ui
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { InputAdornment, IconButton } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ClearIcon from "@mui/icons-material/Clear";

const DocumentInfo = () => {
  const { dispatch } = useContext(Context);
  const [documentOptions, setDocumentOptions] = useState([]);
  const [documentAadhar, setDocumentAadhar] = useState("AADHAR");
  const [documentPan, setDocumentPan] = useState("PAN");
  const [documentOther, setDocumentOther] = useState("");
  const [documentAadharId, setDocumentAadharId] = useState("");
  const [documentPanId, setDocumentPanId] = useState("");
  const [documentOtherId, setDocumentOtherId] = useState("");
  const [AadharNumber, setAadharNumber] = useState("");
  const [panNumber, setPanNumber] = useState("");
  const [otherNumber, setOtherNumber] = useState("");
  const [aadharFile, setAadharFile] = useState(null);
  const [panFile, setPanFile] = useState(null);
  const [otherFile, setOtherFile] = useState(null);
  const [aadharFilePath, setAadharFilePath] = useState(null);
  const [panFilePath, setPanFilePath] = useState(null);
  const [otherFilePath, setOtherFilePath] = useState(null);

  const { getAllDocumentsAPI, PostDocumentApi, getOneDocumentApi } =
    DocumentsAPI();
  const { fileUploadAPI } = FileHandler();
  const { handleFileInputReselect } = ReusableFunction();

  const [accordionOpen, setAccordionOpen] = useState(false);

  const handleCancel = () => {
    setAccordionOpen(false);
  };

  const EmployeeId = sessionStorage.getItem("EmployeeID");

  const [errorFields, setErrorFields] = useState({
    aadharnumber: false,
    aadhardocument: false,
    pannumber: false,
    pandocument: false,
  });

  const handleFileUpload = async (file) => {
    const maxSize = 1024 * 1024 * 5;
    if (file.size > maxSize) {
      toast.error("File size exceeds the limit (5MB)", {
        toastId: "file-upload-maximum",
      });
      return false;
    }
    try {
      dispatch({ type: "SET_LOADING", payload: true });
      let input = new FormData();
      input.append("type", "employee_documents");
      input.append("employeeId", EmployeeId);
      input.append("file", file);
      const res = await fileUploadAPI(input);
      dispatch({ type: "SET_LOADING", payload: false });
      if (res?.data?.location) {
        return res?.data?.location;
      }
      return false;
    } catch (err) {
      dispatch({ type: "SET_LOADING", payload: false });
      console.log({ err });
    }
  };

  const handleFileChangeAadhar = async (event) => {
    const file = event.target.files[0];
    const location = await handleFileUpload(file);
    if (location) {
      setAadharFilePath(location);
      setAadharFile(file);
    }
  };

  const handleFileChangePan = async (event) => {
    const file = event.target.files[0];
    const location = await handleFileUpload(file);
    if (location) {
      setPanFilePath(location);
      setPanFile(file);
    }
  };

  const handleFileChangeOther = async (event) => {
    const file = event.target.files[0];
    const location = await handleFileUpload(file);
    if (location) {
      setOtherFilePath(location);
      setOtherFile(file);
    }
  };

  const handleDocument2Change = (e) => {
    const selectedDocument = documentOptions.find(
      (option) => option.documentName === e.target.value
    );
    setDocumentPan(selectedDocument.documentName);
    setDocumentPanId(selectedDocument.documentId);
  };

  const handleDocument3Change = (e) => {
    const selectedDocument = documentOptions.find(
      (option) => option.documentName === e.target.value
    );
    setDocumentOther(selectedDocument.documentName);
    setDocumentOtherId(selectedDocument.documentId);
  };

  useEffect(() => {
    handleGetAllDocumentsApi();
    handleDocuments();
  }, []);

  const handleGetAllDocumentsApi = async () => {
    try {
      let res = await getAllDocumentsAPI();
      setDocumentOptions(res?.data || []);
    } catch (err) {}
  };

  const handleDocuments = async () => {
    try {
      let aadhar = await getOneDocumentApi("AADHAR");
      let pan = await getOneDocumentApi("PAN");
      setDocumentAadharId(aadhar.data.documentId);
      setDocumentPanId(pan.data.documentId);
    } catch (err) {}
  };

  const handleCreateDocument = async (e) => {
    e.preventDefault();
    // Validate form fields
    const errors = {};
    if (!AadharNumber) errors.aadharnumber = true;
    if (!aadharFile) errors.aadhardocument = true;
    if (!panNumber) errors.pannumber = true;
    if (!panFile) errors.pandocument = true;

    setErrorFields(errors);
    // If no errors, proceed with form submission
    if (Object.keys(errors).length === 0) {
      if (documentAadhar && documentPan && documentOther) {
        let body = {
          employeeId: EmployeeId,
          aadharData: {
            documentId: documentAadharId,
            documentNumber: AadharNumber,
            expirationDate: "",
            filePath: aadharFilePath,
          },
          panData: {
            documentId: documentPanId,
            documentNumber: panNumber,
            expirationDate: "",
            filePath: panFilePath,
          },
          otherData: {
            documentId: documentOtherId,
            documentNumber: otherNumber,
            expirationDate: "",
            filePath: otherFilePath,
          },
        };
        const data = await PostDocumentApi(body);
        if (data.status === "Ok") {
          return toast.success("Data Saved Successfully", {
            toast: "Data Saved Successfully",
          });
        } else {
          return toast.error("Error saving Data", {
            toast: "Error saving Data",
          });
        }
      }
    }
  };

  const handleClearFile = () => {
    setAadharFile(null);
  };

  const handlepanFile = () => {
    setPanFile(null);
  };

  const handleotherFile = () => {
    setOtherFile(null);
  };

  return (
    <Accordion
      expanded={accordionOpen}
      onChange={() => setAccordionOpen(!accordionOpen)}
      sx={{ mt: 2, boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}
    >
      <AccordionSummary
        expandIcon={<ArrowDropDownIcon className="Accordianicon" />}
        aria-controls="panel1-content"
        id="panel1-header"
        style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px", height: "70px" }}
      >
        <Box sx={{ pl: 3 }}>
          {" "}
          <img
            sx={{ pl: 3 }}
            className="personalInfo-logo"
            src={personalInfo}
            alt="info"
          />
        </Box>
        <Box className="acc-title" sx={{ pl: 3 }}>
          Government Issued Document Verification
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={2} sx={{ pl: 4, pr: 5, mt: 1 }}>
          <Box className="subHeadingforinfos" sx={{ pl: 3 }}>
            Aadhaar/SSN Information
          </Box>
        </Grid>

        <Grid container spacing={2} sx={{ pl: 4, pr: 5, mt: 1 }}>
          {/* <Box>Current Addres</Box> */}
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <Box className="label">Aadhaar/SSN</Box>
            <FormControl fullWidth>
              {/* <InputLabel id="marital-status-label">Country</InputLabel> */}
              <Select
                id="maritalStatus"
                value={documentAadhar}
                //  onChange={handleDocument1Change}
                placeholder="Select ID Proof"
                //  defaultValue="Aadhar"
                inputProps={{ "aria-label": "Without label" }}
                style={{ border: "1.5px solid #B2D4F8", color: "grey" }}
                disabled={true}
                //  value={"Aadhaar"}
                //   label="Marital Status"
              >
                {documentOptions.map((doc, index) => (
                  <MenuItem key={index} value={doc.documentCode}>
                    {doc.documentName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <Box className="label">
              {" "}
              Aadhaar/SSN Number<span style={{ color: "red" }}>*</span>{" "}
            </Box>
            <TextField
              id="firstName"
              placeholder="Enter Id Number"
              value={AadharNumber}
              onChange={(e) => setAadharNumber(e.target.value)}
              variant="outlined"
              fullWidth
            />
            {errorFields.aadharnumber && (
              <span className="error-msg">Aadhaar/SSN number is required</span>
            )}
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ pl: 4, pr: 5, mt: 2 }}>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <Box className="label">
              {" "}
              Upload Aadhaar/SSN Copy (JPEG, PNG, PDF)
              <span style={{ color: "red" }}>*</span>{" "}
            </Box>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Browse Files (max of 5mb)"
              //   label="Brow"
              type="text"
              value={aadharFile ? aadharFile.name : ""}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {aadharFile && (
                      <IconButton
                        onClick={handleClearFile}
                        edge="end"
                        aria-label="clear file"
                      >
                        <ClearIcon />
                      </IconButton>
                    )}
                    <input
                      type="file"
                      accept=".pdf,.doc,.docx"
                      id="file-upload"
                      style={{ display: "none" }}
                      onClick={handleFileInputReselect}
                      onChange={handleFileChangeAadhar}
                    />
                    <label htmlFor="file-upload">
                      <IconButton
                        component="span"
                        color="primary"
                        aria-label="attach file"
                      >
                        <AttachFileIcon style={{ color: "#0056B2" }} />
                      </IconButton>
                    </label>
                  </InputAdornment>
                ),
              }}
            />
            {errorFields.aadhardocument && (
              <span className="error-msg">Aadhaar/SSN file is required</span>
            )}
          </Grid>
        </Grid>

        <Grid container spacing={2} sx={{ pl: 4, pr: 5, mt: 2 }}>
          <Box className="subHeadingforinfos" sx={{ pl: 3 }}>
            PAN Information
          </Box>
        </Grid>

        <Grid container spacing={2} sx={{ pl: 4, pr: 5, mt: 1 }}>
          {/* <Box>Current Addres</Box> */}
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <Box className="label">ID Proof</Box>
            <FormControl fullWidth>
              {/* <InputLabel id="marital-status-label">Country</InputLabel> */}
              <Select
                id="maritalStatus"
                value={documentPan}
                onChange={handleDocument2Change}
                placeholder="Select ID Proof"
                inputProps={{ "aria-label": "Without label" }}
                style={{ border: "1.5px solid #B2D4F8", color: "grey" }}
                disabled={true}

                //   label="Marital Status"
              >
                {documentOptions.map((doc, index) => (
                  <MenuItem key={index} value={doc.documentName}>
                    {doc.documentName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <Box className="label">
              {" "}
              PAN Number<span style={{ color: "red" }}>*</span>{" "}
            </Box>
            <TextField
              id="firstName"
              value={panNumber}
              onChange={(e) => setPanNumber(e.target.value)}
              placeholder="Enter Id Number"
              variant="outlined"
              fullWidth
            />
            {errorFields.pannumber && (
              <span className="error-msg">Pan Number is required</span>
            )}
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ pl: 4, pr: 5, mt: 2 }}>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <Box className="label">
              {" "}
              PAN Document (JPEG, PNG, PDF)
              <span style={{ color: "red" }}>*</span>{" "}
            </Box>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Browse Files (max of 5mb)"
              //   label="Brow"
              type="text"
              value={panFile ? panFile.name : ""}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {panFile && (
                      <IconButton
                        onClick={handlepanFile}
                        edge="end"
                        aria-label="clear file"
                      >
                        <ClearIcon />
                      </IconButton>
                    )}
                    <input
                      type="file"
                      accept=".pdf,.doc,.docx"
                      id="file-upload-pan"
                      style={{ display: "none" }}
                      onClick={handleFileInputReselect}
                      onChange={handleFileChangePan}
                    />
                    <label htmlFor="file-upload-pan">
                      <IconButton
                        component="span"
                        color="primary"
                        aria-label="attach file"
                      >
                        <AttachFileIcon style={{ color: "#0056B2" }} />
                      </IconButton>
                    </label>
                  </InputAdornment>
                ),
              }}
            />
            {errorFields.pandocument && (
              <span className="error-msg">Pan file is required</span>
            )}
          </Grid>
        </Grid>

        <Grid container spacing={2} sx={{ pl: 4, pr: 5, mt: 2 }}>
          <Box className="subHeadingforinfos" sx={{ pl: 3 }}>
            Others
          </Box>
        </Grid>

        <Grid container spacing={2} sx={{ pl: 4, pr: 5, mt: 1 }}>
          {/* <Box>Current Addres</Box> */}
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <Box className="label">ID Proof </Box>
            <FormControl fullWidth>
              {/* <InputLabel id="marital-status-label">Country</InputLabel> */}
              <Select
                id="maritalStatus"
                value={documentOther}
                onChange={handleDocument3Change}
                defaultValue=""
                displayEmpty
                placeholder="Select ID Proof"
                inputProps={{ "aria-label": "Without label" }}
                style={{ border: "1.5px solid #B2D4F8", color: "grey" }}
                //   label="Marital Status"
              >
                <MenuItem value="">Select ID</MenuItem>

                {documentOptions.map(
                  (doc, index) =>
                    doc.documentCode !== "AADHAR" &&
                    doc.documentCode !== "PAN" && (
                      <MenuItem key={index} value={doc.documentName}>
                        {doc.documentName}
                      </MenuItem>
                    )
                )}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <Box className="label"> Id Number </Box>
            <TextField
              id="firstName"
              value={otherNumber}
              onChange={(e) => setOtherNumber(e.target.value)}
              placeholder="Enter Id Number"
              variant="outlined"
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ pl: 4, pr: 5, mt: 2 }}>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <Box className="label"> Other Document (JPEG, PNG, PDF)t </Box>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Browse Files (max of 5mb)"
              //   label="Brow"
              type="text"
              value={otherFile ? otherFile.name : ""}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {otherFile && (
                      <IconButton
                        onClick={handleotherFile}
                        edge="end"
                        aria-label="clear file"
                      >
                        <ClearIcon />
                      </IconButton>
                    )}
                    <input
                      type="file"
                      accept=".pdf,.doc,.docx"
                      id="file-upload-other"
                      style={{ display: "none" }}
                      onClick={handleFileInputReselect}
                      onChange={handleFileChangeOther}
                    />
                    <label htmlFor="file-upload-other">
                      <IconButton
                        component="span"
                        color="primary"
                        aria-label="attach file"
                      >
                        <AttachFileIcon style={{ color: "#0056B2" }} />
                      </IconButton>
                    </label>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>

        <Grid
          container
          spacing={1}
          sx={{ pl: 4, pr: 5, mt: 5, justifyContent: "center" }}
        >
          <Grid item xs={12} sm={3} md={3} lg={3}>
            <Button className="resetChanges">Reset Changes</Button>
          </Grid>

          <Grid item xs={12} sm={2} md={2} lg={2}>
            <Button className="cancelButton" onClick={handleCancel}>
              Cancel
            </Button>
          </Grid>

          <Grid item xs={12} sm={2} md={2} lg={2}>
            <Button className="saveButton" onClick={handleCreateDocument}>
              Save
            </Button>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default DocumentInfo;
