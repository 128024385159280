import React, { useRef, useState,useEffect } from 'react';

import "./AddEmployee.css"
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import personalInfo from "assets/images/emergency-call_2991174 1.png";
import AttachFileIcon from '@mui/icons-material/AttachFile';

import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { toast } from "react-toastify";

import {  InputAdornment, IconButton } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

//Api
import getEmpRel from '../../../apis/panel/numberInfo/numberInfo'
import getRel from '../../../apis/panel/numberInfo/numberInfo'
import getcontact from 'apis/panel/numberInfo/numberInfo'


// import { makeStyles } from '@mui/styles';

// const useStyles = makeStyles((theme) => ({
//   fileInput: {
//     '& input[type="file"]': {
//       display: 'none',
//     },
//   },
//   textField: {
//     '& .MuiOutlinedInput-root': {
//       '&.Mui-focused fieldset': {
//         borderColor: theme.palette.primary.main,
//       },
//       '&:hover fieldset': {
//         borderColor: theme.palette.secondary.main,
//       },
//     },
//     '& .MuiFormLabel-root.Mui-focused': {
//       color: theme.palette.primary.main,
//     },
//   },
// }));



const label = { inputProps: { 'aria-label': 'Checkbox demo' } };




const NumberInfo = () => {

  // const { getAllrelationshipApi } = getAllDocumentsDrop();
  // const { getAllrelationshipname } = getAllrelationshipgame();

    const [file, setFile] = useState(null);

    const { getcontactcreateAPI } = getcontact();
    const { getEmployeeRelations } = getEmpRel();
    const { getRelations } = getRel();

    const [EmployeeId] = useState(sessionStorage.getItem('EmployeeID'))
    const [RelationsData, setRelationsData] = useState([])
    const [relName, setrelName] = useState()

    const [phone1,setphone1]=useState('')
    const [relationshipid,setrelationshipid]=useState(null)
    const [relationshipname,setrelationshipname]=useState('')
    const[phone2,setphone2]=useState('')
    const[mail,setmail]=useState('');
    const [accordionOpen, setAccordionOpen] = useState(false);

    const handleCancel = () => {
      setAccordionOpen(false); 
    };
  
  
  
    // const [Relationshipname, setRelationshipname] = useState('')
    // const [selectedrelationship, setSelectedrelationship] = useState({ id: "", name: "" });
    // const [Relationshipid, setRelationshipid] = useState('')
    // const [relationshipOptions, setrelationshipOptions] = useState([]);
    // const [relationshipnames, setrelationshipnames] = useState([]);

    const [Selectedrelation, setSelectedrelation] = useState([])

    const [validationErrors, setValidationErrors] = useState({
      relName: '',
      phone1: '',
      relationshipid: '',
      Email:""
  });
    const handleFileChange = (event) => {
      const selectedFile = event.target.files[0];
      setFile(selectedFile); 
    };

    useEffect(() => {
      findrelations()
    }, [])
    const selectedData= (e) => {
  
  let body ={
    relID:e,
    empID:EmployeeId
  }
  findEmpRelations(body)
    }
    const findEmpRelations = async (e) => {
      const data = await getEmployeeRelations(e)
      setrelName(data?.data?.firstName)
  
    }
    const findrelations = async () => {
      const data = await getRelations(EmployeeId)
      setRelationsData(data.data)
    }

    const handlphoneChange = (e) => {
      setphone1(e.target.value);
    };

    const handlphone1Change = (e) => {
      setphone2(e.target.value);
    };

    const handlemailChange = (e) => {
      setmail(e.target.value);
    };

    const handleReset = () => {
    
      
      setrelName('')
      setphone1('')
      setphone2('')
      setmail('')
  
  
      
  
      
  
    }

    const handlerelationship = (e) => {
      const selectedrelationship = RelationsData.find(option => option.relationshipName === e.target.value);
      selectedData(e.target.value)
      if (selectedrelationship) {
        setrelationshipid(selectedrelationship.relationshipId);
      } else {
        setrelationshipid(null); // Clear the relationship ID if the selected relationship is not found
      }
  
    };

    // useEffect(() => {
    //   console.log(relationshipid); // This will log the updated relationshipid
    // }, [relationshipid])


    const validateInputs = () => {
      const errors = {};
      if (!relName) {
          errors.relName = 'Name is required';
      }
      if (!phone1) {
          errors.phone1 = 'Phone Number 1 is required';
      }
      if (!relationshipid) {
          errors.relationshipid = 'Relationship is required';
      }
      if (!mail) {
        errors.Email = 'Email is required';
    }
      setValidationErrors(errors);
      return Object.keys(errors).length === 0; // Returns true if no errors
  };

    const handleCreatecontact = async (e) => {

      if (validateInputs()) {
    
      const values = {
        employeeId: EmployeeId,
        // relationshipId: relationshipid,
        firstName: relName,
        phoneNumber1:phone1,
        phoneNumber2: phone2,
        email:mail,
       
       
       
      };
      
      
      // sessionStorage.setItem('EmployeeID',data.data.employeeId)
      const data = await getcontactcreateAPI(values);
      if(data.status === 'Ok'){
        return toast.success("Data Saved Successfully", { toast: "Data Saved Successfully" });

      }else{
        return toast.error("Error saving Data", { toast: "Error saving Data" });

      }}

    };


    

 
  return (
    <Accordion expanded={accordionOpen} onChange={() => setAccordionOpen(!accordionOpen)} sx={{ mt: 2, boxShadow:"rgba(0, 0, 0, 0.24) 0px 3px 8px" }}>
    <AccordionSummary
    expandIcon={<ArrowDropDownIcon  className="Accordianicon"/>}
      aria-controls="panel1-content"
      id="panel1-header"
      style={{boxShadow:"rgba(0, 0, 0, 0.24) 0px 3px 8px", height:"70px"}}
    >
      <Box sx={{ pl: 3 }}> <img sx={{ pl: 3 }} className='personalInfo-logo' src={personalInfo} alt="info" /></Box>
      <Box className="acc-title" sx={{ pl: 3 }}>Emergency Contact Information</Box>
    </AccordionSummary>
    <AccordionDetails>



      <Grid container spacing={2}  sx={{ pl: 4, pr:5, mt:1 }} >
        {/* <Box>Current Addres</Box> */}

        <Grid item xs={12} sm={6} md={6} lg={6}>
        <Box className="label" > Relationship <span style={{color:"red"}}>*</span></Box>
        <FormControl fullWidth>
              {/* <InputLabel id="marital-status-label">Country</InputLabel> */}
              <Select
                labelId="marital-status-label"
                id="maritalStatus"
                displayEmpty
                inputProps={{ 'aria-label': 'Without label' }}
                style={{ border: "1.5px solid #B2D4F8", color: "grey" }}
                // value={Selectedrelation.relationshipName}
                value={relName}
                onChange={
                  handlerelationship
                }
              
              >
                <MenuItem>
                  Select Relationship
                </MenuItem>
                {
                  RelationsData?.map((i) => (
                    <MenuItem value={i.relationshipId}>{i.relationshipName}</MenuItem>
                  ))
                }
              </Select>
            </FormControl>
            
            {validationErrors.relationshipid && <Box color="error.main">{validationErrors.relationshipid}</Box>}
       
          </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6}>
        <Box className="label" >Name <span style={{color:"red"}}>*</span></Box>
        <TextField id="firstName"  placeholder=" Enter Name" variant="outlined" fullWidth  value={relName} />
        {validationErrors.relName && <Box color="error.main">{validationErrors.relName}</Box>}
       
        </Grid>
       </Grid>
       <Grid container spacing={2}  sx={{ pl: 4, pr:5, mt:2 }} >
        <Grid item xs={12} sm={6} md={6} lg={6}>
       
        <Box className="label" > Phone Number 1 <span style={{color:"red"}}>*</span></Box>
          <TextField id="firstName"  placeholder="Enter Phone Number 1"  variant="outlined" fullWidth onChange={handlphoneChange}/>
          {validationErrors.phone1 && <Box color="error.main">{validationErrors.phone1}</Box>}
       
        </Grid>

        <Grid item xs={12} sm={6} md={6} lg={6}>
        <Box className="label" > Phone Number 2(Optional)</Box>
          <TextField 
        placeholder="Enter Phone Number 2"

          id="firstName"  variant="outlined" fullWidth onChange={handlphone1Change} />
        </Grid>
        </Grid>



  

      <Grid container spacing={2}  sx={{ pl: 4, pr:5, mt:2 }} >
        {/* <Box>Current Addres</Box> */}

        <Grid item xs={12} sm={6} md={6} lg={6}>
            <Box className="label">Email</Box>
            <TextField id="firstName"  placeholder="Enter Email" variant="outlined" fullWidth onChange={handlemailChange} />
       
            {validationErrors.Email && <Box color="error.main">{validationErrors.Email}</Box>}
        </Grid>
</Grid>
    
<Grid container spacing={1}  sx={{ pl: 4, pr:5, mt:5 , justifyContent:"center"}} >
        <Grid item xs={12} sm={3} md={3} lg={3}>
        <Button className="resetChanges" onClick={handleReset}>Reset Changes</Button>
        </Grid>

        <Grid item xs={12} sm={2} md={2} lg={2}>
        <Button className="cancelButton"  onClick={handleCancel}>Cancel</Button>
        </Grid>

        <Grid item xs={12} sm={2} md={2} lg={2}>
        <Button className="saveButton" onClick={handleCreatecontact}>Save</Button>
        </Grid>

        </Grid>
    </AccordionDetails>
  </Accordion>
   
  )
}

export default NumberInfo