import {React, useContext,useState} from 'react';
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Card from "@mui/material/Card";
import { LocalizationProvider, DesktopDatePicker, DatePicker } from '@mui/x-date-pickers';
import TextField from '@mui/material/TextField';
import * as moment from "moment";
// import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import Button from "@mui/material/Button";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Context from "context/context";
import vraShiftsAPI from 'apis/panel/vraShifts/vraShifts';

const RpAllowance = ({ setDisableDropdown }) => {
    const { dispatch } = useContext(Context);
    const {
        getRPAllowanceReportAPI
      } = vraShiftsAPI();

      const initialStartDate = moment().startOf("month").toDate();
      const initialEndDate = new Date();
    
      const [exportObj, setExportObj] = useState({
        startDate: initialStartDate,
        endDate: initialEndDate,
      });




      const handleExport = async () => {
        let body = {};
        if (!exportObj?.startDate) {
          return;
        }
        if (!exportObj?.endDate) {
          return;
        }
        body = {
          startDate: moment(exportObj?.startDate).format("YYYY-MM-DD"),
          endDate: moment(exportObj?.endDate).format("YYYY-MM-DD"),
        };
       
        dispatch({ type: "SET_LOADING", payload: true });
        setDisableDropdown(true);
       
        await getRPAllowanceReportAPI(body);
        setDisableDropdown(false);
        dispatch({ type: "SET_LOADING", payload: false });
      };
    
  return (
    <div>
          <Grid container spacing={4} sx={{ mt: 2 }}>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <Box className="labelproj">
            Start Date <span style={{ color: "red" }}>*</span>
          </Box>
          <LocalizationProvider fullWidth dateAdapter={AdapterDateFns}>
            <DatePicker
              id="startdate"
              name="startdate"
              sx={{ width: "100%" }}
              format="dd/MM/yyyy"
              renderInput={(params) => <TextField {...params} fullWidth />}
              maxDate={exportObj?.endDate}
              value={exportObj?.startDate}
              onAccept={(date) => {
                setExportObj({ ...exportObj, startDate: date });
              }}
            />
          </LocalizationProvider>
        </Grid>

        <Grid item xs={12} sm={6} md={6} lg={6}>
          <Box className="labelproj">
            End Date <span style={{ color: "red" }}>*</span>
          </Box>
          <LocalizationProvider fullWidth dateAdapter={AdapterDateFns}>
            <DatePicker
              id="enddate"
              name="enddate"
              sx={{ width: "100%" }}
              maxDate={new Date()}
              format="dd/MM/yyyy"
              renderInput={(params) => <TextField {...params} fullWidth />}
              minDate={exportObj?.startDate}
              value={exportObj?.endDate}
              onAccept={(date) => {
                setExportObj({ ...exportObj, endDate: date });
              }}
            />
          </LocalizationProvider>
        </Grid>


      </Grid>

      <Grid container>
        <Grid item xs={12} style={{ textAlign: "right", padding: "20px 0px" }}>

     
          <Button
            className="download-btn"
            startIcon={
              <DownloadForOfflineIcon
                style={{ fontSize: 30, verticalAlign: "middle" }}
              />
            }
            onClick={handleExport}
          >
            Export
          </Button>
        </Grid>
      </Grid>
    </div>
  )
}

export default RpAllowance