// custom pages
import config from "config/config";
import HandleApiResponse from "utils/handleApiResponse";

const insurance = () => {
  const { handleResponse, handleError } = HandleApiResponse();

  async function getEmployeeRelations(id) {
    try {
      const obj = {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      };
      let res = await fetch(
        `${config.host}/panel/employees/relationship/${id.relID}/${id.empID}`,
        obj
      );
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }

  async function getRelations() {
    try {
      const obj = {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      };
      let res = await fetch(`${config.host}/panel/relationship/findAll`, obj);
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }

  async function PostInsuranceApi(data) {
    try {
      const obj = {
        method: "Post",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      };
      let res = await fetch(
        `${config.host}/panel/employees/createInsurance`,
        obj
      );
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }

  return {
    PostInsuranceApi,
    getEmployeeRelations,
    getRelations,
  };
};

export default insurance;
