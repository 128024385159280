import config from "config/config";
import HandleApiResponse from "utils/handleApiResponse";

const TimeSheet = () => {
  const { handleResponse, handleError } = HandleApiResponse();

  async function getAllProjectsAPI() {
    try {
      const obj = {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      };
      const res = await fetch(
        `${config.host}/panel/timesheet/employee-projects`,
        obj
      );
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }

  async function findOneWeekApi(week) {
    try {
      const obj = {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      };
      const res = await fetch(
        `${config.host}/panel/timesheet/${week.week}`,
        obj
      );
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }

  async function PostTimeSheetApi(value) {
    try {
      const obj = {
        method: "Post",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(value),
      };
      let res = await fetch(`${config.host}/panel/timesheet`, obj);
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }

  async function PostDraftTimeSheetApi(value) {
    try {
      const obj = {
        method: "Post",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(value),
      };
      let res = await fetch(`${config.host}/panel/timesheet/draft`, obj);
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }

  async function getEmployeeDetailsAPI() {
    try {
      const obj = {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      };
      const res = await fetch(
        `${config.host}/panel/timesheet/employee-information`,
        obj
      );
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }

  async function getAllProjectAPI(values) {
    try {
      const obj = {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      };
      const query = JSON.stringify(values);
      const res = await fetch(
        `${config.host}/panel/timesheet/manager/employees?query=${query}`,
        obj
      );
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }

  async function putApprovalAPI(values) {
    try {
      const obj = {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      };
      const query = JSON.stringify(values);
      const res = await fetch(
        `${config.host}/panel/timesheet/manager/employee-timesheet?query=${query}`,
        obj
      );
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }

  async function updateApprovalAPI(values) {
    try {
      const obj = {
        method: "PUT",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(values)
      };
      const res = await fetch(
        `${config.host}/panel/timesheet/manager/employee-timesheet`, obj
      );
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }

  async function createOrUpdateFavouriteTaskAPI(value) {
    try {
      const obj = {
        method: "Post",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(value),
      };
      let res = await fetch(`${config.host}/panel/timesheet/favourite-task`, obj);
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }

  // Time sheet revamp starts here

  
  async function getAllProjectTasksAPI(values) {
    try {
      const obj = {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      };
      const query = JSON.stringify(values);
      const res = await fetch(
        `${config.host}/panel/timesheet/project/tasks?query=${query}`,
        obj
      );
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }

  async function getAllProjectOwnerAPI(values) {
    try {
      const obj = {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      };
      const query = JSON.stringify(values);
      const res = await fetch(
        `${config.host}/panel/timesheet/project/owners?query=${query}`,
        obj
      );
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }

  async function getAllProjectShiftsAPI(values) {
    try {
      const obj = {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      };
      const query = JSON.stringify(values);
      const res = await fetch(
        `${config.host}/panel/timesheet/project/shifts?query=${query}`,
        obj
      );
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }


  async function createTaskApi(value) {
    try {
      const obj = {
        method: "Post",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(value),
      };
      let res = await fetch(`${config.host}/panel/timesheet/task`, obj);
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }


  async function getAllCreatedTasksAPI(values) {
    try {
      const obj = {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      };
      const query = JSON.stringify(values);
      const res = await fetch(
        `${config.host}/panel/timesheet/tasks?query=${query}`,
        obj
      );
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }


  async function updateTaskAPI(value) {
    try {
      const obj = {
        method: "PUT",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(value),
      };
      let res = await fetch(`${config.host}/panel/timesheet/task`, obj);
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }

  async function DeleteCreatedTasksAPI(value) {
    try {
      const obj = {
        method: "PUT",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(value),
      };
      let res = await fetch(`${config.host}/panel/timesheet/task/remove`, obj);
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }

  


  return {
    getAllProjectsAPI,
    findOneWeekApi,
    PostTimeSheetApi,
    PostDraftTimeSheetApi,
    getEmployeeDetailsAPI,
    getAllProjectAPI,
    putApprovalAPI,
    updateApprovalAPI,
    createOrUpdateFavouriteTaskAPI,
   

    // timesheet revamp

    getAllProjectTasksAPI,
    getAllProjectOwnerAPI,
    getAllProjectShiftsAPI,
    createTaskApi,
    getAllCreatedTasksAPI,
    DeleteCreatedTasksAPI,
    updateTaskAPI,
    
   
  };
};

export default TimeSheet;
