import React, { useState, useEffect, useContext } from 'react';
import Context from 'context/context';
import './WeeklyReports.css';
import Grid from '@mui/material/Grid';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Pagination from '@mui/material/Pagination';
import reportsAPI from 'apis/panel/reports/reports';
import { useLocation } from 'react-router-dom';
import { format, addDays, startOfWeek, endOfWeek, parseISO, addMonths, endOfMonth, subMonths } from 'date-fns';

const decodeQueryStringToState = (queryString) => {
  return Object.fromEntries(new URLSearchParams(queryString));
};

const WeeklyReport = () => {
  const { dispatch } = useContext(Context);
  const location = useLocation();

  const body = decodeQueryStringToState(location.search);
  const { getWeeklyReportView } = reportsAPI();
  const [weeklyReportList, setWeeklyReportList] = useState([]);
  const [offset, setOffset] = useState(1);
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [selectedMonth, setSelectedMonth] = useState('');
  const [weekDates, setWeekDates] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [months, setMonths] = useState([]);
  const [localValue , setLocalValue] = useState(null)

  const { getWeeklyReportAPI } = reportsAPI();
  
  const startDate = new Date(body.startDate);
  const endDate = new Date(body.endDate);

  const itemsPerPage = 10;

  const handlePagination = (value) => {
    setOffset(value);
  };




  const handleGetWeeklyReportView = async () => {
    const selectedMonthValue = months.find((month) => month.display === selectedMonth)?.value;
    const {projectName,...rest}=body

    if (selectedMonth) {
      let values = {
        offset: (offset - 1) * 10,
        limit: 10,
        ...rest,
        month: selectedMonthValue,
      };
      if (searchValue) {
        values = {
          offset: (offset - 1) * 10,
          limit: 10,
          ...rest,
          month: selectedMonthValue,
          searchValue
        }
      }
     
      
      try {
        dispatch({ type: 'SET_LOADING', payload: true });

        let res = await getWeeklyReportView(values);
        setCount(res?.data?.count);
        setWeeklyReportList(res?.data?.data || []);
        dispatch({ type: 'SET_LOADING', payload: false });
      } catch (err) {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    handleGetWeeklyReportView();
  }, [offset, selectedMonth,searchValue]);

  useEffect(() => {
    const selectedMonthValue = months.find((month) => month.display === selectedMonth)?.value;
    const newWeekDates = calculateWeekDates(selectedMonthValue);
    setWeekDates(newWeekDates);
  }, [selectedMonth]);
  useEffect(() => {
    const startDate = new Date(body.startDate);
    const endDate = new Date(body.endDate);

    const monthsArray = [];
    let currentDate = startDate;

    // Check if the first week includes days from the previous month
    const firstWeekStart = startOfWeek(currentDate, { weekStartsOn: 1 });
    if (firstWeekStart < startDate) {
      
      monthsArray.push({
        display: format(firstWeekStart, 'MMMM'),
        value: format(firstWeekStart, 'yyyy-MM-01')
      });
      
    }

    while (currentDate <= endDate) {
      monthsArray.push({
        display: format(currentDate, 'MMMM'),
        value: format(currentDate, 'yyyy-MM-01')
      });
      currentDate = addMonths(currentDate, 1);
      
    }
    const uniqueMonths = monthsArray.filter((month, index, self) => 
      index === self.findIndex((m) => (
        m.value === month.value
      ))
    );
    setMonths(uniqueMonths);
    setSelectedMonth(monthsArray[0]?.display);
  }, [body.startDate, body.endDate]);


  const calculateWeekDates = (selectedMonth) => {
    if (!selectedMonth) return [];

    const monthStart = parseISO(selectedMonth);
    const start = new Date(body.startDate);
    const actualStart = monthStart > start ? monthStart : start; 

    const monthEnd = endOfMonth(monthStart);
    const weekDates = [];
    let current = actualStart;
    let weekIndex = 1;

    while (current <= monthEnd) {
      const weekStart = weekIndex === 1 ? current : startOfWeek(current, { weekStartsOn: 1 });
      const weekEnd = endOfWeek(current, { weekStartsOn: 1 });

      // If weekStart is in the previous month, include it
      if (weekStart < monthStart) {
        weekDates.push({
          start: format(weekStart, 'MM/dd/yyyy'),
          end: format(weekEnd, 'MM/dd/yyyy'),
          label: `Week ${weekIndex}`
        });
      } else if (weekEnd <= monthEnd) {
        // If weekEnd is within the current month, include it
        weekDates.push({
          start: format(weekStart, 'MM/dd/yyyy'),
          end: format(weekEnd, 'MM/dd/yyyy'),
          label: `Week ${weekIndex}`
        });
      }

      weekIndex++;
      current = addDays(weekEnd, 1);
    }

    return weekDates;
  };

  const calculateWeekDatesFromWeekNumber = (weekNumber, startDate) => {
    const startOfYear = new Date(startDate.getFullYear(), 0, 1);
    const weekStart = addDays(startOfYear, (weekNumber - 1) * 7);
    const weekEnd = endOfWeek(weekStart, { weekStartsOn: 1 });
    return {
      start: format(weekStart, 'yyy-MM-dd'),
      end: format(weekEnd, 'yyy-MM-dd'),
    };
  };

  const TASK_STYLES = {
    sick: 'sick-task',
    "privilege leave": 'pto-task',
    default: 'default-task'
  };


  const mapWeekIndices = () => {
    const weekNumberMapping = {};
    
    let weekIndex = 1;
    if (weeklyReportList.length > 0) {
      const sampleWeekKeys = Object.keys(weeklyReportList[0].tasks[Object.keys(weeklyReportList[0].tasks)[0]]);


const local=localStorage.getItem('weekIndexlocal')




      sampleWeekKeys.forEach((weekKey) => {
        
        weekNumberMapping[weekKey] = `week ${parseInt(weekKey.split(' ')[1], 10)-localValue}`;
        ;
      });
    }

    return weekNumberMapping;
  };

  useEffect(() => {
    if (weeklyReportList.length > 0) {
      const sampleTask = weeklyReportList[0].tasks;
      const firstTaskKey = Object.keys(sampleTask)[0];
      const sampleWeekKeys = Object.keys(sampleTask[firstTaskKey]);

      let weekIndex = parseInt(sampleWeekKeys[0]?.split(' ')[1], 10)-1 ;
     
      localStorage.setItem('weekIndexlocal', weekIndex);
      
      const local = localStorage.getItem('weekIndexlocal');
      
      if(localValue==null){
        setLocalValue(local)
      }
    }
  }, [body]);


  const weekNumberMapping = mapWeekIndices();
  const weekKeys = Object.keys(weekNumberMapping);

  const handleExport = async () => {
    let body2 = {
      startDate: body.startDate,
      endDate: body.endDate,
      taskId: body.taskId,
      projectId: body.projectId
    };
    dispatch({ type: "SET_LOADING", payload: true });
    await getWeeklyReportAPI(body2);
    dispatch({ type: "SET_LOADING", payload: false });
  };

  const isFirstMonth = (selectedMonth, starting) => {
    const firstMonthValue = body.startDate;
    return starting <= firstMonthValue;
  };

  const isLastMonth = (selectedMonth, ending) => {
    const lastMonthValue = body.endDate;
    return ending >= lastMonthValue;
  };

  return (
    <div style={{ backgroundColor: 'white', borderRadius: '10px' }}>
      <Grid container sx={{ alignItems: 'center', p: 2 }}>
        <Grid item xs={12} md={4.5} sm={4.5} lg={4.5} className="WeeklyReport1stheading">
          Weekly Report- <span className="WeeklyreportprojectName">{body.projectName}</span>
        </Grid>

        <Grid item xs={12} md={1.5} sm={1.5} lg={1.5} sx={{ pl: 2 }}>
          <Button className="ExportButtoninWeeklyReports" onClick={handleExport}>Export</Button>
        </Grid>

        <Grid
          item
          xs={12}
          md={6}
          sm={6}
          lg={4}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end'
          }}
        >
          <TextField
            style={{ border: '1.5px solid #B2D4F8', borderRadius: '5px' }}
            variant="outlined"
            placeholder="Search by Employee ID, Employee Name"
            fullWidth
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            sx={{
              '& .MuiInputBase-input': {
                alignItems: 'center',
                height: '12px',
                fontSize: '0.8rem'
              },
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12} md={6} sm={6} lg={2} sx={{ pl: 1 }}>
          <FormControl fullWidth>
            <Select
              sx={{ height: '45px' }}
              id="monthSelect"
              displayEmpty
              value={selectedMonth}
              onChange={(e) => setSelectedMonth(e.target.value)}
              inputProps={{ 'aria-label': 'Without label' }}
            >
              <MenuItem value="" disabled>
                Select Month
              </MenuItem>
              {months.map((month, index) => (
                <MenuItem key={index} value={month.display}>
                  {month.display}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      <Grid container sx={{ pl: 1, pr: 1, pb: 2 }}>
        <table border="1" style={{ borderCollapse: 'collapse', width: '100%', textAlign: 'center', border: '1px solid #E9E9E9' }}>
          <thead>
            <tr>
              <th colSpan="5" className="wr-th-toprow">Week Start Date</th>
              {weekKeys.map((weekKey, index) => {
                const originalWeekKey = weekKey;
                const weekDates = calculateWeekDatesFromWeekNumber(originalWeekKey.split(' ')[1], startDate);
                let weekStart = weekDates.start;

                if (isFirstMonth(months, weekDates.start) && index === 0) {
                  weekStart = body.startDate;
                }

                return <th key={index} className="wr-th-toprow">{weekStart}</th>;
              })}
            </tr>
            <tr>
              <th colSpan="5" className="wr-th-botrow">Week End Date</th>
              {weekKeys.map((weekKey, index) => {
                const originalWeekKey = weekKey;
                const weekDates = calculateWeekDatesFromWeekNumber(originalWeekKey.split(' ')[1], startDate);
                let weekEnd = weekDates.end;

                if (isLastMonth(months, weekDates.end)) {
                  weekEnd = body.endDate;
                }

                return <th key={index} className="wr-th-botrow">{weekEnd}</th>;
              })}
            </tr>
          </thead>
          <thead>
            <tr>
              <th rowSpan="3" className="wr-first3-headings">S.no</th>
              <th rowSpan="3" className="wr-first3-headings">Employee ID</th>
              <th rowSpan="3" className="wr-first3-headings">Employee Name</th>
              <th rowSpan="3" className="wr-first3-headings" style={{ width: '80px' }}>Current Project<br /> Engagement Status</th>
              <th colSpan="1" className="wr-first3-headings">Task</th>
              {Object.keys(weekNumberMapping).map((weekKey, index) => (
                <th key={index} >{weekNumberMapping[weekKey]}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {weeklyReportList?.map((employeeData, index) => {
              const tasks = employeeData.tasks;
              const taskNames = Object.keys(tasks);

              return (
                <React.Fragment key={index}>
                  {taskNames.map((taskName, taskIndex) => (
                    <tr key={taskIndex}>
                      {taskIndex === 0 && (
                        <>
                          <td rowSpan={taskNames.length} className="wr-first3colsin-body">{(offset - 1) * itemsPerPage + index + 1}</td>
                          <td rowSpan={taskNames.length} className="wr-first3colsin-body">{employeeData.employeeId}</td>
                          <td rowSpan={taskNames.length} className="wr-first3colsin-body">{employeeData.employeeName}</td>
                          <td rowSpan={taskNames.length} className="wr-first3colsin-body" style={{ width: '100px' }}>{employeeData.projectStatus}</td>
                        </>
                      )}
                      <td className={`wr-tasks ${TASK_STYLES[taskName.toLowerCase()] || TASK_STYLES.default}`}>
                        {taskName}
                      </td>
                      {Object.keys(weekNumberMapping).map((originalWeekKey, weekIndex) => (
                        <td className={`wr-tasks ${TASK_STYLES[taskName.toLowerCase()] || TASK_STYLES.default}`} key={weekIndex}>
                          {tasks[taskName][originalWeekKey] || 0}
                        </td>
                      ))}
                    </tr>
                  ))}
                </React.Fragment>
              );
            })}
          </tbody>
        </table>
      </Grid>

      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
        <Pagination
          count={Math.ceil(count / itemsPerPage)}
          page={offset}
          
          siblingCount={1}
          onChange={(event, value) => handlePagination(value)}
          color="primary"
        />
      </Box>
    </div>
  );
};

export default WeeklyReport;

