// npm packages
import React, { useState, useEffect, Fragment, useContext } from "react";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import * as moment from "moment";
import { toast } from "react-toastify";
import Divider from "@mui/material/Divider";
import Alert from '@mui/material/Alert';
import CheckIcon from '@mui/icons-material/Check';

// custom pages
import LeaveAPI from "apis/panel/leaveRequest/leaveRequest";
// import EmployeeTaskAPI from "apis/panel/employeeTask/employeeTask";
import LeaveCreateAPI from "apis/panel/leaveRequest/leaveRequest"
import LeaveUpdateApi from "apis/panel/leaveRequest/leaveRequest"
import AllLeaveRequestAPI from "apis/panel/leaveRequest/leaveRequest";
import BalanceLeave from "apis/panel/leaveRequest/leaveRequest"
import EditLeaveRequest from "apis/panel/leaveRequest/leaveRequest"
import { toastCss, theme } from "config/config";
import NotFoundPage from "components/NotFoundPage";
import config from "config/config";
import context from "context/context";

// material ui
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import VisibilityIcon from '@mui/icons-material/Visibility';
import Paper from "@mui/material/Paper";
import CardContent from "@mui/material/CardContent";
import CloseIcon from '@mui/icons-material/Close';


// material ui icons
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  borderRadius: "12px",
  boxShadow: 24,
  p: 4,
};


const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} arrow placement="right" />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}));

export default function ViewLeave() {
  const { dispatch } = useContext(context);

  const { getAllLeaveAPI } = LeaveAPI();
  const { getLeaveCreateAPI } = LeaveCreateAPI();
  const { getLeaveUpdateAPI } = LeaveUpdateApi();
  const { getAllLeaveRequestAPI } = AllLeaveRequestAPI();
  const { getBalanceLeaveAPI } = BalanceLeave();
  const { getFetchLeaveRequestAPI } = EditLeaveRequest();


  // Create Employee Task
  const [leaveNames, setLeaveNames] = useState([]);
  // const [employeeTasks, setEmployeeTasks] = useState({});
  const [totalDays, setTotalDays] = useState(0)
  const [createObj, setCreateObj] = useState({
    leaveRequestId: "",
    leaveName: "",
    startDate: null,
    endDate: null,
    leaveTotal: 0,
    description: "",
    status: null
  });
  const [disableSubmitBtn, setDisableSubmitBtn] = useState(false);
  const [leaveRequests, setLeaveRequests] = useState([]);
  // const [managerApprovalReason, setManagerApprovalReason] = useState("");
  // const [editModes, setEditModes] = useState({});
  const [viewModalOpen, setViewModalOpen] = useState(false);
  const [selectedLeaveRequest, setSelectedLeaveRequest] = useState(null);
  const [viewLeaveModalOpen, setViewLeaveModalOpen] = useState(false);
  const [viewedLeaveDetails, setViewedLeaveDetails] = useState(null);
  const [editModes, setEditModes] = useState({});
  const [balanceLeave, setBalanceLeave] = useState([]);
  const [fetchDetails, setFetchDetails] = useState({})
  const [editModalOpen, setEditModalOpen] = useState(false);


  const handleEditModalOpen = () => {
    setEditModalOpen(true);
  };

  const handleEditModalClose = () => {
    setEditModalOpen(false);
    setFetchDetails({});
  };



  // Create Employee Task Modal
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // Filter Employee Task
  var d = new Date();
  d.setDate(d.getDate() - 6);
  // const [startDate, setStartDate] = useState();
  // const [endDate, setEndDate] = useState(new Date().setHours(0, 0, 0, 0));
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [viewLeave, setViewLeave] = useState()

  useEffect(() => {
    handleGetAllLeave();
    handleGetAllLeaveRequests(2024);
    if (createObj.leaveName) {
      handleGetBalanceLeave();
    };
    handleViewLeaveModalEdit();

  }, [createObj.leaveName]);

  const handleGetAllLeaveRequests = async (selectedYear) => {
    try {
      let res = await getAllLeaveRequestAPI({ year: selectedYear });
      setLeaveRequests(res?.data?.leaveRequests || []);
    } catch (err) {
      console.error(err);
    }
  };
  // const handleGetAllLeaveRequests = async (selectedYear) => {
  //   try {
  //     const year ={
  //       year :selectedYear
  //     }
  //     let res = await getAllLeaveRequestAPI(year);
  //     console.log("API Response - getAllLeaveRequestAPI:", res);
  //     setLeaveRequests(res?.data?.leaveRequest || []);
  //   } catch (err) {
  //     console.error(err);
  //   }
  // };


  const handleGetBalanceLeave = async () => {
    try {
      const data =
      {
        leaveTypeId: createObj?.leaveName?.leaveTypeId,
        year: new Date().getFullYear()
      }
      const res = await getBalanceLeaveAPI(data)
      const setRes = res.data ? res.data.leaveBalances : []
      setBalanceLeave(setRes.length)
    } catch (err) {
      console.error(err);
    }
  };

  const handleGetAllLeave = async () => {
    try {
      let res = await getAllLeaveAPI();
      setLeaveNames(res?.data?.leaveTypes || []);
    }
    catch (err) { }
  };

  const handleCreateLeave = async (e) => {
    try {
      if (!createObj.leaveName || !createObj.startDate || !createObj.endDate || !createObj.description) {
        setDisableSubmitBtn(false);
        return toast.error("Please fill all the required leave fields.", {
          taskId: "create-leave-error",
        });
      }
      if (viewedLeaveDetails) {
        // const values = {
        //   leaveRequestId: createObj.leaveRequestId,
        //   leaveTypeId: createObj.leaveName.leaveTypeId,
        //   startDate: moment(createObj.startDate).format("YYYY-MM-DD"),
        //   endDate: moment(createObj.endDate).format("YYYY-MM-DD"),
        //   leaveTotal: createObj.leaveTotal,
        //   reason: createObj.description,
        //   approvedBy: ""
        // };
        const values = {
          leaveRequestId: createObj.leaveRequestId,
          leaveTypeId: createObj.leaveName.leaveTypeId,
          startDate: moment(createObj.startDate).format("YYYY-MM-DD"),
          endDate: moment(createObj.endDate).format("YYYY-MM-DD"),
          leaveTotal: createObj.leaveTotal,
          reason: createObj.description,
          approvedBy: "",
          status: getStatusText(createObj.status)
        };
        const data = await getLeaveUpdateAPI(values);

        if (data.type === "SUCCESS") {
          toast.success("Leave Created", toastCss);
          setCreateObj({
            leaveRequestId: "",
            leaveName: "",
            startDate: null,
            endDate: null,
            leaveTotal: 0,
            description: "",
          });
          setLeaveRequests((prevLeaveRequests) => [
            ...prevLeaveRequests
          ]);
          handleClose();
          setDisableSubmitBtn(false);
        }
      } else {
        const values = {
          // leaveRequestId: createObj.leaveRequestId,
          leaveTypeId: createObj.leaveName.leaveTypeId,
          startDate: moment(createObj.startDate).format("YYYY-MM-DD"),
          endDate: moment(createObj.endDate).format("YYYY-MM-DD"),
          leaveTotal: createObj.leaveTotal,
          reason: createObj.description,
        };
        const data = await getLeaveCreateAPI(values);

        if (data.type === "SUCCESS") {
          toast.success("Leave Created", toastCss);
          setCreateObj({
            leaveName: "",
            startDate: null,
            endDate: null,
            leaveTotal: 0,
            description: "",
          });
          setLeaveRequests((prevLeaveRequests) => [
            ...prevLeaveRequests
          ]);
          handleClose();
          setDisableSubmitBtn(false);
        }
      }


    } catch (err) {
      console.error(err);
    }
  };

  function truncateSentence(sentence, maxWords) {
    const words = sentence.split(" ");

    if (words.length <= maxWords) {
      return sentence;
    }

    const truncatedWords = words.slice(0, maxWords);
    const truncatedSentence = truncatedWords.join(" ") + "...";

    return truncatedSentence;
  }

  useEffect(() => {
    if (createObj.startDate && createObj.endDate) {
      const daysDifference = calculateDateDifference(new Date(createObj.startDate), new Date(createObj.endDate));
      setCreateObj(prevState => ({ ...prevState, leaveTotal: daysDifference + 1 }));
    }
  }, [createObj.startDate, createObj.endDate]);

  function calculateDateDifference(startDate, endDate) {
    if (!startDate || !endDate) {
      return 0;
    }
    const startMillis = startDate.getTime();
    const endMillis = endDate.getTime();
    const differenceMillis = Math.abs(endMillis - startMillis);
    return Math.ceil(differenceMillis / (1000 * 60 * 60 * 24));
  }

  const handleYearChange = (selectedYear) => {
    handleGetAllLeaveRequests(selectedYear);
  };



  const handleViewModalOpen = (leaveRequest) => {
    setViewedLeaveDetails(leaveRequest);
    setViewModalOpen(true);
  };

  const handleViewModalClose = () => {
    setViewModalOpen(false);
    setSelectedLeaveRequest(null);
  };

  const getStatusText = (numericStatus) => {
    switch (numericStatus) {
      case 0:
        return 'Pending';
      case 1:
        return 'Approved';
      case 2:
        return 'Rejected';
      case 3:
        return 'Cancelled';
      default:
        return 'Unknown';
    }
  };

  const getStatusColor = (numericStatus) => {
    switch (numericStatus) {
      case 0:
        return '#4aa1dc';
      case 1:
        return '#529f54';
      case 2:
        return '#ce3d3d';
      case 3:
        return 'gray';
      default:
        return 'black';
    }
  };

  const handleViewLeaveModalOpen = (leaveRequest) => {
    setViewedLeaveDetails(leaveRequest);
    setViewLeaveModalOpen(true);
  };

  const handleViewLeaveModalClose = () => {
    setViewLeaveModalOpen(false);
    setViewedLeaveDetails(null);
  };

  // const handleViewLeaveModalEdit = (viewedLeaveDetails) => {
  //   // viewedLeaveDetails
  //   // console.log(viewedLeaveDetails.leaveRequestId, "leavenameeditmodel")
  //   // console.log(viewedLeaveDetails.leaveType.leaveTypeId,"leaveNameleaveName")
  //   setCreateObj({
  //     leaveRequestId:viewedLeaveDetails.leaveRequestId,
  //     leaveName: viewedLeaveDetails.leaveType.leaveName,
  //     startDate: new Date(viewedLeaveDetails.startDate),
  //     endDate: new Date(viewedLeaveDetails.endDate),
  //     leaveTotal: Number(viewedLeaveDetails.leaveTotal),
  //     description: viewedLeaveDetails.reason,
  //   });
  //   setViewLeaveModalOpen(false);
  //   handleOpen()
  //   // setViewedLeaveDetails(null);
  // };

  const handleViewLeaveModalEdit = async (viewedLeaveDetails) => {
    try {
      const leaveRequestId = viewedLeaveDetails.leaveRequestId;

      const res = await getFetchLeaveRequestAPI(leaveRequestId);

      if (res && res.data) {
        const leaveDetails = res.data.leaveRequest;

        setCreateObj({
          leaveRequestId: leaveDetails.leaveRequestId,
          leaveName: leaveDetails.leaveType.leaveType,
          startDate: new Date(leaveDetails.startDate),
          endDate: new Date(leaveDetails.endDate),
          leaveTotal: Number(leaveDetails.leaveTotal),
          description: leaveDetails.reason,
          status: leaveDetails.status
        });
        // handleViewLeaveModalOpen();
        setViewLeaveModalOpen(false);
        handleOpen()
      } else {
      }
    } catch (err) {
      console.error(err);
    }
  };

  // const handleViewLeaveModalEdit = async (viewedLeaveDetails) => {
  //   console.log("Edit button clicked. Opening modal...");
  //   try {
  //       console.log("Before setting viewLeaveModalOpen:", viewLeaveModalOpen);
  //       const leaveRequestId = viewedLeaveDetails.leaveRequestId;
  //       console.log(leaveRequestId, "leaveRequestData");

  //       const res = await getFetchLeaveRequestAPI(leaveRequestId);
  //       console.log("API responseleaverequest:", res);

  //       if (res && res.data) {
  //         setViewLeaveModalOpen(false);
  //           handleOpen()
  //       } else {
  //           console.error("Error fetching leave details:", res);
  //       }
  //   } catch (err) {
  //       console.error(err);
  //   }
  // };

  // // Add this useEffect to log the state after it's updated
  // useEffect(() => {
  //   console.log("After setting viewLeaveModalOpen:", viewLeaveModalOpen);
  // }, [viewLeaveModalOpen]);

  const handleEditClick = (leaveRequestId) => {
    setEditModes((prevEditModes) => ({
      ...prevEditModes,
      [leaveRequestId]: true,
    }));
  };

  const LeaveDetailsCard = ({ label, value }) => (
    <Card variant="outlined" style={{ width: "150%" }}>
      <CardContent>
        <Typography variant="h6" color="textSecondary">
          <b>{label}:</b> {value}
        </Typography>
      </CardContent>
    </Card>
  );

  return (
    <Fragment>
      <Card variant="outlined" sx={{ padding: "15px" }}>
        <Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <span style={{ fontSize: 20, fontWeight: "bold" }}> Leave</span>
            <Button
              variant="contained"
              onClick={() => {
                handleOpen();
                // handleCreateLeaveApis();
              }}
              sx={{ marginLeft: "auto" }}
            >
              Apply Leave
            </Button>
          </Box>
          <Box
            sx={{
              display: "flex",
              gap: "30px",
              justifyContent: "end",
              marginTop: "20px",
            }}
          >
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DemoContainer components={["DesktopDatePicker"]}>
                <DatePicker
                  label="Year"
                  views={['year']}
                  value={startDate || new Date()}
                  onChange={(date) => {
                    const selectedYear = date.getFullYear();
                    setStartDate(date);
                    handleYearChange(selectedYear);
                  }}
                />
              </DemoContainer>
            </LocalizationProvider>
          </Box>

          <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <b>Leave Type</b>
                  </TableCell>
                  <TableCell>
                    <b>Start Date</b>
                  </TableCell>
                  <TableCell>
                    <b>End Date</b>
                  </TableCell>
                  <TableCell>
                    <b>Total Days</b>
                  </TableCell>
                  <TableCell>
                    <b>Reason</b>
                  </TableCell>
                  <TableCell>
                    <b>Status</b>
                  </TableCell>
                  <TableCell>
                    <b>Operations</b>
                  </TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {leaveRequests.map((leaveRequest) => (
                  <TableRow key={leaveRequest.leaveRequestId}>
                    <TableCell>
                      {leaveRequest.leaveType.leaveName}
                    </TableCell>
                    <TableCell>
                      {moment(leaveRequest.startDate).format("YYYY-MM-DD")}
                    </TableCell>
                    <TableCell>
                      {moment(leaveRequest.endDate).format("YYYY-MM-DD")}
                    </TableCell>
                    <TableCell>
                      {leaveRequest.leaveTotal}
                    </TableCell>
                    <TableCell>
                      {leaveRequest.reason}
                    </TableCell>
                    <TableCell >
                      <Button
                        // style={{ color: getStatusColor(leaveRequest.status) }}
                        style={{
                          backgroundColor: getStatusColor(leaveRequest.status),
                          color: 'white',
                          marginRight: '10px',
                        }}

                      >
                        {getStatusText(leaveRequest.status)}
                      </Button>

                      {/* {leaveRequest.status === 0 && (
                        <Button style={{ backgroundColor: "RED", color: "White" }}>
                          CANCEL
                        </Button>
                      )} */}
                    </TableCell>
                    <TableCell>
                      <VisibilityIcon
                        onClick={() => handleViewModalOpen(leaveRequest)}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Card>

      {/* Create Modal */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <Typography id="transition-modal-title" variant="h6" component="h2">
              {viewedLeaveDetails === null ? "Request Leave" : "Edit Request Leave"}
            </Typography>
            <Box sx={{ marginTop: "20px" }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} lg={6}>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={leaveNames || []}
                    // getOptionLabel={((option) => option?.leaveNames) || ""}
                    getOptionLabel={(option) => option?.leaveName || ""}
                    onChange={(event, newValue, reason) => {
                      if (reason === "clear") {
                        setCreateObj({ ...createObj, leaveName: [] });
                        return;
                      }
                      setCreateObj({ ...createObj, leaveName: newValue });
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Leave Type *" />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={12} lg={6}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} lg={6}>
                      <Alert icon={false} severity="info">
                        Total: {createObj?.leaveTotal}
                      </Alert>
                      {/* <FormControl sx={{ m: 1, width: "100%", margin: "0px 0px 0px 0px" }}>
                        <TextField
                          labelId="total-days-label"
                          id="total-days"
                          value={createObj?.leaveTotal}
                          label="Total Days *"
                          disabled
                          onChange={(e) => {
                            setCreateObj({
                              ...createObj,
                              leaveTotal: e.target.value,
                            });
                          }}
                        />
                      </FormControl> */}
                    </Grid>
                    <Grid item xs={12} sm={12} lg={6}>
                      {/* <Alert icon={<CheckIcon fontSize="inherit" />} severity="success">
                        Balance: {balanceLeave} */}
                      {/* </Alert> */}
                      {createObj?.leaveName ? (
                        <Alert
                          icon={<CheckIcon fontSize="inherit" />}
                          severity={balanceLeave >= 1 && balanceLeave <= 3 ? "warning" : balanceLeave < 0 ? "error" : "success"}
                        >
                          Balance: {balanceLeave}
                        </Alert>
                      ) : (
                        null
                      )}
                      {/* <FormControl sx={{ m: 1, width: "100%", margin: "0px 0px 0px 0px" }}>
                        <TextField
                          labelId="balance-leave-label"
                          id="balance-leave"
                          value={balanceLeave}
                          label="Balance Leave *"
                        // onChange={(e) => {
                        //   setCreateObj({
                        //     ...createObj,
                        //     leaveTotal: e.target.value,
                        //   });
                        // }}
                        />
                      </FormControl> */}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={12} lg={6}>
                  <FormControl sx={{ m: 1, width: "100%", margin: "0px 0px 0px 0px" }}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DemoContainer components={["DesktopDatePicker"]}>
                        <DatePicker
                          label=" Start Date *"
                          sx={{ width: "200%" }}
                          disablePast={true}
                          format="dd-MM-yyyy"
                          defaultValue={createObj?.startDate}
                          onChange={(date) => {
                            setCreateObj({ ...createObj, startDate: date });
                          }}
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} lg={6}>
                  <FormControl sx={{ m: 1, width: "100%", margin: "0px 0px 0px 0px" }}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DemoContainer components={["DesktopDatePicker"]}>
                        <DatePicker
                          label=" End Date *"
                          sx={{ width: "100%" }}
                          disablePast={true}
                          format="dd-MM-yyyy"
                          defaultValue={createObj?.endDate}
                          onChange={(date) => {
                            setCreateObj({ ...createObj, endDate: date });
                          }}
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                  </FormControl>
                </Grid>
              </Grid>
            </Box>
            <Box sx={{ marginTop: "20px" }}>
              <Grid item xs={12} sm={12} lg={6}>
                <TextField
                  sx={{ width: "100%" }}
                  id="outlined-basic"
                  label="Description"
                  variant="outlined"
                  required
                  multiline
                  value={createObj?.description}
                  onChange={(e) => {
                    setCreateObj({
                      ...createObj,
                      description: e.target.value,
                    });
                  }}
                />
              </Grid>
            </Box>

            <Divider sx={{ margin: "32px 0px" }} />
            <Box sx={{ marginTop: "30px" }}>
              <Grid container spacing={2}>
                <Grid item xs={12} style={{ textAlign: "right" }}>
                  <Button
                    sx={{
                      padding: "8px 20px",
                      borderRadius: "8px",
                      marginRight: "32px",
                      backgroundColor: "#fff",
                      color: config?.theme?.primary?.main,
                      border: `1px solid ${config?.theme?.primary?.main}`,
                      "&:hover": {
                        backgroundColor: "#fff",
                      },
                    }}
                    variant="contained"
                    onClick={() => {
                      handleClose();
                      setTotalDays(0)
                      setCreateObj({
                        leaveName: {},
                        dateWorked: new Date(),
                        leaveTotal: 0,
                        description: "",
                      });
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    sx={{
                      padding: "8px 20px",
                      borderRadius: "8px",
                    }}
                    variant="contained"
                    disabled={disableSubmitBtn}
                    onClick={() => {
                      setDisableSubmitBtn(true);
                      // handleCreateLeave();
                      handleCreateLeave();
                    }}
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Fade>
      </Modal>
      {viewedLeaveDetails && (
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={viewModalOpen}
          onClose={handleViewModalClose}
        >
          <Fade in={viewModalOpen}>
            <Box sx={style}>
              <Typography variant="h6" component="h2">
                <h2>Leave Request Details</h2>
              </Typography>

              {/* <Grid container spacing={2}>
      <Grid item xs={12} sm={6} md={4}>
        <LeaveDetailsCard
          label="Start Date"
          value={moment(viewedLeaveDetails.startDate).format("YYYY-MM-DD")}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <LeaveDetailsCard
          label="End Date"
          value={moment(viewedLeaveDetails.endDate).format("YYYY-MM-DD")}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <LeaveDetailsCard
          label="Status"
          value={getStatusText(viewedLeaveDetails.status)}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <LeaveDetailsCard
          label="Remarks"
          value={viewedLeaveDetails.remarks || ""}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <LeaveDetailsCard
          label="Leave Type"
          value={viewedLeaveDetails.leaveType.leaveName}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <LeaveDetailsCard label="Reason" value={viewedLeaveDetails.reason} />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <LeaveDetailsCard
          label="Total Days"
          value={viewedLeaveDetails.leaveTotal}
        />
      </Grid> */}
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Typography>
                    <b>Start Date:</b> {moment(viewedLeaveDetails.startDate).format("YYYY-MM-DD")}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography>
                    <b>End Date:</b> {moment(viewedLeaveDetails.endDate).format("YYYY-MM-DD")}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography>
                    <b>Status:</b> {getStatusText(viewedLeaveDetails.status)}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography>
                    <b>Remarks:</b> {viewedLeaveDetails.remarks || ''}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography>
                    <b>Leave Type:</b> {viewedLeaveDetails.leaveType.leaveName}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography>
                    <b>Reason:</b> {viewedLeaveDetails.reason}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography>
                    <b>Total Days:</b> {viewedLeaveDetails.leaveTotal}
                  </Typography>
                </Grid>

                {/* <Grid item xs={12} sm={6}>
                  <Typography>
                    <b>Remarks:</b> {viewedLeaveDetails.remarks || ''}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography>
                    <b>Leave Type:</b> {viewedLeaveDetails.leaveType.leaveName}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography>
                    <b>Reason:</b> {viewedLeaveDetails.reason}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography>
                    <b>Total Days:</b> {viewedLeaveDetails.leaveTotal}
                  </Typography>
                </Grid> */}
              </Grid>
              <Divider sx={{ margin: "32px 0px" }} />
              <Box sx={{ marginTop: "30px" }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} style={{ textAlign: "right" }}>
                    <Button
                      sx={{
                        padding: "8px 20px",
                        borderRadius: "8px",
                        backgroundColor: "#fff",
                        color: config?.theme?.primary?.main,
                        border: `1px solid ${config?.theme?.primary?.main}`,
                        position: "absolute",
                        top: "15px",
                        right: "10px",
                        zIndex: 1,
                        "&:hover": {
                          backgroundColor: "#fff",
                        },
                      }}
                      variant="contained"
                      onClick={handleViewLeaveModalClose}>
                      <CloseIcon />
                    </Button>
                    {new Date(viewedLeaveDetails.startDate) > new Date() && (
                      <Button
                        onClick={() => { handleViewLeaveModalEdit(viewedLeaveDetails) }}
                        sx={{
                          padding: "8px 20px",
                          borderRadius: "8px",
                        }}
                        variant="contained">
                        Edit
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Fade>
        </Modal>
      )}
    </Fragment>
  );
}
