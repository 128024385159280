// npm packages
import React, { useContext, useReducer, useEffect } from "react";
import ReactDOM from "react-dom/client";
import {
  Route,
  Routes,
  BrowserRouter as Router,
  Navigate,
} from "react-router-dom";
import { createBrowserHistory } from "history";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "components/Loader";
// custom pages
import parentRoutes from "routes/parentRoute";
import Context from "context/context";
import Reducer from "reducer/reducer";
import "./assets/styles/custom.css";
// import reportWebVitals from "./reportWebVitals";
import AuthAPI from "apis/auth/auth";
import { msalConfig } from "../src/config/config.js";
import { MsalProvider, MsalAuthenticationTemplate, useMsal } from '@azure/msal-react';
import { PublicClientApplication, EventType } from '@azure/msal-browser';
// material ui
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { GoogleOAuthProvider } from '@react-oauth/google';
import Login from "../src/views/auth/login/Login.jsx"; // Ensure the path is correct

let history = createBrowserHistory();

const msalInstance = new PublicClientApplication(msalConfig);

const clientId = '6062148310-fmhvrd07ghkar0npoqr9ipegejfaf9di.apps.googleusercontent.com';

const theme = createTheme({
  palette: {
    primary: {
      main: "#B2D4F8",
    },
    secondary: {
      main: "#E0C2FF",
    },
  },
});

// setting the routes
const ParentRoutes = parentRoutes.map((route, i) => {
  return (
    <Route
      key={i}
      path={route.path}
      element={<route.component key={`component${i}`} />}
    />
  );
});

function Index() {
  const globalState = useContext(Context);
  const [state, dispatch] = useReducer(Reducer, globalState);

  const { refreshAPI } = AuthAPI();

  // handling loggedin api
  useEffect(() => {
    handleRefresh();
  }, []);

  const handleRefresh = async () => {
    dispatch({ type: "SET_LOADING", payload: true });

    try {
      let res = await refreshAPI();
      dispatch({ type: "SET_IS_LOGGED_IN", payload: true });
      dispatch({ type: "SET_EMPLOYEE", payload: res?.data?.employee });
      dispatch({ type: "SET_PERMISSION", payload: res?.data?.permission });
      dispatch({ type: "SET_ROLE", payload: res?.data?.role });
      dispatch({ type: "SET_LOADING", payload: false });
    } catch (err) {
      console.log({ err });
    }
  };

  return (
    <Context.Provider value={{ state, dispatch }}>
      <MsalProvider instance={msalInstance}>
        <GoogleOAuthProvider clientId={clientId}>
          <Loader loading={state.loading} />
          <ToastContainer />
          <Routes>
            <Route path="/" element={<Navigate to="/login" />} />
            <Route path="/login" element={<Login />} />
            {ParentRoutes}
          </Routes>
        </GoogleOAuthProvider>
      </MsalProvider>
    </Context.Provider>
  );
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ThemeProvider theme={theme}>
    <Router history={history}>
      <Index />
    </Router>
  </ThemeProvider>
);
