import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import "./EditAddEmployee.css"
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import personalInfo from "assets/images/Capa_1.png";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import getAllCountriesdrop from "apis/panel/Contact/contact";
import getStateCountrydrop from "apis/panel/Contact/contact";
import getAllStatesdrop from "apis/panel/Contact/contact";
import getStatecityDrop from "apis/panel/Contact/contact";
import getAllCitiesDrop from "apis/panel/Contact/contact";
import PostApi from "apis/panel/Contact/contact"
import getcreatefindone from "apis/panel/AddEmployee/addEmployee"
import { toast } from "react-toastify";
import Loader from "components/Loader";

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

const EditContactInfo = () => {
  let params = useParams();
  const employeeId = params.id;
  const [isEditMode, setIsEditMode] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [loader2, setLoader2] = useState(false)

  const [selectedDoorNo, setSelectedDoorNo] = useState("");
  const [selectedStreetName, setSelectedStreetName] = useState("");
  const [selectedCountries, setSelectedCountries] = useState([]);
  const [selectedCountryId, setSelectedCountryId] = useState("");
  const [selectedCountryName, setSelectedCountryName] = useState();
  const [selectedStates, setSelectedStates] = useState([]);
  const [selectedStateId, setSelectedStateId] = useState("");
  const [selectedStateName, setSelectedStateName] = useState();
  const [selectedCities, setSelectedCities] = useState([]);
  const [selectedCityId, setSelectedCityId] = useState("");
  const [selectedCityName, setSelectedCityName] = useState();
  const [selectedPostalCode, setSelectedPostalCode] = useState("");

  const [isPermanentSameAsCurrent, setIsPermanentSameAsCurrent] = useState(false);

  const [selectedPDoorNo, setSelectedPDoorNo] = useState("");
  const [selectedPStreetName, setSelectedPStreetName] = useState("");
  const [selectedPCountries, setSelectedPCountries] = useState([]);
  const [selectedPCountryId, setSelectedPCountryId] = useState("");
  const [selectedPCountryName, setSelectedPCountryName] = useState();
  const [selectedPStates, setSelectedPStates] = useState([]);
  const [selectedPStateId, setSelectedPStateId] = useState("");
  const [selectedPStateName, setSelectedPStateName] = useState();
  const [selectedPCities, setSelectedPCities] = useState([]);
  const [selectedPCityId, setSelectedPCityId] = useState("");
  const [selectedPCityName, setSelectedPCityName] = useState();
  const [selectedPPostalCode, setSelectedPPostalCode] = useState("");

  const { getAllCountriesAPI } = getAllCountriesdrop();
  const { getStateCountryApi } = getStateCountrydrop();
  const { getAllStatesApi } = getAllStatesdrop();
  const { getStatecityApi } = getStatecityDrop();
  const { getAllCitiesApi, getOneCitiesApi } = getAllCitiesDrop();
  const { getContactCreateAPI } = PostApi()
  const { getEmployeefindone } = getcreatefindone();

  useEffect(() => {
    handleGetAllCountries();
    handleGetEmployeeData();
  }, []);

  const handleGetAllCountries = async () => {
    try {
      let res = await getAllCountriesAPI();
      setSelectedCountries(res?.data || []);
      setSelectedPCountries(res?.data || []);
    }
    catch (err) {
      console.error("Error fetching countries:", err);
    }
  };

  const handleGetEmployeeData = async (e) => {
    const values = {
      relID: employeeId
    };
    const data = await getEmployeefindone(values);
    let address = data?.data?.address;
    for (let index = 0; index < address?.length; index++) {
      const element = address[index];
      if (element.addressType == 'current') {
        setSelectedDoorNo(element?.doorNo);
        setSelectedStreetName(element?.street);
        setSelectedCountryId(element?.countryId);
        let states = await getStateCountryApi({ countryId: element?.countryId });
        setSelectedStates(states.data);
        setSelectedCountryName(element?.countries?.countryName);
        setSelectedStateId(element?.stateId);
        let cities = await getStatecityApi({ StateId: element?.stateId });
        setSelectedCities(cities.data);
        setSelectedStateName(element?.states?.stateName);
        setSelectedCityId(element?.cityId);
        setSelectedCityName(element?.cities?.cityName);
        setSelectedPostalCode(element?.postalCode)
        setIsPermanentSameAsCurrent(element?.isPermanent);
      }
      if (element.addressType == 'permanent') {
        setSelectedPDoorNo(element?.doorNo);
        setSelectedPStreetName(element?.street);
        setSelectedPCountryId(element?.countryId);
        let states = await getStateCountryApi({ countryId: element?.countryId });
        setSelectedPStates(states.data);
        setSelectedPCountryName(element?.countries?.countryName);
        setSelectedPStateId(element?.stateId);
        let cities = await getStatecityApi({ StateId: element?.stateId });
        setSelectedPCities(cities.data);
        setSelectedPStateName(element?.states?.stateName);
        setSelectedPCityId(element?.cityId);
        setSelectedPCityName(element?.cities?.cityName);
        setSelectedPPostalCode(element?.postalCode)
        setIsPermanentSameAsCurrent(element?.isPermanent);
      }
    }
  }

  const handleDoorNo = (event) => {
    setSelectedDoorNo(event.target.value);
  };

  const handleStreetName = (event) => {
    setSelectedStreetName(event.target.value);
  };

  const handleCountry = async (event) => {
    try {
      const countryId = event.target.value;
      if (countryId !== "") {
        setSelectedCountryId(countryId);
        let states = await getStateCountryApi({ countryId: countryId });
        setSelectedStates(states.data);
        // setSelectedCountryName(states.data.countries.countryName);
      }
    }
    catch (err) {
      console.error("Error fetching states:", err);
    }
  };

  const handleState = async (event) => {
    try {
      const stateId = event.target.value;
      if (stateId !== "") {
        setSelectedStateId(stateId);
        let cities = await getStatecityApi({ StateId: stateId });
        setSelectedCities(cities.data);
        // setSelectedStateName(cities.data.states.stateName);
      }
    }
    catch (err) {
      console.error("Error fetching cities:", err);
    }
  };

  const handleCity = async (event) => {
    try {
      const cityId = event.target.value;
      if (cityId) {
        setSelectedCityId(cityId);
      }
      // let city = await getOneCitiesApi(cityId);
      // setSelectedCityName(city.data.cityName);
    }
    catch (err) {
      console.error("Error fetching cities:", err);
    }
  };

  const handlePostalCode = (event) => {
    const code = event.target.value;
    setSelectedPostalCode(code);
  };

  const handleCheckboxChange = (event) => {
    const isChecked = event.target.checked;
    setIsPermanentSameAsCurrent(isChecked);
  };

  const handlePCountry = async (event) => {
    try {
      const countryId = event.target.value;
      if (countryId !== "") {
        setSelectedPCountryId(countryId);
        let states = await getStateCountryApi({ countryId: countryId });
        setSelectedPStates(states.data);
      }
    }
    catch (err) {
      console.error("Error fetching states:", err);
    }
  };

  const handlePState = async (event) => {
    try {
      const stateId = event.target.value;
      if (stateId !== "") {
        setSelectedPStateId(stateId);
        let cities = await getStatecityApi({ StateId: stateId });
        setSelectedPCities(cities.data);
      }
    }
    catch (err) {
      console.error("Error fetching cities:", err);
    }
  };

  const handlePCity = async (event) => {
    try {
      const cityId = event.target.value;
      if (cityId !== "") {
        setSelectedPCityId(cityId);
      }
    }
    catch (err) {
      console.error("Error fetching cities:", err);
    }
  };

  const handleUpdateAddress = async (e) => {
    setLoader2(true)
    const isValid = validateForm();
    if (isValid) {
      const requestData = {
        employeeId: employeeId,
        addressType: "current",
        doorNo: selectedDoorNo,
        street: selectedStreetName,
        countryId: selectedCountryId,
        stateId: selectedStateId,
        cityId: selectedCityId,
        postalCode: selectedPostalCode,
        isPermanent: isPermanentSameAsCurrent,
        permanentAddress: isPermanentSameAsCurrent ? null : {
          addressType: "permanent",
          doorNo: selectedPDoorNo,
          street: selectedPStreetName,
          postalCode: selectedPPostalCode,
          countryId: selectedPCountryId,
          stateId: selectedPStateId,
          cityId: selectedPCityId
        }
      };

      const data = await getContactCreateAPI(requestData);
      if (data.status === 'Ok') {
        handleGetEmployeeData();
        setLoader2(false)
        return toast.success("Data Saved Successfully", { toast: "Data Saved Successfully" });
      }
      else {
        setLoader2(false)
        return toast.error("Error saving Data", { toast: "Error saving Data" });
      }
    }
    else {
      setLoader2(false)
    }
  };

  const [errorFields, setErrorFields] = useState({
    doorNo: false,
    streetName: false,
    countryId: false,
    stateId: false,
    cityId: false,
    postalCode: false,
    pDoorNo: false,
    pStreetName: false,
    pCountryId: false,
    pStateId: false,
    pCityId: false,
    pPostalCode: false
  });

  const validateForm = () => {
    const errors = {
      doorNo: selectedDoorNo.trim() === "",
      streetName: selectedStreetName.trim() === "",
      countryId: selectedCountryId === "",
      stateId: selectedStateId === "",
      cityId: selectedCityId === "",
      postalCode: selectedPostalCode.trim() === ""
    };

    // Validate permanent address fields only if the checkbox is unchecked
    if (!isPermanentSameAsCurrent) {
      errors.pDoorNo = selectedPDoorNo.trim() === "";
      errors.pStreetName = selectedPStreetName.trim() === "";
      errors.pCountryId = selectedPCountryId === "";
      errors.pStateId = selectedPStateId === "";
      errors.pCityId = selectedPCityId === "";
      errors.pPostalCode = selectedPPostalCode.trim() === "";
    }

    setErrorFields(errors);

    // Check if any field has errors
    return !Object.values(errors).some(error => error);
  };

  const toggleEditMode = (event) => {
    event.stopPropagation();
    setIsEditMode(!isEditMode);
  };

  const handleAccordionChange = (event, isExpanded) => {
    setExpanded(isExpanded);
    if (!isExpanded) {
      setIsEditMode(false);
    }
  };

  return (
    <div>
      <Loader loading={loader2} />
      <Accordion sx={{ mt: 2, boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }} onChange={handleAccordionChange}>
        <AccordionSummary
          expandIcon={<ArrowDropDownIcon className="Accordianicon" />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Box sx={{ pl: 3 }}> <img sx={{ pl: 3 }} className='editpersonalInfo-logo' src={personalInfo} alt="info" /></Box>
          <Box className="editacc-title" sx={{ pl: 3 }}>Address Information</Box>
          <Box sx={{ ml: 40 }}>
            {expanded && (
              <button className="editButton" onClick={toggleEditMode}>
                Edit
                <Box sx={{ flexGrow: 1 }} />
                {isEditMode}
              </button>
            )}
          </Box>
        </AccordionSummary>
        <AccordionDetails onClick={(event) => event.stopPropagation()}>
          <Grid container spacing={2} sx={{ pl: 5, pr: 5 }} >
            <Grid container spacing={2} sx={{ pl: 4, pr: 5, mt: 2 }} >
              <Box className="subHeadingforinfos" > Current Address</Box>
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ pl: 5, pr: 5, mt: 2 }} >
            {
              isEditMode ? (
                <Grid item xs={12} sm={6} md={6} lg={6}>
                  <Box className="label">Flat, House No <span style={{ color: "red" }}>*</span></Box>
                  <TextField
                    id="currentAddress"
                    placeholder="Enter Address"
                    variant="outlined"
                    fullWidth
                    value={selectedDoorNo}
                    onChange={handleDoorNo}
                  />
                  {errorFields.doorNo && <p className="error-message">Door number is required</p>}
                </Grid>
              ) : (
                <Grid item xs={12} sm={4} md={4} lg={4} sx={{ mt: 2 }}>
                  <Box className="editlabel">Flat, House no</Box>
                  <Box className="editvalue">{selectedDoorNo}</Box>
                </Grid>
              )
            }
            {
              isEditMode ? (
                <Grid item xs={12} sm={6} md={6} lg={6}>
                  <Box className="label">Street Name<span style={{ color: "red" }}>*</span></Box>
                  <TextField
                    id="currentAddressStreet"
                    placeholder="Street Name"
                    variant="outlined"
                    fullWidth
                    value={selectedStreetName}
                    onChange={handleStreetName}
                  />
                  {errorFields.streetName && <p className="error-message">Street name is required</p>}
                </Grid>
              ) : (
                <Grid item xs={12} sm={4} md={4} lg={4} sx={{ mt: 2 }}>
                  <Box className="editlabel">Street</Box>
                  <Box className="editvalue">{selectedStreetName}</Box>
                </Grid>
              )
            }
            {
              isEditMode ? (
                <Grid item xs={12} sm={6} md={6} lg={6}>
                  <Box className="label">Country <span style={{ color: "red" }}>*</span></Box>
                  <FormControl fullWidth>
                    <Select
                      id="countryId"
                      placeholder="Select Country"
                      displayEmpty
                      inputProps={{ 'aria-label': 'Without label' }}
                      style={{ border: "1.5px solid #B2D4F8", color: "grey" }}
                      value={selectedCountryId}
                      onChange={(e) => { handleCountry(e) }}
                    >
                      <MenuItem value=""> Select Country </MenuItem>
                      {selectedCountries.map((country, index) => (
                        <MenuItem key={index} value={country.countryId} data-name={country.countryName}> {country.countryName} </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {errorFields.countryId && <p className="error-message">Country is required</p>}
                </Grid>
              ) : (
                <Grid item xs={12} sm={4} md={4} lg={4} sx={{ mt: 2 }}>
                  <Box className="editlabel">Country</Box>
                  <Box className="editvalue">{selectedCountryName}</Box>
                </Grid>
              )
            }
            {
              isEditMode ? (
                <Grid item xs={12} sm={6} md={6} lg={6}>
                  <Box className="label">State <span style={{ color: "red" }}>*</span></Box>
                  <FormControl fullWidth>
                    <Select
                      id="stateId"
                      placeholder="Select State"
                      displayEmpty
                      value={selectedStateId}
                      inputProps={{ 'aria-label': 'Without label' }}
                      style={{ border: "1.5px solid #B2D4F8", color: "grey" }}
                      onChange={handleState}
                    >
                      <MenuItem value=""> Select State </MenuItem>
                      {selectedStates.map((state, index) => (
                        <MenuItem key={index} value={state.stateId}> {state.stateName} </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {errorFields.stateId && <p className="error-message">State is required</p>}
                </Grid>
              ) : (
                <Grid item xs={12} sm={4} md={4} lg={4} sx={{ mt: 1 }}>
                  <Box className="editlabel">State</Box>
                  <Box className="editvalue">{selectedStateName}</Box>
                </Grid>
              )
            }
            {
              isEditMode ? (
                <Grid item xs={12} sm={6} md={6} lg={6}>
                  <Box className="label">City <span style={{ color: "red" }}>*</span></Box>
                  <FormControl fullWidth>
                    <Select
                      id="cityId"
                      placeholder="Select City"
                      style={{ border: "1.5px solid #B2D4F8", color: "grey" }}
                      displayEmpty
                      onChange={handleCity}
                      value={selectedCityId}
                      inputProps={{ 'aria-label': 'Without label' }}
                    >
                      <MenuItem value=""> Select City </MenuItem>
                      {selectedCities.map((city, index) => (
                        <MenuItem key={index} value={city.cityId}> {city.cityName} </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {errorFields.cityId && <p className="error-message">City is required</p>}
                </Grid>
              ) : (
                <Grid item xs={12} sm={4} md={4} lg={4} sx={{ mt: 2 }}>
                  <Box className="editlabel">City</Box>
                  <Box className="editvalue">{selectedCityName}</Box>
                </Grid>
              )
            }
            {
              isEditMode ? (
                <Grid item xs={12} sm={6} md={6} lg={6}>
                  <Box className="label">Postal Code <span style={{ color: "red" }}>*</span></Box>
                  <TextField style={{ border: "1.5px solid #B2D4F8" }} id="postalCode" placeholder="Postal Code" value={selectedPostalCode} onChange={handlePostalCode} variant="outlined" fullWidth />
                  {errorFields.postalCode && <p className="error-message">Postal Code is required</p>}
                </Grid>
              ) : (
                <Grid item xs={12} sm={4} md={4} lg={4} sx={{ mt: 1 }}>
                  <Box className="editlabel">Postal Code</Box>
                  <Box className="editvalue">{selectedPostalCode}</Box>
                </Grid>
              )
            }
          </Grid>

          <Grid container spacing={2} sx={{ pl: 7, pr: 5, mt: 4 }}>
            <Box className="subHeadingforinfos"> Permanent Address
              <span style={{ color: "#FE602F", fontWeight: "400", alignSelf: "center" }}>
                {isEditMode ? (
                  <>
                    <Checkbox style={{ color: "#FE602F" }} checked={isPermanentSameAsCurrent} onChange={handleCheckboxChange} /> Same as Current Address
                  </>
                ) : (
                  <span>
                    <b> is current address : </b>
                    {isPermanentSameAsCurrent ? <span style={{ color: "green" }}> YES</span> : <span style={{ color: "red" }}> NO</span>}
                  </span>
                )}
              </span>
            </Box>
          </Grid>

          {!isPermanentSameAsCurrent && (
            <Grid container spacing={2} sx={{ pl: 5, pr: 5, mt: 1, pb: 5 }} >
              {
                isEditMode ? (
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <Box className="label">Flat, House No <span style={{ color: "red" }}>*</span></Box>
                    <TextField style={{ border: "1.5px solid #B2D4F8" }}
                      id="pDoorNo"
                      placeholder="Enter Address"
                      value={selectedPDoorNo}
                      onChange={(e) => setSelectedPDoorNo(e.target.value)}
                      variant="outlined"
                      fullWidth
                    />
                    {errorFields.pDoorNo && <p className="error-message">Door number is required</p>}
                  </Grid>
                ) : (
                  <Grid item xs={12} sm={4} md={4} lg={4} sx={{ mt: 2 }}>
                    <Box className="editlabel">Flat, House no</Box>
                    <Box className="editvalue">{selectedPDoorNo}</Box>
                  </Grid>
                )
              }
              {
                isEditMode ? (
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <Box className="label">Street Name<span style={{ color: "red" }}>*</span></Box>
                    <TextField style={{ border: "1.5px solid #B2D4F8" }}
                      id="pStreetName"
                      placeholder="Street Address"
                      value={selectedPStreetName}
                      onChange={(e) => setSelectedPStreetName(e.target.value)}
                      variant="outlined"
                      fullWidth
                    />
                    {errorFields.pStreetName && <p className="error-message">Permanent Street Name is required</p>}
                  </Grid>
                ) : (
                  <Grid item xs={12} sm={4} md={4} lg={4} sx={{ mt: 2 }}>
                    <Box className="editlabel">Street</Box>
                    <Box className="editvalue">{selectedPStreetName}</Box>
                  </Grid>
                )
              }
              {
                isEditMode ? (
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <Box className="label">Country <span style={{ color: "red" }}>*</span></Box>
                    <FormControl fullWidth>
                      <Select
                        labelId="permanent-country-label"
                        id="pCountryId"
                        displayEmpty
                        inputProps={{ 'aria-label': 'Without label' }}
                        style={{ border: "1.5px solid #B2D4F8", color: "grey" }}
                        value={selectedPCountryId}
                        onChange={handlePCountry}
                      >
                        <MenuItem value=""> Select Country </MenuItem>
                        {selectedPCountries.map((country, index) => (
                          <MenuItem key={index} value={country.countryId}> {country.countryName} </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    {errorFields.pCountryId && <p className="error-message">Permanent Country is required</p>}
                  </Grid>
                ) : (
                  <Grid item xs={12} sm={4} md={4} lg={4} sx={{ mt: 2 }}>
                    <Box className="editlabel">Country</Box>
                    <Box className="editvalue">{selectedPCountryName}</Box>
                  </Grid>
                )
              }
              {
                isEditMode ? (
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <Box className="label">State <span style={{ color: "red" }}>*</span></Box>
                    <FormControl fullWidth>
                      <Select
                        id="pStateId"
                        placeholder="Select State"
                        displayEmpty
                        value={selectedPStateId}
                        inputProps={{ 'aria-label': 'Without label' }}
                        style={{ border: "1.5px solid #B2D4F8", color: "grey" }}
                        onChange={handlePState}
                      >
                        <MenuItem value=""> Select State </MenuItem>
                        {selectedPStates.map((state, index) => (
                          <MenuItem key={index} value={state.stateId}> {state.stateName} </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    {errorFields.pStateId && <p className="error-message">Permanent State is required</p>}
                  </Grid>
                ) : (
                  <Grid item xs={12} sm={4} md={4} lg={4} sx={{ mt: 2 }}>
                    <Box className="editlabel">State</Box>
                    <Box className="editvalue">{selectedPStateName}</Box>
                  </Grid>
                )
              }
              {
                isEditMode ? (
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <Box className="label">City <span style={{ color: "red" }}>*</span></Box>
                    <FormControl fullWidth>
                      <Select
                        labelId="permanent-city-label"
                        id="pCityId"
                        placeholder="Select City"
                        style={{ border: "1.5px solid #B2D4F8", color: "grey" }}
                        value={selectedPCityId}
                        onChange={handlePCity}
                        displayEmpty
                        inputProps={{ 'aria-label': 'Without label' }}
                      >
                        <MenuItem value=""> Select City </MenuItem>
                        {selectedPCities.map((city, index) => (
                          <MenuItem key={index} value={city.cityId}> {city.cityName} </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    {errorFields.pCityId && <p className="error-message">Permanent State is required</p>}
                  </Grid>
                ) : (
                  <Grid item xs={12} sm={4} md={4} lg={4} sx={{ mt: 2 }}>
                    <Box className="editlabel">City</Box>
                    <Box className="editvalue">{selectedPCityName}</Box>
                  </Grid>
                )
              }
              {
                isEditMode ? (
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <Box className="label">Postal Code <span style={{ color: "red" }}>*</span></Box>
                    <TextField
                      id="pPostalCode"
                      placeholder="Postal Code"
                      variant="outlined"
                      fullWidth
                      value={selectedPPostalCode}
                      onChange={(e) => setSelectedPPostalCode(e.target.value)}
                    />
                    {errorFields.pPostalCode && <p className="error-message">Permanent PostalCode is required</p>}
                  </Grid>
                ) : (
                  <Grid item xs={12} sm={4} md={4} lg={4} sx={{ mt: 2 }}>
                    <Box className="editlabel">Postal Code</Box>
                    <Box className="editvalue">{selectedPPostalCode}</Box>
                  </Grid>
                )
              }
            </Grid>
          )}

          {isEditMode && (
            <Grid container spacing={1} sx={{ pl: 4, pr: 5, mt: 5, justifyContent: "center" }} >
              <Grid item xs={12} sm={2} md={2} lg={2}>
                <Button className="cancelButton" onClick={toggleEditMode}>Cancel</Button>
              </Grid>
              <Grid item xs={12} sm={2} md={2} lg={2}>
                <Button className="saveButton" onClick={handleUpdateAddress}>
                  Save
                </Button>
              </Grid>
            </Grid>
          )}

        </AccordionDetails>
      </Accordion >
    </div>
  )
}

export default EditContactInfo