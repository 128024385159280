// custom pages
import config from "config/config";
import HandleApiResponse from "utils/handleApiResponse";

const taskAPI = () => {
  const { handleResponse, handleError } = HandleApiResponse();

  async function getAllMasterTasks(values) {
    try {
      const obj = {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      };
      const query = JSON.stringify(values);
      let res = await fetch(`${config.host}/panel/tasks/findAllMaster?query=${query}`, obj);
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }

  async function getAllTasksAPI() {
    try {
      const obj = {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      };
      let res = await fetch(`${config.host}/panel/tasks/findAll`, obj);
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }

  async function getTaskCreateAPI(data) {
    try {
      const obj = {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      };
      let res = await fetch(`${config.host}/panel/tasks/create`, obj);
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }

  async function gettaskfindone(id) {
    try {
      const obj = {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      };
      let res = await fetch(`${config.host}/panel/tasks/find/${id.relID}`, obj);
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }

  async function gettaskupadte(data) {
    try {
      const obj = {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      };
      let res = await fetch(`${config.host}/panel/tasks/update`, obj);
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }

  return {
    getAllMasterTasks,
    getAllTasksAPI,
    getTaskCreateAPI,
    gettaskfindone,
    gettaskupadte,
  };
};

export default taskAPI;
