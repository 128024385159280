import React, { useRef, useState, useEffect } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import "./EditAddEmployee.css"
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import personalInfo from "assets/images/emergency-call_2991174 1.png";
import AttachFileIcon from '@mui/icons-material/AttachFile';

import CloudUploadIcon from '@mui/icons-material/CloudUpload';

import { InputAdornment, IconButton } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';


import getEmpRel from '../../../apis/panel/numberInfo/numberInfo'
import getRel from '../../../apis/panel/numberInfo/numberInfo'
import getcontact from 'apis/panel/numberInfo/numberInfo'
import getcreatefindone from "apis/panel/AddEmployee/addEmployee"
import { toast } from "react-toastify";
import Loader from "components/Loader";




const label = { inputProps: { 'aria-label': 'Checkbox demo' } };


const EditNumberInfo = () => {
  let params = useParams();
  const employeeId = params.id;
  const [file, setFile] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false);
  const [employeeName, setEmployeeName] = useState('Hinduja venkatesan');
  const [fatherName, setFatherName] = useState('Venkatesan');
  const [maritalStatus, setMaritalStatus] = useState('Unmarried');
  const [spouseName, setSpouseName] = useState('');
  const [businessEmail, setBusinessEmail] = useState('gveyj@gmail.com');
  const [businessPhoneNumber, setBusinessPhoneNumber] = useState('1234567890');
  const [personalEmail, setPersonalEmail] = useState('ghfhjkcn@gmail.com');
  const [personalPhoneNumber, setPersonalPhoneNumber] = useState('1234567890');
  const [dateOfBirth, setDateOfBirth] = useState('20-07-2024');
  const [gender, setGender] = useState('Male');
  const [bloodGroup, setBloodGroup] = useState('A+');
  const [expanded, setExpanded] = useState(false);

  const [EmployeeId] = useState(sessionStorage.getItem('EmployeeID'))
  const [RelationsData, setRelationsData] = useState([])
  const [relName, setrelName] = useState()

  const [phone1, setphone1] = useState('')
  const [relationshipid, setrelationshipid] = useState(null)
  const [relationshipname, setrelationshipname] = useState('')
  const [phone2, setphone2] = useState('')
  const [mail, setmail] = useState('')
  const [loader2, setLoader2] = useState(false)

  const [validationErrors, setValidationErrors] = useState({
    relName: '',
    phone1: '',
    relationshipid: '',
    Email: ""
  });
  const { getEmployeefindone } = getcreatefindone();



  const toggleEditMode = (event) => {
    event.stopPropagation();
    setIsEditMode(!isEditMode);
  };

  const handleFieldChange = (setter) => (event) => {
    setter(event.target.value);
  };
  const handleAccordionChange = (event, isExpanded) => {
    setExpanded(isExpanded);
    if (!isExpanded) {
      // Accordion is not expanded, reset edit mode
      setIsEditMode(false);
    }
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
  };




  const validateInputs = () => {
    const errors = {};
    if (!relName) {
      errors.relName = 'Name is required';
    }
    if (!phone1) {
      errors.phone1 = 'Phone Number 1 is required';
    }
    if (!relationshipid) {
      errors.relationshipid = 'Relationship is required';
    }
    // if (!mail) {
    //   errors.Email = 'Email is required';
    // }
    setValidationErrors(errors);
    return Object.keys(errors).length === 0; // Returns true if no errors
  };

  const { getcontactcreateAPI } = getcontact();
  const { getEmployeeRelations } = getEmpRel();
  const { getRelations } = getRel();


  useEffect(() => {
    findrelations()
  }, [])

  const selectedData = (e) => {

    let body = {
      relID: e,
      empID: employeeId
    }
    findEmpRelations(body)
  }

  const findEmpRelations = async (e) => {
    const data = await getEmployeeRelations(e)
    setrelName(data?.data?.firstName)
  }

  const findrelations = async () => {
    const data = await getRelations(EmployeeId)
    setRelationsData(data.data)
  }

  const handlphoneChange = (e) => {
    setphone1(e.target.value);
  };

  const handlnameChange = (e) => {
    setrelName(e.target.value);
  };

  const handlphone1Change = (e) => {
    setphone2(e.target.value);
  };

  const handlemailChange = (e) => {
    setmail(e.target.value);
  };

  const handlerelationship = async(e) => {
    let relationshipId = e.target.value;
    const data = await getEmployeeRelations({
      relID: relationshipId,
      empID: employeeId
    })
    setrelationshipid(relationshipId);
    setrelationshipname(data?.data?.relationships?.relationshipName);
    setrelName(data?.data?.firstName);
  };

  const handleCreateLeave = async (e) => {
    const values = {
      relID: employeeId
    };
    const data = await getEmployeefindone(values);
    let relationArray = data?.data?.relationship;
    for (let index = 0; index < relationArray?.length; index++) {
      const element = relationArray[index];
      if (element?.emergencyContact) {
        setrelationshipid(element?.relationshipId)
        setrelationshipname(element?.relationships?.relationshipName)
        setrelName(element?.firstName)
        setphone1(element?.phoneNumber1)
        setphone2(element?.phoneNumber2)
        setmail(element?.email)
      }
    }
  }

  useEffect(() => {
    handleCreateLeave()
  }, [])

  const handleCreatecontact = async (e) => {
    setLoader2(true)
    let validate = validateInputs();
    if (validate) {
      const values = {
        employeeId: employeeId,
        relationshipId: relationshipid,
        firstName: relName,
        phoneNumber1: phone1,
        phoneNumber2: phone2,
        email: mail
      };
      // sessionStorage.setItem('EmployeeID',data.data.employeeId)
      const data = await getcontactcreateAPI(values);
      if (data.status === 'Ok') {
        handleCreateLeave();
        setLoader2(false)
        return toast.success("Data Saved Successfully", { toast: "Data Saved Successfully" });
      } 
      else {
        setLoader2(false)
        return toast.error("Error saving Data", { toast: "Error saving Data" });
      }
    }
    else{
      setLoader2(false)
      return toast.error("Error saving Data", { toast: "Error saving Data" });
    }
  };



  return (
    <div>
      <Loader loading={loader2} />
      <Accordion sx={{ mt: 2, boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }} onChange={handleAccordionChange}>
        <AccordionSummary
          expandIcon={<ArrowDropDownIcon className="Accordianicon" />}
          aria-controls="panel1-content"
          id="panel1-header"
          style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px", height: "70px" }}

        >
          <Box sx={{ pl: 3 }}> <img sx={{ pl: 3 }} className='editpersonalInfo-logo' src={personalInfo} alt="info" /></Box>
          <Box className="editacc-title" sx={{ pl: 3 }}>Emergency Contact Information</Box>
          <Box sx={{ ml: 35 }}>
            {expanded && (
              <button className="editButton" onClick={toggleEditMode}>
                Edit
                <Box sx={{ flexGrow: 1 }} />
                {isEditMode}
              </button>
            )}
          </Box>
        </AccordionSummary>
        <AccordionDetails>

          <Grid container spacing={2} sx={{ pl: 4, pr: 5, mt: 2 }} >
            {/* <Box>Current Addres</Box> */}
            {isEditMode ? (
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <Box className="label" > Relationship <span style={{ color: "red" }}>*</span></Box>
                <FormControl fullWidth>
                  {/* <InputLabel id="marital-status-label">Country</InputLabel> */}
                  <Select
                    labelId="marital-status-label"
                    id="maritalStatus"
                 
                    inputProps={{ 'aria-label': 'Without label' }}
                    style={{ border: "1.5px solid #B2D4F8", color: "grey" }}
                    // value={Selectedrelation.relationshipName}
                    defaultValue={relationshipid || ""}
                   
                  displayEmpty
                    // value={relationshipname}
                    onChange={
                      handlerelationship
                    }
                  >
                    <MenuItem value="">
                      Select Relationship
                    </MenuItem>
                    {
                      RelationsData.map((i) => (

                        <MenuItem value={i.relationshipId}>{i.relationshipName}</MenuItem>
                      ))
                    }
                  </Select>
                </FormControl>
                {/* {validationErrors.relationshipid && <Box color="error.main">{validationErrors.relationshipid}</Box>} */}
              </Grid>
            ) : (
              <Grid item xs={12} sm={4} md={4} lg={4} sx={{ mt: 2 }}>
                <Box className="editlabel" >Relationship </Box>
                <Box className="editvalue">{relationshipname}</Box>
              </Grid>
            )}

            {isEditMode ? (
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <Box className="label" >Name <span style={{ color: "red" }}>*</span></Box>
                <TextField id="firstName" placeholder=" Enter Name" variant="outlined" fullWidth value={relName} onChange={handlnameChange} />
                {validationErrors.relName && <Box color="error.main">{validationErrors.relName}</Box>}
              </Grid>
            ) : (
              <Grid item xs={12} sm={4} md={4} lg={4} sx={{ mt: 2 }}>
                <Box className="editlabel" >Name</Box>
                <Box className="editvalue">{relName}</Box>
              </Grid>
            )}

            {isEditMode ? (
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <Box className="label" > Phone Number 1 <span style={{ color: "red" }}>*</span></Box>
                <TextField id="firstName" placeholder="Enter Phone Number 1" variant="outlined" fullWidth onChange={handlphoneChange} value={phone1} />
                {validationErrors.phone1 && <Box color="error.main">{validationErrors.phone1}</Box>}

              </Grid>
            ) : (
              <Grid item xs={12} sm={4} md={4} lg={4} sx={{ mt: 2 }}>
                <Box className="editlabel" > Phone Number 1 </Box>
                <Box className="editvalue">{phone1}</Box>
              </Grid>)}

            {isEditMode ? (
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <Box className="label" > Phone Number 2</Box>
                <TextField
                  placeholder="Enter Phone Number 2"

                  id="firstName" variant="outlined" fullWidth onChange={handlphone1Change} value={phone2} />
              </Grid>
            ) : (
              <Grid item xs={12} sm={4} md={4} lg={4} sx={{ mt: 2 }}>
                <Box className="editlabel" > Phone Number 2</Box>
                <Box className="editvalue">{phone2}</Box>
              </Grid>
            )}


            {isEditMode ? (
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <Box className="label">Email Address <span style={{ color: "red" }}></span></Box>
                <TextField id="firstName" placeholder="Enter Email" variant="outlined" fullWidth onChange={handlemailChange} value={mail} />
              </Grid>
            ) : (
              <Grid item xs={12} sm={4} md={4} lg={4} sx={{ mt: 2 }}>
                <Box className="editlabel">Email Address</Box>
                <Box className="editvalue">{mail}</Box>
              </Grid>
            )}

          </Grid>
          {isEditMode && (
            <Grid container spacing={1} sx={{ pl: 4, pr: 5, mt: 5, justifyContent: "center" }} >              
              <Grid item xs={12} sm={2} md={2} lg={2}>
                <Button className="cancelButton" onClick={toggleEditMode}>Cancel</Button>
              </Grid>
              <Grid item xs={12} sm={2} md={2} lg={2}>
                <Button className="saveButton" onClick={handleCreatecontact}>Save</Button>
              </Grid>
            </Grid>
          )}

        </AccordionDetails>
      </Accordion> 
    </div>

  )
}

export default EditNumberInfo