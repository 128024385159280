import React, { useState, useEffect } from 'react';
import "./AddEmployee.css";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import TextField from '@mui/material/TextField';
import Grid from "@mui/material/Grid";
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import personalInfo from "assets/images/Layer_1 (3).png";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { toast } from "react-toastify";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import projectAPI from 'apis/panel/project/project';
import projectRoleAPI from 'apis/panel/projectRole/projectRole';

const ProjectInfo = () => {
  const [EmployeeId] = useState(sessionStorage.getItem('EmployeeID'));
  const [projectInfo, setProjectInfo] = useState([{ id: 1 }]);
  const [projectArray, setProjectArray] = useState([{ id: 1, projectId: null }]);
  const [allProjects, setAllProjects] = useState([]);
  const [allProjectRoles, setAllProjectRoles] = useState([]);
  const [selectedProjects, setSelectedProjects] = useState([]);

  const { getAllProjects, getprojectfindone, createEmployeeProject } = projectAPI();
  const { getAllProjectRoles } = projectRoleAPI();

  useEffect(() => {
    handleGetAllProjectApi();
    handleGetAllProjectRoleApi();
  }, []);

  const handleGetAllProjectApi = async () => {
    const data = await getAllProjects();
    setAllProjects(data.data);
  };

  const handleGetAllProjectRoleApi = async () => {
    const data = await getAllProjectRoles();
    setAllProjectRoles(data.data);
  };

  const handleProjects = async (projectId, sectionId) => {
    const projects = await getprojectfindone(projectId);
    const updatedArr = projectArray.map((p) =>
      p.id === sectionId ? { ...p, projectId: projectId, projectCode: projects.data.projectCode } : p
    );
    setProjectArray(updatedArr);
    setSelectedProjects(updatedArr.map(p => p.projectId).filter(id => id !== null));
  };

  const handleAddSection = () => {
    setProjectInfo(prevState => [...prevState, { id: prevState.length + 1 }]);
    setProjectArray(prev => [...prev, { id: prev.length + 1, projectId: null }]);
  };

  const handleRemoveSection = (indexToRemove) => {
    const updatedProjectArray = projectArray.filter((_, index) => index !== indexToRemove);
    setProjectInfo(prevState => prevState.filter((_, index) => index !== indexToRemove));
    setProjectArray(updatedProjectArray);
    setSelectedProjects(updatedProjectArray.map(p => p.projectId).filter(id => id !== null));
  };

  const [accordionOpen, setAccordionOpen] = useState(false);

  const handleCancel = () => {
    setAccordionOpen(false);
  };

  const [errors, setErrors] = useState({});

  const handleClick = async () => {
    const validationErrors = {};
    if (!projectArray.every(item => item.projectId)) {
      validationErrors.projectId = true;
    }
    if (!projectArray.every(item => item.projectRoleId)) {
      validationErrors.projectRoleId = true;
    }
    if (!projectArray.every(item => item.percentAllocation)) {
      validationErrors.percentAllocation = true;
    }
    if (!projectArray.every(item => item.startDate)) {
      validationErrors.startDate = true;
    }
    if (!projectArray.every(item => item.endDate)) {
      validationErrors.endDate = true;
    }
    if (!projectArray.every(item => item.status)) {
      validationErrors.status = true;
    }
    setErrors(validationErrors);

    if (Object.keys(validationErrors).length === 0) {
      let body = {
        employeeId: EmployeeId,
        projectData: projectArray
      };
      const data = await createEmployeeProject(body);
      if (data.status === 'Ok') {
        return toast.success("Data Saved Successfully", { toast: "Data Saved Successfully" });
      } else {
        return toast.error("Error saving Data", { toast: "Error saving Data" });
      }
    }
  };

  return (
    <Accordion expanded={accordionOpen} onChange={() => setAccordionOpen(!accordionOpen)} sx={{ mt: 2, boxShadow: "box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px" }}>
      <AccordionSummary
        expandIcon={<ArrowDropDownIcon className="Accordianicon" />}
        aria-controls="panel1-content"
        id="panel1-header"
        style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px", height: "70px" }}
      >
        <Box sx={{ pl: 3 }}> <img sx={{ pl: 3 }} className='personalInfo-logo' src={personalInfo} alt="info" /></Box>
        <Box className="acc-title" sx={{ pl: 3 }}>Project Information</Box>
      </AccordionSummary>
      <AccordionDetails>
        {projectInfo.map((section, index) => (
          <React.Fragment key={section.id}>
            <Grid container spacing={2} sx={{ pl: 4, pr: 5, mt: 2 }} style={{ display: "flex", justifyContent: "space-between" }}>
              <Box className="subHeadingforinfos" sx={{ pl: 3 }}>Project Info {index + 1}</Box>
              {index > 0 ? (
                <Button className="removeSectionButton" onClick={() => handleRemoveSection(index)}>Remove Project</Button>
              ) : (
                <Button className="addSectionButton" onClick={handleAddSection}>Add Project</Button>
              )}
            </Grid>
            <Grid container spacing={2} sx={{ pl: 4, pr: 5, mt: 2 }}>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <Box className="label">Project Name <span style={{ color: "red" }}>*</span></Box>
                <FormControl fullWidth>
                  <Select
                    id="projectId"
                    placeholder="Select Project"
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                    style={{ border: "1.5px solid #B2D4F8", color: "grey" }}
                    onChange={(e) => {
                      handleProjects(e.target.value, section.id);
                    }}
                    value={projectArray[index].projectId || ""}
                  >
                    <MenuItem value=""> Select Project </MenuItem>
                    {allProjects
                      .filter(project => project.status === "Active" || project.projectId === projectArray[index].projectId)
                      .filter(project => !selectedProjects.includes(project.projectId) || project.projectId === projectArray[index].projectId)
                      .map(project => (
                        <MenuItem key={project.projectId} value={project.projectId}>{project.projectName}</MenuItem>
                      ))}
                  </Select>
                </FormControl>
                {errors.projectId && <div className='error-message'>This field is mandatory.</div>}
              </Grid>

              <Grid item xs={12} sm={6} md={6} lg={6}>
                <Box className="label">Project Code</Box>
                <TextField 
                  id="projectCode"
                  placeholder="Project Code"
                  disabled={true}
                  variant="outlined"
                  fullWidth 
                  value={projectArray[index].projectCode || ""}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <Box className="label">Resource Allocation Percentage <span style={{ color: "red" }}>*</span></Box>
                <TextField 
                  id="percentAllocation"
                  placeholder="Enter Percent Allocation"
                  variant="outlined" 
                  fullWidth 
                  type="number"
                  InputProps={{ inputProps: { min: 0, max: 100 }}}
                  onChange={(e) => {
                    const updatedArr = projectArray.map((p) =>
                      p.id === section.id ? { ...p, percentAllocation: e.target.value } : p
                    );
                    setProjectArray(updatedArr);
                  }}
                />
                {errors.percentAllocation && <div className='error-message'>This field is mandatory.</div>}
              </Grid>              
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <Box className="label">Project Role <span style={{ color: "red" }}>*</span></Box>
                <FormControl fullWidth>
                  <Select
                    id="projectRoleId"
                    placeholder="Select Project Role"
                    defaultValue=""
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                    style={{ border: "1.5px solid #B2D4F8", color: "grey" }}
                    onChange={(e) => {
                      const updatedArr = projectArray.map((p) =>
                        p.id === section.id ? { ...p, projectRoleId: e.target.value } : p
                      );
                      setProjectArray(updatedArr);
                    }}
                  >
                    <MenuItem value=""> Select Project Role </MenuItem>
                    {allProjectRoles
                      .filter(role => role.status === "Active")
                      .map(role => (
                        <MenuItem key={role.projectRoleId} value={role.projectRoleId}>
                          {role.projectRoleName}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
                {errors.projectRoleId && <div className='error-message'>This field is mandatory.</div>}
              </Grid>      
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <Box className="label"> Employee Project Start Date <span style={{ color: "red" }}>*</span></Box>
                <LocalizationProvider fullWidth dateAdapter={AdapterDateFns}>
                  <DatePicker
                    sx={{width:"100%"}}
                    id="startDate"
                    name="startDate"
                    onChange={(e) => {
                      const updatedArr = projectArray.map((p) =>
                        p.id === section.id ? { ...p, startDate: e } : p
                      );
                      setProjectArray(updatedArr);
                    }}
                    format="dd/MM/yyyy"
                    renderInput={(params) => <TextField {...params} helperText={null} />}
                  />
                </LocalizationProvider>
                {errors.startDate && <div className='error-message'>This field is mandatory.</div>}
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <Box className="label">Employee Project End Date <span style={{ color: "red" }}>*</span></Box>
                <LocalizationProvider fullWidth dateAdapter={AdapterDateFns}>
                  <DatePicker
                    sx={{width:"100%"}}
                    id="endDate"
                    name="endDate"
                    onChange={(e) => {
                      const updatedArr = projectArray.map((p) =>
                        p.id === section.id ? { ...p, endDate: e } : p
                      );
                      setProjectArray(updatedArr);
                    }}
                    format="dd/MM/yyyy"
                    renderInput={(params) => <TextField {...params} helperText={null} />}
                  />
                </LocalizationProvider>
                {errors.endDate && <div className='error-message'>This field is mandatory.</div>}
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <Box className="label">Employee Project Status <span style={{ color: "red" }}>*</span></Box>
                <FormControl fullWidth>
                  <Select
                    id="projectStatus"
                    placeholder="Select Status"
                    defaultValue=""
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                    style={{ border: "1.5px solid #B2D4F8", color: "grey" }}
                    onChange={(e) => {
                      const updatedArr = projectArray.map((p) =>
                        p.id === section.id ? { ...p, status: e.target.value } : p
                      );
                      setProjectArray(updatedArr);
                    }}  
                  >
                    <MenuItem value="">Select Status </MenuItem>                                    
                    <MenuItem value="Active">Active</MenuItem>
                    <MenuItem value="Inactive">Inactive</MenuItem>
                  </Select>
                </FormControl>
                {errors.status && <div className='error-message'>This field is mandatory.</div>}
              </Grid>
            </Grid>
          </React.Fragment>
        ))}
        <Grid container spacing={1} sx={{ pl: 4, pr: 5, mt: 5, justifyContent: "center" }}>
          <Grid item xs={12} sm={3} md={3} lg={3}>
            <Button className="resetChanges">Reset Changes</Button>
          </Grid>
          <Grid item xs={12} sm={2} md={2} lg={2}>
            <Button className="cancelButton" onClick={handleCancel}>Cancel</Button>
          </Grid>
          <Grid item xs={12} sm={2} md={2} lg={2}>
            <Button className="saveButton" onClick={handleClick}>Save</Button>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  )
}

export default ProjectInfo;
