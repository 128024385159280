// npm packages
import React, { useEffect, useState, Fragment, useContext } from "react";
import * as moment from "moment";

// custom pages
import Context from "context/context";
import reportAPI from "apis/panel/reports/reports";
import "./Reports.css";

// material ui
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";


import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import Autocomplete from "@mui/material/Autocomplete";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import ReplayIcon from "@mui/icons-material/Replay";
import { toastCss } from "config/config";
import { toast } from "react-toastify";
import { styled } from '@mui/system';

const CustomCheckbox = styled(Checkbox)({
  color: ' #0056B2',
  '&.Mui-checked': {
    color: ' #0056B2',
  },
});

export default function EmployeeNonComplainceSummary({ setDisableDropdown }) {
  const { dispatch } = useContext(Context);

  const {
    getNonReportedHoursSummaryReportAPI, getAllDepartmentsAPI
  } = reportAPI();

  const [checkedList, setCheckedList] = useState(new Set());
  const [allDept, setAllDept] = useState([])
  const [selectAll, setSelectAll] = useState(false)
  const [startDate, setStartDate]= useState(null);
  const [endDate, setEndDate]= useState(null)
  const [submitClicked,setSubmitClicked]= useState(false)
  const [submitted, setSubmitted] = useState(false);
 
  const [reset,setReset]= useState(false)
  //   const [projects, setProjects] = useState([]);
  //   const [employees, setEmployees] = useState([]);

  // const [exportObj, setExportObj] = useState({

  //   startDate: '',
  //   endDate: '',
  // });

  //   const handleGetAllProjects = async () => {
  //     const res = await getAllManagerProjects();
  //     setProjects(res?.data?.projects || []);
  //     setProjectTypes(res?.data?.projectTypes || []);
  //   };

  //   const handleGetAllEmployees = async () => {
  //     const res = await getAllManagerEmployees();
  //     setEmployees(res?.data || []);
  //   };

  //   useEffect(() => {
  //     handleGetAllProjects();
  //     handleGetAllEmployees();
  //   }, []);


  const handleReset=()=>{
    setStartDate(null)
    setEndDate(null)
    setSelectAll(true)
    setReset(false)
    handleSelectAll()
  }

  const handleExport = async () => {
    setSubmitClicked(true)
    if(!startDate || !endDate ){
      setSubmitted(false);
      return;
    }
    let body = {};
  
    const selectedDeptArray = Array.from(checkedList);
    if (selectedDeptArray?.length == 0) {
      setSubmitted(false)
      
      return ;
    }
    // console.log(exportObj?.startDate,exportObj.endDate,selectedDeptArray);
    body = {
      startDate: moment(startDate).format("YYYY-MM-DD"),
      endDate: moment(endDate).format("YYYY-MM-DD"),
      deptIds: selectedDeptArray
    };

    
    dispatch({ type: "SET_LOADING", payload: true });
    setDisableDropdown(true);
    await getNonReportedHoursSummaryReportAPI(body);
    setSubmitClicked(false)
    setSubmitted(false)
    setDisableDropdown(false);
    dispatch({ type: "SET_LOADING", payload: false });
  };

  const handleChange = (deptId) => {
    setCheckedList((prevList) => {
      const newCheckedList = new Set(prevList);
      if (newCheckedList.has(deptId)) {
        newCheckedList.delete(deptId);
      } else {
        newCheckedList.add(deptId);
      }
      if (newCheckedList.size === allDept.length) {
        setSelectAll(true);
        setReset(false)
      } else {
        setSelectAll(false);
        setReset(true)
      }
      return newCheckedList;

    });
  };

  const handleSelectAll = () => {
    if (checkedList.size === allDept.length) {
      setCheckedList(new Set());
      setSelectAll(false);
      setReset(true)

    } else {
      const newCheckedList = new Set(allDept.map((dept) => dept?.department_id));
      setCheckedList(newCheckedList);
      setSelectAll(true);
      setReset(false)
    }

  }

  const getAllDepartments = async () => {
    dispatch({ type: "SET_LOADING", payload: true });
    const res = await getAllDepartmentsAPI()

    const departments = res?.data?.departments
    setCheckedList(new Set(departments.map(department => department.department_id)))

    setAllDept(departments)

    setSelectAll(true)
    dispatch({ type: "SET_LOADING", payload: false });
  }

  const handleStartDate=async(value)=>{
    setStartDate(value)
    setReset(true)
  }

  const handleEndDate=async(value)=>{
    setEndDate(value)
    setReset(true)
  }
 
  useEffect(() => {
    getAllDepartments()
  }, [])


  return (
    <Fragment>
      <Grid container spacing={4} sx={{ mt: 2 }}>
        <Grid item xs={6} sm={6} md={3} lg={4}>
          <Box className="labelproj">
            Start Date <span style={{ color: "red" }}>*</span>
          </Box>
          <LocalizationProvider fullWidth dateAdapter={AdapterDateFns}>
            <DatePicker
              id="startdate"
              name="startdate"
              sx={{ width: "100%" }}
              format="dd/MM/yyyy"
              renderInput={(params) => <TextField {...params} fullWidth />}
              maxDate={new Date()}
              value={startDate}
              onChange={(newValue) => handleStartDate(newValue)}
              // onAccept={(date) => {
              //   setExportObj({ ...exportObj, startDate: date });
              // }}
             
            />
          </LocalizationProvider>
          {submitClicked && !startDate && (
    <Box sx={{ color: 'red', fontSize: '13px' }}>
        <span>*</span> This Field is Required
    </Box>
)}
        </Grid>

        <Grid item xs={6} sm={6} md={3} lg={4}>
          <Box className="labelproj">
            End Date <span style={{ color: "red" }}>*</span>
          </Box>
          <LocalizationProvider fullWidth dateAdapter={AdapterDateFns}>
            <DatePicker
              id="enddate"
              name="enddate"
              sx={{ width: "100%" }}
              
              format="dd/MM/yyyy"
              renderInput={(params) => <TextField {...params} fullWidth />}
              minDate={startDate}
              maxDate={new Date()}
              value={endDate}
              onChange={(newValue) => handleEndDate(newValue)}
              // onAccept={(date) => {
              //   setExportObj({ ...exportObj, endDate: date });
              // }}
            />
          </LocalizationProvider>
          {submitClicked && !endDate && (
    <Box sx={{ color: 'red', fontSize: '13px' }}>
        <span>*</span> This Field is Required
    </Box>
)}
        </Grid>

      </Grid>
      <Grid container spacing={4} sx={{ mt: 2 }}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Box display="flex" alignItems="center">
            <FormControlLabel
              className="labelproj"
              control={
                <CustomCheckbox
                  checked={selectAll}
                  onChange={handleSelectAll}
                />
              }
              label={
                <>
                  <span style={{ fontFamily: "Inter",
                  fontSize: '18px',fontWeight: '600'}}>Department Name</span><span style={{ color: "red" }}>*</span>
            </>
                }
            />
          </Box>
          {submitClicked && checkedList.size==0 && (
    <Box sx={{marginTop:'10px', color: 'red', fontSize: '13px' }}>
        <span>*</span> This Field is Required
    </Box>
)}

        </Grid>
     
        {allDept && allDept.length > 0 && allDept.map((dept, index) => (
          <Grid key={dept?.department_id} sx={{ height: "60px" }} item xs={12} sm={5} md={5} lg={5}>

            <Box sx={{ display: 'flex', ml: 3 ,}}>

              <FormControlLabel
               
                label={dept?.department_name}
                control={
                  <CustomCheckbox
                    checked={checkedList.has(dept?.department_id)}
                    onChange={() => handleChange(dept?.department_id)}
                  />
                }
              />

            </Box>
          </Grid>
        ))}


      </Grid>
      <Grid>
    
      </Grid>
    
      
      <Grid container>
        <Grid item xs={12} style={{ textAlign: "right", padding: "20px 0px" }}>
          {
            reset && (
              <Button
                className="reset-btn"
                component="label"
                variant="contained"
                tabIndex={-1}
                startIcon={
                  <ReplayIcon style={{ fontSize: 30, verticalAlign: "middle" }} />
                }
                onClick={handleReset}
              >
                Reset
              </Button>
            )}
          <Button
            className="download-btn"
            startIcon={
              <DownloadForOfflineIcon
                style={{ fontSize: 30, verticalAlign: "middle" }}
              />
            }
            onClick={handleExport}
          >
            Export
          </Button>
        </Grid>


      </Grid>
    </Fragment>
  );
}
