import { createContext } from "react";

const Context = createContext({
  isLoggedIn: false,
  loading: false,
  role: {},
  employee: {},
  permission: [],
  employeeData: [],
  sidebar: true,
});

export default Context;
