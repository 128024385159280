// custom pages
import config from "config/config";
import HandleApiResponse from "utils/handleApiResponse";

const addEmployee = () => {
  const { handleResponse, handleError } = HandleApiResponse();

  async function getAllTitleAPI() {
    try {
      const obj = {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      };
      const res = await fetch(`${config.host}/panel/title/findAll`, obj);
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }

  async function getAllRolesAPI() {
    try {
      const obj = {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      };
      const res = await fetch(`${config.host}/panel/roles/findAll`, obj);
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }

  async function getAllGenderAPI() {
    try {
      const obj = {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      };
      const res = await fetch(`${config.host}/panel/gender/findAll`, obj);
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }

  async function getMaritalStatusAPI() {
    try {
      const obj = {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      };
      const res = await fetch(
        `${config.host}/panel/marital_status/findAll`,
        obj
      );
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }

  async function getAllBloodsAPI() {
    try {
      const obj = {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      };
      const res = await fetch(`${config.host}/panel/bloodgroups/findAll`, obj);
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }

  async function getEmployeeCreateAPI(data) {
    try {
      const obj = {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      };

      let res = await fetch(`${config.host}/panel/employees/create`, obj);
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }

  async function getEmployeeCreateupdate(data) {
    try {
      const obj = {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      };

      let res = await fetch(`${config.host}/panel/employees/update`, obj);
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }

  async function getEmployeefindone(id) {
    try {
      const obj = {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      };
      let res = await fetch(
        `${config.host}/panel/employees/find/${id.relID}`,
        obj
      );
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }

  return {
    getAllGenderAPI,
    getAllTitleAPI,
    getAllBloodsAPI,
    getMaritalStatusAPI,
    getEmployeeCreateAPI,
    getEmployeefindone,
    getEmployeeCreateupdate,
    getAllRolesAPI,
  };
};

export default addEmployee;
