// npm packages
import React, { useEffect, useState, Fragment, useContext } from "react";
import * as moment from "moment";

// custom pages
import Context from "context/context";
import reportAPI from "apis/panel/reports/reports";
import "../Reports.css";

// material ui
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import Autocomplete from "@mui/material/Autocomplete";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import ReplayIcon from "@mui/icons-material/Replay";

export default function TimesheetSummary({ setDisableDropdown }) {
  const { dispatch } = useContext(Context);

  const {
    getAllManagerProjects,
    getAllManagerEmployees,
    getTimesheetSummaryReportAPI,
    
  } = reportAPI();

  const [projectTypes, setProjectTypes] = useState([]);
  const [projects, setProjects] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [uniqueEmployees, setUniqueEmployees] = useState([]);

  const [exportObj, setExportObj] = useState({
    projectTypeIds: [],
    project: {},
    employee: {},
    startDate: new Date(),
    endDate: new Date(),
  });

  const handleGetAllProjects = async () => {
    const res = await getAllManagerProjects();
    setProjects(res?.data?.projects || []);
    setProjectTypes(res?.data?.projectTypes || []);
  };

  const handleGetAllEmployees = async () => {
    const res = await getAllManagerEmployees();
    setEmployees(res?.data || []);

    let uniqueEmployees = res?.data?.filter(
      (employee, index, self) =>
        index ===
        self.findIndex(
          (e) =>
            e.employeeId === employee.employeeId &&
            e.employeeCode === employee.employeeCode &&
            e.firstName === employee.firstName &&
            e.lastName === employee.lastName
        )
    );

    setUniqueEmployees(uniqueEmployees || []);
  };

  useEffect(() => {
    handleGetAllProjects();
    handleGetAllEmployees();
  }, []);

  const handleView= async()=>{
    let body = {};
    if (!exportObj?.startDate) {
      return;
    }
    if (!exportObj?.endDate) {
      return;
    }
    body = {
      startDate: moment(exportObj?.startDate).format("YYYY-MM-DD"),
      endDate: moment(exportObj?.endDate).format("YYYY-MM-DD"),
    };
    if (exportObj?.projectTypeIds && exportObj?.projectTypeIds.length > 0) {
      body = {
        ...body,
        projectTypeIds: exportObj?.projectTypeIds?.map((p) => p.projectTypeId),
      };
    }
    if (exportObj?.projects && exportObj?.projects.length > 0) {
      body = {
        ...body,
        projectIds: exportObj?.projects?.map((p) => p.projectId),
      };
    }
    if (exportObj?.employees && exportObj?.employees?.length > 0) {
      body = {
        ...body,
        employeeIds: exportObj?.employees.map((e) => e.employeeId),
      };
    }
   
    dispatch({ type: "SET_LOADING", payload: true });
    setDisableDropdown(true);
  if(body){
    openNewTabWithState(body)
  }
  //  let res= await getTimesheetSummaryReportViewAPI(body);

    setDisableDropdown(false);
    // console.log(res?.type,'outside');
  
    // if(res?.type=='SUCCESS'){
    //   console.log(res?.data,'resssssss');
      
    // }
    dispatch({ type: "SET_LOADING", payload: false });
  }

  const openNewTabWithState = (body) => {
    const queryString = encodeStateToQueryString(body);
    
    window.open(`/panel/timesheetSummaryView?${queryString}`, '_blank', 'noopener,noreferrer');
  };

  const encodeStateToQueryString = (state) => {
    const params = new URLSearchParams();
  
    for (const [key, value] of Object.entries(state)) {
      if (Array.isArray(value)) {
        params.append(key, `array:${JSON.stringify(value)}`); // Mark arrays with a prefix
      } else {
        params.append(key, value);
      }
    }
  
    return params.toString();
  };
  

  const handleExport = async () => {
    let body = {};
    if (!exportObj?.startDate) {
      return;
    }
    if (!exportObj?.endDate) {
      return;
    }
    body = {
      startDate: moment(exportObj?.startDate).format("YYYY-MM-DD"),
      endDate: moment(exportObj?.endDate).format("YYYY-MM-DD"),
    };
    if (exportObj?.projectTypeIds && exportObj?.projectTypeIds.length > 0) {
      body = {
        ...body,
        projectTypeIds: exportObj?.projectTypeIds?.map((p) => p.projectTypeId),
      };
    }
    if (exportObj?.projects && exportObj?.projects.length > 0) {
      body = {
        ...body,
        projectIds: exportObj?.projects?.map((p) => p.projectId),
      };
    }
    if (exportObj?.employees && exportObj?.employees?.length > 0) {
      body = {
        ...body,
        employeeIds: exportObj?.employees.map((e) => e.employeeId),
      };
    }
    dispatch({ type: "SET_LOADING", payload: true });
    setDisableDropdown(true);
   
    await getTimesheetSummaryReportAPI(body);
    setDisableDropdown(false);
    dispatch({ type: "SET_LOADING", payload: false });
  };

  function findMatchingObjects(arr1, arr2, key) {

    const arr2Keys = arr2.map((item) => item[key]);
    let result = arr1.filter((item) => arr2Keys.includes(item[key])); 
    if(key === 'projectId'){
     return findUniqueObjects(result)
    }
    return result
  }

  function findUniqueObjects(data) {
   
    const unique = data.filter(
      (employee, index, self) =>
        index ===
        self.findIndex(
          (e) =>
            e.employeeId === employee.employeeId &&
            e.employeeCode === employee.employeeCode &&
            e.firstName === employee.firstName &&
            e.lastName === employee.lastName
        )
    );
  
    return unique;
  }

  return (
    <Fragment>
      <Grid container spacing={4} sx={{ mt: 2 }}>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <Box className="labelproj">
            Start Date <span style={{ color: "red" }}>*</span>
          </Box>
          <LocalizationProvider fullWidth dateAdapter={AdapterDateFns}>
            <DatePicker
              id="startdate"
              name="startdate"
              sx={{ width: "100%" }}
              format="dd/MM/yyyy"
              renderInput={(params) => <TextField {...params} fullWidth />}
              maxDate={exportObj?.endDate}
              value={exportObj?.startDate}
              onAccept={(date) => {
                setExportObj({ ...exportObj, startDate: date });
              }}
            />
          </LocalizationProvider>
        </Grid>

        <Grid item xs={12} sm={6} md={6} lg={6}>
          <Box className="labelproj">
            End Date <span style={{ color: "red" }}>*</span>
          </Box>
          <LocalizationProvider fullWidth dateAdapter={AdapterDateFns}>
            <DatePicker
              id="enddate"
              name="enddate"
              sx={{ width: "100%" }}
              maxDate={new Date()}
              format="dd/MM/yyyy"
              renderInput={(params) => <TextField {...params} fullWidth />}
              minDate={exportObj?.startDate}
              value={exportObj?.endDate}
              onAccept={(date) => {
                setExportObj({ ...exportObj, endDate: date });
              }}
            />
          </LocalizationProvider>
        </Grid>
        {/* <Grid item xs={12} sm={6} md={6} lg={6}>
          <Box className="label">Project Type</Box>
          <FormControl fullWidth>
            <Select
              id="project-type"
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
              value={exportObj?.projectTypeId}
              onChange={(e) => {
                setExportObj({
                  ...exportObj,
                  projectTypeId: e.target.value
                });
              }}
            >
              {projectTypes?.map((project, i) => (
                <MenuItem key={i} value={project.projectTypeId}>
                  {project.projectTypeName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid> */}
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <Box className="label">Project Type</Box>
          <Autocomplete
            multiple
            options={projectTypes || []}
            getOptionLabel={(option) => option?.projectTypeName || ""}
            value={exportObj?.projectTypeIds || []}
            onChange={(event, newValues) => {
              setExportObj({ ...exportObj, projectTypeIds: newValues });
            }}
            renderInput={(params) => <TextField {...params} />}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={6} lg={6}>
          <Box className="label">Project Name</Box>
          <Autocomplete
            multiple
            options={
              (exportObj?.projectTypeIds?.length > 0
                ? projects &&
                  findMatchingObjects(
                    projects,
                    exportObj?.projectTypeIds,
                    "projectTypeId"
                  )
                : projects) || []
            }
            getOptionLabel={(option) => option?.projectName || ""}
            value={exportObj?.projects || []}
            onChange={(event, newValues) => {
              setExportObj({ ...exportObj, projects: newValues });
            }}
            renderInput={(params) => <TextField {...params} />}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={6} lg={6}>
          <Box className="label">Employee Name</Box>
          <Autocomplete
            multiple
            options={
              (exportObj?.projects?.length > 0
                ? findMatchingObjects(
                    employees,
                    exportObj?.projects,
                    "projectId"
                  )
                : uniqueEmployees) || []
            }
            getOptionLabel={(option) =>
              option && Object.keys(option).length > 0
                ? option?.employeeCode +
                  " - " +
                  option?.firstName +
                  " " +
                  option?.lastName
                : ""
            }
            value={exportObj?.employees || []}
            onChange={(event, newValues) => {
              setExportObj({ ...exportObj, employees: newValues });
            }}
            renderInput={(params) => <TextField {...params} />}
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} style={{ textAlign: "right", padding: "20px 0px" }}>
          {(exportObj?.projectTypeId ||
            Object.keys(exportObj?.project).length > 0 ||
            Object.keys(exportObj?.employee).length > 0 ||
            !moment(exportObj.startDate).isSame(new Date(), "day") ||
            !moment(exportObj.endDate).isSame(new Date(), "day")) && (
            <Button
              className="reset-btn"
              component="label"
              variant="contained"
              tabIndex={-1}
              startIcon={
                <ReplayIcon style={{ fontSize: 30, verticalAlign: "middle" }} />
              }
              onClick={() => {
                setExportObj({
                  projectTypeId: "",
                  project: {},
                  employee: {},
                  startDate: new Date(),
                  endDate: new Date(),
                });
              }}
            >
              Reset
            </Button>
          )}
           <Button
            className="download-btn"
            sx={{marginRight:'10px'}}
            startIcon={
              <RemoveRedEyeOutlinedIcon
                style={{ fontSize: 30, verticalAlign: "middle" ,pl:'2px'}}
              />
            }
            onClick={handleView}
          >
            View
          </Button>
          <Button
            className="download-btn"
            startIcon={
              <DownloadForOfflineIcon
                style={{ fontSize: 30, verticalAlign: "middle" }}
              />
            }
            onClick={handleExport}
          >
            Export
          </Button>
        </Grid>
      </Grid>
    </Fragment>
  );
}
