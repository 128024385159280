const ReusableFunction = () => {
  const handleFileInputReselect = (event) => {
    const { target = {} } = event || {};
    target.value = "";
  };

  return {
    handleFileInputReselect,
  };
};

export default ReusableFunction;
