import { Fragment, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import context from "context/context";
import AuthAPI from "apis/auth/auth";

// material ui
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

export default function ChangePassword() {
    const { state, dispatch } = useContext(context);
    const { changePasswordAPI } = AuthAPI();  
    const [employee] = useState(state.employee);
    const [oldPassword, setOldPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [conPassword, setConPassword] = useState("");
    const [disableBtn, setDisableBtn] = useState(false);
    const navigate = useNavigate();

    const handleSubmit = async () => {    
        try {
            setDisableBtn(true);
            dispatch({ type: "SET_LOADING", payload: true });                                    
            if (!newPassword) {            
                toast.error("Please fill all the required fields.", {
                    taskId: "create-employee-task-error",
                });
            }
            if (newPassword != conPassword) {
                toast.error("New password and confirm password mismatch", {
                    taskId: "create-employee-task-error",
                });
            }
            let values = {
                email: employee.businessEmail,
                password: newPassword
            };
            let res = await changePasswordAPI(values);
            if(res.status=="SUCCESS"){
                toast.success("Your password has been successfully changed", {
                    taskId: "create-employee-task-error",
                });
                setDisableBtn(false);
                dispatch({ type: "SET_LOADING", payload: false });            
            }        
            setDisableBtn(false);
            dispatch({ type: "SET_LOADING", payload: false });
            return;        
        } 
        catch (err) {}
    };

    const handleToggleNewPasswordVisibility = () => {
        setShowNewPassword(!showNewPassword);
    };

    const handleToggleConfirmPasswordVisibility = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    return (
        <Fragment>
            <Card variant="outlined" sx={{ padding: "15px" }}>
                <Typography variant="h6" component="h2">
                    Change Password
                </Typography>
                <Grid container spacing={2} mt={2} >
                    <Grid item xs={6}>
                        {/* <Grid item xs={12} sm={7} lg={12}>
                            <TextField
                                name="oldpassword"
                                id="oldpassword"
                                label="Old Password"
                                type="password"
                                margin="normal"
                                required
                                fullWidth                               
                                autoComplete="current-password"
                                InputLabelProps={{
                                    shrink: true,
                                    style: { width: '100%' }
                                }}
                                value={oldPassword}
                                onChange={(e) => {
                                    setOldPassword(e.target.value);
                                }}
                            />
                        </Grid> */}
                        <Grid item xs={12} sm={7} lg={12}>
                        <TextField
                            name="newpassword"
                            id="newpassword"
                            label="New Password"
                            type={showNewPassword ? 'text' : 'password'}
                            margin="normal"
                            required
                            fullWidth
                            autoComplete="current-password"
                            InputLabelProps={{
                                shrink: true,
                                style: { width: '100%' }
                            }}
                            value={newPassword}
                            onChange={(e) => {
                                setNewPassword(e.target.value);
                            }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={handleToggleNewPasswordVisibility}
                                            edge="end"
                                        >
                                            {showNewPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                        />
                        </Grid>
                        <Grid item xs={12} sm={7} lg={12}>
                        <TextField
                            name="conpassword"
                            id="conpassword"
                            label="Confirm Password"
                            type={showConfirmPassword ? 'text' : 'password'}
                            margin="normal"
                            required
                            fullWidth
                            autoComplete="new-password"
                            InputLabelProps={{
                                shrink: true,
                                style: { width: '100%' }
                            }}
                            value={conPassword}
                            onChange={(e) => {
                                setConPassword(e.target.value);
                            }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={handleToggleConfirmPasswordVisibility}
                                            edge="end"
                                        >
                                        {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                        />
                        </Grid>
                        <Grid item xs={4}>
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                disabled={disableBtn}
                                onClick={() => {                            
                                    handleSubmit();
                                }}
                            >
                                Change
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Card>
        </Fragment>
    );
}
