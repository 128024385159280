import React, { useState, useEffect, Fragment, useContext, useMemo } from "react";
import {
    Button, Card, Switch, Paper, IconButton, InputBase,
    Divider, MenuItem, Select, InputLabel, FormControl, TableBody, TableHead, TableRow, TablePagination
    , Table, TableCell,Modal,Typography,Box
} from '../../../../node_modules/@mui/material/index'
import '../../../../src/assets/styles/custom.css'
import { useTable } from 'react-table';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import SearchIcon from '@mui/icons-material/Search';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import ManageTask from '../../../assets/images/Frame 140.svg'
import timeSheet from '../../../assets/images/Frame 12.svg'


const Timesheetsummary = () => {

    const initialHeadingData = useMemo(
        () => [
            { A: 'Project', B: 'Task', Mon: 'Mon', Tue: 'Tue', Wed: 'Wed', Thu: 'Thu', Fri: 'Fri', Sat: 'Sat', Sun: 'Sun' },
        ],
        []
    );

    const initialData = useMemo(
        () => [
            { Project: 'Project 1', Task: 'Task 1', Mon: '', Tue: '', Wed: '', Thu: '', Fri: '', Sat: '', Sun: '' },
            { Project: 'Project 2', Task: 'Task 2', Mon: '', Tue: '', Wed: '', Thu: '', Fri: '', Sat: '', Sun: '' },
            { Project: 'Project 3', Task: 'Task 3', Mon: '', Tue: '', Wed: '', Thu: '', Fri: '', Sat: '', Sun: '' },
            { Project: 'Project 4', Task: 'Task 4', Mon: '', Tue: '', Wed: '', Thu: '', Fri: '', Sat: '', Sun: '' },
            { Project: 'SickLeave', Task: '', Mon: '', Tue: '', Wed: '', Thu: '', Fri: '', Sat: '', Sun: '' },
            { Project: 'PTO', Task: '', Mon: '', Tue: '', Wed: '', Thu: '', Fri: '', Sat: '', Sun: '' },
            { Project: 'Total', Task: '', Mon: '', Tue: '', Wed: '', Thu: '', Fri: '', Sat: '', Sun: '' },
        ],
        []
    );

    const [headingData, setHeadingData] = useState(initialHeadingData);
    const [data, setData] = useState(initialData);
    const [val, setval] = useState('')
    // const [View, setView] = useState(false)
    // const [SwitchVal, setSwitchVal] = useState("My View")
    const [view, setView] = useState(false);
    const [SwitchVal, setSwitchVal] = useState('My View');
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10); 
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);



    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };


    const columns = useMemo(
        () => [
            { Header: '', accessor: 'Project' },
            { Header: '', accessor: 'Task' },
            { Header: 'Mon', accessor: 'Mon' },
            { Header: 'Tue', accessor: 'Tue' },
            { Header: 'Wed', accessor: 'Wed' },
            { Header: 'Thu', accessor: 'Thu' },
            { Header: 'Fri', accessor: 'Fri' },
            { Header: 'Sat', accessor: 'Sat' },
            { Header: 'Sun', accessor: 'Sun' },
        ],
        []
    );


    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({ columns, data });


    const calculateSpecialCells = () => {
        const updatedData = [...data];

        const calculateDayTotal = (day) => {
            const dayValues = updatedData.map((row) => Number(row[day]) || 0);
            return dayValues.reduce((acc, value) => acc + value, 0);
        };


        const specialRows = ["Total"];
        specialRows.forEach((rowName) => {
            updatedData.find((row) => row.Project === rowName).Mon = calculateDayTotal("Mon");
            updatedData.find((row) => row.Project === rowName).Tue = calculateDayTotal("Tue");
            updatedData.find((row) => row.Project === rowName).Wed = calculateDayTotal("Wed");
            updatedData.find((row) => row.Project === rowName).Thu = calculateDayTotal("Thu");
            updatedData.find((row) => row.Project === rowName).Fri = calculateDayTotal("Fri");
            updatedData.find((row) => row.Project === rowName).Sat = calculateDayTotal("Sat");
            updatedData.find((row) => row.Project === rowName).Sun = calculateDayTotal("Sun");
        });

        setData(updatedData);
    };


    useEffect(() => {
        calculateSpecialCells();
    }, []);

    const handleSendForApproval = () => {
        calculateSpecialCells();
    };

    const handleCellEdit = (rowIndex, columnId) => {
        let column = columnId
        const updatedData = [...data];
        updatedData[rowIndex] = {
            ...updatedData[rowIndex],
            [column]: val,
        };
        setData(updatedData);
    };

    const inputData = useMemo(() => data.slice(0, 4), [data]);
    const totalsData = useMemo(() => data.slice(4), [data]);


    const {
        getTableProps: getInputTableProps,
        getTableBodyProps: getInputTableBodyProps,
        headerGroups: inputHeaderGroups,
        rows: inputRows,
        prepareRow: prepareInputRow,
    } = useTable({ columns, data: inputData });


    const {
        getTableProps: getTotalsTableProps,
        getTableBodyProps: getTotalsTableBodyProps,
        rows: totalsRows,
        prepareRow: prepareTotalsRow,
    } = useTable({ columns, data: totalsData });


    const handleView = (isChecked) => {
        setView(isChecked);
        setSwitchVal(isChecked ? 'Team View' : 'My View');
      }
    function createData(sno, id, name, role, project, startdate, enddate, action) {
        return { sno, id, name, role, project, startdate, enddate, action };
    }

    const Tablerows = [
        createData("1", 'CVHS008', 'kumar Kumar', 'MERN', 'HRMS', '11-12-2000', '11-12-2000', <RemoveRedEyeIcon />),
        createData("2", 'CVHS008', 'kumar Kumar', 'MERN', 'HRMS', '11-12-2000', '11-12-2000', <RemoveRedEyeIcon />),
        createData("3", 'CVHS008', 'kumar Kumar', 'MERN', 'HRMS', '11-12-2000', '11-12-2000', <RemoveRedEyeIcon />),
        createData("4", 'CVHS008', 'kumar Kumar', 'MERN', 'HRMS', '11-12-2000', '11-12-2000', <RemoveRedEyeIcon />),
        createData("5", 'CVHS008', 'kumar Kumar', 'MERN', 'HRMS', '11-12-2000', '11-12-2000', <RemoveRedEyeIcon />),
        createData("6", 'CVHS008', 'kumar Kumar', 'MERN', 'HRMS', '11-12-2000', '11-12-2000', <RemoveRedEyeIcon />),
        createData("7", 'CVHS008', 'kumar Kumar', 'MERN', 'HRMS', '11-12-2000', '11-12-2000', <RemoveRedEyeIcon />),
        createData("8", 'CVHS008', 'kumar Kumar', 'MERN', 'HRMS', '11-12-2000', '11-12-2000', <RemoveRedEyeIcon />),
        createData("9", 'CVHS008', 'kumar Kumar', 'MERN', 'HRMS', '11-12-2000', '11-12-2000', <RemoveRedEyeIcon />),
        createData("10", 'CVHS008', 'kumar Kumar', 'MERN', 'HRMS', '11-12-2000', '11-12-2000', <RemoveRedEyeIcon />),
        createData("11", 'CVHS008', 'kumar Kumar', 'MERN', 'HRMS', '11-12-2000', '11-12-2000', <RemoveRedEyeIcon />),
        createData("12", 'CVHS008', 'kumar Kumar', 'MERN', 'HRMS', '11-12-2000', '11-12-2000', <RemoveRedEyeIcon />),
        createData("13", 'CVHS008', 'kumar Kumar', 'MERN', 'HRMS', '11-12-2000', '11-12-2000', <RemoveRedEyeIcon />),
        createData("14", 'CVHS008', 'kumar Kumar', 'MERN', 'HRMS', '11-12-2000', '11-12-2000', <RemoveRedEyeIcon />),
        createData("15", 'CVHS008', 'kumar Kumar', 'MERN', 'HRMS', '11-12-2000', '11-12-2000', <RemoveRedEyeIcon />),
    ];

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 450,
        bgcolor: 'background.paper',
        border: '2px solid #fff',
        borderRadius:5 ,
        boxShadow: 24,
        p: 4,
      };
    return (
        <Fragment>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                  <div className="ModalDIv">
                    <img src={timeSheet} alt="img"/>
                    <img src={ManageTask} alt="img"/>
                  </div>
                  <div>
                  </div>
                </Box>
            </Modal>
            {
                view === false ?

                    <Card variant="outlined" sx={{ padding: "30px" }}>
                        <div className="Firstline">
                            <div>
                                <h4 >
                                    Employee ID
                                </h4>
                                <h3 style={{ color: "#0079E9" }}>
                                    CVHS 200
                                </h3>
                            </div>
                            <div>
                                <h4>
                                    Employee Name
                                </h4>
                                <h3 style={{ color: "#0079E9" }}>
                                    CVHS 200
                                </h3>

                            </div>
                            <div>
                                <h4>
                                    Project Start Date
                                </h4>
                                <h3 style={{ color: "#008A27" }}>
                                    CVHS 200
                                </h3>
                            </div>
                            <div>
                                <h4>
                                    Project End Date
                                </h4>
                                <h3 style={{ color: "#E90000" }}>
                                    12-feb-2024
                                </h3>
                            </div>
                            {/* <div className="Switch-div">
                            <Switch checked={view} onChange={(e) => handleView(e.target.checked)} />
                            <h3 style={{ margin: '7px 0px 0px 0px', color: "#00B562" }}>
                            {SwitchVal}
                            </h3>
                        </div> */}
                                                </div>
                        <div className="linetwo" >
                            <div >
                                <h4 >
                                    Project ID
                                </h4>
                                <h3 style={{ color: "#0079E9", margin: 0 }}>
                                    CVHS 200
                                </h3>
                            </div>
                            <div >
                                <h4 >
                                    Project Name
                                </h4>
                                <h3 style={{ color: "#0079E9", margin: 0 }}>
                                    CVHS 200
                                </h3>
                            </div>
                            <div className="Leave-div">
                                <h3 style={{ margin: '2px 0px 0px 0px' }}>
                                    Available Sick Leave
                                </h3>
                                <h1 style={{ margin: '0px 0px 0px 4px' }}>01</h1>
                            </div>
                            <div className="PTO-div">
                                <h3 style={{ margin: '2px 0px 0px 0px' }}>
                                    Available PTO
                                </h3>
                                <h1 style={{ margin: '0px 0px 0px 4px' }}>01</h1>
                            </div>
                        </div>

                        <div className="Btn-div">
                            <div className="WeekBtn">
                                <NavigateBeforeIcon className='beforeicon' />
                                <p style={{ margin: 0 }}> Current</p>
                                <NavigateNextIcon className='aftericon' />
                            </div>
                            <Button onClick={handleSendForApproval} className='Approval-BTN' variant="contained">
                                Send for Approval
                            </Button>
                            <Button variant="outlined" className='Copy-Btn'>
                                Copy Roster from Last week
                            </Button>
                        </div>
                        <div className="Table-div">
                            <table {...getInputTableProps()} style={{ borderCollapse: 'collapse', width: '100%' }}>
                                <thead>
                                    {inputHeaderGroups.map((headerGroup, groupIndex) => (
                                        <tr {...headerGroup.getHeaderGroupProps()} key={groupIndex}>
                                            {headerGroup.headers.map((column, columnIndex) => (
                                                <th {...column.getHeaderProps()} style={{ border: '1px solid black', padding: '8px' }} key={columnIndex}>
                                                    {column.render('Header')}
                                                </th>
                                            ))}
                                        </tr>
                                    ))}
                                </thead>
                                <tbody {...getInputTableBodyProps()}>
                                    {inputRows.map((row, rowIndex) => {
                                        prepareInputRow(row);
                                        const isSpecialRow = row.original.B === 'Sick Leave' || row.original.B === 'PTO' || row.original.B === 'Total';
                                        return (
                                            <tr
                                                {...row.getRowProps()}
                                                key={rowIndex}
                                            >
                                                {row.cells.map((cell, cellIndex) => (
                                                    <td
                                                        {...cell.getCellProps()}
                                                        style={{
                                                            border: '1px solid black',
                                                            padding: '8px',
                                                            color: isSpecialRow ? '#0070E9' : ''
                                                        }}
                                                        contentEditable={cellIndex !== 0 && !isSpecialRow}
                                                        suppressContentEditableWarning
                                                        onBlur={(e) => handleCellEdit(rowIndex, columns[cellIndex].accessor)}
                                                        key={cellIndex}
                                                    >
                                                        {cell.render('Cell')}
                                                    </td>
                                                ))}
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>

                            <table {...getTotalsTableProps()} style={{ borderCollapse: 'collapse', width: '100%' }}>
                                <tbody {...getTotalsTableBodyProps()}>
                                    {totalsRows.map((row, rowIndex) => {
                                        prepareTotalsRow(row);
                                        return (
                                            <tr
                                                {...row.getRowProps()}
                                                key={rowIndex}
                                            >
                                                {row.cells.map((cell, cellIndex) => (
                                                    <td
                                                        {...cell.getCellProps()}
                                                        style={{
                                                            border: '1px solid black',
                                                            padding: '8px',
                                                            color: cell == 7 ? '#0070E9' : 'red'
                                                        }}
                                                        key={cellIndex}
                                                    >
                                                        {cell.render('Cell')}
                                                    </td>
                                                ))}
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </Card>
                    :
                    <Card variant="outlined" sx={{ padding: "30px" }}>
                        <div className="Team-view">
                            <div>
                                <h2 style={{ margin: 0 }}>
                                    Team View
                                </h2>
                            </div>
                            <div>
                                <Paper
                                    component="form"
                                    sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 400 }}
                                >

                                    <InputBase
                                        sx={{ ml: 1, flex: 1 }}
                                        placeholder="Search"
                                        inputProps={{ 'aria-label': 'search ' }}
                                    />
                                    <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
                                        <SearchIcon />
                                    </IconButton>
                                    <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />

                                </Paper>
                            </div>
                            <div>
                                    <FormControl sx={{ m: 1, minWidth: 120 }}>
                                    <InputLabel id="demo-simple-select-label">Sort By</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        // value={age}
                                        label="Sort By"
                                    // onChange={handleChange}
                                    >
                                        <MenuItem value={10}>Ten</MenuItem>
                                        <MenuItem value={20}>Twenty</MenuItem>
                                        <MenuItem value={30}>Thirty</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                            <div>
                                <Button className='Approval-BTN' variant="contained">
                                    Reports
                                </Button>
                            </div>
                            <div className="Switch-div">
                                <Switch value={SwitchVal} onChange={(e) => handleView(e)} />

                                <h3 style={{ margin: '7px 0px 0px 0px', color: "#0070E9" }}>
                                    {SwitchVal}
                                </h3>
                            </div>
                        </div>
                        <div className="Table-div">
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead >
                                    <TableRow>
                                        <TableCell className="tablecell">S no</TableCell>
                                        <TableCell className="tablecell" align="right">Employee ID</TableCell>
                                        <TableCell className="tablecell" align="right">Employee Name</TableCell>
                                        <TableCell className="tablecell" align="right">Role </TableCell>
                                        <TableCell className="tablecell" align="right">Project Name</TableCell>
                                        <TableCell className="tablecell" align="right">Start Date</TableCell>
                                        <TableCell className="tablecell" align="right">End Date</TableCell>
                                        <TableCell className="tablecell" align="right">Action</TableCell>

                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {Tablerows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((row) => (
                                            <TableRow
                                                key={row.name}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell component="th" scope="row">
                                                    {row.sno}
                                                </TableCell>
                                                <TableCell align="right">{row.id}</TableCell>
                                                <TableCell align="right">{row.name}</TableCell>
                                                <TableCell align="right">{row.role}</TableCell>
                                                <TableCell align="right">{row.project}</TableCell>
                                                <TableCell style={{ color: '#008A27' }} align="right">{row.startdate}</TableCell>
                                                <TableCell style={{ color: '#E90000' }} align="right">{row.enddate}</TableCell>
                                                <TableCell onClick={handleOpen} style={{ color: '#0070E9' }} align="right">{row.action}</TableCell>
                                            </TableRow>
                                        ))}
                                </TableBody>
                            </Table>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 15]}
                                component="div"
                                count={rows.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </div>
                    </Card>
            }
        </Fragment>
    )
}

export default Timesheetsummary