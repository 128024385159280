import { Fragment, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";

import Logo from "assets/images/logo.png";
import GLogo from "assets/images/google.png";
import AuthAPI from "apis/auth/auth";
import Context from "context/context";
import LoginIcon from "assets/images/loginleftimage.png";
import LoginrightImage from "assets/images/loginrightimage.png";
import MailIcon from "assets/images/Mailicon.png";
import keyIcon from "assets/images/Vector.png";
import GoogleLogo from "assets/images/google-logo.png";
import MicrosoftLogo from "../../../assets/images/Microsoft_logo.svg.png"

import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useMsal } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";

export default function Login() {
  const { dispatch } = useContext(Context);
  const navigate = useNavigate();
  const { instance, accounts } = useMsal();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [disableBtn, setDisableBtn] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const { loginAPI, loginGoogle, loginMicrosoft } = AuthAPI();

  const handleSubmit = async () => {
    try {
      setDisableBtn(true);
      dispatch({ type: "SET_LOADING", payload: true });
      if (!email || !password) {
        setDisableBtn(false);
        dispatch({ type: "SET_LOADING", payload: false });
        return toast.error("Please enter email and password!.", {
          toast: "login-error",
        });
      }
      let values = {
        email,
        password,
      };
      let res = await loginAPI(values);
      if(res?.status === "SUCCESS"){
        dispatch({ type: "SET_IS_LOGGED_IN", payload: true });
        dispatch({ type: "SET_EMPLOYEE", payload: res?.data?.employee });
        dispatch({ type: "SET_PERMISSION", payload: res?.data?.permission });
        dispatch({ type: "SET_ROLE", payload: res?.data?.role });
        setDisableBtn(false);
        dispatch({ type: "SET_LOADING", payload: false });
        return navigate("/panel/EmployeeInformation");
      }

        setDisableBtn(false);
        dispatch({ type: "SET_LOADING", payload: false });
        
        return toast.error(
          res?.message?res?.message:"Network issue detected. Please try again later.",
          { toastId: "login-error" }
        );

    } catch (err) {
      console.error(err);
    }
  };

  const googleLogin = useGoogleLogin({
    onSuccess: async (response) => {
      dispatch({ type: "SET_LOADING", payload: true });
      if (response.hd !== "valuehealthsol.com") {
        dispatch({ type: "SET_LOADING", payload: false });
        return toast.error("Please choose your valuehealth mail!", {
          toast: "login-error",
        });
      }
      let googleResponse = await axios.get(
        `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${response.access_token}`,
        {
          headers: {
            Authorization: `Bearer ${response.access_token}`,
            Accept: "application/json",
          },
        }
      );
      let googleData = googleResponse.data;
      let googleProfileImagePath = googleData.picture;

      let values = {
        name: googleData.name,
        first_name: googleData.given_name,
        last_name: googleData.family_name,
        email: googleData.email,
        photo: googleProfileImagePath,
      };
      let result = await loginGoogle(values);
      if (result?.status !== "SUCCESS") {
        dispatch({ type: "SET_LOADING", payload: false });
        return toast.error(result?.message?result?.message:"Something went wrong.", { toast: "login-error" });
      }
      dispatch({ type: "SET_IS_LOGGED_IN", payload: true });
      dispatch({ type: "SET_EMPLOYEE", payload: result?.data?.employee });
      dispatch({ type: "SET_PERMISSION", payload: result?.data?.permission });
      dispatch({ type: "SET_ROLE", payload: result?.data?.role });
      dispatch({ type: "SET_LOADING", payload: false });
      return navigate("/panel/EmployeeInformation");
    },
    onError: (error) => {
      console.log("Login Failed:", error);
    },
  });

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const microsoftLogin = async () => {

    instance
      .loginPopup({
        scopes: ["user.read"],
        prompt: "select_account",
      })
      .then(async (response) => {
        dispatch({ type: "SET_LOADING", payload: true });
        const microsoftData = response.account;
        
        const values = {
          name: microsoftData.name,
          first_name: microsoftData.name.split(" ")[0],
          last_name: microsoftData.name.split(" ")[1],
          email: microsoftData.username,
        };

        try {
          const result = await loginMicrosoft(values);
          if (result?.status === "SUCCESS") {
            dispatch({ type: "SET_IS_LOGGED_IN", payload: true });
            dispatch({ type: "SET_EMPLOYEE", payload: result?.data?.employee });
            dispatch({ type: "SET_PERMISSION", payload: result?.data?.permission });
            dispatch({ type: "SET_ROLE", payload: result?.data?.role });
            navigate("/panel/EmployeeInformation");
          } else {
            toast.error(result?.message?result?.message:"Something went wrong.", { toast: "login-error" });
          }
        } catch (error) {
          toast.error("Something went wrong.", { toast: "login-error" });
        } finally {
          dispatch({ type: "SET_LOADING", payload: false });
        }
      })
      .catch((err) => {
        dispatch({ type: "SET_LOADING", payload: false });
      });
  };

  return (
    <Fragment>
      <Box>
        <Box className="login">
          <Box>
            <img className="logo-login" src={LoginIcon} alt="login" />
          </Box>
          <Box
            style={{
              position: "absolute",
              right: 30,
              width: "20%",
            }}
          >
            <img
              className="logo-login-right"
              src={Logo}
              alt="login"
              style={{ maxWidth: "100%" }}
            />
          </Box>
          <Box
            style={{
              position: "absolute",
              bottom: 0,
              right: 0,
            }}
          >
            <img
              className="logo-login-right"
              src={LoginrightImage}
              alt="login"
              style={{ maxWidth: "100%" }}
            />
          </Box>
        </Box>
      </Box>

      <Box className="login-body">
        <Box
          style={{
            color: "#0070E9",
            fontSize: "30px",
            fontWeight: "bold",
            textAlign: "center",
          }}
        >
          Welcome To Employee Nexus
        </Box>
        <Box
          className="pathName1"
          style={{
            marginTop: "15px",
            fontSize: "15px",
            textAlign: "center",
          }}
        >
          Redefining Employee Engagement
        </Box>
        <Box
          sx={{
            textAlign: "center",
            marginTop: "40px",
            paddingLeft: "50px",
            paddingRight: "50px",
          }}
        >
          <TextField
            style={{
              width: "100%",
              maxWidth:"400px",
              borderRadius: "46px",
              border: "#375370",
              opacity: "0px",
              color: "#375370",
              borderColor: "#375370",
              marginTop: "30px",
            }}
            id="emailId"
            placeholder="Email Id"
            name="employeeId"
            autoComplete="off"
            autoFocus
            InputLabelProps={{ style: { color: "#375370" } }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <img src={MailIcon} alt="Key" />
                </InputAdornment>
              ),
              style: {
                borderRadius: "46px",
                borderColor: "#375370",
                backgroundColor: "#FAFCFE",
                height: "48px",
                border: "1.5px solid #375370",
              },
            }}
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
          <FormControl
            style={{
              width: "100%",
              maxWidth:"400px",
              borderRadius: "46px 0px 0px 0px",
              border: "#375370",
              opacity: "0px",
              color: "#375370",
              boxShadow: "none",
              marginTop: "20px",
            }}
          >
            <OutlinedInput
              id="outlined-adornment-password"
              type={showPassword ? "text" : "password"}
              margin="normal"
              required
              name="password"
              placeholder="Password"
              autoComplete="off"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              InputLabelProps={{
                style: {
                  color: "#375370",
                  backgroundColor: "#375370",
                  paddingLeft: "10px",
                },
              }}
              startAdornment={
                <InputAdornment position="start">
                  <img src={keyIcon} alt="Key" />
                </InputAdornment>
              }
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
              style={{
                borderRadius: "46px",
                borderColor: "#375370",
                backgroundColor: "#FAFCFE",
                height: "48px",
                border: "1.5px solid #375370",
                color: "#375370",
              }}
            />
          </FormControl>
          <Box sx={{ textAlign: "center" }}>
            <Button
              type="submit"
              style={{
                width: "35%",
                background: "#FE602F",
                color: "#fff",
                fontWeight: "bold",
                boxShadow: "none",
                gap: "40px",
              }}
              variant="contained"
              sx={{ marginTop: "34px" }}
              disabled={disableBtn}
              onClick={() => {
                handleSubmit();
              }}
            >
              LOGIN
            </Button>
            <p style={{ color: "#375370", fontWeight: "600" }}>Or</p>
            <Button
              style={{
                backgroundColor: "#E5F0FC",
                color: "#375370",
                fontSize: "16px",
                fontWeight: "bold",
                padding: "4px 34px",
                borderRadius: "25px",
                textTransform: "capitalize",
              }}
              onClick={() => googleLogin()}
            >
              <img alt="google-logo" src={GoogleLogo} />
              Continue with Google
            </Button>

            <Box style={{ marginTop: "20px" }}>
              <Button
                style={{
                  backgroundColor: "#E5F0FC",
                  color: "#375370",
                  fontSize: "16px",
                  fontWeight: "bold",
                  padding: "12px 34px",
                  borderRadius: "25px",
                  textTransform: "capitalize",
                }}
                onClick={() => microsoftLogin()}
              >
                <img
                  alt="microsoft-logo"
                  src={MicrosoftLogo}
                  
                  style={{ width: "27px", marginRight: "8px" }}
                />
                Continue with Microsoft
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Fragment>
  );
}
