// npm packages
import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

// custom pages
import "./EditAddEmployee.css";
import DocumentsAPI from "apis/panel/Document/document";
import FileHandler from "apis/panel/fileHandler/fileHandler";
import getcreatefindone from "apis/panel/AddEmployee/addEmployee";
import Context from "context/context";
import Loader from "components/Loader";
import ReusableFunction from "utils/reusablefunction";

// custom images
import personalInfo from "assets/images/Capa_1 (2).png";

// material ui
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { InputAdornment, IconButton } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ClearIcon from "@mui/icons-material/Clear";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";

const EditDocumentInfo = () => {
  let params = useParams();
  const employeeId = params.id;
  const { dispatch } = useContext(Context);
  const [isEditMode, setIsEditMode] = useState(false);
  const [documentOptions, setDocumentOptions] = useState([]);
  const [documentAadhar, setDocumentAadhar] = useState("");
  const [documentPan, setDocumentPan] = useState("");
  const [documentOther, setDocumentOther] = useState("");
  const [documentAadharId, setDocumentAadharId] = useState("");
  const [documentPanId, setDocumentPanId] = useState("");
  const [documentOtherId, setDocumentOtherId] = useState("");
  const [AadharNumber, setAadharNumber] = useState("");
  const [panNumber, setPanNumber] = useState("");
  const [otherNumber, setOtherNumber] = useState("");
  const [aadharFile, setAadharFile] = useState(null);
  const [panFile, setPanFile] = useState(null);
  const [otherFile, setOtherFile] = useState(null);
  const [expanded, setExpanded] = useState(false);
  const { getEmployeefindone } = getcreatefindone();

  const [loader2, setLoader2] = useState(false);

  const { getAllDocumentsAPI, PostDocumentApi, getOneDocumentApi } =
    DocumentsAPI();
  const { fileUploadAPI, fileDownloadAPI } = FileHandler();
  const { handleFileInputReselect } = ReusableFunction();

  const [errorFields, setErrorFields] = useState({
    aadharnumber: false,
    aadhardocument: false,
    pannumber: false,
    pandocument: false,
    // otherdocument:false,
    // otherdocumentnumber:false,
    // otherdocumentfile:false
  });

  const handleFileUpload = async (file) => {
    const maxSize = 1024 * 1024 * 5;
    if (file.size > maxSize) {
      toast.error("File size exceeds the limit (5MB)", {
        toastId: "file-upload-maximum",
      });
      return false;
    }
    try {
      dispatch({ type: "SET_LOADING", payload: true });
      let input = new FormData();
      input.append("type", "employee_documents");
      input.append("employeeId", employeeId);
      input.append("file", file);
      const res = await fileUploadAPI(input);
      dispatch({ type: "SET_LOADING", payload: false });
      if (res?.data?.location) {
        return res?.data?.location;
      }
      return false;
    } catch (err) {
      dispatch({ type: "SET_LOADING", payload: false });
      console.log({ err });
    }
  };

  const handleFileDownload = async (id) => {
    try {
      dispatch({ type: "SET_LOADING", payload: true });
      await fileDownloadAPI(id);
      dispatch({ type: "SET_LOADING", payload: false });
    } catch (error) {
      dispatch({ type: "SET_LOADING", payload: false });
      console.error("Error downloading file:", error);
    }
  };

  const handleFileChangeAadhar = async (event) => {
    const file = event.target.files[0];
    const location = await handleFileUpload(file);
    if (location) setAadharFile(location);
  };

  const handleFileChangePan = async (event) => {
    const file = event.target.files[0];
    const location = await handleFileUpload(file);
    if (location) setPanFile(location);
  };

  const handleFileChangeOther = async (event) => {
    const file = event.target.files[0];
    const location = await handleFileUpload(file);
    if (location) setOtherFile(location);
  };

  const toggleEditMode = (event) => {
    event.stopPropagation();
    setIsEditMode(!isEditMode);
  };

  const getFilenameFromUrl = (url) => {
    if (url !== "" && url !== undefined && url !== null) {
      const parts = url.split("/");
      return parts[parts.length - 1];
    } else {
      return "";
    }
  };

  const handleCreateLeave = async (e) => {
    const values = {
      relID: employeeId,
    };

    const data = await getEmployeefindone(values);

    let documents = data?.data?.document;
    for (let index = 0; index < documents?.length; index++) {
      const element = documents[index];
      if (element.documents.documentCode == "AADHAR") {
        setAadharNumber(element?.documentNumber);
        setAadharFile(element?.filePath);
        setDocumentAadharId(element?.documentId);
        setDocumentAadhar(element?.documents?.documentName);
      }
      if (element.documents.documentCode == "PAN") {
        setPanNumber(element?.documentNumber);
        setPanFile(element?.filePath);
        setDocumentPanId(element?.documentId);
        setDocumentPan(element?.documents?.documentName);
      }
      if (
        element.documents.documentCode === "PASSPORT" ||
        element.documents.documentCode === "DRIVING_LICENCE"
      ) {
        setOtherNumber(element?.documentNumber);
        setOtherFile(element?.filePath);
        setDocumentOtherId(element?.documentId);
        setDocumentOther(element?.documents.documentName);
      } else {
        setOtherNumber("");
        setOtherFile("");
        setDocumentOtherId("");
        setDocumentOther("");
      }
    }
  };

  useEffect(() => {
    handleCreateLeave();
    handleGetAllDocumentsApi();
    handleDocuments();
  }, []);

  const handleDocument1Change = (e) => {
    const selectedDocument = documentOptions.find(
      (option) => option.documentName === e.target.value
    );
    setDocumentAadhar(selectedDocument.documentName);
    setDocumentAadharId(selectedDocument.documentId);
  };

  const handleDocument2Change = (e) => {
    const selectedDocument = documentOptions.find(
      (option) => option.documentName === e.target.value
    );
    setDocumentPan(selectedDocument.documentName);
    setDocumentPanId(selectedDocument.documentId);
  };

  const handleDocument3Change = (e) => {
    if (e.target.value) {
      const selectedDocument = documentOptions.find(
        (option) => option.documentName === e.target.value
      );
      setDocumentOther(selectedDocument.documentName);
      setDocumentOtherId(selectedDocument.documentId);
    } else {
      setDocumentOther("");
      setDocumentOtherId("");
    }
  };

  const handleGetAllDocumentsApi = async () => {
    try {
      let res = await getAllDocumentsAPI();
      setDocumentOptions(res?.data || []);
    } catch (err) {}
  };

  const handleDocuments = async () => {
    try {
      let aadhar = await getOneDocumentApi("AADHAR");
      let pan = await getOneDocumentApi("PAN");
      setDocumentAadharId(aadhar.data.documentId);
      setDocumentPanId(pan.data.documentId);
    } catch (err) {}
  };

  const handleAccordionChange = (event, isExpanded) => {
    setExpanded(isExpanded);
    if (!isExpanded) {
      // Accordion is not expanded, reset edit mode
      setIsEditMode(false);
    }
  };

  const handleCreateDocument = async (e) => {
    setLoader2(true);
    const errors = {};
    if (!AadharNumber) errors.aadharnumber = true;
    if (!aadharFile) errors.aadhardocument = true;
    if (!panNumber) errors.pannumber = true;
    if (!panFile) errors.pandocument = true;
    if (documentOtherId || otherNumber || otherFile) {
      if (!documentOtherId) errors.documentOtherId = true;
      if (!otherNumber) errors.otherNumber = true;
      if (!otherFile) errors.otherFile = true;
    }

    setErrorFields(errors);

    // If no errors, proceed with form submission
    if (Object.keys(errors).length === 0) {
      if (documentAadharId && documentPanId) {
        let body = {
          employeeId: employeeId,
          aadharData: {
            documentId: documentAadharId,
            documentNumber: AadharNumber,
            expirationDate: "",
            filePath: aadharFile,
          },
          panData: {
            documentId: documentPanId,
            documentNumber: panNumber,
            expirationDate: "",
            filePath: panFile,
          },
          otherData: {
            documentId: documentOtherId,
            documentNumber: otherNumber,
            expirationDate: "",
            filePath: otherFile,
          },
        };
        const data = await PostDocumentApi(body);
        if (data.status === "Ok") {
          handleCreateLeave();
          setLoader2(false);
          return toast.success("Data Saved Successfully", {
            toast: "Data Saved Successfully",
          });
        } else {
          setLoader2(false);
          return toast.error("Error saving Data", {
            toast: "Error saving Data",
          });
        }
      } else {
        setLoader2(false);
        return toast.error("Error saving Data", { toast: "Error saving Data" });
      }
    } else {
      setLoader2(false);
      return toast.error("Error saving Data", { toast: "Error saving Data" });
    }
  };

  const handleClearFile = () => {
    setAadharFile(null);
  };

  const handlepanFile = () => {
    setPanFile(null);
  };

  const handleotherFile = () => {
    setOtherFile(null);
  };

  return (
    <div>
      <Loader loading={loader2} />
      <Accordion
        sx={{ mt: 2, border: "none" }}
        onChange={handleAccordionChange}
      >
        <AccordionSummary
          expandIcon={<ArrowDropDownIcon className="Accordianicon" />}
          aria-controls="panel1-content"
          id="panel1-header"
          style={{
            boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
            height: "70px",
          }}
        >
          <Box sx={{ pl: 3 }}>
            {" "}
            <img
              sx={{ pl: 3 }}
              className="editpersonalInfo-logo"
              src={personalInfo}
              alt="info"
            />
          </Box>
          <Box className="editacc-title" sx={{ pl: 3 }}>
            Government Issued Document Verification
          </Box>
          <Box sx={{ ml: 30 }}>
            {expanded && (
              <button className="editButton" onClick={toggleEditMode}>
                Edit
                <Box sx={{ flexGrow: 1 }} />
                {isEditMode}
              </button>
            )}
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2} sx={{ pl: 4, pr: 5, mt: 1 }}>
            <Grid container spacing={2} sx={{ pl: 4, pr: 5, mt: 1 }}>
              <Box className="subHeadingforinfos"> Aadhaar/SSN Information</Box>
            </Grid>
          </Grid>

          <Grid container spacing={2} sx={{ pl: 4, pr: 5, mt: 2 }}>
            {isEditMode ? (
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <Box className="label">
                  Aadhaar/SSN <span style={{ color: "red" }}>*</span>
                </Box>
                <FormControl fullWidth>
                  {/* <InputLabel id="marital-status-label">Country</InputLabel> */}
                  <Select
                    id="maritalStatus"
                    value={documentAadharId}
                    onChange={handleDocument1Change}
                    placeholder="Select ID Proof"
                    inputProps={{ "aria-label": "Without label" }}
                    style={{ border: "1.5px solid #B2D4F8", color: "grey" }}
                    disabled={true}
                    //   label="Marital Status"
                  >
                    {documentOptions.map((doc, index) => (
                      <MenuItem key={index} value={doc.documentId}>
                        {doc.documentName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            ) : (
              <Grid item xs={12} sm={4} md={4} lg={4} sx={{ mt: 1 }}>
                {/* <Grid item xs={12} sm={6} md={6} lg={6}> */}
                <Box className="editlabel">Aadhaar/SSN </Box>
                <Box className="editvalue">{documentAadhar}</Box>
                {/* </Grid> */}
              </Grid>
            )}

            {isEditMode ? (
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <Box className="label">
                  Aadhaar/SSN Number<span style={{ color: "red" }}>*</span>{" "}
                </Box>
                <TextField
                  id="firstName"
                  placeholder="Enter Id Number"
                  value={AadharNumber}
                  onChange={(e) => setAadharNumber(e.target.value)}
                  variant="outlined"
                  fullWidth
                />
                {errorFields.aadharnumber && (
                  <span className="error-msg">
                    Aadhaar/SSN number is required
                  </span>
                )}
              </Grid>
            ) : (
              <Grid item xs={12} sm={4} md={4} lg={4} sx={{ mt: 1 }}>
                <Box className="editlabel">Aadhaar/SSN Number </Box>
                <Box className="editvalue">{AadharNumber}</Box>
              </Grid>
            )}
            {isEditMode ? (
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <Box className="label">
                  Upload Aadhaar/SSN Copy (JPEG/PNG/JPG/PDF/DOC){" "}
                  <span style={{ color: "red" }}>*</span>
                </Box>
                <TextField
                  fullWidth
                  variant="outlined"
                  placeholder="Browse Files (max of 5mb)"
                  // defaultValue={aadharFile}
                  //   label="Brow"
                  type="text"
                  value={getFilenameFromUrl(aadharFile)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {aadharFile && (
                          <IconButton
                            onClick={handleClearFile}
                            edge="end"
                            aria-label="clear file"
                          >
                            <ClearIcon />
                          </IconButton>
                        )}
                        <input
                          type="file"
                          accept=".pdf,.doc,.docx"
                          id="file-upload"
                          style={{ display: "none" }}
                          onClick={handleFileInputReselect}
                          onChange={(e) => {
                            handleFileChangeAadhar(e);
                          }}
                        />
                        <label htmlFor="file-upload">
                          <IconButton
                            component="span"
                            color="primary"
                            aria-label="attach file"
                          >
                            <AttachFileIcon style={{ color: "#0056B2" }} />
                          </IconButton>
                        </label>
                      </InputAdornment>
                    ),
                  }}
                />
                {errorFields.aadhardocument && (
                  <span className="error-msg">
                    Aadhaar/SSN file is required
                  </span>
                )}
              </Grid>
            ) : (
              <Grid item xs={12} sm={4} md={4} lg={4} sx={{ mt: 1 }}>
                <Box className="editlabel">Upload Aadhaar/SSN Document</Box>
                {aadharFile ? (
                  <span
                    onClick={() => {
                      handleFileDownload(aadharFile);
                    }}
                    className="download"
                  >
                    <DownloadForOfflineIcon
                      style={{ fontSize: 30, verticalAlign: "middle" }}
                    />{" "}
                    {"Click to download"}
                  </span>
                ) : (
                  <Box className="editvalue"> -</Box>
                )}
              </Grid>
            )}
          </Grid>

          <Grid container spacing={2} sx={{ pl: 4, pr: 5, mt: 2 }}>
            <Grid container spacing={2} sx={{ pl: 4, pr: 5, mt: 2 }}>
              <Box className="subHeadingforinfos"> PAN Information</Box>
            </Grid>
          </Grid>

          <Grid container spacing={2} sx={{ pl: 4, pr: 5, mt: 1 }}>
            {isEditMode ? (
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <Box className="label">
                  ID Proof <span style={{ color: "red" }}>*</span>
                </Box>
                <FormControl fullWidth>
                  {/* <InputLabel id="marital-status-label">Country</InputLabel> */}
                  <Select
                    id="maritalStatus"
                    value={documentPanId}
                    onChange={handleDocument2Change}
                    placeholder="Select ID Proof"
                    inputProps={{ "aria-label": "Without label" }}
                    style={{ border: "1.5px solid #B2D4F8", color: "grey" }}
                    disabled={true}
                  >
                    {documentOptions.map((doc, index) => (
                      <MenuItem key={index} value={doc.documentId}>
                        {doc.documentName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            ) : (
              <Grid item xs={12} sm={4} md={4} lg={4} sx={{ mt: 1 }}>
                <Box className="editlabel">ID Proof</Box>
                <Box className="editvalue">{documentPan}</Box>
              </Grid>
            )}
            {isEditMode ? (
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <Box className="label">
                  {" "}
                  PAN Number<span style={{ color: "red" }}>*</span>{" "}
                </Box>
                <TextField
                  id="firstName"
                  value={panNumber}
                  onChange={(e) => setPanNumber(e.target.value)}
                  placeholder="Enter Id Number"
                  variant="outlined"
                  fullWidth
                />
                {errorFields.pannumber && (
                  <span className="error-msg">Pan Number is required</span>
                )}
              </Grid>
            ) : (
              <Grid item xs={12} sm={4} md={4} lg={4} sx={{ mt: 1 }}>
                <Box className="editlabel">PAN Number</Box>
                <Box className="editvalue">{panNumber}</Box>
              </Grid>
            )}

            {isEditMode ? (
              <Grid container spacing={2} sx={{ pl: 2, pr: 5, mt: 2 }}>
                <Grid item xs={12} sm={6} md={6} lg={6}>
                  <Box className="label">
                    {" "}
                    Upload PAN Copy (JPEG/PNG/JPG/PDF/DOC)
                    <span style={{ color: "red" }}>*</span>{" "}
                  </Box>
                  <TextField
                    fullWidth
                    variant="outlined"
                    placeholder="Browse Files (max of 5mb) "
                    //   label="Brow"
                    type="text"
                    // defaultValue={panFile}
                    value={getFilenameFromUrl(panFile)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {panFile && (
                            <IconButton
                              onClick={handlepanFile}
                              edge="end"
                              aria-label="clear file"
                            >
                              <ClearIcon />
                            </IconButton>
                          )}
                          <input
                            type="file"
                            accept=".pdf,.doc,.docx"
                            id="file-upload-pan"
                            style={{ display: "none" }}
                            onClick={handleFileInputReselect}
                            onChange={handleFileChangePan}
                          />
                          <label htmlFor="file-upload-pan">
                            <IconButton
                              component="span"
                              color="primary"
                              aria-label="attach file"
                            >
                              <AttachFileIcon style={{ color: "#0056B2" }} />
                            </IconButton>
                          </label>
                        </InputAdornment>
                      ),
                    }}
                  />
                  {errorFields.pandocument && (
                    <span className="error-msg">Pan file is required</span>
                  )}
                </Grid>
              </Grid>
            ) : (
              <Grid item xs={12} sm={4} md={4} lg={4} sx={{ mt: 1 }}>
                <Box className="editlabel">Upload PAN Copy</Box>
                {panFile ? (
                  <span
                    onClick={() => {
                      handleFileDownload(panFile);
                    }}
                    className="download"
                  >
                    <DownloadForOfflineIcon
                      style={{ fontSize: 30, verticalAlign: "middle" }}
                    />{" "}
                    {"Click to download"}
                  </span>
                ) : (
                  <Box className="editvalue"> -</Box>
                )}
              </Grid>
            )}
          </Grid>
          <Grid container spacing={2} sx={{ pl: 4, pr: 5, mt: 2 }}>
            <Grid container spacing={2} sx={{ pl: 4, pr: 5, mt: 2 }}>
              <Box className="subHeadingforinfos"> Others</Box>
            </Grid>
          </Grid>

          <Grid container spacing={2} sx={{ pl: 4, pr: 5, pb: 5, mt: 1 }}>
            {isEditMode ? (
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <Box className="label">
                  ID Proof <span style={{ color: "red" }}></span>
                </Box>
                <FormControl fullWidth>
                  {/* <InputLabel id="marital-status-label">Country</InputLabel> */}
                  <Select
                    id="maritalStatus"
                    value={documentOther}
                    onChange={handleDocument3Change}
                    placeholder="Select ID Proof"
                    defaultValue=""
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                    style={{ border: "1.5px solid #B2D4F8", color: "grey" }}
                  >
                    <MenuItem value="">Select ID</MenuItem>
                    {documentOptions.map(
                      (doc, index) =>
                        doc.documentCode !== "AADHAR" &&
                        doc.documentCode !== "PAN" && (
                          <MenuItem key={index} value={doc.documentName}>
                            {doc.documentName}
                          </MenuItem>
                        )
                    )}
                  </Select>
                  {errorFields?.documentOtherId && (
                    <span className="error-msg">ID Proof is required</span>
                  )}
                </FormControl>
              </Grid>
            ) : (
              <Grid item xs={12} sm={4} md={4} lg={4} sx={{ mt: 1 }}>
                <Box className="editlabel">ID Proof </Box>
                <Box className="editvalue">{documentOther}</Box>
              </Grid>
            )}
            {isEditMode ? (
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <Box className="label">
                  {" "}
                  ID Number<span style={{ color: "red" }}></span>{" "}
                </Box>
                <TextField
                  id="firstName"
                  value={otherNumber}
                  onChange={(e) => setOtherNumber(e.target.value)}
                  placeholder="Enter Id Number"
                  variant="outlined"
                  fullWidth
                />
                {errorFields?.otherNumber && (
                  <span className="error-msg">ID Number is required</span>
                )}
              </Grid>
            ) : (
              <Grid item xs={12} sm={4} md={4} lg={4} sx={{ mt: 1 }}>
                <Box className="editlabel">ID Number</Box>
                <Box className="editvalue">{otherNumber}</Box>
              </Grid>
            )}
            {isEditMode ? (
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <Box className="label">
                  {" "}
                  Upload Other Document (JPEG/PNG/JPG/PDF/DOC){" "}
                  <span style={{ color: "red" }}></span>{" "}
                </Box>
                <TextField
                  fullWidth
                  variant="outlined"
                  placeholder="Browse Files (max of 5mb) "
                  //   label="Brow"
                  type="text"
                  // defaultValue={otherFile}
                  value={getFilenameFromUrl(otherFile)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {otherFile && (
                          <IconButton
                            onClick={handleotherFile}
                            edge="end"
                            aria-label="clear file"
                          >
                            <ClearIcon />
                          </IconButton>
                        )}
                        <input
                          type="file"
                          accept=".pdf,.doc,.docx"
                          id="file-upload-other"
                          style={{ display: "none" }}
                          onClick={handleFileInputReselect}
                          onChange={handleFileChangeOther}
                        />
                        <label htmlFor="file-upload-other">
                          <IconButton
                            component="span"
                            color="primary"
                            aria-label="attach file"
                          >
                            <AttachFileIcon style={{ color: "#0056B2" }} />
                          </IconButton>
                        </label>
                      </InputAdornment>
                    ),
                  }}
                />
                {errorFields?.otherFile && (
                  <span className="error-msg">
                    Other Document file is required
                  </span>
                )}
              </Grid>
            ) : (
              <Grid item xs={12} sm={4} md={4} lg={4} sx={{ mt: 1 }}>
                <Box className="editlabel"> Upload Other Document</Box>
                {otherFile ? (
                  <span
                    onClick={() => {
                      handleFileDownload(otherFile);
                    }}
                    className="download"
                  >
                    <DownloadForOfflineIcon
                      style={{ fontSize: 30, verticalAlign: "middle" }}
                    />{" "}
                    {"Click to download"}
                  </span>
                ) : (
                  <Box className="editvalue"> -</Box>
                )}
              </Grid>
            )}
          </Grid>

          {isEditMode && (
            <Grid
              container
              spacing={1}
              sx={{ pl: 4, pr: 5, mt: 5, justifyContent: "center" }}
            >
              <Grid item xs={12} sm={2} md={2} lg={2}>
                <Button className="cancelButton" onClick={toggleEditMode}>
                  Cancel
                </Button>
              </Grid>
              <Grid item xs={12} sm={2} md={2} lg={2}>
                <Button className="saveButton" onClick={handleCreateDocument}>
                  save
                </Button>
              </Grid>
            </Grid>
          )}
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default EditDocumentInfo;
