import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import { Grid, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import imports from "../../../../assets/images/rpHomePage/4022440.jpg";
import settings from "../../../../assets/images/settings.png"
import vra from "../../../../assets/images/rpHomePage/vra.png";
import vra2 from "../../../../assets/images/rpHomePage/vra2.jpg";
import caoch from "../../../../assets/images/rpHomePage/8294.jpg";
import report from "../../../../assets/images/rpHomePage/313.jpg";
import affinity from "../../../../assets/images/rpHomePage/aff.png";
import substitution from "../../../../assets/images/rpHomePage/substitution.png";
import Delete from "../../../../assets/images/rpHomePage/saveforsetting.png";


// import "./RpHome.css";

const Settingss = () => {
    const navigate = useNavigate();

    const handlevraRosterNavigate = () => navigate("/panel/vraRoster");
    const handleCard1Navigate = () => navigate("/panel/radRoster");
    const handleCard2Navigate = () => navigate("/panel/vraScheduling");
    const handleCard3Navigate = () => navigate("/panel/rpreports");
    const handleCard4Navigate = () => navigate("/panel/trainingsetup");
    const handleCardQgendaNavigate = () => navigate("/panel/addAffinity");
    const handleAffinityNavigate=()=> navigate("/panel/vraRadAffinity");
    const handleCardVRASchedulingNavigate=()=> navigate("/panel/vraScheduling")

    return (
        <div style={{
            backgroundColor: "white",
            borderRadius: "10px",
            minHeight: "100vh"
        }}>
          
       

            <Box sx={{ pt: 2, pb: 2, pl: 3 }} className="VraShiftsTitle">Settings</Box>
            <Grid container spacing={2} sx={{ p: 1 }}>
                <Grid item xs={12} md={3}>
                    <Card onClick={handleCard2Navigate}>
                        <CardActionArea sx={{ display: 'flex', flexDirection: 'column' }}>
                            <CardMedia
                                component="img"
                                image={imports}
                                alt="Import SRF Data"
                                sx={{
                                    height: '150px',
                                    width: '100%',
                                    objectFit: 'contain',
                                    borderTopLeftRadius: 4,
                                    borderTopRightRadius: 4
                                }}
                            />
                            <CardContent>
                                <div variant="h6" className='CardContentTitlemini'>
                                   Build VRA Schedule
                                </div>
                            </CardContent>
                        </CardActionArea>
                    </Card>   
                </Grid>
                <Grid item xs={12} md={3}>
                    <Card onClick={handleCardQgendaNavigate}>
                        <CardActionArea sx={{ display: 'flex', flexDirection: 'column' }}>
                            <CardMedia
                                component="img"
                                image={imports}
                                alt="Import Qgenda Data"
                                sx={{
                                    height: '150px',
                                    width: '100%',
                                    objectFit: 'contain',
                                    borderTopLeftRadius: 4,
                                    borderTopRightRadius: 4
                                }}
                            />
                            <CardContent>
                                <div variant="h6" className='CardContentTitlemini'>
                                 Build RAD VRA Affinity
                                </div>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>
                <Grid item xs={12} md={3}>
                    <Card onClick={handleCardQgendaNavigate}>
                        <CardActionArea sx={{ display: 'flex', flexDirection: 'column' }}>
                            <CardMedia
                                component="img"
                                image={substitution}
                                alt="Import Qgenda Data"
                                sx={{
                                    height: '150px',
                                    width: '80%',
                                    objectFit: 'contain',
                                    borderTopLeftRadius: 4,
                                    borderTopRightRadius: 4
                                }}
                            />
                            <CardContent>
                                <div variant="h6" className='CardContentTitlemini'>
                                 VRA Substitutions
                                </div>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>
                <Grid item xs={12} md={3}>
                    <Card onClick={handleCardQgendaNavigate}>
                        <CardActionArea sx={{ display: 'flex', flexDirection: 'column' }}>
                            <CardMedia
                                component="img"
                                image={Delete}
                                alt="Import Qgenda Data"
                                sx={{
                                    height: '150px',
                                    width: '60%',
                                    objectFit: 'contain',
                                    borderTopLeftRadius: 4,
                                    borderTopRightRadius: 4
                                }}
                            />
                            <CardContent>
                                <div variant="h6" className='CardContentTitlemini'>
                                Delete Affinity
                                </div>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>
    

                <Grid item xs={12} md={3}>
                    <Card onClick={handleCardQgendaNavigate}>
                        <CardActionArea sx={{ display: 'flex', flexDirection: 'column' }}>
                            <CardMedia
                                component="img"
                                image={Delete}
                                alt="Import Qgenda Data"
                                sx={{
                                    height: '150px',
                                    width: '60%',
                                    objectFit: 'contain',
                                    borderTopLeftRadius: 4,
                                    borderTopRightRadius: 4
                                }}
                            />
                            <CardContent>
                                <div variant="h6" className='CardContentTitlemini'>
                                Delete Forecast Table
                                </div>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>


                <Grid item xs={12} md={3}>
                    <Card onClick={handleCardQgendaNavigate}>
                        <CardActionArea sx={{ display: 'flex', flexDirection: 'column' }}>
                            <CardMedia
                                component="img"
                                image={Delete}
                                alt="Import Qgenda Data"
                                sx={{
                                    height: '150px',
                                    width: '60%',
                                    objectFit: 'contain',
                                    borderTopLeftRadius: 4,
                                    borderTopRightRadius: 4
                                }}
                            />
                            <CardContent>
                                <div variant="h6" className='CardContentTitlemini'>
                                Delete Actual Table
                                </div>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>


                <Grid item xs={12} md={3}>
                    <Card onClick={handleCardQgendaNavigate}>
                        <CardActionArea sx={{ display: 'flex', flexDirection: 'column' }}>
                            <CardMedia
                                component="img"
                                image={Delete}
                                alt="Import Qgenda Data"
                                sx={{
                                    height: '150px',
                                    width: '60%',
                                    objectFit: 'contain',
                                    borderTopLeftRadius: 4,
                                    borderTopRightRadius: 4
                                }}
                            />
                            <CardContent>
                                <div variant="h6" className='CardContentTitlemini'>
                                Delete VRA Schedule
                                </div>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>
            </Grid>


        </div>
    );
};

export default Settingss;
