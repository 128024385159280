// npm packages
import React, { useState, useEffect, Fragment,useContext } from "react";

// custom pages
import EmployeeAPI from "apis/panel/employee/employee";
import context from "context/context";

// material ui
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

export default function TrainingStatus() {
  const { dispatch } = useContext(context);

  const { getTrainingStatusAPI } = EmployeeAPI();

  // Create Employee Task
  const [trainingStatus, setTrainingStatus] = useState([]);

  useEffect(() => {
    handleGetEmployeeTimeoff();
  }, []);

  const handleGetEmployeeTimeoff = async () => {
    dispatch({ type: "SET_LOADING", payload: true });
    try {
      let values = {};

      let res = await getTrainingStatusAPI(values);

      setTrainingStatus(res?.data?.employeeTraining);
      dispatch({ type: "SET_LOADING", payload: false });
    } catch (err) {
      console.log({ err });
    }
  };

  return (
    <Fragment>
      <Box sx={{ padding: "15px" }}>
        <Box sx={{ fontSize: 20, fontWeight: "bold" }}>Training Status</Box>
        <Card
          sx={{
            borderRadius: "12px",
            boxShadow:
              "rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px",
            marginTop: "22px",
          }}
        >
          <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <b>Employee Code</b>
                  </TableCell>
                  <TableCell>
                    <b>NDA SIGNED</b>
                  </TableCell>
                  <TableCell>
                    <b>SEXUAL HARASSMENT PREVENTION</b>
                  </TableCell>
                  <TableCell>
                    <b>DESCRIMINATION</b>
                  </TableCell>
                  <TableCell>
                    <b>WORKPLACE BEHAVIOUR</b>
                  </TableCell>
                  <TableCell>
                    <b>IT SECURITY</b>
                  </TableCell>
                  <TableCell>
                    <b>HIPAA COMPLIANCE</b>
                  </TableCell>
                  <TableCell>
                    <b>GLOBAL DATA PRIVACY"</b>
                  </TableCell>
                  <TableCell>
                    <b>ANTI CORRUPTION AND BRIBERY</b>
                  </TableCell>
                  <TableCell>
                    <b>BYOD POLICY</b>
                  </TableCell>
                  <TableCell>
                    <b>HIPAA EMPLOYEE CONFIDENTIALITY AGREEMENT</b>
                  </TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {trainingStatus &&
                  trainingStatus?.map((obj, i) => (
                    <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
                      <TableCell component="th" scope="row">
                        {obj?.employee?.employeeCode}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {obj?.ndaSigned}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {obj?.sexualHarassement}
                      </TableCell>

                      <TableCell component="th" scope="row">
                        {obj?.descrimination}
                      </TableCell>

                      <TableCell component="th" scope="row">
                        {obj?.workplaceBehaviour}
                      </TableCell>

                      <TableCell component="th" scope="row">
                        {obj?.itSecurity}
                      </TableCell>

                      <TableCell component="th" scope="row">
                        {obj?.hippaCompliance}
                      </TableCell>

                      <TableCell component="th" scope="row">
                        {obj?.globalDataPrivacy}
                      </TableCell>

                      <TableCell component="th" scope="row">
                        {obj?.bribery}
                      </TableCell>

                      <TableCell component="th" scope="row">
                        {obj?.byodPolicy}
                      </TableCell>

                      <TableCell component="th" scope="row">
                        {obj?.confidentialityAgreement}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
      </Box>
    </Fragment>
  );
}
