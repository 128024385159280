import React, { useEffect, useState, useContext } from "react";
import * as moment from "moment";
import Context from "context/context";
import { toast } from "react-toastify";

// custom pages
import "./Timesheet.css";
import TimeSheetTable from "./TimeSheetTable";
import TimesheetApis from "apis/panel/timesheet/TimeSheet";
import postTimeSheet from "apis/panel/timesheet/TimeSheet";
import getemployees from "apis/panel/timesheet/TimeSheet";

// material-ui
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import StarIcon from "@mui/icons-material/Star";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import DeleteIcon from "@mui/icons-material/Delete";
import Pagination from "@mui/material/Pagination";
import EditIcon from "@mui/icons-material/Edit";
import {
  format,
  addDays,
  startOfWeek,
  endOfWeek,
  parseISO,
  addMonths,
  endOfMonth,
  subMonths,
} from "date-fns";

// images
import frame2 from "assets/images/Frame (2).png";
import editiconOrange from "assets/images/Vector (9).png";
import { useNavigate } from "../../../../node_modules/react-router-dom/dist/index";
import CreateEditedTask from "./CreateEditedTask";

import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

const CustomWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 500,
    cursor: "pointer",
    backgroundColor: "#002c5d",
    color: "#ffffff",
  },
});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const Timesheet = () => {
  const moment = require('moment-timezone');
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const { dispatch } = useContext(Context);
  const navigate = useNavigate();
  const [showTimeSheet, setShowTimeSheet] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [projects, setProjects] = useState([]);
  const [sickLeaveData, setSickLeaveData] = useState();
  const [ptoLeaveData, setPtoLeaveData] = useState();

  const [totalData, setTotalData] = useState([]);
  const [empCode, setEmpCode] = useState("");
  const [name, setName] = useState("");
  const [actualWorkHours, setActualWorkHours] = useState(0);
  const [projectCode, setProjectCode] = useState("");
  const [projectName, setProjectName] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [availSickLeave, setAvailSickLeave] = useState(0);
  const [utilizedSckLeave, setUtilizedSickLeave] = useState(0);
  const [ptoleaveavail, setPtoLeaveAvail] = useState(0);
  const [utilizedPto, setUtilizedPto] = useState(0);
  const [approvestatus, setapprovestatus] = useState("");
  const [selectedDates, setSelectedDates] = useState([]);
  const [selectedWeeks, setSelectedWeeks] = useState(
    moment().format("YYYY-MM-DD")
  );
  const [totalWorkHours, setTotalWorkHours] = useState(0);
  const [workLocationType, setWorkLocationType] = useState("Office");
  const [teamView, setTeamView] = useState(false);
  const [holidayList, setHolidayList] = useState([]);

  const [count, setCount] = useState(0);
  const [offset, setOffset] = useState(1);
  const [errors, setErrors] = useState({});
  const [currentPage, setCurrentPage] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const itemsPerPage = 10;


  // apis
  const {
    findOneWeekApi,
    createOrUpdateFavouriteTaskAPI,
    getAllProjectTasksAPI,
    getAllProjectOwnerAPI,
    getAllProjectShiftsAPI,
    createTaskApi,
    getAllCreatedTasksAPI,
    DeleteCreatedTasksAPI,
  } = TimesheetApis();
  const { PostTimeSheetApi, PostDraftTimeSheetApi } = postTimeSheet();
  const { getEmployeeDetailsAPI } = getemployees();

  const [isEditMode, setIsEditMode] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState({
    projectId: "",
    projectName: "",
    taskId: "",
    taskName: "",
    day: "",
    hours: 0,
    description: "",
    openMode: "View",
    workLocationType: "",
  });

  const [editModalContent, setEditModalContent] = useState({
    projectId: "",
    projectName: "",
    taskId: "",
    taskName: "",
    date: "",
    hours: 0,
    description: "",
    openMode: "View",
    workLocationType: "",
    ownerName: "",
    shiftName: "",
    employeeWorkHoursId: "",
    values: [],
  });

  // modal1
  const [isAddTaskClicked, setIsAddTaskClicked] = useState(true);
  const [value, setValue] = React.useState("1");
  const [isListModal, setIsListModal] = useState(false);

  // revamp
  const [projectTaskList, setProjectTaskList] = useState([]);
  const [selectedTask, setSelectedTask] = useState("");
  const [projectOwnerList, setProjectOwnerList] = useState([]);
  const [selectedOwner, setSelectedOwner] = useState("");
  const [projectShiftsList, setProjectShiftsList] = useState([]);
  const [selectedShift, setSelectedShift] = useState("");
  const [hours, setHours] = useState(0);
  const [description, setDescription] = useState("");
  const [createTask, setCreateTask] = useState([]);
  const [getAllCreatedTasks, setGetAllCreatedTasks] = useState([]);
  const [isCreateEditedTask, setIsCreateEditedtask] = useState(false);
  const [deletedTasksList, setDeletedTasksList] = useState([]);
  const [workWeekId, setWorkWeekId] = useState("");
  const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState(false);
  const [rowToDelete, setRowToDelete] = useState(null);
  const [defaultOwner, setdefaultOwner] = useState("");
  const [defaultShift, setdefaultShift] = useState('')
  const [allSelectedTasks, setAllSelectedTasks] = useState([])
  const [filteredTasks,setFilteredTasks]= useState([])

  const [isFirstRender, setIsFirstRender] = useState(true);

  const handleDeleteClick = (row) => {
    setRowToDelete(row);
    setIsDeleteConfirmOpen(true);
  };

  const handleConfirmDelete = () => {
    DeleteCreatedTask(rowToDelete);
    setIsDeleteConfirmOpen(false);
  };

  const handleCloseConfirm = () => {
    setIsDeleteConfirmOpen(false);
    setRowToDelete(null);
    setErrors({})

  };

  const handleProjectTaskChange = (event) => {

    setSelectedTask(event.target.value);
    setErrors({ ...errors, selectedTask: "" });
  };

  const handleProjectShiftChange = (event) => {
    setSelectedShift(event.target.value);
    setErrors({ ...errors, selectedShift: "" });
  };
  const handleProjectOwnerChange = (event) => {
    setSelectedOwner(event.target.value);
    setErrors({ ...errors, selectedOwner: "" });
  };


  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleAddTaskClick = () => {
    setIsAddTaskClicked(true);
    setErrors({})
  };


  const handlegetAllCreatedTasks = async (projectId, day, projectName) => {

    try {
      dispatch({ type: "SET_LOADING", payload: true });
      let value;
      value = {
        projectId: projectId,
        date: day,
        offset: (offset - 1) * 10,
        limit: 10,
      };


      let res = await getAllCreatedTasksAPI(value);
      setGetAllCreatedTasks(res?.data?.data || []);

      setCount(res?.data?.count);


      openModal(projectId, day, projectName);
      dispatch({ type: "SET_LOADING", payload: false });
    } catch (err) {
      dispatch({ type: "SET_LOADING", payload: false });
    }
  };

  useEffect(() => {
    const matchedTaskIds = [];
    const selectedTaskNames=[]

    getAllCreatedTasks.forEach((task) => {
      projectTaskList.forEach((tasklist) => {
        if (task.taskName === tasklist.taskName) {
          matchedTaskIds.push(tasklist.taskId);
          
        }
      });
    });

    const filteredTasks = projectTaskList.filter((task) => 
      !matchedTaskIds.includes(task.taskId)
    );

    setFilteredTasks(filteredTasks)

    setAllSelectedTasks(matchedTaskIds);

  }, [getAllCreatedTasks, projectTaskList]);





  const handleCreateTaskApi = async () => {
   
    let valid = true;
    let newErrors = {};
    if (!selectedTask) {
      valid = false;
      newErrors.selectedTask = "This field is mandatory";
    }
    if (!selectedOwner) {
      valid = false;
      newErrors.selectedOwner = "This field is mandatory";
    }
    if (!hours) {
      valid = false;
      newErrors.hours = "This field is mandatory";
    }


    if (!selectedShift) {
      valid = false;
      newErrors.selectedShift = "This field is mandatory";
    }

    if (!description) {
      valid = false;
      newErrors.description = "This field is mandatory";
    }

    setErrors(newErrors);

    if (valid) {
      try {
        dispatch({ type: "SET_LOADING", payload: true });
        let value;
        value = {
          projectId: modalContent.projectId,
          date: modalContent.day,
          taskId: selectedTask,
          hoursLogged: Number(hours),
          workArrangement: modalContent.workLocationType || "Office",
          projectOwnerId: selectedOwner,
          projectShiftId: selectedShift,
          description: description,
        };


        let res = await createTaskApi(value);
        setCreateTask(res?.data || []);
        if (res?.status == "Ok") {

          toast.success("Record created successfully.");
          setIsListModal(true);
          handlegetAllCreatedTasks(
            modalContent.projectId,
            modalContent.day,
            modalContent.projectName
          );

          setSelectedTask("");
          setDescription("");
          setHours(0);
          setSelectedOwner("");
          setSelectedShift("");
        }

      } catch (err) {
        dispatch({ type: "SET_LOADING", payload: false });
      }
    }
  };



  const handleaddsave = () => {


    if (!allSelectedTasks.includes(selectedTask)) {
      setAllSelectedTasks(prevTasks => [...prevTasks, selectedTask]);
      setErrors({ ...errors, selectedTask: "" });
      
    // } else {
    //   return toast.error(`This task has already been created for the day ${moment(modalContent.day).format("DD-MMM-YYYY")}. Please add the details to the existing task record. Duplicate task records for a single day are not allowed.`);
    }
    handleCreateTaskApi();
  };


  const closeListModal = () => {
    handleGetProjects(selectedWeeks);
    setIsListModal(false);
    // setGetAllCreatedTasks([])
  };


  const getAllProjectTasks = async (projectId) => {
    let values = {
      projectId: projectId,
    };
    const res = await getAllProjectTasksAPI(values);

    setProjectTaskList(res?.data?.data || []);
    setFilteredTasks(res?.dats?.data || [])
    
  };

  
  
  

  const getAllProjectOwner = async (projectId) => {
    let values = {
      projectId: projectId,
    };
    const res = await getAllProjectOwnerAPI(values);
    setProjectOwnerList(res?.data?.data || []);
    const defaultOwnerId = res?.data?.defaultOwner?.projectOwnerId || "";

    setdefaultOwner(defaultOwnerId);
    setSelectedOwner(defaultOwnerId);

  };
  const getAllProjectShifts = async (projectId) => {
    let values = {
      projectId: projectId,
    };
    const res = await getAllProjectShiftsAPI(values);
    const defaultShiftId = res?.data?.defaultShift?.projectShiftId || "";
    setdefaultShift(defaultShiftId);
    setSelectedShift(defaultShiftId);

    setProjectShiftsList(res?.data?.data || []);
  };



  const openAddModal = async () => {
    let projectId = modalContent.projectId;
    getAllProjectOwner(projectId);
    getAllProjectShifts(projectId);
    getAllProjectTasks(projectId);

  };



  const openModal = async (
    projectId,
    day,
    projectName,
    taskId,
    taskName,
    hours,
    description,
    workLocationType
  ) => {
    setIsEditMode(true);
    getAllProjectOwner(projectId);
    getAllProjectShifts(projectId);
    getAllProjectTasks(projectId);
    setIsAddTaskClicked(true);

    setModalContent({
      projectId: projectId,
      projectName: projectName,
      taskId: taskId,
      taskName: taskName,
      day: day,
      hours: hours,
      description: description,
      workLocationType: workLocationType,
      openMode:
        approvestatus === "New" ||
          approvestatus === "Draft" ||
          approvestatus === "Rejected"
          ? "Edit"
          : "View",
    });
    setIsModalOpen(true);
    setIsAddTaskClicked(false);
   
  };

  const EditCreatedTask = async (row, day, projectName) => {
    setIsCreateEditedtask(true);


    setEditModalContent({
      projectId: modalContent?.projectId,
      projectName: modalContent?.projectName,
      taskName: row?.taskName,
      hours: row?.hoursLogged,
      description: row?.description,
      date: day,
      ownerName: row?.ownerName,
      shiftName: row?.shiftName,
      taskName: row?.taskName,
      workLocationType: row?.workLocationType,
      employeeWorkHoursId: row?.employeeWorkHoursId,
      values: [projectTaskList, projectOwnerList, projectShiftsList],
      allSelectedTasks: allSelectedTasks
      // openMode: (approvestatus === "New" || approvestatus === "Draft" || approvestatus === "Rejected") ? "Edit" : "View"
    });


  };

  const closeEditModalBox = () => {

    setIsCreateEditedtask(false);
    setIsListModal(true);
    handlegetAllCreatedTasks(
      modalContent.projectId,
      modalContent.day,
      modalContent.projectName
    );
  };

  const handleHoursChange = (event) => {
    const value = event.target.value;
    if (value >= 0 && value <= 24) {
      setHours(value);
      setErrors({ ...errors, hours: "" });
    } else {
      setErrors({ ...errors, hours: "Please enter a value between 0 and 24." });
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setIsAddTaskClicked(false);
    setIsAddTaskClicked(false);
    setSelectedTask("");
    setDescription("");
    setHours(0);
    setSelectedOwner("");
    setSelectedShift("");
    setErrors({});
    handleGetProjects(selectedWeeks);
    // setapprovestatus("new")
  };

  const handleEdit = () => {
    setIsEditMode(true);
  };

  const handleCancel = () => {
    handleGetProjects(selectedDate);
    setIsAddTaskClicked(false);
    setSelectedTask("");
    setDescription("");
    setHours(0);
    setSelectedOwner("");
    setSelectedShift("");
    setErrors({})
    setapprovestatus("new");
  };

  const handleSave = () => {
    setIsEditMode(false);
    dispatch({ type: "SET_LOADING", payload: true });
    setIsModalOpen(false);
    dispatch({ type: "SET_LOADING", payload: false });
  };

  const [isLeaveEditMode, setIsLeaveEditMode] = useState(false);
  const [isLeaveModalOpen, setIsLeaveModalOpen] = useState(false);
  const [modalLeaveContent, setModalLeaveContent] = useState({
    leaveType: "",
    day: "",
    hours: 0,
    reason: "",
    openMode: "View",
  });

  const openLeaveModal = (leaveType, day, hours, reason) => {
    setIsLeaveEditMode(true);
    setModalLeaveContent({
      leaveType: leaveType,
      day: day,
      hours: hours,
      reason: reason,
      openMode:
        approvestatus === "New" ||
          approvestatus === "Draft" ||
          approvestatus === "Rejected"
          ? "Edit"
          : "View",
    });
    setIsLeaveModalOpen(true);
  };

  const closeLeaveModal = () => {
    setIsLeaveModalOpen(false);
  };

  const handleLeaveEdit = () => {
    setIsLeaveEditMode(true);
  };

  const handleLeaveCancel = () => {
    setIsLeaveModalOpen(false);
    handleGetProjects(selectedDate);
  };

  const handleLeaveSave = () => {
    setIsLeaveEditMode(false);
    dispatch({ type: "SET_LOADING", payload: true });
    setIsLeaveModalOpen(false);
    dispatch({ type: "SET_LOADING", payload: false });
  };

  const toggleComponent = () => {
    setShowTimeSheet((prevState) => !prevState);
  };


  const label = { inputProps: { "aria-label": "Switch demo" } };

  const handleHourChange = (projectName, taskName, day, e) => {
    let { value, min, max } = e;
    value = Math.max(Number(min), Math.min(Number(max), Number(value)));
    value = Math.round(value * 2) / 2;
    setModalContent({
      ...modalContent,
      hours: value,
    });
    setProjects((prevProjects) => {
      const updatedProjects = prevProjects.map((project) => {
        if (project?.projectName === projectName) {
          return {
            ...project,
            tasks: project?.tasks?.map((task) => {
              if (task?.taskName === taskName) {
                return {
                  ...task,
                  [day]: {
                    hoursLogged: value,
                    description: modalContent.description,
                    workLocationType: modalContent.workLocationType,
                  },
                };
              }
              return task;
            }),
          };
        }
        return project;
      });
      return updatedProjects;
    });
  };

  const submitDraftTimeSheet = async (saveType) => {
    if (saveType == "work") {
      if (modalContent?.hours == 0) {
        return toast.error("Please enter the working hours!");
      }
      if (
        modalContent?.description == null ||
        modalContent?.description == ""
      ) {
        return toast.error("Please enter the description!");
      }
    }
    if (saveType == "leave") {
      if (modalLeaveContent?.hours == 0) {
        return toast.error("Please enter the leave hours!");
      }
      if (
        modalLeaveContent?.reason == null ||
        modalLeaveContent?.reason == ""
      ) {
        return toast.error("Please enter the reason!");
      }
    }
    let workingHours = [];
    projects?.forEach((project) => {
      project?.tasks?.forEach((task) => {
        selectedDates?.forEach((date) => {
          const hoursLogged = task[date].hoursLogged || 0;
          const description = task[date].description;
          const workLocationType = task[date].workLocationType || "Office";
          if (hoursLogged && hoursLogged > 0) {
            let my_object = {
              projectId: project.projectId,
              taskId: task.taskId,
              hoursLogged: hoursLogged,
              description: description,
              workLocationType: workLocationType,
              hoursType: "Regular",
              date: date,
            };
            workingHours.push(my_object);
          }
        });
      });
    });
    const sickHours = sickLeaveData;
    selectedDates?.forEach((date) => {
      const hoursLogged = sickHours[date].hoursLogged || 0;
      const description = sickHours[date].description;
      if (hoursLogged && hoursLogged > 0) {
        workingHours.push({
          hoursLogged: hoursLogged,
          description: description,
          hoursType: "Sick",
          date: date,
        });
      }
    });
    const ptoHours = ptoLeaveData;
    selectedDates?.forEach((date) => {
      const hoursLogged = ptoHours[date].hoursLogged || 0;
      const description = ptoHours[date].description;
      if (hoursLogged && hoursLogged > 0) {
        workingHours.push({
          hoursLogged: hoursLogged,
          description: description,
          hoursType: "PTO",
          date: date,
        });
      }
    });
    const value = {
      week: selectedWeeks,
      workingHours: workingHours,
    };
    try {
      dispatch({ type: "SET_LOADING", payload: true });
      let res = await PostDraftTimeSheetApi(value);
      if (res.status === "Ok") {
        dispatch({ type: "SET_LOADING", payload: false });
        toast.success("Timesheet entries have been successfully saved.");
        setIsModalOpen(false);
        setIsLeaveModalOpen(false);
      } else {
        dispatch({ type: "SET_LOADING", payload: false });
        toast.error("Failed to submit draft time sheet");
      }
    } catch (error) {
      console.error("Error submitting draft time sheet:", error);
      toast.error("Failed to submit draft time sheet");
    }
  };

  const handleDescriptionChange = (projectName, taskName, day, e) => {
    let { value } = e;
    setModalContent({
      ...modalContent,
      description: value,
    });
    setProjects((prevProjects) => {
      const updatedProjects = prevProjects.map((project) => {
        if (project?.projectName === projectName) {
          return {
            ...project,
            tasks: project?.tasks?.map((task) => {
              if (task?.taskName === taskName) {
                return {
                  ...task,
                  [day]: {
                    hoursLogged: modalContent.hours,
                    workLocationType: modalContent.workLocationType,
                    description: value,
                  },
                };
              }
              return task;
            }),
          };
        }
        return project;
      });
      return updatedProjects;
    });
  };

  
  const handleWorkArrangementChange = (projectName, taskName, day, e) => {
    let { value } = e;
    setModalContent({
      ...modalContent,
      workLocationType: value
    });

    setProjects((prevProjects) => {
      const updatedProjects = prevProjects.map((project) => {
        if (project?.projectName === projectName) {
          return {
            ...project,
            tasks: project?.tasks?.map((task) => {
              if (task?.taskName === taskName) {
                return {
                  ...task,
                  [day]: {
                    hoursLogged: modalContent.hours,
                    description: modalContent.description,
                    workLocationType: value,
                  },
                };
              }

              return task;
            }),
          };
        }

        return project;
      });
      return updatedProjects;
    });
  };

  const handleSickLeaveChange = (day, e) => {
    let { value, min, max } = e;
    setModalLeaveContent({
      ...modalLeaveContent,
      hours: value,
    });
    setSickLeaveData({
      ...sickLeaveData,
      [day]: {
        hoursLogged: value,
        description: modalLeaveContent.description,
      },
    });
  };

  const handlePtoLeaveChange = (day, e) => {
    let { value, min, max } = e;
    setModalLeaveContent({
      ...modalLeaveContent,
      hours: value,
    });
    setPtoLeaveData({
      ...ptoLeaveData,
      [day]: {
        hoursLogged: value,
        description: modalLeaveContent.description,
      },
    });
  };

  const handleReasonChange = (leaveType, day, e) => {
    let { value } = e;
    setModalLeaveContent({
      ...modalLeaveContent,
      reason: value,
    });
    if (leaveType == "sick") {
      setSickLeaveData({
        ...sickLeaveData,
        [day]: {
          hoursLogged: modalLeaveContent.hours,
          description: value,
        },
      });
    }
    if (leaveType == "pl") {
      setPtoLeaveData({
        ...ptoLeaveData,
        [day]: {
          hoursLogged: modalLeaveContent.hours,
          description: value,
        },
      });
    }
  };

  const handlePagination = (value) => {
    setOffset(value);
  };

  useEffect(() => {
    getWeekDates(selectedWeeks);
    handleGetProjects(selectedDate);
  }, [getAllCreatedTasks]);

  useEffect(() => {
    handleGetEmployees();
  }, [])

  useEffect(() => {
    getWeekTotals();
  }, [projects, sickLeaveData, ptoLeaveData]);

  useEffect(() => {
    if (!isFirstRender) {
      handlegetAllCreatedTasks(modalContent.projectId, modalContent.day, modalContent.projectName);
    } else {
      setIsFirstRender(false);
    }
  }, [offset]);


  const getWeekDates = async (selectedDate) => {
    // Detect the user's time zone
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    // Calculate week dates based on the user's time zone
    let startOfWeek = moment.tz(selectedDate, userTimeZone).startOf("isoWeek");

    // Generate the week dates, ensuring each is in the user's time zone
    let d1 = startOfWeek.format("YYYY-MM-DD");
    let d2 = startOfWeek.clone().add(1, "days").format("YYYY-MM-DD");
    let d3 = startOfWeek.clone().add(2, "days").format("YYYY-MM-DD");
    let d4 = startOfWeek.clone().add(3, "days").format("YYYY-MM-DD");
    let d5 = startOfWeek.clone().add(4, "days").format("YYYY-MM-DD");
    let d6 = startOfWeek.clone().add(5, "days").format("YYYY-MM-DD");
    let d7 = startOfWeek.clone().add(6, "days").format("YYYY-MM-DD");

    setSelectedDates([d1, d2, d3, d4, d5, d6, d7]);
  };


  const getWeekTotals = () => {

    let dateSums = [];
    let totalHours = 0;
    let allTotal = 0;
    for (let i = 0; i < selectedDates.length; i++) {
      allTotal = 0;
      projects.map((p) => {
        let value = p[selectedDates[i]]?.hoursLogged;
       
        allTotal += value;
        
      });
      dateSums.push(allTotal);
      totalHours += allTotal;
    }
    setTotalData(dateSums);
    
    setTotalWorkHours(totalHours);
    // setTotalData(dateSums);
  };



  const handleGetProjects = async (weekNumber) => {
    let value = {
      week: weekNumber,
    };
    try {
      dispatch({ type: "SET_LOADING", payload: true });
      let res = await findOneWeekApi(value);

      setapprovestatus(res?.data?.access?.status);

      if (
        res.status === "Ok" &&
        res?.data &&
        res?.data?.employeeProject &&
        res?.data?.employeeProject?.length > 0
      ) {
        let holidayList = res?.data?.holidayList;
        let employeeProject = res?.data?.employeeProject;
        let employeeSick = res?.data?.timeoff[0];
        let employeePto = res?.data?.timeoff[1];
        let workweekId = res?.data?.employeeWorkWeeks?.employeeWorkWeekId;
       
        setHolidayList(holidayList || []);
        setProjects(employeeProject || []);
        setSickLeaveData(employeeSick);
        setPtoLeaveData(employeePto);
        setWorkWeekId(workweekId);
      }
      dispatch({ type: "SET_LOADING", payload: false });
    } catch (err) {
      console.error("Error fetching projects:", err);
    }
  };

  const handleGetEmployees = async () => {
    try {
      dispatch({ type: "SET_LOADING", payload: true });
      let res = await getEmployeeDetailsAPI();
      setEmpCode(res?.data?.employeeInformation?.employeeCode);
      setName(res?.data?.employeeInformation?.firstName);
      setActualWorkHours(
        res?.data?.employeeInformation?.workLocations?.workingHours
      );

      setStartDate(res?.data?.projectInformation?.startDate);
      setEndDate(res?.data?.projectInformation?.endDate);
      setProjectCode(res?.data?.projectInformation?.projectCode);
      setProjectName(res?.data?.projectInformation?.projectName);
      setAvailSickLeave(
        res?.data?.timeoffInformation?.availableSickLeavePerMonth || 0
      );
      setUtilizedSickLeave(
        res?.data?.timeoffInformation?.utilizedSickLeavePerMonth || 0
      );
      setPtoLeaveAvail(
        res?.data?.timeoffInformation?.availablePTOLeavePerYear || 0
      );
      setUtilizedPto(
        res?.data?.timeoffInformation?.utilizedTotalPTOPerYear || 0
      );
      setTeamView(res?.data?.teamView);
      console.log(teamView, "teamview")
      dispatch({ type: "SET_LOADING", payload: false });
    } catch (err) { }
  };

  const submitTimeSheet = async () => {
    let workingHours = [];

    projects?.forEach((project) => {
      project?.tasks?.forEach((task) => {
        selectedDates?.forEach((date) => {
          const hoursLogged = task[date].hoursLogged || 0;
          const description = task[date].description;
          const workLocationType = task[date].workLocationType;
          if (hoursLogged && hoursLogged > 0) {
            let my_object = {
              projectId: project.projectId,
              taskId: task.taskId,
              hoursLogged: hoursLogged,
              description: description,    
              workLocationType: workLocationType,
              hoursType: "Regular",
              date: date,
            };
            workingHours.push(my_object);
          }
        });
      });
    });
    let scount = 0;
    const sickHours = sickLeaveData;
    selectedDates?.forEach((date) => {
      const hoursLogged = sickHours[date].hoursLogged || 0;
      const description = sickHours[date].description;
      if (hoursLogged && hoursLogged > 0) {
        scount = scount + parseFloat(hoursLogged);
        workingHours.push({
          hoursLogged: hoursLogged,
          description: description,
          hoursType: "Sick",
          date: date,
        });
      }
    });
    let pcount = 0;
    const ptoHours = ptoLeaveData;
    selectedDates?.forEach((date) => {
      const hoursLogged = ptoHours[date].hoursLogged || 0;
      const description = ptoHours[date].description;
      if (hoursLogged && hoursLogged > 0) {
        pcount = pcount + parseFloat(hoursLogged);
        workingHours.push({
          hoursLogged: hoursLogged,
          description: description,
          hoursType: "PTO",
          date: date,
        });
      }
    });
    const value = {
      week: selectedWeeks,
      employeeWorkWeekId: workWeekId,
    };
    try {
      dispatch({ type: "SET_LOADING", payload: true });

      let res = await PostTimeSheetApi(value);
      
      if (res.status === "Ok") {
        dispatch({ type: "SET_LOADING", payload: false });
        toast.success("Time sheet submitted successfully");
        handleGetProjects(selectedWeeks);
      } else {
        dispatch({ type: "SET_LOADING", payload: false });
        toast.error("Failed to submit time sheet inside");
      }
    }
    catch (error) {
      console.error("Error submitting time sheet:", error);
      toast.error("Failed to submit time sheet");
    }
  };


  const handleDateChange = (date) => {
    setSelectedDate(date);
    setSelectedWeeks(moment(date).format("YYYY-MM-DD"));
    getWeekDates(date);
    handleGetProjects(date);
  };

  const DeleteCreatedTask = async (row) => {

    let values = {
      employeeWorkHoursId: row.employeeWorkHoursId,
    };
    dispatch({ type: "SET_LOADING", payload: true });
    const res = await DeleteCreatedTasksAPI(values);
    if (res?.status == "Ok") {
      handlegetAllCreatedTasks(
        modalContent.projectId,
        modalContent.day,
        modalContent.projectName
      );
    }

    dispatch({ type: "SET_LOADING", payload: false });
    setDeletedTasksList(res?.data?.data || []);
  };

  return (
    <div className="timesheetcont">
      {!showTimeSheet ? (
        <div className="timesheet-main">
          <div className="timesheet-content2">
            <Grid container sx={{ display: "flex", justifyContent: "space-end", alignItems: "center" }}>
              <Grid
                style={{ marginRight: "auto" }}
                item
                xs={12}
                sm={3.5}
                md={3.5}
                lg={3.5}>
                <Box className="MyTimesheetHeading">My Timesheet</Box>

              </Grid>
              <Grid
                item
                xs={12}
                sm={8.5}
                md={8.5}
                lg={8.5}
                // justifyContent="right"
                sx={{ display: "flex", justifyContent: "end" }}
              >
                <LocalizationProvider fullWidth dateAdapter={AdapterDateFns}>
                  <DatePicker
                    id="selectedDate"
                    name="selectedDate"
                    value={selectedDate}
                    className="dateInput"
                    onAccept={handleDateChange}
                    format="dd-MMM-yyyy"
                    maxDate={new Date()}
                    renderInput={(params) => (
                      <TextField {...params} fullWidth />
                    )}
                  />
                </LocalizationProvider>

                {approvestatus === "Approved" && (
                  <Button
                    className="approvalbutton"
                    disabled
                    sx={{ mr: "10px", ml: "10px" }}
                  >
                    Approved
                  </Button>
                )}
                {approvestatus === "Rejected" && (
                  <Button
                    className="rejectbutton"
                    onClick={submitTimeSheet}
                    sx={{ mr: "10px", ml: "10px" }}
                  >
                    Resubmit for Approval
                  </Button>
                )}
                {approvestatus === "Pending" && (
                  <Button
                    className="rejectbutton"
                    disabled
                    sx={{ mr: "10px", ml: "10px" }}
                  >
                    Pending For Review
                  </Button>
                )}
                {(approvestatus === "New" || approvestatus === "Draft") && (
                  <>
                    <Button
                      className="approvalbutton"
                      onClick={submitTimeSheet}
                      sx={{ mr: "10px", ml: "10px" }}
                    >
                      Send For Approval
                    </Button>
                  </>
                )}
                {approvestatus === "" && (
                  <Button
                    className="approvalbutton"
                    disabled
                    sx={{ mr: "10px", ml: "10px" }}
                  >
                    Not Allowed
                  </Button>
                )}
                {teamView && (
                  <CustomWidthTooltip
                    title={
                      <span>
                        Toggle to switch to Manager View and <br />
                        see timesheets submitted by team members.
                      </span>
                    }
                  >
                    <div style={{ display: "inline-flex" }}>
                      <div className="tsheads">
                        <Switch
                          style={{ color: "gray" }}
                          checked={showTimeSheet}
                          onChange={toggleComponent}
                          {...label}
                        />
                      </div>
                      <div
                        className="tsbods"
                        style={{ color: "#00B562", paddingTop: 9 }}
                      >
                        Switch to Manager View
                      </div>
                    </div>
                  </CustomWidthTooltip>
                )}
              </Grid>
            </Grid>
          </div>

          <table className="timesheet-table">
            <thead>
              <tr>
                <th
                  style={{ width: "15%", height: "60px" }}
                  className="projectNameHeadingTable"
                >
                  Project Name
                </th>


                {selectedDates?.map((day, index) => (
                  <th
                    key={index}
                    className="daystyle"
                    style={{
                      width: "10%",
                      height: "60px",
                    }}
                  >
                    {moment.tz(day, userTimeZone).format("ddd")}
                  
                    <br />
                    <span
                      style={{
                        color: "#0070E9",
                        fontWeight: "700",
                        fontSize: "14px",
                        paddingTop: "6px",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {moment.tz(day, userTimeZone).format("DD-MMM-YYYY")}
                    </span>
                  </th>
                ))}

              </tr>
            </thead>
            <tbody>
              {projects?.map((project) => (
                <tr key={project.projectId}>
                  <td className="projectntaskname">{project.projectName}</td>
                  {selectedDates?.map((day, index) => (
                    <td
                      key={index}
                      onClick={() =>
                        handlegetAllCreatedTasks(
                          project.projectId,
                          day,
                          project.projectName
                        )
                      }
                      style={{
                        textAlign: "center",
                        color:
                          project[day]?.hoursLogged > 0 ? "#375370" : "inherit",
                        fontSize: "14px",
                        fontWeight: "400",
                        cursor: "pointer",
                      }}
                    >
                      {project[day]?.hoursLogged !== undefined
                        ? project[day]?.hoursLogged
                        : "0"}
                    </td>
                  ))}
                </tr>
              ))}
              <tr>
                <td
                  style={{
                    color: "#E97000",
                    fontSize: "14px",
                    fontWeight: "700",
                    textAlign: "center",
                    padding: "10px",
                  }}
                >
                  Sick Leave
                </td>
                {selectedDates?.map((day, index) => (
                  <td
                    key={index}
                    
                    style={{
                      textAlign: "center",
                      color: "#E97000",
                      fontSize: "14px",
                      fontWeight: "700",
                      cursor: "pointer",
                    }}
                  >
                    {sickLeaveData?.[day]?.hoursLogged !== undefined
                      ? sickLeaveData?.[day]?.hoursLogged
                      : "0"}
                  </td>
                ))}
              </tr>
              <tr>
                <td
                  style={{
                    color: "#0070E9",
                    fontSize: "14px",
                    fontWeight: "700",
                    textAlign: "center",
                    padding: "10px",
                  }}
                >
                  Privilege Leave
                </td>
                {selectedDates?.map((day, index) => (
                  <td
                    key={index}
                   
                    style={{
                      textAlign: "center",
                      color: "#0070E9",
                      fontSize: "14px",
                      fontWeight: "700",
                      cursor: "pointer",
                    }}
                  >
                    {ptoLeaveData?.[day]?.hoursLogged !== undefined
                      ? ptoLeaveData?.[day]?.hoursLogged
                      : "0"}
                  </td>
                ))}
              </tr>
              <tr>
                <td
                  style={{
                    color: "#00B562",
                    fontSize: "14px",
                    fontWeight: "700",
                    textAlign: "center",
                    padding: "10px",
                  }}
                >
                  Total Working Hours
                </td>
               
                {selectedDates.map((day, index) => (
                  <td key={index}>
                    <input
                      style={{
                        backgroundColor: "white",
                        textAlign: "center",
                        color: "#00B562",
                        fontSize: "14px",
                        fontWeight: "700",
                      }}
                      className="timesheet-input"
                      value={totalData[index] || 0}
                      disabled={true}
                    />
                  </td>
                ))}
              </tr>
            </tbody>
          </table>

          <Modal
            open={isModalOpen}
            onClose={closeModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            style={{ border: "none" }}
          >
            <Box
              sx={style}
              style={{
                minHeight: "300px",
                maxHeight: "450px",
                overflowY: "auto",
                borderRadius: "10px",
                border: 'none'
              }}
            >
              {/* Close Button */}
              <IconButton
                aria-label="close"
                onClick={closeModal}
                sx={{
                  position: "absolute",
                  top: 0,
                  right: 0,
                  color: "inherit",
                  zIndex: 1,
                }}
              >
                <CloseIcon />
              </IconButton>

              {/* Project Information */}
              <Grid
                container
                xs={12}
                sm={12}
                md={12}
                lg={12}
                style={{ alignItems: "center" }}
              >
                <Grid
                  item
                  xs={12}
                  sm={5}
                  md={5}
                  lg={5}
                  style={{ display: "flex", gap: "1%" }}
                >
                  <div className="tsheads">Project Name : </div>
                  <div className="tsbods">{modalContent.projectName}</div>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={4}
                  md={4}
                  lg={4}
                  style={{ display: "flex", gap: "1%", pl: 3 }}
                >
                  <div className="tsheads">Task Date :</div>
                  <div className="tsbods">
                    {" "}
                    {moment(modalContent.day).format("DD-MMM-YYYY")}
                  </div>
                </Grid>

                {!isAddTaskClicked &&
                  (approvestatus === "New" || approvestatus === "Draft" || approvestatus === "Rejected") ? (
                  <Grid item xs={12} sm={3} md={3} lg={3}>
                    <Button
                      className="AddTaskButtonInModal"
                      onClick={handleAddTaskClick}
                    >
                      Add Task
                    </Button>
                  </Grid>
                ) : null}
              </Grid>

              {/* Frame Image */}
             
              {!isAddTaskClicked && getAllCreatedTasks.length > 0 ? (
                <>
                  <Grid
                    container
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    style={{ alignItems: "center", marginTop: "2%" }}
                  >
                    <TableContainer component={Paper}>
                      <Table aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell className="tasklistheadertable">
                              S.No
                            </TableCell>
                            <TableCell className="tasklistheadertable">
                              Task Name
                            </TableCell>
                            <TableCell className="tasklistheadertable">
                              Project Owner
                            </TableCell>
                            <TableCell className="tasklistheadertable">
                              No. of Hours
                            </TableCell>
                            <TableCell className="tasklistheadertable">
                              Work Arrangements
                            </TableCell>
                            <TableCell className="tasklistheadertable">
                              Shift Name
                            </TableCell>
                            {!isAddTaskClicked &&
                              (approvestatus === "New" ||
                                approvestatus === "Draft" || approvestatus === "Rejected") ? (
                              <TableCell className="tasklistheadertable">
                                Action
                              </TableCell>
                            ) : null}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {getAllCreatedTasks?.map((row, index) => (
                            <TableRow
                              key={row.index}
                              style={{
                                alignItems: "center",
                                textAlign: "center",
                                justifyContent: "center",
                              }}
                            >
                              <TableCell>{((offset - 1) * 10) + index + 1}</TableCell>
                              <TableCell>{row?.taskName}</TableCell>
                              <TableCell>{row.ownerName}</TableCell>
                              <TableCell>{row.hoursLogged}</TableCell>
                              <TableCell>{row.workLocationType}</TableCell>
                              <TableCell>{row.shiftName}</TableCell>
                              {!isAddTaskClicked &&
                                (approvestatus === "New" ||
                                  approvestatus === "Draft" || approvestatus === "Rejected") ? (
                                <TableCell
                                  style={{
                                    alignItems: "center",
                                    textAlign: "center",
                                    justifyContent: "center",
                                    display: "flex",
                                    gap: "%",
                                  }}
                                >
                                  <div>
                                    <CustomWidthTooltip title="Click to edit details">
                                      <EditIcon
                                        style={{
                                          color: "rgb(0, 121, 233)",
                                          // color: "#0070E9",
                                          cursor: "pointer",
                                        }}
                                        onClick={() => EditCreatedTask(row, modalContent?.day)}
                                      />
                                    </CustomWidthTooltip>
                                  </div>
                                  <div onClick={() => handleDeleteClick(row)}>
                                    <CustomWidthTooltip title="Click to delete details">
                                      <DeleteIcon
                                        style={{
                                          color: "red",
                                          cursor: "pointer",
                                        }}
                                      />
                                    </CustomWidthTooltip>
                                  </div>
                                </TableCell>
                              ) : null}
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>

                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        margin: "auto",
                        pt: 2,
                      }}
                    >
                      <Pagination
                        style={{
                          textAlign: "center",
                          justifyContent: "center",
                          margin: "auto",
                          pt: 2,
                        }}
                        count={
                          (count / 10) % 1 == 0
                            ? Math.floor(count / 10)
                            : Math.floor(count / 10 + 1)
                        }
                        page={offset}
                        siblingCount={1}
                        onChange={(event, value) => {
                          handlePagination(value);
                        }}
                        color="primary"
                      />
                    </Box>
                  </Grid>
                </>
              ) : (
                !isAddTaskClicked && (
                  <Grid container sx={{ mt: 4 }}>
                    <img
                      style={{ width: "100%", minHeight: "200px" }}
                      src={frame2}
                      alt=""
                    />
                  </Grid>
                )
              )}

              {/* Inputs Grid */}
              {isAddTaskClicked && (
                <Box>
                  <Grid container sx={{ mt: 2, display: "flex", gap: "4%" }}>
                    <Grid
                      item
                      xs={12}
                      sm={5.5}
                      md={5.5}
                      lg={5.5}
                      sx={{ mt: 2 }}
                    >
                      <Box className="label" style={{ color: "#002C5D" }}>
                        {" "}
                        Task Name <span style={{ color: "red" }}>*</span>
                      </Box>
                      {/* {(isEditMode && modalContent.openMode == "Edit") ? */}
                      <FormControl
                        sx={{
                          width: "100%",
                        }}
                      >
                        <Select
                          id="workLocationType"
                          name="workLocationType"
                          sx={{
                            padding: "0px 0px",
                            border: "1px solid #B2D4F8",
                            height: "48px",
                          }}
                          value={selectedTask}
                          displayEmpty
                          defaultValue=""
                          onChange={handleProjectTaskChange}
                        >
                          <MenuItem value="">Select Task</MenuItem>
                          {filteredTasks &&
                            filteredTasks.length > 0 &&
                            filteredTasks.map((task, index) => (
                              <MenuItem key={index} value={task.taskId}>
                                {task.taskName}
                              </MenuItem>
                            ))}
                        </Select>
                        {errors.selectedTask && (
                          <Box style={{ color: "red" }}>
                            {errors.selectedTask}
                          </Box>
                        )}
                      </FormControl>
                      {/* :
                        <Box className="viewValue" style={{ margin: "10px" }}>{selectedTask}</Box> */}

                      {/* } */}
                    </Grid>
                  
                    <Grid
                      item
                      xs={12}
                      sm={5.5}
                      md={5.5}
                      lg={5.5}
                      sx={{ mt: 2 }}
                    >
                      <Box className="label" style={{ color: "#002C5D" }}>
                        Project Owner <span style={{ color: "red" }}>*</span>
                      </Box>
                      {isEditMode && modalContent.openMode == "Edit" ? (
                        <FormControl
                          sx={{
                            width: "100%",
                          }}
                        >
                          <Select
                            id="workLocationType"
                            displayEmpty
                            defaultValue=""
                            name="workLocationType"
                            sx={{
                              padding: "0px 0px",
                              border: "1px solid #B2D4F8",
                              height: "48px",
                            }}
                            value={selectedOwner}
                            onChange={handleProjectOwnerChange}
                          >
                           
                            <MenuItem value="">Select Project Owner</MenuItem>
                            {projectOwnerList &&
                              projectOwnerList.length > 0 &&
                              projectOwnerList.map((owner, index) => (
                                <MenuItem
                                  key={index}
                                  value={owner.projectOwnerId}
                                >
                                  {owner.ownerName}
                                </MenuItem>
                              ))}
                          </Select>
                        
                          {errors.selectedOwner && (
                            <Box style={{ color: "red" }}>
                              {errors.selectedOwner}
                            </Box>
                          )}
                        </FormControl>
                      ) : (
                        <Box className="viewValue" style={{ margin: "10px" }}>
                          {modalContent.workLocationType
                            ? modalContent.workLocationType
                            : "Office"}
                        </Box>
                      )}
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={5.5}
                      md={5.5}
                      lg={5.5}
                      sx={{ mt: 2 }}
                    >
                      <Box className="label" style={{ color: "#002C5D" }}>
                        Number of Hours <span style={{ color: "red" }}>*</span>
                      </Box>
                      {isEditMode && modalContent.openMode == "Edit" ? (
                        <input
                          id="hours"
                          name="hours"
                          //  className="modalInput"
                          variant="outlined"
                          fullWidth
                          style={{
                            border: "1.5px solid #B2D4F8",
                            width: "97%",
                            height: "45px",
                            borderRadius: "5px",
                            paddingLeft: "10px",
                          }}
                          value={hours}
                          // onChange={(event) => setHours(event.target.value)}
                          onChange={handleHoursChange}
                          placeholder=""
                          type="number"
                          min="0"
                          max="24"
                          step="0.5"
                        />
                      ) : (
                        <Box className="viewValue" style={{ margin: "10px" }}>
                          {modalContent.hours}
                        </Box>
                      )}
                      {errors.hours && (
                        <Box style={{ color: "red" }}>{errors.hours}</Box>
                      )}
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={5.5}
                      md={5.5}
                      lg={5.5}
                      sx={{ mt: 2 }}
                    >
                      <Box className="label" style={{ color: "#002C5D" }}>
                        {" "}
                        Work Arrangement <span style={{ color: "red" }}>*</span>
                      </Box>
                      {isEditMode && modalContent.openMode == "Edit" ? (
                        <FormControl
                          sx={{
                            width: "100%",
                          }}
                        >
                          <Select
                            id="workLocationType"
                            name="workLocationType"
                            sx={{
                              padding: "0px 0px",
                              border: "1px solid #B2D4F8",
                              height: "48px",
                            }}
                            value={modalContent.workLocationType || "Office"}
                            onChange={(e) => {
                              handleWorkArrangementChange(
                                modalContent.projectName,
                                modalContent.taskName,
                                modalContent.day,
                                e.target
                              );
                            }}
                          >
                            <MenuItem value={"Office"}>Office</MenuItem>
                            <MenuItem value={"Home"}>Home</MenuItem>
                          </Select>
                        </FormControl>
                      ) : (
                        <Box className="viewValue" style={{ margin: "10px" }}>
                          {modalContent.workLocationType
                            ? modalContent.workLocationType
                            : "Office"}
                        </Box>
                      )}
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={5.5}
                      md={5.5}
                      lg={5.5}
                      sx={{ mt: 2 }}
                    >
                      <Box className="label" style={{ color: "#002C5D" }}>
                        {" "}
                        Shift <span style={{ color: "red" }}>*</span>
                      </Box>
                      {isEditMode && modalContent.openMode == "Edit" ? (
                        <FormControl sx={{ width: "100%" }}>
                          <Select
                            id="workLocationType"
                            name="workLocationType"
                            sx={{
                              padding: "0px 0px",
                              border: "1px solid #B2D4F8",
                              height: "48px",
                            }}
                            displayEmpty
                            defaultValue=""
                            value={selectedShift}
                            onChange={handleProjectShiftChange}
                          >
                            <MenuItem value="">Select Shift</MenuItem>
                            {projectShiftsList &&
                              projectShiftsList.length > 0 &&
                              projectShiftsList.map((shift, index) => (
                                <MenuItem
                                  key={index}
                                  value={shift.projectShiftId}
                                >
                                  {shift.shiftName}
                                </MenuItem>
                              ))}
                          </Select>
                          {errors.selectedShift && (
                            <Box style={{ color: "red" }}>
                              {errors.selectedShift}
                            </Box>
                          )}
                        </FormControl>
                      ) : (
                        <Box className="viewValue" style={{ margin: "10px" }}>
                          {modalContent.workLocationType
                            ? modalContent.workLocationType
                            : "Office"}
                        </Box>
                      )}
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sm={5.5}
                      md={5.5}
                      lg={5.5}
                      sx={{ mt: 2 }}
                    >
                      <Box className="label" style={{ color: "#002C5D" }}>
                        Description<span style={{ color: "red" }}>*</span>
                      </Box>
                      {isEditMode && modalContent.openMode == "Edit" ? (
                        <textarea
                          id="description"
                          name="description"
                          //  className="modalTextarea"
                          //  style={{width:'95%'}}
                          placeholder="Type the task description"
                          variant="outlined"
                          fullWidth
                          style={{
                            width: "98%",
                            // border: "1.5px solid #B2D4F8",
                            // borderRadius: "5px",
                            paddingLeft: "10px",
                            paddingTop: "10px",
                            fontFamily: "Inter",
                            color: "black",
                          }}
                          value={description}
                          onChange={(event) => {
                            setDescription(event.target.value);
                            setErrors({ ...errors, description: "" });
                          }}
                          multiline
                          rows={4}
                          maxRows={50}
                        />
                      ) : (
                        <Box className="viewValue" style={{ margin: "10px" }}>
                          {description}
                        </Box>
                      )}
                      {errors.description && (
                        <Box style={{ color: "red" }}>{errors.description}</Box>
                      )}
                    </Grid>
                    {isEditMode && modalContent.openMode == "Edit" && (
                      <Grid
                        container
                        spacing={1}
                        sx={{ pl: 6, pr: 5, mt: 5, justifyContent: "center" }}
                      >
                        <Grid item xs={12} sm={2} md={2} lg={2}>
                          <Button
                            className="cancelButton"
                            onClick={handleCancel}
                          >
                            Cancel
                          </Button>
                        </Grid>
                        <Grid item xs={12} sm={2} md={2} lg={2}>
                          <Button
                            className="saveButton"
                            onClick={handleaddsave}
                          >
                            Save
                          </Button>
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                  {/* </TabPanel> */}
                  {/* </TabContext> */}
                </Box>
              )}
            </Box>
          </Modal>

          <Modal
            open={isDeleteConfirmOpen}
            onClose={handleCloseConfirm}
          // aria-labelledby="confirm-modal-title"
          // aria-describedby="confirm-modal-description"
          >
            <Box
              sx={style}
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                bgcolor: "background.paper",
                boxShadow: 24,
                paddingLeft: 15,
                paddingTop: 0,
                paddingBottom: 15,
                minHeight: "150px",
                maxHeight: "200px",
                overflowY: "auto",
                borderRadius: "10px",
                width: "25%",
              }}
            >
              <h2 className="ConfirmDeletion">Confirm Deletion</h2>
              <p id="confirm-modal-description">
                Are you sure you want to delete this row?
              </p>
              <Box
                sx={{ display: "flex", gap: "3%", mt: 3, justifyContent: "center" }}
              >
                <Button
                  variant="contained"
                  // color="secondary"
                  onClick={handleCloseConfirm}
                  style={{ color: "#FE602f", border: "1px solid #FE602F", borderRadius: "6px", backgroundColor: "white" }}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  // color="primary"
                  onClick={handleConfirmDelete}
                  style={{ color: "White", border: "none", borderRadius: "6px", backgroundColor: "#FE602F" }}
                >
                  Yes
                </Button>
              </Box>
            </Box>
          </Modal>

          <Modal
            open={isLeaveModalOpen}
            onClose={closeLeaveModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style} style={{ height: "400px", overflowY: "auto" }}>
              <IconButton
                aria-label="close"
                onClick={closeLeaveModal}
                sx={{
                  position: "absolute",
                  right: 0,
                  top: 0,
                  color: "inherit",
                  zIndex: 1,
                }}
              >
                <CloseIcon />
              </IconButton>
              <Grid container sx={{ mt: 2, mb: 2 }}>
                <Grid item xs={12} sm={9} md={9} lg={9}>
                  <Grid container xs={12} sm={12} md={12} lg={12}>
                    <Grid item xs={12} sm={4} md={4} lg={4}>
                      <div className="tsheads">Leave Type</div>
                      <div className="tsbods">
                        {modalLeaveContent.leaveType == "sick"
                          ? "Sick Leave"
                          : "Privilege Leave"}
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={4} md={4} lg={4}>
                      <div className="tsheads">Leave Date</div>
                      <div className="tsbods">
                        {moment(modalLeaveContent.day).format("DD-MMM-YYYY")}
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={3}
                  md={3}
                  lg={3}
                  style={{ textAlign: "right" }}
                >
                  {!isLeaveEditMode &&
                    (approvestatus === "New" ||
                      approvestatus === "Draft" ||
                      approvestatus === "Rejected") ? (
                    <Button className="copybutton" onClick={handleLeaveEdit}>
                      Edit
                    </Button>
                  ) : (
                    <Box
                      style={{
                        color: "red",
                        fontWeight: "700",
                        fontSize: "16px",
                        textAlign: "center",
                      }}
                    >
                      {/* {approvestatus} */}
                    </Box>
                  )}
                </Grid>
              </Grid>
              <Grid container sx={{ mt: 2, mb: 2 }}>
                <Grid item xs={12} sm={12} md={12} lg={12} sx={{ mt: 2 }}>
                  <Box className="label" style={{ color: "#002C5D" }}>
                    Shift Type <span style={{ color: "red" }}>*</span>
                  </Box>
                  {isLeaveEditMode && modalLeaveContent.openMode == "Edit" ? (
                    <select
                      id="hours"
                      name="hours"
                      className="modalSelect"
                      fullWidth
                      onChange={(e) => {
                        if (modalLeaveContent.leaveType == "sick") {
                          handleSickLeaveChange(
                            modalLeaveContent.day,
                            e.target
                          );
                        }
                        if (modalLeaveContent.leaveType == "pl") {
                          handlePtoLeaveChange(modalLeaveContent.day, e.target);
                        }
                      }}
                    >
                      <option
                        value="0"
                        selected={
                          modalLeaveContent.hours == "0" ? "selected" : ""
                        }
                      >
                        {" "}
                        -- None --{" "}
                      </option>
                      <option
                        value="0.5"
                        selected={
                          modalLeaveContent.hours == "0.5" ? "selected" : ""
                        }
                      >
                        {" "}
                        Half Day{" "}
                      </option>
                      <option
                        value="1"
                        selected={
                          modalLeaveContent.hours == "1" ? "selected" : ""
                        }
                      >
                        {" "}
                        Full Day{" "}
                      </option>
                    </select>
                  ) : (
                    // <input
                    //   id="hours"
                    //   name="hours"
                    //   className="modalInput"
                    //   variant="outlined"
                    //   fullWidth
                    //   value={modalLeaveContent.hours}
                    //   onChange={(e) => {
                    //     if(modalLeaveContent.leaveType=='sick'){
                    //       handleSickLeaveChange(
                    //         modalLeaveContent.day,
                    //         e.target
                    //       );
                    //     }
                    //     if(modalLeaveContent.leaveType=='pl'){
                    //       handlePtoLeaveChange(
                    //         modalLeaveContent.day,
                    //         e.target
                    //       );
                    //     }
                    //   }}
                    //   type="number"
                    //   min="0"
                    //   max="1"
                    //   step="0.5"
                    // />
                    <Box className="viewValue" style={{ margin: "10px" }}>
                      {modalLeaveContent.hours == "0.5"
                        ? "Half Day"
                        : modalLeaveContent.hours == "1"
                          ? "Full Day"
                          : "N/A"}
                    </Box>
                  )}
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} sx={{ mt: 2 }}>
                  <Box className="label" style={{ color: "#002C5D" }}>
                    Reason for Sick/Privilege Leave{" "}
                    <span style={{ color: "red" }}>*</span>
                  </Box>
                  {isLeaveEditMode && modalLeaveContent.openMode == "Edit" ? (
                    <textarea
                      id="reason"
                      name="reason"
                      className="modalTextarea"
                      placeholder="Type the leave reason"
                      variant="outlined"
                      fullWidth
                      value={modalLeaveContent.reason}
                      onChange={(e) => {
                        handleReasonChange(
                          modalLeaveContent.leaveType,
                          modalLeaveContent.day,
                          e.target
                        );
                      }}
                      multiline
                      rows={4}
                      maxRows={10}
                    />
                  ) : (
                    <Box className="viewValue" style={{ margin: "10px" }}>
                      {modalLeaveContent.reason
                        ? modalLeaveContent.reason
                        : "N/A"}
                    </Box>
                  )}
                </Grid>
                {isLeaveEditMode && modalLeaveContent.openMode == "Edit" && (
                  <Grid
                    container
                    spacing={1}
                    sx={{ pl: 6, pr: 5, mt: 5, justifyContent: "center" }}
                  >
                    <Grid item xs={12} sm={2} md={2} lg={2}>
                      <Button
                        className="cancelButton"
                        onClick={handleLeaveCancel}
                      >
                        Cancel
                      </Button>
                    </Grid>
                    <Grid item xs={12} sm={2} md={2} lg={2}>
                      <Button
                        className="saveButton"
                        onClick={(e) =>
                          // handleSave
                          submitDraftTimeSheet("leave")
                        }
                      >
                        Save
                      </Button>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Box>
          </Modal>
        </div>
      ) : (
        <TimeSheetTable />
      )}

      {isCreateEditedTask && (
        <CreateEditedTask
          editModalContent={editModalContent}
          closeModal={closeEditModalBox}
        />
      )}
    </div>
  );
};

export default Timesheet;
