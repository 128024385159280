import React, { useState } from 'react';
import Grid from "@mui/material/Grid";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import VisibilityIcon from '@mui/icons-material/Visibility';

import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment'

import "./Configurationpage.css";
import Paper from '@mui/material/Paper';

import IconButton from '@mui/material/IconButton';

import Box from '@mui/material/Box';


import Pagination from '@mui/material/Pagination';


import { useNavigate } from 'react-router-dom';



const EmployeeRoster = () => {

    const navigate = useNavigate()

    const handleViewClick = (id) => {
        if (id === 1) {
            navigate("/panel/TitlePage");
        } else if (id === 2) {

            navigate("/panel/WorkLocation");
        } else if (id === 3) {
            navigate("/panel/Gender");
        } else if (id === 4) {
            navigate("/panel/Certificate");
        } else if (id === 5) {
            navigate("/panel/Document");
        } else if (id === 6) {
            navigate("/panel/Roles");
        } else if (id === 7) {
            navigate("/panel/EmploymentType");
        } else if (id === 8) {
            navigate("/panel/Country");
        } else if (id === 9) {
            navigate("/panel/YearsOFExperience");
        } else if (id === 10) {
            navigate("/panel/States");
        } else if (id === 11) {
            navigate("/panel/Cities");
        } else if (id === 12) {
            navigate("/panel/Degree");
        } else if (id === 13) {
            navigate("/panel/BloodGroup");
        } else if (id === 14) {
            navigate("/panel/MaritalStatus");
        } else if (id === 15) {
            navigate("/panel/Designations");
        } else if (id === 16) {
            navigate("/panel/Relationship");
        } else if (id === 17) {
            navigate("/panel/Departments");
        } else if (id === 18) {
            navigate("/panel/Banks");
        } else if (id === 19) {
            navigate("/panel/BankBranches");
        } else if (id === 20) {
            navigate("/panel/projectType");
        } else if (id === 21) {
            navigate("/panel/Settings");
        } else if (id === 22) {
            navigate("/panel/Permissions");
        } else if(id === 23) {
            navigate("/panel/Holidays")
        }

    };


    const [employees, setEmployees] = useState([
        { id: 1, name: 'Title' },

        { id: 2, name: 'Work Location' },
        { id: 3, name: 'Gender' },
        { id: 4, name: 'Certificate' },
        { id: 5, name: 'Document' },
        { id: 6, name: 'Roles' },

        { id: 7, name: 'Employment Type' },
        { id: 8, name: 'Country' },
        { id: 9, name: 'Years Of Experiance' },

        { id: 10, name: 'States' },
        { id: 11, name: 'Cities' },
        { id: 12, name: 'Degree' },

        { id: 13, name: 'Blood Group' },
        { id: 14, name: 'Marital Status' },
        { id: 15, name: 'Designations' },

        { id: 16, name: 'Relationship' },
        { id: 17, name: 'Departments' },
        { id: 18, name: 'Banks' },
        { id: 19, name: 'Banks Branch' },
        { id: 20, name: 'Project Type' },
        { id: 21, name: 'Settings' },
        { id: 22, name: 'Permissions' },
        {id:23, name:'Holidays'}
    ]);

    const [searchValue, setSearchValue] = useState("");
    const [filteredEmployees, setFilteredEmployees] = useState([...employees]);

    const handleSearchConfigOptions = (e) => {
        const searchInput = e.target.value.toLowerCase();
        setSearchValue(searchInput);
        const filtered = employees.filter(employee => employee.name.toLowerCase().includes(searchInput));
        setFilteredEmployees(filtered);
    }




    const [page, setPage] = useState(1);
    const itemsPerPage = 10;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    }



    return (
        <React.Fragment>
            <div style={{ backgroundColor: "white" }}>
                <Grid style={{
                    marginBottom: "2%"
                }}>
                    <Grid container>

                        <Grid container alignItems="center" item xl={6} lg={6} md={6} sm={12} xs={12}>


                            <Box sx={{ pl: 10 }} style={{
                                color: "#0056B2",
                            }}><h2>Config Options</h2></Box>
                        </Grid>



                        <Grid style={{ alignSelf: 'center', justifyContent: "center" }} sx={{ pl: 2 }} item xl={2} lg={2} md={2} sm={12} xs={12} >

                        </Grid>

                        <Grid style={{ alignSelf: 'center' }} sx={{ pl: 3 }} item xl={3.5} lg={3.5} md={3.5} sm={12} xs={12}>
                            <TextField
                                className="editvalue"
                                value={searchValue}
                                onChange={handleSearchConfigOptions}
                                sx={{ width: '85%', mt: 3 }}
                                label="Search tableName"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton>
                                                <SearchIcon onClick={handleSearchConfigOptions} />
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                                InputLabelProps={{
                                    shrink: true, // To keep label from floating upwards when focused or filled
                                    style: { paddingTop: '8px', fontSize: '1rem', fontWeight: 600, color: '#333' } // Adjust padding and other styles
                                }}

                            />

                        </Grid>



                    </Grid>

                </Grid>
                <Grid container  >
                    <Grid style={{ alignSelf: 'center' }} item xl={4} lg={4} md={4} sm={12} xs={12}>
                    </Grid>
                    <Grid style={{ alignSelf: 'center' }} sx={{ pl: 3 }} item xl={4} lg={4} md={4} sm={12} xs={12}>

                    </Grid>
                    <Grid style={{ alignSelf: 'center', justifyContent: "center" }} sx={{ pl: 2 }} item xl={2} lg={2} md={2} sm={12} xs={12} >

                    </Grid>
                </Grid>


                <TableContainer component={Paper}>
                    <Table aria-label="collapsible table">
                        <TableHead>
                            <TableRow>
                                <TableCell><b className="tablecellforemployeeRoster1"> S.No</b></TableCell>
                                <TableCell><b className="tablecellforemployeeRoster1">Table Name</b></TableCell>
                                <TableCell><b className="tablecellforemployeeRoster1">Action</b></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody  >

                            {(filteredEmployees.slice((page - 1) * itemsPerPage, page * itemsPerPage)).map((employee, index) => (

                                <TableRow key={index} >
                                    <TableCell style={{ padding: '20px', paddingLeft: '90px' }}>{(page - 1) * itemsPerPage + index + 1}</TableCell>

                                    <TableCell style={{ padding: '20px', paddingLeft: '90px' }}>{employee.name}</TableCell>
                                    <TableCell style={{ padding: '20px', paddingLeft: '90px' }}>
                                        <IconButton aria-label="view" style={{ color: '#E97000' }} onClick={() => handleViewClick(employee.id)}>
                                            <VisibilityIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>


                </TableContainer>

                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>

                    <Pagination
                        count={Math.ceil(employees.length / itemsPerPage)}
                        page={page}
                        onChange={handleChangePage}
                        color="primary"
                    />
                </Box>

            </div>
        </React.Fragment>
    )
}

export default EmployeeRoster;