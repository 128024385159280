// npm packages
import React, { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import * as moment from "moment";

// custom pages
import "./EditAddEmployee.css";
import Context from "context/context";
import Loader from "components/Loader";
import FileHandler from "apis/panel/fileHandler/fileHandler";
import getAllDegreesAPIdrop from "apis/panel/Education/education";
import postApi from "apis/panel/Education/education";
import getcreatefindone from "apis/panel/AddEmployee/addEmployee";
import ReusableFunction from "utils/reusablefunction";

// custom images
import personalInfo from "assets/images/Capa_1 (1).png";

// material ui
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { InputAdornment, IconButton } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import ClearIcon from "@mui/icons-material/Clear";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";

const EditEducationInfo = () => {
  let params = useParams();
  const employeeId = params.id;
  const { dispatch } = useContext(Context);
  const [isEditMode, setIsEditMode] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [degree, setDegree] = useState("");
  const [degreeid, setDegreeid] = useState("");
  const [otherDegree, setOtherDegree] = useState("");
  const [degreeOptions, setDegreeOptions] = useState([]);
  const [Specialization, setSpecialization] = useState("");
  const [InstituitionName, setInstituitionName] = useState("");
  const [GraduationDate, setGraduationDate] = useState(null);
  const [SSCmarksheet, setSSCmarksheet] = useState(null);
  const [hscMarksheet, sethscMarksheet] = useState(null);
  const [ugConsolidated, setugConsolidated] = useState(null);
  const [ugDegree, setugDegree] = useState(null);
  const [pgConsolidated, setpgConsolidated] = useState(null);
  const [pgDegree, setpgDegree] = useState(null);
  const [PassportPhoto, setPassportPhoto] = useState(null);
  const [loader2, setLoader2] = useState(false);

  const { PostEducationApi } = postApi();
  const { getEmployeefindone } = getcreatefindone();
  const { getAllDegreesAPI } = getAllDegreesAPIdrop();

  const [degreeError, setDegreeError] = useState(false);
  const [specializationError, setSpecializationError] = useState(false);
  const [instituitionNameError, setInstituitionNameError] = useState(false);
  const [graduationDateError, setGraduationDateError] = useState(false);
  const [SSCmarksheetError, setSSCmarksheetError] = useState(false);
  const [hscMarksheetError, setHscMarksheetError] = useState(false);
  const [ugConsolidatedError, setUgConsolidatedError] = useState(false);
  const [ugDegreeError, setUgDegreeError] = useState(false);
  const [pgConsolidatedError, setPgConsolidatedError] = useState(false);
  const [pgDegreeError, setPgDegreeError] = useState(false);
  const [passportPhotoError, setPassportPhotoError] = useState(false);
  const { fileUploadAPI, fileDownloadAPI } = FileHandler();
  const { handleFileInputReselect } = ReusableFunction();

  const toggleEditMode = (event) => {
    event.stopPropagation();
    setIsEditMode(!isEditMode);
  };

  const handleAccordionChange = (event, isExpanded) => {
    setExpanded(isExpanded);
    if (!isExpanded) {
      // Accordion is not expanded, reset edit mode
      setIsEditMode(false);
    }
  };

  const getFilenameFromUrl = (url) => {
    if (url !== "" && url !== undefined && url !== null) {
      const parts = url.split("/");
      return parts[parts.length - 1];
    } else {
      return "";
    }
  };

  const handleCreateLeave = async (e) => {
    const values = {
      relID: employeeId,
    };
    const data = await getEmployeefindone(values);
    setDegree(data?.data?.education?.degrees?.degreeName);
    setDegreeid(data?.data?.education?.degrees?.degreeId);
    setSpecialization(data?.data?.education?.specialisation);
    setInstituitionName(data?.data?.education?.institutionName);
    if (data?.data?.education === null) {
      setGraduationDate(null);
    } else {
      setGraduationDate(data?.data?.education?.graduationDate);
    }
    let certificates = data?.data?.certificate;
    for (let index = 0; index < certificates?.length; index++) {
      const element = certificates[index];
      if (element.certificates.certificateCode === "SSC") {
        setSSCmarksheet(element?.filePath);
      }
      if (element.certificates.certificateCode === "HSC") {
        sethscMarksheet(element?.filePath);
      }
      if (element.certificates.certificateCode === "UG_CONSOLIDATED") {
        setugConsolidated(element?.filePath);
      }
      if (element.certificates.certificateCode === "UG_DEGREE") {
        setugDegree(element?.filePath);
      }
      if (element.certificates.certificateCode === "PG_CONSOLIDATED") {
        setpgConsolidated(element?.filePath);
      }
      if (element.certificates.certificateCode === "PG_DEGREE") {
        setpgDegree(element?.filePath);
      }
      if (element.certificates.certificateCode === "PHOTO") {
        setPassportPhoto(element?.filePath);
      } else {
        setPassportPhoto(data?.data?.profileImageFilePath);
      }
    }

    if (PassportPhoto === null) {
      setPassportPhoto(data?.data?.profileImageFilePath);
    }
  };

  const handleGetAllDegreesApi = async () => {
    try {
      let res = await getAllDegreesAPI();
      setDegreeOptions(res?.data || []);
    } catch (err) {}
  };

  useEffect(() => {
    handleGetAllDegreesApi();
    handleCreateLeave();
  }, []);

  const handledegreeChange = (e) => {
    const selecteddegree = degreeOptions.find(
      (option) => option.degreeName === e.target.value
    );
    setDegree(selecteddegree.degreeName); // Set the titleName
    setDegreeid(selecteddegree.degreeId); // Set the titleId
  };

  const handleFileUpload = async (file, type) => {
    const maxSize = 1024 * 1024 * 5;
    if (file.size > maxSize) {
      toast.error("File size exceeds the limit (5MB)", {
        toastId: "file-upload-maximum",
      });
      return false;
    }
    try {
      dispatch({ type: "SET_LOADING", payload: true });
      let input = new FormData();
      if (type === "PROFILE") {
        input.append("type", "employee_photos");
      } else {
        input.append("type", "employee_certificates");
      }
      input.append("employeeId", employeeId);
      input.append("file", file);
      const res = await fileUploadAPI(input);
      dispatch({ type: "SET_LOADING", payload: false });
      if (res?.data?.location) {
        return res?.data?.location;
      }
      return false;
    } catch (err) {
      dispatch({ type: "SET_LOADING", payload: false });
      console.log({ err });
    }
  };

  const handleFileDownload = async (id) => {
    try {
      dispatch({ type: "SET_LOADING", payload: true });
      await fileDownloadAPI(id);
      dispatch({ type: "SET_LOADING", payload: false });
    } catch (error) {
      dispatch({ type: "SET_LOADING", payload: false });
      console.error("Error downloading file:", error);
    }
  };

  const handleFileChangeSSC = async (event) => {
    const file = event.target.files[0];
    const location = await handleFileUpload(file, "CERTIFICATE");
    if (location) setSSCmarksheet(location);
  };
  const handleFileChangeHSC = async (event) => {
    const file = event.target.files[0];

    const location = await handleFileUpload(file, "CERTIFICATE");
    if (location) sethscMarksheet(location);
  };
  const handleFileChangeUg = async (event) => {
    const file = event.target.files[0];
    const location = await handleFileUpload(file, "CERTIFICATE");
    if (location) setugConsolidated(location);
  };
  const handleFileChangeUgDegree = async (event) => {
    const file = event.target.files[0];

    const location = await handleFileUpload(file, "CERTIFICATE");
    if (location) setugDegree(location);
  };
  const handleFileChangePG = async (event) => {
    const file = event.target.files[0];

    const location = await handleFileUpload(file, "CERTIFICATE");
    if (location) setpgConsolidated(location);
  };
  const handleFileChangePgDegree = async (event) => {
    const file = event.target.files[0];

    const location = await handleFileUpload(file, "CERTIFICATE");
    if (location) setpgDegree(location);
  };
  const handleFileChangePassportPhoto = async (event) => {
    const file = event.target.files[0];

    const location = await handleFileUpload(file, "PROFILE");
    if (location) setPassportPhoto(location);
  };

  const handleSave = async () => {
    setLoader2(true);

    let isError = false;

    if (!degree) {
      setDegreeError(true);
      isError = true;
    } else {
      setDegreeError(false);
    }

    if (!Specialization) {
      setSpecializationError(true);
      isError = true;
    } else {
      setSpecializationError(false);
    }

    if (!InstituitionName) {
      setInstituitionNameError(true);
      isError = true;
    } else {
      setInstituitionNameError(false);
    }

    if (!GraduationDate) {
      setGraduationDateError(true);
      isError = true;
    } else {
      setGraduationDateError(false);
    }

    if (!SSCmarksheet) {
      setSSCmarksheetError(true);
      isError = true;
    } else {
      setSSCmarksheetError(false);
    }

    if (!hscMarksheet) {
      setHscMarksheetError(true);
      isError = true;
    } else {
      setHscMarksheetError(false);
    }

    // if (!ugConsolidated) {
    //   setUgConsolidatedError(true);
    //   isError = true;
    // } else {
    //   setUgConsolidatedError(false);
    // }

    // if (!ugDegree) {
    //   setUgDegreeError(true);
    //   isError = true;
    // } else {
    //   setUgDegreeError(false);
    // }

    // if (!pgConsolidated) {
    //   setPgConsolidatedError(true);
    //   isError = true;
    // } else {
    //   setPgConsolidatedError(false);
    // }

    // if (!pgDegree) {
    //   setPgDegreeError(true);
    //   isError = true;
    // } else {
    //   setPgDegreeError(false);
    // }

    if (!PassportPhoto) {
      setPassportPhotoError(true);
      isError = true;
    } else {
      setPassportPhotoError(false);
    }

    if (isError) {
      setLoader2(false);
      return;
    }

    if (SSCmarksheet && hscMarksheet && PassportPhoto) {
      let s3 = `https://cvhs-hrms.s3.amazonaws.com/employee_certificates/${employeeId}`;
      let body = {
        employeeId: employeeId,
        degreeId: degreeid,
        specialisation: Specialization,
        institutionName: InstituitionName,
        graduationDate: GraduationDate,
        ssc: SSCmarksheet,
        hsc: hscMarksheet,
        ugConsolidated: ugConsolidated,
        ugDegree: ugDegree,
        pgConsolidated: pgConsolidated,
        pgDegree: pgDegree,
        photo: PassportPhoto,
      };

      const data = await PostEducationApi(body);
      if (data.status === "Ok") {
        setLoader2(false);
        return toast.success("Data Saved Successfully", {
          toast: "Data Saved Successfully",
        });
      } else {
        setLoader2(false);
        return toast.error("Error saving Data", { toast: "Error saving Data" });
      }
    } else {
    }
  };

  const [value, setValue] = useState("");
  const modifiedValue = moment(moment(value, "DD/MM/YYYY"), "MM-DD-YYYY");

  const maxDate = new Date();

  const handleClearsscFile = () => {
    setSSCmarksheet(null);
  };

  const handleClearhscFile = () => {
    sethscMarksheet(null);
  };

  const handleClearugconFile = () => {
    setugConsolidated(null);
  };

  const handleClearugFile = () => {
    setugDegree(null);
  };

  const handleClearpgconFile = () => {
    setpgConsolidated(null);
  };

  const handleClearpgFile = () => {
    setpgDegree(null);
  };

  const handleClearpassportFile = () => {
    setPassportPhoto(null);
  };

  return (
    <div>
      <Loader loading={loader2} />

      <Accordion
        sx={{ mt: 2, boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}
        onChange={handleAccordionChange}
      >
        <AccordionSummary
          expandIcon={<ArrowDropDownIcon className="Accordianicon" />}
          aria-controls="panel1-content"
          id="panel1-header"
          style={{
            boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
            height: "70px",
          }}
        >
          <Box sx={{ pl: 3 }}>
            {" "}
            <img
              sx={{ pl: 3 }}
              className="editpersonalInfo-logo"
              src={personalInfo}
              alt="info"
            />
          </Box>
          <Box className="editacc-title" sx={{ pl: 3 }}>
            Education Information
          </Box>
          <Box sx={{ ml: 40 }}>
            {expanded && (
              <button className="editButton" onClick={toggleEditMode}>
                Edit
                <Box sx={{ flexGrow: 1 }} />
                {isEditMode}
              </button>
            )}
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2} sx={{ pl: 4, pr: 5, mt: 2 }}>
            <Grid container spacing={2} sx={{ pl: 4, pr: 5, mt: 2 }}>
              <Box className="subHeadingforinfos">UG Degree</Box>
            </Grid>
          </Grid>

          <Grid container spacing={2} sx={{ pl: 4, pr: 5, mt: 2 }}>
            {isEditMode ? (
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <Box className="label">
                  Degree <span style={{ color: "red" }}>*</span>
                </Box>
                <FormControl fullWidth>
                  {/* <InputLabel id="marital-status-label">Country</InputLabel> */}
                  <Select
                    labelId="marital-status-label"
                    id="maritalStatus"
                    defaultValue=""
                    value={degree}
                    onChange={handledegreeChange}
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                    style={{ border: "1.5px solid #B2D4F8", color: "grey" }}
                    //   label="Marital Status"
                  >
                    <MenuItem value="">Select Degree</MenuItem>
                    {degreeOptions.map((option) => (
                      <MenuItem key={option.id} value={option.degreeName}>
                        {option.degreeName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {degreeError && (
                  <div className="error-message">Please select a degree</div>
                )}

                {degree === "Others" && (
                  <TextField
                    id="otherDegree"
                    placeholder="Enter Degree"
                    variant="outlined"
                    fullWidth
                    sx={{ mt: 1 }}
                    value={otherDegree}
                    onChange={(e) => setOtherDegree(e.target.value)}
                  />
                )}
              </Grid>
            ) : (
              <Grid item xs={12} sm={4} md={4} lg={4} sx={{ mt: 2 }}>
                <Box className="editlabel">Degree</Box>
                <Box className="editvalue">{degree}</Box>
              </Grid>
            )}

            {isEditMode ? (
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <Box className="label">
                  {" "}
                  Specialization <span style={{ color: "red" }}>*</span>
                </Box>
                <TextField
                  id="Specialization"
                  placeholder="Enter Specialization"
                  variant="outlined"
                  value={Specialization}
                  onChange={(e) => setSpecialization(e.target.value)}
                  fullWidth
                  //  value={specialisation}
                />
                {specializationError && (
                  <div className="error-message">
                    Please enter specialization
                  </div>
                )}
              </Grid>
            ) : (
              <Grid item xs={12} sm={4} md={4} lg={4} sx={{ mt: 2 }}>
                <Box className="editlabel"> Specialization</Box>
                <Box className="editvalue">{Specialization}</Box>
              </Grid>
            )}

            {isEditMode ? (
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <Box className="label">
                  {" "}
                  Instituition Name <span style={{ color: "red" }}>*</span>
                </Box>
                <TextField
                  id="firstName"
                  placeholder="Enter Instituition"
                  value={InstituitionName}
                  onChange={(e) => setInstituitionName(e.target.value)}
                  variant="outlined"
                  fullWidth
                />
                {instituitionNameError && (
                  <div className="error-message">
                    Please enter institution name
                  </div>
                )}
              </Grid>
            ) : (
              <Grid item xs={12} sm={4} md={4} lg={4} sx={{ mt: 2 }}>
                <Box className="editlabel"> Instituition Name</Box>
                <Box className="editvalue">{InstituitionName}</Box>
              </Grid>
            )}

            {isEditMode ? (
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <Box className="label">
                  {" "}
                  Graduation Date <span style={{ color: "red" }}>*</span>
                </Box>
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  sx={{ width: "100%" }}
                >
                  <DatePicker
                    sx={{ width: "100%" }}
                    fullWidth
                    value={GraduationDate ? new Date(GraduationDate) : ""}
                    onChange={(date) => {
                      setGraduationDate(date);
                    }}
                    slotProps={{
                      field: { clearable: true },
                    }}
                    maxDate={maxDate}
                    format="dd/MM/yyyy"
                    renderInput={(params) => (
                      <TextField {...params} helperText={null} />
                    )}
                  />
                </LocalizationProvider>
                {graduationDateError && (
                  <div className="error-message">
                    Please enter graduationDate
                  </div>
                )}
              </Grid>
            ) : (
              <Grid item xs={12} sm={6} md={6} lg={6} sx={{ mt: 1 }}>
                <Box className="editlabel"> Graduation Date</Box>
                <Box className="editvalue">
                  {GraduationDate
                    ? moment(GraduationDate).format("DD/MM/YYYY")
                    : ""}
                </Box>
              </Grid>
            )}
          </Grid>

          <Grid container spacing={2} sx={{ pl: 4, pr: 5, mt: 2 }}>
            <Grid item xs={12} sm={4} md={4} lg={4} sx={{ mt: 2 }}>
              <Box className="subHeadingforinfos">Certificates</Box>
            </Grid>
          </Grid>

          <Grid container spacing={2} sx={{ pl: 4, pr: 5, pb: 5, mt: 2 }}>
            {isEditMode ? (
              <Grid item xs={12} sm={6} md={6} lg={6} sx={{ mt: 2 }}>
                <Box className="label">
                  SSC Mark Sheet (JPEG/PNG/JPG/PDF/DOC){" "}
                  <span style={{ color: "red" }}>*</span>
                </Box>
                <TextField
                  className="editvalue"
                  fullWidth
                  // variant="outlined"
                  // defaultValue={SSCmarksheet}
                  placeholder="Browse Files (max of 5mb) "
                  value={getFilenameFromUrl(SSCmarksheet)}
                  // value={SSCmarksheet}

                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {SSCmarksheet && (
                          <IconButton
                            onClick={handleClearsscFile}
                            edge="end"
                            aria-label="clear file"
                          >
                            <ClearIcon />
                          </IconButton>
                        )}
                        <input
                          type="file"
                          accept=".pdf,.doc,.docx"
                          id="file-upload"
                          style={{ display: "none" }}
                          onClick={handleFileInputReselect}
                          onChange={handleFileChangeSSC}
                        />
                        <label htmlFor="file-upload">
                          <IconButton
                            component="span"
                            color="primary"
                            aria-label="attach file"
                          >
                            <AttachFileIcon style={{ color: "#0056B2" }} />
                          </IconButton>
                        </label>
                      </InputAdornment>
                    ),
                  }}
                />
                {SSCmarksheetError && (
                  <div className="error-message">
                    Please upload SSC mark sheet
                  </div>
                )}
              </Grid>
            ) : (
              <Grid item xs={12} sm={4} md={4} lg={4} sx={{ mt: 2 }}>
                <Box className="editlabel">SSC Mark Sheet </Box>
                {SSCmarksheet ? (
                  <span
                    onClick={() => {
                      handleFileDownload(SSCmarksheet);
                    }}
                    className="download"
                  >
                    <DownloadForOfflineIcon
                      style={{ fontSize: 30, verticalAlign: "middle" }}
                    />{" "}
                    {"Click to download"}
                  </span>
                ) : (
                  <Box className="editvalue"> -</Box>
                )}
              </Grid>
            )}
            {isEditMode ? (
              <Grid item xs={12} sm={6} md={6} lg={6} sx={{ mt: 2 }}>
                <Box className="label">
                  HSC Mark Sheet (JPEG/PNG/JPG/PDF/DOC){" "}
                  <span style={{ color: "red" }}>*</span>
                </Box>
                <TextField
                  fullWidth
                  variant="outlined"
                  placeholder="Browse Files (max of 5mb) doc/pdf/jpg/jpeg/png"
                  //   label="Brow"
                  type="text"
                  // defaultValue={hscMarksheet}
                  value={getFilenameFromUrl(hscMarksheet)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {hscMarksheet && (
                          <IconButton
                            onClick={handleClearhscFile}
                            edge="end"
                            aria-label="clear file"
                          >
                            <ClearIcon />
                          </IconButton>
                        )}
                        <input
                          type="file"
                          accept=".pdf,.doc,.docx"
                          id="file-upload-1"
                          style={{ display: "none" }}
                          onClick={handleFileInputReselect}
                          onChange={handleFileChangeHSC}
                        />
                        <label htmlFor="file-upload-1">
                          <IconButton
                            component="span"
                            color="primary"
                            aria-label="attach file"
                          >
                            <AttachFileIcon style={{ color: "#0056B2" }} />
                          </IconButton>
                        </label>
                      </InputAdornment>
                    ),
                  }}
                />
                {hscMarksheetError && (
                  <div className="error-message">
                    Please upload HSC mark sheet
                  </div>
                )}
              </Grid>
            ) : (
              <Grid item xs={12} sm={4} md={4} lg={4} sx={{ mt: 2 }}>
                <Box className="editlabel">HSC Mark Sheet</Box>
                {hscMarksheet ? (
                  <span
                    onClick={() => {
                      handleFileDownload(hscMarksheet);
                    }}
                    className="download"
                  >
                    <DownloadForOfflineIcon
                      style={{ fontSize: 30, verticalAlign: "middle" }}
                    />{" "}
                    {"Click to download"}
                  </span>
                ) : (
                  <Box className="editvalue"> -</Box>
                )}
              </Grid>
            )}

            {isEditMode ? (
              <Grid item xs={12} sm={6} md={6} lg={6} sx={{ mt: 2 }}>
                <Box className="label">
                  UG Consolidated Mark Sheet (JPEG/PNG/JPG/PDF/DOC)
                </Box>
                <TextField
                  className="editvalue"
                  fullWidth
                  // variant="outlined"
                  placeholder="Browse Files (max of 5mb) "
                  // defaultValue={ugConsolidated}
                  value={getFilenameFromUrl(ugConsolidated)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {ugConsolidated && (
                          <IconButton
                            onClick={handleClearugconFile}
                            edge="end"
                            aria-label="clear file"
                          >
                            <ClearIcon />
                          </IconButton>
                        )}

                        <input
                          type="file"
                          accept=".pdf,.doc,.docx"
                          id="file-upload-2"
                          style={{ display: "none" }}
                          onClick={handleFileInputReselect}
                          onChange={handleFileChangeUg}
                        />
                        <label htmlFor="file-upload-2">
                          <IconButton
                            component="span"
                            color="primary"
                            aria-label="attach file"
                          >
                            <AttachFileIcon style={{ color: "#0056B2" }} />
                          </IconButton>
                        </label>
                      </InputAdornment>
                    ),
                  }}
                />
                {/* {ugConsolidatedError && <div className="error-message">Please upload  ugConsolidated Mark sheet</div>} */}
              </Grid>
            ) : (
              <Grid item xs={12} sm={4} md={4} lg={4} sx={{ mt: 2 }}>
                <Box className="editlabel">UG Consolidated Mark Sheet</Box>
                {ugConsolidated ? (
                  <span
                    onClick={() => {
                      handleFileDownload(ugConsolidated);
                    }}
                    className="download"
                  >
                    <DownloadForOfflineIcon
                      style={{ fontSize: 30, verticalAlign: "middle" }}
                    />{" "}
                    {"Click to download"}
                  </span>
                ) : (
                  <Box className="editvalue"> -</Box>
                )}
              </Grid>
            )}

            {isEditMode ? (
              <Grid item xs={12} sm={6} md={6} lg={6} sx={{ mt: 2 }}>
                <Box className="label">
                  UG Degree Certificate (JPEG/PNG/JPG/PDF/DOC)
                </Box>
                <TextField
                  className="editvalue"
                  fullWidth
                  // variant="outlined"
                  placeholder="Browse Files (max of 5mb) "
                  // defaultValue={ugDegree}
                  value={getFilenameFromUrl(ugDegree)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {ugDegree && (
                          <IconButton
                            onClick={handleClearugFile}
                            edge="end"
                            aria-label="clear file"
                          >
                            <ClearIcon />
                          </IconButton>
                        )}
                        <input
                          type="file"
                          accept=".pdf,.doc,.docx"
                          id="file-upload-3"
                          style={{ display: "none" }}
                          onClick={handleFileInputReselect}
                          onChange={handleFileChangeUgDegree}
                        />
                        <label htmlFor="file-upload-3">
                          <IconButton
                            component="span"
                            color="primary"
                            aria-label="attach file"
                          >
                            <AttachFileIcon style={{ color: "#0056B2" }} />
                          </IconButton>
                        </label>
                      </InputAdornment>
                    ),
                  }}
                />
                {/* {ugDegreeError && <div className="error-message">Please upload  ugDegree Mark sheet</div>} */}
              </Grid>
            ) : (
              <Grid item xs={12} sm={4} md={4} lg={4} sx={{ mt: 2 }}>
                <Box className="editlabel">UG Degree Certificate</Box>
                {ugDegree ? (
                  <span
                    onClick={() => {
                      handleFileDownload(ugDegree);
                    }}
                    className="download"
                  >
                    <DownloadForOfflineIcon
                      style={{ fontSize: 30, verticalAlign: "middle" }}
                    />{" "}
                    {"Click to download"}
                  </span>
                ) : (
                  <Box className="editvalue"> -</Box>
                )}
              </Grid>
            )}

            {isEditMode ? (
              <Grid item xs={12} sm={6} md={6} lg={6} sx={{ mt: 2 }}>
                <Box className="label">
                  PG Consolidated Mark Sheet (JPEG/PNG/JPG/PDF/DOC)
                </Box>
                <TextField
                  className="editvalue"
                  fullWidth
                  // variant="outlined"
                  placeholder="Browse Files (max of 5mb) "
                  // defaultValue={pgConsolidated}
                  value={getFilenameFromUrl(pgConsolidated)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {pgConsolidated && (
                          <IconButton
                            onClick={handleClearpgconFile}
                            edge="end"
                            aria-label="clear file"
                          >
                            <ClearIcon />
                          </IconButton>
                        )}
                        <input
                          type="file"
                          accept=".pdf,.doc,.docx"
                          id="file-upload-4"
                          style={{ display: "none" }}
                          onClick={handleFileInputReselect}
                          onChange={handleFileChangePG}
                        />
                        <label htmlFor="file-upload-4">
                          <IconButton
                            component="span"
                            color="primary"
                            aria-label="attach file"
                          >
                            <AttachFileIcon style={{ color: "#0056B2" }} />
                          </IconButton>
                        </label>
                      </InputAdornment>
                    ),
                  }}
                />
                {/* {pgConsolidatedError && <div className="error-message">Please upload pgConsolidated  Mark sheet</div>} */}
              </Grid>
            ) : (
              <Grid item xs={12} sm={4} md={4} lg={4} sx={{ mt: 2 }}>
                <Box className="editlabel">PG Consolidated Mark Sheet</Box>
                {pgConsolidated ? (
                  <span
                    onClick={() => {
                      handleFileDownload(pgConsolidated);
                    }}
                    className="download"
                  >
                    <DownloadForOfflineIcon
                      style={{ fontSize: 30, verticalAlign: "middle" }}
                    />{" "}
                    {"Click to download"}
                  </span>
                ) : (
                  <Box className="editvalue"> -</Box>
                )}
              </Grid>
            )}
            {isEditMode ? (
              <Grid item xs={12} sm={6} md={6} lg={6} sx={{ mt: 2 }}>
                <Box className="label">
                  PG Degree Certificate (JPEG/PNG/JPG/PDF/DOC)
                </Box>
                <TextField
                  className="editvalue"
                  fullWidth
                  // variant="outlined"

                  placeholder="Browse Files (max of 5mb) "
                  // defaultValue={pgDegree}
                  value={getFilenameFromUrl(pgDegree)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {pgDegree && (
                          <IconButton
                            onClick={handleClearpgFile}
                            edge="end"
                            aria-label="clear file"
                          >
                            <ClearIcon />
                          </IconButton>
                        )}
                        <input
                          type="file"
                          accept=".pdf,.doc,.docx"
                          id="file-upload-5"
                          style={{ display: "none" }}
                          onClick={handleFileInputReselect}
                          onChange={handleFileChangePgDegree}
                        />
                        <label htmlFor="file-upload-5">
                          <IconButton
                            component="span"
                            color="primary"
                            aria-label="attach file"
                          >
                            <AttachFileIcon style={{ color: "#0056B2" }} />
                          </IconButton>
                        </label>
                      </InputAdornment>
                    ),
                  }}
                />
                {/* {pgDegreeError && <div className="error-message">Please upload pgDegree  Mark sheet</div>} */}
              </Grid>
            ) : (
              <Grid item xs={12} sm={4} md={4} lg={4} sx={{ mt: 2 }}>
                <Box className="editlabel">PG Degree Certificate</Box>
                {pgDegree ? (
                  <span
                    onClick={() => {
                      handleFileDownload(pgDegree);
                    }}
                    className="download"
                  >
                    <DownloadForOfflineIcon
                      style={{ fontSize: 30, verticalAlign: "middle" }}
                    />{" "}
                    {"Click to download"}
                  </span>
                ) : (
                  <Box className="editvalue"> -</Box>
                )}
              </Grid>
            )}

            {isEditMode ? (
              <Grid item xs={12} sm={6} md={6} lg={6} sx={{ mt: 2 }}>
                <Box className="label">
                  Passport Size Photographs (JPEG, PNG, PDF){" "}
                  <span style={{ color: "red" }}>*</span>
                </Box>
                <TextField
                  className="editvalue"
                  fullWidth
                  // variant="outlined"
                  placeholder="Browse Files (max of 5mb) "
                  // defaultValue={PassportPhoto}
                  value={getFilenameFromUrl(PassportPhoto)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {PassportPhoto && (
                          <IconButton
                            onClick={handleClearpassportFile}
                            edge="end"
                            aria-label="clear file"
                          >
                            <ClearIcon />
                          </IconButton>
                        )}
                        <input
                          type="file"
                          accept=".jpg,.jpeg,.png"
                          id="file-upload-other"
                          style={{ display: "none" }}
                          onClick={handleFileInputReselect}
                          onChange={handleFileChangePassportPhoto}
                        />
                        <label htmlFor="file-upload-other">
                          <IconButton
                            component="span"
                            color="primary"
                            aria-label="attach file"
                          >
                            <AttachFileIcon style={{ color: "#0056B2" }} />
                          </IconButton>
                        </label>
                      </InputAdornment>
                    ),
                  }}
                />
                {passportPhotoError && (
                  <div className="error-message">
                    Please upload passportPhoto
                  </div>
                )}
              </Grid>
            ) : (
              <Grid item xs={12} sm={4} md={4} lg={4} sx={{ mt: 2 }}>
                <Box className="editlabel">Passport Size Photographs</Box>
                {PassportPhoto ? (
                  <span
                    onClick={() => {
                      handleFileDownload(PassportPhoto);
                    }}
                    className="download"
                  >
                    <DownloadForOfflineIcon
                      style={{ fontSize: 30, verticalAlign: "middle" }}
                    />{" "}
                    {"Click to download"}
                  </span>
                ) : (
                  <Box className="editvalue"> -</Box>
                )}
              </Grid>
            )}
          </Grid>

          {isEditMode && (
            <Grid
              container
              spacing={1}
              sx={{ pl: 4, pr: 5, mt: 5, justifyContent: "center" }}
            >
              <Grid item xs={12} sm={2} md={2} lg={2}>
                <Button className="cancelButton" onClick={toggleEditMode}>
                  Cancel
                </Button>
              </Grid>

              <Grid item xs={12} sm={2} md={2} lg={2}>
                <Button className="saveButton" onClick={handleSave}>
                  Save
                </Button>
              </Grid>
            </Grid>
          )}
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default EditEducationInfo;
