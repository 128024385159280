import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toastCss } from "config/config";
import { toast } from "react-toastify";

import "./Project.css";
import Loader from "components/Loader";
import ProjectAPI from "apis/panel/project/project";

import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import {
  FormControl,
  IconButton,
  MenuItem,
  Modal,
  TextField,
  Typography,
  Select,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Pagination from "@mui/material/Pagination";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";

const CustomWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 500,
    cursor: "pointer",
    backgroundColor: "#002c5d",
    color: "#ffffff",
  },
});

const ProjectOwnerTable = ({ projectId }) => {
  const navigate = useNavigate();
  const [loader2, setLoader2] = useState(false);
  const [addOwnerPopup, setAddOwnerPopup] = useState(false);
  const [editOwnerPopup, setEditOwnerPopup] = useState(false);
  const [ownerList, setOwnerList] = useState([]);
  const [count, setCount] = useState(0);
  const [offset, setOffset] = useState(1);
  const [load, setLoad] = useState(false);
  const [newOwnerObj, setNewOwnerObj] = useState({
    projectOwnerId: "",
    ownerName: "",
    status: "Active",
    isDefault: false,
  });
  const [editOwnerObj, setEditOwnerObj] = useState({});
  const [defaultOwner, setDefaultOwner] = useState({});

  const {
    getAllOwnerDetailsAPI,
    createProjectOwnerAPI,
    updateProjectOwnerAPI,
  } = ProjectAPI();

  const handleOpenAddNewOwnerPopup = () => {
    setAddOwnerPopup(true);
  };

  const handleOpenEditOwnerPopup = (owner) => {
    setNewOwnerObj({
      projectOwnerId: owner.projectOwnerId,
      ownerName: owner.ownerName,
      status: owner.status,
      isDefault: owner.isDefault,
    });
    setEditOwnerObj({
      projectOwnerId: owner.projectOwnerId,
    });
    setAddOwnerPopup(true);
    setEditOwnerPopup(true);
  };

  const handleCloseAddPopUp = () => {
    setNewOwnerObj({
      projectOwnerId: "",
      ownerName: "",
      status: "Active",
      isDefault: false,
    });
    setEditOwnerObj({});
    setAddOwnerPopup(false);
    setEditOwnerPopup(false);
  };

  const handleAddNewOwner = async () => {
    try {
      if (newOwnerObj.ownerName === "") {
        return toast.error("Please fill in all required fields.");
      }

      if (newOwnerObj.status === "Inactive" && newOwnerObj.isDefault) {
        return toast.error("The default project owner record cannot be deactivated.",{
        toastId: "default-inactive-error",
        })
      }

      if (!newOwnerObj?.projectOwnerId) {
        const values = {
          projectId,
          ownerName: newOwnerObj?.ownerName,
          status: newOwnerObj?.status,
          isDefault: newOwnerObj?.isDefault,
        };
        if (values?.isDefault && Object.keys(defaultOwner)?.length > 0) {
          return toast.error(
            `Already default owner is assigned for the Project Owner - "${defaultOwner?.ownerName}"`,
            { toastId: "default-Inactive-error" }
          );

        
        }

        setLoader2(true);
        const res = await createProjectOwnerAPI(values);
        setLoader2(false);
        if (res.status === "Ok") {
          toast.success("Record created successfully.", toastCss);
          setLoad((prev) => !prev); // Toggle load state to trigger useEffect
        } else {
          return toast.error(res.error.message, toastCss);
        }
      } else {
        if (editOwnerObj.ownerName === "") {
          return toast.error("Please change atleast one field.");
        }

        let values = { projectOwnerId: newOwnerObj?.projectOwnerId };
        if (editOwnerObj?.ownerName)
          values = { ...values, ownerName: newOwnerObj?.ownerName };

        if (editOwnerObj?.status)
          values = { ...values, status: newOwnerObj?.status };

        if (typeof editOwnerObj?.isDefault === "boolean") {
          values = { ...values, isDefault: newOwnerObj?.isDefault };

          if (values?.isDefault && Object.keys(defaultOwner)?.length > 0) {
            return toast.error(
              `Already default owner is assigned for the Project Owner - "${defaultOwner?.ownerName}"`,
              { toastId: "default-owner-error" }
            );
          }
        }
        setLoader2(true);
        const res = await updateProjectOwnerAPI(values);
        setLoader2(false);
        if (res.status === "Ok") {
          toast.success("Record updated successfully.", toastCss);
          setLoad((prev) => !prev); // Toggle load state to trigger useEffect
        } else {
          return toast.error(res.error.message, toastCss);
        }
      }

      handleCloseAddPopUp();
    } catch (error) {
      setLoader2(false);
    }
  };

  const getAllOwnerDetails = async () => {
    try {
      const values = {
        offset: (offset - 1) * 10,
        limit: 10,
        projectId,
      };
      setLoader2(true);
      const res = await getAllOwnerDetailsAPI(values);
      setOwnerList(res?.data?.data || []);
      setCount(res?.data?.count);
      setDefaultOwner(res?.data?.defaultOwner);
      setLoader2(false);
    } catch (err) {
      setLoader2(false);
    }
  };

  const handlePagination = (value) => {
    setOffset(value);
  };

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    padding: "15px", // Adjust the padding as needed
  }));

  const StyledTableCellBody = styled(TableCell)(({ theme }) => ({
    padding: "10px", // Set a fixed height for the cell
    paddingLeft: "15px",
  }));

  useEffect(() => {
    getAllOwnerDetails();
  }, [projectId, offset, load]);

  return (
    <div className="ProjectCont">
      <Loader loading={loader2} />
      <Grid
        container
        className="editsetupmaincont"
        sx={{ pl: 3, pr: 3, mt: 2, mb: 1 }}
      >
        <Box className="addnewprojecthead">Project Owners</Box>
        <Box>
          <Button
            className="projsetupeditOwner"
            onClick={handleOpenAddNewOwnerPopup}
          >
            Add New Owner
          </Button>
        </Box>
      </Grid>
      <Grid container spacing={2} sx={{ pt: 1, pl: 4, pr: 3, mt: 2 }}>
        <TableContainer
          component={Paper}
          sx={{ boxShadow: "none", border: 1, borderColor: "grey.300" }}
        >
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <StyledTableCell>
                  <b className="projectOwnertable-header">Project Owner Name</b>
                </StyledTableCell>
                <StyledTableCell>
                  <b className="projectOwnertable-header">Status</b>
                </StyledTableCell>
                <StyledTableCell>
                  <b className="projectOwnertable-header">Default</b>
                </StyledTableCell>
                <StyledTableCell>
                  <b className="projectOwnertable-header">Action</b>
                </StyledTableCell>
              </TableRow>
            </TableHead>

            {ownerList && ownerList.length > 0 ? (
              ownerList.map((owner) => (
                <TableBody>
                  <TableRow key={owner?.projectOwnerId}>
                    <StyledTableCellBody>
                      <b className="projectOwnerTable-body">
                        {owner?.ownerName}
                      </b>
                    </StyledTableCellBody>
                    <StyledTableCellBody>
                      <b className="projectOwnerTable-body">
                        {owner?.status === "Active" ? (
                          <span style={{ color: "green", fontWeight: "bold" }}>
                            {owner?.status}
                          </span>
                        ) : (
                          <span style={{ color: "red", fontWeight: "bold" }}>
                            {owner?.status}
                          </span>
                        )}
                      </b>
                    </StyledTableCellBody>
                    <StyledTableCellBody>
                      <b className="projectOwnerTable-body">
                        <Checkbox
                          checked={owner?.isDefault}
                          sx={{
                            color: "rgb(0, 44, 93)",
                            "&.Mui-checked": {
                              color: "rgb(0, 44, 93)",
                            },
                            cursor: "inherit",
                          }}
                        />
                      </b>
                    </StyledTableCellBody>
                    <StyledTableCellBody>
                      <b className="projectOwnerTable-body">
                        <CustomWidthTooltip title="Click to edit details">
                          <IconButton
                            style={{ color: "rgb(0, 121, 233)", height: "2px" }}
                            onClick={() => handleOpenEditOwnerPopup(owner)}
                          >
                            <EditOutlinedIcon />
                          </IconButton>
                        </CustomWidthTooltip>
                      </b>
                    </StyledTableCellBody>
                  </TableRow>
                </TableBody>
              ))
            ) : (
              <TableRow>
                <StyledTableCellBody
                  colSpan={6}
                  style={{
                    textAlign: "center",
                    fontSize: "16px",
                    color: "grey",
                  }}
                >
                  No Data Found
                </StyledTableCellBody>
              </TableRow>
            )}
          </Table>
        </TableContainer>
      </Grid>

      <Modal
        open={addOwnerPopup}
        onClose={handleCloseAddPopUp}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            minWidth: "40%",
            bgcolor: "background.paper",
            boxShadow: 24,
            borderRadius: "10px",
            p: 4,
          }}
        >
          <IconButton
            aria-label="close"
            onClick={handleCloseAddPopUp}
            sx={{
              position: "absolute",
              top: 0,
              right: 0,
              color: "inherit",
              zIndex: 1,
            }}
          >
            <CloseIcon />
          </IconButton>


          <Grid container alignItems="center">
            <Grid item xs>
              <Typography
                variant="h6"
                style={{ color: "#0070E9", fontWeight: "bold" }}
              >
                {editOwnerPopup ? "Edit Project Owner" : "Add Project Owner"}
              </Typography>
            </Grid>
            <Grid item>

            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={6} sx={{ mt: 1 }}>
              <Box className="addProjectOwnerPopup">
                Project Owner Name <span style={{ color: "red" }}>*</span>
              </Box>
              <TextField
                className="editvalue"
                placeholder="Enter Project Owner Name"
                value={newOwnerObj?.ownerName}
                onChange={(e) => {
                  setNewOwnerObj({
                    ...newOwnerObj,
                    ownerName: e.target.value,
                  });
                  if (editOwnerPopup)
                    setEditOwnerObj({
                      ...editOwnerObj,
                      ownerName: e.target.value,
                    });
                }}
                sx={{ width: "100%" }}
              />
            </Grid>
            <Grid item xs={12} lg={6} sx={{ mt: 1 }}>
              <Box className="addProjectOwnerPopup">
                Status <span style={{ color: "red" }}>*</span>
              </Box>
              <FormControl sx={{ width: "100%" }}>
                <Select
                  value={newOwnerObj?.status}
                  onChange={(e) => {
                    setNewOwnerObj({
                      ...newOwnerObj,
                      status: e.target.value,
                    });
                    if (editOwnerPopup)
                      setEditOwnerObj({
                        ...editOwnerObj,
                        status: e.target.value,
                      });
                  }}
                  style={{ color: "grey" }}
                >
                  <MenuItem value="Active">Active</MenuItem>
                  <MenuItem value="Inactive">Inactive</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} lg={6} sx={{ mt: 1 }}>
              <Box className="addProjectOwnerPopup">
                Default{" "}
                {/* <Checkbox
                  inputProps={{ "aria-label": "controlled" }}
                  checked={newOwnerObj?.isDefault}
                  sx={{
                    color: "rgb(0, 44, 93)",
                    "&.Mui-checked": {
                      color: "rgb(0, 44, 93)",
                    },
                    "& .MuiSvgIcon-root": { fontSize: 28 },
                  }}
                  onChange={(e) => {
                    setNewOwnerObj({
                      ...newOwnerObj,
                      isDefault: !newOwnerObj?.isDefault,
                    });
                    if (editOwnerPopup)
                      setEditOwnerObj({
                        ...editOwnerObj,
                        isDefault: !newOwnerObj?.isDefault,
                      });
                  }}
                /> */}
                <Checkbox
  inputProps={{ "aria-label": "controlled" }}
  checked={newOwnerObj?.isDefault}
  sx={{
    color: "rgb(0, 44, 93)",
    "&.Mui-checked": {
      color: "rgb(0, 44, 93)",
    },
    "& .MuiSvgIcon-root": { fontSize: 28 },
  }}
  onChange={(e) => {
    if (newOwnerObj.status === "Inactive") {
      toast.error("An inactive record cannot be set as default.",{
      toastId: "default-owners-error"
      });
    } else {
      setNewOwnerObj({
        ...newOwnerObj,
        isDefault: !newOwnerObj?.isDefault,
      });
      if (editOwnerPopup)
        setEditOwnerObj({
          ...editOwnerObj,
          isDefault: !newOwnerObj?.isDefault,
        });
    }
  }}
/>
              </Box>
            </Grid>
          </Grid>
          <Grid container spacing={1} sx={{ pl: 2, pr: 1, mt: 2 }}>
            <Grid item xs={12} sm={3}>
              <Button className="cancelButton" onClick={handleCloseAddPopUp}>
                Cancel
              </Button>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Button className="saveButton" onClick={handleAddNewOwner}>
                Save
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>

      {ownerList && ownerList.length > 0 && (
        <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
          <Pagination
            count={Math.ceil(count / 10)}
            page={offset}
            siblingCount={1}
            onChange={(event, value) => handlePagination(value)}
            color="primary"
          />
        </Box>
      )}
    </div>
  );
};

export default ProjectOwnerTable;
