import { Fragment, useState, useEffect, useContext } from "react";
import context from "context/context";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

export default function Dashboard() {
  

  return (
    <Fragment>
      <Card variant="outlined" sx={{ padding: "15px" }}>
        <Typography variant="h6" component="h2">
          Dashboard
        </Typography>
        <Grid container spacing={2} mt={2} >
          <Grid item xs={12}>

            

          </Grid>
        </Grid>
      </Card>
    </Fragment>
  );
}

