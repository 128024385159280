import React from 'react';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Button from '@mui/material/Button';
import Paper from "@mui/material/Paper";




const dummyData = [
  { id: 1, taskName: 'Task 1', hours: 5, arrangements: 'Remote', owner: 'John Doe', shift: 'Morning' },
  { id: 2, taskName: 'Task 2', hours: 8, arrangements: 'On-site', owner: 'Jane Smith', shift: 'Evening' },
  { id: 3, taskName: 'Task 3', hours: 6, arrangements: 'Hybrid', owner: 'Alice Johnson', shift: 'Night' },
];

const timesheettasktable=()=> {


  return (
    <TableContainer component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>S.No</TableCell>
            <TableCell>Task Name</TableCell>
            <TableCell>No. of Hours</TableCell>
            <TableCell>Work Arrangements</TableCell>
            <TableCell>Project Owner</TableCell>
            <TableCell>Shift Name</TableCell>
            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {dummyData.map((row) => (
            <TableRow key={row.id}>
              <TableCell>{row.id}</TableCell>
              <TableCell>{row.taskName}</TableCell>
              <TableCell>{row.hours}</TableCell>
              <TableCell>{row.arrangements}</TableCell>
              <TableCell>{row.owner}</TableCell>
              <TableCell>{row.shift}</TableCell>
              <TableCell>
                <Button variant="contained" color="primary">Edit</Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
export default timesheettasktable;