import React from 'react';
// import { useNavigate } from "react-router-dom";

import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid'; 
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';

import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import { format,parseISO } from 'date-fns';

import moment from "moment"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useNavigate, useParams } from "react-router-dom";


import { useState, useEffect } from "react";


import getAllVraAPIS from "apis/panel/vraShifts/vraShifts.js";

import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import { toast } from "react-toastify";




const VraEdit = () => {
  let params = useParams();
  const vra = params.id;
 
  const [vraId, setVraId]= useState(vra)
 
  const navigate = useNavigate();
  const [employeeId, setEmployeeId] = useState('');
  const [employeeCode,setEmployeeCode]= useState('')
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  
  const [vraRpId, setVraRpId] = useState('');
  const [srfVraName, setSrfVraName] = useState('');
  const [radName,setRadName]=useState('')
  const [srfRadname, setSrfRadname] = useState({});
  const [goLiveDate, setGoLiveDate] = useState(null);
  const [commitmentDate, setCommitmentDate] = useState(null);
  const [dateOfJoining, setDateOfJoining] = useState(null);
  const [engagementType,setEngagementType]= useState(null)
  const [role, setRole] = useState(null);
  const [srfRadNameOptions, setSrfRadNameOptions] = useState([]);
  const [status, setStatus] = useState('');
  const [accordionOpen, setAccordionOpen] = useState(false);

  const handleCancel = () => {
    setAccordionOpen(false); 
  };


  useEffect(()=>{
 handleView()
 handleGetAllSrfRadName()
 

 
  },[])

  const handleGetAllSrfRadName= async()=>{
    try {
      let res= await getAllRadiologistsAPI()
      setSrfRadNameOptions(res?.data?.radiologists || [])
      
      
    } catch (error) {
      console.log(error);
      
    }
  }


  
 const handleSrfRadNameChange = async(e)=>{
  setSrfRadname(e.target.value)
 }

 const handleStatusChange= async(e)=>{
  setStatus(e.target.value)
 }

 const handleEngagementTypeChange= async(e)=>{
  setEngagementType(e.target.value)
 }
  
   const handleView = async() =>{
    let values;
    values = {
      vraEmployeeDetailId: vra
    };
    
    try {
      
      // setLoading(true);
      let res = await getVraEmployeeFindone(values);
      
      
      console.log(res?.data?.employeeDetails
        ,'kk')
      
      setCommitmentDate(res?.data?.employeeDetails?.commitmentDate)
      setDateOfJoining(res?.data?.employeeDetails?.dateOfJoining || null)
      setEmployeeId (res?.data?.employeeDetails?.employeeId)
      setEmployeeCode(res?.data?.employeeDetails?.employeeCode)
      setFirstName(res?.data?.employeeDetails?.firstName)
      setLastName(res?.data?.employeeDetails?.lastName)
      setEngagementType(res?.data?.employeeDetails?.engagementType)
      setVraRpId(res?.data?.employeeDetails?.vraUsername)
      setGoLiveDate(res?.data?.employeeDetails?.deploymentDate)
      setRadName(res?.data?.employeeDetails?.radUsername)
      setStatus(res?.data?.employeeDetails?.status)
      setRole(res?.data?.employeeDetails?.role)
      
          }
          catch (err) {
              
          }
        }
      
        
        

useEffect(()=>{
  handleVraRpIdFind()
},[radName])

// useEffect(()=>{
//   const formattedDate = dateofJoining
//   ? format(parseISO(dateofJoining), 'dd MMM yyyy')
//   : 'Date not available';
//   setDateofJoining(formattedDate)
// },[dateofJoining])




const  handleVraRpIdFind= async()=>{
 
  srfRadNameOptions.map((radId)=>{

    
    if(radId.radUsername==radName){
      setSrfRadname(radId)
    } 
  })
  
  
}

const [errorFields, setErrorFields] = useState({
  employeeId: false,
  firstName: false,
  lastName: false,
  srfVraName: false,
  primaryRadiologist:false,
  vraRpId:false,
  srfRadname:false,

  goLiveDate: false,
  commitmentDate: false,
  trainingstDate: false,
  tainingendDate: false,
  // dateOfJoining: false,
  Role: false,
  certificationDate:false,
  
});






  // api's

  const {getAllRadiologistsAPI,getsrfVraNameAPI,getVraEmployeeFindone} = getAllVraAPIS()
   
    const handleCreateLeave = async (e) => {
        e.preventDefault();
        // Validate form fields
        const errors = {};
        // if (!employeeId) errors.employeeId = true;
        // if (!firstName) errors.firstName = true;
        // if (!lastName) errors.lastName = true;
        // if (!lastName) errors.middleName = true;
        // if (!primaryRadiologist) errors.primaryRadiologist = true;
        // if (!vraRpId) errors.vraRpId = true;
        // if (!srfRadname) errors.srfRadname = true;
        // if (!certificationDate) errors.certificationDate = true;
        // if (!srfVraName) errors.srfVraName = true;
        // if (!goLiveDate) errors.goLiveDate = true;
        // if (!commitmentDate) errors.commitmentDate = true;
      //   if (!trainingstDate) errors.trainingstDate = true;
      //   if (!tainingendDate) errors.tainingendDate = true;
      //   if (!dateofJoining) errors.dateofJoining = true;
      //   if (!Role) errors.Role = true;
      //   if (srfVraName === 'Married' && !srfRadname) {
      //     errors.srfRadname = true;
      // }
  
      
    
        setErrorFields(errors);
    
        if (Object.keys(errors).length === 0) {
        
     
      // const values = {
      //   vraEmployeeDetailId:vra,
      //   radiologistId,
      //   vraUsername:vraRpId,
      //   vraName,
      //   deploymentDate,
      //   commitmentDate,
      //   engagementType,
      //   employeeId: employeeId,
      //   firstName: firstName,
      //   lastName: lastName,
      //   // middleName:middleName,
      //   srfVraNameId: maritalid,
      //   fatherName:primaryRadiologist,
      //   motherName:vraRpId,
      //   srfRadname:srfRadname,
      //   dateofJoining: dateofJoining,
      //   businessEmail: goLiveDate,
      //   personalEmail: trainingstDate,
      //   commitmentDateNumber: commitmentDate,
      //   tainingendDateNumber: tainingendDate,
      //   EngagementType: EngagementType,
      //   status:status
       
      // };
      
      const values={}
      // const data = await getEmployeeCreateAPI(values);

      // if(data.status === 'Ok'){
      //   sessionStorage.setItem('EmployeeID',data.data.employeeId)       
      //   return toast.success("Data Saved Successfully", { toast: "Data Saved Successfully" });
      // }
      // else{
      //   return toast.error("Error: User already exists in the system");
      // }

      navigate("/panel/vraShifts")
 
    }


  };

const handleReset = () => {

  setSrfVraName('')
  
  setVraRpId('')
  setSrfRadname('')
  setGoLiveDate(null)
  setCommitmentDate(null)
 
  setDateOfJoining(null)
  setRole('')
 

}


 
  const handlegoLiveDateChange = (e) => {
    
    // setGoLiveDate(format(e, 'd/M/yyyy'))
    // const value = e.target.value.toLowerCase().replace(/\s/g, '');
    // setGoLiveDate(value);
    // if (!value) {
    //   setErrorFields({ ...errorFields, goLiveDate: true });
    // } else {
    //   setErrorFields({ ...errorFields, goLiveDate: false });
    // }
  };

  const handleCommitmentDateChange = (e) => {
    // console.log(e,'ff');
    
    // setCommitmentDate(e.target.value);
  };
  




const handleRoleChange = (e) => {
  
  setRole(e.target.value);
 
};


  
  
  const maxDate = new Date();
  


  return (
    <div style={{backgroundColor:"white",borderRadius:"20px"}}>
 
      <Grid container spacing={2}  sx={{ pl: 4, pr:5 , mt:1}} >

            <Grid item xs={12} sm={6} md={6} lg={6} sx={{mt:2}}>
                <Box className="label">EmployeeId <span style={{color:"red"}}>*</span></Box>
              <TextField sx={{ border: '1px solid #B2D4F8' }} id="employeeCode" value={employeeCode} name="employeeCode"  placeholder="Last Name" variant="outlined" fullWidth />
              {errorFields.employeeId && <span className="error-msg">EmployeeId is required</span>}
            </Grid>

            <Grid item xs={12} sm={6} md={6} lg={6} sx={{ mt: 2 }}>
              <Box className="label">First Name <span style={{ color: "red" }}>*</span></Box>
              <TextField sx={{ border: '1px solid #B2D4F8' }} id="firstName" value={firstName}
              //  onChange={handleFirstNameChange} 
               placeholder="First Name" variant="outlined" fullWidth />
              {errorFields.firstName && <span className="error-msg" style={{alignItems:"center",display:"flex", gap:"4px"}}><div><ErrorOutlineOutlinedIcon style={{alignItems:"center",alignSelf:"center"}}/> </div> <div>First letter must be capital letter</div></span>}
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6} sx={{ mt: 2 }}>
              <Box className="label">Last Name <span style={{ color: "red" }}>*</span></Box>
              <TextField sx={{ border: '1px solid #B2D4F8' }} id="lastName" value={lastName} name="lastName"
              //  onChange={handleLastNameChange} 
               placeholder="Last Name" variant="outlined" fullWidth />
              {errorFields.lastName && <span className="error-msg" style={{alignItems:"center",display:"flex", gap:"4px"}}><div><ErrorOutlineOutlinedIcon style={{alignItems:"center",alignSelf:"center"}}/> </div> <div>First letter must be capital letter</div></span>}
            </Grid>

      {/* radiologist name */}

            <Grid item xs={12} sm={6} md={6} lg={6} sx={{mt:2}}>
             
            <Box className="label">Primary Radiologist <span style={{color:"red"}}>*</span></Box>
              <TextField sx={{ border: '1px solid #B2D4F8' }} id="primaryRadiologist"  value={srfRadname?.radFormattedName}  name="primaryRadiologist"  
              // onChange={handleFatherNameChange} 
              placeholder="Primary Radiologist" variant="outlined" fullWidth />
              {errorFields.primaryRadiologist && <span className="error-msg" style={{alignItems:"center",display:"flex", gap:"4px"}}><div><ErrorOutlineOutlinedIcon style={{alignItems:"center",alignSelf:"center"}}/> </div> <div>First letter must be capital letter</div></span>}
            </Grid>


            {/* vra rp id */}

            <Grid item xs={12} sm={6} md={6} lg={6} sx={{mt:2}}>
            <Box className="label"> VRA RP ID <span style={{color:"red"}}>*</span></Box>
            
              <TextField sx={{ border: '1px solid #B2D4F8' }} id="primaryRadiologist" value={vraRpId}  name="vraRpId" 
              //  onChange={handleMotherNameChange} 
               placeholder="VRA RP ID" variant="outlined" fullWidth />
              {errorFields.vraRpId && <span className="error-msg" style={{alignItems:"center",display:"flex", gap:"4px"}}><div><ErrorOutlineOutlinedIcon style={{alignItems:"center",alignSelf:"center"}}/> </div> <div>First letter must be capital letter</div></span>}
            </Grid>

            {/* srf vra name */}
        
            <Grid item xs={12} sm={6} md={6} lg={6} sx={{mt:2}}>
            
            <Box className="label">SRF VRA Name <span style={{color:"red"}}>*</span></Box>
            
             
              <TextField sx={{ border: '1px solid #B2D4F8' }} id="srfVraname" value={firstName.concat(lastName)}  name="srfVraname"   placeholder="SRF VRA NAME" variant="outlined" fullWidth   />
              {errorFields.srfVraName && <span className="error-msg">This is required Field</span>}
            </Grid>

      {/* srf rad name */}

            <Grid item xs={12} sm={6} md={6} lg={6} sx={{mt:2}}>
            <Box className="label">SRF RAD Name <span style={{color:"red"}}>*</span></Box>
           {console.log(srfRadname?.radUsername,"srfrad")}
              <FormControl fullWidth>
        <Select
                  labelId="srfRadname"
                  id="srfRadname"
                  defaultValue={srfRadname?.radUsername}
                  // displayEmpty
                  name="srfRadname"
                  value={srfRadname?.radUsername}
                  // sx={{ border: '2px solid #B2D4F8' }}
                  onChange={handleSrfRadNameChange}
                  style={{border:"2px solid #B2D4F8",color:"grey"}}
                  inputProps={{ 'aria-label': 'Without label' }}
                >
          {/* <MenuItem value="">
        Select
      </MenuItem> */}
      {srfRadNameOptions.map((radId) => (
        <MenuItem key={radId.radiologistId} value={radId.radUsername}>
          {radId.radUsername}
        </MenuItem>
      ))}
                </Select>
              </FormControl>
             
              {/* <TextField sx={{ border: '1px solid #B2D4F8' }} id="srfRadname" value={srfRadname}  name="srfRadname"  onChange={handlesrfRadnameChange} placeholder="Srf Rad Name" variant="outlined" fullWidth   /> */}
              {errorFields.srfRadname && <span className="error-msg" style={{alignItems:"center",display:"flex", gap:"4px"}}><div><ErrorOutlineOutlinedIcon style={{alignItems:"center",alignSelf:"center"}}/> </div> <div>First letter must be capital letter</div></span>}
            </Grid>

      {/* Go live date */}

            <Grid item xs={12} sm={6} md={6} lg={6} sx={{mt:2}}>
            <Box className="label">Go-Live Date <span style={{color:"red"}}>*</span></Box>
            <LocalizationProvider  fullWidth dateAdapter={AdapterDateFns}>   
            <DatePicker
             id="goLiveDate"
             name="goLiveDate"
             value={goLiveDate}
             sx={{width:"100%",border:"2px solid #B2D4F8"}}
             onAccept={handlegoLiveDateChange}
             fullWidth
             inputFormat="dd/MM/yyyy"
             slotProps={{
            field: { clearable: true },
          }}
            maxDate={maxDate}
            renderInput={(params) => <TextField {...params} fullWidth />}
             />
             </LocalizationProvider>
              {/* <TextField sx={{ border: '1px solid #B2D4F8' }} id="goLiveDate" value={goLiveDate}  name="goLiveDate"  onChange={handlegoLiveDateChange} placeholder="go-live date" variant="outlined" fullWidth /> */}
              {errorFields.goLiveDate && <span className="error-msg">This is required Field</span>}
            </Grid>

          {/* commitmentDate */}

            <Grid item xs={12} sm={6} md={6} lg={6} sx={{mt:2}}>
            <Box className="label">Commitment Date</Box>
            <LocalizationProvider  fullWidth dateAdapter={AdapterDateFns}>   
            <DatePicker
             id="commitmentDate"
             name="commitmentDate"
             value={commitmentDate}
             sx={{width:"100%",border:"2px solid #B2D4F8"}}
             onAccept={handleCommitmentDateChange}
             fullWidth
             inputFormat="dd/MM/yyyy"
             slotProps={{
            field: { clearable: true },
          }}
            maxDate={maxDate}
            renderInput={(params) => <TextField {...params} fullWidth />}
             />
             </LocalizationProvider>
              {/* <TextField sx={{ border: '1px solid #B2D4F8' }} id="commitmentDate" value={commitmentDate}  name="commitmentDate"  onChange={handleBusinessNumberChange} placeholder="Commitment Date" variant="outlined" fullWidth /> */}
             
            </Grid>

       
            {/* DOJ */}
           
            <Grid item xs={12} sm={6} md={6} lg={6} sx={{mt:2}}>
            <Box className="label">Date of Joining <span style={{color:"red"}}>*</span></Box>
            <TextField sx={{ border: '1px solid #B2D4F8', width:"100%" }} id="primaryRadiologist"  value={moment(dateOfJoining).format("DD-MMM-YYYY")}  name="primaryRadiologist"  />
            </Grid>

            {/* Role */}

            <Grid item xs={12} sm={6} md={6} lg={6} sx={{mt:2}}>
            <Box className="label">Role <span style={{color:"red"}}>*</span></Box>
            <FormControl fullWidth>
            
            <Select
              labelId="role"
              id="role"
              placeholder="Select Date"
              defaultValue=""
              displayEmpty
          
             
              name="Role"
        value={role}
        onChange={handleRoleChange}
              inputProps={{ 'aria-label': 'Without label' }}
                   style={{border:"2px solid #B2D4F8", color:"grey"}}
                >
                       <MenuItem value='null'>
               Select Role
          </MenuItem>
          <MenuItem  value={'VRA'}>Virtual Radiology Assistant</MenuItem>
          <MenuItem  value={'Pod Manager'}>Pod Manager</MenuItem>
       
         
            </Select>
          </FormControl>
          {/* <TextField sx={{ border: '1px solid #B2D4F8' }}id="role" value={Role}  name="role"  onChange={handleRoleChange} placeholder="Role" variant="outlined" fullWidth /> */}
          {errorFields.Role && <span className="error-msg">This is required Field</span>}
            </Grid>

           {/* status */}

            <Grid item xs={12} sm={6} md={6} lg={6} sx={{mt:2}}>
            <Box className="label">Status <span style={{color:"red"}}>*</span></Box>
            <FormControl fullWidth>
            
            <Select
              labelId="status"
              id="status"
              
              defaultValue=""
              displayEmpty
          
             
              name="status"
        value={status}
        onChange={handleStatusChange}
              inputProps={{ 'aria-label': 'Without label' }}
                   style={{border:"2px solid #B2D4F8", color:"grey"}}
                >
                       <MenuItem value="">
               Select Status
          </MenuItem>
          
          <MenuItem  value={'Active'}>Active</MenuItem>
          <MenuItem  value={'Inactive'}>Inactive</MenuItem>
       
            </Select>
          </FormControl>
          {/* <TextField sx={{ border: '1px solid #B2D4F8' }}id="role" value={Role}  name="role"  onChange={handleRoleChange} placeholder="Role" variant="outlined" fullWidth /> */}
          {errorFields.Role && <span className="error-msg">This is required Field</span>}
            </Grid>


            {/* Engagement Types */}

            <Grid item xs={12} sm={6} md={6} lg={6} sx={{mt:2}}>
            <Box className="label">Engagement Type <span style={{color:"red"}}>*</span></Box>
            <FormControl fullWidth>
            
            <Select
              labelId="engagementType"
              id="engagementType"
              placeholder="Select Type"
              defaultValue=""
              displayEmpty
              name="engagementType"
              value={engagementType}
              onChange={handleEngagementTypeChange}
              inputProps={{ 'aria-label': 'Without label' }}
                   style={{border:"2px solid #B2D4F8", color:"grey"}}
                >
                       <MenuItem value='null'>
               Select Engagement Type
          </MenuItem>
          <MenuItem  value={'Part-Time'}>Part Time</MenuItem>
          <MenuItem  value={'Full-Time'}>Full Time</MenuItem>
            </Select>
           </FormControl>
          {/* <TextField sx={{ border: '1px solid #B2D4F8' }}id="role" value={Role}  name="role"  onChange={handleRoleChange} placeholder="Role" variant="outlined" fullWidth /> */}
          {errorFields.Role && <span className="error-msg">This is required Field</span>}
            </Grid> 
  </Grid>
 
<Grid container  sx={{ pl: 4, pr:5, mt:5 , justifyContent:"center"}} >
        <Grid item  sx={{ml:1}} xs={12} sm={3} md={3} lg={3}>
        <Button className="resetChanges" onClick={handleReset}>Reset Changes</Button>
        </Grid>
        <Grid item xs={12} sm={2} md={2} lg={2}>
        <Button className="cancelButton" onClick={handleCancel}>Cancel</Button>
        </Grid>
        <Grid item  sx={{mr:1}} xs={12} sm={2} md={2} lg={2}>
        <Button className="saveButton" onClick = {handleCreateLeave}>Save</Button>
        </Grid>
        </Grid>
    </div>
  )
}

export default VraEdit;
