import React, { useRef, useState, useEffect, useContext } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import "./EditAddEmployee.css"
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import personalInfo from "assets/images/Layer_1 (1).png";
import AttachFileIcon from '@mui/icons-material/AttachFile';
import Context from "context/context";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { InputAdornment, IconButton } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import getemployee from '../../../apis/panel/managerinfo/managerinfo'
import getEmpall from '../../../apis/panel/managerinfo/managerinfo'
import getRel from '../../../apis/panel/managerinfo/managerinfo'
import getcreatefindone from "apis/panel/AddEmployee/addEmployee"
import { toast } from "react-toastify";
import { Email } from '../../../../node_modules/@mui/icons-material/index';

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

const EditManagerInfo = () => {
  const { state, dispatch } = useContext(Context);
  const [roleCode, setRoleCode] = useState(state.role.roleCode);
  let params = useParams();
  const employeeId = params.id;
  const [isEditMode, setIsEditMode] = useState(false);
  const [expanded, setExpanded] = useState(false);

  const { getEmployeeinfo } = getemployee();
  const { getemployeeall } = getEmpall();
  const { getEmployementupdate } = getRel();
  const { getEmployeefindone } = getcreatefindone();

  const [allManagers, setAllManagers] = useState([])
  const [managerId, setManagerId] = useState('');
  const [managerName, setManagerName] = useState('');
  const [managerDesignation, setmanagerDesignation] = useState()
  const [managerEmail, setManagerEmail] = useState('');

  const toggleEditMode = (event) => {
    event.stopPropagation();
    setIsEditMode(!isEditMode);
  };

  useEffect(() => {
    findrelations()
    handleCreateLeave()
  }, [])

  const handleCreateLeave = async (e) => {
    const values = {
      relID: employeeId
    };
    
    const data = await getEmployeefindone(values);
    setManagerName(data?.data?.managers?.firstName + ' ' + data?.data?.managers?.lastName)
    setManagerId(data?.data?.managers?.employeeId)
    setmanagerDesignation(data?.data?.managersDesignations?.designationName)
    setManagerEmail(data?.data?.managers?.businessEmail)
  }

  const findrelations = async () => {
    const data = await getemployeeall();
    setAllManagers(data.data?.employee);
  }

  const handleManagers = async (e) => {


    let managerId = e.target.value;
    setManagerId(managerId)
    const data = await getEmployeeinfo({
      relID: managerId
    });
    setManagerEmail(data?.data?.businessEmail)
    setManagerName(data?.data?.firstName + ' ' + data?.data?.lastName)
    if (data?.data?.designationResult) {
      setmanagerDesignation(data?.data?.designationResult?.designationName)
    }
    else {
      setmanagerDesignation("")
    }



  }

  const handleAccordionChange = (event, isExpanded) => {
    setExpanded(isExpanded);
    if (!isExpanded) {
      // Accordion is not expanded, reset edit mode
      setIsEditMode(false);
    }
  };

  const handleCreatecontact = async () => {
   
    if (managerId == 'null') {
      return toast.error("Select a valid manager ");
    }
    const values = {
      employeeId: employeeId,
      managerId: managerId
    };
    const data = await getEmployementupdate(values);
    if (data?.status === 'Ok') {
      return toast.success("Data Saved Successfully", { toast: "Data Saved Successfully" });
    }
    else {
      return toast.error("Error saving Data", { toast: "Error saving Data" });
    }
  };
 

  return (
    <Accordion sx={{ mt: 2, boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }} onChange={handleAccordionChange}>
      <AccordionSummary
        expandIcon={<ArrowDropDownIcon className="Accordianicon" />}
        aria-controls="panel1-content"
        id="panel1-header"
        style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px", height: "70px" }}
      >
        <Box sx={{ pl: 3 }}> <img sx={{ pl: 3 }} className='editpersonalInfo-logo' src={personalInfo} alt="info" /></Box>
        <Box className="editacc-title" sx={{ pl: 3 }}>Manager Information</Box>
        <Box sx={{ ml: 45 }}>
          {expanded && (roleCode == "R001") && (
            <button className="editButton" onClick={toggleEditMode}>
              Edit
              <Box sx={{ flexGrow: 1 }} />
              {isEditMode}
            </button>
          )}
        </Box>
      </AccordionSummary>
      <AccordionDetails>

        <Grid container spacing={2} sx={{ pl: 4, pr: 5, mt: 2, pb: 5 }} >
          {isEditMode ? (
            <Grid item xs={12} sm={6} md={6} lg={6} sx={{ mt: 2 }}>
              <Box className="label" >Manager Name <span style={{ color: "red" }}>*</span></Box>
              <FormControl fullWidth>
                <Select
                  labelId="marital-status-label"
                  id="maritalStatus"

                  displayEmpty
                  inputProps={{ 'aria-label': 'Without label' }}
                  style={{ border: "1.5px solid #B2D4F8", color: "grey" }}
                  value={managerId}
                  onChange={
                    handleManagers
                  }
                >
                 
                  <MenuItem value={'null'}>
                    Select Manager
                  </MenuItem>

                  {
                    allManagers?.map((manager) => (
                      
                      manager.employeeId !== employeeId && (
                        <MenuItem key={manager.employeeId} value={manager.employeeId}>
                          {manager.firstName + ' ' + manager.lastName}
                        </MenuItem>
                      )
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
          ) : (
            <Grid item xs={12} sm={4} md={4} lg={4} sx={{ mt: 2 }}>
              <Box className="editlabel" >Manager Name</Box>
              <Box className="editvalue">{managerName}</Box>
            </Grid>
          )}
          {isEditMode ? (
            <Grid item xs={12} sm={6} md={6} lg={6} sx={{ mt: 2 }}>
              <Box className="label">Designation</Box>
              <TextField
                className="editvalue"
                value={managerDesignation}
                disabled={true}
                sx={{ width: '100%', border: "1.5px solid  #B2D4F8" }}
              />
            </Grid>
          ) : (
            <Grid item xs={12} sm={4} md={4} lg={4} sx={{ mt: 2 }}>
              <Box className="editlabel" > Designation</Box>
              <Box className="editvalue">{managerDesignation}</Box>
            </Grid>
          )}
          {isEditMode ? (
            <Grid item xs={12} sm={6} md={6} lg={6} sx={{ mt: 2 }}>
              <Box className="label">Email</Box>
              <TextField
                className="editvalue"
                disabled={true}
                value={managerEmail}

                onChange={(e) => setManagerEmail(e.target.value)}
                sx={{ width: '100%', border: "1.5px solid  #B2D4F8" }}
              />
            </Grid>
          ) : (
            <Grid item xs={12} sm={4} md={4} lg={4} sx={{ mt: 2 }}>
              <Box className="editlabel" > Email</Box>
              <Box className="editvalue">{managerEmail}</Box>
            </Grid>
          )}


          {isEditMode && (
            <Grid container spacing={1} sx={{ pl: 4, pr: 5, mt: 5, justifyContent: "center" }} >
              <Grid item xs={12} sm={2} md={2} lg={2}>
                <Button className="cancelButton" onClick={toggleEditMode}>Cancel</Button>
              </Grid>
              <Grid item xs={12} sm={2} md={2} lg={2}>
                <Button className="saveButton" onClick={handleCreatecontact}>
                  Save
                </Button>
              </Grid>
            </Grid>
          )}

        </Grid>
      </AccordionDetails>
    </Accordion>

  )
}

export default EditManagerInfo

