// custom pages
import config from "config/config";
import HandleApiResponse from "utils/handleApiResponse";

const authAPI = () => {
  const { handleResponse, handleError } = HandleApiResponse();

  async function loginAPI(values) {
    try {
      const obj = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify(values),
      };
      let res = await fetch(`${config.host}/auth/login`, obj);
      let body = await res.json();
      return body;
    } catch (err) {}
  }

  async function refreshAPI() {
    try {
      const obj = {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      };
      let res = await fetch(
        `${config.host}/auth/refresh`,
        obj
      );
      return handleResponse(res);
    } catch (err) {
      handleError(err)
    }
  }

  async function logoutAPI() {
    try {
      const obj = {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      };
      let res = await fetch(
        `${config.host}/auth/logout`,
        obj
      );
      let body = await res.json();
      return body;
    } catch (err) {}
  }

  async function changePasswordAPI(values) {
    try {
      const obj = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify(values),
      };
      let res = await fetch(`${config.host}/auth/change_password`, obj);
      let body = await res.json();
      return body;
    } 
    catch (err) {}
  }

  async function loginGoogle(values) {
    try {
      const obj = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify(values),
      };
      let res = await fetch(`${config.host}/auth/google`, obj);
      let body = await res.json();
      return body;
    } catch (err) {}
  }

  async function loginMicrosoft(values) {
    try {
      const obj = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify(values),
      };
      let res = await fetch(`${config.host}/auth/microsoft`, obj);
      let body = await res.json();
      return body;
    } catch (err) {}
  }

  async function resetPasswordLinkAPI(values) {
    try {
      const obj = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify(values),
      };
      let res = await fetch(`${config.host}/auth/reset_password_link`, obj);
      let body = await res.json();
      return body;
    } 
    catch (err) {}
  }

  return {
    loginAPI,
    refreshAPI,
    logoutAPI,
    changePasswordAPI,
    loginGoogle,
    loginMicrosoft,
    resetPasswordLinkAPI
  };
};

export default authAPI;
