// custom pages
import config from "config/config";
import HandleApiResponse from "utils/handleApiResponse";

const addbank = () => {
  const { handleResponse, handleError } = HandleApiResponse();

  async function getAllbankAPI() {
    try {
      const obj = {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      };
      const res = await fetch(`${config.host}/panel/banks/findAll`, obj);
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }

  async function getAllbankbranchesAPI() {
    try {
      const obj = {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      };
      const res = await fetch(`${config.host}/panel/bankbranches/findAll`, obj);
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }

  async function getbankCreateAPI(data) {
    try {
      const obj = {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      };

      let res = await fetch(
        `${config.host}/panel/employees/createBankDetail`,
        obj
      );
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }

  return {
    getAllbankAPI,
    getAllbankbranchesAPI,
    getbankCreateAPI,
  };
};

export default addbank;
