// npm packages
import React, { useState, useEffect, Fragment, useContext } from "react";

// custom pages
import config from "config/config";
import context from "context/context";

// material ui
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";

export default function ManageMyTeam() {
  const { dispatch } = useContext(context);
  return (
    <Fragment>
      <Card variant="outlined" sx={{ padding: "15px" }}>
        <Typography variant="h6" component="h2">
          <b>Manage My Team</b>
        </Typography>
        <Card variant="outlined" sx={{ margin: "10px 0px 10px 0px" }}>
        fsd
      </Card>
      </Card>
    </Fragment>
  );
}
