// npm packages
import React, { useState, useEffect, Fragment, useContext } from "react";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import * as moment from "moment";
import { toast } from "react-toastify";
import Divider from "@mui/material/Divider";

// custom pages
// import {LeaveTypeAPI , LeaveTypeCreateAPI} from "apis/panel/leaveType/leaveType";
import LeaveTypeAPI from "apis/panel/leaveType/leaveType";
import LeaveTypeCreate from "apis/panel/leaveType/leaveType"
import config from "config/config";
import context from "context/context";
import { toastCss, theme } from "config/config";

// material ui
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import VisibilityIcon from '@mui/icons-material/Visibility';
// import MenuItem from '@mui/material/MenuItem';
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Paper from "@mui/material/Paper";

// material ui icons
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  borderRadius: "12px",
  boxShadow: 24,
  p: 4,
};


const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} arrow placement="right" />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}));

export default function ViewLeave() {
  const { dispatch } = useContext(context);
  const { getLeaveTypesAPI } = LeaveTypeAPI();
  const { getLeaveTypeCreateAPI } = LeaveTypeCreate()



  // Create Employee Task
  const [startDate, setStartDate] = useState(new Date());
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [leavetypes, setLeaveTypes] = useState()
  const [createLeaveTypeObj, setCreateLeaveTypeObj] = useState({
    leaveName: "",
    leaveDescription: "",
    status: "",
    leaveTotal: "",
    leaveYear: new Date().getFullYear(),
  });
  const [disableSubmitBtn, setDisableSubmitBtn] = useState(false);
  const [status, setStatus] = useState("");
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());


  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleGetAllLeaveTypes = async (selectedYear) => {
    try {
      let res = await getLeaveTypesAPI(selectedYear);
      setLeaveTypes(res?.data || []);
    } catch (err) {
      console.error(err);
    }
  };


  // const handleCreateLeaveTypeTask = async () => {

  //   try {
  //     if (
  //       !createLeaveTypeObj?.leaveName ||
  //       !createLeaveTypeObj?.leaveDescription ||
  //       !createLeaveTypeObj?.leaveTotal ||
  //       !createLeaveTypeObj?.leaveYear
  //     ) {
  //       setDisableSubmitBtn(false);
  //       return toast.error("Please fill all the required fields.", {
  //         taskId: "create-employee-task-error",
  //       });
  //     }
  //     const leaveTypePayload = {
  //       leaveName: createLeaveTypeObj.leaveName,
  //       leaveDescription: createLeaveTypeObj.leaveDescription,
  //       leaveTotal: createLeaveTypeObj.leaveTotal,
  //       leaveYear: createLeaveTypeObj.leaveYear,
  //       isActive: true, // Assuming isActive is always true
  //     };
  //     const response = await getLeaveTypeCreateAPI(leaveTypePayload);
  //     if (response.status === "success") {
  //       toast.success("Leave Type created successfully");
  //       handleClose();
  //       // You may want to reload the list of leave types after creating one
  //       handleGetAllLeaveTypes();
  //     } else {
  //       toast.error("Failed to create Leave Type");
  //     }
  //   } catch (error) {
  //     console.error("Error creating Leave Type", error);
  //     toast.error("Failed to create Leave Type. Please try again.");
  //   }
  // };

  const handleCreateLeaveTypeTask = async () => {
    try {
      if (
        !createLeaveTypeObj?.leaveName ||
        !createLeaveTypeObj?.leaveDescription ||
        !createLeaveTypeObj?.leaveTotal ||
        !createLeaveTypeObj?.leaveYear ||
        !createLeaveTypeObj?.status
      ) {
        setDisableSubmitBtn(false);
        return toast.error("Please fill all the required fields.", {
          taskId: "create-employee-task-error",
        });
      }
  
      const leaveTypePayload = {
        leaveName: createLeaveTypeObj.leaveName,
        leaveDescription: createLeaveTypeObj.leaveDescription,
        leaveTotal: createLeaveTypeObj.leaveTotal,
        leaveYear: createLeaveTypeObj.leaveYear,
        isActive: createLeaveTypeObj.status, 
      };
  
      const response = await getLeaveTypeCreateAPI(leaveTypePayload);
      if (response && response.status === "SUCCESS") {
        toast.success("Leave Type created successfully");
        handleClose();
        // You may want to reload the list of leave types after creating one
        // handleGetAllLeaveTypes();
      } else {
        toast.error("Failed to create Leave Type");
      }
    } catch (error) {
      console.error("Error creating Leave Type", error);
      toast.error("Failed to create Leave Type. Please try again.");
    }
  };

  
  

  useEffect(() => {
    handleGetAllLeaveTypes();
    // handleCreateLeaveTypeTask()
  }, [])


  return (
    <Fragment>
      <Card variant="outlined" sx={{ padding: "15px" }}>
        <Box sx={{ padding: "15px" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <span style={{ fontSize: 20, fontWeight: "bold" }}> Leave Types</span>
            <Button
              variant="contained"
              onClick={() => {
                handleOpen();
              }}
              sx={{ marginLeft: "auto" }}
            >
              Create
            </Button>
          </Box>
          <div style={{ padding: "10px" }}>

            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DemoContainer components={["DesktopDatePicker"]}>
                <DatePicker
                  label="Year"
                  views={['year']}
                  value={startDate || new Date()}
                  onChange={(date) => {
                    const selectedYear = date.getFullYear();
                  
                  }}
                />
              </DemoContainer>
            </LocalizationProvider>
          </div>

        </Box>

        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <b>Leave Type</b>
                </TableCell>
                <TableCell>
                  <b>Created Date</b>
                </TableCell>
                {/* <TableCell>
                  <b>Created By</b>
                </TableCell> */}
                <TableCell>
                  <b>Total Days</b>
                </TableCell>
                <TableCell>
                  <b>Leaave Description</b>
                </TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {leavetypes && leavetypes.map((data) => (
                <TableRow key={data.leaveTypeId}>
                  <TableCell>
                    {data.leaveName}
                  </TableCell>
                  <TableCell>
                    {moment(data.createdAt).format("YYYY-MM-DD")}
                  </TableCell>
                  {/* <TableCell>
                </TableCell> */}
                  <TableCell>
                    {data.leaveTotal}
                  </TableCell>
                  <TableCell>
                    {data.leaveDescription}
                  </TableCell>

                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <Typography id="transition-modal-title" variant="h6" component="h2">
              Create Leave Types
            </Typography>
            <Box sx={{ marginTop: "20px" }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} lg={6}>
                  <FormControl sx={{ m: 1, width: "100%", margin: "0px 0px 0px 0px" }}>
                    <TextField
                      labelId="balance-leave-label"
                      id="balance-leave"
                      value={createLeaveTypeObj.leaveName}
                      label="Leave Name *"
                      onChange={(e) => {
                        setCreateLeaveTypeObj({
                          ...createLeaveTypeObj,
                          leaveName: e.target.value,
                        });
                      }}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={12} lg={6}>
                  <FormControl sx={{ m: 1, width: "100%", margin: " 0px 0px 0px 0px" }}>
                    <TextField
                      labelId="balance-leave-label"
                      id="balance-leave"
                      value={createLeaveTypeObj.leaveTotal}
                      label="Total Days *"
                      onChange={(e) => {
                        setCreateLeaveTypeObj({
                          ...createLeaveTypeObj,
                          leaveTotal: e.target.value,
                        });
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} lg={6}>
                  <FormControl fullWidth>
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      options={['Active', 'InActive']}
                      value={createLeaveTypeObj.status}
                      onChange={(e, value) => setCreateLeaveTypeObj({ ...createLeaveTypeObj, status: value })}
                      renderInput={(params) => <TextField {...params} label="Status *" />}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} lg={6} >
                  <FormControl sx={{ m: 1, width: "100%", marginLeft: "1px", marginTop: "-8px" }}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DemoContainer components={["DesktopDatePicker"]}>
                        <DatePicker
                          label="Leave Year"
                          views={['year']}
                          sx={{ width: "100%" }}
                          value={startDate || new Date()}
                          onChange={(date) => {
                            // const selectedYear = date.getFullYear();
                            setCreateLeaveTypeObj({
                              ...createLeaveTypeObj,
                              leaveYear: date.getFullYear(),
                            });
                          }}
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                  </FormControl>
                </Grid>


              </Grid>
            </Box>
            <Box sx={{ marginTop: "20px" }}>
              <Grid item xs={12} sm={12} lg={6}>
                <TextField
                  sx={{ width: "100%" }}
                  id="outlined-basic"
                  label=" Leave Description"
                  variant="outlined"
                  required
                  multiline
                  value={createLeaveTypeObj?.description}
                  onChange={(e) => {
                    setCreateLeaveTypeObj({
                      ...createLeaveTypeObj,
                      leaveDescription: e.target.value,
                    });
                  }}
                />
              </Grid>
            </Box>

            <Divider sx={{ margin: "32px 0px" }} />
            <Box sx={{ marginTop: "30px" }}>
              <Grid container spacing={2}>
                <Grid item xs={12} style={{ textAlign: "right" }}>
                  <Button
                    sx={{
                      padding: "8px 20px",
                      borderRadius: "8px",
                      marginRight: "32px",
                      backgroundColor: "#fff",
                      color: config?.theme?.primary?.main,
                      border: `1px solid ${config?.theme?.primary?.main}`,
                      "&:hover": {
                        backgroundColor: "#fff",
                      },
                    }}
                    variant="contained"
                    onClick={() => {
                      handleClose();
                      // setTotalDays(0)
                      setCreateLeaveTypeObj({
                        leaveName: "",
                        leaveDescription: "",
                        status: "",
                        leaveTotal: "",
                        leaveYear:"",
                      });
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    sx={{
                      padding: "8px 20px",
                      borderRadius: "8px",
                    }}
                    variant="contained"
                    disabled={disableSubmitBtn}
                    onClick={() => {
                      setDisableSubmitBtn();
                      handleCreateLeaveTypeTask();
                      // handleCreateLeave();
                    }}
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </Fragment >
  );
}
