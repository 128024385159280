// npm packages
import React, { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

// custom pages
import "./EditAddEmployee.css";
import Context from "context/context";
import FileHandler from "apis/panel/fileHandler/fileHandler";
import getAllbankdrop from "apis/panel/bank/bank";
import getAllbranchesdrop from "apis/panel/bank/bank";
import getAllbankcreate from "apis/panel/bank/bank";
import getcreatefindone from "apis/panel/AddEmployee/addEmployee";
import Loader from "components/Loader";
import ReusableFunction from "utils/reusablefunction";

// custom images
import personalInfo from "assets/images/Capa_1 (3).png";

// material ui
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { InputAdornment, IconButton } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ClearIcon from "@mui/icons-material/Clear";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";

const EditBankInfo = () => {
  let params = useParams();
  const employeeId = params.id;
  const { dispatch } = useContext(Context);

  const [isEditMode, setIsEditMode] = useState(false);
  const [expanded, setExpanded] = useState(false);

  const { getAllbankAPI } = getAllbankdrop();
  const { getAllbankbranchesAPI } = getAllbranchesdrop();
  const { getbankCreateAPI } = getAllbankcreate();
  const { fileUploadAPI, fileDownloadAPI } = FileHandler();
  const { handleFileInputReselect } = ReusableFunction();

  const [bankid, setbankid] = useState("");
  const [passbook, setpassbook] = useState(null);
  const [bankname, setbankname] = useState("");
  const [bankbranchesname, setbankbranchesname] = useState("");
  const [bankoption, setbankoption] = useState([]);
  const [bankbranchesoption, setbankbranchesoption] = useState([]);
  const [account, setaccount] = useState("");
  const [idfc, setidfc] = useState("");
  const { getEmployeefindone } = getcreatefindone();
  const [loader2, setLoader2] = useState(false);
  const [errorFields, setErrorFields] = useState({
    Bankname: false,
    Branchname: false,
    accountname: false,
    idfcnumber: false,
    bankfile: false,
  });

  const handleGetAllbank = async () => {
    try {
      let res = await getAllbankAPI();
      setbankoption(res?.data || []);
    } catch (err) {}
  };

  const handleGetAllbankbranches = async () => {
    try {
      let res = await getAllbankbranchesAPI();
      setbankbranchesoption(res?.data || []);
    } catch (err) {}
  };

  useEffect(() => {
    handleGetAllbank();
    handleGetAllbankbranches();
  }, []);

  const getFilenameFromUrl = (url) => {
    if (url !== "" && url !== undefined && url !== null) {
      const parts = url.split("/");
      return parts[parts.length - 1];
    } else {
      return "";
    }
  };

  const handleCreateLeave = async (e) => {
    const values = {
      relID: employeeId,
    };

    const data = await getEmployeefindone(values);
    setbankname(data?.data?.bank[0]?.banks?.bankName);
    setbankid(data?.data?.bank[0]?.bankId);
    setbankbranchesname(data?.data?.bank[0]?.bankBranchName);
    // setbankbranchesname(data?.data?.bank[0]?.bankBranches?.bankBranchName)
    // setbankbranchesid(data?.data?.bank[0]?.bankBranches?.bankBranchId)
    setidfc(data?.data?.bank[0]?.ifscCode);
    setaccount(data?.data?.bank[0]?.accountNumber);
    setpassbook(data?.data?.bank[0]?.passbookFilePath);
  };

  useEffect(() => {
    handleCreateLeave();
  }, []);

  const handlebankChange = (e) => {
    const selectedbank = bankoption.find(
      (option) => option.bankName === e.target.value
    );
    setbankname(selectedbank.bankName); // Set the titleName
    setbankid(selectedbank.bankId); // Set the titleId
  };
  const handlebankbranchesChange = (e) => {
    setbankbranchesname(e.target.value);
    // const selectedbankbranches = bankbranchesoption.find(option => option.bankBranchName === e.target.value);
    // setbankbranchesname(selectedbankbranches.bankBranchName); // Set the titleName
    // setbankbranchesid(selectedbankbranches.bankBranchId); // Set the titleId
  };
  const handleaccountChange = (e) => {
    setaccount(e.target.value);
  };
  const handleidfcChange = (e) => {
    setidfc(e.target.value);
  };

  const handleFileUpload = async (file) => {
    const maxSize = 1024 * 1024 * 5;
    if (file.size > maxSize) {
      toast.error("File size exceeds the limit (5MB)", {
        toastId: "file-upload-maximum",
      });
      return false;
    }
    try {
      dispatch({ type: "SET_LOADING", payload: true });
      let input = new FormData();
      input.append("type", "employee_bank_passbooks");
      input.append("employeeId", employeeId);
      input.append("file", file);
      const res = await fileUploadAPI(input);
      dispatch({ type: "SET_LOADING", payload: false });
      if (res?.data?.location) {
        return res?.data?.location;
      }
      return false;
    } catch (err) {
      dispatch({ type: "SET_LOADING", payload: false });
      console.log({ err });
    }
  };

  const handleFileDownload = async (id) => {
    try {
      dispatch({ type: "SET_LOADING", payload: true });
      await fileDownloadAPI(id);
      dispatch({ type: "SET_LOADING", payload: false });
    } catch (error) {
      dispatch({ type: "SET_LOADING", payload: false });
      console.error("Error downloading file:", error);
    }
  };

  const handleFileChangeSSC = async (event) => {
    const file = event.target.files[0];
    const location = await handleFileUpload(file);
    if (location) setpassbook(location);
  };

  const handlebankcreate = async () => {
    setLoader2(true);
    const errors = {};
    if (!bankid) errors.Bankname = true;
    // if (!bankbranchesid) errors.Branchname = true;
    if (!bankbranchesname) errors.Branchname = true;
    if (!account) errors.accountname = true;
    if (!idfc) errors.idfcnumber = true;
    if (!passbook) errors.bankfile = true;

    setErrorFields(errors);

    // If no errors, proceed with form submission
    if (Object.keys(errors).length === 0) {
      if (passbook) {
        const values = {
          employeeId: employeeId,
          bankId: bankid,
          // bankBranchId: bankbranchesid,
          bankBranchName: bankbranchesname,
          accountNumber: account,
          ifscCode: idfc,
          passbookFilePath: passbook,
        };

        const data = await getbankCreateAPI(values);
        if (data.status === "Ok") {
          setLoader2(false);
          return toast.success("Data Saved Successfully", {
            toast: "Data Saved Successfully",
          });
        } else {
          setLoader2(false);
          return toast.error("Error saving Data", {
            toast: "Error saving Data",
          });
        }
      } else {
        setLoader2(false);
        // Handle case where passbook is not set
      }
    } else {
      setLoader2(false);
    }
    // sessionStorage.setItem('EmployeeID',data.data.employeeId)
  };

  const toggleEditMode = (event) => {
    event.stopPropagation();
    setIsEditMode(!isEditMode);
  };

  const handleAccordionChange = (event, isExpanded) => {
    setExpanded(isExpanded);
    if (!isExpanded) {
      // Accordion is not expanded, reset edit mode
      setIsEditMode(false);
    }
  };

  const handleClearpassFile = () => {
    setpassbook(null);
  };

  return (
    <div>
      <Loader loading={loader2} />
      <Accordion
        sx={{ mt: 2, boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}
        onChange={handleAccordionChange}
      >
        <AccordionSummary
          expandIcon={<ArrowDropDownIcon className="Accordianicon" />}
          aria-controls="panel1-content"
          id="panel1-header"
          style={{
            boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
            height: "70px",
          }}
        >
          <Box sx={{ pl: 3 }}>
            {" "}
            <img
              sx={{ pl: 3 }}
              className="editpersonalInfo-logo"
              src={personalInfo}
              alt="info"
            />
          </Box>
          <Box className="editacc-title" sx={{ pl: 3 }}>
            Bank Information
          </Box>
          <Box sx={{ ml: 50 }}>
            {expanded && (
              <button className="editButton" onClick={toggleEditMode}>
                Edit
                <Box sx={{ flexGrow: 1 }} />
                {isEditMode}
              </button>
            )}
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2} sx={{ pl: 4, pr: 5, mt: 2, pb: 5 }}>
            {isEditMode ? (
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <Box className="label">
                  Bank Name <span style={{ color: "red" }}>*</span>
                </Box>
                <FormControl fullWidth>
                  <Select
                    labelId="title-label"
                    id="title"
                    style={{ border: "1px solid #B2D4F8", color: "grey" }}
                    defaultValue=""
                    name="title"
                    value={bankname}
                    onChange={handlebankChange}
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    <MenuItem value="">Select Bank</MenuItem>

                    {bankoption.map((option) => (
                      <MenuItem key={option.id} value={option.bankName}>
                        {option.bankName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {errorFields.Bankname && (
                  <span className="error-msg">Bank Name is required</span>
                )}
              </Grid>
            ) : (
              <Grid item xs={12} sm={4} md={4} lg={4}>
                <Box className="editlabel">Bank Name </Box>
                <Box className="editvalue">{bankname}</Box>
              </Grid>
            )}
            {isEditMode ? (
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <Box className="label">
                  {" "}
                  Branch <span style={{ color: "red" }}>*</span>
                </Box>
                <FormControl fullWidth>
                  {/* <Select
                    labelId="title-label"
                    id="title"
                    style={{ border: "1px solid #B2D4F8", color: "grey" }}
                    defaultValue=""
                    name="title"
                    value={bankbranchesname}
                    onChange={handlebankbranchesChange}

                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                  >
                    <MenuItem value="">
                      Select Title
                    </MenuItem>

                    {bankbranchesoption.map((option) => (
                      <MenuItem key={option.id} value={option.bankBranchName}>{option.bankBranchName}</MenuItem>
                    ))}
                  </Select> */}
                  <TextField
                    id="firstName"
                    placeholder="Enter Branch Name"
                    variant="outlined"
                    fullWidth
                    onChange={handlebankbranchesChange}
                    value={bankbranchesname}
                  />
                </FormControl>
                {errorFields.Branchname && (
                  <span className="error-msg">Branch is required</span>
                )}
              </Grid>
            ) : (
              <Grid item xs={12} sm={4} md={4} lg={4}>
                <Box className="editlabel"> Branch </Box>
                <Box className="editvalue">{bankbranchesname}</Box>
              </Grid>
            )}
            {isEditMode ? (
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <Box className="label">
                  {" "}
                  Account Number <span style={{ color: "red" }}>*</span>
                </Box>
                <TextField
                  id="firstName"
                  placeholder="Enter Account Number"
                  variant="outlined"
                  fullWidth
                  onChange={handleaccountChange}
                  value={account}
                />
                {errorFields.accountname && (
                  <span className="error-msg">Account Number is required</span>
                )}
              </Grid>
            ) : (
              <Grid item xs={12} sm={4} md={4} lg={4}>
                <Box className="editlabel"> Account Number</Box>
                <Box className="editvalue">{account}</Box>
              </Grid>
            )}

            {isEditMode ? (
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <Box className="label">
                  {" "}
                  IFSC Code <span style={{ color: "red" }}>*</span>
                </Box>
                <TextField
                  placeholder="Enter IFSC Code"
                  onChange={handleidfcChange}
                  value={idfc}
                  id="firstName"
                  variant="outlined"
                  fullWidth
                />
                {errorFields.idfcnumber && (
                  <span className="error-msg">
                    IFSC Code number is required
                  </span>
                )}
              </Grid>
            ) : (
              <Grid item xs={12} sm={4} md={4} lg={4} sx={{ mt: "30px" }}>
                <Box className="editlabel"> IFSC Code </Box>
                <Box className="editvalue">{idfc}</Box>
              </Grid>
            )}
            {isEditMode ? (
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <Box className="label">
                  Passbook(JPEG/PNG/JPG/PDF/DOC)
                  <span style={{ color: "red" }}>*</span>
                </Box>
                <TextField
                  fullWidth
                  variant="outlined"
                  placeholder="Browse Files (max of 5mb) "
                  type="text"
                  value={getFilenameFromUrl(passbook)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {passbook && (
                          <IconButton
                            onClick={handleClearpassFile}
                            edge="end"
                            aria-label="clear file"
                          >
                            <ClearIcon />
                          </IconButton>
                        )}
                        <input
                          type="file"
                          accept=".pdf,.doc,.docx.jpg,.jpeg,.png"
                          id="file-upload-9"
                          style={{ display: "none" }}
                          onClick={handleFileInputReselect}
                          onChange={handleFileChangeSSC}
                        />
                        <label htmlFor="file-upload-9">
                          <IconButton
                            component="span"
                            color="primary"
                            aria-label="attach file"
                          >
                            <AttachFileIcon style={{ color: "#0056B2" }} />
                          </IconButton>
                        </label>
                      </InputAdornment>
                    ),
                  }}
                />
                {errorFields.bankfile && (
                  <span className="error-msg">Passbook file is required</span>
                )}
              </Grid>
            ) : (
              <Grid item xs={12} sm={4} md={4} lg={4} sx={{ mt: "30px" }}>
                <Box className="editlabel">Passbook/Cheque Leaf Copy</Box>
                  {passbook ? (
                    <span
                    onClick={() => {
                      handleFileDownload(passbook);
                    }}
                    className="download"
                  >
                    <DownloadForOfflineIcon
                      style={{ fontSize: 30, verticalAlign: "middle" }}
                    />{" "}
                    {"Click to download"}
                  </span>
                  ) : (
                    <Box className="editvalue"> -</Box>
                  )}
              </Grid>
            )}
          </Grid>

          {isEditMode && (
            <Grid
              container
              spacing={1}
              sx={{ pl: 4, pr: 5, mt: 5, justifyContent: "center" }}
            >
              <Grid item xs={12} sm={2} md={2} lg={2}>
                <Button className="cancelButton" onClick={toggleEditMode}>
                  Cancel
                </Button>
              </Grid>
              <Grid item xs={12} sm={2} md={2} lg={2}>
                <Button className="saveButton" onClick={handlebankcreate}>
                  Save
                </Button>
              </Grid>
            </Grid>
          )}
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default EditBankInfo;
