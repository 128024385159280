import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
// css
import "./vraShifts.css";
// mui
import Tables from "components/table/table";
import getAllVraShiftAPIS from "apis/panel/vraShifts/vraShifts.js";
import Context from "context/context";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";

import Box from '@mui/material/Box';
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import EditIcon from '@mui/icons-material/Edit';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import MenuItem from '@mui/material/MenuItem';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import Card from '@mui/material/Card';

import CardContent from '@mui/material/CardContent';


import { LocalizationProvider, DesktopDatePicker, DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
// import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import check from "../../../../assets/images/check.png";
import cross from "../../../../assets/images/remove.png";
import Autocomplete from '@mui/material/Autocomplete';



// react calendar
import 'react-calendar/dist/Calendar.css';
import moment from 'moment';
import { Button } from "../../../../../node_modules/@mui/material/index";
// import { each } from "../../../../../node_modules/chart.js/dist/helpers/helpers.core";

const style = {
  position: 'absolute',
  top: '60%',
  left: '60%',
  transform: 'translate(-60%, -60%)',
  width: "98%",
  bgcolor: 'background.paper',
  border: 'none',
  boxShadow: 24,
  p: 4,
  borderRadius: "10px",
  maxHeight: "90vh",
  overflowY: "scroll",
};

const VraShifts = () => {
  const { state, dispatch } = useContext(Context);
  const [roleCode] = useState(state.role.roleCode);
  const navigate = useNavigate();
  const { getEditVraShiftDetailsAPI, getAllVraDetailsAPI, updateVraShiftAPI , getAllShiftsAPI,getAllRadiologistsAPI,getVraEmployeeFindone} = getAllVraShiftAPIS();
  const [empDetails, setEmpDetails] = useState([]);
  const [shiftOptions, setShiftOptions] = useState([]);
  const [radOptions, setRadOptions] = useState([]);
  const [primaryRadiologist, setPrimaryRadiologist] = useState('');
  const [shiftValue, setShiftValue] = useState('')
  const [vraShiftDetails, setVraShiftDetails] = useState([])
  const [offset, setOffset] = useState(1);
  const [count, setCount] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const [filterValue, setFilterValue] = useState("");
  const [vraShiftDetailsEditObj, setVraShiftDetailsEditObj] = useState([]);
  const [serialNumber, setSerialNumber] = useState("");
  const [employeeName, setEmployeeName] = useState('')
  const [open, setOpen] = useState(false);
  const [editMode, setEditMode] = useState({});
  const [mappedData, setMappedData] = useState([]);
  const [editEmployeeId, setEditEmployeeId] = useState('')
  const [updatedDatas, setUpdatedDatas] = useState([])

  const [findoneData, setFindoneData]= useState([])
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [datesArray, setDatesArray] = useState([]);
  const [date,setDate]=useState(false)

  const handleDateChange = (date) => {
    setSelectedDate(date);
   setDate(true)
  };

  const generateDatesArray = (date) => {
  
    const year = date.getFullYear();
    const month = date.getMonth();
    const daysInMonth = new Date(year, month + 1, 0).getDate();

    const dates = [];
    for (let day = 1; day <= daysInMonth; day++) {
      const currentDate = new Date(year, month, day);
      const formattedDate = formatDate(currentDate);
      dates.push(formattedDate);
    }

    setDatesArray(dates);
  };
  
  
  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); 
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setSelectedDate(new Date())
    setDate(false)
    setEditMode(false)
  }

  const handleEditClick = (index) => {
    setEditMode(false);
    setEditMode((prev) => ({ ...prev, [index]: !prev[index] }));
  };

 const handleEditClickClose = (index)=>{
  setEditMode(false);
 }

  const updateMappedData = (date, field, value) => {
    setMappedData(prevMappedData => {
      // Create a new object for the updated state
      const updatedData = { ...prevMappedData };
      // Update the specific date's field with the new value
      if (updatedData[date]) {
        updatedData[date] = {
          ...updatedData[date],
          [field]: value
        };
      }
  
      return updatedData;
    });
  };
  
  
  const handleChange = (date, field, value) => {
    updateMappedData(date, field, value);
    let found = false;
    const updatedDataArray = vraShiftDetailsEditObj.map((item) => {
      if (item.date === date) {
        found = true;

        return {
          vraEmployeeShiftId: item.vraEmployeeShiftId,

          [field]: value
        }
      }
      return null;
    }).filter(day => day !== null);

    if (!found) {
      let values = {
        date: date,
        [field]: value
      }
      updatedDataArray.push(values)
    }

    const dateAlreadyInUpdatedDatas = updatedDatas.some((item) => item.date === date);

    if (dateAlreadyInUpdatedDatas) {
      const mergedDataArray = updatedDatas.map((item) => {
        if (item.date === date) {
          return {
            ...item,
            [field]: value
          };
        }
        return item;
      })

      setUpdatedDatas(mergedDataArray);
    } else {

      setUpdatedDatas([...updatedDatas, ...updatedDataArray]);
    }

  }
  
  
  const handleSave = (index) => {
    setEditMode((prev) => ({ ...prev, [index]: false }));
  };




  const handleNaviagteVraEdit=(id)=>{
  
  
    navigate(`/panel/vraEdit/${id}`);
  }
  // const handleView = async(vraEmployeeDetailId) =>{
    
  //   console.log(vraEmployeeDetailId,"vra")
    
  //   let values;
  //   values = {
  //     vraEmployeeDetailId
  //   };
    
  //   try {
      
  //     // setLoading(true);
  //     let res = await getVraEmployeeFindone(values);
  //     // console.log(details?.data)
  //     setFindoneData(res?.data || [])
  //     navigate("/panel/vraEdit"+project.projectId)
              
      
  //         }
  //         catch (err) {
              
  //         }
    
  


    
  //       }



  const handleEdit = async (index, employeeName) => {
    
    try {
      dispatch({ type: "SET_LOADING", payload: true });
      let values;
      values = {
        month: selectedDate,
        vraEmployeeId: index,
      }
      let res = await getEditVraShiftDetailsAPI(values);
      setMappedData([])
     
      setVraShiftDetailsEditObj(res?.data?.employeeShifts)
    
      setEditEmployeeId(index)
     
      setOpen(true)
      setEmployeeName(employeeName)
      setUpdatedDatas([])
      dispatch({ type: "SET_LOADING", payload: false });
    } catch (error) {
      dispatch({ type: "SET_LOADING", payload: false });
    }
  }

  const handleNavigate = () => {
    navigate("/panel/AddVraShifts")
  }

  useEffect(() => {
    handleEditObjData()
  }, [vraShiftDetailsEditObj,datesArray])


  const handleEditObjData = () => {
    let dayDataMap = []
    datesArray.map((date) => {
      dayDataMap[date] = {}
    })


    for (const item of vraShiftDetailsEditObj) {
      const day = item.date;

      if (dayDataMap[day]) {
        dayDataMap[day] = item;
       
        
      }

    }
    setMappedData(dayDataMap);


  }


  const handleGetAllVraDetailsApi = async () => {
    try {
      dispatch({ type: "SET_LOADING", payload: true });
      let values;
      values = {
        offset: (offset - 1) * 10,
        limit: 10,
      };
      if (searchValue) {
        values = {
            ...values,
          searchValue,
        };
      }
      if (filterValue) {
        values = {
            ...values,
          filterValue,
        };
      }
      let res = await getAllVraDetailsAPI(values);

      setVraShiftDetails(res?.data?.vraShifts || []);
      setCount(res?.data?.count);
      setSerialNumber(offset)
      dispatch({ type: "SET_LOADING", payload: false });
    } catch (err) {
      dispatch({ type: "SET_LOADING", payload: false });
    }
  };

  const handleGetAllShiftsApi = async () => {
    try {
      let res = await getAllShiftsAPI();
      
      
      const shiftnames = res?.data?.shifts?.map(shift => shift);
     
      
      setShiftOptions(shiftnames || []); 


    }
    catch (err) { }
  };

  const handleGetAllRadiologistsApi = async () => {
    try {
      let res = await getAllRadiologistsAPI();

      const radnames = res?.data?.radiologists.map(radiologist => radiologist);

      setRadOptions(radnames);

    }
    catch (err) { }
  };

  const handleUpdateVraShiftsApi = async (status) => {
     
   try {
      dispatch({ type: "SET_LOADING", payload: true });
      let value;

      value = {
        employeeId: editEmployeeId,
        shiftData: updatedDatas.flat()
      };

      
     
      let res = await updateVraShiftAPI(value);

      handleGetAllVraDetailsApi()
      setOpen(false)
      setEmployeeName('')
      setEditEmployeeId('')
      setUpdatedDatas([])
      setSelectedDate(new Date())
      setDate(false)
      setEditMode(false)
      dispatch({ type: "SET_LOADING", payload: false });
    } catch (err) {
      dispatch({ type: "SET_LOADING", payload: false });
    }
  };


  useEffect(() => {
    handleGetAllVraDetailsApi()
    handleGetAllShiftsApi()
    handleGetAllRadiologistsApi()
  }, [offset, searchValue, filterValue])

  useEffect(()=>{
    handleGetAllShiftsApi()
    generateDatesArray(selectedDate)
  },[])

  useEffect(()=>{
    if(date){
      generateDatesArray(selectedDate)
    handleEdit(editEmployeeId,employeeName)
    }
    
  },[selectedDate])
  return (
    <div
      className="vraShiftsMediaQuery"
      style={{
        backgroundColor: "white",
        borderRadius: "10px",
      }}
    >
      <Tables
        title={
          <Box sx={{ pt: 2, pb: 2, pl: 1 }} className="VraShiftsTitle">Rp Master Roster</Box>
        }
        searchBar={roleCode === "R001"}
        filter={roleCode === "R001"}
        setSearchValue={setSearchValue}
        filterValue={filterValue}
        setFilterValue={setFilterValue}
        filterValues={[
          { label: "Employee Id", value: "" },
          { label: "VRA Name", value: "employeeName" },
        ]}
        heading={[
          "",
          "",
          "S.No",
          "Employee Id",
          "First Name",
          "Last Name",
          "Date of Joining",
          "Primary Radiologist",
          "VRA RP ID",
          "SRF VRA Name",
          "SRF RAD Name",
          "Go-Live Date",
          "Commitment Date",
          "Training Start Date",
          "Training End Date",
          "Certification Date",
          "Role",
          "Enagagement Type",
          "status",
        ]}
        data={vraShiftDetails?.map((e, index) => [
          <EditIcon style={{color:"#FE6024"}}  onClick={() => handleEdit(e.employeeId,e.firstName)}/>,
          <RemoveRedEyeIcon style={{color:"#FE6024"}} onClick={() => handleNaviagteVraEdit(e.vraEmployeeDetailId)}/>,
              index + 1 ,
              e.employeeCode,
              e.firstName,
              e.lastName,
              // e.dateOfJoining,
              moment(e.dateOfJoining).format('DD-MMM-YYYY'),
              e.radFormattedName,
              e.vraUsername,
              e.firstName.concat(e.lastName),
              e.radUsername,
              moment(e.deploymentDate).format("DD-MMM-YYYY"),
              moment(e.commitmentDate).format("DD-MMM-YYYY"),
              moment(e.trainingStartDate).format("DD-MMM-YYYY"),
              moment(e.trainingEndDate).format("DD-MMM-YYYY"),
              moment(e.certificationDate).format("DD-MMM-YYYY"),
              e.role,
              e.engagementType,
              e.status === "Active" ? (
                  <img style={{height: "20px"}} src={check} alt=""/>
                ) : (
                  <img  style={{height: "20px"}} src={cross} alt=""/>
                ),
         ])}
         offset={offset}
         setOffset={setOffset}
         count={count}
         placeholder="Search by ID, VRA Name"
      />

      <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={style}>
          <Grid container spacing={2} sx={{ mt: 1,mb:1 }}>
            <Grid item xs={12} sm={4} md={4} lg={4} justifyContent="center">
              <div  className="VraShiftsTitle">
                Edit Master Roster
              </div>
            </Grid>
            <Grid item xs={12} sm={4} md={4} lg={4} style={{ display: "flex", justifyContent: "end", gap: "4%", alignItems: "center" }}>
              <div className="editlabelinroster">Employee Name - </div>
              <div className="editlabelinroster">{employeeName}</div>
            </Grid>
            <Grid item xs={12} sm={4} md={2} lg={2} justifyContent="center" sx={{pl:5, ml:15}}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  views={[ "month","year"]}
                  value={selectedDate}
                  onChange={handleDateChange}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                  maxDate={new Date()}
                />
              </LocalizationProvider>
            </Grid>
          </Grid>
          <IconButton
            onClick={handleClose}
            sx={{ position: 'absolute', top: 3, right: 3 }}
          >
            <CloseIcon style={{ border: "2px solid grey", borderRadius: "5px" }} />
          </IconButton>
          <Grid container spacing={2}>
            {Object.keys(mappedData)?.map((day, index) => (
              <Grid item xs={12} md={4} lg={4} xl={4} sm={4} key={index} classNamea="allcardsparentgrid">
                <Card className="cardinmasterRoster">
                  <CardContent>
                    <Grid container spacing={1.2} style={{minHeight:"240px"}}>
                      <Grid item xs={12} style={{ display: "flex", justifyContent: "space-between" }}>
                        <Box sx={{ fontWeight: 'bold', fontSize: '16px', mb: 1 }}>Day:  {moment(day).format('DD-MMM-YYYY')} </Box>
                        {editMode[day] ? (
                          <Box><EditIcon style={{ color: "#FE6024" }} onClick={() => handleEditClickClose(day)} /></Box>
                        ) : (
                          <Box><EditIcon style={{ color: "#FE6024" }} onClick={() => handleEditClick(day)} /></Box>
                        )}
                      </Grid>
                      <Grid item xs={12} md={6} lg={6} sm={6}>
                        <Box className="editlabelinroster">Primary Radiologist</Box>
                        {editMode[day] ? (
                         <Autocomplete
                         id="primary-radiologist-autocomplete"
                         getOptionLabel={(option) => option?.radUsername} 
                         options={radOptions}
                            value={radOptions.find(option => option.radiologistId === mappedData[day]?.radiologists?.radiologistId )|| null}
                            onChange={(event, newValue) => handleChange(day, 'radiologists', newValue )}
                            renderInput={(params) => (
                           <TextField
                             {...params}
                             fullWidth
                           />
                         )}
                       />
                          ) : (
                            <Box sx={{ mt: 1 }}>  {radOptions.find(option => option.radiologistId === mappedData[day]?.radiologists?.radiologistId)?.radUsername || ''}</Box>   
                          )
                        }
                      </Grid>
                      <Grid item xs={12} md={6} lg={6} sm={6}>
                        <Box className="editlabelinroster" sx={{pl:2}}>Shift </Box>
                        {editMode[day] ? (
                          <Autocomplete
                          id={`shift-autocomplete-${day}`}
                            // id="primary-radiologist-autocomplete"
                            getOptionLabel={(option) => option.shiftName} 
                            options={shiftOptions}
                            value={shiftOptions.find(option => option.projectShiftId === mappedData[day]?.projectShifts?.projectShiftId )|| null}
                            onChange={(event, newValue) => handleChange(day, 'projectShifts', newValue)}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                fullWidth
                              />
                            )}
                          />
                        ) : (
                          <Box sx={{ mt: 1, pl:2 }}>  {shiftOptions.find(option => option.projectShiftId === mappedData[day]?.projectShifts?.projectShiftId)?.shiftName || ''}</Box>
                        )}
                      </Grid>
                      
                     
                      <Grid item xs={12} md={4} lg={4} sm={4}>
                        <Box className="editlabelinroster">Actual Hours</Box>
                        <TextField
                          onChange={(e) => handleChange(day, 'actualHours', e.target.value)}
                          type="number"
                          value={mappedData[day]?.actualHours}
                          sx={{ width: '45%', mt: 1 }}
                        />
                        
                      </Grid>
                      <Grid item xs={12} md={4} lg={4} sm={4}>
                        <Box className="editlabelinroster">Forecast Hours</Box>
                        <TextField
                          type="number"
                          value={mappedData[day]?.forecastHours}
                          onChange={(e) => handleChange(day, 'forecastHours', e.target.value)}
                          sx={{ width: '45%', mt: 1 }}
                        />
                      </Grid>


                     <Grid item xs={12} md={4} lg={4} sm={4}>
                        <Box className="editlabelinroster">Timesheet Hours</Box>
                        <TextField
                          // onChange={(e) => handleChange(day, 'actualHours', e.target.value)}
                          type="number"
                          value={mappedData[day]?.timesheetHours}
                          sx={{ width: '45%', mt: 1 }}
                        />
                        
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>

            ))}

          </Grid>
          <Grid>
               <Button
                className="reset-btnforroster"
                component="label"
                variant="contained"
                style={{margin:"auto",justifyContent:"center", marginTop:"2%",marginLeft:"50%"}}
                tabIndex={-1}
                onClick={handleUpdateVraShiftsApi}
                >
                save
                </Button>
          </Grid>
        </Box>
      </Modal>
    </div>
  )
}


export default VraShifts