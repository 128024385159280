// custom pages
import config from "config/config";
import HandleApiResponse from "utils/handleApiResponse";

const leaveRequestAPI = () => {
  const { handleResponse, handleError } = HandleApiResponse();

  async function getAllLeaveAPI() {
    try {
      const obj = {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      };
      let res = await fetch(`${config.host}/panel/leave/leave_types`, obj);
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }

  async function getLeaveCreateAPI(data) {
    try {
      const obj = {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      };
      let res = await fetch(`${config.host}/panel/leave/create`, obj);
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }

  async function getLeaveUpdateAPI(data) {
    try {
      const obj = {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      };
      let res = await fetch(`${config.host}/panel/leave/update`, obj);
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }

  async function getAllLeaveRequestAPI(year) {
    try {
      const obj = {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(year),
      };
      let res = await fetch(
        `${config.host}/panel/leave/leave_request_by_employee`,
        obj
      );
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }

  async function getAllLeaveAprovalAPI(data) {
    try {
      const obj = {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      };
      let res = await fetch(
        `${config.host}/panel/leave/leave_request_by_manager`,
        obj
      );
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }

  async function getAprovedAPI(leavevalue) {
    try {
      const obj = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify(leavevalue),
      };
      let res = await fetch(`${config.host}/panel/leave/approve`, obj);
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }

  async function getBalanceLeaveAPI(leavevalue) {
    try {
      const obj = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify(leavevalue),
      };
      let res = await fetch(`${config.host}/panel/leave/balance_leave`, obj);
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }

  async function getFetchLeaveRequestAPI(leaveRequestId) {
    try {
      let queryParams = new URLSearchParams({ leaveRequestId }).toString();
      let res = await fetch(
        `${config.host}/panel/leave/leave_request_view?${queryParams}`,
        {
          method: "GET", // Use GET method for sending data in parameters
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
        }
      );
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }

  return {
    getAllLeaveAPI,
    getLeaveCreateAPI,
    getLeaveUpdateAPI,
    getAllLeaveRequestAPI,
    getAllLeaveAprovalAPI,
    getAprovedAPI,
    getBalanceLeaveAPI,
    getFetchLeaveRequestAPI,
  };
};

export default leaveRequestAPI;
