import React, { useRef, useState, useEffect } from 'react';
import { useNavigate, useParams } from "react-router-dom";
// import "./EditAddEmployee.css"
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import personalInfo from "assets/images/color (1).png";
import AttachFileIcon from '@mui/icons-material/AttachFile';

import CloudUploadIcon from '@mui/icons-material/CloudUpload';

import { InputAdornment, IconButton } from '@mui/material';
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import * as moment from "moment";
import { toast } from "react-toastify";
import Loader from "components/Loader";

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

// import { makeStyles } from '@mui/styles';

// const useStyles = makeStyles((theme) => ({
//   fileInput: {
//     '& input[type="file"]': {
//       display: 'none',
//     },
//   },
//   textField: {
//     '& .MuiOutlinedInput-root': {
//       '&.Mui-focused fieldset': {
//         borderColor: theme.palette.primary.main,
//       },
//       '&:hover fieldset': {
//         borderColor: theme.palette.secondary.main,
//       },
//     },
//     '& .MuiFormLabel-root.Mui-focused': {
//       color: theme.palette.primary.main,
//     },
//   },
// }));

import projectupdate from 'apis/panel/project/project'
import getEmpRel from 'apis/panel/Insurance/insurance'
import getRel from 'apis/panel/Insurance/insurance'
import getcreatefindone from "apis/panel/projectRole/projectRole"
import projectRoleAPI from "apis/panel/projectRole/projectRole";
import ProjecttypeallAPI from "apis/panel/project/project";

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };


const EditNewRole = () => {
  let params = useParams();
  const roleId = params.id;
  const [file, setFile] = useState(null);
  
  
  const [expanded, setExpanded] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
 

  const[roleid, setroleid]= useState('');
  const[rolename, setrolename]= useState('');
 
  const[status, setstatus]= useState('');
 
  const[description, setdescription]= useState('');
  const[active, setactive]= useState('');
  const[inactive, setinactive]= useState('');
  const [loader2, setLoader2] = useState(false)


 

  const { getrolefindone } = getcreatefindone();
  const { getroleupadte } = projectRoleAPI();


//   const { getprojectupadte } = projectupdate();
  const { getEmployeeRelations } = getEmpRel();
  const { getRelations } = getRel();
  const navigate = useNavigate();

  const handleprojectnameChange = (e) => {
    setrolename(e.target.value);
  };

 
  const handlestatusChange = (e) => {
    setstatus(e.target.value);
  };

  const handledescriptionChange = (e) => {
    setdescription(e.target.value);
  };
  const handleprojectcodeChange = (e) => {
    setroleid(e.target.value);
  };


  const [errorFields, setErrorFields] = useState({
    rolename: false,
    roleid: false,
    rolestatus: false,
   
   
    
  });
 



  

  const handlefindonee = async (e) => {
    setLoader2(true)
    const values = {
      relID: roleId


    };

    const data = await getrolefindone(values);
    setLoader2(false)
    setroleid(data?.data?.projectRoleCode)
    setrolename(data?.data?.projectRoleName)
    // setstatus(data?.data?.isActive)
    setdescription(data?.data?.projectRoleDescription)
    setstatus(data?.data?.status)










  }

  

  useEffect(() => {
    handlefindonee()
  }, [])

  const handleNavigate = (e) => {
    navigate("/panel/projectrole");
  };



  


  


  // const toggleEditMode = (event) => {
  //   event.stopPropagation();
  //   setIsEditMode(!isEditMode);
  // };

  


 

 





  const handleFieldChange = (setter) => (event) => {
    setter(event.target.value);
  };
  // const handleAccordionChange = (event, isExpanded) => {
  //   setExpanded(isExpanded);
  //   if (!isExpanded) {
  //     // Accordion is not expanded, reset edit mode
  //     setIsEditMode(false);
  //   }
  // };

  const handleprojectRoleAPI = async (e) => {
    setLoader2(true)

    e.preventDefault();
    // Validate form fields
    const errors = {};
    if (!rolename) errors.rolename = true;
    if (!roleid) errors.roleid = true;
    if (!status) errors.rolestatus = true;
    // if (!lastName) errors.middleName = true;
   
  //   if (!mothersName) errors.mothersName = true;
  //   // if (!spouseName) errors.spouseName = true;
  //   if (!bloodgroup) errors.bloodgroup = true;
  //   if (!maritalStatus) errors.maritalStatus = true;
  //   if (!businessMail) errors.businessMail = true;
  //   if (!personalMail) errors.personalMail = true;
  //   if (!personalPhone) errors.personalPhone = true;
  //   if (!dateOfBirth) errors.dateOfBirth = true;
  //   if (!gender) errors.gender = true;
  //   if (maritalStatus === 'Married' && !spouseName) {
  //     errors.spouseName = true;
  // }

  

    setErrorFields(errors);

    if (Object.keys(errors).length === 0) {
   
      
   
    const values = {
      projectRoleId:roleId,
      projectRoleName: rolename,
      projectRoleCode: roleid,
      isActive: true,
      projectRoleDescription:description,
      status:status
     
     
    };
    
    const data = await getroleupadte(values);
  
  
    if(data.status === 'Ok'){
      navigate("/panel/projectrole");
      setLoader2(false)
      // sessionStorage.setItem('EmployeeID',data.data.employeeId)
      // sessionStorage.setItem('EmployeeCode',data.data.employeeCode)
      return toast.success("Data Saved Successfully", { toast: "Data Saved Successfully" });
  
    }else{
      setLoader2(false)
      return toast.error("Error saving Data", { toast: "Error saving Data" });
  
    }

  }
  else{
    setLoader2(false)
    return toast.error("Error saving Data", { toast: "Error saving Data" });
  }

}
  

const toggleEditMode = () => {
  setIsEditMode(!isEditMode); // Toggles the value of isEditMode
};


  return (
  
 
    <div className='ProjectCont'>
      <Loader loading={loader2} />

            < Grid container className="editsetupmaincont" sx={{pl:5, pr:5, mt:2, mb:1}}>
            <Box className="addnewprojecthead">Roles</Box>
            <Box>
            <Button className="projsetupedit" onClick={toggleEditMode}>
          {isEditMode ? 'Edit' : 'Edit'}
        </Button>
            </Box>
          </Grid>

          <Grid container spacing={2} sx={{ pl: 5, pr: 5, mt: 2 }} >



            {isEditMode ? (
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <Box className="label" >Role Id <span style={{ color: "red" }}>*</span></Box>
                <TextField
                  id="permanentAddress"
                  placeholder="Enter Role Id"
                  variant="outlined"
                  fullWidth
                  disabled = {true}
                  value={roleid}
                  onChange={handleprojectcodeChange}
                />
                 {errorFields.roleid && <span className="error-msg">This is required Field</span>}
              </Grid>
            ) : (

              <Grid item xs={12} sm={4} md={4} lg={4} sx={{ mt: 2 }}>
                <Box className="editlabel" >Role Id</Box>
                <Box className="editvalue">{roleid}</Box>
              </Grid>
            )}


            {isEditMode ? (
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <Box className="label" >Role Name <span style={{ color: "red" }}>*</span></Box>
                <TextField
                  id="permanentAddress"
                  placeholder="Enter the Role Name"

                  // Add onChange handler
                  variant="outlined"
                  fullWidth
                  value={rolename}
                  onChange={handleprojectnameChange}
                />
                {errorFields.rolename && <span className="error-msg">This is required Field</span>}
              </Grid>
            ) : (

              <Grid item xs={12} sm={4} md={4} lg={4} sx={{ mt: 2 }}>
                <Box className="editlabel" >Role Name</Box>
                <Box className="editvalue">{rolename}</Box>
              </Grid>
            )}


{isEditMode ? (
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <Box className="label">Role Status<span style={{ color: "red" }}>*</span></Box>
                <FormControl fullWidth>
                  <Select
                    id="permanentState"
                    defaultValue=""
                    placeholder="Select Status"
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                    style={{ border: "1.5px solid #B2D4F8", color: "grey" }}
                    value={status}
                    onChange={handlestatusChange}
                  >
                    <MenuItem value="">
                      Select Status
                    </MenuItem>
                    <MenuItem value="Active">Active</MenuItem>
              <MenuItem value="Inactive">InActive</MenuItem>
                  </Select>
                </FormControl>
                {errorFields.rolestatus && <span className="error-msg">This is required Field</span>}
              </Grid>
            ) : (

              <Grid item xs={12} sm={4} md={4} lg={4} sx={{ mt: 2 }}>
                <Box className="editlabel" >Status</Box>
                <Box className="editvalue">{status}</Box>
              </Grid>
            )}


           
    
          </Grid>

      
        {isEditMode ? (
              <Grid item xs={12} sm={6} md={6} lg={6} sx={{mt:"30px",pl:5,pr:5}}>
                <Box className="label">Description</Box>
                <TextField
                  id="permanentAddress"
                  placeholder="Enter the Description"
                  sx={{
                    "& .MuiInputBase-input": {
                    //   alignItems: "center",
                    alignItems:"top",
                      height: "90px",
                      border:"1px solid #B2D4F8"
                    },
                  }}
                  // Add onChange handler
                  variant="outlined"
                  fullWidth
                  value={description}
                      onChange={handledescriptionChange}
                />
               
              </Grid>
            ) : (

              <Grid item xs={12} sm={4} md={4} lg={4} sx={{ pl:5, pr:5, pb:5, mt: "30px" }}>
                <Box className="editlabel" >Description</Box>
                <Box className="editvalue">{description}</Box>
              </Grid>
            )}

        <Grid container spacing={1} sx={{ pl: 4, pr: 5, mt: 5, pb:5,justifyContent: "center" }} >
      

          <Grid item xs={12} sm={2} md={2} lg={2}>
            <Button className="cancelButton" onClick={handleNavigate}>Cancel</Button>
          </Grid>

          <Grid item xs={12} sm={2} md={2} lg={2}>
            <Button
               className="saveButton" onClick={handleprojectRoleAPI}>Save</Button>
          </Grid>

        </Grid>


        </div>
  )
}

export default EditNewRole