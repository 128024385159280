// custom pages
import config from "config/config";
import HandleApiResponse from "utils/handleApiResponse";

const projectRoleAPI = () => {
  const { handleResponse, handleError } = HandleApiResponse();

  async function getAllMasterProjectRoles(values) {
    try {
      const obj = {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      };
      const query = JSON.stringify(values);
      let res = await fetch(`${config.host}/panel/projectRoles/findAllMaster?query=${query}`, obj);
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }

  async function getAllProjectRoles() {
    try {
      const obj = {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      };
      let res = await fetch(`${config.host}/panel/projectRoles/findAll`, obj);
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }
  
  async function getRoleCreateAPI(data) {
    try {
      const obj = {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      };
      let res = await fetch(`${config.host}/panel/projectRoles/create`, obj);
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }

  async function getrolefindone(id) {
    try {
      const obj = {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      };
      let res = await fetch(
        `${config.host}/panel/projectRoles/find/${id.relID}`,
        obj
      );
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }

  async function getroleupadte(data) {
    try {
      const obj = {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      };
      let res = await fetch(`${config.host}/panel/projectRoles/update`, obj);
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }

  return {
    getAllMasterProjectRoles,
    getAllProjectRoles,
    getRoleCreateAPI,
    getrolefindone,
    getroleupadte,
  };
};

export default projectRoleAPI;
