import React from "react";
import { useNavigate } from "react-router-dom";

import SessionExpiredSvg from "assets/images/session-expired.svg";

function SessionExpired() {
  const navigate = useNavigate();

  return (
    <div
      style={{
        backgroundColor: "rgba(229, 240, 252, 1)",
        height: "100vh",
      }}
    >
      <div
        style={{
          position: "absolute",
          top: "45%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          textAlign: "center",
        }}
      >
        <img src={SessionExpiredSvg} alt="session-expired" width={"50%"} />
        <h1 style={{ color: "rgba(0, 44, 93, 1)", margin: "50px 0px" }}>
          Your session has expired
        </h1>
        <button
          style={{
            background: "rgba(254, 96, 47, 1)",
            color: "#fff",
            fontSize: "16px",
            fontWeight: "bold",
            border: "none",
            borderRadius: 20,
            cursor: "pointer",
            width: 140,
            height:39,
            fontFamily: "Inter",
            padding: "10px, 20px, 10px, 20px",
          }}
          onClick={() => navigate("/")}
        >
          Login
        </button>
      </div>
    </div>
  );
}

export default SessionExpired;
