import { useState, useEffect, useContext } from "react";
import context from "context/context";
import config from "config/config";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Grid from "@mui/material/Grid";
import * as moment from "moment";

export default function Profile() {
  const { state } = useContext(context);
  const [employee] = useState(state.employee);
  return (
    <Card variant="outlined" sx={{ padding: "15px 15px 30px 15px" }}>
      <Box sx={{ fontSize: 20, fontWeight: "bold" }}>Profile</Box>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} lg={12}>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Avatar src={employee?.image} alt={employee?.firstName} sx={{ width: 150, height: 150 }} />
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} lg={4} sx={{ textAlign: "center" }}>
          <Card
            sx={{
              padding: "22px",
              borderRadius: "8px",
              border: `1px solid ${config?.theme?.primary?.main}`,
            }}
          >
            <b>First Name :</b> {employee?.firstName}
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} lg={4} sx={{ textAlign: "center" }}>
          <Card
            sx={{
              padding: "22px",
              borderRadius: "8px",
              border: `1px solid ${config?.theme?.primary?.main}`,
            }}
          >
            <b>Middle Name :</b> {employee?.middleName}
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} lg={4} sx={{ textAlign: "center" }}>
          <Card
            sx={{
              padding: "22px",
              borderRadius: "8px",
              border: `1px solid ${config?.theme?.primary?.main}`,
            }}
          >
            <b>Last Name :</b> {employee?.lastName}
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} lg={4} sx={{ textAlign: "center" }}>
          <Card
            sx={{
              padding: "22px",
              borderRadius: "8px",
              border: `1px solid ${config?.theme?.primary?.main}`,
            }}
          >
            <b>DOB :</b> {employee?.dateOfBirth?moment(employee?.dateOfBirth).format("DD/MM/YYYY"):""}
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} lg={4} sx={{ textAlign: "center" }}>
          <Card
            sx={{
              padding: "22px",
              borderRadius: "8px",
              border: `1px solid ${config?.theme?.primary?.main}`,
            }}
          >
            <b>Gender :</b> {employee?.gender}
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} lg={4} sx={{ textAlign: "center" }}>
          <Card
            sx={{
              padding: "22px",
              borderRadius: "8px",
              border: `1px solid ${config?.theme?.primary?.main}`,
            }}
          >
            <b>Email :</b> {employee?.businessEmail}
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} lg={4} sx={{ textAlign: "center" }}>
          <Card
            sx={{
              padding: "22px",
              borderRadius: "8px",
              border: `1px solid ${config?.theme?.primary?.main}`,
            }}
          >
            <b>Contact Number :</b> {employee?.contactNumber}
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} lg={4} sx={{ textAlign: "center" }}>
          <Card
            sx={{
              padding: "22px",
              borderRadius: "8px",
              border: `1px solid ${config?.theme?.primary?.main}`,
            }}
          >
            <b>Employee Code :</b> {employee?.employeeCode}
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} lg={4} sx={{ textAlign: "center" }}>
          <Card
            sx={{
              padding: "22px",
              borderRadius: "8px",
              border: `1px solid ${config?.theme?.primary?.main}`,
            }}
          >
            <b>Joining Date :</b> {employee?.hireDate?moment(employee?.hireDate).format("DD/MM/YYYY"):""}
          </Card>
        </Grid>
      </Grid>
    </Card>
  );
}
