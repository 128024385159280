import React, { useState, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import { useDropzone } from 'react-dropzone';
import { motion } from 'framer-motion';
import { toast, ToastContainer } from 'react-toastify'; // Import toast and ToastContainer
import 'react-toastify/dist/ReactToastify.css'; // Import the CSS for styling
import "./VraScheduling.css";
import getAllVraShiftAPIS from "apis/panel/vraShifts/vraShifts.js";
import upload from "../../../../assets/images/rpHomePage/upload.gif";
import Context from "context/context";
import leftarrow from "assets/images/arrow-right.png";
// import { Box } from '@mui/material';
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import vraShiftsAPI from 'apis/panel/vraShifts/vraShifts.js';

import { LocalizationProvider, DesktopDatePicker, DatePicker } from '@mui/x-date-pickers';
import TextField from '@mui/material/TextField';
import * as moment from "moment";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import Button from "@mui/material/Button";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";

import "react-toastify/dist/ReactToastify.css";
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
// import reportAPI from 'apis/panel/reports/reports';

const AddAffinity= () => {
  const navigate= useNavigate()
  const { buildAffinityAPI  } =vraShiftsAPI();
  const [uploadProgress, setUploadProgress] = useState(0); // State to track upload progress
  const { state, dispatch } = useContext(Context);



  const handleNavigation=()=>{
    navigate("/panel/rpHomePage")
  }

//   const initialStartDate = moment().startOf('month').toDate();
     
//   const initialEndDate = new Date();

//   const [exportObj, setExportObj] = useState({
//       startDate: initialStartDate,
//       endDate: initialEndDate,
//   });
 
  const handleBuildAffinity = async () => {

    // const values = {
     
    //   start_date: moment(exportObj.startDate).format("YYYY-MM-DD"),
    //   end_date: moment(exportObj.endDate).format("YYYY-MM-DD"),
    // };
    try {
      dispatch({ type: "SET_LOADING", payload: true });
  
      // Pass the start and end dates from exportObj to the API
      const response = await buildAffinityAPI();
      dispatch({ type: "SET_LOADING", payload: false });
  
      if (response?.Status == 200) {
        toast.success(response?.detail)
      } else {
        // Handle failure
        toast.error("Failed to Build.");
      }
    } catch (err) {
      dispatch({ type: "SET_LOADING", payload: false });
      console.error("Error Building:", err);
      toast.error("Error Building Affinity.");
    }
  };
  

  return (
    <div style={{ backgroundColor: "white", borderRadius: "10px" }}>
       <Box sx={{ pt: 2, pb: 2, pl: 1 }} className="VraShiftsTitle">Build Affinity</Box>
      
          {/* <Grid container spacing={4} sx={{ mt: 2, pl:4,pr:4 }}>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <Box className="labelproj">
            Start Date <span style={{ color: "red" }}>*</span>
          </Box>
          <LocalizationProvider fullWidth dateAdapter={AdapterDateFns}>
            <DatePicker
              id="startdate"
              name="startdate"
              sx={{ width: "100%" }}
              format="dd/MM/yyyy"
              renderInput={(params) => <TextField {...params} fullWidth />}
            //   maxDate={exportObj?.endDate}
              value={exportObj?.startDate}
              onAccept={(date) => {
                setExportObj({ ...exportObj, startDate: date });
              }}
            />
          </LocalizationProvider>
        </Grid>  

        <Grid item xs={12} sm={6} md={6} lg={6}>
          <Box className="labelproj">
            End Date <span style={{ color: "red" }}>*</span>
          </Box>
          <LocalizationProvider fullWidth dateAdapter={AdapterDateFns}>
            <DatePicker
              id="enddate"
              name="enddate"
              sx={{ width: "100%" }}
              format = "dd/MM/yyyy"
              renderInput={(params) => <TextField {...params} fullWidth />}
              minDate={exportObj?.startDate}
              value={exportObj?.endDate}
              onAccept={(date) => {
                setExportObj({ ...exportObj, endDate: date });
              }}
            />
          </LocalizationProvider>
        </Grid>

          </Grid> */}

      <Grid container sx={{pr:4}}>
        <Grid item xs={12} style={{ textAlign: "right", padding: "20px 0px" }}>
          <Button
            className="download-btn"
            // startIcon={
            //   <DownloadForOfflineIcon
            //     style={{ fontSize: 30, verticalAlign: "middle" }}
            //   />
            // }
            onClick={handleBuildAffinity}
            // onClick={handleExport}
          >
            Click to Build Affinity
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

export default AddAffinity;
