import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import context from "context/context";
import { toastCss, theme } from "config/config";
import Grid from "@mui/material/Grid";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
// import VisibilityIcon from '@mui/icons-material/Visibility';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import "./Configurationpage.css";
import Paper from '@mui/material/Paper';
import Modal from '@mui/material/Modal';
import IconButton from '@mui/material/IconButton';
import Loader from 'components/Loader';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import Pagination from '@mui/material/Pagination';
import Typography from '@mui/material/Typography';
import ReplyIcon from '@mui/icons-material/Reply';
import InputAdornment from '@mui/material/InputAdornment'
import SearchIcon from '@mui/icons-material/Search';
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { parseISO, format } from 'date-fns';
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import HolidaysAPIs from '../../../apis/panel/ConfigurationPage/configuration.js'

const CustomWidthTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))({
    [`& .${tooltipClasses.tooltip}`]: {
        maxWidth: 250,
        cursor: "pointer",
        backgroundColor: "#002c5d",
        color: "#ffffff",
    },
});



const Holidays = () => {
    const { dispatch } = useContext(context);
    const [isActive, setIsActive] = useState(true);
    const [isOptional, setIsOptional] = useState(false);
    const [holidayDate, setHolidayDate] = useState(null)
    const [isActiveEdit, setIsActiveEdit] = useState('')
    const [date, setDate] = useState(null)
    const Navigate = useNavigate()
    const [holidayList, setHolidayList] = useState([])
    const [viewModalOpen, setViewModalOpen] = useState(false); // State to manage view modal visibility
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [createObj, setCreateObj] = useState({
        holidayId: "",
        holidayName: "",
        holidayDate: "",
        isOptional: "",
        description: "",
        isActive: "",
    });
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [deleteIndex, setDeleteIndex] = useState(null);
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(1);
    const [offset, setOffset] = useState(1);
    const [count, setCount] = useState(0)
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [searchValue, setSearchValue] = useState("");
    const itemsPerPage = 10;

    const { getAllHolidaysAPI, getCreateHolidaysAPI, getUpdateHolidaysAPI } = HolidaysAPIs()

    const handleBackButtonClick = () => {
        Navigate('/panel/configurationPage');
    };

    

    const handleChangeIsActive = (event) => {

        setIsActive(event.target.value)
       
    };

    const handleEditIsActive = (event) => {

        setIsActiveEdit(event.target.value)
      
    };


    const handleChangeIsOptional = (event) => {
        setIsOptional(event.target.value)
    }

    const handleHolidayDate = async (value) => {
        setHolidayDate(value)

    }

    const handleViewClick = () => {
        setViewModalOpen(true); 
    };

    const handleCloseViewModal = () => {
        setIsActive(true)
        setIsOptional(false)
        setHolidayList([])
        setHolidayDate(null)
        setCreateObj("")
        setViewModalOpen(false); 
    };

   

    
    const handleEditClick = (index) => {

        const clickedItem = holidayList.find(item => item.holidayId === index);

        const parsedHolidayDate = parseISO(clickedItem?.holidayDate);
        setCreateObj({
            holidayId: clickedItem?.holidayId,
            holidayName: clickedItem?.holidayName,
            holidayDate: parsedHolidayDate,
            isOptional: clickedItem?.isOptional,
            description: clickedItem?.description,
            isActive: clickedItem?.isActive,
        })


        setIsActiveEdit(clickedItem?.isActive)
        setIsOptional(clickedItem?.isOptional)
        setHolidayDate(parsedHolidayDate)
        setEditModalOpen(true);
    };
    
    const formatDate = (date) => {
        return format(date, 'yyyy-MM-dd');
    };

    const formatDateView = (date) => {
        return format(new Date(date), 'dd-MMM-yyyy');
    };



    const handleCloseEditModal = () => {
        setIsActive(true)
        setIsOptional(false)
        setHolidayList([])
        setHolidayDate(null)
        setCreateObj("")

        setEditModalOpen(false);
    };


    const handleGetAllHolidays = async () => {
        let values;
        values = {
            offset: (offset - 1) * 10,
            limit: 10,
        };
        if (searchValue) {
            values = {
                offset: 0,
                limit: 10,
                searchValue,
            };
        }
        try {
            setLoading(true);
            let res = await getAllHolidaysAPI(values);
            setHolidayList(res?.data?.holidays || []);

            setCount(res?.data?.count);
            setLoading(false);
        }
        catch (err) {
            setLoading(false);
        }
    };

    const handleCreateHoliday = async (e) => {
        try {

            if (
                createObj.holidayName === "" || holidayDate === "" || isOptional === "" || createObj.description === "" || isActive === ""
            ) {
                return toast.error(
                    "Please fill in all required fields ."
                );
            }
            

            if (((createObj.holidayName).trim()=='')) {
                return toast.error("Holiday name  should contain a valid name.");
            }

            if (createObj?.holidayId) {
                const values = {
                    "holiday_id": createObj.holidayId,
                    "holidayName": createObj.holidayName.trim(),
                    "holidayDate": formatDate(holidayDate),
                    "isOptional": isOptional,
                    "description": createObj.description,
                    "isActive": isActiveEdit,
                }
                setLoading(true)
                const data = await getUpdateHolidaysAPI(values)
                if (data?.status === "Ok") {
                    toast.success("Updated", toastCss);
                    setCreateObj({
                        holidayId: "",
                        holidayName: "",
                        holidayDate: "",
                        isOptional: "",
                        description: "",
                        isActive: "",
                    });
                    setIsActive(true)
                    setIsActiveEdit('')
                    setIsOptional(false)
                    setHolidayDate(null)
                    setEditModalOpen(false);
                    setLoading(false)
                } else {
                    toast.error(data.error.message, toastCss);
                    setCreateObj({
                        holidayId: "",
                        holidayName: "",
                        holidayDate: "",
                        isOptional: "",
                        description: "",
                        isActive: "",
                    });
                    setIsActive(true)
                    setIsOptional(false)
                    setIsActiveEdit('')
                    setHolidayDate(null)
                    setEditModalOpen(false);

                }
            } else {

                const values = {


                    "holidayName": createObj.holidayName,
                    "holidayDate": formatDate(holidayDate),
                    "isOptional": isOptional,
                    "description": createObj.description,
                    "isActive": isActive,
                };
                setLoading(true)
                const data = await getCreateHolidaysAPI(values);

                if (data.status === "Ok") {
                    toast.success("Created", toastCss);
                    setCreateObj({
                        holidayId: "",
                        holidayName: "",
                        holidayDate: "",
                        isOptional: "",
                        description: "",
                        isActive: "",
                    });
                    setIsActive(true)
                    setIsOptional(false)
                    setHolidayDate(null)
                    setViewModalOpen(false); 
                    setLoading(false)

                } else {
                    toast.error(data.error.message, toastCss);
                    setCreateObj({
                        holidayId: "",
                        holidayName: "",
                        holidayDate: "",
                        isOptional: "",
                        description: "",
                        isActive: "",
                    });
                    setIsActive(true)
                    setIsOptional(false)
                    setHolidayDate(null)
                    setViewModalOpen(false); 

                }

            }
        } catch (err) {
            console.error(err);
        }
    };


  
    useEffect(() => {
        handleGetAllHolidays();
    }, [viewModalOpen, editModalOpen, page, searchValue, offset, itemsPerPage])

    

    const indexOfLastItem = currentPage * perPage;
    const indexOfFirstItem = indexOfLastItem - perPage;

    const handlePagination = (value) => {

        setOffset(value);
    };

    return (
        <div style={{ backgroundColor: "white" }}>
            <Grid style={{
                marginBottom: "2%"
            }}>
                <Grid container  >

                    <Grid container alignItems="center" item xl={4} lg={4} md={4} sm={12} xs={12}>
                        <Box
                            sx={{
                                
                                width: '30px',
                                height: '30px',
                                borderRadius: '50%',
                               
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                marginLeft: '10%'
                                
                            }}
                            onClick={handleBackButtonClick}
                        >
                            <ReplyIcon />
                        </Box>

                        <Box sx={{ pl: 3 }} style={{
                            color: "#0056B2",
                        }}><h2>Holidays</h2></Box>
                    </Grid>
                    <Grid style={{ alignSelf: 'center', justifyContent: "center" }} sx={{ pl: 2 }} item xl={2} lg={2} md={2} sm={12} xs={12} >

                    </Grid>
                    <Grid style={{ alignSelf: 'center' }} sx={{ pl: 3 }} item xl={3.5} lg={3.5} md={3.5} sm={12} xs={12}>
                        <TextField
                            className="editvalue"
                            value={searchValue}
                            onChange={(e) => setSearchValue(e.target.value)}
                            sx={{ width: '85%', mt: 3 }}
                            label="Search holiday Name"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton>
                                            <SearchIcon onClick={handleGetAllHolidays} />
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                            InputLabelProps={{
                                shrink: true, 
                                style: { paddingTop: '8px', fontSize: '1rem', fontWeight: 600, color: '#333' } // Adjust padding and other styles
                            }}

                        />
                    </Grid>

                    <Grid style={{ alignSelf: 'center', marginTop: "2%", justifyContent: "center" }} item xl={2} lg={2} md={2} sm={12} xs={12}>
                        <Button className="AddEmployerButton"
                            onClick={handleViewClick}
                        >Add Values</Button>
                    </Grid>
                </Grid>
            </Grid>

            <Grid sx={{ pl: "20px", pr: "20px" }}>
                <TableContainer component={Paper}>
                    <Table aria-label="collapsible table">
                        <TableHead>
                            <TableRow>
                                <TableCell><b > S.No</b></TableCell>
                                <TableCell><b >HolidayName</b></TableCell>
                                <TableCell><b >HolidayDate</b></TableCell>
                                <TableCell><b >IsOptional</b></TableCell>
                                <TableCell><b >Status</b></TableCell>
                                <TableCell><b >Description</b></TableCell>
                                <TableCell><b >Action</b></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <Loader loading={loading} />
                            {holidayList.map((holiday, index) => (
                                <TableRow key={index}>
                                    <TableCell className="TableCell">{(offset - 1) * itemsPerPage + index + 1}</TableCell>
                                   
                                        <TableCell> <div className="holidayDescription">{holiday?.holidayName}</div></TableCell>
                                   
                                    <TableCell className="TableCell">{formatDateView(holiday?.holidayDate)}</TableCell>
                                    <TableCell className="TableCell">
                                        {
                                            (holiday?.isOptional) ?
                                                <span style={{ color: "green", fontWeight: 700, fontSize: "14px" }}> Yes </span>
                                                : <span style={{ color: "red", fontWeight: "700", fontSize: "14px" }}> No </span>
                                        }

                                    </TableCell>
                                    <TableCell className="TableCell">
                                        {
                                            (holiday.isActive) ?
                                                <span style={{ color: "green", fontWeight: 700, fontSize: "14px" }}> Active </span>
                                                : <span style={{ color: "red", fontWeight: "700", fontSize: "14px" }}> InActive </span>
                                        }</TableCell>
                                    <CustomWidthTooltip
                                        title={holiday?.description}
                                    >
                                        <TableCell>
                                            <div className="holidayDescription" >{holiday?.description} </div>
                                        </TableCell>
                                    </CustomWidthTooltip>
                                    <TableCell  >
                                        <IconButton aria-label="view" style={{ color: '#E97000' }} onClick={() => handleEditClick(holiday?.holidayId)}>
                                            <ModeEditIcon />
                                        </IconButton>
                                       
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                <Pagination
                    count={(count / 10) % 1 == 0 ? Math.floor(count / 10) : Math.floor(count / 10 + 1)}
                    page={offset}
                    siblingCount={1}
                    onChange={(event, value) => {
                        handlePagination(value);
                    }}
                    color="primary"
                />
            </Box>
            <Modal
                open={viewModalOpen}
                onClose={handleCloseViewModal}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '60%',
                    transform: 'translate(-50%, -50%)',
                    width: "49%",
                    bgcolor: 'background.paper',

                    boxShadow: 24,
                    p: 4,
                }}>

                    <Typography variant="h6" id="modal-title" style={{ color: "#0070E9" }}>
                        Holiday
                    </Typography>
                    <Grid container spacing={2} sx={{ pl: 4, pr: 5 }} >
                        <Grid item xs={12} sm={6} md={6} lg={6} sx={{ mt: 2 }}>
                            <Box className="editlabel">Name <span style={{ color: 'red' }}>*</span></Box>
                            <TextField
                                className="editvalue"
                                placeholder="Enter Name"
                                value={createObj?.holidayName}
                                onChange={(e) => {
                                    setCreateObj({
                                        ...createObj,
                                        holidayName: e.target.value,
                                    });
                                }}
                                sx={{ width: '85%' }}
                            />
                        </Grid>

                        <Grid item xs={12} sm={4} md={4} lg={4} sx={{ mt: 2 }}>
                            <Box className="editlabel">Status <span style={{ color: 'red' }}>*</span></Box>
                            <FormControl sx={{ width: '130%' }} >
                                <Select
                                    value={isActive || 'true'}

                                    onChange={handleChangeIsActive}
                                >
                                    <MenuItem value={true}>Active</MenuItem>
                                    <MenuItem value={false}>Inactive</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} sx={{ pl: 4, pr: 5 }} >
                        <Grid item xs={12} sm={6} md={6} lg={6} sx={{ mt: 2 }}>
                            <Box className="editlabel">Date <span style={{ color: 'red' }}>*</span></Box>
                            <LocalizationProvider fullWidth dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    id="holidayDate"
                                    name="holidayDate"
                                    sx={{ width: "85%" }}
                                    format="dd-MMM-yyyy"
                                    renderInput={(params) => <TextField {...params} fullWidth />}
                                    
                                    value={holidayDate}
                                    onChange={(newValue) => handleHolidayDate(newValue)}


                                />
                            </LocalizationProvider>
                        </Grid>

                        <Grid item xs={12} sm={4} md={4} lg={4} sx={{ mt: 2 }}>
                            <Box className="editlabel">Is Optional <span style={{ color: 'red' }}>*</span></Box>
                            <FormControl sx={{ width: '130%' }} >
                                <Select
                                    value={isOptional || 'false'}
                                    onChange={handleChangeIsOptional}


                                >

                                    <MenuItem value={true}>Yes</MenuItem>
                                    <MenuItem value={false}>No</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} sx={{ pl: 4, pr: 10 }} >
                        <Grid item xs={12} sm={12} md={12} lg={12} sx={{ mt: 2 }}>
                            <Box className="editlabel">Description </Box>
                            <TextField
                                id="description"
                                fullWidth
                                value={createObj?.description}
                                placeholder="Enter Description"
                                sx={{
                                    "& .MuiInputBase-input": {
                                        alignItems: "top",
                                        height: "50px",
                                        width: "90%",
                                        border: "1px solid #B2D4F8"
                                    },
                                }}
                                variant="outlined"
                                
                                onChange={(e) => {
                                    setCreateObj({
                                        ...createObj,
                                        description: e.target.value,
                                    });
                                }}
                            />
                        </Grid>

                    </Grid>
                    <Grid container spacing={1} sx={{ pl: 4, pr: 5, display: 'flex', justifyContent: 'flex-end', mt: 2 }} >

                        <Grid item xs={12} sm={2} md={2} lg={2}>
                            <Button sx={{ marginRight: '20px' }} className="cancelButton" onClick={handleCloseViewModal}>Cancel</Button>
                        </Grid>

                        <Grid item xs={12} sm={2} md={2} lg={2}>
                            <Button sx={{ marginLeft: '20px' }}
                                className="saveButton"
                                onClick={handleCreateHoliday}>

                                Save
                            </Button>
                        </Grid>
                    </Grid>


                </Box>
            </Modal>

            <Modal
                open={editModalOpen}
                onClose={handleCloseEditModal}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: "49%",
                    bgcolor: 'background.paper',

                    boxShadow: 24,
                    p: 4,
                }}>
                    <Typography variant="h6" id="modal-title" style={{ color: "#0070E9" }}>
                        Holiday
                    </Typography>
                    <Grid container spacing={2} sx={{ pl: 4, pr: 5 }} >
                        <Grid item xs={12} sm={6} md={6} lg={6} sx={{ mt: 2 }}>
                            <Box className="editlabel">Name <span style={{ color: 'red' }}>*</span></Box>
                            <TextField
                                className="editvalue"
                                value={createObj?.holidayName}
                                onChange={(e) => {
                                    setCreateObj({
                                        ...createObj,
                                        holidayName: e.target.value,
                                    });
                                }}
                                sx={{ width: '85%' }}
                            />
                        </Grid>

                        <Grid item xs={12} sm={4} md={4} lg={4} sx={{ mt: 2 }}>
                            <Box className="editlabel">Status <span style={{ color: 'red' }}>*</span></Box>
                            <FormControl sx={{ width: '130%' }} >
                                <Select
                                    value={isActiveEdit}
                                    onChange={handleEditIsActive}


                                >
                                    <MenuItem value={true}>Active</MenuItem>
                                    <MenuItem value={false}>Inactive</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} sx={{ pl: 4, pr: 5 }} >
                        <Grid item xs={12} sm={6} md={6} lg={6} sx={{ mt: 2 }}>
                            <Box className="editlabel">Date <span style={{ color: 'red' }}>*</span></Box>
                            <LocalizationProvider fullWidth dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    id="holidayDate"
                                    name="holidayDate"
                                    sx={{ width: "85%" }}
                                    format="dd-MMM-yyyy"
                                    renderInput={(params) => <TextField {...params} fullWidth />}
                                   
                                    value={holidayDate}
                                    onChange={(newValue) => handleHolidayDate(newValue)}


                                />
                            </LocalizationProvider>
                        </Grid>

                        <Grid item xs={12} sm={4} md={4} lg={4} sx={{ mt: 2 }}>
                            <Box className="editlabel">Is Optional <span style={{ color: 'red' }}>*</span></Box>
                            <FormControl sx={{ width: '130%' }} >
                                <Select
                                    value={isOptional || 'false'}
                                    onChange={handleChangeIsOptional}


                                >

                                    <MenuItem value={true}>Yes</MenuItem>
                                    <MenuItem value={false}>No</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} sx={{ pl: 4, pr: 10 }} >
                        <Grid item xs={12} sm={12} md={12} lg={12} sx={{ mt: 2 }}>
                            <Box className="editlabel">Description </Box>
                            <TextField
                                id="description"
                                fullWidth
                                value={createObj?.description}
                                placeholder="Enter Description"
                                sx={{
                                    "& .MuiInputBase-input": {
                                        alignItems: "top",
                                        height: "50px",
                                        width: "90%",
                                        border: "1px solid #B2D4F8"
                                    },
                                }}
                                variant="outlined"
                               
                                onChange={(e) => {
                                    setCreateObj({
                                        ...createObj,
                                        description: e.target.value,
                                    });
                                }}
                            />
                        </Grid>

                    </Grid>

                    <Grid container spacing={{ xs: 2, md: 3 }} sx={{ mt: 4, width: "83%", justifyContent: "flex-end", paddingTop: "52px" }}>
                        <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end' }}>
                            <Button className="cancelButton" onClick={handleCloseEditModal}>Cancel</Button>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleCreateHoliday}
                                className="saveButton"
                            >
                                Update
                            </Button>
                        </Grid>
                    </Grid>

                </Box>
            </Modal>

          
        </div >

    )


}

export default Holidays