import { Fragment, useState, useEffect, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import context from "context/context";
import AuthAPI from "apis/auth/auth";

// material ui
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Logo from "assets/images/logo.png";

export default function ResetPassword() {
    const { state, dispatch } = useContext(context);
    const { changePasswordAPI } = AuthAPI();
    const [email, setEmail] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [conPassword, setConPassword] = useState("");
    const [disableBtn, setDisableBtn] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const navigate = useNavigate();
    let params = useParams();
    
    useEffect(() => {
        var decodedStringAtoB = atob(params.id);
        setEmail(decodedStringAtoB)
    }, []);

    const handleSubmit = async () => {    
        try {
            setDisableBtn(true);
            dispatch({ type: "SET_LOADING", payload: true });                                    
            if (!newPassword) {            
                toast.error("Please fill all the required fields.", {
                    taskId: "create-employee-task-error",
                });
            }
            if (newPassword != conPassword) {
                toast.error("New password and confirm password mismatch", {
                    taskId: "create-employee-task-error",
                });
            }
            let values = {
                email: email,
                password: newPassword
            };
            let res = await changePasswordAPI(values);
            if(res.status=="SUCCESS"){
                toast.success("New password updated", {
                    taskId: "create-employee-task-error",
                });
                setDisableBtn(false);
                dispatch({ type: "SET_LOADING", payload: false });
                return navigate("/");
            }        
            setDisableBtn(false);
            dispatch({ type: "SET_LOADING", payload: false });
            return        
        } 
        catch (err) {}
    };

    return (
        <Fragment>
            <Box>
                <img className='logo-login' src={Logo} alt="logo" />
            </Box>
            <Box className='login-box'>
                <Typography style={{textAlign:"center",color:"#fe602f"}}>Reset Psssword</Typography>        
                <Card variant="outlined" sx={{ padding: "15px" }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>                        
                            <Grid item xs={12} sm={12} lg={12}>
                                <TextField
                                    name="newpassword"
                                    id="newpassword"
                                    label="New Password"
                                    type="password"
                                    margin="normal"
                                    required
                                    fullWidth
                                    autoComplete="current-password"
                                    InputLabelProps={{
                                        shrink: true,
                                        style: { width: '100%' }
                                    }}
                                    value={newPassword}
                                    onChange={(e) => {
                                        setNewPassword(e.target.value);
                                    }}
                                    
                                    
                                />
                                
                                
                            </Grid>
                            <Grid item xs={12} sm={12} lg={12}>
                                <TextField
                                    name="conpassword"
                                    id="conpassword"
                                    label="Confirm Password"
                                    type="password"
                                    margin="normal"
                                    required
                                    fullWidth
                                    autoComplete="current-password"
                                    InputLabelProps={{
                                        shrink: true,
                                        style: { width: '100%' }
                                    }}
                                    value={conPassword}
                                    onChange={(e) => {
                                        setConPassword(e.target.value);
                                    }}
                                /> 
                            </Grid>
                            <Grid item xs={12} display="flex" justifyContent="flex-end">
                                <Button 
                                    type="submit"
                                    variant="contained"
                                    disabled={disableBtn}
                                    onClick={() => {                            
                                        handleSubmit();
                                    }}
                                >
                                    Reset
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Card>
            </Box>
        </Fragment>
    );
}
