import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import context from "context/context";

import getAllStates from "apis/panel/ConfigurationPage/configuration";
import getCreate from "apis/panel/ConfigurationPage/configuration"
import getUpdateDegree from "apis/panel/ConfigurationPage/configuration";
import getDeleteDegree from "apis/panel/ConfigurationPage/configuration";
import getAllMasterCities from "apis/panel/ConfigurationPage/configuration"
import { toastCss, theme } from "config/config";



import Grid from "@mui/material/Grid";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
// import VisibilityIcon from '@mui/icons-material/Visibility';
import ModeEditIcon from '@mui/icons-material/ModeEdit';

import "./Configurationpage.css";
import Paper from '@mui/material/Paper';
import Modal from '@mui/material/Modal';

import IconButton from '@mui/material/IconButton';

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

import MenuItem from '@mui/material/MenuItem';

import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';


import Pagination from '@mui/material/Pagination';

import DeleteIcon from '@mui/icons-material/Delete';

import Typography from '@mui/material/Typography';
import Loader from 'components/Loader';
import ReplyIcon from '@mui/icons-material/Reply';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment'
import Autocomplete from '@mui/material/Autocomplete'


const EmployeeRoster = () => {
    const { dispatch } = useContext(context);
   
    const { getAllStatesAPI } = getAllStates();
    const { getCreatecitiesAPI } = getCreate();
    const { getUpdatecitiesAPI } = getUpdateDegree()
    const { getDeletecitiesAPI } = getDeleteDegree()
   
    const { getAllMasterCitiesAPI } = getAllMasterCities();

    const [isActive, setIsActive] = useState('');
    const Navigate = useNavigate()
    const [titleList, setTitleList] = useState([])
    const [allStatesId, setAllStatesId] = useState([])
    const [offset, setOffset] = useState(1);
    const [count, setCount] = useState(0)
    const [viewModalOpen, setViewModalOpen] = useState(false); // State to manage view modal visibility
    const [searchValue, setSearchValue] = useState("");
    const [filterValue, setFilterValue] = useState("");
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [createObj, setCreateObj] = useState({
        cityId: "",
        cityName: "",
        isActive: "",
        latitude: "",
        longitude: "",
        stateId: ""
    });
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [deleteIndex, setDeleteIndex] = useState(null);
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(1);
    const itemsPerPage = 10;

    const handleBackButtonClick = () => {
        Navigate('/panel/configurationPage');
    };


    const handleChangeIsActive = (event) => {
        
        setCreateObj({
            ...createObj,
            isActive: event.target.value,
        });
        setIsActive(event.target.value);

    };

    const handleViewClick = () => {
        setViewModalOpen(true); // Open the view modal when View button is clicked
    };



    const handleCloseViewModal = () => {
        setIsActive("")
        setTitleList([])
        setCreateObj("")
        setViewModalOpen(false); // Close the view modal
    };

    const handleDeleteClick = (index) => {
        setDeleteModalOpen(true);
        setDeleteIndex(index); // Pass the index of the item to be deleted
    };



    const handleDeleteModalcloseModal = () => {
        setDeleteModalOpen(false); // Close the add modal
        setDeleteIndex(null);
    };



    const handleEditClick = (index) => {
        const clickedItem = titleList.find(item => item.cityId === index);

        setCreateObj({
            cityId: clickedItem.cityId,
            cityName: clickedItem.cityName,
            latitude: clickedItem.latitude,
            longitude: clickedItem.longitude,
            isActive: clickedItem.isActive
        })

        setIsActive(clickedItem.isActive)
        setEditModalOpen(true);
    };


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    }

    const handleCloseEditModal = () => {
        setCreateObj({
            cityId: "",
            cityName: "",
            latitude: "",
            longitude: "",
            isActive: "",
        })
        setIsActive("")
        setEditModalOpen(false);
    };


    const handleGetAllStates = async () => {
        try {
            setLoading(true);
            let res = await getAllStatesAPI();
            setAllStatesId(res?.data || []);
            setLoading(false);
        }
        catch (err) {
            setLoading(false);
        }
    };




    const handleGetAllCities = async () => {
        let values;
        values = {
            offset: (offset - 1) * 10,
            limit: 10,
        };
        if (searchValue) {
            values = {
                offset: 0,
                limit: 10,
                searchValue,
            };
        }
        if (filterValue) {
            values = {
                ...values,
                filterValue,
            };
        }
        try {
            setLoading(true);
            let res = await getAllMasterCitiesAPI(values);

            setTitleList(res?.data?.city || []);
            setCount(res?.data?.count);

            setLoading(false);
        }
        catch (err) {
            setLoading(false);
        }
    };


    const handleCreateLeave = async (e) => {

        try {
            if (
                createObj.stateId === "" ||
                createObj.cityName === "" ||
                isActive === "" ||
                createObj.latitude === "" ||
                createObj.longitude === ""

            ) {
                return toast.error(
                    "Please fill in all required fields ."
                );
            }
            if (createObj?.cityId) {
                const values = {
                    cityId: createObj.cityId,
                    cityName: createObj.cityName,
                    latitude: createObj.latitude,
                    longitude: createObj.longitude,
                    isActive: createObj.isActive 
                }
                const data = await getUpdatecitiesAPI(values)
                if (data.status === "Ok") {
                    toast.success("Updated", toastCss);
                    setCreateObj({
                        cityId: "",
                        cityName: "",
                        latitude: "",
                        longitude: "",
                        isActive: "",
                    });
                    setIsActive("")
                    setEditModalOpen(false);
                }else{
                    toast.error(data.error.message, toastCss);
                    setCreateObj({
                        cityId: "",
                        cityName: "",
                        latitude: "",
                        longitude: "",
                        isActive: "",
                    });
                    setIsActive("")
                    setEditModalOpen(false);

                }
            } else {

                const values = {
                    stateId: createObj.stateId,
                    cityName: createObj.cityName,
                    latitude: createObj.latitude,
                    longitude: createObj.longitude,
                    isActive: isActive
                };


                const data = await getCreatecitiesAPI(values);

                if (data.status === "Ok") {
                    toast.success("Created", toastCss);
                    setCreateObj({
                        cityName: "",
                        isActive: "",
                        latitude: "",
                        longitude: "",
                        stateId: ""
                    });
                    setIsActive("")
                    setViewModalOpen(false);
                }else{
                    toast.error(data.error.message, toastCss);
                    setCreateObj({
                        cityName: "",
                        isActive: "",
                        latitude: "",
                        longitude: "",
                        stateId: ""
                    });
                    setIsActive("")
                    setViewModalOpen(false);

                }
            }
        } catch (err) {
            console.error(err);
        }
    };



    // const handleConfirmDelete = async () => {
    //     try {
    //         if (deleteIndex !== null) {
    //             const cityId = deleteIndex;
    //             const res = await getDeletecitiesAPI(cityId);

    //             if (res?.status === "Ok") {
    //                 if( (offset-1)*itemsPerPage+1==count){
    //                     if(offset>1){
    //                      setOffset(offset-1)
     
    //                     }
    //                 }
    //                 setDeleteModalOpen(false);
    //                 toast.success("Item deleted successfully", toastCss);
    //             }  else {
    //                 setDeleteModalOpen(false);
    //                 toast.error("Failed to delete item", toastCss);
    //             }
    //         }
    //     } catch (error) {
    //         console.error('Error deleting item:', error);
    //     }
    // };
    useEffect(() => {
        handleGetAllStates();
        handleGetAllCities()
  
    }, [viewModalOpen, editModalOpen,searchValue, offset, page, itemsPerPage])


    const indexOfLastItem = currentPage * perPage;
    const indexOfFirstItem = indexOfLastItem - perPage;

    const handlePagination = (value) => {

        setOffset(value);
    };


    return (
        <div style={{ backgroundColor: "white" }}>
            <Grid style={{
                marginBottom: "2%"
            }}>
                <Grid container  >

                    <Grid container alignItems="center" item xl={4} lg={4} md={4} sm={12} xs={12}>
                        <Box
                            sx={{
                                // position: 'absolute',
                                width: '30px',
                                height: '30px',
                                borderRadius: '50%',

                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                marginLeft:'10%'
                                // left: "245px",
                            }}
                            onClick={handleBackButtonClick}
                        >
                            <ReplyIcon />
                        </Box>
                        <Box sx={{ pl: 3 }} style={{
                            color: "#0056B2",
                        }}><h2>Cities</h2></Box>
                    </Grid>
                    <Grid style={{ alignSelf: 'center', justifyContent: "center" }} sx={{ pl: 2 }} item xl={2} lg={2} md={2} sm={12} xs={12} >

</Grid>
                    <Grid style={{ alignSelf: 'center' }} sx={{ pl: 3 }} item xl={3.5} lg={3.5} md={3.5} sm={12} xs={12}>
                        <TextField
                            className="editvalue"
                            value={searchValue}
                            onChange={(e) => setSearchValue(e.target.value)}
                            sx={{ width: '85%', mt: 1 }}
                            label="Search city name"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton>
                                            <SearchIcon onClick={handleGetAllCities} />
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                            InputLabelProps={{
                              
                                shrink: true, // To keep label from floating upwards when focused or filled
                                style: { paddingTop: '8px', fontSize: '1rem', fontWeight: 600, color: '#333' } // Adjust padding and other styles
                            }}

                        />
                    </Grid>
                   
                    <Grid style={{ alignSelf: 'center', marginTop: "2%", justifyContent: "center" }} item xl={2} lg={2} md={2} sm={12} xs={12}>
                        <Button className="AddEmployerButton"
                            onClick={handleViewClick}
                        >Add Values</Button>
                    </Grid>
                </Grid>
            </Grid>
            <Grid sx={{ pl: "20px", pr: "20px" }}>
                <TableContainer component={Paper}>
                    <Table aria-label="collapsible table">
                        <TableHead>
                            <TableRow>
                                <TableCell><b className="tablecellforemployeeRoster1">S.No</b></TableCell>
                                <TableCell><b className="tablecellforemployeeRoster1">CityName</b></TableCell>
                                <TableCell><b className="tablecellforemployeeRoster1">StateName</b></TableCell>
                                <TableCell><b className="tablecellforemployeeRoster1">Status</b></TableCell>
                                <TableCell><b className="tablecellforemployeeRoster1">Action</b></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <Loader loading={loading} />
                            {titleList?.map((value, index) => (
                                <TableRow key={index}>
                                    <TableCell style={{ padding: '20px', paddingLeft: '90px' }} className="TableCell">{(offset - 1) * itemsPerPage + index + 1}</TableCell>
                                    <TableCell style={{ padding: '20px', paddingLeft: '90px' }} className="TableCell">{value.cityName}</TableCell>
                                    <TableCell style={{ padding: '20px', paddingLeft: '90px' }} className="TableCell">  {allStatesId.find(state => state.stateId === value.stateId)?.stateName}</TableCell>
                                    <TableCell style={{ padding: '20px', paddingLeft: '90px' }} className="TableCell">
                                        {
                                            (value.isActive) ?
                                                <span style={{ color: "green" }}> Active </span>
                                                : <span style={{ color: "red" }}> InActive </span>
                                        }
                                    </TableCell>
                                    <TableCell style={{ padding: '20px', paddingLeft: '80px' }} >
                                        <IconButton aria-label="view" style={{ color: "#E97000" }} onClick={() => handleEditClick(value.cityId)}>
                                            <ModeEditIcon />
                                        </IconButton>
                                        {/* <IconButton aria-label="add" style={{ color: "grey" }} onClick={() => handleDeleteClick(value.cityId)}>
                                            <DeleteIcon />
                                        </IconButton> */}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                <Pagination
                    count={(count / 10) % 1 == 0 ? Math.floor(count / 10) : Math.floor(count / 10 + 1)}
                    page={offset}
                    siblingCount={1}
                    onChange={(event, value) => {
                        handlePagination(value);
                    }}
                    color="primary"
                />
            </Box>
            <Modal
                open={viewModalOpen}
                onClose={handleCloseViewModal}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '60%',
                    transform: 'translate(-50%, -50%)',
                    width: "49%",
                    bgcolor: 'background.paper',

                    boxShadow: 24,
                    p: 4,
                }}>

                    <Typography variant="h6" id="modal-title" style={{ color: "#0070E9" }}>
                        Cities
                    </Typography>
                    <Grid container spacing={2} sx={{ pl: 4, pr: 5, mt: 2 }} >

                        <Grid item xs={12} sm={4} md={4} lg={4} sx={{ mt: 2 }}>
                            <Box className="editlabel">StateId <span style={{ color: 'red' }}>*</span></Box>
                            {/* <TextField
                                select
                                className="editvalue"
                                value={createObj?.stateId || ""}
                                onChange={(e) => {
                                    setCreateObj({
                                        ...createObj,
                                        stateId: e.target.value,
                                    });
                                }}
                                sx={{ width: '95%' }}
                            >

                                {allStatesId.map((state) => (
                                    <MenuItem key={state.stateId} value={state.stateId}>
                                        {state.stateName}
                                    </MenuItem>
                                ))}
                            </TextField> */}
                            <Autocomplete
                value={allStatesId.find(state => state.stateId === createObj.stateId) || null}
                onChange={(event, newValue) => {
                    setCreateObj({
                        ...createObj,
                        stateId: newValue ? newValue.stateId : "", 
                    });
                }}
                options={allStatesId}
                getOptionLabel={(option) => option.stateName}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        className="editvalue"
                        sx={{ width: '95%' }}
                    />
                )}
            />
                        </Grid>

                        <Grid item xs={12} sm={4} md={4} lg={4} sx={{ mt: 2 }}>
                            <Box className="editlabel">CityName <span style={{ color: 'red' }}>*</span></Box>
                            <TextField
                                className="editvalue"
                                value={createObj?.cityName}
                                onChange={(e) => {
                                    setCreateObj({
                                        ...createObj,
                                        cityName: e.target.value,
                                    });
                                }}
                                sx={{ width: '95%' }}
                            />
                        </Grid>

                        <Grid item xs={12} sm={4} md={4} lg={4} sx={{ mt: 2 }}>
                            <Box className="editlabel">Latitude <span style={{ color: 'red' }}>*</span></Box>
                            <TextField
                                className="editvalue"
                                value={createObj?.latitude}
                                onChange={(e) => {
                                    setCreateObj({
                                        ...createObj,
                                        latitude: e.target.value,
                                    });
                                }}
                                sx={{ width: '95%' }}
                            />
                        </Grid>

                        <Grid item xs={12} sm={4} md={4} lg={4} sx={{ mt: 2 }}>
                            <Box className="editlabel">Longitude <span style={{ color: 'red' }}>*</span></Box>
                            <TextField
                                className="editvalue"
                                value={createObj?.longitude}
                                onChange={(e) => {
                                    setCreateObj({
                                        ...createObj,
                                        longitude: e.target.value,
                                    });
                                }}
                                sx={{ width: '95%' }}
                            />
                        </Grid>`

                        <Grid item xs={12} sm={4} md={4} lg={4} sx={{ mt: 2 }}>

                            <Box className="editlabel">Status <span style={{ color: 'red' }}>*</span></Box>
                            <FormControl sx={{ width: '95%' }} >
                                <Select
                                    value={isActive}
                                    onChange={handleChangeIsActive}


                                >
                                    <MenuItem value={1}>Active</MenuItem>
                                    <MenuItem value={0}>Inactive</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>`

                    </Grid>




                    <Grid container spacing={1} sx={{ pl: 4, pr: 5, display: 'flex', justifyContent: 'flex-end', mt: 2 }} >


                        <Grid item xs={12} sm={2} md={2} lg={2}>
                            <Button sx={{ marginRight: '20px' }} className="cancelButton" onClick={handleCloseViewModal}>Cancel</Button>
                        </Grid>

                        <Grid item xs={12} sm={2} md={2} lg={2}>
                            <Button sx={{ marginLeft: '20px' }}
                                className="saveButton"
                                onClick={handleCreateLeave}>

                                Save
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Modal>
            <Modal
                open={editModalOpen}
                onClose={handleCloseEditModal}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: "49%",
                    bgcolor: 'background.paper',

                    boxShadow: 24,
                    p: 4,
                }}>
                    <Typography variant="h6" id="modal-title" style={{ color: "#0070E9" }}>
                        City
                    </Typography>
                    <Grid container spacing={2} sx={{ pl: 4, pr: 5, mt: 2 }} >

                        <Grid item xs={12} sm={4} md={4} lg={4} sx={{ mt: 2 }}>
                            <Box className="editlabel">CityName <span style={{ color: 'red' }}>*</span></Box>
                            <TextField
                                className="editvalue"
                                value={createObj?.cityName}
                                onChange={(e) => {
                                    setCreateObj({
                                        ...createObj,
                                        cityName: e.target.value,
                                    });
                                }}
                                sx={{ width: '95%' }}
                            />
                        </Grid>


                        <Grid item xs={12} sm={4} md={4} lg={4} sx={{ mt: 2 }}>
                            <Box className="editlabel">Latitude <span style={{ color: 'red' }}>*</span></Box>
                            <TextField
                                className="editvalue"
                                value={createObj?.latitude}
                                onChange={(e) => {
                                    setCreateObj({
                                        ...createObj,
                                        latitude: e.target.value,
                                    });
                                }}
                                sx={{ width: '95%' }}
                            />
                        </Grid>


                        <Grid item xs={12} sm={4} md={4} lg={4} sx={{ mt: 2 }}>
                            <Box className="editlabel">Status <span style={{ color: 'red' }}>*</span></Box>
                            <FormControl sx={{ width: '100%' }} >
                                <Select
                                    value={createObj?.isActive}
                                    onChange={handleChangeIsActive}
                                    style={{ border: "1.5px solid #B2D4F8", color: "grey" }}

                                >
                                    <MenuItem value="1">Active</MenuItem>
                                    <MenuItem value="0">Inactive</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>

                    </Grid>
                    <Grid container spacing={2} sx={{ pl: 4, pr: 5, mt: 2 }} >

                        <Grid item xs={12} sm={4} md={4} lg={4} sx={{ mt: 2 }}>
                            <Box className="editlabel">Longitude <span style={{ color: 'red' }}>*</span></Box>
                            <TextField
                                className="editvalue"
                                value={createObj?.longitude}
                                onChange={(e) => {
                                    setCreateObj({
                                        ...createObj,
                                        longitude: e.target.value,
                                    });
                                }}
                                sx={{ width: '95%' }}
                            />
                        </Grid>

                    </Grid>

                    <Grid container spacing={{ xs: 2, md: 3 }} sx={{ mt: 4, width: "83%", justifyContent: "flex-end", paddingTop: "52px" }}>
                        <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end' }}>
                            <Button className="cancelButton" onClick={handleCloseEditModal}>Cancel</Button>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleCreateLeave}
                                className="saveButton"
                            >
                                Update
                            </Button>
                        </Grid>
                    </Grid>

                </Box>
            </Modal>
            {/* <Modal
                open={deleteModalOpen}
                onClose={handleDeleteModalcloseModal}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: "55%",
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4,
                }}>
                    <Typography id="modal-title" variant="h6" component="h2" style={{ color: "#0070E9" }} sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}>
                        Confirm Deletion
                    </Typography>
                    <Typography id="modal-description" sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}>
                        Are you sure you want to delete this item?
                    </Typography>
                    <Grid container spacing={1} sx={{ pl: 10, mt: 5, justifyContent: "center" }}  >
                        <Grid item xs={12} sm={3} md={3} lg={3}>
                            <Button className="saveButton" onClick={handleConfirmDelete}>
                                Confirm
                            </Button>
                        </Grid>
                        <Grid item xs={12} sm={3} md={3} lg={3}>
                            <Button className="cancelButton" onClick={handleDeleteModalcloseModal}>
                                Cancel
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Modal> */}
        </div>

    )
}

export default EmployeeRoster