// custom pages
import config from "config/config";
import HandleApiResponse from "utils/handleApiResponse";

const projectAPI = () => {
  const { handleResponse, handleError } = HandleApiResponse();

  async function getAllMasterProjects(values) {
    try {
      const obj = {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      };
      const query = JSON.stringify(values);
      let res = await fetch(`${config.host}/panel/projects/findAllMaster?query=${query}`, obj);
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }

  async function getProjectsAPI(values) {
    try {
      const obj = {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      };
      const query = JSON.stringify(values);
      let res = await fetch(`${config.host}/panel/projects/setup?query=${query}`, obj);
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }

  async function getAllProjects() {
    try {
      const obj = {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      };
      let res = await fetch(`${config.host}/panel/projects/findAll`, obj);
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }

  async function getAllProjectstypeAPI() {
    try {
      const obj = {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      };
      let res = await fetch(`${config.host}/panel/projectTypes/findAll`, obj);
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }

  async function getProjectCreateAPI(data) {
    try {
      const obj = {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      };
      let res = await fetch(`${config.host}/panel/projects/create`, obj);
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }

  async function getprojectfindone(id) {
    try {
      const obj = {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      };
      let res = await fetch(`${config.host}/panel/projects/find/${id}`, obj);
      return handleResponse(res);
    } 
    catch (err) {
      handleError(err);
    }
  }

  async function getprojectupadte(data) {
    try {
      const obj = {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      };
      let res = await fetch(`${config.host}/panel/projects/update`, obj);
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }


  
  async function createEmployeeProject(data) {
    try {
      const obj = {
        method: "Post",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      };
      let res = await fetch(
        `${config.host}/panel/employees/assignProject`,
        obj
      );
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }


  /////////////////////// Project Owner Table APIs //////////////////////////

  async function getAllOwnerDetailsAPI(values) {
    try {
      const obj = {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      };
      const query = JSON.stringify(values);
      let res = await fetch(`${config.host}/panel/projects/owners?query=${query}`, obj);
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }

 
  async function createProjectOwnerAPI(data) {
    try {
      const obj = {
        method: "Post",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      };
      let res = await fetch(
        `${config.host}/panel/projects/owner`,
        obj
      );
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }

  async function updateProjectOwnerAPI(data) {
    try {
      const obj = {
        method: "put",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      };
      let res = await fetch(
        `${config.host}/panel/projects/owner`,
        obj
      );
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }


   /////////////////////// Shift Table APIs //////////////////////////

   async function getAllShiftDetailsAPI(values) {
    try {
      const obj = {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      };
      const query = JSON.stringify(values);
      let res = await fetch(`${config.host}/panel/projects/shifts?query=${query}`, obj);
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }

 
  async function createShiftAPI(data) {
    try {
      const obj = {
        method: "Post",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      };
      let res = await fetch(
        `${config.host}/panel/projects/shift`,
        obj
      );
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }

  async function updateShiftAPI(data) {
    try {
      const obj = {
        method: "put",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      };
      let res = await fetch(
        `${config.host}/panel/projects/shift`,
        obj
      );
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }


   /////////////////////// Task Assign Table APIs //////////////////////////

   async function getAllProjectTasksAPI(values) {
    try {
      const obj = {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      };
      const query = JSON.stringify(values);
      let res = await fetch(`${config.host}/panel/projects/tasks?query=${query}`, obj);
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }

 
  async function getAllProjectTypeTasksAPI(values) {
    try {
      const obj = {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      };
      const query = JSON.stringify(values);
      let res = await fetch(`${config.host}/panel/projects/type/tasks?query=${query}`, obj);
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }

  async function updateProjectTasksAPI(data) {
    try {
      const obj = {
        method: "PUT",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      };
      let res = await fetch(
        `${config.host}/panel/projects/tasks`,
        obj
      );
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }

 

  return {
    getAllMasterProjects,
    getProjectsAPI,
    getAllProjects,
    getAllProjectstypeAPI,
    getProjectCreateAPI,
    getprojectfindone,
    getprojectupadte,
    createEmployeeProject,

    getAllOwnerDetailsAPI,
    createProjectOwnerAPI,
    updateProjectOwnerAPI,

    getAllShiftDetailsAPI,
    createShiftAPI,
    updateShiftAPI,

    getAllProjectTasksAPI,
    getAllProjectTypeTasksAPI,
    updateProjectTasksAPI

  };
};

export default projectAPI;
