// custom pages
import config from "config/config";
import HandleApiResponse from "utils/handleApiResponse";

const DocumentsAPI = () => {
  const { handleResponse, handleError } = HandleApiResponse();

  async function getAllDocumentsAPI() {
    try {
      const obj = {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      };
      let res = await fetch(`${config.host}/panel/documents/findAll`, obj);
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }

  async function PostDocumentApi(data) {
    try {
      const obj = {
        method: "Post",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      };
      let res = await fetch(
        `${config.host}/panel/employees/createDocument`,
        obj
      );
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }

  async function getOneDocumentApi(id) {
    try {
      const obj = {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      };
      const res = await fetch(
        `${config.host}/panel/documents/findOneDoc/${id}`,
        obj
      );
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }

  return {
    getAllDocumentsAPI,
    PostDocumentApi,
    getOneDocumentApi,
  };
};

export default DocumentsAPI;
