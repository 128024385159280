// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ApproveInManagerModal{
    font-family: "Inter" !important;
font-size: 14px !important;
font-weight: 600 !important;
/* line-height: 19.36px !important; */
/* letter-spacing: 0.02em !important; */
text-align: left !important;
height: 45px !important;
/* border-radius: 30px !important; */
color:white !important;
background-color: #008A27 !important;
width: 100% !important;
max-width: 180px !important;

}

.DeclineInManagerModal{
    font-family: "Inter" !important;
    font-size: 14px !important;
    font-weight: 600 !important;
    /* line-height: 19.36px !important; */
    /* letter-spacing: 0.02em !important; */
    text-align: left !important;
    height: 45px !important;
    /* border-radius: 30px !important; */
    color:white !important;
    background-color: #E90000 !important;
    width: 100% !important;
    max-width: 180px !important;

}
.managerLeaveModalhead{
    font-family: "Inter";
font-size: 14px;
font-weight: 600;
line-height: 16.94px;
letter-spacing: 0.02em;
text-align: left;
color:#002C5D;
margin-top: 4% !important; 

}

`, "",{"version":3,"sources":["webpack://./src/views/panel/LeaveManagement/managerView/managerLeave.css"],"names":[],"mappings":"AAAA;IACI,+BAA+B;AACnC,0BAA0B;AAC1B,2BAA2B;AAC3B,qCAAqC;AACrC,uCAAuC;AACvC,2BAA2B;AAC3B,uBAAuB;AACvB,oCAAoC;AACpC,sBAAsB;AACtB,oCAAoC;AACpC,sBAAsB;AACtB,2BAA2B;;AAE3B;;AAEA;IACI,+BAA+B;IAC/B,0BAA0B;IAC1B,2BAA2B;IAC3B,qCAAqC;IACrC,uCAAuC;IACvC,2BAA2B;IAC3B,uBAAuB;IACvB,oCAAoC;IACpC,sBAAsB;IACtB,oCAAoC;IACpC,sBAAsB;IACtB,2BAA2B;;AAE/B;AACA;IACI,oBAAoB;AACxB,eAAe;AACf,gBAAgB;AAChB,oBAAoB;AACpB,sBAAsB;AACtB,gBAAgB;AAChB,aAAa;AACb,yBAAyB;;AAEzB","sourcesContent":[".ApproveInManagerModal{\r\n    font-family: \"Inter\" !important;\r\nfont-size: 14px !important;\r\nfont-weight: 600 !important;\r\n/* line-height: 19.36px !important; */\r\n/* letter-spacing: 0.02em !important; */\r\ntext-align: left !important;\r\nheight: 45px !important;\r\n/* border-radius: 30px !important; */\r\ncolor:white !important;\r\nbackground-color: #008A27 !important;\r\nwidth: 100% !important;\r\nmax-width: 180px !important;\r\n\r\n}\r\n\r\n.DeclineInManagerModal{\r\n    font-family: \"Inter\" !important;\r\n    font-size: 14px !important;\r\n    font-weight: 600 !important;\r\n    /* line-height: 19.36px !important; */\r\n    /* letter-spacing: 0.02em !important; */\r\n    text-align: left !important;\r\n    height: 45px !important;\r\n    /* border-radius: 30px !important; */\r\n    color:white !important;\r\n    background-color: #E90000 !important;\r\n    width: 100% !important;\r\n    max-width: 180px !important;\r\n\r\n}\r\n.managerLeaveModalhead{\r\n    font-family: \"Inter\";\r\nfont-size: 14px;\r\nfont-weight: 600;\r\nline-height: 16.94px;\r\nletter-spacing: 0.02em;\r\ntext-align: left;\r\ncolor:#002C5D;\r\nmargin-top: 4% !important; \r\n\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
