import config from "config/config";
import HandleApiResponse from "utils/handleApiResponse";

const addEmployee = () => {
  const { handleResponse, handleError } = HandleApiResponse();

  async function getAllEmployeeAPI(values) {
    try {
      const obj = {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      };
      const query = JSON.stringify(values);
      const res = await fetch(
        `${config.host}/panel/employees/findAll?query=${query}`,
        obj
      );
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }

  async function getOneEmployeeAPI(id) {
    try {
      const obj = {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      };
      const res = await fetch(`${config.host}/panel/employees/find/${id}`, obj);
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }

  return {
    getAllEmployeeAPI,
    getOneEmployeeAPI,
  };
};

export default addEmployee;
