import React from "react";
import CircularProgress from "@mui/material/CircularProgress";

export default function Loader({ loading }) {
  return (
    loading && (
      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "rgb(207 207 207 / 70%)", // Adjust the background color and opacity
          zIndex: 9999,
        }}
      >
        <CircularProgress color="primary"  sx={{color:"rgba(0, 44, 93, 1)"}} />
      </div>
    )
  );
}
