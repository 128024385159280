//=============================================================================
//                       <--- Developed By Icon Team --->
//=============================================================================

// custom pages
import Login from 'views/auth/login/Login'
import ForgotPassword from 'views/auth/forgotPassword/ForgotPassword'
import ResetPassword from 'views/auth/resetPassword/ResetPassword'
import ChildLayout from "layouts/ChildLayout"
import ErrorPage from 'views/errors/ErrorPage';
import SessionExpired from 'views/errors/SessionExpired'

var parentRoutes = [
  { path: "/panel/*", name: "Panel", component: ChildLayout },
  { path: "/forgot-password", name: "ForgotPassword", component: ForgotPassword },
  { path: "/reset-password/:id", name: "ResetPassword", component: ResetPassword },
  { path: "/", name: "Login", component: Login },
  { path: "/session-expired", component: SessionExpired},
  { path: "/error", component: ErrorPage, redirect:true },
  
];

export default parentRoutes;
