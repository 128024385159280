// npm packages
import React, { useState, useEffect, Fragment, useContext } from "react";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import * as moment from "moment";
import { toast } from "react-toastify";
import Divider from "@mui/material/Divider";

// custom pages
import LeaveAPI from "apis/panel/leaveRequest/leaveRequest";
import AprovedAPI from "apis/panel/leaveRequest/leaveRequest";
// import EmployeeTaskAPI from "apis/panel/employeeTask/employeeTask";
import LeaveAproveAPI from "apis/panel/leaveRequest/leaveRequest"
import AllLeaveRequestAPI from "apis/panel/leaveRequest/leaveRequest";
import getAprovedAPI from "apis/panel/leaveRequest/leaveRequest"
import { toastCss, theme } from "config/config";
import NotFoundPage from "components/NotFoundPage";
import config from "config/config";
import context from "context/context";

// material ui
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import VisibilityIcon from '@mui/icons-material/Visibility';
// import MenuItem from '@mui/material/MenuItem';
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Paper from "@mui/material/Paper";

// material ui icons
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  borderRadius: "12px",
  boxShadow: 24,
  p: 4,
};


const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} arrow placement="right" />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}));

export default function ViewLeave() {
  const { dispatch } = useContext(context);

  const { getAllLeaveAPI } = LeaveAPI();
  const { getAllLeaveAprovalAPI } = LeaveAproveAPI();
  const { getAllLeaveRequestAPI } = AllLeaveRequestAPI();
  const { getAprovedAPI } = AprovedAPI()


  // Create Employee Task
  const [disableSubmitBtn, setDisableSubmitBtn] = useState(false);
  const [leaveRequests, setLeaveRequests] = useState([]);
  const [viewModalOpen, setViewModalOpen] = useState(false);
  const [selectedLeaveRequest, setSelectedLeaveRequest] = useState(null);
  const [status, setStatus] = useState("");
  const [remarks, setRemarks] = useState("");


  // Create Employee Task Modal
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // Filter Employee Task
  var d = new Date();
  d.setDate(d.getDate() - 6);
  // const [startDate, setStartDate] = useState();
  // const [endDate, setEndDate] = useState(new Date().setHours(0, 0, 0, 0));
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };


  useEffect(() => {
    handleGetAllLeaveRequests();
    getAllLeaveAprovalAPI();

  }, []);

  const handleGetAllLeaveRequests = async () => {
    dispatch({ type: "SET_LOADING", payload: true });

    try {
      let res = await getAllLeaveAprovalAPI();
      setLeaveRequests(res?.data?.leaveRequest || []);
      dispatch({ type: "SET_LOADING", payload: false });
    } catch (err) {
      console.error(err);
    }
  };


  const handleAproveLeave = async () => {
    try {
      let numericStatus;
      if (status === 'New') {
        numericStatus = 0;
      } else if (status === 'Approved') {
        numericStatus = 1;
      } else if (status === 'Rejected') {
        numericStatus = 2;
      } else if (status === 'Cancelled') {
        numericStatus = 3;
      } else {
        numericStatus = 0;
      }

      const leavevalue = {
        leaveRequestId: selectedLeaveRequest.leaveRequestId,
        status: numericStatus,
        remarks: remarks,
      };

      await getAprovedAPI(leavevalue);

      // Update the state with the new data
      setLeaveRequests((prevLeaveRequests) => {
        const updatedLeaveRequests = prevLeaveRequests.map((leaveRequest) =>
          leaveRequest.leaveRequestId === selectedLeaveRequest.leaveRequestId
            ? {
              ...leaveRequest,
              status: leavevalue.status,
              remarks: leavevalue.remarks,
            }
            : leaveRequest
        );
        return updatedLeaveRequests;
      });

      // Close the modal and reset the form
      handleClose();
      setDisableSubmitBtn(false);
      toast.success("Leave Approved/Rejected", toastCss);
    } catch (err) {
      console.error(err);
    }
  };

  const handleViewModalOpen = (leaveRequest) => {
    setSelectedLeaveRequest(leaveRequest);
    setStatus(
      leaveRequest.status === 0 ? 'New' :
        leaveRequest.status === 1 ? 'Approved' :
          leaveRequest.status === 2 ? 'Rejected' : 'Cancelled'
    );
    setRemarks(leaveRequest.remarks);
    setViewModalOpen(true);
  };

  const handleViewModalClose = () => {
    setViewModalOpen(false);
    setSelectedLeaveRequest(null);
  };


  const getStatusText = (numericStatus) => {
    switch (numericStatus) {
      case 0:
        return 'New';
      case 1:
        return 'Approved';
      case 2:
        return 'Rejected';
      case 3:
        return 'Cancelled';
      default:
        return 'Unknown';
    }
  };

  const getStatusColor = (numericStatus) => {
    switch (numericStatus) {
      case 0:
        return 'blue';
      case 1:
        return 'green';
      case 2:
        return 'red';
      case 3:
        return 'gray';
      default:
        return 'black';
    }
  };

  const getFilteredLeaveRequests = (status) => {
    return leaveRequests.filter((leaveRequest) => leaveRequest.status === status);
  };

  return (
    <Fragment>
      <Card variant="outlined" sx={{ padding: "15px" }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={selectedTab} onChange={handleTabChange} centered>
            <Tab label="New" />
            <Tab label="Approved" />
            <Tab label="Rejected" />
          </Tabs>
        </Box>
      </Card>
      {selectedTab === 0 && (
        <Card variant="outlined" sx={{ padding: "15px", marginTop: "1%" }}>
          <Box>
            <Box
              sx={{
                display: "flex",
                gap: "30px",
                justifyContent: "end",
                marginTop: "20px",
              }}
            >
            </Box>
            <TableContainer component={Paper}>
              <Table aria-label="collapsible table">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <b>Leave Type</b>
                    </TableCell>
                    <TableCell>
                      <b>Employee Code</b>
                    </TableCell>
                    <TableCell>
                      <b>Employee Name</b>
                    </TableCell>
                    <TableCell>
                      <b>Start Date</b>
                    </TableCell>
                    <TableCell>
                      <b>End Date</b>
                    </TableCell>
                    <TableCell>
                      <b>Total Days</b>
                    </TableCell>
                    <TableCell>
                      <b>Description</b>
                    </TableCell>
                    <TableCell>
                      <b>Status</b>
                    </TableCell>
                    <TableCell>
                      <b>Remarks</b>
                    </TableCell>
                    <TableCell>
                      <b>Operations</b>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {getFilteredLeaveRequests(0).map((leaveRequest) => (
                    <TableRow key={leaveRequest.leaveRequestId}>
                      <TableCell>
                        {leaveRequest.leaveName}
                      </TableCell>
                      <TableCell>
                        {leaveRequest.employeeCode}
                      </TableCell>
                      <TableCell>
                        {leaveRequest.firstName}
                      </TableCell>
                      <TableCell>
                        {moment(leaveRequest.startDate).format("YYYY-MM-DD")}
                      </TableCell>
                      <TableCell>
                        {moment(leaveRequest.endDate).format("YYYY-MM-DD")}
                      </TableCell>
                      <TableCell>
                        {leaveRequest.leaveTotal}
                      </TableCell>
                      <TableCell>
                        {leaveRequest.reason}
                      </TableCell>
                      <TableCell style={{ color: getStatusColor(leaveRequest.status) }}>
                        {getStatusText(leaveRequest.status)}
                      </TableCell>
                      <TableCell>
                        {leaveRequest.remarks}
                      </TableCell>
                      <TableCell>
                        <VisibilityIcon
                          onClick={() => handleViewModalOpen(leaveRequest)}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Card>
      )}

      {selectedTab === 1 && (
        <Card variant="outlined" sx={{ padding: "15px", marginTop: "1%" }}>
          <Box>
            <Box
              sx={{
                display: "flex",
                gap: "30px",
                justifyContent: "end",
                marginTop: "20px",
              }}
            >
            </Box>
            <TableContainer component={Paper}>
              <Table aria-label="collapsible table">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <b>Leave Type</b>
                    </TableCell>
                    <TableCell>
                      <b>Employee Code</b>
                    </TableCell>
                    <TableCell>
                      <b>Employee Name</b>
                    </TableCell>
                    <TableCell>
                      <b>Start Date</b>
                    </TableCell>
                    <TableCell>
                      <b>End Date</b>
                    </TableCell>
                    <TableCell>
                      <b>Total Days</b>
                    </TableCell>
                    <TableCell>
                      <b>Description</b>
                    </TableCell>
                    <TableCell>
                      <b>Status</b>
                    </TableCell>
                    <TableCell>
                      <b>Remarks</b>
                    </TableCell>
                    <TableCell>
                      <b>Operations</b>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {leaveRequests
                    .filter((leaveRequest) => leaveRequest.status === 1) // Filter by Approved status
                    .map((leaveRequest) => (
                      <TableRow key={leaveRequest.leaveRequestId}>
                        <TableCell>
                          {leaveRequest.leaveName}
                        </TableCell>
                        <TableCell>
                          {leaveRequest.employeeCode}
                        </TableCell>
                        <TableCell>
                          {leaveRequest.firstName}
                        </TableCell>
                        <TableCell>
                          {moment(leaveRequest.startDate).format("YYYY-MM-DD")}
                        </TableCell>
                        <TableCell>
                          {moment(leaveRequest.endDate).format("YYYY-MM-DD")}
                        </TableCell>
                        <TableCell>
                          {leaveRequest.leaveTotal}
                        </TableCell>
                        <TableCell>
                          {leaveRequest.reason}
                        </TableCell>
                        <TableCell style={{ color: getStatusColor(leaveRequest.status) }}>
                          {getStatusText(leaveRequest.status)}
                        </TableCell>
                        <TableCell>
                          {leaveRequest.remarks}
                        </TableCell>
                        <TableCell>
                          <VisibilityIcon
                            onClick={() => handleViewModalOpen(leaveRequest)}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Card >

      )
      }
      {
        selectedTab === 2 && (
          <Card variant="outlined" sx={{ padding: "15px", marginTop: "1%" }}>
            <Box>
              <Box
                sx={{
                  display: "flex",
                  gap: "30px",
                  justifyContent: "end",
                  marginTop: "20px",
                }}
              >
              </Box>
              <TableContainer component={Paper}>
                <Table aria-label="collapsible table">
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <b>Leave Type</b>
                      </TableCell>
                      <TableCell>
                        <b>Employee Code</b>
                      </TableCell>
                      <TableCell>
                        <b>Employee Name</b>
                      </TableCell>
                      <TableCell>
                        <b>Start Date</b>
                      </TableCell>
                      <TableCell>
                        <b>End Date</b>
                      </TableCell>
                      <TableCell>
                        <b>Total Days</b>
                      </TableCell>
                      <TableCell>
                        <b>Description</b>
                      </TableCell>
                      <TableCell>
                        <b>Status</b>
                      </TableCell>
                      <TableCell>
                        <b>Remarks</b>
                      </TableCell>
                      <TableCell>
                        <b>Operations</b>
                      </TableCell>

                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {leaveRequests
                      .filter((leaveRequest) => leaveRequest.status === 2)
                      .map((leaveRequest) => (
                        <TableRow key={leaveRequest.leaveRequestId}>
                      <TableCell>
                        {leaveRequest?.leaveName}
                      </TableCell>
                      <TableCell>
                        {leaveRequest?.employeeCode}
                      </TableCell>
                      <TableCell>
                        {leaveRequest?.firstName}
                      </TableCell>
                      <TableCell>
                        {moment(leaveRequest.startDate).format("YYYY-MM-DD")}
                      </TableCell>
                      <TableCell>
                        {moment(leaveRequest.endDate).format("YYYY-MM-DD")}
                      </TableCell>
                      <TableCell>
                        {leaveRequest.leaveTotal}
                      </TableCell>
                      <TableCell>
                        {leaveRequest.reason}
                      </TableCell>
                      <TableCell style={{ color: getStatusColor(leaveRequest.status) }}>
                        {getStatusText(leaveRequest.status)}
                      </TableCell>
                      <TableCell>
                        {leaveRequest.remarks}
                      </TableCell>
                      <TableCell>
                        <VisibilityIcon
                          onClick={() => handleViewModalOpen(leaveRequest)}
                        />
                      </TableCell>
                    </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Card>
        )
      }

      {/* Create Modal */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={viewModalOpen}
        onClose={handleViewModalClose}
      >
        <Fade in={viewModalOpen}>
          <Box sx={style}>
            <Typography id="transition-modal-title" variant="h6" component="h2">
              Status Leave
            </Typography>
            <Box sx={{ marginTop: "20px", }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={7} lg={12}>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={['Approved', 'Rejected']}
                    value={status}
                    onSelect={(e) => setStatus(e.target.value)}
                    renderInput={(params) => <TextField {...params} label="Status *" />}
                  />
                </Grid>

                <Grid item xs={12} sm={7} lg={12}>
                  <TextField
                    sx={{ width: "100%" }}
                    id="outlined-basic"
                    label="Remarks"
                    variant="outlined"
                    multiline
                    required={status === 'Rejected'}
                    value={remarks}
                    onChange={(e) => setRemarks(e.target.value)}
                  />
                </Grid>
              </Grid>
            </Box>


            <Divider sx={{ margin: "32px 0px" }} />
            <Box sx={{ marginTop: "30px" }}>
              <Grid container spacing={2}>
                <Grid item xs={12} style={{ textAlign: "right" }}>
                  <Button
                    sx={{
                      padding: "8px 20px",
                      borderRadius: "8px",
                      marginRight: "32px",
                      backgroundColor: "#fff",
                      color: config?.theme?.primary?.main,
                      border: `1px solid ${config?.theme?.primary?.main}`,
                      "&:hover": {
                        backgroundColor: "#fff",
                      },
                    }}
                    variant="contained"
                    onClick={() => {
                      handleClose();
                      setViewModalOpen(false);
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    sx={{
                      padding: "8px 20px",
                      borderRadius: "8px",
                    }}
                    variant="contained"
                    disabled={disableSubmitBtn}
                    onClick={() => {
                      setDisableSubmitBtn(true);
                      handleAproveLeave();
                      setViewModalOpen(false);
                    }}
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </Fragment >
  );
}
