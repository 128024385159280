import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField'
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import projectAPI from "apis/panel/project/project";
import { toast } from "react-toastify";
import "./Project.css"
import { useNavigate } from "react-router-dom";
import Loader from "components/Loader";

const AddNewProject = () => {
  const navigate = useNavigate();
  const [loader2, setLoader2] = useState(false);
  const { getAllProjectstypeAPI, getProjectCreateAPI } = projectAPI();

  const [projectcode, setprojectcode] = useState('');
  const [projectname, setprojectename] = useState('');
  const [accountname, setaccountname] = useState('');  
  const [projecttypeid, setprojecttypeid] = useState('');
  const [startdate, setstartdate] = useState(null);
  const [enddate, setendend] = useState(null);
  const [status, setstatus] = useState('');
  const [description, setdescription] = useState('');
  const [projecttypeoption, setprojecttypeoption] = useState([]);  

  const handleGetAllprojectype = async () => {
    try {
      let res = await getAllProjectstypeAPI();
      setprojecttypeoption(res?.data || []);
    }
    catch (err) { }
  };

  const [errorFields, setErrorFields] = useState({
    projectid: false,
    projectname: false,
    accountname: false,
    projecttype: false,
    startdate: false,
    status: false
  });

  useEffect(() => {
    handleGetAllprojectype();
  }, [])

  const handleprojecttype = (e) => {
    setprojecttypeid(e.target.value);
  };

  const handlestartAccept = (date) => {
    setstartdate(date);
  }

  const handleendAccept = (date) => {
    setendend(date);
  }

  const handleprojectnameChange = (e) => {
    setprojectename(e.target.value);
  };

  const handleaccountChange = (e) => {
    setaccountname(e.target.value);
  };

  const handlestatusChange = (e) => {
    setstatus(e.target.value);
  };

  const handledescriptionChange = (e) => {
    setdescription(e.target.value);
  };

  const handleprojectcodeChange = (e) => {
    setprojectcode(e.target.value);
  };

  const handleCancel = (e) => {
    navigate("/panel/project");
  };

  const handleReset = (e) => {
    setprojectcode('');
    setprojectename('');
    setaccountname('');
    setprojecttypeid('');
    setstartdate(null);
    setendend(null);
    setstatus('');
    setdescription('');
  };  

  const handleSave = async (e) => {
    setLoader2(true);
    e.preventDefault();
    const errors = {};
    if (!projectcode) errors.projectid = true;
    if (!projectname) errors.projectname = true;
    if (!accountname) errors.accountname = true;
    if (!projecttypeid) errors.projecttype = true;
    if (!startdate) errors.startdate = true;
    if (!status) errors.status = true;

    setErrorFields(errors);

    if (Object.keys(errors).length === 0) {
      const values = {
        projectTypeId: projecttypeid,
        projectName: projectname,
        projectCode: projectcode,
        accountName: accountname,
        projectDescription: description,
        startDate: startdate,
        endDate: enddate,
        status: status
      };
      const data = await getProjectCreateAPI(values);
      if (data.status === 'Ok') {
        navigate("/panel/project");
        setLoader2(false);
        return toast.success("Record created successfully.", { toast: "Record created successfully." });
      } 
      else {
        setLoader2(false);
        return toast.error("Project Id already exist!", { toast: "Project Id already exist!" });
      }
    }
    else {
      setLoader2(false);
    }
  };

  return (
    <div className="ProjectCont">
      <Loader loading={loader2} />
      <Grid container className="editsetupmaincont" sx={{ pl: 3, pr: 3, mt: 2, mb: 1 }}>
        <Box className="addnewprojecthead">Add New Project</Box>
        <Box>
          <Button className="cancelButton" onClick={() => navigate(-1)}>
            Back
          </Button>
        </Box>
      </Grid>
      
      <Grid container spacing={2} sx={{ pl: 7, pr: 7, pb: 5, mt: 2 }} >
        
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <Box className="labelproj">Project ID <span style={{ color: "red" }}>*</span></Box>
          <TextField
            id="projectCode"
            value={projectcode}
            placeholder="Enter Project id"
            variant="outlined"
            fullWidth
            style={{ border: "1px solid #B2D4F8" }}
            onChange={handleprojectcodeChange}
          />
          {errorFields.projectid && <span className="error-msg">This is required Field</span>}
        </Grid>

        <Grid item xs={12} sm={6} md={6} lg={6}>
          <Box className="labelproj">Project Name<span style={{ color: "red" }}>*</span></Box>
          <TextField
            id="projectname"
            value={projectname}
            placeholder="Enter Project Name"
            style={{ border: "1px solid #B2D4F8" }}
            variant="outlined"
            fullWidth
            onChange={handleprojectnameChange}
          />
          {errorFields.projectname && <span className="error-msg">This is required Field</span>}
        </Grid>

        <Grid item xs={12} sm={6} md={6} lg={6}>
          <Box className="labelproj">Account Name <span style={{ color: "red" }}>*</span></Box>
          <TextField
            id="accountname"
            value={accountname}
            placeholder="Enter Account Name"
            style={{ border: "1px solid #B2D4F8" }}
            variant="outlined"
            fullWidth
            onChange={handleaccountChange}
          />
          {errorFields.accountname && <span className="error-msg">This is required Field</span>}
        </Grid>

        <Grid item xs={12} sm={6} md={6} lg={6}>
          <Box className="labelproj">Project type <span style={{ color: "red" }}>*</span></Box>
          <FormControl fullWidth>
            <Select
              id="projecttypeid"
              value={projecttypeid}
              defaultValue=""
              placeholder="Select Project Type"
              displayEmpty
              inputProps={{ 'aria-label': 'Without label' }}
              style={{ border: "1.5px solid #B2D4F8", color: "grey" }}
              onChange={handleprojecttype}
            >
              <MenuItem value="">Select Project Type</MenuItem>
              {projecttypeoption.map((option) => (
                <MenuItem key={option.id} value={option.projectTypeId}>{option.projectTypeName}</MenuItem>
              ))}
            </Select>
          </FormControl>
          {errorFields.projecttype && <span className="error-msg">This is required Field</span>}
        </Grid>

        <Grid item xs={12} sm={6} md={6} lg={6}>
          <Box className="labelproj">Start Date <span style={{ color: "red" }}>*</span></Box>
          <LocalizationProvider fullWidth dateAdapter={AdapterDateFns}>
            <DatePicker
              id="startdate"
              name="startdate"
              value={startdate}
              sx={{ width: "100%", border: "1.5px solid #B2D4F8" }}
              onAccept={handlestartAccept}
              fullWidth
              format="dd/MM/yyyy"
              renderInput={(params) => <TextField {...params} fullWidth />}
            />
          </LocalizationProvider>
          {errorFields.startdate && <span className="error-msg">This is required Field</span>}
        </Grid>

        <Grid item xs={12} sm={6} md={6} lg={6}>
          <Box className="labelproj">End Date </Box>
          <LocalizationProvider fullWidth dateAdapter={AdapterDateFns}>
            <DatePicker
              id="enddate"
              name="enddate"
              value={enddate}
              sx={{ width: "100%", border: "1.5px solid #B2D4F8" }}
              onAccept={handleendAccept}
              fullWidth
              format="dd/MM/yyyy"
              renderInput={(params) => <TextField {...params} fullWidth />}
            />
          </LocalizationProvider>
        </Grid>

        <Grid item xs={12} sm={6} md={6} lg={6}>
          <Box className="labelproj">Status <span style={{ color: "red" }}>*</span></Box>
          <FormControl fullWidth>
            <Select
              id="status"
              value={status}
              defaultValue=""
              placeholder="Select status"
              displayEmpty
              inputProps={{ 'aria-label': 'Without label' }}
              style={{ border: "1.5px solid #B2D4F8", color: "grey" }}
              onChange={handlestatusChange}
            >
              <MenuItem value="">Select Status</MenuItem>
              <MenuItem value="Active">Active</MenuItem>
              <MenuItem value="Inactive">InActive</MenuItem>
              <MenuItem value="Onhold">OnHold</MenuItem>
            </Select>
          </FormControl>
          {errorFields.status && <span className="error-msg">This is required Field</span>}
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Box className="labelproj">Description </Box>
          <TextField
            id="description"
            value={description}
            placeholder="Enter Description"
            sx={{
              "& .MuiInputBase-input": {
                alignItems: "top",
                height: "90px",
                border: "1px solid #B2D4F8"
              },
            }}
            variant="outlined"
            fullWidth
            onChange={handledescriptionChange}
          />
        </Grid>

      </Grid>     

      <Grid container sx={{ pl: 4, pr: 5, mt: 5, justifyContent: "center" }} >
        <Grid item sx={{ ml: 1 }} xs={12} sm={3} md={3} lg={3}>
          <Button className="resetChanges" onClick={handleReset}>Reset Changes</Button>
        </Grid>
        <Grid item xs={12} sm={2} md={2} lg={2}>
          <Button className="cancelButton" onClick={handleCancel}>Cancel</Button>
        </Grid>
        <Grid item sx={{ mr: 1 }} xs={12} sm={2} md={2} lg={2}>
          <Button className="saveButton" onClick={handleSave}>Save</Button>
        </Grid>
      </Grid>

    </div>
  )
}

export default AddNewProject