import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import Tables from "components/table/table";
import "./Training.css"
import getallList from "apis/panel/EmployeeRoster/employeeRoster";
import Context from "context/context";
import getcreatefindone from "apis/panel/AddEmployee/addEmployee"
import Button from "@mui/material/Button";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import Box from '@mui/material/Box';
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
// import check from "../../../assets/images/check.png";
// import cross from "../../../assets/images/remove.png"

const CustomWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 500,
    cursor: "pointer",
    backgroundColor: "#002c5d",
    color: "#ffffff",
  },
});

const Trainings = () => {
    const { state, dispatch } = useContext(Context);
    const [roleCode] = useState(state.role.roleCode);
    const navigate = useNavigate();
    const { getAllEmployeeAPI } = getallList();
    const [empDetails, setEmpDetails] = useState([]);
    const [offset, setOffset] = useState(1);
    const [count, setCount] = useState(0);
    const [searchValue, setSearchValue] = useState("");
    const [filterValue, setFilterValue] = useState("");
    const [serialNumber, setSerialNumber] = useState("");

    const data = [{"sno":"1", "id":"CVHS0025", "name":"rasheed","Email":"rasheed.s@valuehealthsol.com", "Date":"8-12-2024",  }]


  return (
    <div
      style={{
        backgroundColor: "white",
        borderRadius: "10px",
      }}
    >
      
      <Tables style={{width:"98%", overFlowX: "auto"}}
        title={
          <Box className="EmployeeRoster" sx={{ pt:2, pb:2, pl:2}}>Training</Box>
        }
        searchBar={roleCode === "R001"}
        // filter={roleCode === "R001"}
        role={roleCode}
        create={
          (roleCode === "R001") &&
          <Button className="AddEmployerButton">
            Add Trainee
          </Button>
        }
        setSearchValue={setSearchValue}
        filterValue={filterValue}
        setFilterValue={setFilterValue}
        filterValues={[
          { label: "Employee Id", value: "" },
          { label: "Employee Name", value: "employeeName" },
          { label: "Employee Email", value: "employeeEmail" },
        ]}
        heading={[
          "S.No",
          "Employee Id",
          "Full Name",
          "Email Id",
          "Date of Joining",
          "Action",
        ]}
       
        data={data?.map((e, index) => [
          // index + 1 + (serialNumber - 1) * 10,
          e.sno,
          e.id,
          e.name,
          <span className="businessmail">
            {e?.Email?.split("@")[0]}@<br />
            {e?.Email?.split("@")[1]}
          </span>,
           e?.Date,
        //   <span
        //     style={{
        //       color: getJobTypeColor(employee?.employmentTypeName),
        //       fontWeight: "700",
        //     }}
        //   >
        //     {employee.employmentTypeName},
  
        //   </span>,

// employee.status === "Active" ? (
//   <img style={{height: "20px"}} src={check} alt=""/>
// ) : (
//   <img  style={{height: "20px"}} src={cross} alt=""/>
// ),
              <CustomWidthTooltip
              title='Click to view details'
            >  
          <span className="jobType">
            <RemoveRedEyeIcon
              style={{ color: '#E97000', cursor:'pointer' }}
              onClick={(e) => 
            //     handleEmployeeView(employee.employeeId)
                navigate("/panel/EditTrainings")
              }
            />
          </span>
          </CustomWidthTooltip>
        ])}
        offset={offset}
        setOffset={setOffset}
        count={count}
        placeholder="Search by ID, Name, Email"
      />
    </div>
  );
};

export default Trainings;
