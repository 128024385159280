// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* FileUpload.css */

.containerforupload {
    display: flex;
    justify-content: center;
    /* align-items: center; */
    height: 50vh;
    /* background-color: #f7f8fc; */
    
    padding-top: 6%;
    border-radius: 20px;
    background-color: white;
  }
  
  .upload-area {
    /* margin-top: 2%; */
   width: 400px;
   height: 250px;
    background-color: white;
    border: 2px dashed #00aaff;
    border-radius: 12px;
    display: flex;
    /* justify-content: center; */
    align-items: center;
    cursor: pointer;

    transition: border-color 0.3s, transform 0.3s;
  }
  
  .upload-area:hover {
    border-color: #0077cc;
  }
  
  .instructions {
    text-align: center;
    color: #333;
  }
  `, "",{"version":3,"sources":["webpack://./src/views/panel/vraShifts/ImportSrf/ImportSrf.css"],"names":[],"mappings":"AAAA,mBAAmB;;AAEnB;IACI,aAAa;IACb,uBAAuB;IACvB,yBAAyB;IACzB,YAAY;IACZ,+BAA+B;;IAE/B,eAAe;IACf,mBAAmB;IACnB,uBAAuB;EACzB;;EAEA;IACE,oBAAoB;GACrB,YAAY;GACZ,aAAa;IACZ,uBAAuB;IACvB,0BAA0B;IAC1B,mBAAmB;IACnB,aAAa;IACb,6BAA6B;IAC7B,mBAAmB;IACnB,eAAe;;IAEf,6CAA6C;EAC/C;;EAEA;IACE,qBAAqB;EACvB;;EAEA;IACE,kBAAkB;IAClB,WAAW;EACb","sourcesContent":["/* FileUpload.css */\r\n\r\n.containerforupload {\r\n    display: flex;\r\n    justify-content: center;\r\n    /* align-items: center; */\r\n    height: 50vh;\r\n    /* background-color: #f7f8fc; */\r\n    \r\n    padding-top: 6%;\r\n    border-radius: 20px;\r\n    background-color: white;\r\n  }\r\n  \r\n  .upload-area {\r\n    /* margin-top: 2%; */\r\n   width: 400px;\r\n   height: 250px;\r\n    background-color: white;\r\n    border: 2px dashed #00aaff;\r\n    border-radius: 12px;\r\n    display: flex;\r\n    /* justify-content: center; */\r\n    align-items: center;\r\n    cursor: pointer;\r\n\r\n    transition: border-color 0.3s, transform 0.3s;\r\n  }\r\n  \r\n  .upload-area:hover {\r\n    border-color: #0077cc;\r\n  }\r\n  \r\n  .instructions {\r\n    text-align: center;\r\n    color: #333;\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
