// npm packages
import React, { useState,useContext } from "react";

// custom pages
// import TimesheetSummary from "./TimesheetSummaryReports/TimesheetSummary";


// material ui
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Card from "@mui/material/Card";


import Context from "context/context";
import RpAllowance from "./rpAllowance/RpAllowance";
import RpAllowanceSummary from "./rpAllowance/RPAllowanceSummary";
import RadScheduleForecast from "./RadScheduleForecast";
import RadScheduleForecastArchive from "./RadScheduleForecastArchive";
import RadScheduleActual from "./RadScheduleActual";
import VraMonthlySchedule from "./VraMonthlySchedule";
import RadRosterReport from "./RadRosterReport";
import VraRosterReport from "./VraRosterReport";

import VraRadAffinity from "./VraRadAffinity"

export default function RPreports() {
  const { state, dispatch } = useContext(Context);
  const [disableDropdown, setDisableDropdown] = useState(false);
  const [currentReport, setCurrentReport] = useState(1);
  const [roleCode, setRoleCode] = useState(state.role.roleCode);
 
  const reports = [

    {
      id: 1,
      reportName: "RP Allowance Report",
    },
    
    {
        id: 2,
        reportName: "RP Allowance Report Summary",
      },
      {
        id: 3,
        reportName:"RP RAD Schedule Report Forecast",
      },
      {
        id: 4,
        reportName:"RP RAD Schedule Report Actual",
      },
      {
        id: 5,
        reportName:"RP VRA Monthly Schedule Report ",
       
      },
      {
        id: 6,
        reportName:"RP VRA Roster Report",
      },
      {
        id: 7,
        reportName:"RP RAD Roster Report",
      },
{       id:8,
        reportName:"RP RAD Schedule Forecast Archive Report",
      },
      {
        id: 9,
        reportName:"RP VRA RAD Affinity Report",
      },
  ];


  function setReports(tab) {
    switch (tab) {
      case 1:
        return <RpAllowance setDisableDropdown={setDisableDropdown}/>;
      case 2:
          return <RpAllowanceSummary setDisableDropdown={setDisableDropdown}/>;
          case 3:
            return <RadScheduleForecast setDisableDropdown={setDisableDropdown}/>;
            case 4:
              return <RadScheduleActual setDisableDropdown={setDisableDropdown} />;
              case 5:
                return <VraMonthlySchedule setDisableDropdown={setDisableDropdown} />;
             
              case 6: 
              return <VraRosterReport setDisableDropdown={setDisableDropdown}/>;
              case 7: 
              return <RadRosterReport setDisableDropdown={setDisableDropdown}/>;
              case 8:
              return <RadScheduleForecastArchive setDisableDropdown={setDisableDropdown} />;
              case 9:
                return <VraRadAffinity setDisableDropdown={setDisableDropdown} />;
    //   case 3:
    //     if(roleCode=='R001'){
    //       return <EmployeeNonComplainceSummary setDisableDropdown={setDisableDropdown} />;
    //     }
       return null;
      default:
        return;
    }
  }

  return (
    <Card variant="outlined" sx={{ padding: "30px",borderRadius:"10px" }}>
      <Grid container >
      <Grid item xs={12} sm={6} md={4} lg={4}>
        <Box className="label">Select a Report</Box>
        <FormControl fullWidth>
          <Select
            id="maritalStatus"
            placeholder="Select ID Proof"
            inputProps={{ "aria-label": "Without label" }}
            disabled={disableDropdown}
            value={currentReport}
            onChange={(e) => {
              setCurrentReport(e.target.value);
            }}
          >
            {reports.map((report, index) => (
              <MenuItem key={index} value={report.id}>
                {report.reportName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      </Grid>
      <div>{setReports(currentReport)}</div>
    </Card>
  );
}
