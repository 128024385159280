// npm packages
import React, { useState, useEffect, Fragment, useContext } from "react";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import * as moment from "moment";
import { toast } from "react-toastify";
import Divider from "@mui/material/Divider";

// custom pages

import config from "config/config";
import context from "context/context";
import { toastCss, theme } from "config/config";

// material ui
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import VisibilityIcon from '@mui/icons-material/Visibility';
// import MenuItem from '@mui/material/MenuItem';
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Paper from "@mui/material/Paper";

// material ui icons
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  borderRadius: "12px",
  boxShadow: 24,
  p: 4,
};


const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} arrow placement="right" />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}));

export default function ViewLeave() {
  const { dispatch } = useContext(context);




  // Create Employee Task
  const [startDate, setStartDate] = useState(new Date());
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);



  return (
    <Fragment>
      <Card variant="outlined" sx={{ padding: "15px" }}>
        <Box sx={{ padding: "15px" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <span style={{ fontSize: 20, fontWeight: "bold" }}>Holidays List</span>
            <Button
              variant="contained"
              onClick={() => {
                handleOpen();
              }}
              sx={{ marginLeft: "auto" }}
            >
              Create
            </Button>
          </Box>
          <div style={{ padding: "10px" }}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DemoContainer components={["DesktopDatePicker"]}>
                <DatePicker
                  label="Year"
                  views={['year']}
                  value={startDate || new Date()}
                  onChange={(date) => {
                    const selectedYear = date.getFullYear();
                  }}
                />
              </DemoContainer>
            </LocalizationProvider>
          </div>
        </Box>
      </Card>
      <Card variant="outlined" sx={{ padding: "15px" , marginTop: "10px"}}>
      <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow style={{
                  backgroundColor: "rgb(244 93 47)",
                  color: "white"
                }}>
                <TableCell>
                  <b>Holiday</b>
                </TableCell>
                <TableCell>
                  <b>Date</b>
                </TableCell>
                <TableCell>
                  <b>Day</b>
                </TableCell>
                <TableCell>
                  <b>Description</b>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
                <TableRow>
                </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
    </Fragment >
  );
}
