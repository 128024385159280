import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import context from "context/context";
import getAllCountry from "apis/panel/ConfigurationPage/configuration";
import getCreate from "apis/panel/ConfigurationPage/configuration";
import getUpdate from "apis/panel/ConfigurationPage/configuration";
import getDelete from "apis/panel/ConfigurationPage/configuration";
import getAllMasterCountries from "apis/panel/ConfigurationPage/configuration"
import { toastCss, theme } from "config/config";


import Grid from "@mui/material/Grid";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import Card from "@mui/material/Card";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
// import VisibilityIcon from '@mui/icons-material/Visibility';
import ModeEditIcon from '@mui/icons-material/ModeEdit';

import "./Configurationpage.css";
import Paper from '@mui/material/Paper';
import Modal from '@mui/material/Modal';

import IconButton from '@mui/material/IconButton';

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

import MenuItem from '@mui/material/MenuItem';

import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';

import Pagination from '@mui/material/Pagination';


import Loader from 'components/Loader';
import Typography from '@mui/material/Typography';
import ReplyIcon from '@mui/icons-material/Reply';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment  from '@mui/material/InputAdornment'



const EmployeeRoster = () => {
    const { dispatch } = useContext(context);
    const { getAllCountryAPI } = getAllCountry();
    const { getCreateCountriesAPI } = getCreate();
    const { getUpdateCountriesAPI } = getUpdate();
    const { getDeleteCountriesAPI } = getDelete();
    const { getAllMasterCountriesAPI } = getAllMasterCountries();
   
    const [titleList, setTitleList] = useState([])
   
  
    const [isActive, setIsActive] = useState('');
    const Navigate = useNavigate();
    
    const [viewModalOpen, setViewModalOpen] = useState(false); // State to manage view modal visibility
  
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [createObj, setCreateObj] = useState({
       countryId:"",
        countryName: "",
        iso2: "",
        iso3: "",
        countryCode: "",
        phoneCode: "",
        currency: "",
        capital: "",
        native: "",
        isActive: ""
    });
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [searchValue, setSearchValue] = useState("");
    const [filterValue, setFilterValue] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(1);
    const [offset, setOffset] = useState(1);
    const [count, setCount] = useState(0)
    const itemsPerPage = 10;
    
    const handleBackButtonClick = () => {
        Navigate('/panel/configurationPage');
    };

    
    const handleChangeIsActive = (event) => {
        setIsActive(event.target.value);
    };

    const handleViewClick = () => {
        setViewModalOpen(true); // Open the view modal when View button is clicked
    };


    const handleCloseViewModal = () => {
        setIsActive("")
        setTitleList([])
        setCreateObj("")
        setViewModalOpen(false); // Close the view modal
    };

   

    const handleEditClick = (index) => {

        const clickedItem = titleList.find(item => item.countryId === index);
        setCreateObj({
          countryId:clickedItem.countryId,
            countryName: clickedItem.countryName,
            iso2: clickedItem.iso2,
            iso3: clickedItem.iso3,
            countryCode: clickedItem.countryCode,
            phoneCode: clickedItem.phoneCode,
            currency: clickedItem.currency,
            capital: clickedItem.capital,
            native: clickedItem.native,
            isActive: clickedItem.isActive
        })
        setIsActive(clickedItem.isActive ? 1 : 0)
        setEditModalOpen(true);
    };

    const handleCloseEditModal = () => {
        setCreateObj({
          countryId:"",
            countryName: "",
            iso2: "",
            iso3: "",
            countryCode: "",
            phoneCode: "",
            currency: "",
            capital: "",
            native: "",
            isActive: ""
        })
        setIsActive("")
        setEditModalOpen(false);
    };

   
    const handleGetAllCountry = async () => {
        let values;
        values = {
            offset: (offset - 1) * 10,
            limit: 10,
        };
        if (searchValue) {
            values = {
                offset: 0,
                limit: 10,
                searchValue,
            };
        }
        if (filterValue) {
            values = {
                ...values,
                filterValue,
            };
        }
        try {
            setLoading(true);
            
            let res = await getAllMasterCountriesAPI(values);
         
            setTitleList(res?.data?.country || []);
            setCount(res?.data?.count);
            setLoading(false);
        }
        catch (err) {
            setLoading(false);
         }
    };


    const handleCreateLeave = async (e) => {
        try {

            if (
                createObj.countryName === "" ||
               isActive==="" ||
               createObj.iso2 === "" ||
               createObj.iso3 === "" ||
               createObj.countryCode === "" ||
               createObj.currency === "" ||
               createObj.phoneCode=== "" ||
               createObj.capital === "" ||
               createObj.native === ""
              ) {
                return toast.error(
                  "Please fill in all required fields ."
                );
              }

            if (createObj.countryId) {
                
                const values = {
                    "countryId": parseInt(createObj.countryId),
                    "countryName": createObj.countryName,
                    "iso2": createObj.iso2,
                    "iso3": createObj.iso3,
                    "countryCode": createObj.countryCode,
                    "phoneCode": createObj.phoneCode,
                    "currency": createObj.currency,
                    "capital": createObj.capital,
                    "native": createObj.native,
                    "isActive": isActive
                }
          
                const data = await getUpdateCountriesAPI(values)
                if (data.status === "Ok") {
                    toast.success("Updated", toastCss);
                    setCreateObj({
                        countryId:"",
                        countryName: "",
                        isActive: "",
                        iso2: "",
                        iso3:"",
                        countryCode:"",
                        phoneCode:"",
                        currency:"",
                        capital:"",
                        native:"",
                        isActive:""
                    });
                    setIsActive("")
                    setEditModalOpen(false);
                }else{
                    toast.error(data.error.message, toastCss);
                    setCreateObj({
                        countryId:"",
                        countryName: "",
                        isActive: "",
                        iso2: "",
                        iso3:"",
                        countryCode:"",
                        phoneCode:"",
                        currency:"",
                        capital:"",
                        native:"",
                        isActive:""
                    });
                    setIsActive("")
                    setEditModalOpen(false);
                }
               
            } else {

                
                  const values = {
                    
                    "countryName": createObj.countryName,
                    "iso2": createObj.iso2,
                    "iso3": createObj.iso3,
                    "countryCode": createObj.countryCode,
                    "phoneCode": createObj.phoneCode,
                    "currency": createObj.currency,
                    "capital": createObj.capital,
                    "native": createObj.native,
                    "isActive": isActive
                }

                const data = await getCreateCountriesAPI(values);

                if (data.status === "Ok") {
                    toast.success("Created", toastCss);
                    setCreateObj({
                        countryName: "",
                        isActive: "",
                        iso2: "",
                        iso3:"",
                        countryCode:"",
                        phoneCode:"",
                        currency:"",
                        capital:"",
                        native:"",
                        isActive:""

                    });
                    setIsActive("")
                    setViewModalOpen(false); // Close the view modal

                    
                }else{
                    toast.error(data.error.message, toastCss);
                    setCreateObj({
                        countryName: "",
                        isActive: "",
                        iso2: "",
                        iso3:"",
                        countryCode:"",
                        phoneCode:"",
                        currency:"",
                        capital:"",
                        native:"",
                        isActive:""

                    });
                    setIsActive("")
                    setViewModalOpen(false); // Close the view modal

                }
            }
        } catch (err) {
            console.error(err);
        }
    };

   

    useEffect(() => {
        handleGetAllCountry();
    }, [viewModalOpen, editModalOpen, offset,page,searchValue, itemsPerPage])

    const indexOfLastItem = currentPage * perPage;
    const indexOfFirstItem = indexOfLastItem - perPage;

    const 
    handlePagination = (value) => {

        setOffset(value);
    };

    return (
        <div style={{ backgroundColor: "white" }}>
            <Grid style={{
                marginBottom: "2%"
             }}>
            <Grid container  >
              
                <Grid container alignItems="center" item xl={4} lg={4} md={4} sm={12} xs={12}>
                        <Box
                            sx={{
                                // position: 'absolute',
                                width: '30px',
                                height: '30px',
                                borderRadius: '50%',
                               
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                marginLeft:'10%'
                                // left: "245px",
                            }}
                            onClick={handleBackButtonClick}
                        >
                            <ReplyIcon />
                        </Box>
                         <Box sx={{ pl: 3 }} style={{
                            color: "#0056B2",
                        }}><h2>Country</h2></Box>
                    </Grid>
                    <Grid style={{ alignSelf: 'center', justifyContent: "center" }} sx={{ pl: 2 }} item xl={2} lg={2} md={2} sm={12} xs={12} >
                  
                  </Grid>

                  <Grid style={{ alignSelf: 'center' }} sx={{ pl: 3 }} item xl={3} lg={3.5} md={3.5} sm={12} xs={12}>
            <TextField
                className="editvalue"
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)} 
                sx={{ width: '85%', mt:3 }}
                label="Search country name"
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton>
                                <SearchIcon onClick={handleGetAllCountry}/>
                            </IconButton>
                        </InputAdornment>
                    )
                }}
                InputLabelProps={{
                    shrink: true, // To keep label from floating upwards when focused or filled
                    style: { paddingTop: '8px', fontSize: '1rem', fontWeight: 600, color: '#333' } // Adjust padding and other styles
                }}
                
            />
        </Grid>
               
                <Grid style={{ alignSelf: 'center', marginTop: "2%", justifyContent: "center" }} item xl={2} lg={2} md={2} sm={12} xs={12}>
                    <Button className="AddEmployerButton"
                        onClick={handleViewClick}
                    >Add Values</Button>
                </Grid>
                </Grid>
            </Grid>
    
                <Grid sx={{ p:2}}>
                    <TableContainer component={Paper} variant="outlined" sx={{ }}>
                        <Table aria-label="collapsible table">
                            <TableHead>
                                <TableRow>
                                    <TableCell><b > S.No</b></TableCell>
                                   <TableCell><b >CountryName</b></TableCell>
                                  <TableCell><b >Currency</b></TableCell>
                                    <TableCell><b >Capital</b></TableCell>
                                    <TableCell><b >Native</b></TableCell>
                                    <TableCell><b >iso2</b></TableCell>
                                    <TableCell><b >iso3</b></TableCell>
                                   
                                    <TableCell><b >Status</b></TableCell>
                                   <TableCell><b >Action</b></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            <Loader loading={loading} />
                                {titleList.map((titleList, index) => (
                                    <TableRow key={index}>
                                        <TableCell   className="TableCell">{(offset - 1) * itemsPerPage + index + 1}</TableCell>
                                        <TableCell  className="TableCell">{titleList.countryName}</TableCell>
                                      <TableCell   className="TableCell">{titleList.currency}</TableCell>
                                        <TableCell   className="TableCell">{titleList.capital}</TableCell>
                                        <TableCell  className="TableCell">{titleList.native}</TableCell>
                                        <TableCell   className="TableCell">{titleList.iso2}</TableCell>
                                        <TableCell   className="TableCell">{titleList.iso3}</TableCell>
                                    <TableCell  className="TableCell">
                                        {
                                            (titleList.isActive) ?
                                                <span style={{ color: "green" }}> Active </span>
                                                : <span style={{ color: "red" }}> InActive </span>
                                        }
                                    </TableCell>
                                        <TableCell style={{ }} >
                                            <Grid>
                                                <IconButton aria-label="view" style={{ color: '#E97000' }} onClick={() => handleEditClick(titleList.countryId)}>
                                                    <ModeEditIcon />
                                                </IconButton>
                                               
                                            </Grid>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    </Grid>
          
         
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                <Pagination
                    count={(count / 10) % 1 == 0 ? Math.floor(count / 10) : Math.floor(count / 10 + 1)}
                    page={offset}
                    siblingCount={1}
                    onChange={(event, value) => {
                        handlePagination(value);
                    }}
                    color="primary"
                />
            </Box>
            <Modal
                open={viewModalOpen}
                onClose={handleCloseViewModal}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: "49%",
                    bgcolor: 'background.paper',
                    
                    boxShadow: 24,
                    p: 4,
                }}>
                  
                    <Typography variant="h6" id="modal-title" style={{ color: "#0070E9" }}>
                    Country
                    </Typography>
                   

                    <Grid container spacing={2} sx={{ pl: 4, pr: 5, mt: 2 }} >
                       
                        <Grid item xs={12} sm={4} md={4} lg={4} sx={{ mt: 2 }}>
                            <Box className="editlabel">CountryName <span style={{ color: 'red' }}>*</span></Box>
                            <TextField
                                className="editvalue"
                                value={createObj?.countryName}
                                onChange={(e) => {
                                    setCreateObj({
                                        ...createObj,
                                        countryName: e.target.value,
                                    });
                                }}
                                sx={{ width: '95%' }}
                            />
                        </Grid>

                        <Grid item xs={12} sm={4} md={4} lg={4} sx={{ mt: 2 }}>
                            <Box className="editlabel">iso2 <span style={{ color: 'red' }}>*</span></Box>
                            <TextField
                                className="editvalue"
                                value={createObj?.iso2}
                                onChange={(e) => {
                                    setCreateObj({
                                        ...createObj,
                                        iso2: e.target.value,
                                    });
                                }}
                                sx={{ width: '95%' }}
                            />
                        </Grid>

                        <Grid item xs={12} sm={4} md={4} lg={4} sx={{ mt: 2 }}>
                            <Box className="editlabel">iso3 <span style={{ color: 'red' }}>*</span></Box>
                            <TextField
                                className="editvalue"
                                value={createObj?.iso3}
                                onChange={(e) => {
                                    setCreateObj({
                                        ...createObj,
                                        iso3: e.target.value,
                                    });
                                }}
                                sx={{ width: '95%' }}
                            />
                        </Grid>

                    </Grid>
                    <Grid container spacing={2} sx={{ pl: 4, pr: 5, mt: 2 }} >
                      
                        <Grid item xs={12} sm={4} md={4} lg={4} sx={{ mt: 2 }}>
                            <Box className="editlabel">CountryCode <span style={{ color: 'red' }}>*</span></Box>
                            <TextField
                                className="editvalue"
                                value={createObj?.countryCode}
                                onChange={(e) => {
                                    setCreateObj({
                                        ...createObj,
                                        countryCode: e.target.value,
                                    });
                                }}
                                sx={{ width: '95%' }}
                            />
                        </Grid>

                        <Grid item xs={12} sm={4} md={4} lg={4} sx={{ mt: 2 }}>
                            <Box className="editlabel">PhoneCode <span style={{ color: 'red' }}>*</span></Box>
                            <TextField
                                className="editvalue"
                                value={createObj?.phoneCode}
                                onChange={(e) => {
                                    setCreateObj({
                                        ...createObj,
                                        phoneCode: e.target.value,
                                    });
                                }}
                                sx={{ width: '95%' }}
                            />
                        </Grid>

                        <Grid item xs={12} sm={4} md={4} lg={4} sx={{ mt: 2 }}>
                            <Box className="editlabel">Currency <span style={{ color: 'red' }}>*</span></Box>
                            <TextField
                                className="editvalue"
                                value={createObj?.currency}
                                onChange={(e) => {
                                    setCreateObj({
                                        ...createObj,
                                        currency: e.target.value,
                                    });
                                }}
                                sx={{ width: '95%' }}
                            />
                        </Grid>

                    </Grid>
                    <Grid container spacing={2} sx={{ pl: 4, pr: 5, mt: 2 }} >
                       
                        <Grid item xs={12} sm={4} md={4} lg={4} sx={{ mt: 2 }}>
                            <Box className="editlabel">Capital <span style={{ color: 'red' }}>*</span></Box>
                            <TextField
                                className="editvalue"
                                value={createObj?.capital}
                                onChange={(e) => {
                                    setCreateObj({
                                        ...createObj,
                                        capital: e.target.value,
                                    });
                                }}
                                sx={{ width: '95%' }}
                            />
                        </Grid>

                        <Grid item xs={12} sm={4} md={4} lg={4} sx={{ mt: 2 }}>
                            <Box className="editlabel">Native <span style={{ color: 'red' }}>*</span></Box>
                            <TextField
                                className="editvalue"
                                value={createObj?.native}
                                onChange={(e) => {
                                    setCreateObj({
                                        ...createObj,
                                        native: e.target.value,
                                    });
                                }}
                                sx={{ width: '95%' }}
                            />
                        </Grid>

                        <Grid item xs={12} sm={4} md={4} lg={4} sx={{ mt: 2 }}>
                            <Box className="editlabel">Status<span style={{ color: 'red' }}>*</span></Box>
                            <FormControl sx={{ width: '95%' }}>
                                <Select
                                    value={isActive}
                                    onChange={handleChangeIsActive}
                                   
                                >
                                    <MenuItem value={1}>Active</MenuItem>
                                    <MenuItem value={0}>Inactive</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>

                    </Grid>
                    <Grid container spacing={1} sx={{ pl: 4, pr: 5, display: 'flex', justifyContent: 'flex-end', mt: 2  }} >
                        

                        <Grid item xs={12} sm={2} md={2} lg={2}>
                            <Button sx={{ marginRight: '20px' }}  className="cancelButton" onClick={handleCloseViewModal}>Cancel</Button>
                        </Grid>

                        <Grid item xs={12} sm={2} md={2} lg={2}>
                            <Button sx={{ marginLeft: '20px' }}  className="saveButton" onClick={handleCreateLeave}>
                                Save
                            </Button>
                        </Grid>
                    </Grid>
                              </Box>
            </Modal>


            <Modal
                open={editModalOpen}
                onClose={handleCloseEditModal}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: "49%",
                    bgcolor: 'background.paper',
                  
                    boxShadow: 24,
                    p: 4,
                }}>
                    <Typography variant="h6" id="modal-title" style={{ color: "#0070E9" }}>
                        country
                    </Typography>
                    <Grid container spacing={2} sx={{ pl: 4, pr: 5, mt: 2 }} >
                            
                            <Grid item xs={12} sm={4} md={4} lg={4} sx={{ mt: 2 }}>
                                <Box className="editlabel">CountryName <span style={{ color: 'red' }}>*</span></Box>
                                <TextField
                                    className="editvalue"
                                    value={createObj?.countryName}
                                    onChange={(e) => {
                                        setCreateObj({
                                            ...createObj,
                                            countryName: e.target.value,
                                        });
                                    }}
                                    sx={{ width: '95%' }}
                                />
                            </Grid>

                           
                       
                       
                       
                            <Grid item xs={12} sm={4} md={4} lg={4} sx={{ mt: 2 }}>
                                <Box className="editlabel">iso2 <span style={{ color: 'red' }}>*</span></Box>
                                <TextField
                                    className="editvalue"
                                    value={createObj?.iso2}
                                    onChange={(e) => {
                                        setCreateObj({
                                            ...createObj,
                                            iso2: e.target.value,
                                        });
                                    }}
                                    sx={{ width: '95%' }}
                                />
                            </Grid>
                       
                       
                            <Grid item xs={12} sm={4} md={4} lg={4} sx={{ mt: 2 }}>
                                <Box className="editlabel">Status <span style={{ color: 'red' }}>*</span> </Box>
                                <FormControl sx={{ width: '100%' }} >
                                    <Select
                                        value={createObj?.isActive}
                                        onChange={handleChangeIsActive}
                                        style={{ border: "1.5px solid #B2D4F8", color: "grey" }}

                                    >
                                        <MenuItem value={1}>Active</MenuItem>
                                        <MenuItem value={0}>Inactive</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} sm={4} md={4} lg={4} sx={{ mt: 2 }}>
                                <Box className="editlabel">iso3 <span style={{ color: 'red' }}>*</span></Box>
                                <TextField
                                    className="editvalue"
                                    value={createObj?.iso3}
                                    onChange={(e) => {
                                        setCreateObj({
                                            ...createObj,
                                            iso3: e.target.value,
                                        });
                                    }}
                                    sx={{ width: '95%' }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4} md={4} lg={4} sx={{ mt: 2 }}>
                                <Box className="editlabel">CountryCode <span style={{ color: 'red' }}>*</span></Box>
                                <TextField
                                    className="editvalue"
                                    value={createObj?.countryCode}
                                    onChange={(e) => {
                                        setCreateObj({
                                            ...createObj,
                                            countryCode: e.target.value,
                                        });
                                    }}
                                    sx={{ width: '95%' }}
                                />
                            </Grid>

                            <Grid item xs={12} sm={4} md={4} lg={4} sx={{ mt: 2 }}>
                                <Box className="editlabel">PhoneCode <span style={{ color: 'red' }}>*</span></Box>
                                <TextField
                                    className="editvalue"
                                    value={createObj?.phoneCode}
                                    onChange={(e) => {
                                        setCreateObj({
                                            ...createObj,
                                            phoneCode: e.target.value,
                                        });
                                    }}
                                    sx={{ width: '95%' }}
                                />
                            </Grid>

                            <Grid item xs={12} sm={4} md={4} lg={4} sx={{ mt: 2 }}>
                                <Box className="editlabel">Currency <span style={{ color: 'red' }}>*</span></Box>
                                <TextField
                                    className="editvalue"
                                    value={createObj?.currency}
                                    onChange={(e) => {
                                        setCreateObj({
                                            ...createObj,
                                            currency: e.target.value,
                                        });
                                    }}
                                    sx={{ width: '95%' }}
                                />
                            </Grid>

                            <Grid item xs={12} sm={4} md={4} lg={4} sx={{ mt: 2 }}>
                                <Box className="editlabel">Capital <span style={{ color: 'red' }}>*</span></Box>
                                <TextField
                                    className="editvalue"
                                    value={createObj?.capital}
                                    onChange={(e) => {
                                        setCreateObj({
                                            ...createObj,
                                            capital: e.target.value,
                                        });
                                    }}
                                    sx={{ width: '95%' }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4} md={4} lg={4} sx={{ mt: 2 }}>
                                <Box className="editlabel">Native <span style={{ color: 'red' }}>*</span></Box>
                                <TextField
                                    className="editvalue"
                                    value={createObj?.native}
                                    onChange={(e) => {
                                        setCreateObj({
                                            ...createObj,
                                            native: e.target.value,
                                        });
                                    }}
                                    sx={{ width: '95%' }}
                                />
                            </Grid>
                       
                    </Grid>

                
                   
                        <Grid container spacing={{ xs: 2, md: 3 }} sx={{ mt: 4, width: "83%", justifyContent: "flex-end", paddingTop: "52px" }}>
                            <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end' }}>
                                <Button className="cancelButton" onClick={handleCloseEditModal}>Cancel</Button>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleCreateLeave}
                                    className="saveButton"
                                >
                                    Update
                                </Button>
                            </Grid>
                        </Grid>
                    
                 </Box>
            </Modal>
           
        </div>

    )
}

export default EmployeeRoster