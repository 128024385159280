import { Fragment, useState, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import AuthAPI from "apis/auth/auth";
import Context from "context/context";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Logo from "assets/images/logo.png";

export default function ForgotPassword() {
  const { dispatch } = useContext(Context);
  const navigate = useNavigate();
  const { resetPasswordLinkAPI } = AuthAPI();
  const [email, setEmail] = useState("");
  const [disableBtn, setDisableBtn] = useState(false);
  const handleSubmit = async () => {    
    try {
      if (!email) {
        return 
      }
      let values = {
        email
      };
      let res = await resetPasswordLinkAPI(values);
      if(res?.status !== "SUCCESS"){
        setDisableBtn(false);
        dispatch({ type: "SET_LOADING", payload: false });
        return toast.error(res?.message,{toast:"login-error"});
      }
      setDisableBtn(false);
      dispatch({ type: "SET_LOADING", payload: false });
      setEmail("")
      toast.success(res?.message,{toast:"login-error"});
    } 
    catch (err) {}
  };

  return (
    <Fragment>
      <Box>
        <img className='logo-login' src={Logo} alt="logo" />
      </Box>
      <Box className='login-box'>
        <Typography style={{textAlign:"center",color:"#fe602f"}}>Forgot Psssword</Typography>
        <Card variant="outlined" sx={{ padding: "15px" }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid item xs={12} sm={12} lg={12}>    
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="employeeId"
                  label="Employee Email"
                  name="employeeId"
                  autoComplete="employeeId"
                  autoFocus
                  InputLabelProps={{
                    shrink: true,
                    style: { width: '100%' }
                  }}
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={12} display="flex" justifyContent="flex-end">
                <Button
                  type="submit"
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                  disabled={disableBtn}
                  onClick={() => {
                    handleSubmit();
                    setDisableBtn(true);
                    dispatch({ type: "SET_LOADING", payload: true });
                  }}
                >
                  Send Link
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Card>
        <Box sx={{ textAlign: "right" }}>
          If you know password? <span style={{cursor:"pointer",color:"#fe602f"}} onClick={() => navigate("/")}>Login Here</span>
        </Box>
      </Box>
    </Fragment>
  );
}