import React, { useRef, useState, useEffect } from 'react';
import { useNavigate, useParams } from "react-router-dom";
// import "./EditAddEmployee.css"
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import personalInfo from "assets/images/color (1).png";
import AttachFileIcon from '@mui/icons-material/AttachFile';

import CloudUploadIcon from '@mui/icons-material/CloudUpload';

import { InputAdornment, IconButton } from '@mui/material';
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import * as moment from "moment";
import { toast } from "react-toastify";
import Loader from "components/Loader";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

// import { makeStyles } from '@mui/styles';

// const useStyles = makeStyles((theme) => ({
//   fileInput: {
//     '& input[type="file"]': {
//       display: 'none',
//     },
//   },
//   textField: {
//     '& .MuiOutlinedInput-root': {
//       '&.Mui-focused fieldset': {
//         borderColor: theme.palette.primary.main,
//       },
//       '&:hover fieldset': {
//         borderColor: theme.palette.secondary.main,
//       },
//     },
//     '& .MuiFormLabel-root.Mui-focused': {
//       color: theme.palette.primary.main,
//     },
//   },
// }));

import projectupdate from 'apis/panel/project/project'
import getEmpRel from 'apis/panel/Insurance/insurance'
import getRel from 'apis/panel/Insurance/insurance'
import getcreatefindone from "apis/panel/task/task"
import taskupdate from "apis/panel/task/task";
import ProjecttypeallAPI from "apis/panel/project/project";

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };


const EditNewTask = () => {
  let params = useParams();
  const taskId = params.id;
  const [file, setFile] = useState(null);
  
  
  const [expanded, setExpanded] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);

  const [genderOptions, setGenderOptions] = useState([]);

  const { gettaskfindone } = getcreatefindone();
  const { getAllProjectstypeAPI } = ProjecttypeallAPI();


  const { gettaskupadte } = taskupdate();

  const[projecttypeid, setprojecttypeid]= useState('');
  const[projecttypename, setprojecttypename]= useState('');
  const[taskname, settaskename]= useState('');
  const[costcenter, setcostcenter]= useState('');
  const[status, setstatus]= useState('');
  const [loader2, setLoader2] = useState(false)
 
 
  const[taskcode, settaskcode]= useState('');
 
  const[projecttypeoption, setprojecttypeoption]= useState([]);
  const navigate = useNavigate();

  const handleprojectnameChange = (e) => {
    settaskename(e.target.value);
  };

  const handleaccountChange = (e) => {
    setcostcenter(e.target.value);
  };
  const handlestatusChange = (e) => {
    setstatus(e.target.value);
  };

  const handleNavigate = (e) => {
    navigate("/panel/projecttask");
  };

  const handleprojectcodeChange = (e) => {
    settaskcode(e.target.value);
  };


  const [errorFields, setErrorFields] = useState({
    taskid: false,
    projecttype: false,
    taskname: false,
    status: false,
    costcenter:false
   
    
  });



  const handlefindonee = async (e) => {
    setLoader2(true)
    const values = {
      relID: taskId


    };

    const data = await gettaskfindone(values);
    setLoader2(false)
    setprojecttypeid(data?.data?.projectTypes.projectTypeId)
    setprojecttypename(data?.data?.projectTypes.projectTypeName)
    settaskcode(data?.data?.taskCode)
    settaskename(data?.data?.taskName)
    setstatus(data?.data?.status)
    setcostcenter(data?.data?.costCenter)

   
  }

  useEffect(() => {
    handlefindonee()
  }, [])

  const toggleEditMode = (event) => {
    event.stopPropagation();
    setIsEditMode(!isEditMode);
  };

  const handleGetAllprojectype = async () => {
    try {
        let res = await getAllProjectstypeAPI();
        // console.log(titleOptions, "res")
        setprojecttypeoption(res?.data || []);
    }
    catch (err) {}


  };


  useEffect(()=>{
    handleGetAllprojectype()

  },[])

  const handleprojecttype  = (e) => {
    const selectedprojecttype = projecttypeoption.find(option => option.projectTypeName === e.target.value);
    setprojecttypename(selectedprojecttype.projectTypeName); // Set the titleName
    setprojecttypeid(selectedprojecttype.projectTypeId); // Set the titleId
  };





  const handleFieldChange = (setter) => (event) => {
    setter(event.target.value);
  };
  const handleAccordionChange = (event, isExpanded) => {
    setExpanded(isExpanded);
    if (!isExpanded) {
      // Accordion is not expanded, reset edit mode
      setIsEditMode(false);
    }
  };

  const handleprojectupdate = async (e) => {
    setLoader2(true)

    e.preventDefault();
    
    const errors = {};
    if (!taskcode) errors.taskid = true;
    if (!projecttypeid) errors.projecttype = true;
    if (!taskname) errors.taskname = true;
   
    if (!status) errors.status = true;
    if (!costcenter) errors.costcenter = true;
  
   
  

    setErrorFields(errors);

    if (Object.keys(errors).length === 0) {
   
      
   
    const values = {
        taskId: taskId,
    taskName: taskname,
    taskCode: taskcode,
    costCenter:costcenter,
    status: status,
    projectTypeId: projecttypeid,
     
     
    };
   
    const data = await gettaskupadte(values);
  
  
    if(data.status === 'Ok'){
      navigate("/panel/projecttask");
      setLoader2(false)
      // sessionStorage.setItem('EmployeeID',data.data.employeeId)
      // sessionStorage.setItem('EmployeeCode',data.data.employeeCode)
      return toast.success("Record updated successfully.", { toast: "Record updated successfully." });
  
    }else{
      setLoader2(false)
      return toast.error("Error saving Data", { toast: "Error saving Data" });
  
    }
  }
  else{
    setLoader2(false)
    return toast.error("Error saving Data", { toast: "Error saving Data" });
  }

}
  


  return (
  
        <div className='ProjectCont'>
           <Loader loading={loader2} />
     < Grid container className="editsetupmaincont" sx={{pl:4, pr:5, mt:2, mb:1}}>
            <Box className="addnewprojecthead">Tasks</Box>
            <Box>
            <Button className="projsetupedit" onClick={toggleEditMode}>
          {isEditMode ? 'Edit' : 'Edit'}
        </Button>
            </Box>
          </Grid>


          <Grid container spacing={2} sx={{ pl: 4, pr: 5, mt: 2 }} >



            {isEditMode ? (
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <Box className="label" >Task Id <span style={{ color: "red" }}>*</span></Box>
                <TextField
                  id="permanentAddress"
                  placeholder="Enter Task Id"
                  variant="outlined"
                  fullWidth
                  disabled = {true}
                  value={taskcode}
                  onChange={handleprojectcodeChange}
                />
                 {errorFields.taskid && <span className="error-msg">This is required Field</span>}
              </Grid>
            ) : (

              <Grid item xs={12} sm={4} md={4} lg={4} sx={{ mt: 2 }}>
                <Box className="editlabel" >Task Id</Box>
                <Box className="editvalue">{taskcode}</Box>
              </Grid>
            )}


{isEditMode ? (
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <Box className="label" > Project Type <span style={{ color: "red" }}>*</span></Box>
                <FormControl fullWidth>
                  <Select
                    id="permanentState"
                    defaultValue=""
                    placeholder="Select Type"
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                    style={{ border: "1.5px solid #B2D4F8", color: "grey" }}
                    value={projecttypename}
                    onChange={handleprojecttype}
                  >
                    <MenuItem value="">
                    Project Type
                    </MenuItem>
                    
          {projecttypeoption.map((option) => (
          <MenuItem key={option.id} value={option.projectTypeName}>{option.projectTypeName}</MenuItem>
        ))}
                  </Select>
                </FormControl>
                {errorFields.projecttype && <span className="error-msg">This is required Field</span>}
              </Grid>
            ) : (

              <Grid item xs={12} sm={4} md={4} lg={4} sx={{mt:2}}>
                <Box className="editlabel" >Project Type</Box>
                <Box className="editvalue">{projecttypename}</Box>
              </Grid>
            )}


            {isEditMode ? (
              <Grid item xs={12} sm={6} md={6} lg={6}>

                <Box className="label" >Task Name<span style={{ color: "red" }}>*</span></Box>
                <TextField
                  id="permanentAddress"
                  placeholder="Enter the Task name"

                  // Add onChange handler
                  variant="outlined"
                  fullWidth
                  value={taskname}
                    onChange={handleprojectnameChange}
                />
                 {errorFields.taskname && <span className="error-msg">This is required Field</span>}
              </Grid>
            ) : (

              <Grid item xs={12} sm={4} md={4} lg={4} sx={{ mt: 2 }}>
                <Box className="editlabel" >Task Name</Box>
                <Box className="editvalue">{taskname}</Box>
              </Grid>
            )}


            

            {/* <Box>Current Addres</Box> */}
            {isEditMode ? (
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <Box className="label">Status<span style={{ color: "red" }}>*</span></Box>
                <FormControl fullWidth>
                  <Select
                    id="permanentState"
                    defaultValue=""
                    placeholder="Select status"
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                    style={{ border: "1.5px solid #B2D4F8", color: "grey" }}
                    value={status}
                    onChange={handlestatusChange}
                  >
                    <MenuItem value="">
                      Select Status
                    </MenuItem>
                    <MenuItem value="Active">Active</MenuItem>
              <MenuItem value="Inactive">InActive</MenuItem>
              {/* <MenuItem value="Onhold">OnHold</MenuItem> */}
                  </Select>
                </FormControl>
                {errorFields.status && <span className="error-msg">This is required Field</span>}
              </Grid>
            ) : (

              <Grid item xs={12} sm={4} md={4} lg={4} sx={{ mt: "30px" }}>
                <Box className="editlabel" >Status</Box>
                <Box className="editvalue">{status}</Box>
              </Grid>
            )}
          



          
   

      
        {isEditMode ? (
              <Grid item xs={12} sm={6} md={6} lg={6} sx={{pl:4, pr:5}}>
                <Box className="label">Cost Center<span style={{ color: "red" }}>*</span></Box>
                <FormControl fullWidth>
                  <Select
                    id="permanentState"
                    defaultValue=""
                    placeholder="Select Country"
                    displayEmpty
                    fullWidth
                    inputProps={{ 'aria-label': 'Without label' }}
                    style={{ border: "1.5px solid #B2D4F8", color: "grey" ,width:"100%"}}
            
                    value={costcenter}
                    onChange={handleaccountChange}
                  >
                    <MenuItem value="">
                      Select Cost Center
                    </MenuItem>
                    <MenuItem value="Billable">BILLABLE</MenuItem>
              <MenuItem value="Non-Billable">NONBILLABLE</MenuItem>
                  </Select>
                </FormControl>
                {errorFields.costcenter && <span className="error-msg">This is required Field</span>}
              </Grid>
            ) : (

              <Grid item xs={12} sm={4} md={4} lg={4} sx={{ pl:4, mt: "30px" }}>
                <Box className="editlabel" >Cost Center</Box>
                <Box className="editvalue">{costcenter}</Box>
              </Grid>
            )}
       </Grid>
        <Grid container spacing={1} sx={{ pl: 4, pr: 5, mt: 5, justifyContent: "center" }} >
     

          <Grid item xs={12} sm={2} md={2} lg={2}>
            <Button className="cancelButton" onClick={handleNavigate}>Cancel</Button>
          </Grid>

          <Grid item xs={12} sm={2} md={2} lg={2}>
            <Button
               className="saveButton" onClick={handleprojectupdate}>Save</Button>
          </Grid>

        </Grid>

        </div>
  )
}

export default EditNewTask