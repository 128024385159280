import React, { useState, useContext, useEffect } from 'react';
import moment from 'moment';
import Tooltip from '@mui/material/Tooltip';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import './LeaveMain.css'; // Import your CSS styles
import leaveManagementAPI from 'apis/panel/leaveManagemnet/leaveManagement';
import Context from "context/context";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';

// Function to generate data array with dynamic year
const generateData = (year) => {
    return [
        { month: 'Jan', value: `${year}-01-01` , year:`${year}`},
        { month: 'Feb', value: `${year}-02-01` , year:`${year}`},
        { month: 'Mar', value: `${year}-03-01` , year:`${year}`},
        { month: 'Apr', value: `${year}-04-01` , year:`${year}`},
        { month: 'May', value: `${year}-05-01` , year:`${year}`},
        { month: 'June', value: `${year}-06-01` , year:`${year}`},
        { month: 'July', value: `${year}-07-01` , year:`${year}`},
        { month: 'Aug', value: `${year}-08-01` , year:`${year}`},
        { month: 'Sept', value: `${year}-09-01` , year:`${year}`},
        { month: 'Oct', value: `${year}-10-01` , year:`${year}`},
        { month: 'Nov', value: `${year}-11-01` , year:`${year}`},
        { month: 'Dec', value: `${year}-12-01` , year:`${year}`},
    ];
};

const LeaveMain = () => {
    const currentYear = moment().year();
    const currentMonth = moment().month();
    const data = generateData(currentYear);

    const { state, dispatch } = useContext(Context);
    const [departmentName, setDepartmentName]= useState(state?.employee?.departmentName)
   
    const [value, setValue] = useState(currentMonth);
    const [holidayList, setHolidayList] = useState([]);
    const [publicHolidayCount, setpublicHolidayCount]= useState("")
    const selectedMonth = data[value].value;
    

    const { getAllPublicHolidaysAPI } = leaveManagementAPI();

    useEffect(() => {
        handleGetAllHolidaysApi();
    }, [selectedMonth]);

    const handleGetAllHolidaysApi = async () => {
        try {
            dispatch({ type: "SET_LOADING", payload: true });
            let values = { month: selectedMonth }; 
            let res = await getAllPublicHolidaysAPI(values);
            setHolidayList(res?.data?.totalHolidays || []);
            setpublicHolidayCount(res?.data?.totalPublicHolidaysCount || 0)
           
            dispatch({ type: "SET_LOADING", payload: false });
        } catch (err) {
            dispatch({ type: "SET_LOADING", payload: false });
        }
    };

    const generateCalendar = (month) => {
        const startOfMonth = moment(month).startOf('month');
        const endOfMonth = moment(month).endOf('month');
        const days = [];
        let date = startOfMonth.clone();

        while (date <= endOfMonth) {
            days.push(date.clone());
            date.add(1, 'days');
        }

        const weeks = [];
        let week = [];
        const startDay = startOfMonth.day() === 0 ? 6 : startOfMonth.day() - 1;
        for (let i = 0; i < startDay; i++) {
            week.push(null);
        }
        days.forEach(day => {
            if (week.length === 7) {
                weeks.push(week);
                week = [];
            }
            week.push(day);
        });
        while (week.length < 7) {
            week.push(null);
        }
        weeks.push(week);
        return weeks;
    };

    const calendarWeeks = generateCalendar(selectedMonth);
    const renderDay = (day) => {
        if (!day) return <div className="calendar-day empty" />;
    
        const publicHoliday = holidayList.find(holiday =>
            moment(holiday.holidayDate).isSame(day, 'day')
        );
    
        if(departmentName == "KPO/BPO"){
            const dayClass = day.day() === 6 || day.day() === 0 ? 'weekday' : 'weekday';
            const holidayClass = publicHoliday ? (publicHoliday.isOptional ? 'optional-holiday' : 'holiday') : '';
            return (
                <Tooltip  key={day.format('YYYY-MM-DD')} title={publicHoliday ? publicHoliday.description : ''} arrow  PopperProps={{
                    sx: {
                        '& .MuiTooltip-tooltip': {
                            backgroundColor: '#36454F', // Change this to your desired color
                            color: 'white',
                            fontFamily:"Inter" // Optional: Change the text color if needed
                        },
                    },
                }}>
                    <div className={`calendar-day ${dayClass} ${holidayClass}`}>
                        {day.date()}
                        {publicHoliday && (
                            <div className="holiday-name">{publicHoliday.holidayName}</div>
                        )}
                    </div>
                </Tooltip>
            );
        
        
        }
        else{
            const dayClass = day.day() === 6 || day.day() === 0 ? 'weekend' : 'weekday';
            const holidayClass = publicHoliday ? (publicHoliday.isOptional ? 'optional-holiday' : 'holiday') : '';
       
            return (
                <Tooltip  key={day.format('YYYY-MM-DD')} title={publicHoliday ? publicHoliday.description : ''} arrow  PopperProps={{
                    sx: {
                        '& .MuiTooltip-tooltip': {
                            backgroundColor: '#36454F', // Change this to your desired color
                            color: 'white',
                            fontFamily:"Inter" // Optional: Change the text color if needed
                        },
                    },
                }}>
                    <div className={`calendar-day ${dayClass} ${holidayClass}`}>
                        {day.date()}
                        {publicHoliday && (
                            <div className="holiday-name">{publicHoliday.holidayName}</div>
                        )}
                    </div>
                </Tooltip>
            );
        }
        
        }
       
        
    
      

    return (
        <div>
            <Tabs
                value={value}
                TabIndicatorProps={{
                    style: {
                        display: 'none',
                    },
                }}
                sx={{
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                    marginLeft: "auto",
                    marginRight: "auto",
                }}
            >
                {data.map((e, i) => (
                    <Tab
                        key={i}
                        label={(
                            <>
                                <Typography className="subtitle1month" sx={{ color: i === value ? 'white' : 'black' }}>{e.month}</Typography>
                                <Typography className="captionmonth" sx={{ color: i === value ? 'white' : 'black' }}>{e.year}</Typography>
                            </>
                        )}
                        sx={{
                            backgroundColor: i === value ? '#0070E9' : '',
                            color: i === value ? 'white' : 'black',
                            width: "auto",
                            minWidth: "7%",
                            borderRadius: "5px",
                            marginRight: "10px",
                        }}
                        onClick={() => setValue(i)}
                    />
                ))}
            </Tabs>

            <hr style={{ border:" 1px solid #E9E9E9" ,marginTop:"3%", width:"96%"}}/>
           
           <Grid container style={{alignItems:"center"}}>
            <Grid xs={12} sm={12} md={6} lg={6} xl={6} sx={{display:"flex", gap:"2%"}}>
          
       
        <Box className="privilizedleavegrid"><ul><li>Public Holiday</li></ul></Box>
        <Box className="Optinalleavegridhl"><ul><li>Optional Holiday</li></ul> </Box>
        {/* <Box className="Sick-Leave-grid"><ul><li>Sick Leave</li></ul></Box> */}
        <Box className="Holidaygrid"><ul><li> Weekend</li></ul> </Box>

            </Grid>
            <Grid xs={12} sm={12} md={5.5} lg={5.5} xl={5.5} style={{display:"flex", gap: "2%", justifyContent:"end", textAlign:"right", alignItems:"center"}}>
                <Box className="totalCountinholidaylisthead">Total Public Holidays :</Box>
                <Box className="totalCountinholidaylist">{publicHolidayCount}</Box>
            </Grid>
           </Grid>

           <Grid container>
            <Grid item xs={12} lg={6.5} sm={6.5} xl={6.5}>
            <div className="calendar">
                <div className="calendar-header">
                    {['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'].map(day => (
                        <div key={day} className="calendar-header-day">
                            {day}
                        </div>
                    ))}
                </div>
                <div className="calendar-body">
                    {calendarWeeks.map((week, index) => (
                        <div key={index} className="calendar-week">
                            {week.map(day => renderDay(day))}
                        </div>
                    ))}
                </div>
            </div>
            </Grid>
            <Grid item xs={12} lg={5} sm={5} xl={5}>
                    <Box className="holiday-list">
                    <h3 style={{ marginTop: "2%", fontFamily:"Inter", textAlign:"center"}}>
                      Holiday List
                    </h3>
                    {holidayList.length > 0 ? (
                        <ul>
                            {holidayList?.map((holiday, index) => (
                                <li key={index}
                                     style={{marginLeft:"20%"}}
                                 className={`holiday-item ${holiday.isOptional ? 'optional-holidayforholidaylist' : 'public-holidayforholidaylist'}`}
                                //  style={{ color: holiday.isOptional ? 'red' : "#008A27" }}
                                 >
                                    <div className="holidayListHolidayName">
                                        {moment(holiday.holidayDate).format('MMM DD')} - {holiday.holidayName}
                                    </div>
                                    {/* <Typography variant="body2" color="textSecondary">
                                        {holiday.description}
                                    </Typography> */}
                                </li>
                            ))}
                            </ul>
                            ):(
                                <div style={{fontFamily:"Inter", fontSize:"18px", color:"gray", textAlign:"center", fontWeight:600}}> No Holidays</div>
                            )
                        }
                    </Box>
               
            </Grid>
            </Grid>
        </div>
    );
};

export default LeaveMain;
