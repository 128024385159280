export default function reducer(state, { type, payload }) {
  switch (type) {
    case "SET_IS_LOGGED_IN":
      return {
        ...state,
        isLoggedIn: payload,
      };
    case "SET_LOADING":
      return {
        ...state,
        loading: payload,
      };
    case "SET_EMPLOYEE":
      return {
        ...state,
        employee: payload,
      };
    case "SET_EMPLOYEE_DATA":
      return {
        ...state,
        employeeData: payload,
      };
    case "SET_ROLE":
      return {
        ...state,
        role: payload,
      };
    case "SET_PERMISSION":
      return {
        ...state,
        permission: payload,
      };
    case "SET_SIDEBAR":
      return {
        ...state,
        sidebar: payload,
      };
    case "SET_LOGOUT":
      return {
        isLoggedIn: false,
        loading: false,
        role: {},
        employee: {},
        permission: [],
      };
    default:
      return state;
  }
}
