import React, { useEffect , useState, useContext} from 'react';
import "./Reports.css";
import { Typography } from '../../../../node_modules/@mui/material/index';
import * as moment from "moment";
import Context from "context/context";
import { toast } from "react-toastify";

// material ui
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Card from "@mui/material/Card";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Grid";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Pagination from '@mui/material/Pagination';
import VisibilityIcon from '@mui/icons-material/Visibility';

import { toastCss, theme } from "config/config";

// api imports
import reportsAPI from 'apis/panel/reports/reports';

import exportIcon from "assets/images/Vector (8).png";


// import * as XLSX from 'xlsx';
import { useNavigate } from 'react-router-dom';

// import { useNavigate } from '../../../../node_modules/react-router-dom/dist/index';

import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

const CustomWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 500,
    cursor: "pointer",
    backgroundColor: "#002c5d",
    color: "#ffffff",
  },
});

const ProjectSetup = () => {

  const { dispatch } = useContext(Context);
  const [projects, setProjects] = useState([]);
  const [TaskList , setTaskList] = useState([])
  const [selectedProjectId , setSelectedProjectId]= useState([]);
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  
  const [page, setPage] = useState(1);
  const [offset, setOffset] = useState(1);
  const [count, setCount] = useState(0)
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [submitted, setSubmitted] = useState(false);
  const [startDate, setStartDate]= useState(null);
  const [submitClicked, setSubmitClicked]= useState(false);
  const [endDate, setEndDate]= useState(null)
  const [projectName,setProjectName]= useState('')

  const navigate=useNavigate()

  const itemsPerPage=10

  const { getAllProjectNames, getAllProjectTasks, getWeeklyReportAPI } = reportsAPI();

  const handleProjects = async () => {
    try {
      const res = await getAllProjectNames();
      setProjects(res?.data?.projects || []);
    } catch (error) {
      console.error('Error fetching projects:', error);
    }
  };

  
 

  useEffect(() => {
    handleProjects();
    if (submitClicked || submitted) {
      handleSubmit();
      
    }
   
  }, [offset]);

  
  const handleExport = async (rowData) => {
    
   let body = {
      startDate: moment(startDate).format("YYYY-MM-DD"),
      endDate: moment(endDate).format("YYYY-MM-DD"),
      taskId:rowData.task_id,
      projectId:selectedProjectId
    };

    dispatch({ type: "SET_LOADING", payload: true });
    // setDisableDropdown(true);
   
    await getWeeklyReportAPI(body);
    dispatch({ type: "SET_LOADING", payload: false });
  };



  const handleview = async (rowData) => {
    
    let body = {
      startDate: moment(startDate).format('YYYY-MM-DD'),
      endDate: moment(endDate).format('YYYY-MM-DD'),
       taskId:rowData.task_id,
       projectId:selectedProjectId,
       projectName:projectName
       
     };
     openNewTabWithState(body);

    //  dispatch({ type: "SET_LOADING", payload: true });
    //  navigate('/panel/weeklyReports',{ state: body })
    //  dispatch({ type: "SET_LOADING", payload: false });
   };

   const openNewTabWithState = (body) => {
    const queryString = encodeStateToQueryString(body);
    window.open(`/panel/weeklyReports?${queryString}`, '_blank', 'noopener,noreferrer');
  };

  const encodeStateToQueryString = (state) => {
  return new URLSearchParams(state).toString();
};

  // const handleview = async (rowData) => {
  //   let body = {
  //     startDate: moment(startDate).format('YYYY-MM-DD'),
  //     endDate: moment(endDate).format('YYYY-MM-DD'),
  //      taskId:rowData.task_id,
  //      projectId:selectedProjectId,
  //      projectName:projectName
  //    };
 

  //    dispatch({ type: "SET_LOADING", payload: true });
  //    navigate('/panel/weeklyReports',{ state: body })


  //    dispatch({ type: "SET_LOADING", payload: false });
  //  };
   
  
  

  // const handleExportRow = (rowData) => {
  //   // Convert rowData to worksheet
  //   const ws = XLSX.utils.json_to_sheet([rowData]);
  
  //   // Create workbook
  //   const wb = XLSX.utils.book_new();
  //   XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
  
  //   // Convert workbook to binary string
  //   const wbout = XLSX.write(wb, { type: 'binary', bookType: 'xlsx' });
  
  //   // Create a Blob object
  //   const blob = new Blob([s2ab(wbout)], { type: 'application/octet-stream' });
  
  //   // Create a temporary anchor element
  //   const link = document.createElement('a');
  //   link.href = window.URL.createObjectURL(blob);
  //   link.download = 'row_data.xlsx';
  
  //   // Trigger a click event on the anchor element
  //   link.click();
  
  //   // Cleanup
  //   window.URL.revokeObjectURL(link.href);
  // };
  
  // const s2ab = (s) => {
  //   const buf = new ArrayBuffer(s.length);
  //   const view = new Uint8Array(buf);
  //   for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
  //   return buf;
  // };


//  console.log(projects,'whole');

const handleProjectId=async(projectId)=>{

//  console.log(projectId,'project');
  setSelectedProjectId(projectId)
  

 
  
}

useEffect(()=>{
  projects.forEach(id => {
    if(id.projectId===selectedProjectId){
      setProjectName(id.projectName)
    }
  });
},[selectedProjectId])

// console.log(selectedProjectId,'selectedProject');

// console.log(projectName,'name');
 
  const handleGetProjectTasks = async (selectedProjectId) => {
    let values;
    values = {
        offset: (offset - 1) * 10,
        limit: 10,
       id:selectedProjectId
    };
 
    try {
      dispatch({ type: "SET_LOADING", payload: true });
        let res = await getAllProjectTasks(values);
      
        setCount(res?.data?.count);
        setTaskList(res?.data?.tasks || [])
       
         dispatch({ type: "SET_LOADING", payload: false });
    }
    catch (err) {
        setLoading(false);
    }
};


const indexOfLastItem = currentPage * perPage;
    const indexOfFirstItem = indexOfLastItem - perPage;

const handlePagination = (value) => {

  setOffset(value);
};


  const handleSubmit=()=>{
  
    setSubmitClicked(true)
    if(!startDate || !endDate ){
      setSubmitted(false);
    }
    if (selectedProjectId !='' ) {
      setSubmitted(true);
      handleGetProjectTasks(selectedProjectId);
      
    } 
  }


  const getStatusColor = (status) => {
    return status === 'Active' ? 'green' : 'red';
  };


    const heading=[
            "S.No",
            "Project Type",
            "Task Name",
            "Cost Center",
            "Status",
            "Action"
    ]
  return (
    <div>
<Grid container spacing={1} sx={{ mt: 2 }} style={{}}>

  <Grid item xl={3} lg={3} md={6} sm={6} xs={6} sx={{ alignItems: "center" }}>
    <FormControl fullWidth>
      <Select
        sx={{ height: "44px" }}
        id="maritalStatus"
        placeholder="Select ID Proof"
        inputProps={{ "aria-label": "Without label"}}
        value={selectedProjectId}
        onChange={(e) => handleProjectId(e.target.value)}
        displayEmpty
      
      >

  <MenuItem value="" disabled>Select Project</MenuItem>
              {projects.map((project, index) => (
                <MenuItem key={index} value={project.projectId}>
                  {project.projectName} {`(${typeof project?.status ==="boolean"? project?.status?.toString():project?.status})`}
                </MenuItem>
              ))}
      </Select>
    </FormControl>
    {submitClicked && selectedProjectId=='' && (
    <Box sx={{ color: 'red', fontSize: '13px' }}>
        <span>*</span> Required field
    </Box>
)}
  </Grid>
  <Grid item xl={3} lg={3} md={6} sm={6} xs={6} sx={{pl:2  }}>
    <LocalizationProvider fullWidth dateAdapter={AdapterDateFns}>
    <DatePicker
              // style={{ height: "50px" }}
              id  = "startDate"
              name="startDate"
              placeholder="Start Date"
              className="dateInputforstrtDate"
              value={startDate}
              onChange={(newValue) => setStartDate(newValue)}
              format="dd-MMM-yyyy"
              maxDate={new Date()}
              renderInput={(params) => <TextField {...params} label="Start Date" placeholder="Select Start Date" fullWidth />}
            />
    </LocalizationProvider>
    {submitClicked && !startDate && (
    <Box sx={{ color: 'red', fontSize: '13px' }}>
        <span>*</span> Required field
    </Box>
)}

    
  </Grid>
  <Grid item xl={3} lg={3} md={6} sm={6} xs={6} sx={{ pl:2 }}>
    <LocalizationProvider fullWidth dateAdapter={AdapterDateFns}>
    <DatePicker
              // sx={{ height: "50px" }}
              id="endDate"
              name="endDate"
              className="dateInputforstrtDate"
              value={endDate}
              onChange={(newValue) => setEndDate(newValue)}
              format="dd-MMM-yyyy"
              minDate={startDate}
              maxDate={new Date()}
              
              renderInput={(params) => <TextField {...params} label="End Date" placeholder="End Date" fullWidth />}
            />
     
    </LocalizationProvider>
    {submitClicked && !endDate && (
    <Box sx={{ color: 'red', fontSize: '13px' }}>
        <span>*</span> Required field
    </Box>
)}

  </Grid>
<Grid item xl={3} lg={3} md={6} sm={6} xs={6} sx={{ pl:2, textAlign:"center", alignItems:"center"}} >
    <button className="projectsetupSubmitButton" onClick={handleSubmit}>Submit</button>
  </Grid>
</Grid>


<Grid>

 
        {submitted && startDate && endDate?  (
          <>
          <TableContainer>
            <Table aria-label="collapsible table">
              <TableHead>
                <TableRow>
                  {heading.map((h, i) => (
                    <TableCell key={i}>
                      <b className="tablecellforemployeeRoster">{h}</b>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {TaskList.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={heading.length} sx={{ textAlign: 'center'}}>
                      No tasks found
                    </TableCell>
                  </TableRow>
                ) : (
                  TaskList.map((task, i) => (  
                    <TableRow key={i}>
                      <TableCell className="TableCell">{(offset - 1) * itemsPerPage + i + 1}</TableCell>
                      <TableCell className="TableCell">{task?.project_type_name}</TableCell>
                      <TableCell className="TableCell">{task?.task_name}</TableCell>
                      <TableCell className="TableCell">{task?.cost_center}</TableCell>
                      <TableCell   className="TableCell">
                         <span style={{ color: getStatusColor(task.status),  fontWeight :"700"}}>{task.status}</span>
                      </TableCell>

                      <TableCell className="TableCell"  style={{ alignItems:"center"}} >
                        <div style={{textAlign:"center", alignContent:"center", alignItems:"center", display:"flex",gap:"2%"}}>
                        <CustomWidthTooltip
          title='Export'
        >  
                      <div> <img  onClick={() => handleExport(task)}  src={exportIcon} alt=""/></div> 
                      </CustomWidthTooltip>
                      <CustomWidthTooltip
          title='Click to view details'
        >  
                       <div> <VisibilityIcon  style={{color:"#E97000"}} onClick={() => handleview(task)}/></div>
                        
                        </CustomWidthTooltip>
                        </div> 
                      </TableCell>
                    </TableRow>
                    ))  
                )}
                
              </TableBody>
              
            </Table>
          </TableContainer>
           <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                <Pagination
                    count={(count /itemsPerPage ) % 1 == 0 ? Math.floor(count / itemsPerPage) : Math.floor(count / itemsPerPage + 1)}
                    page={offset}
                    siblingCount={1}
                    onChange={(event, value) => {
                        handlePagination(value);
                    }}
                    color="primary"
                /> 


{/* <Pagination
                count={Math.ceil(count / itemsPerPage)}
                page={offset}
                siblingCount={1}
                onChange={(event, value) => handlePagination(value)}
                color="primary"
              /> */}
            </Box>
            
          </>
          
          
          
        ):(
          <Typography variant="h6" sx={{ mt: 3, textAlign: 'center' ,color: "grey"}}>
            No data available
          </Typography>
        ) }
        
      </Grid>


    </div>
  )
}

export default ProjectSetup