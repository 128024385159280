
import React, { useEffect, useContext, useState } from 'react';
import { toast } from "react-toastify";

import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Tooltip from '@mui/material/Tooltip';
import moment from 'moment';
import getAllLeavetypesAPI from "apis/panel/leaveManagemnet/leaveManagement";
import leaveManagementAPI from 'apis/panel/leaveManagemnet/leaveManagement';
// import Context from "context/context";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { InputAdornment, IconButton } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import ClearIcon from "@mui/icons-material/Clear";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Modal from "@mui/material/Modal";

import Context from "context/context";

import leftarrow from "assets/images/arrow-right.png";
import rightarrow from "assets/images/arrow-right (1).png"
import 'react-calendar/dist/Calendar.css';
import Calendar from 'react-calendar';
import "./LeaveApply.css";

// custom pages 
import FileHandler from "apis/panel/fileHandler/fileHandler";
import ReusableFunction from "utils/reusablefunction";
import getemployees from "apis/panel/timesheet/TimeSheet";
import { useNavigate } from '../../../../../node_modules/react-router-dom/dist/index';


const generateData = (year) => {
  return [
    { month: 'Jan', value: `${year}-01-01`, year: `${year} ` },
    { month: 'Feb', value: `${year}-02-01`, year: `${year} ` },
    { month: 'Mar', value: `${year}-03-01`, year: `${year} ` },
    { month: 'Apr', value: `${year}-04-01`, year: `${year} ` },
    { month: 'May', value: `${year}-05-01`, year: `${year} ` },
    { month: 'June', value: `${year}-06-01`, year: `${year} ` },
    { month: 'July', value: `${year}-07-01`, year: `${year} ` },
    { month: 'Aug', value: `${year}-08-01`, year: `${year} ` },
    { month: 'Sept', value: `${year}-09-01`, year: `${year} ` },
    { month: 'Oct', value: `${year}-10-01`, year: `${year} ` },
    { month: 'Nov', value: `${year}-11-01`, year: `${year} ` },
    { month: 'Dec', value: `${year}-12-01`, year: `${year} ` },
  ];
};
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 3,
  width: "30%",
  borderRadius: "20px",
};

const LeaveApply = () => {
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [selectedLeaveType, setSelectedLeaveType] = useState('');
  const [leavesTypelist, setLeavesTypeList] = useState([]);
  const [currentMonthFrom, setCurrentMonthFrom] = useState(moment().startOf('month'));
  const [currentMonthTo, setCurrentMonthTo] = useState(moment().startOf('month'));
  const [totalDays, setTotalDays] = useState('');
  const [holidayList, setHolidayList] = useState([]);
  const [description, setDescription] = useState('');
  const [passbookFilePath, setPassbookFilePath] = useState("");
  const [passbook, setpassbook] = useState(null);
  const [employeeId, setEmployeeId] = useState('');
  const [leaveTypeError, setLeaveTypeError] = useState('');
  const [descriptionError, setDescriptionError] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedDayTypes, setSelectedDayTypes] = useState({});
  const [limitMonth, setLimitMonth] = useState(moment().clone().subtract(1, 'month'));
  const [availabledays, setAvailableDays] = useState('')
  const [leaveTypeFromapi, setLeaveTypeFromApi] = useState('')
  const [filteredSelectedDates, setFilteredSelectedDates] = useState([]);
  const [modalDaySelections, setModalDaySelections] = useState([]);
  const [availableDaysForType, setAvailableDaysForType] = useState(0);
  const [isGridVisible, setIsGridVisible] = useState(false);
  const { state, dispatch } = useContext(Context);
  const [roleCode, setRoleCode] = useState(state?.employee?.departmentName || "");
  const Navigate = useNavigate();
  const [availableDaysList, setAvailableDaysList] = useState([]);
  const [pendingLeavesDaysList,setPendingLeavesDaysList] =useState([])
  const [allLeaveDates,setAllLeaveDates] = useState([])
  const { getAllLeaveTypesAPI, createLeaveRequestAPI } = getAllLeavetypesAPI();
  const { fileUploadAPI } = FileHandler();
  const { handleFileInputReselect } = ReusableFunction();
  const { getEmployeeDetailsAPI } = getemployees();
  const handleClearpassbookFile = () => {
    setpassbook("");
  };

  const handleFileUpload = async (file, type) => {
    const maxSize = 1024 * 1024 * 5;
    if (file.size > maxSize) {
      toast.error("File size exceeds the limit (5MB)", {
        toastId: "file-upload-maximum",
      });
      return false;
    }
    try {
      dispatch({ type: "SET_LOADING", payload: true });
      let input = new FormData();
      input.append("type", "employee_leave_documents");
      input.append("employeeId", employeeId);
      input.append("file", file);
      const res = await fileUploadAPI(input);
      dispatch({ type: "SET_LOADING", payload: false });
      if (res?.data?.location) {
        return res?.data?.location;
      }
      return false;
    } catch (err) {
      dispatch({ type: "SET_LOADING", payload: false });
      console.log({ err });
    }
  };
  const handleFileChangeSSC = async (event) => {
    const file = event.target.files[0];
    const location = await handleFileUpload(file);
    if (location) {
      setPassbookFilePath(location);
      setpassbook(file);
    }
  };
  const calculateModalTotalDays = () => {
    let totalDays = 0;
    
    
    
    
    
    filteredSelectedDates.forEach((date) => {
      const formattedDate = date.format('YYYY-MM-DD');
      const dayTypeInfo = halfDayLeaveDates.find(d => d.date === formattedDate);
      const dayTypeOptions = dayTypeInfo ? (() => {
        switch (dayTypeInfo.day_type) {
          case 'First Half':
            return ['0.5_second_half'];
          case 'Second Half':
            return ['0.5_first_half'];
            case 'Full':
            return [0];
          default:
            return null;
        }
      })() : ['1', '0.5_first_half', '0.5_second_half'];

      
      const selectedType = selectedDayTypes[date.format('YYYY-MM-DD')] ||  (dayTypeInfo ? dayTypeOptions : "1");
    
     
     
     
      if (selectedType == '1') {
        totalDays += 1;
       
        
      } else if(selectedType == '0.5_second_half' || selectedType == '0.5_first_half' ) {
        
        
        totalDays += 0.5;
       
      }
     
      
    });
    return totalDays;

    // if(totalDays>availableDaysForType){
     
    //   return availableDaysForType;
    // }else{
    //   return totalDays;
    // }
   
  };

  useEffect(() => {
    if (fromDate) {
      const endDate = toDate || fromDate;
      const isSingleDay = !toDate;
      const filteredDates = Array.from(
        { length: endDate.diff(fromDate, 'days') + 1 },
        (_, i) => fromDate.clone().add(i, 'day')
      ).filter(date => !isPublicHolidayOrWeekend(date));

      setFilteredSelectedDates(filteredDates);

    }
  }, [fromDate, toDate]);


  const [halfDayLeaveDates, setHalfDayLeaveDates] = useState([]);

  useEffect(() => {
    const results = filteredSelectedDates.map(selectedDate => {
      const formattedSelectedDate = selectedDate.format('YYYY-MM-DD');
      const leaveInfo = allLeaveDates.find(leave => 
        moment(leave.date).format('YYYY-MM-DD') === formattedSelectedDate &&
        (leave.day_type === 'First Half' || leave.day_type === 'Second Half' || leave.day_type === 'Full')
      );

      return leaveInfo ? {
        date: formattedSelectedDate,
        day_type: leaveInfo.day_type
      } : null;
    }).filter(item => item !== null); // Filter out null values

    setHalfDayLeaveDates(results);

  }, [filteredSelectedDates, allLeaveDates]);

  
  

  const handleRaiseLeaveRequest = async () => {
    let valid = true;

    // Validation for leave type
    if (!selectedLeaveType) {
      setLeaveTypeError('This field is mandatory');
      valid = false;
    } else {
      setLeaveTypeError('');
    }

    // Validation for description
    if (!description) {
      setDescriptionError('This field is mandatory');
      valid = false;
    } else {
      setDescriptionError('');
    }

    // Validation for dates
    if (!fromDate) {
      toast.error('Please select valid start and end dates.', {
        toastId: "start and end date error",
      });
      valid = false;
    }

    // Proceed only if the form is valid
    if (!valid) {
      return;
    }
    const leaveTypeName = leavesTypelist.find(leave => leave.leaveTypeId === selectedLeaveType)?.leaveTypeName;
    if (leaveTypeName === "Privilege Leave" || leaveTypeName === "Sick Leave"){
      
      
      const totalDays=calculateModalTotalDays()
if(totalDays>availableDaysForType){
  return toast.error(`You can only select up to ${availableDaysForType} days for this leave type.`, {
    toastId: "date-range-error",
  });
}
    }

    // Calculate filtered dates
    const isSingleDay = !toDate;
    const endDate = toDate || fromDate;
    const filteredDates = Array.from(
      { length: endDate.diff(fromDate, 'days') + 1 },
      (_, i) => fromDate.clone().add(i, 'day')
    ).filter(date => !isPublicHolidayOrWeekend(date));

    setFilteredSelectedDates(filteredDates);

    // Prepare daysArray based on filteredSelectedDates
    let daysArray = [];
    filteredDates.forEach((date) => {
      const formattedDate = date.format('YYYY-MM-DD');
      const dayTypeInfo = halfDayLeaveDates.find(d => d.date === formattedDate);
      const dayTypeOptions = dayTypeInfo ? (() => {
        switch (dayTypeInfo.day_type) {
          case 'First Half':
            return ['0.5_second_half'];
          case 'Second Half':
            return ['0.5_first_half'];
          default:
            return null;
        }
      })() : ['1', '0.5_first_half', '0.5_second_half'];
      const selectedType = selectedDayTypes[date.format('YYYY-MM-DD')] ||  (dayTypeInfo ? dayTypeOptions : "1");
      let dayType = "Full";
      if (selectedType === '1') {
        dayType = "Full";
      } else if (selectedType === '0.5_first_half' ) {
        dayType = "First Half";
      }else if(selectedType === '0.5_second_half'){
        dayType = "Second Half";
      }
      else if( selectedType === '0.5_second_half'){
        dayType= "Second Half"
      }

      daysArray.push({
        date: date.format('YYYY-MM-DD'),
        dayType: dayType,
        dayLogged: selectedType === '1' ? 1 : 0.5,
      });
    });

    dispatch({ type: "SET_LOADING", payload: true });

    const value = {
      leaveTypeId: selectedLeaveType,
      startDate: fromDate.format('YYYY-MM-DD'),
      endDate: toDate ? toDate.format('YYYY-MM-DD') : fromDate.format('YYYY-MM-DD'),
      numberOfDays: calculateModalTotalDays(),
      reason: description,
      attachments: passbookFilePath,
      days: daysArray
    };

    const res = await createLeaveRequestAPI(value);

    if (res.status === 'Ok') {
      dispatch({ type: "SET_LOADING", payload: false });
      setIsModalOpen(false);
      Navigate("/panel/leaveHistory");
      toast.success("Data Saved Successfully");
    } else {
      dispatch({ type: "SET_LOADING", payload: false });
      toast.error("Error: User already exists in the system");
    }
  };

  const calculateTotalDays = (start, end) => {
    if (!start || !end) return 0;
  
    let totalDays = 0;
    const current = start.clone();
  
    while (current.isSameOrBefore(end, 'day')) {
      const isPublicHoliday = holidayList.some(holiday => moment(holiday.holidayDate).isSame(current, 'day'));
      const isWeekend = current.day() === 0 || current.day() === 6;
      
      // Check if current date has leave applied
      const isFullDay = allLeaveDates.some(leave =>
        leave.day_type === 'Full' && moment(leave.date).isSame(current, 'day')
      );
      const isHalfDay = allLeaveDates.some(leave =>
        leave.day_logged === '0.5' && moment(leave.date).isSame(current, 'day')
      );
  
      // Determine if the day should be counted
      if (roleCode === "KPO/BPO" || (!isPublicHoliday && !isWeekend)) {
        if (isFullDay) {
          // Exclude full-day leaves from totalDays
          // Do nothing, full-day leaves are excluded
        } else if (isHalfDay) {
          // Add half a day for half-day leaves
          totalDays += 0.5;
        } else {
          // Count the day if it's not a full-day or half-day leave
          totalDays++;
        }
      }
  
      current.add(1, 'day');
    }
  
    
  
    return (totalDays); 
  };
  

  const handlegetAllleaveTypes = async () => {
    const res = await getAllLeaveTypesAPI();
    setLeavesTypeList(res?.data || []);
  };

  const getAvailableDaysForSelectedLeaveType = () => {
    
    const leaveType = availableDaysList.find(
      (leave) => leave.leaveTypeId === selectedLeaveType
    );
  
    const pendingRequests = pendingLeavesDaysList.find(
      (leave) => leave.leave_type_id === selectedLeaveType
    );
  
    const balance = leaveType ? leaveType.balanceDaysPerMonth : 0;
  
    const totalAvailableDays = balance - (pendingRequests ? pendingRequests.number_of_days : 0);
  
    return totalAvailableDays;
};


  const handleGetEmployees = async () => {
    try {
      dispatch({ type: "SET_LOADING", payload: true });
      let res = await getEmployeeDetailsAPI();
      setEmployeeId(res?.data?.employeeInformation?.employeeId);

      dispatch({ type: "SET_LOADING", payload: false });
    } catch (err) { }
  };

  useEffect(() => {
    handlegetAllleaveTypes();
    handleGetEmployees()
    
  }, []);

  useEffect(() => {
    if (fromDate && toDate) {
      const totalDays = calculateTotalDays(fromDate, toDate);
      setTotalDays(totalDays);
    } else if (fromDate && !toDate) {
      setTotalDays(1);
    } else {
      setTotalDays(0);
    }
  }, [fromDate, toDate, selectedLeaveType]);

  useEffect(() => {
    if (selectedLeaveType) {
      const balanceDaysOfLeave = getAvailableDaysForSelectedLeaveType();
      setAvailableDaysForType(balanceDaysOfLeave);
    }
  }, [selectedLeaveType]);

  const handleLeaveTypeChange = (event) => {
    setSelectedLeaveType(event.target.value);
    setFromDate(null);
    setToDate(null);
  };

  const handleDateClick = (day) => {
    if (!selectedLeaveType) {
      toast.error("Please select a leave type");
      return;
    }
    if (roleCode !== "KPO/BPO" && isPublicHolidayOrWeekend(day)) return;

    if (!fromDate || (fromDate && toDate)) {
      setFromDate(day);
      setToDate(null);
    } else if (day.isBefore(fromDate)) {
      setFromDate(day);
      setToDate(null);
    } else {
      const totalSelectedDays = calculateTotalDays(fromDate, day);
      const leaveTypeName = leavesTypelist.find(leave => leave.leaveTypeId === selectedLeaveType)?.leaveTypeName;

      if (leaveTypeName === "Privilege Leave" || leaveTypeName === "Sick Leave") {
        if (totalSelectedDays <= availableDaysForType) {
          setToDate(day);
        }else if(totalSelectedDays - availableDaysForType == 0.5){
          const fullDays = Math.floor(availableDaysForType);
          const remainingDays = availableDaysForType - fullDays;
           setToDate(day);
        } else {
          toast.error(`You can  only select up to ${availableDaysForType} days for this leave type.`, {
            toastId: "date-range-error",
          });
        }
      } else {
        setToDate(day);
      }
    }
  };

  const isPublicHolidayOrWeekend = (day) => {
    if (roleCode === "KPO/BPO") {
      return false;
    }
    return Array.isArray(holidayList) && holidayList.some(holiday => moment(holiday.holidayDate).isSame(day, 'day')) || day.day() === 0 || day.day() === 6;
  };

  useEffect(() => {

    setLimitMonth(currentMonthFrom.clone().subtract(1, 'month'));
  }, []);


  const handleMonthChangeFrom = (direction) => {
  
    const newMonth = currentMonthFrom.clone().add(direction, 'month');

    if (direction === -1 && newMonth.isBefore(limitMonth, 'month')) {
      
      toast.error('Cannot go back more than one month from the current month.',{toastId:'previousmonth'})
      return;
    }else if (direction === 1 && newMonth.isAfter(limitMonth, 'year')){
     toast.error('Cannot go forward more than one year from the current year.',{toastId:'nextyear'});
      return;
    }
    setCurrentMonthFrom(newMonth);
  };


  


  const renderDay = (day, handleClick) => {
    if (!day) return <div className="calendar-dayInapply12" />;
    const isKPOBPO = roleCode === "KPO/BPO";
    const isPublicHoliday = holidayList.some(holiday => moment(holiday.holidayDate).isSame(day, 'day'));
    const isLeaveApplied = allLeaveDates.some(leave => moment(leave.date).isSame(day, 'day'));
    const isFullDay = allLeaveDates.some(leave => 
      leave.day_type === 'Full' && moment(leave.date).isSame(day, 'day')
  );
    const isWeekend = day.day() === 0 || day.day() === 6;
    const isSelected = day.isSame(fromDate, 'day') || day.isSame(toDate, 'day');
    const isInRange = fromDate && toDate && day.isBetween(fromDate, toDate, 'day', '[]');

    let dayClass = 'weekday';
    if (isSelected) dayClass = 'selected';
    if (isInRange && (isKPOBPO || (!isPublicHoliday && !isWeekend))) dayClass = 'in-range'; // Highlight range only if it's not a public holiday or weekend, unless role code is KPO/BPO
    if (isWeekend || isPublicHoliday) dayClass = isKPOBPO ? 'in-range' : 'weekend'; // Apply the selected range color for KPO/BPO
    if (isPublicHoliday) dayClass = 'public-holiday';
    if(isLeaveApplied && isFullDay) dayClass = 'applied' ;
    const isDisabled = isLeaveApplied && isFullDay;

    const backgroundColor = isSelected ? '#0070E9' :
                            (isInRange && dayClass === 'in-range') ? '#B3DFF7' :
                            (dayClass === 'public-holiday') ? '#008A2733' :
                            isDisabled ? 'lightgrey' : '';
    const color = dayClass === 'public-holiday' ? '#008A27' :
                  isDisabled ? 'black' : 
                  isSelected ? 'white' : '';


                  return (
                    <Tooltip key={day.format('YYYY-MM-DD')} title={isPublicHoliday ? 'Public Holiday' : isDisabled ? 'Full Day Leave Applied' : ''} arrow>
                        <div
                            className={`calendar-dayInapply1 ${dayClass} ${isDisabled ? 'disabled' : ''}`}
                            onClick={() => !isDisabled  && handleClick(day)}
                            style={{
                              backgroundColor,
                              color,
                              cursor: isKPOBPO 
                                ? (isDisabled ? 'not-allowed' : 'pointer') 
                                : (isDisabled || isPublicHoliday || isWeekend ? 'not-allowed' : 'pointer')
                            }}
                        >
                            {day.date()}
                        </div>
                    </Tooltip>
                );
  };

  const generateCalendar = (month) => {
    const startOfMonth = moment(month).startOf('month');
    const endOfMonth = moment(month).endOf('month');
    const days = [];
    let date = startOfMonth.clone();
    while (date <= endOfMonth) {
      days.push(date.clone());
      date.add(1, 'days');
    }

    const weeks = [];
    let week = [];
    const startDay = startOfMonth.day() === 0 ? 6 : startOfMonth.day() - 1;
    for (let i = 0; i < startDay; i++) {
      week.push(null);
    }
    days.forEach(day => {
      if (week.length === 7) {
        weeks.push(week);
        week = [];
      }
      week.push(day);
    });

    while (week.length < 7) {
      week.push(null);
    }
    weeks.push(week);
    return weeks;
  };

  const calendarWeeksFrom = generateCalendar(currentMonthFrom);
  const calendarWeeksTo = generateCalendar(currentMonthTo);
  const [value, setValue] = useState(0);
  const selectedMonth = generateData(moment().year())[value].value;
  const { getAllPublicHolidaysAPI, getAllPublicHolidaysYearAPI, getLeavesNumberWithType } = leaveManagementAPI();
  
    useEffect(() => {
    handleGetAllHolidaysApi();
    handleGetLeavesNumberApi()
    }, []);

  const date = new Date();
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const formattedDate = `${year}-${month}-${day}`;

  const handleGetLeavesNumberApi = async (month) => {
    try {
      dispatch({ type: "SET_LOADING", payload: true });
      let values = { year: formattedDate };
      let res = await getLeavesNumberWithType(values);
     
      
      
      setAvailableDaysList(res?.data?.leaveData || []);
      setPendingLeavesDaysList(res?.data?.pendingLeaves|| [])
      setAllLeaveDates(res?.data?.leaveDates || [])
      dispatch({ type: "SET_LOADING", payload: false });
    } catch (err) {
      dispatch({ type: "SET_LOADING", payload: false });
    }
  };


   const handleGetAllHolidaysApi = async (month) => {
    try {
      dispatch({ type: "SET_LOADING", payload: true });
      let values = { year: formattedDate }
      let res = await getAllPublicHolidaysYearAPI(values);
      setHolidayList(res?.data || []);
      
      dispatch({ type: "SET_LOADING", payload: false });
    } catch (err) {
      dispatch({ type: "SET_LOADING", payload: false });
    }
  };
  const handleDayTypeChange = (date, event) => {
    const value = event.target.value;
    setSelectedDayTypes((prevState) => ({
      ...prevState,
      [date]: value,
    }));
  };


  const hanldeNavigation=()=>{
    Navigate("/panel/leaveManagement")
  }

  const handleCancelLeaveRequest=()=>{
    setSelectedLeaveType('');
    setFromDate(null);
    setToDate(null);
    setDescription('')
  }


  const isDateFullyApplied = (date) => {
    const formattedDate = date.format('YYYY-MM-DD');
    const leaveInfo = allLeaveDates.find(leave =>
      moment(leave.date).format('YYYY-MM-DD') === formattedDate &&
      leave.day_type === 'Full'
    );
    return leaveInfo !== undefined;
  };

  return (
    <div style={{ backgroundColor: "white", borderRadius: "10px" }}>
      <div style={{ display: "flex", gap: "0.2%", alignItems: "center", paddingTop: "2%" }}>
        <div style={{ alignItems: "center", paddingTop: 2 }} onClick={hanldeNavigation} ><img src={leftarrow} /></div>
        <div className='leaveapplyfirstdiv'>  Raise Leave Request</div>
      </div>
    <Grid container sx={{ display:"flex"}}>
    <Grid item xs={12} sm={6} md={6} lg={6} sx={{ mt: 2, width: "100%" }}>
    <Grid container sx={{ display:"flex",pl:2}}>
    <Grid item xs={12} sm={11} md={11} lg={11} sx={{ mt: 2, width: "100%" }}>
    <Box className="label">Leave Type <span style={{ color: "red" }}>*</span></Box>
    <FormControl
      sx={{ width: "100%" }}
    >
      <Select id="workLocationType"
        name="workLocationType"
        sx={{
          padding: "0px 0px",
          border: "1px solid #B2D4F8",
          height: "45px",
          borderRadius:"10px"
        }}
        value={selectedLeaveType}
        displayEmpty
        defaultValue=""
        onChange={handleLeaveTypeChange}
      >
        <MenuItem value="">Select Leave Type</MenuItem>
      
        {leavesTypelist && leavesTypelist.length > 0 &&
  leavesTypelist
    .filter((leaveType) => {
      // Find the pending requests and available leave information
      const pendingRequests = pendingLeavesDaysList.find(
        (leave) => leave.leave_type_id === leaveType.leaveTypeId
      );
      const leave = availableDaysList.find(
        (l) => l.leaveTypeId === leaveType.leaveTypeId
      );


      // Check if leave data exists
      if (leave && leave?.leaveTypeName === 'Privilege Leave' || leave?.leaveTypeName === 'Sick Leave' ) {
        const isSpecialLeaveType = 
          leave.leaveTypeName === 'Privilege Leave' || 
          leave.leaveTypeName === 'Sick Leave';
        const balanceAvail = 
          isSpecialLeaveType 
            ? leave.availableDays > leave.utilizedDays 
            : true;
        if (balanceAvail && pendingRequests) {
          return leave.utilizedDays + pendingRequests.number_of_days < leave.availableDays;
        }
    
        return balanceAvail;
      }

      return true;
    })
    .map((filteredLeaveType, index) => (
      <MenuItem key={index} value={filteredLeaveType.leaveTypeId}>
        {filteredLeaveType.leaveTypeName}
      </MenuItem>
    ))
}
      </Select>
      {leaveTypeError && (
        <Box style={{ color: "red" }}>
          {leaveTypeError}
        </Box>
      )}
    </FormControl>
  </Grid>
  </Grid>
      <Grid container sx={{ pt: 5 }} className="leavereqSelectedTotalMain">
        <Grid item xs={12} sm={12} md={12} lg={12} sx={{pl: 3}} className="labelforselectDates">
          Select Dates
        </Grid>
      </Grid>
      <hr style={{ border:" 1px solid #E9E9E9" ,marginTop:"2%", marginBottom:"1.5%", width:"90%"}}/>
      <Grid container className='LeaveApplyCalenderContainer'>
        <Grid item sm={11} lg={11} md={11} xl={11} xs={12} className="calendarInapply">
          <div className="calendar-headerInapply1">
            <div  className="leftarrowinapply" onClick={() => handleMonthChangeFrom(-1)}><ArrowBackIosIcon/></div>
            <div className='headinginfromInapply1'> <span className='fromInapply1'>From</span> {currentMonthFrom.format('MMMM YYYY')}</div>
            <div className="leftarrowinapply" onClick={() => handleMonthChangeFrom(1)}><ArrowForwardIosIcon/></div>
          </div>
          <hr style={{ border:" 1px solid #E9E9E9" ,marginTop:"3%", marginBottom:"2%", width:"95%"}}/>
          <div className="calendar-headerInapply1">
            {['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'].map(day => (
              <div key={day} className="calendar-header-dayInapply1">{day}</div>
            ))}
          </div>
          <div className="calendar-bodyInapply1">
            {calendarWeeksFrom.map((week, index) => (
              <div key={index} className="calendar-weekInapply1">
                {week.map(day => renderDay(day, handleDateClick, true))}
              </div>
            ))}
          </div>
        </Grid>
      </Grid>
      <Grid container className="colors-label-detailing">
        <Grid item xs={12} sm={4} md={4} lg={4} sx={{}} className="Selected-Leavegrid"><ul><li>Selected Leave</li></ul></Grid>
        <Grid item xs={12} sm={4} md={4} lg={4} sx={{}} className="privilizedleavegrid"><ul><li>Public Holiday</li></ul></Grid>
        {/* <Grid item xs={12} sm={4} md={4} lg={4} sx={{}} className="Sick-Leave-grid"><ul><li>Sick Leave</li></ul></Grid> */}
        <Grid item xs={12} sm={4} md={4} lg={4} sx={{}} className="Holidaygrid"><ul><li>Weekend</li></ul> </Grid>
      </Grid>
        <Grid item xs={12} sm={4} md={4} lg={4} sx={{}} className="Optinalleavegrid"><ul><li>Applied Leaves</li></ul> </Grid>
      <Grid container sx={{ display:"flex",pl:2}}>
            <Grid item xs={12} sm={11.5} md={11.5} lg={11.5} sx={{mt:2,width:"90%"}}>
            <Box className="label">
              Attachments (JPEG, PNG, PDF)
            </Box>
            <TextField  fullWidth variant="outlined"  placeholder="Browse Files (max of 5mb)" type="text"  value={passbook ? passbook.name : ""}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {passbook && (
                      <IconButton
                        onClick={handleClearpassbookFile}
                        edge="end"
                        aria-label="clear file"
                      >
                        <ClearIcon />
                      </IconButton>
                    )}
                    <input
                      type="file"
                      accept=".pdf,.doc,.docx"
                      id="file-upload0"
                      style={{ display: "none",height:"75px" }}
                      onClick={handleFileInputReselect}
                      onChange={handleFileChangeSSC}
                    />
                    <label htmlFor="file-upload0">
                      <IconButton
                        component="span"
                        color="primary"
                        aria-label="attach file"
                      >
                        <AttachFileIcon style={{ color: "#0056B2" }}/>
                      </IconButton>
                    </label>
                  </InputAdornment>
                ),
              }}
            />
        </Grid>
        <Grid item xs={12} sm={11.5} md={11.5} lg={11.5} sx={{ mt: 2, width: "90%" }}>
    <Box className="label">Description <span style={{ color: "red" }}>*</span></Box>
    <TextField
      placeholder="Enter Description Here"
      value={description}
      onChange={(e) => setDescription(e.target.value)}
      multiline
      rows={4}
      fullWidth
    />
    {descriptionError && (
      <Box style={{ color: "red" }}> 
        {descriptionError}
      </Box>
    )}
  </Grid>   
  </Grid>
        </Grid>
        <Grid item xs={12} md={5} lg={5} sm={12}
          // open={isModalOpen}
          // onClose={() => setIsModalOpen(false)}
          // aria-labelledby="modal-title"
          aria-describedby=""
        >
         
         
         <Box sx={{ mt: 4 }}>
      <div id="">
        {fromDate ? (
          <div style={{ marginTop: "1%" }}>
            <div className='label' style={{ marginBottom: "2%", marginTop: "1%" }}>Your Request Includes</div>
            <div className='modalselectedDates'>
              {filteredSelectedDates.map(date => {
                const formattedDate = date.format('YYYY-MM-DD');
                const dayTypeInfo = halfDayLeaveDates.find(d => d.date === formattedDate);

                // Determine the available options based on the day type
                const dayTypeOptions = dayTypeInfo ? (() => {
                  switch (dayTypeInfo.day_type) {
                    case 'First Half':
                      return ['0.5_second_half'];
                    case 'Second Half':
                      return ['0.5_first_half'];
                    default:
                      return null;
                  }
                })() : ['1', '0.5_first_half', '0.5_second_half'];

                if (isDateFullyApplied(date)) {
                  return null; 
                }

                return (
                  <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }} key={formattedDate}>
                    <div style={{ fontSize: "15px", fontWeight: "600", fontFamily: "Inter" }}>
                      {date.format('ddd, MMMM DD YYYY')}
                    </div>
                    <Select
                      value={selectedDayTypes[formattedDate] || (dayTypeInfo ? dayTypeOptions : "1")}
                      onChange={(e) => handleDayTypeChange(formattedDate, e)}
                      sx={{
                        '& .MuiOutlinedInput-notchedOutline': {
                          border: 'none',
                        },
                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                          border: 'none',
                        },
                        '&:hover .MuiOutlinedInput-notchedOutline': {
                          border: 'none',
                        },
                        color: "#0070E9",
                        fontSize: "16px",
                        fontWeight: "700"
                      }}
                    >
                      
                      {dayTypeOptions.includes('1') && <MenuItem value="1">Full Day</MenuItem>}
                      {dayTypeOptions.includes('0.5_first_half') && <MenuItem value="0.5_first_half">Morning Session</MenuItem>}
                      {dayTypeOptions.includes('0.5_second_half') && <MenuItem value="0.5_second_half">Evening Session</MenuItem>}
                    </Select>
                  </div>
                );
              })}
            </div>
            <p className='tsbods'><span className='label'>Total Days Requested:</span> {calculateModalTotalDays()}</p>
          </div>
        ) : (
          <div className='modalselectedDates'>
            <img style={{ maxWidth: "200px", maxHeight: "200px", objectFit: "cover", borderRadius: "20px" }} src="https://www.soulmatedestination.com/assets/front_end_new/images/no-data.png" alt="" />
            <div style={{ color: "lightgrey" }}> No Dates Selected</div>
          </div>
        )}
      </div>
    </Box>

        </Grid>
      </Grid>
      <Grid container sx={{ width: "30%", justifyContent: "center", margin: "auto", textAlign: "center", mt: 4, }}>
        <Grid item xs={12} sm={6} lg={6} md={6} sx={{ mb: 4 }}>
          <Button className="leaveApplyModalCancel" onClick={handleCancelLeaveRequest}>
            Cancel
          </Button>
        </Grid>
        <Grid item xs={12} sm={6} lg={6} md={6} sx={{ mb: 4 }}>
          <Button className="leaveApplyModalConfirm" onClick={handleRaiseLeaveRequest}>
            Request Now
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

export default LeaveApply;