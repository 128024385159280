import React from 'react';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import moment from 'moment'
import Button from '@mui/material/Button';
import { parseISO, format } from 'date-fns';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useState, useEffect,useContext } from "react";
import Context from "context/context";
import MenuItem from '@mui/material/MenuItem';
import { useNavigate } from "react-router-dom";
import Select from '@mui/material/Select';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import { toast } from "react-toastify";
import FormControl from '@mui/material/FormControl';
import vraRoster from "apis/panel/vraRoster/vraRoster";
import getAllVraShiftAPIS from "apis/panel/vraShifts/vraShifts.js";
import Autocomplete from '@mui/material/Autocomplete';
// import RadRosterApis from 'apis/panel/rpRoster/rpRadRoster'




const AddVraRoster = () => {
    const navigate = useNavigate();
    const { state, dispatch } = useContext(Context);
    const [employeeId, setEmployeeId] = useState('');
    const [firstName, setFirstName]= useState('');
    const [lastName, setLastName]= useState('');
    const [fullName , setFullName]= useState('')
    const [dateofJoining, setDateOfJoining]= useState(null)
    const [vrasrfname, setvrasrfname] = useState('');
    const [vrarpuserid, setvrarpuserid] = useState('');
    const [primaryShift, setPrimaryShift]= useState('');
    const [deployedDate, setDeployedDate] = useState(null);
    const [deploymentDate, setDeploymentDate] = useState(null);
    const [role, setrole] = useState('');
    const [engagementType, setengagementType] = useState('')
    const [radrosterId, setRadrosterId] = useState('');
    const [status, setStatus] = useState('');
    const [vraNeededCount, setVraNeededCount] = useState(0);
    const [commitmentDate, setCommitmentDate] = useState(null)
    const [editRadRosterObj,setEditRadRosterObj] = useState([]);
    const [selectedEmployeeId, setSelectedEmployeeId] = useState(""); 
    const [vraEmployees , setVraEmployees]= useState([])
    const {getAllVraEmployeesAPI}= getAllVraShiftAPIS()


    



    const handleGetAllVraEmployees = async () => {
        try {
            let res = await getAllVraEmployeesAPI();
            setVraEmployees(res?.data?.vraEmployeesMaster || []);
            console.log(vraEmployees,"vra")
            }
        catch (err) {}
      };
    
      useEffect(()=>{
        handleGetAllVraEmployees()
      },[])
      

    const maxDate = new Date()

    const [errorFields, setErrorFields] = useState({

        vrasrfname: false,
        vrarpuserid: false,
        deployedDate: false,
        plannedDeploymentDate: false,
        role: false,
        engagementType: false,
        radrosterId: false,
        status: false,
        vraNeededCount: false,

    });


    // api's

    const handleCancel =()=>{
        handleReset()
        navigate("/panel/vraRoster")
    }
    const {getVraRosterFindOne, updateVraRosterAPI,createVraRosterAPI } = vraRoster()
    const handleSaveRadRosterEdit = async (e) => {
        e.preventDefault();
    
        const values = {
        
            employeeId: employeeId,
            primaryShift: primaryShift,
            status: status,
            srfName: vrasrfname,
            vraFullName: vrarpuserid,
            deployedDate: deployedDate,
            commitmentDate: commitmentDate,
            role: role,
            engagementType: engagementType,
        };
    
        console.log(values, 'values');
        dispatch({ type: "SET_LOADING", payload: true });
        const res = await createVraRosterAPI(values);
        if (res.status === 'Ok') {
            
            dispatch({ type: "SET_LOADING", payload: false });
            return toast.success("Data Saved Successfully", { toast: "Data Saved Successfully" });
        } else {
            dispatch({ type: "SET_LOADING", payload: false });
            return toast.error("Error: User already exists in the system");
        }
    };
    


    const handleReset = () => {
        setvrasrfname('')
        setvrarpuserid('')
        setDeployedDate('')
        // setPlannedDeploymentDate('')
        setrole('')
        setengagementType('')
        setStatus('')
        setVraNeededCount('')
        setRadrosterId('')
    }

    const handlevrasrfnameChange = (event) => {
        const input = event.target.value;
        setvrasrfname(input);
    };
    const handlevrarpuserIdChange = (event) => {
        const input = event.target.value;
        setvrarpuserid(input);
    };


    const handleShiftChange=(e)=>{
        setPrimaryShift(e.target.value)
    }
    // const handleprimary = (event) => {
    //     const input = event.target.value;
    //     setPrimaryShift(input);
    // };
    console.log(primaryShift,"primary")
    const handleengagementTypeChange = (event) => {
        const input = event.target.value;
        setengagementType(input);
    };

    const handleroleChange = (event) => {

        const input = event.target.value;

        setrole(input);


    };


    // const handleVraNeededCountChange = (event) => {
    //     const input = event.target.value;

        

    // };

    const handleCommitmentDateChange = (date) => {
        setCommitmentDate(date);
    };



    const handlePlannedDeploymentDateChange = (date) => {
        setDeploymentDate(date);
    };
    const handleStatusChange = (event) => {

        setStatus(event.target.value)
    }

    return (
        <div style={{ backgroundColor: "white" }} >

            <Grid container spacing={2} sx={{ pl: 4, pr: 5 }} >

            <Grid item xs={12} sm={6} md={6} lg={6} sx={{ mt: 2 }}>
            <Box className="label">Employee Id <span style={{ color: "red" }}>*</span></Box>
            <Autocomplete
            id="primary-radiologist-autocomplete"
            options={vraEmployees}
            getOptionLabel={(option) => option.employeeCode || ""}
            renderInput={(params) => <TextField {...params} fullWidth />}
            isOptionEqualToValue={(option, value) => option.employeeId === value.employeeId}
            onChange={(event, newValue) => {
              setEmployeeId(newValue ? newValue.employeeId : ""); // Store the employeeId when an option is selected
            }}
          />
                </Grid>

                {/* <Grid item xs={12} sm={6} md={6} lg={6} sx={{ mt: 2 }}>
                    <Box className="label">VRA SRF Name <span style={{ color: "red" }}>*</span></Box>
                    <TextField sx={{ border: '1px solid #B2D4F8' }} id="vrasrfname" value={vrasrfname}  onChange={handlevrasrfnameChange} placeholder="Enter VRA SRF Name" variant="outlined" fullWidth />

                </Grid> */}

                {/* <Grid item xs={12} sm={6} md={6} lg={6} sx={{ mt: 2 }}>
                    <Box className="label">VRA SRF Name <span style={{ color: "red" }}>*</span></Box>
                    <TextField sx={{ border: '1px solid #B2D4F8' }} id="vrasrfname" value={vrasrfname}  onChange={handlevrasrfnameChange} placeholder="Enter VRA SRF Name" variant="outlined" fullWidth />

                </Grid> */}

                {/* <Grid item xs={12} sm={6} md={6} lg={6} sx={{ mt: 2 }}>
                    <Box className="label">VRA SRF Name <span style={{ color: "red" }}>*</span></Box>
                    <TextField sx={{ border: '1px solid #B2D4F8' }} id="vrasrfname" value={vrasrfname}  onChange={handlevrasrfnameChange} placeholder="Enter VRA SRF Name" variant="outlined" fullWidth />

                </Grid> */}
{/* 
                <Grid item xs={12} sm={6} md={6} lg={6} sx={{ mt: 2 }}>
                    <Box className="label">VRA SRF Name <span style={{ color: "red" }}>*</span></Box>
                    <TextField sx={{ border: '1px solid #B2D4F8' }} id="vrasrfname" value={vrasrfname}  onChange={handlevrasrfnameChange} placeholder="Enter VRA SRF Name" variant="outlined" fullWidth />

                </Grid> */}
                <Grid item xs={12} sm={6} md={6} lg={6} sx={{ mt: 2 }}>
                    <Box className="label">VRA SRF Name <span style={{ color: "red" }}>*</span></Box>
                    <TextField sx={{ border: '1px solid #B2D4F8' }} id="vrasrfname" value={vrasrfname}  onChange={handlevrasrfnameChange} placeholder="Enter VRA SRF Name" variant="outlined" fullWidth />

                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} sx={{ mt: 2 }}>
                    <Box className="label">VRA RP User Id <span style={{ color: "red" }}>*</span></Box>
                    <TextField sx={{ border: '1px solid #B2D4F8' }} id="vrasrfname" value={vrarpuserid} onChange={handlevrarpuserIdChange} placeholder="Enter VRA RP USER Id" variant="outlined" fullWidth />
                    {/* {errorFields.vrasrfname && <span className="error-msg" style={{ alignItems: "center", display: "flex", gap: "4px" }}><div><ErrorOutlineOutlinedIcon style={{ alignItems: "center", alignSelf: "center" }} /> </div> <div>First letter must be capital letter</div></span>} */}
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} sx={{ mt: 2 }}>
                    <Box className="label">Primary Shift<span style={{ color: "red" }}>*</span></Box>
                    <FormControl fullWidth>
                    
                        <Select
                            labelId="status"
                            id="status"
                            defaultValue=""
                            displayEmpty
                            name="status"
                            value={primaryShift}
                            onChange={handleShiftChange}
                            inputProps={{ 'aria-label': 'Without label' }}
                            style={{ border: "2px solid #B2D4F8", color: "grey" }}
                        >
                            <MenuItem value="">
                                Select Shift
                            </MenuItem>

                            <MenuItem value={'Morning'}>Morning</MenuItem>
                            <MenuItem value={'Evening'}>Evening</MenuItem>
                            <MenuItem value={'Night'}>Night</MenuItem>
                            {/* <MenuItem value={'Deployed'}>Deployed</MenuItem> */}



                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} sx={{ mt: 2 }}>
                    <Box className="label"> Role<span style={{ color: "red" }}>*</span></Box>
                    <TextField sx={{ border: '1px solid #B2D4F8' }} id="role" value={role} name="role" onChange={handleroleChange} placeholder="Enter role" variant="outlined" fullWidth />
                    {/* {errorFields.role && <span className="error-msg" style={{ alignItems: "center", display: "flex", gap: "4px" }}><div><ErrorOutlineOutlinedIcon style={{ alignItems: "center", alignSelf: "center" }} /> </div> <div>First letter must be capital letter</div></span>} */}
                </Grid>

                <Grid item xs={12} sm={6} md={6} lg={6} sx={{ mt: 2 }}>
                    <Box className="label"> Engagement Type <span style={{ color: "red" }}>*</span></Box>
                    <TextField sx={{ border: '1px solid #B2D4F8' }} id="engagementType" value={engagementType} name="engagementType" onChange={handleengagementTypeChange} placeholder=" engagementType" variant="outlined" fullWidth />
                    {/* {errorFields.engagementType && <span className="error-msg" style={{ alignItems: "center", display: "flex", gap: "4px" }}><div><ErrorOutlineOutlinedIcon style={{ alignItems: "center", alignSelf: "center" }} /> </div> <div>First letter must be capital letter</div></span>} */}
                </Grid>

    



                <Grid item xs={12} sm={6} md={6} lg={6} sx={{ mt: 2 }}>
                    <Box className="label">Commitment Date</Box>
                    <LocalizationProvider fullWidth dateAdapter={AdapterDateFns}>
                        <DatePicker
                            id="deployedDate"
                            name="deployedDate"
                            value={commitmentDate ? new Date(commitmentDate) : null}
                            sx={{ width: "100%", border: "2px solid #B2D4F8" }}
                            onAccept={handleCommitmentDateChange}
                            fullWidth
                            inputFormat="dd/MM/yyyy"
                            slotProps={{
                                field: { clearable: true },
                            }}
                            maxDate={maxDate}
                            renderInput={(params) => <TextField {...params} fullWidth />}
                        />
                    </LocalizationProvider>
                    {/* <TextField sx={{ border: '1px solid #B2D4F8' }}id="deployedDate" value={deployedDate}  name="deployedDate"  onChange={handleDeployedDateChange} placeholder="deployedDate" variant="outlined" fullWidth /> */}
                    {/* {errorFields.deployedDate && <span className="error-msg">This is required Field</span>} */}
                </Grid>

                <Grid item xs={12} sm={6} md={6} lg={6} sx={{ mt: 2 }}>
                    <Box className="label">Deployment Date <span style={{ color: "red" }}>*</span></Box>
                    <LocalizationProvider fullWidth dateAdapter={AdapterDateFns}>
                        <DatePicker
                            id="plannedDeploymentDate"
                            name="plannedDeploymentDate"
                            value={deploymentDate ? new Date(deploymentDate) : null}
                            sx={{ width: "100%", border: "2px solid #B2D4F8" }}
                            onAccept={handlePlannedDeploymentDateChange}
                            fullWidth
                            inputFormat="dd/MM/yyyy"
                            slotProps={{
                                field: { clearable: true },
                            }}
                           
                            renderInput={(params) => <TextField {...params} fullWidth />}
                        />
                    </LocalizationProvider>
                    {/* <TextField sx={{ border: '1px solid #B2D4F8' }} id="plannedDeploymentDate" value={plannedDeploymentDate}  name="plannedDeploymentDate"  onChange={handlePlannedDeploymentDateChange} placeholder="plannedDeploymentDate" variant="outlined" fullWidth /> */}
                    {errorFields.plannedDeploymentDate && <span className="error-msg">This is required Field</span>}
                </Grid>

                <Grid item xs={12} sm={6} md={6} lg={6} sx={{ mt: 2 }}>
                    <Box className="label">Status <span style={{ color: "red" }}>*</span></Box>
                    <FormControl fullWidth>
                    
                        <Select
                            labelId="status"
                            id="status"
                            defaultValue=""
                            displayEmpty
                            name="status"
                            value={status}
                            onChange={handleStatusChange}
                            inputProps={{ 'aria-label': 'Without label' }}
                            style={{ border: "2px solid #B2D4F8", color: "grey" }}
                        >
                            <MenuItem value="">
                                Select Status
                            </MenuItem>

                            <MenuItem value={'Hired'}>Hired</MenuItem>
                            <MenuItem value={'Trained'}>Trained</MenuItem>
                            <MenuItem value={'Certified'}>Certified</MenuItem>
                            <MenuItem value={'Deployed'}>Deployed</MenuItem>
                            <MenuItem value={'Resigned'}>Resigned</MenuItem>
                            <MenuItem value={'Terminated'}>Terminated</MenuItem>
                            <MenuItem value={'Long Term Leave'}>Long Term Leave</MenuItem>


                        </Select>
                    </FormControl>
                    {/* <TextField sx={{ border: '1px solid #B2D4F8' }}id="role" value={Role}  name="role"  onChange={handleRoleChange} placeholder="Role" variant="outlined" fullWidth /> */}
                    {errorFields.status && <span className="error-msg">This is required Field</span>}
                </Grid>


            </Grid>


            <Grid container sx={{ pl: 4, pr: 5, mt: 5,pb:4, justifyContent: "center" }} >
                <Grid item sx={{ ml: 1 }} xs={12} sm={3} md={3} lg={3}>
                    <Button className="resetChanges" onClick={handleReset}>Reset Changes</Button>
                </Grid>

                <Grid item xs={12} sm={2} md={2} lg={2}>
                    <Button className="cancelButton" onClick={handleCancel} >Cancel</Button>
                </Grid>

                <Grid item sx={{ mr: 1 }} xs={12} sm={2} md={2} lg={2}>
                    <Button className="saveButton" onClick={handleSaveRadRosterEdit} >Save</Button>
                </Grid>

            </Grid>





        </div>
    )
}


export default AddVraRoster;
