// custom pages
import config from "config/config";
import HandleApiResponse from "utils/handleApiResponse";

const managerinfoApi = () => {
  const { handleResponse, handleError } = HandleApiResponse();

  async function getEmployeeinfo(id) {
    try {
      const obj = {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      };
      let res = await fetch(
        `${config.host}/panel/employees/manager/${id.relID}`,
        obj
      );
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }

  async function getemployeeall() {
    try {
      const obj = {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      };
      let res = await fetch(`${config.host}/panel/employees/allManagers`, obj);
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }

  async function getEmployementupdate(data) {
    try {
      const obj = {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      };

      let res = await fetch(
        `${config.host}/panel/employees/updateManager`,
        obj
      );
      return handleResponse(res);
    } catch (err) {
      handleError(err);
    }
  }

  return {
    getEmployeeinfo,
    getemployeeall,
    getEmployementupdate,
  };
};

export default managerinfoApi;
