import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import Context from "context/context";
import Tables from "components/table/table";
import ProjectAPI from "apis/panel/project/project";
import * as moment from "moment";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";

const CustomWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 500,
    cursor: "pointer",
    backgroundColor: "#002c5d",
    color: "#ffffff",
  },
});

const ProjectSetup = () => {
  const { state, dispatch } = useContext(Context);
  const [roleCode] = useState(state.role.roleCode);
  const navigate = useNavigate();  
  const { getProjectsAPI } = ProjectAPI();
  const [projectDetails, setprojectDetails] = useState([]);
  const [offset, setOffset] = useState(1);
  const [count, setCount] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const [filterValue, setFilterValue] = useState("");
  const [serialNumber, setSerialNumber] = useState("");

  useEffect(() => {
    handleGetAllrole();    
  }, [offset, searchValue, filterValue]);

  const handleGetAllrole = async () => {
    try {
      dispatch({ type: "SET_LOADING", payload: true });
      let values;
      values = {
        offset: (offset - 1) * 10,
        limit: 10,
      };
      if (searchValue) {
        values = {
          ...values,
          searchValue,
        };
      }
      if (filterValue) {
        values = {
          ...values,
          filterValue,
        };
      }
      
      let res = await getProjectsAPI(values);
      setprojectDetails(res?.data?.data || []);
      setCount(res?.data?.count);
      setSerialNumber(offset)
      dispatch({ type: "SET_LOADING", payload: false });
    } 
    catch (err) {
      dispatch({ type: "SET_LOADING", payload: false });
    }
  };

  return (
    <div style={{ backgroundColor: "white", borderRadius: "10px" }}>
      <Tables
        title={
          <Box className="EmployeeRoster" sx={{ pt:2, pb:2, pl:2}}>Project Information</Box>
        }
        searchBar={true}
        filter={true}
        role={roleCode}
        create={
        roleCode === "R001" &&  <Button 
            className="AddEmployerButton" 
            onClick={(e) =>
              navigate("../AddProject")
            }
          >
            New Project
          </Button>}
        setSearchValue={setSearchValue}
        filterValue={filterValue}
        setFilterValue={setFilterValue}
        filterValues={[
          { label: "Project Id", value: "" },
          { label: "Project Name", value: "projectName" },
          { label: "Account Name", value: "accountName" }
        ]}
        heading={[
          "S.No",
          "Project Id",
          "Project Name",
          "Account Name",
          "Project Type",
          "Start Date",
          "End Date",
          "Status",
          "Action"
        ]}
        data={projectDetails?.map((project, index) => [
          index + 1 + (serialNumber - 1) * 10,
          project?.projectCode,
          project?.projectName,
          project?.accountName,
          project?.projectTypeName,
          project?.startDate?moment(project.startDate).format("DD/MM/YYYY"):"",
          project?.endDate?moment(project.endDate).format("DD/MM/YYYY"):"",
          <span style={{ color: project.status === 'Active' ? 'green' : project.status === 'Onhold' ? 'black' : 'red', fontWeight: 700, fontSize: '14px' }}>
            {project.status === 'Active' ? ' Active' : project.status === 'Onhold' ? ' Onhold' : ' Inactive'}
          </span>,       
          <CustomWidthTooltip
          title='Click to view details'
        >   
          <span className="jobType">            
            <RemoveRedEyeIcon 
              style={{ color: "#FE602F", cursor: "pointer" }} 
              onClick={(e) =>
                navigate("../EditProjectTask/"+project.projectId)
              } 
            />
          </span> 
          </CustomWidthTooltip>
        ])}
        offset={offset}
        setOffset={setOffset}
        count={count}
        placeholder="Search by Project ID & Project Name"
      />
    </div>
  )
}

export default ProjectSetup;