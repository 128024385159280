import config from "config/config";
import HandleApiResponse from "utils/handleApiResponse";

const vraShiftsAPI = () => {
    const { handleResponse, handleError } = HandleApiResponse();

    async function getAllVraRosterDetailsAPI(values) {
        try {
          const obj = {
            method: "GET",
            credentials: "include",
            headers: {
              "Content-Type": "application/json",
            },
          };
          const query = JSON.stringify(values);
          const res = await fetch(
            `${config.host}/panel/rp/vraRoster/employees?query=${query}`,
            obj
          );
          return handleResponse(res);
        } catch (err) {
          handleError(err);
        }
      }

      async function getOneVraRosterAPI(values) {
        try {
          const obj = {
            method: "GET",
            credentials: "include",
            headers: {
              "Content-Type": "application/json",
            },
          };
          const query = JSON.stringify(values);
          const res = await fetch(
            `${config.host}/panel/rp/vraRoster/findOne?query=${query}`,
            obj
          );
          return handleResponse(res);
        } catch (err) {
          handleError(err);
        }
      }



      // async function updateVraRosterAPI(value) {
      //   try {
      //     const obj = {
      //       method: "PUT",
      //       credentials: "include",
      //       headers: {
      //         "Content-Type": "application/json",
      //       },
      //       body: JSON.stringify(value),
      //     };
      //     let res = await fetch(`${config.host}/panel/rp/vraRoster/updateEmployees`, obj);
      //     return handleResponse(res);
      //   } catch (err) {
      //     handleError(err);
      //   }
      // }
      

      async function getVraRosterFindOne(values) {
        try {
          const obj = {
            method: "GET",
            credentials: "include",
            headers: {
              "Content-Type": "application/json",
            },
          };
          const query = JSON.stringify(values);
          let res = await fetch(`${config.host}/panel/rp/vraRoster/findOne?query=${query}`, obj);
          return handleResponse(res);
        } catch (err) {
          handleError(err);
        }
      } 


      async function updateVraRosterAPI(value) {
        try {
          const obj = {
            method: "PUT",
            credentials: "include",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(value),
          };
          let res = await fetch(`${config.host}/panel/rp/vraRoster/updateEmployees`, obj);
          return handleResponse(res);
        } catch (err) {
          handleError(err);
        }
      }

      async function createVraRosterAPI(value) {
        try {
          const obj = {
            method: "Post",
            credentials: "include",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(value),
          };
          let res = await fetch(`${config.host}/panel/rp/vraRoster/create`, obj);
          return handleResponse(res);
        } catch (err) {
          handleError(err);
        }
      }
      // async function createVraShiftAPI(value) {
      //   try {
      //     const obj = {
      //       method: "Post",
      //       credentials: "include",
      //       headers: {
      //         "Content-Type": "application/json",
      //       },
      //       body: JSON.stringify(value),
      //     };
      //   //   let res = await fetch(`${config.host}/panel/leave-management/leave-request`, obj);
      //   //   return handleResponse(res);
      //   } catch (err) {
      //     handleError(err);
      //   }
      // }

      // async function updateVraRosterAPI(value) {
      //   try {
      //     const obj = {
      //       method: "PUT",
      //       credentials: "include",
      //       headers: {
      //         "Content-Type": "application/json",
      //       },
      //       body: JSON.stringify(value),
      //     };
      //     let res = await fetch(`${config.host}/panel/rp/vraRoster/employee-shifts`, obj);
      //     return handleResponse(res);
      //   } catch (err) {
      //     handleError(err);
      //   }
      // }
      

      
      
    
  

    return{
           getAllVraRosterDetailsAPI,
           updateVraRosterAPI,
           getVraRosterFindOne,
           createVraRosterAPI
          }
}
export default vraShiftsAPI; 