import config from "config/config";
import HandleApiResponse from "utils/handleApiResponse";
import { toast } from "react-toastify";
import * as moment from "moment";

const vraRadAffinityAPI = () => {
    const { handleResponse, handleError } = HandleApiResponse();

    async function getAllVraRadAffinityAPI(values) {
        try {
          const obj = {
            method: "GET",
            credentials: "include",
            headers: {
              "Content-Type": "application/json",
            },
          };
          const query = JSON.stringify(values);
          const res = await fetch(
            `${config.host}/panel/rp/vraRadAffinity/findAll?query=${query}`,
            obj
          );
          return handleResponse(res);
        } catch (err) {
          handleError(err);
        }
      }

      async function getVraRadAffinityFindOneAPI(values) {
        try {
          const obj = {
            method: "GET",
            credentials: "include",
            headers: {
              "Content-Type": "application/json",
            },
          };
          const query = JSON.stringify(values);
          const res = await fetch(
            `${config.host}/panel/rp/vraRadAffinity/findOne?query=${query}`,
            obj
          );
          return handleResponse(res);
        } catch (err) {
          handleError(err);
        }
      } 

      async function updateVraRadAffinityAPI(value) {
        try {
          const obj = {
            method: "PUT",
            credentials: "include",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(value),
          };
          const res = await fetch(
            `${config.host}/panel/rp/vraRadAffinity/update`,
            obj
          );
          return handleResponse(res);
        } catch (err) {
          handleError(err);
        }
      }

      async function createVraRadAffinityAPI(value) {
        try {
          const obj = {
            method: "Post",
            credentials: "include",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(value),
          };
          let res = await fetch(`${config.host}/panel/rp/vraRadAffinity/create`, obj);
          return handleResponse(res);
        } catch (err) {
          handleError(err);
        }
      }

      async function getAllRadRostersAPI() {
        try {
          const obj = {
            method: "GET",
            credentials: "include",
            headers: {
              "Content-Type": "application/json",
            },
          };
          const query = JSON.stringify();
          const res = await fetch(
            `${config.host}/panel/rp/radRoster/allRadRosterIds`,
            obj
          );
          return handleResponse(res);
        } catch (err) {
          handleError(err);
        }
      }

      async function getAllVraRostersAPI(values) {
        try {
          const obj = {
            method: "GET",
            credentials: "include",
            headers: {
              "Content-Type": "application/json",
            },
          };
          const query = JSON.stringify(values);
          const res = await fetch(
            `${config.host}/panel/rp/vraRoster/allVraRosterIds`,
            obj
          );
          return handleResponse(res);
        } catch (err) {
          handleError(err);
        }
      }
    
    return{
        getAllVraRadAffinityAPI,
        getVraRadAffinityFindOneAPI,
        updateVraRadAffinityAPI,
        createVraRadAffinityAPI,
        getAllRadRostersAPI,
        getAllVraRostersAPI
       
    }
}
export default vraRadAffinityAPI;
