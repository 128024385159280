// npm packages
import React, { useState, useEffect, Fragment, useContext } from "react";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import * as moment from "moment";
import { toast } from "react-toastify";
import Divider from "@mui/material/Divider";

// custom pages
import config from "config/config";
import context from "context/context";
import { toastCss, theme } from "config/config";
import getemployeelist from "apis/panel/employee/employee"
import getcreateemployee from "apis/panel/employee/employee"
import getOneEmployee from "apis/panel/employee/employee"
import getUpdateEmployee from "apis/panel/employee/employee"

// material ui
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import VisibilityIcon from '@mui/icons-material/Visibility';
// import MenuItem from '@mui/material/MenuItem';
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Paper from "@mui/material/Paper";
import Alert from '@mui/material/Alert';
import CheckIcon from '@mui/icons-material/Check';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';
// import Button from '@mui/material/Button';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

// material ui icons
const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    borderRadius: "12px",
    boxShadow: 24,
    p: 4,
    width: 1000,
    height: "auto"
};

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});


const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} arrow placement="right" />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "#f5f5f9",
        color: "rgba(0, 0, 0, 0.87)",
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        border: "1px solid #dadde9",
    },
}));

export default function ViewLeave() {
    const { dispatch } = useContext(context);
    const { getAllEmployeesAPI } = getemployeelist();
    const { getEmployeeCreateAPI } = getcreateemployee();
    const { getOneEmployeeAPI } = getOneEmployee()
    const { getEmployeeUpdateAPI } = getUpdateEmployee()

    // Create Employee Task
    const [startDate, setStartDate] = useState(new Date());
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [viewModalOpen, setViewModalOpen] = useState(false);
    const [serialNumber, setSerialNumber] = useState(1);
    const [selectedEmployee, setSelectedEmployee] = useState(null);
    const [employeeList, setEmployeeList] = useState([]);
    const [createModalOpen, setCreateModalOpen] = useState(false);
    const [createObj, setCreateObj] = useState({
        firstName: "",
        lastName: "",
        middleName: "",
        dateOfBirth: "",
        joiningDate: "",
        gender: "",
        phoneNumber: "",
        status: "",
        image: "",
        employeeCode: "",
        emailId: "",
        managerName: "",
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (!editModalOpen && selectedEmployee) {
                    // Make the API request with the selectedEmployee data
                    const response = await getOneEmployeeAPI()
                    // Process the response data here, if needed
                    if (response.type === "SUCCESS") {
                        setCreateObj({
                            firstName: response.firstName,
                            lastName: response.lastName,
                            middleName: response.middleName,
                            dateOfBirth: response.dateOfBirth,
                            joiningDate: response.hireDate,
                            gender: response.gender,
                            phoneNumber: response.contactNumber,
                            status: response.isActive,
                            image: response.image,
                            employeeCode: response.employeeCode,
                            emailId: response.email,
                            managerName: response.managerId,
                        })
                    }
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [editModalOpen, selectedEmployee]);


    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();

        reader.onloadend = () => {
            // After reading the file, set the base64 string to the image field in the state
            setCreateObj({
                ...createObj,
                image: reader.result
            });
        };

        // Read the file as a data URL (base64 string)
        reader.readAsDataURL(file);
    }

    const handleGetAllLeave = async () => {
        try {
            let res = await getAllEmployeesAPI();
            setEmployeeList(res?.data?.employees || []);
        }
        catch (err) { }
    };

    useEffect(() => {
        handleGetAllLeave();
        // handleCreateLeaveTypeTask()
    }, [])

    const handleEditClick = (employee) => {
        setSelectedEmployee(employee);
        setEditModalOpen(true);
    };

    const handleViewClick = (employee) => {
        setSelectedEmployee(employee);
        setViewModalOpen(true);
    };

    const handleEditModalClose = () => {
        setEditModalOpen(false);
    };

    const handleViewModalClose = () => {
        setViewModalOpen(false);
    };

    const handleCreateModalOpen = () => {
        setCreateModalOpen(true);
    };

    const handleCreateModalClose = () => {
        setCreateModalOpen(false);
    };

    const columns = [
        { field: 'SNo', headerName: 'S.No', width: 70 },
        { field: 'EmployeeId', headerName: 'EmployeeId', width: 170 },
        { field: 'FirstName', headerName: 'FirstName', width: 200 },
        { field: 'MiddleName', headerName: 'MiddleName', width: 200 },
        { field: 'LastName', headerName: 'LastName', width: 200 },
        { field: 'Email ID', headerName: 'Email ID', width: 170 },
        { field: 'Phone Number', headerName: 'Phone Number', width: 170 },
        {
            field: 'operations', headerName: 'Operations', width: 170,
            renderCell: (params) => (
                <>
                    <EditIcon onClick={() => handleEditClick(params.row)} />
                    <VisibilityIcon onClick={() => handleViewClick(params.row)} />
                </>
            ),
        },
    ];

    const rows = employeeList && employeeList.map((employee, index) => ({
        id: serialNumber + index,
        SNo: serialNumber + index,
        EmployeeId: employee.employeeCode,
        employeeCode: employee.employeeCode || '',
        FirstName: employee.firstName || '',
        MiddleName: employee.middleName || '',
        LastName: employee.lastName || '',


    })) || [];

    const HtmlTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} arrow placement="right" />
    ));

    // const handleCreateLeave = async (e) => {
    //     try {
    //         if (!createObj.firstName || !createObj.lastName || !createObj.joiningDate || !createObj.email) {
    //             // setDisableSubmitBtn(false);
    //             return toast.error("Please fill all the required leave fields.", {
    //                 taskId: "create-leave-error",
    //             });
    //         }

    //         //   firstName: "",
    //         //   lastName: "",
    //         //   middleName: "",
    //         //   dateOfBirth: "",
    //         //   joiningDate: "",
    //         //   gender: "",
    //         //   phoneNumber: "",
    //         //   status: "",
    //         //   : "",
    //         //   employeeCode: "",
    //         //   emailId: "",
    //         //   managerName: "",


    //         // const values = {
    //         //   leaveRequestId: createObj.leaveRequestId,
    //         //   leaveTypeId: createObj.leaveName.leaveTypeId,
    //         //   startDate: moment(createObj.startDate).format("YYYY-MM-DD"),
    //         //   endDate: moment(createObj.endDate).format("YYYY-MM-DD"),
    //         //   leaveTotal: createObj.leaveTotal,
    //         //   reason: createObj.description,
    //         //  image approvedBy: ""
    //         // };
    //         const values = {
    //             firstName: createObj.firstName,
    //             lastName: createObj.leaveName.lastName,
    //             joiningDate: moment(createObj.startDate).format("YYYY-MM-DD"),
    //             dateOfBirth: moment(createObj.endDate).format("YYYY-MM-DD"),
    //             middleName: createObj.middleName,
    //             gender: createObj.gender,
    //             phoneNumber: createObj.phoneNumber,
    //             status: createObj.status,
    //             image: createObj.image,
    //             employeeCode: createObj.employeeCode,
    //             emailId: createObj.emailId,
    //             managerName: createObj.managerName
    //         };
    //         const data = await getEmployeeCreateAPI(values);

    //         if (data.type === "SUCCESS") {
    //             toast.success("Leave Created", toastCss);
    //             setCreateObj({
    //                 firstName: "",
    //                 lastName: "",
    //                 middleName: "",
    //                 dateOfBirth: "",
    //                 joiningDate: "",
    //                 gender: "",
    //                 phoneNumber: "",
    //                 status: "",
    //                 employeeCode: "",
    //                 emailId: "",
    //                 managerName: "",
    //             });

    //               handleCreateModalClose();
    //             //   setDisableSubmitBtn(false);
    //         }
    //     } catch (err) {
    //         console.error(err);
    //     }
    // };


    const handleCreateLeave = async (e) => {
        try {
            // if (!createObj.firstName || !createObj.lastName || !createObj.joiningDate || !createObj.emailId) {
            //     return toast.error("Please fill all the required leave fields.", {
            //         taskId: "create-leave-error",
            //     });
            // }
            for (const field in createObj) {
                if (!createObj[field]) {
                    return toast.error("Please fill all the required fields.", {
                        taskId: "create-leave-error",
                    });
                }
            }

            const values = {
                firstName: createObj.firstName,
                lastName: createObj.lastName,
                hireDate: moment(createObj.joiningDate).format("YYYY-MM-DD"),
                dateOfBirth: moment(createObj.dateOfBirth).format("YYYY-MM-DD"),
                middleName: createObj.middleName,
                gender: createObj.gender,
                contactNumber: createObj.phoneNumber,
                isActive: createObj.status,
                employeeCode: createObj.employeeCode,
                email: createObj.emailId,
                managerId: createObj.managerName,
                image: createObj.image
            };

            const data = await getEmployeeCreateAPI(values);

            if (data.status === "SUCCESS") {
                toast.success("Leave Created", toastCss);
                setCreateObj({
                    firstName: "",
                    lastName: "",
                    middleName: "",
                    dateOfBirth: "",
                    joiningDate: "",
                    gender: "",
                    phoneNumber: "",
                    status: "",
                    employeeCode: "",
                    emailId: "",
                    managerName: "",
                    image: ""
                });

                handleCreateModalClose();
            }
        } catch (err) {
            console.error(err);
        }
    };

    const handleEditLeave = async (e) => {
        try {
            // if (!createObj.firstName || !createObj.lastName || !createObj.joiningDate || !createObj.emailId) {
            //     return toast.error("Please fill all the required leave fields.", {
            //         taskId: "create-leave-error",
            //     });
            // }
            for (const field in createObj) {
                if (!createObj[field]) {
                    return toast.error("Please fill all the required fields.", {
                        taskId: "create-leave-error",
                    });
                }
            }

            const values = {
                firstName: createObj.firstName,
                lastName: createObj.lastName,
                hireDate: moment(createObj.joiningDate).format("YYYY-MM-DD"),
                dateOfBirth: moment(createObj.dateOfBirth).format("YYYY-MM-DD"),
                middleName: createObj.middleName,
                gender: createObj.gender,
                contactNumber: createObj.phoneNumber,
                isActive: createObj.status,
                employeeCode: createObj.employeeCode,
                email: createObj.emailId,
                managerId: createObj.managerName,
                image: createObj.image
            };

            const data = await getEmployeeUpdateAPI(values);

            if (data.status === "SUCCESS") {
                toast.success("Leave Created", toastCss);
                setCreateObj({
                    firstName: "",
                    lastName: "",
                    middleName: "",
                    dateOfBirth: "",
                    joiningDate: "",
                    gender: "",
                    phoneNumber: "",
                    status: "",
                    employeeCode: "",
                    emailId: "",
                    managerName: "",
                    image: ""
                });

                handleEditModalClose();
            }
        } catch (err) {
            console.error(err);
        }
    };
    return (
        <Fragment>
            <Card variant="outlined" sx={{ padding: "15px" }}>
                <Box sx={{ padding: "15px" }}>
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                        }}
                    >
                        <span style={{ fontSize: 20, fontWeight: "bold" }}>Employee List</span>
                        <Button
                            variant="contained"
                            onClick={() => {
                                handleCreateModalOpen();
                            }}
                            sx={{ marginLeft: "auto" }}
                        >
                            Create
                        </Button>
                    </Box>
                    <div style={{ padding: "10px" }}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DemoContainer components={["DesktopDatePicker"]}>
                                <DatePicker
                                    label="Year"
                                    views={['year']}
                                    value={startDate || new Date()}
                                    onChange={(date) => {
                                        const selectedYear = date.getFullYear();
                                    }}
                                />
                            </DemoContainer>
                        </LocalizationProvider>
                    </div>
                </Box>
            </Card>
            <Card variant="outlined" sx={{ padding: "15px", marginTop: "10px" }}>
                <Paper style={{ height: 500, width: '100%' }}>
                    <div style={{ height: 500, width: '100%' }}>
                        <DataGrid
                            rows={rows}
                            columns={columns}
                            pageSize={10}
                            checkboxSelection
                            slots={{ toolbar: GridToolbar }}
                        />
                    </div>
                </Paper>
            </Card>

            {/* Edit Modal */}
            <Modal
                open={editModalOpen}
                // onClose={handleEditModalClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                {/* Your Edit Modal Content */}
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Edit Employee
                    </Typography>
                    <Box sx={{ marginTop: "20px" }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} lg={6}>
                                <FormControl sx={{ m: 1, width: "100%", margin: "0px 0px 0px 0px" }}>
                                    <TextField
                                        labelId="balance-leave-label"
                                        id="first-name"
                                        //   value={balanceLeave}
                                        label="First Name *"
                                    // onChange={(e) => {
                                    //   setCreateObj({
                                    //     ...createObj,
                                    //     leaveTotal: e.target.value,
                                    //   });
                                    // }}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={12} lg={6}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={12} lg={6}>
                                        <FormControl sx={{ m: 1, width: "100%", margin: "0px 0px 0px 0px" }}>
                                            <TextField
                                                labelId="balance-leave-label"
                                                id="middle-name"
                                                //   value={balanceLeave}
                                                label="Middle Name *"
                                            // onChange={(e) => {
                                            //   setCreateObj({
                                            //     ...createObj,
                                            //     leaveTotal: e.target.value,
                                            //   });
                                            // }}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={12} lg={6}>
                                        <FormControl sx={{ m: 1, width: "100%", margin: "0px 0px 0px 0px" }}>
                                            <TextField
                                                labelId="balance-leave-label"
                                                id="last-name"
                                                //   value={balanceLeave}
                                                label="Last Name *"
                                            // onChange={(e) => {
                                            //   setCreateObj({
                                            //     ...createObj,
                                            //     leaveTotal: e.target.value,
                                            //   });
                                            // }}
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={12} lg={6}>
                                <FormControl sx={{ m: 1, width: "100%", margin: "0px 0px 0px 0px" }}>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DemoContainer components={["DesktopDatePicker"]}>
                                            <DatePicker
                                                label=" Joining Date *"
                                                sx={{ width: "200%" }}
                                                //   disablePast={true}
                                                format="dd-MM-yyyy"
                                            //   defaultValue={createObj?.startDate}
                                            //   onChange={(date) => {
                                            //     setCreateObj({ ...createObj, startDate: date });
                                            //   }}
                                            />
                                        </DemoContainer>
                                    </LocalizationProvider>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={12} lg={6}>
                                <FormControl sx={{ m: 1, width: "100%", marginLeft: "0%" }}>
                                    <TextField
                                        labelId="balance-leave-label"
                                        id="last-name"
                                        //   value={balanceLeave}
                                        label="EMail ID *"
                                    // onChange={(e) => {
                                    //   setCreateObj({
                                    //     ...createObj,
                                    //     leaveTotal: e.target.value,
                                    //   });
                                    // }}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={12} lg={6}>
                                <FormControl sx={{ m: 1, width: "100%", marginLeft: "0%" }}>
                                    <TextField
                                        labelId="balance-leave-label"
                                        id="last-name"
                                        //   value={balanceLeave}
                                        label="Phone Number *"
                                        onChange={(e) => {
                                            setCreateObj({
                                                ...createObj,
                                                leaveTotal: e.target.value,
                                            });
                                        }}
                                    />
                                </FormControl>
                            </Grid>
                            {/* <Grid item xs={12} sm={12} lg={6}>
                                <FormControl sx={{ m: 1, width: "100%", marginLeft: "0%" }}>
                                    <TextField
                                        labelId="balance-leave-label"
                                        id="last-name"
                                        //   value={balanceLeave}
                                        label="Department *"
                                    // onChange={(e) => {
                                    //   setCreateObj({
                                    //     ...createObj,
                                    //     leaveTotal: e.target.value,
                                    //   });
                                    // }}
                                    />
                                </FormControl>
                            </Grid> */}
                            {/* <Grid item xs={12} sm={12} lg={6}>
                                <FormControl sx={{ m: 1, width: "100%", marginLeft: "0%" }}>
                                    <TextField
                                        labelId="balance-leave-label"
                                        id="last-name"
                                        //   value={balanceLeave}
                                        label="Project *"
                                    // onChange={(e) => {
                                    //   setCreateObj({
                                    //     ...createObj,
                                    //     leaveTotal: e.target.value,
                                    //   });
                                    // }}
                                    />
                                </FormControl>
                            </Grid> */}
                            {/* <Grid item xs={12} sm={12} lg={6}>
                                <FormControl sx={{ m: 1, width: "100%", marginLeft: "0%" }}>
                                    <TextField
                                        labelId="balance-leave-label"
                                        id="last-name"
                                        //   value={balanceLeave}
                                        label="Experience *"
                                    // onChange={(e) => {
                                    //   setCreateObj({
                                    //     ...createObj,
                                    //     leaveTotal: e.target.value,
                                    //   });
                                    // }}
                                    />
                                </FormControl>
                            </Grid> */}
                            {/* <Grid item xs={12} sm={12} lg={6}>
                                <FormControl sx={{ m: 1, width: "100%", marginLeft: "0%" }}>
                                    <TextField
                                        labelId="balance-leave-label"
                                        id="last-name"
                                        //   value={balanceLeave}
                                        label="Working Mode *"
                                    // onChange={(e) => {
                                    //   setCreateObj({
                                    //     ...createObj,
                                    //     leaveTotal: e.target.value,
                                    //   });
                                    // }}
                                    />
                                </FormControl>
                            </Grid> */}
                            {/* <Grid item xs={12} sm={12} lg={6}>
                                <FormControl sx={{ m: 1, width: "100%", marginLeft: "0%" }}>
                                    <TextField
                                        labelId="balance-leave-label"
                                        id="last-name"
                                        //   value={balanceLeave}
                                        label="working Location *"
                                    // onChange={(e) => {
                                    //   setCreateObj({
                                    //     ...createObj,
                                    //     leaveTotal: e.target.value,
                                    //   });
                                    // }}
                                    />
                                </FormControl>
                            </Grid> */}
                            {/* <Grid item xs={12} sm={12} lg={6}>
                                <FormControl sx={{ m: 1, width: "100%", marginLeft: "0%" }}>
                                    <TextField
                                        labelId="balance-leave-label"
                                        id="last-name"
                                        //   value={balanceLeave}
                                        label="OverAll Experience *"
                                    // onChange={(e) => {
                                    //   setCreateObj({
                                    //     ...createObj,
                                    //     leaveTotal: e.target.value,
                                    //   });
                                    // }}
                                    />
                                </FormControl>
                            </Grid> */}
                            <Grid item xs={12} sm={12} lg={6}>
                                <FormControl sx={{ m: 1, width: "100%", marginLeft: "0%" }}>
                                    <Button
                                        component="label"
                                        role={undefined}
                                        variant="contained"
                                        tabIndex={-1}
                                        startIcon={<CloudUploadIcon />}
                                    >
                                        Upload file
                                        <VisuallyHiddenInput
                                            type="file"
                                            onChange={handleFileUpload} // Call the handleFileUpload function on file selection
                                        />
                                    </Button>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Box>
                    <Divider sx={{ margin: "32px 0px" }} />
                    <Box sx={{ marginTop: "30px" }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} style={{ textAlign: "right" }}>
                                <Button
                                    sx={{
                                        padding: "8px 20px",
                                        borderRadius: "8px",
                                        marginRight: "32px",
                                        backgroundColor: "#fff",
                                        color: config?.theme?.primary?.main,
                                        border: `1px solid ${config?.theme?.primary?.main}`,
                                        "&:hover": {
                                            backgroundColor: "#fff",
                                        },
                                    }}
                                    variant="contained"
                                    onClick={() => {
                                        handleEditModalClose();
                                        //   handleClose();
                                        //   setTotalDays(0)
                                        //   setCreateObj({
                                        //     leaveName: {},
                                        //     dateWorked: new Date(),
                                        //     leaveTotal: 0,
                                        //     description: "",
                                        //   });
                                    }}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    sx={{
                                        padding: "8px 20px",
                                        borderRadius: "8px",
                                    }}
                                    variant="contained"
                                    // disabled={disableSubmitBtn}
                                    onClick={() => {
                                        handleEditLeave()
                                        //   setDisableSubmitBtn(true);
                                        // handleCreateLeave();
                                        //   handleCreateLeave();
                                    }}
                                >
                                    Submit
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Modal>

            {/* View Modal */}
            <Modal
                open={viewModalOpen}
                onClose={handleViewModalClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >

                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2" >
                        <b>View Employee</b>
                    </Typography>
                    <Box sx={{ marginTop: "20px" }}>
                        {selectedEmployee && (
                            <Grid container spacing={2}>
                                <Card style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    marginLeft: "3%",
                                    width: "95%",
                                    height: " 15vh",
                                    marginTop: "2%",
                                    borderRadius: "1%"
                                }}>

                                    <Typography variant="h7" component="div" style={
                                        {
                                            marginTop: "4%",
                                            marginLeft: "4%",
                                            width: "16%",
                                        }
                                    }>
                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                            <div>
                                                <b> First Name</b>
                                            </div>
                                            <Typography variant="h10" component="div">
                                                {selectedEmployee.FirstName}
                                            </Typography>
                                        </div>
                                    </Typography>

                                    <Typography variant="h7" component="div" style={
                                        {
                                            marginTop: "4%",
                                            marginLeft: "4%",
                                            width: "16%",
                                        }
                                    }>
                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                            <div>
                                                <b> Middle Name</b>
                                            </div>
                                            <Typography variant="h10" component="div">

                                                {selectedEmployee.MiddleName}
                                            </Typography>
                                        </div>
                                    </Typography>
                                    <Typography variant="h7" component="div" style={
                                        {
                                            marginTop: "4%",
                                            marginRight: "4%",
                                            width: "16%",
                                        }
                                    }>
                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                            <div>
                                                <b> Last Name</b>
                                            </div>
                                            <Typography variant="h10" component="div">
                                                {selectedEmployee.LastName}
                                            </Typography>
                                        </div>
                                    </Typography>
                                </Card>
                                <Card style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    marginLeft: "3%",
                                    width: "95%",
                                    height: " 15vh",
                                    marginTop: "2%",
                                    borderRadius: "1%"
                                }}>

                                    <Typography variant="h7" component="div" style={
                                        {
                                            marginTop: "4%",
                                            marginLeft: "4%",
                                            width: "16%",
                                        }
                                    }>
                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                            <div>
                                                <b>Joining Date</b>
                                            </div>
                                            <Typography variant="h10" component="div">
                                                {/* cvhs009 */}
                                            </Typography>
                                        </div>
                                    </Typography>

                                    <Typography variant="h7" component="div" style={
                                        {
                                            marginTop: "4%",
                                            marginLeft: "4%",
                                            width: "16%",
                                        }
                                    }>
                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                            <div>
                                                <b>Employee Code</b>
                                            </div>
                                            <Typography variant="h10" component="div">
                                                {selectedEmployee.employeeCode}
                                            </Typography>
                                        </div>
                                    </Typography>
                                    <Typography variant="h7" component="div" style={
                                        {
                                            marginTop: "4%",
                                            marginRight: "4%",
                                            width: "16%",
                                        }
                                    }>
                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                            <div>
                                                <b>Department</b>
                                            </div>
                                            <Typography variant="h10" component="div">
                                                {/* cvhs009 */}
                                            </Typography>
                                        </div>
                                    </Typography>
                                </Card>
                                <Card style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    marginLeft: "3%",
                                    width: "95%",
                                    height: " 15vh",
                                    marginTop: "2%",
                                    borderRadius: "1%"
                                }}>

                                    <Typography variant="h7" component="div" style={
                                        {
                                            marginTop: "4%",
                                            marginLeft: "4%",
                                            width: "16%",
                                        }
                                    }>
                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                            <div>
                                                <b>Project</b>
                                            </div>
                                            <Typography variant="h10" component="div">
                                                {/* cvhs009 */}
                                            </Typography>
                                        </div>
                                    </Typography>

                                    <Typography variant="h7" component="div" style={
                                        {
                                            marginTop: "4%",
                                            marginLeft: "4%",
                                            width: "16%",
                                        }
                                    }>
                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                            <div>
                                                <b> Experience</b>
                                            </div>
                                            <Typography variant="h10" component="div">
                                                {/* cvhs009 */}
                                            </Typography>
                                        </div>
                                    </Typography>
                                    <Typography variant="h7" component="div" style={
                                        {
                                            marginTop: "4%",
                                            marginRight: "4%",
                                            width: "16%",
                                        }
                                    }>
                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                            <div>
                                                <b>Working Mode</b>
                                            </div>
                                            <Typography variant="h10" component="div">
                                                {/* cvhs009 */}
                                            </Typography>
                                        </div>
                                    </Typography>
                                </Card>
                                <Card style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    marginLeft: "3%",
                                    width: "95%",
                                    height: " 15vh",
                                    marginTop: "2%",
                                    borderRadius: "1%"
                                }}>

                                    <Typography variant="h7" component="div" style={
                                        {
                                            marginTop: "4%",
                                            marginLeft: "4%",
                                            width: "16%",
                                        }
                                    }>
                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                            <div>
                                                <b>Working Location</b>
                                            </div>
                                            <Typography variant="h10" component="div">
                                                {/* cvhs009 */}
                                            </Typography>
                                        </div>
                                    </Typography>

                                    <Typography variant="h7" component="div" style={
                                        {
                                            marginTop: "4%",
                                            marginLeft: "4%",
                                            width: "16%",
                                        }
                                    }>
                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                            <div>
                                                <b>Phone number</b>
                                            </div>
                                            <Typography variant="h10" component="div">
                                                {/* cvhs009 */}
                                            </Typography>
                                        </div>
                                    </Typography>
                                    <Typography variant="h7" component="div" style={
                                        {
                                            marginTop: "4%",
                                            marginRight: "4%",
                                            width: "16%",
                                        }
                                    }>
                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                            <div>
                                                <b>Email Id</b>
                                            </div>
                                            <Typography variant="h10" component="div" style={{
                                                // marginLeft:"10px"
                                            }}>
                                                {/* cvhs009 */}
                                            </Typography>
                                        </div>
                                    </Typography>
                                </Card>

                                <Divider sx={{ margin: "32px 0px" }} />
                                <Box sx={{ marginTop: "30px" }}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} style={{ textAlign: "right" }}>
                                            <Button
                                                sx={{
                                                    padding: "8px 20px",
                                                    borderRadius: "8px",
                                                    marginRight: "32px",
                                                    backgroundColor: "#fff",
                                                    color: config?.theme?.primary?.main,
                                                    border: `1px solid ${config?.theme?.primary?.main}`,
                                                    "&:hover": {
                                                        backgroundColor: "#fff",
                                                    },
                                                }}
                                                onClick={() => {
                                                    handleViewModalClose();
                                                }}
                                            >
                                                Cancel
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Box>

                            </Grid>
                        )}
                    </Box>
                </Box>
            </Modal>

            {/* Create Modal */}
            <Modal
                open={createModalOpen}
                onClose={handleCreateModalClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        <b>Create Employee</b>
                    </Typography>
                    <Box sx={{ marginTop: "20px" }}>
                        <Grid container spacing={2}>

                            <Grid item xs={12} sm={12} lg={6}>
                                <FormControl sx={{ m: 1, width: "100%", margin: "0px 0px 0px 0px" }}>
                                    <TextField
                                        labelId="balance-leave-label"
                                        id="first-name"
                                        //   value={firstName}
                                        label="First Name *"
                                        onChange={(e) => {
                                            setCreateObj({
                                                ...createObj,
                                                firstName: e.target.value,
                                            });
                                        }}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={12} lg={6}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={12} lg={6}>
                                        <FormControl sx={{ m: 1, width: "100%", margin: "0px 0px 0px 0px" }}>
                                            <TextField
                                                labelId="balance-leave-label"
                                                id="middle-name"
                                                //   value={balanceLeave}
                                                label="Middle Name *"
                                                onChange={(e) => {
                                                    setCreateObj({
                                                        ...createObj,
                                                        middleName: e.target.value,
                                                    });
                                                }}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={12} lg={6}>
                                        <FormControl sx={{ m: 1, width: "100%", margin: "0px 0px 0px 0px" }}>
                                            <TextField
                                                labelId="balance-leave-label"
                                                id="last-name"
                                                //   value={balanceLeave}
                                                label="Last Name *"
                                                onChange={(e) => {
                                                    setCreateObj({
                                                        ...createObj,
                                                        lastName: e.target.value,
                                                    });
                                                }}
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={12} lg={6}>
                                <FormControl sx={{ m: 1, width: "100%", marginLeft: "0%" }}>
                                    <TextField
                                        labelId="balance-leave-label"
                                        id="last-name"
                                        //   value={balanceLeave}
                                        label="Employee Code *"
                                        onChange={(e) => {
                                            setCreateObj({
                                                ...createObj,
                                                employeeCode: e.target.value,
                                            });
                                        }}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={12} lg={6}>
                                <FormControl sx={{ m: 1, width: "100%", margin: "0px 0px 0px 0px" }}>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DemoContainer components={["DesktopDatePicker"]}>
                                            <DatePicker
                                                label=" Joining Date *"
                                                sx={{ width: "200%" }}
                                                //   disablePast={true}
                                                format="dd-MM-yyyy"
                                                //   defaultValue={createObj?.hireDate}
                                                onChange={(date) => {
                                                    setCreateObj({ ...createObj, joiningDate: date });
                                                }}
                                            />
                                        </DemoContainer>
                                    </LocalizationProvider>
                                </FormControl>
                             
                            </Grid>
                            <Grid item xs={12} sm={12} lg={6}>
                            <FormControl sx={{ m: 1, width: "100%", margin: "0px 0px 0px 0px" }}>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DemoContainer components={["DesktopDatePicker"]}>
                                            <DatePicker
                                                label="Date Of Birth *"
                                                sx={{ width: "200%" }}
                                                //   disablePast={true}
                                                format="dd-MM-yyyy"
                                                //   defaultValue={createObj?.hireDate}
                                                onChange={(date) => {
                                                    setCreateObj({ ...createObj, dateOfBirth: date });
                                                }}
                                            />
                                        </DemoContainer>
                                    </LocalizationProvider>
                                </FormControl>
                                </Grid>
                            <Grid item xs={12} sm={12} lg={6}>
                                <FormControl sx={{ m: 1, width: "100%", marginLeft: "0%" }}>
                                    <TextField
                                        labelId="balance-leave-label"
                                        id="last-name"
                                        //   value={balanceLeave}
                                        label="EMail ID *"
                                        onChange={(e) => {
                                            setCreateObj({
                                                ...createObj,
                                                emailId: e.target.value,
                                            });
                                        }}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={12} lg={6}>
                                <FormControl sx={{ m: 1, width: "100%", marginLeft: "0%" }}>
                                    <TextField
                                        labelId="balance-leave-label"
                                        id="last-name"
                                        //   value={balanceLeave}
                                        label="Phone Number *"
                                        onChange={(e) => {
                                            setCreateObj({
                                                ...createObj,
                                                phoneNumber: e.target.value,
                                            });
                                        }}
                                    />
                                </FormControl>
                            </Grid>
                            {/* <Grid item xs={12} sm={12} lg={6}>
                                <FormControl sx={{ m: 1, width: "100%", marginLeft: "0%" }}>
                                    <TextField
                                        labelId="balance-leave-label"
                                        id="last-name"
                                        //   value={balanceLeave}
                                        label="Department *"
                                    // onChange={(e) => {
                                    //   setCreateObj({
                                    //     ...createObj,
                                    //     leaveTotal: e.target.value,
                                    //   });
                                    // }}
                                    />
                                </FormControl>
                            </Grid> */}
                            {/* <Grid item xs={12} sm={12} lg={6}>
                                <FormControl sx={{ m: 1, width: "100%", marginLeft: "0%" }}>
                                    <TextField
                                        labelId="balance-leave-label"
                                        id="last-name"
                                        //   value={balanceLeave}
                                        label="Project *"
                                    // onChange={(e) => {
                                    //   setCreateObj({
                                    //     ...createObj,
                                    //     leaveTotal: e.target.value,
                                    //   });
                                    // }}
                                    />
                                </FormControl>
                            </Grid> */}

                            {/* <Grid item xs={12} sm={12} lg={6}>
                                <FormControl sx={{ m: 1, width: "100%", marginLeft: "0%" }}>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DemoContainer components={["DesktopDatePicker"]}>
                                            <DatePicker
                                                label="Joining Date *"
                                                sx={{ width: "200%" }}
                                                disablePast={true}
                                                format="dd-MM-yyyy"
                                            // defaultValue={createObj?.startDate}
                                            // onChange={(date) => {
                                            //     setCreateObj({ ...createObj, startDate: date });
                                            // }}
                                            />
                                        </DemoContainer>
                                    </LocalizationProvider>
                                </FormControl>
                            </Grid> */}
                            {/* <Grid item xs={12} sm={12} lg={6}>
                                <FormControl sx={{ m: 1, width: "100%", marginLeft: "0%" }}>
                                    <TextField
                                        labelId="balance-leave-label"
                                        id="last-name"
                                        //   value={balanceLeave}
                                        label="Gender *"
                                        onChange={(e) => {
                                            setCreateObj({
                                                ...createObj,
                                                gender: e.target.value,
                                            });
                                        }}
                                    />
                                </FormControl>
                            </Grid> */}
                            <Grid item xs={12} sm={12} lg={6}>
                                <FormControl sx={{ m: 1, width: "100%", marginLeft: "0%" }}>
                                    <Autocomplete
                                        disablePortal
                                        id="combo-box-demo"
                                        options={['Female', 'Male']}
                                        // value={createLeaveTypeObj.status}
                                        onChange={(e, value) => setCreateObj({ ...createObj, gender: value })}
                                        renderInput={(params) => <TextField {...params} label="Gender *" />}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={12} lg={6}>
                                <FormControl sx={{ m: 1, width: "100%", marginLeft: "0%" }}>
                                    <TextField
                                        labelId="balance-leave-label"
                                        id="last-name"
                                        //   value={balanceLeave}
                                        label="Manager Name *"
                                        onChange={(e) => {
                                            setCreateObj({
                                                ...createObj,
                                                managerName: e.target.value,
                                            });
                                        }}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={12} lg={6}>
                                <FormControl sx={{ m: 1, width: "100%", marginLeft: "0%" }}>
                                    <Autocomplete
                                        disablePortal
                                        id="combo-box-demo"
                                        options={['Active', 'InActive']}
                                        // value={createLeaveTypeObj.status}
                                        onChange={(e, value) => setCreateObj({ ...createObj, status: value })}
                                        renderInput={(params) => <TextField {...params} label="Status *" />}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={12} lg={6}>
                                <FormControl sx={{ m: 1, width: "100%", marginLeft: "0%" }}>
                                    <Button
                                        component="label"
                                        role={undefined}
                                        variant="contained"
                                        tabIndex={-1}
                                        startIcon={<CloudUploadIcon />}
                                    >
                                        Upload file
                                        <VisuallyHiddenInput
                                            type="file"
                                            onChange={handleFileUpload} // Call the handleFileUpload function on file selection
                                        />
                                    </Button>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Box>
                    <Divider sx={{ margin: "32px 0px" }} />
                    <Box sx={{ marginTop: "30px" }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} style={{ textAlign: "right" }}>
                                <Button
                                    sx={{
                                        padding: "8px 20px",
                                        borderRadius: "8px",
                                        marginRight: "32px",
                                        backgroundColor: "#fff",
                                        color: config?.theme?.primary?.main,
                                        border: `1px solid ${config?.theme?.primary?.main}`,
                                        "&:hover": {
                                            backgroundColor: "#fff",
                                        },
                                    }}
                                    variant="contained"
                                    onClick={() => {
                                        handleCreateModalClose();
                                        //   handleClose();
                                        //   setTotalDays(0)
                                        //   setCreateObj({
                                        //     leaveName: {},
                                        //     dateWorked: new Date(),
                                        //     leaveTotal: 0,
                                        //     description: "",
                                        //   });
                                    }}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    sx={{
                                        padding: "8px 20px",
                                        borderRadius: "8px",
                                    }}
                                    variant="contained"
                                    // disabled={disableSubmitBtn}
                                    onClick={() => {
                                        //   setDisableSubmitBtn(true);
                                        handleCreateLeave();
                                        //   handleCreateLeave();
                                    }}
                                >
                                    Submit
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Modal>

        </Fragment >
    );
}
