import React from 'react';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import moment from 'moment'
import Button from '@mui/material/Button';
import { parseISO, format } from 'date-fns';
import { useNavigate } from "react-router-dom";
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useState, useEffect ,useContext} from "react";
import Context from "context/context";
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import { toast } from "react-toastify";
import FormControl from '@mui/material/FormControl';
import vraRadAffinityAPI from 'apis/panel/vraRadAffinity/vraRadAffinity';
import {InputLabel, FormHelperText } from '@mui/material';



const AddVraRadAffinity = () => {
    const navigate = useNavigate();
    const { state, dispatch } = useContext(Context);
    const [affinityStartDate, setAffinityStartDate] = useState(new Date());
    const [noOfShifts, setNoOfShifts] = useState(0);
    const [vraRosterId, setVraRosterId] = useState("");
    const [ radRosterId, setRadRosterId] = useState("");
    const [allRadRostersDetails,setAllRadRostersDetails]= useState([])
    const [allVraRostersDetails,setAllVraRostersDetails]= useState([])


    const [errorFields, setErrorFields] = useState({

        affinityStartDate: false,
        noOfShifts: false,
        vraRosterId: false,
        radRosterId: false,

    });


    // api's

    const {createVraRadAffinityAPI,getAllRadRostersAPI,getAllVraRostersAPI } = vraRadAffinityAPI()

   
    useEffect(()=>{
        handleGetRadRostersAPI()
        handleGetVraRostersAPI()
    },[])

    const handleGetRadRostersAPI = async () => {
        try {
            
            dispatch({ type: "SET_LOADING", payload: true });
            const res = await getAllRadRostersAPI();
         setAllRadRostersDetails(res?.data?.radRostersIds)
            dispatch({ type: "SET_LOADING", payload: false });
            
        } catch (error) {
           
            console.error('Error fetching RAD rosters:', error);
    
            dispatch({ type: "SET_LOADING", payload:false });
        }
    };

    const handleGetVraRostersAPI = async () => {
        try {
            
            dispatch({ type: "SET_LOADING", payload: true });
            const res = await getAllVraRostersAPI();
         setAllVraRostersDetails(res?.data?.vraRosterIds)
            dispatch({ type: "SET_LOADING", payload: false });
            
        } catch (error) {
           
            console.error('Error fetching RAD rosters:', error);
    
            dispatch({ type: "SET_LOADING", payload:false });
        }
    };
  

    const handleSaveRadRoster = async (e) => {
        e.preventDefault();

        const errors = {};

        if (!affinityStartDate) errors.affinityStartDate = true;
        if (!noOfShifts) errors.noOfShifts = true;
        if (!radRosterId) errors.radRosterId = true;
        if (!vraRosterId) errors.vraRosterId = true;
        
        setErrorFields(errors);

        if (Object.keys(errors).length === 0) {


            const values = {
              
                affinityStartDate: affinityStartDate,
                radRosterId:radRosterId,
                vraRosterId:vraRosterId,
                numberOfShiftsTogether: parseFloat(noOfShifts),
               
            };


            dispatch({ type: "SET_LOADING", payload: true });

            const res = await createVraRadAffinityAPI(values);
           
            
            if (res.status === 'Ok') {
               handleCancel()
               dispatch({ type: "SET_LOADING", payload: false });
                return toast.success("Data Saved Successfully", { toast: "Data Saved Successfully" });
            }
            else {
                dispatch({ type: "SET_LOADING", payload: false });
                return toast.error("Error: User already exists in the system");
            }

        }


    };

    const handleCancel =()=>{
        handleReset()
        navigate("/panel/vraRadAffinity")
    }

    const handleReset = () => {

        setAffinityStartDate('')
        setNoOfShifts(0)
        setVraRosterId('')
        setRadRosterId('')
    
    }

    const handleAffinityStartDateChange = (date) => {
       
        setAffinityStartDate(date);

        
    };

   
    const handleVraRosterIdChange = (event) => {

        const input = event.target.value;
        
        setVraRosterId(input);
    
    };

    const handleRadRosterIdChange = (e) => {

        const input = e.target.value;
        
        setRadRosterId(input);
    };

    const handleNoOfShiftsChange = (event) => {
        const input = event.target.value;

    
        if (/^\d*$/.test(input) ) { 
            setNoOfShifts(input); 
            setErrorFields({ ...errorFields, vraNeededCount: false }); 
        } else {
            setErrorFields({ ...errorFields, vraNeededCount: true }); 
        } 
        
    };

   
    
    return (
        <div style={{ backgroundColor: "white" }}>

            <Grid container spacing={2} sx={{ pl: 4, pr: 5 }} >
               
                
                <Grid item xs={12} sm={6} md={6} lg={6} sx={{ mt: 2 }}>
                    <Box className="label">Affinity Start Date</Box>
                    <LocalizationProvider fullWidth dateAdapter={AdapterDateFns}>
                        <DatePicker
                            id="affinityStartDate"
                            name="affinityStartDate"
                            value={affinityStartDate ? new Date(affinityStartDate) : null}
                            sx={{ width: "100%", border: "2px solid #B2D4F8" }}
                            onAccept={handleAffinityStartDateChange}
                            fullWidth
                            inputFormat="dd/mm/yyyy"
                            slotProps={{
                                field: { clearable: true },
                            }}
                            maxDate={new Date()}
                            renderInput={(params) => <TextField {...params} fullWidth />}
                        />
                    </LocalizationProvider>
                    {/* <TextField sx={{ border: '1px solid #B2D4F8' }}id="deployedDate" value={deployedDate}  name="deployedDate"  onChange={handleDeployedDateChange} placeholder="deployedDate" variant="outlined" fullWidth /> */}
                    {errorFields.affinityStartDate && <span className="error-msg">This is required Field</span>}
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} sx={{ mt: 2 }}>
                    <Box className="label">Number of Shifts Together<span style={{ color: "red" }}>*</span></Box>
                    <TextField sx={{ border: '1px solid #B2D4F8' }} value={noOfShifts} name="noOfShifts" onChange={handleNoOfShiftsChange} id="noOfShifts" placeholder="Enter Nuber of shifts Together" variant="outlined" fullWidth />
                    {errorFields.noOfShifts && <span className="error-msg">This is required Field</span>}
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} sx={{ mt: 2 }}>
            <Box className="label">
                RAD Qgenda FullName<span style={{ color: "red" }}>*</span>
            </Box>
            <FormControl fullWidth variant="outlined" error={Boolean(errorFields.radRosterId)}>
                {/* <InputLabel id="rad-roster-select-label">Select RAD Qgenda Name</InputLabel> */}
                <Select
                    labelId="rad-roster-select-label"
                    id="radRosterId"
                    displayEmpty
                    value={radRosterId?radRosterId:""}
                    onChange={handleRadRosterIdChange}
                    // label="Select RAD Qgenda Name"
                    sx={{ border: '1px solid #B2D4F8' }}
                >
                      <MenuItem value="">
                      Select RAD Qgenda Name
          </MenuItem>
                    {allRadRostersDetails?.map((rad) => (
                        <MenuItem key={rad.radrosterid} value={rad.radrosterid}>
                            {rad.radiologistusername}
                        </MenuItem>
                    ))}
                </Select>
                
                {errorFields.radRosterId && (
                    <FormHelperText>
                        <ErrorOutlineOutlinedIcon style={{ verticalAlign: "middle", marginRight: "4px" }} />
                        This is a required field
                    </FormHelperText>
                )}
            </FormControl>
        </Grid>

        <Grid item xs={12} sm={6} md={6} lg={6} sx={{ mt: 2 }}>
            <Box className="label">
                VRA SRF Name<span style={{ color: "red" }}>*</span>
            </Box>
            <FormControl fullWidth variant="outlined" error={Boolean(errorFields.vraRosterId)}>
                {/* <InputLabel id="vra-roster-select-label">Select VRA Srf Name</InputLabel> */}
                <Select
                    labelId="vra-roster-select-label"
                    id="vraRosterId"
                    value={vraRosterId}
                    displayEmpty
                    onChange={handleVraRosterIdChange}
                    // label="Select VRA Srf name"
                    sx={{ border: '1px solid #B2D4F8' }}
                >
                      <MenuItem value="">
                      Select VRA SRF Name
          </MenuItem>
                    {allVraRostersDetails.map((vra) => (
                        <MenuItem key={vra.vraRosterId} value={vra.vraRosterId}>
                            {vra.srfName}
                        </MenuItem>
                    ))}
                </Select>
                {errorFields.vraRosterId && (
                    <FormHelperText>
                        <ErrorOutlineOutlinedIcon style={{ verticalAlign: "middle", marginRight: "4px" }} />
                        This is a required field
                    </FormHelperText>
                )}
            </FormControl>
        </Grid>
             
            
            </Grid>


            <Grid container sx={{ pl: 4, pr: 5, mt: 5, mb:5, pb:4, justifyContent: "center" }} >
                <Grid item sx={{ ml: 1 }} xs={12} sm={3} md={3} lg={3}>
                    <Button className="resetChanges" onClick={handleReset}>Reset Changes</Button>
                </Grid>

                <Grid item xs={12} sm={2} md={2} lg={2}>
                    <Button className="cancelButton" onClick={handleCancel}   >Cancel</Button>
                </Grid>

                <Grid item sx={{ mr: 1 }} xs={12} sm={2} md={2} lg={2}>
                    <Button className="saveButton" onClick={handleSaveRadRoster} >Save</Button>
                </Grid>

            </Grid>





        </div>
    )
}

export default AddVraRadAffinity;
